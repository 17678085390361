import React, { Fragment, useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import LoadingButton from "@mui/lab/LoadingButton";
import { Col, Row } from "react-bootstrap";
import { useAPI } from "../../utils/useAPI";
import { useUser } from "../../utils/useUser";
import { useNavigate } from "react-router-dom";
import { sendEvent, tdrAlert, tdrLog } from "../../utils/utils";
import Button from "@mui/material/Button";
import * as apiproto from "../../apiproto";


type StripeKeys = {
    clientSecret: string;
    publishableKey: string;
};



type ChildProps =
    {
        planSelected: apiproto.api.IPlanInfo | undefined,
        update: boolean,
        stripeKeys: StripeKeys,
        existingPayment: string,
        paymentCancel?: () => void | null,
        paymentSuccess?: () => void | null,
    }

const ProductCheckout: React.FC<ChildProps> = ({ planSelected = {}, update = false, stripeKeys = { clientSecret: "", publishableKey: "" }, existingPayment = "", paymentCancel = () => { }, paymentSuccess = () => { } }) => {
    const api = useAPI();
    const user = useUser();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string | null | undefined>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPaymentSuccess,] = useState(false);
    const [setupIntentID, setSetupIntentID] = useState<string | null>(null);
    const [, setPaymentInfo] = useState({ lastName: "", firstName: "", email: "" });
    const [closeButton, setCloseButton] = useState(false);
    const [, setPaymentRadioValue] = React.useState('new');
    const [, setTotalStr] = useState("$0.00");


    useEffect(() => {
        const getUserProfile = async (userId: string) => {
            try {
                const userResult = await api.getUser({}, { userId: userId });
                if (userResult && userResult.user) {
                    const userinfo = userResult.user;
                    if (userinfo.firstName && userinfo.lastName && userinfo.emailAddress) {
                        setPaymentInfo({ firstName: userinfo.firstName, lastName: userinfo.lastName, email: userinfo.emailAddress })
                    }
                    return userResult.user;
                }
                /*
                if (topicOwners && topicOwners.length > 0) {
                    setPaymentInfo({ firstName: topicOwners[0].firstName!, lastName: topicOwners[0].lastName!, email: topicOwners[0].emailAddress! })
                }*/
            }
            catch (err: any) {
                // We don't throw this error back since the IDs may have been deleted for some reason
                // and this not necessarily a fatal error.
            }
            return null;
        }


        if (user.userId !== null && stripeKeys.publishableKey.length > 0) {
            getUserProfile(user.userId);
        }
    }, [stripeKeys.publishableKey, user.userId]);

    useEffect(() => {
        if (existingPayment.length > 0) {
            setPaymentRadioValue('existing');
        }
    }, [existingPayment.length]);

    useEffect(() => {
        if (planSelected.amount) {
            const pricestr = planSelected.amount.toLocaleString("en-US", { style: "currency", currency: "USD" });
            setTotalStr(pricestr);
        }
    }, [planSelected.planId, planSelected.amount]
    )
    const handleSubmit = async () => {
        if (planSelected.amount === 0) {
            setMessage("You have selected 'No Top Up'. You must select an amount to top up with when you update payments.");
            return;
        }

        setIsLoading(true);
        if (closeButton) {
            // Here is means something failed with Stripe 
            // we refresh entire page to get a refreshed Stripe object.
            // Here is why, user A and user B both checkout and then both hit confirm
            // the first one will succeed. User B will fail with require_payment_method which really should not be the error name
            // regardless this failure, we need to refresh.
            setCloseButton(false);
            if (window) {
                window.location.reload();
            }
            return;
        }
        if (!user.authenticated) {
            navigate('/login');
        }

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setIsLoading(false)
            return;
        }
        if (!stripe) {
            setIsLoading(false)
            return;
        }
        if (stripeKeys.clientSecret.length === 0) {
            setIsLoading(false)
            return;
        }
        let returnurl = "https://" + window.location.hostname + "/profile";
        if (window.location.hostname === 'localhost') {
            returnurl = "http://localhost:3000/profile"
        }
        /*
        if (paymentRadioValue === 'existing') {
            setMessage("Processing payment on file...please wait...");
            setSetupIntentID(null);
            setIsLoading(true);
            paymentSuccess();
            return;
        }
        else {

        }
*/
        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: returnurl
            },
            redirect: "if_required"
        })
        if (error) {
            tdrAlert('The error is ' + error.type)
            sendEvent('Upgrade', 'UpgradeStripeError', error.type);

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            }
            else if (error.type === "invalid_request_error") {
                // This happens when the same user press book on 2 different tabs or browser
                // Tries to enter the credit card in the 1st one - but possibly the 2nd one made the 1st invalid
                // so it needs to be closed.
                setMessage("There was an invalid request. Close this screen and try again.");
                setCloseButton(true);
            }
            else {
                setMessage("An unexpected error occurred.");
            }
        }
        else if (setupIntent) {
            setMessage("Processing payment...Please do not close your browser.");
            setIsLoading(true);
            setSetupIntentID(setupIntent.id);
            sendEvent('Upgrade', 'UpgradeProcessing', user.tokensLeft + "");
            return;
        }
        setIsLoading(false);
    };

    const refreshPaymentIntent = () => {
        if (setupIntentID != null && stripe) {
            stripe.retrieveSetupIntent(stripeKeys.clientSecret).then(({ setupIntent }) => {
                switch (setupIntent!.status) {
                    case "succeeded":
                        //setMessage("Payment authorized...please wait...");
                        //setConfirmPaymentSuccess(true);
                        setSetupIntentID(null);
                        setIsLoading(false);
                        paymentSuccess();
                        break;
                    case "processing":
                        setMessage("Your payment setup is processing.");
                        setIsLoading(true);
                        break;
                    case "requires_payment_method":
                        // Do not trap this error because it can come here if you retrieve too quickly and
                        // stripe has still not process payment info.
                        // Not sure Stripe returns this error instead of something else when payment is actually submitted
                        setMessage("Your payment was not successful, please try again.");
                        setIsLoading(false);
                        //setPaymentIntentID(null);
                        //setCloseButton(true);
                        break;
                    case "canceled":
                        setMessage("Payment setup was cancelled. This can be due to a card error or someone booked the session just ahead of you. Please try again.");
                        setSetupIntentID(null);
                        setIsLoading(false);
                        setCloseButton(true);
                        break;
                    case "requires_confirmation":
                        setMessage("Payment requires confirmation.");
                        setIsLoading(false);
                        break;
                    default:
                        setMessage("Something went wrong.");
                        setSetupIntentID(null);
                        setIsLoading(false);
                        break;
                }
            }).catch((err) => {
                setMessage("An error occurred processing payment setup. Please try again");
                tdrLog('error occurred - ' + err.name);
                setSetupIntentID(null);
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        if (setupIntentID !== null) {
            const interval = setInterval(() => {
                refreshPaymentIntent()
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [setupIntentID])

    const cancelClicked = () => {
        paymentCancel();
    }

    return (
        <div>
            <Fragment>
                <Row>
                    <Col>
                        <div className="form-group form-input">
                            <h2 style={{ fontSize: "1.1rem" }}>Payment Information</h2>
                            <hr />
                        </div>
                    </Col>
                </Row>
            </Fragment>
            {existingPayment.length > 0 &&
                <Row>
                    <Col>
                        <b>Current Payment on File</b><br /> {existingPayment}
                    </Col>
                </Row>
            }
            {confirmPaymentSuccess ? <div></div>
                :
                <Fragment>
                    <div>
                        <Row>
                            <Col>
                                <b>Enter billing information:</b><br /><br />
                                <PaymentElement id="payment-element" />
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col>
                            <br />
                            <p className="form-error">{message}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                sx={{ float: 'right', mr: 2 }}
                                size="large"
                                variant="text"
                                onClick={cancelClicked}
                                disabled={isLoading}>
                                Cancel
                            </Button>
                            <LoadingButton
                                sx={{ float: 'right', mr: 2 }}
                                size="large"
                                variant="contained"
                                loading={isLoading}
                                onClick={handleSubmit}
                                hidden={false}>
                                {existingPayment ? "Update Payment" : "Subscribe"}
                            </LoadingButton>
                        </Col>
                    </Row>
                </Fragment>
            }

        </div>
    );
}

export default ProductCheckout;