import * as apiproto from '../apiproto';
import { APIObject } from './apiobject';
import protobuf from 'protobufjs';
import React from 'react';
import { ActionType, AuthContext } from '../context/authcontext';
import { decodeJWT } from './utils';

export { API }

class API extends APIObject {
    url: string;
    token: string | null = null;
    anonymous: boolean = false;
    userId: string | null = null;
    dispatch: React.Dispatch<ActionType> | null;

    constructor(dispatch: React.Dispatch<ActionType> | null) {
        super();
        this.dispatch = dispatch;
        this.url = process.env["REACT_APP_APIURL"]!;
    }

    setToken(newToken: string, anonymous: boolean) {
        this.token = newToken;
        this.anonymous = anonymous;
    }

    async executeHttpRequests(singleTransaction: boolean, apiRequests: apiproto.api.APIRequest[], checkForErrors: boolean = false): Promise<apiproto.api.IAPIResponse[]> {
        try {
            if (apiRequests.length === 0) {
                throw this.newError('Cannot send empty request', 'INVALID_PARAMETER');
            }

            const requestMessage = new apiproto.api.APIMessage({ apiRequests: { singleTransaction: singleTransaction, requests: apiRequests } });
            const writer = protobuf.Writer.create();
            apiproto.api.APIMessage.encode(requestMessage, writer);
            const requestData = writer.finish();
            if (requestData.length === 0) {
                throw this.newError('Invalid request size', 'Invalid request size');
            }
            const headers: HeadersInit = {};
            if (this.token) {
                headers['Authorization'] = 'Basic ' + this.token;
            }
            headers['Content-Type'] = 'application/octet-stream';
            const response = await fetch(this.url + '/api', { method: 'POST', body: requestData, cache: 'no-store', headers: headers, credentials: 'omit' });
            if (response.status !== 200) {
                throw this.newError('Bad status code', 'Invalid response');
            }
            if (!response.body) {
                throw this.newError('No body', 'Invalid response');
            }

            const newToken = response.headers.get('token');
            if (newToken) {
                // has new token
                const { userId, exp } = decodeJWT(newToken);
                if (this.dispatch) {
                    this.dispatch({ type: 'updateToken', updateTokenPayload: { userId: userId, token: newToken, expirationTime: exp * 1000, anonymous: this.anonymous } });
                }
            }
            if (response.headers.get('Content-Type') !== 'application/octet-stream') {
                throw this.newError('Bad respond content type', 'Invalid response');
            }
            const contentLengthString = response.headers.get('Content-Length');
            if (!contentLengthString) {
                throw this.newError('Missing response content length', 'Invalid content length');
            }

            const contentLength = parseInt(contentLengthString, 10);

            if (isNaN(contentLength)) {
                throw this.newError('Invalid content length', 'Invalid content length');
            }

            if (contentLength <= 0 || contentLength > 65536 * 1000) {
                throw this.newError('Too large or too small content length', 'Invalid content length');
            }

            const responseData = await response.arrayBuffer();
            const msg = apiproto.api.APIMessage.decode(new Uint8Array(responseData)) as apiproto.api.APIMessage;
            if (!msg.apiResponses || !msg.apiResponses.responses) {
                throw this.newError('Invalid response', 'Invalid response from API');
            }

            if (msg.apiResponses.error) {
                throw this.newError(msg.apiResponses.error, msg.apiResponses.errorCode ? msg.apiResponses.errorCode : 'API_ERROR');
            }

            if (msg.apiResponses.responses.length !== apiRequests.length) {
                throw this.newError('Invalid response', 'Invalid response from API');
            }
            for (let a = 0; a < apiRequests.length; a++) {
                const apiResponse = msg.apiResponses.responses[a]
                if (apiRequests[a].requestId !== apiResponse.requestId) {
                    // mis match request id
                    throw this.newError('Invalid response', 'mismatch request id from API');
                }
                if (checkForErrors) {
                    if (apiResponse.error) {
                        throw this.newError(apiResponse.error, apiResponse.errorCode ? apiResponse.errorCode : 'API_ERROR');
                    }
                }
            }
            return msg.apiResponses.responses;
        } catch (err) {
            // error
            this.log('Error executing api - ' + err);
            throw err
        }
    }

    async executeHttpRequest(apiRequest: apiproto.api.APIRequest): Promise<apiproto.api.IAPIResponse> {
        const responses = await this.executeHttpRequests(true, [apiRequest])
        if (responses.length !== 1) {
            throw this.newError('Invalid response', 'Invalid response from API');
        }
        const apiResponse = responses[0]
        if (apiResponse.error) {
            throw this.newError(apiResponse.error, apiResponse.errorCode ? apiResponse.errorCode : 'API_ERROR');
        }
        return apiResponse
    }

    async slackLogin(slackAuthCode: string, slackRedirectUrl: string): Promise<apiproto.api.ILoginResponse> {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ loginRequest: { slackOauth: { slackAuthCode: slackAuthCode, slackRedirectUrl: slackRedirectUrl } } }));
        if (!result.loginResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.loginResponse;
    }

    async slackLoginAnonymous(slackAuthCode: string, slackRedirectUrl: string, token: string): Promise<apiproto.api.ILoginResponse> {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ loginRequest: { slackOauth: { slackAuthCode: slackAuthCode, slackRedirectUrl: slackRedirectUrl }, existingUserToken: token } }));
        if (!result.loginResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.loginResponse;
    }

    async googleLogin(jwtToken: string): Promise<apiproto.api.ILoginResponse> {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ loginRequest: { googleCredential: { jwtToken: jwtToken } } }));
        if (!result.loginResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.loginResponse;
    }

    async googleLoginAnonymous(jwtToken: string, token: string): Promise<apiproto.api.ILoginResponse> {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ loginRequest: { googleCredential: { jwtToken: jwtToken }, existingUserToken: token } }));
        if (!result.loginResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.loginResponse;
    }

    async nativeLogin(emailAddress: string, password: string): Promise<apiproto.api.ILoginResponse> {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ loginRequest: { emailPassword: { emailAddress: emailAddress, password: password } } }));
        if (!result.loginResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.loginResponse;
    }

    async createUser(parentGroupId: string, userInfo: apiproto.api.IUser, userToken?: string) {
        let result = null;
        if (userToken) {
            result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createNativeUserRequest: { existingUserToken: userToken, parentGroupId: parentGroupId, user: userInfo } }));
        }
        else {
            result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createNativeUserRequest: { parentGroupId: parentGroupId, user: userInfo } }));
        }
        if (!result.createNativeUserResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.createNativeUserResponse;
    }

    // the user for the secondary token will be deleted
    async mergeUser(secondaryToken: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ mergeUserRequest: { primaryToken: this.token, secondaryToken: secondaryToken } }));
        if (!result.mergeUserResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.mergeUserResponse;
    }

    async generateText(template: string, parameters: { [index: string]: string }, textRequest: apiproto.api.IGenerateTextRequest, numAnswers: number = 1) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ generateAiRequest: { template: template, parameters: parameters, generateTextRequest: textRequest, numAnswers: numAnswers } }));
        if (!result.generateAiResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        if (this.dispatch) {
            this.dispatch({ type: 'updateTokensLeft', updateTokensLeftPayload: { tokensLeft: result.generateAiResponse.tokensLeft ? result.generateAiResponse.tokensLeft as number : 0 } });
        }
        return result.generateAiResponse;
    }

    async generateImageOld(template: string, parameters: { [index: string]: string }, imageRequest: apiproto.api.IGenerateImageRequest, numAnswers: number = 1, paidOnly: boolean = false) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ generateAiRequest: { template: template, parameters: parameters, generateImageRequest: imageRequest, numAnswers: numAnswers, paidOnly: paidOnly } }));
        if (!result.generateAiResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        if (this.dispatch) {
            this.dispatch({ type: 'updateTokensLeft', updateTokensLeftPayload: { tokensLeft: result.generateAiResponse.tokensLeft ? result.generateAiResponse.tokensLeft as number : 0 } });
        }
        // modify image urls
        const s3Bucket = process.env['REACT_APP_S3_BUCKET'];
        if (s3Bucket) {
            if (result.generateAiResponse.responses) {
                for (const response of result.generateAiResponse.responses) {
                    if (response.images) {
                        for (const image of response.images) {
                            if (image.imageUrl) {
                                const url = new URL(image.imageUrl);
                                image.imageUrl = s3Bucket + url.pathname;
                            }
                            if (image.thumbnailUrl) {
                                const url = new URL(image.thumbnailUrl);
                                image.thumbnailUrl = s3Bucket + url.pathname;
                            }
                        }
                    }
                }
            }
        }
        return result.generateAiResponse;
    }

    generateImage(template: string, parameters: { [index: string]: string }, imageRequest: apiproto.api.IGenerateImageRequest, numAnswers: number = 1, priority: number = 1, paidOnly: boolean = false): Promise<apiproto.api.IGenerateAIResponse> {
        return new Promise((resolve, reject) => {
            var jobId: string | null | undefined = null;
            var requestDone: boolean = false;
            var ws: WebSocket | null = null;

            const connect = () => {
                ws = new WebSocket(this.url.replace('http', 'ws') + '/ws');
                //console.log('connecting to ws');
                const currentWebSocket = ws;
                currentWebSocket.binaryType = 'arraybuffer';
                var closed: boolean = false;
                currentWebSocket.addEventListener('open', () => {
                    //console.log('ws open');
                    // send authentication message
                    {
                        const writer = protobuf.Writer.create();
                        const authMessage = new apiproto.api.APIRequest({ authenticateRequest: { token: this.token } });
                        apiproto.api.APIRequest.encode(authMessage, writer);
                        const requestData = writer.finish();
                        currentWebSocket.send(requestData);
                        //console.log("sent auth request");
                    }
                    if (!jobId) {
                        // send request
                        const writer = protobuf.Writer.create();
                        const requestMessage = new apiproto.api.APIRequest({ generateAiRequest: { template: template, parameters: parameters, generateImageRequest: imageRequest, numAnswers: numAnswers, paidOnly: paidOnly, priority: priority } });
                        apiproto.api.APIRequest.encode(requestMessage, writer);
                        const requestData = writer.finish();
                        currentWebSocket.send(requestData);
                        //console.log("sent image create request");
                    } else {
                        // send job tracking
                        const writer = protobuf.Writer.create();
                        const getJobEvents = new apiproto.api.APIRequest({ getJobEventsRequest: { jobId: jobId } });
                        apiproto.api.APIRequest.encode(getJobEvents, writer);
                        const requestData = writer.finish();
                        currentWebSocket.send(requestData);
                        //console.log("sent job events request");
                    }
                });
                currentWebSocket.addEventListener('close', () => {
                    if (!ws) {
                        return;
                    }
                    //console.log('ws close');
                    if (!closed) {
                        currentWebSocket.close();
                        closed = true;
                        ws = null;
                    }
                    if (!jobId) {
                        requestDone = true;
                        reject(this.newError('Error submitting image request', 'SYSTEM_ERROR'));
                        return;
                    }
                    if (!requestDone) {
                        setTimeout(() => {
                            connect();
                        }, 1000);
                        return;
                    }
                });
                currentWebSocket.addEventListener('error', () => {
                    console.log('ws error');
                    if (!closed) {
                        currentWebSocket.close();
                        ws = null;
                        closed = true;
                    }
                    if (!jobId) {
                        requestDone = true;
                        reject(this.newError('Error submitting image request', 'SYSTEM_ERROR'));
                        return;
                    }
                    // okay, we have jobId, but did not get response yet, so we need to re-connect
                    if (!requestDone) {
                        setTimeout(() => {
                            connect();
                        }, 1000);
                        return;
                    }
                });
                currentWebSocket.addEventListener('message', async (event) => {
                    //console.log('ws message');
                    const responseData = await event.data as ArrayBuffer;
                    const apiResponse = apiproto.api.APIResponse.decode(new Uint8Array(responseData));
                    if (apiResponse.error) {
                        if (!closed) {
                            currentWebSocket.close();
                            closed = true;
                            ws = null;
                        }
                        requestDone = true;
                        reject(this.newError(apiResponse.error, apiResponse.errorCode ? apiResponse.errorCode : 'API_ERROR'));
                        return;
                    }
                    if (apiResponse.jobCreatedResponse) {
                        // job created
                        jobId = apiResponse.jobCreatedResponse.jobId;
                        //console.log('received jobId ' + jobId);
                        return;
                    }
                    if (apiResponse.jobStatusResponse) {
                        // job status
                        //console.log('job status:' + apiResponse.jobStatusResponse.status);
                        return;
                    }
                    if (apiResponse.generateAiResponse) {
                        if (this.dispatch) {
                            this.dispatch({ type: 'updateTokensLeft', updateTokensLeftPayload: { tokensLeft: apiResponse.generateAiResponse.tokensLeft ? apiResponse.generateAiResponse.tokensLeft as number : 0 } });
                        }
                        // modify image urls
                        const s3Bucket = process.env['REACT_APP_S3_BUCKET'];
                        if (s3Bucket) {
                            if (apiResponse.generateAiResponse.responses) {
                                for (const response of apiResponse.generateAiResponse.responses) {
                                    if (response.images) {
                                        for (const image of response.images) {
                                            if (image.imageUrl) {
                                                const url = new URL(image.imageUrl);
                                                image.imageUrl = s3Bucket + url.pathname;
                                            }
                                            if (image.thumbnailUrl) {
                                                const url = new URL(image.thumbnailUrl);
                                                image.thumbnailUrl = s3Bucket + url.pathname;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (!closed) {
                            currentWebSocket.close();
                            ws = null;
                            closed = true;
                        }
                        requestDone = true;
                        resolve(apiResponse.generateAiResponse);
                        return;
                    }
                });
            };
            connect();
        });
    }

    generateImageAsync(template: string, parameters: { [index: string]: string }, imageRequest: apiproto.api.IGenerateImageRequest, timeout: number, priority: number, numAnswers: number = 1, paidOnly: boolean = false): Promise<apiproto.api.IJobCreatedResponse> {
        return new Promise((resolve, reject) => {
            var requestDone: boolean = false;
            var ws: WebSocket | null = null;

            const connect = () => {
                ws = new WebSocket(this.url.replace('http', 'ws') + '/ws');
                //console.log('connecting to ws');
                const currentWebSocket = ws;
                currentWebSocket.binaryType = 'arraybuffer';
                var closed: boolean = false;
                currentWebSocket.addEventListener('open', () => {
                    //console.log('ws open');
                    // send authentication message
                    {
                        const writer = protobuf.Writer.create();
                        const authMessage = new apiproto.api.APIRequest({ authenticateRequest: { token: this.token } });
                        apiproto.api.APIRequest.encode(authMessage, writer);
                        const requestData = writer.finish();
                        currentWebSocket.send(requestData);
                        //console.log("sent auth request");
                    }
                    // send request
                    const writer = protobuf.Writer.create();
                    const requestMessage = new apiproto.api.APIRequest({ generateAiRequest: { template: template, parameters: parameters, generateImageRequest: imageRequest, numAnswers: numAnswers, paidOnly: paidOnly, timeout: timeout, priority: priority } });
                    apiproto.api.APIRequest.encode(requestMessage, writer);
                    const requestData = writer.finish();
                    currentWebSocket.send(requestData);
                    //console.log("sent image create request");
                });
                currentWebSocket.addEventListener('close', () => {
                    if (!ws) {
                        return;
                    }
                    //console.log('ws close');
                    if (!closed) {
                        currentWebSocket.close();
                        closed = true;
                        ws = null;
                    }
                    if (!requestDone) {
                        reject(this.newError('Error submitting image request', 'SYSTEM_ERROR'));
                        return;
                    }
                });
                currentWebSocket.addEventListener('error', () => {
                    console.log('ws error');
                    if (!closed) {
                        currentWebSocket.close();
                        ws = null;
                        closed = true;
                    }
                    if (!requestDone) {
                        reject(this.newError('Error submitting image request', 'SYSTEM_ERROR'));
                        return;
                    }
                    // okay, we have jobId, but did not get response yet, so we need to re-connect
                });
                currentWebSocket.addEventListener('message', async (event) => {
                    //console.log('ws message');
                    const responseData = await event.data as ArrayBuffer;
                    const apiResponse = apiproto.api.APIResponse.decode(new Uint8Array(responseData));
                    if (apiResponse.error) {
                        if (!closed) {
                            currentWebSocket.close();
                            closed = true;
                            ws = null;
                        }
                        requestDone = true;
                        reject(this.newError(apiResponse.error, apiResponse.errorCode ? apiResponse.errorCode : 'API_ERROR'));
                        return;
                    }
                    if (apiResponse.jobCreatedResponse) {
                        // job created
                        if (!closed) {
                            currentWebSocket.close();
                            closed = true;
                            ws = null;
                        }
                        requestDone = true;
                        //const jobId = apiResponse.jobCreatedResponse.jobId;
                        //console.log('received jobId ' + jobId);
                        resolve(apiResponse.jobCreatedResponse);
                        return;
                    }
                    reject(this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER'));
                });
            };
            connect();
        });
    }

    getJobResult(jobId: string): Promise<apiproto.api.IJobStatusResponse | apiproto.api.IGenerateAIResponse> {
        return new Promise((resolve, reject) => {
            var requestDone: boolean = false;
            var ws: WebSocket | null = null;

            const connect = () => {
                ws = new WebSocket(this.url.replace('http', 'ws') + '/ws');
                //console.log('connecting to ws');
                const currentWebSocket = ws;
                currentWebSocket.binaryType = 'arraybuffer';
                var closed: boolean = false;
                currentWebSocket.addEventListener('open', () => {
                    //console.log('ws open');
                    // send authentication message
                    {
                        const writer = protobuf.Writer.create();
                        const authMessage = new apiproto.api.APIRequest({ authenticateRequest: { token: this.token } });
                        apiproto.api.APIRequest.encode(authMessage, writer);
                        const requestData = writer.finish();
                        currentWebSocket.send(requestData);
                        //console.log("sent auth request");
                    }
                    // send request
                    const writer = protobuf.Writer.create();
                    const requestMessage = new apiproto.api.APIRequest({ getJobEventsRequest: { jobId: jobId } });
                    apiproto.api.APIRequest.encode(requestMessage, writer);
                    const requestData = writer.finish();
                    currentWebSocket.send(requestData);
                    //console.log("sent job events request");
                });
                currentWebSocket.addEventListener('close', () => {
                    if (!ws) {
                        return;
                    }
                    //console.log('ws close');
                    if (!closed) {
                        currentWebSocket.close();
                        closed = true;
                        ws = null;
                    }
                    if (!requestDone) {
                        reject(this.newError('Error submitting image request', 'SYSTEM_ERROR'));
                        return;
                    }
                });
                currentWebSocket.addEventListener('error', () => {
                    console.log('ws error');
                    if (!closed) {
                        currentWebSocket.close();
                        ws = null;
                        closed = true;
                    }
                    if (!requestDone) {
                        reject(this.newError('Error submitting image request', 'SYSTEM_ERROR'));
                        return;
                    }
                    // okay, we have jobId, but did not get response yet, so we need to re-connect
                });
                currentWebSocket.addEventListener('message', async (event) => {
                    //console.log('ws message');
                    const responseData = await event.data as ArrayBuffer;
                    const apiResponse = apiproto.api.APIResponse.decode(new Uint8Array(responseData));
                    if (apiResponse.error) {
                        if (!closed) {
                            currentWebSocket.close();
                            closed = true;
                            ws = null;
                        }
                        requestDone = true;
                        reject(this.newError(apiResponse.error, apiResponse.errorCode ? apiResponse.errorCode : 'API_ERROR'));
                        return;
                    }
                    if (apiResponse.jobStatusResponse) {
                        // job status
                        if (!closed) {
                            currentWebSocket.close();
                            closed = true;
                            ws = null;
                        }
                        requestDone = true;
                        resolve(apiResponse.jobStatusResponse!);
                        return;
                    }
                    if (apiResponse.generateAiResponse) {
                        if (!closed) {
                            currentWebSocket.close();
                            closed = true;
                            ws = null;
                        }
                        requestDone = true;
                        resolve(apiResponse.generateAiResponse);
                        return;
                    }
                    reject(this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER'));
                });
            };
            connect();
        });
    }

    async slackPassthrough(sessionId: string): Promise<apiproto.api.ILoginResponse> {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ loginRequest: { slackPassthrough: { sessionId: sessionId } } }));
        if (!result.loginResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.loginResponse;
    }

    async addSlackTeam(slackAuthCode: string, slackRedirectUrl: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addSlackTeamRequest: { slackAuthCode: slackAuthCode, slackRedirectUrl: slackRedirectUrl } }));
        if (!result.addSlackTeamResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.addSlackTeamResponse;
    }

    async updateSlackUser(update: apiproto.api.ISlackUserUpdate, slackUserInfo: apiproto.api.ISlackUser) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateSlackUserRequest: { update: update, slackUser: slackUserInfo } }));
        if (!result.updateSlackUserResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.updateSlackUserResponse;
    }

    async updateUser(update: apiproto.api.IUserUpdate, userInfo: apiproto.api.IUser) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateUserRequest: { update: update, user: userInfo } }));
        if (!result.updateUserResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.updateUserResponse;
    }

    async getUser(query: apiproto.api.IUserQuery, options: { userId?: string | null | undefined }) {
        if (!options.userId) {
            throw this.newError('Must pass userId', 'INVALID_PARAMETER');
        }
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getUserRequest: { query: query, userId: options.userId } }));
        if (!result.getUserResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.getUserResponse;
    }

    async updatePayment() {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updatePaymentRequest: {} }));
        if (!result.updatePaymentResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.updatePaymentResponse;
    }

    async deletePayment() {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deletePaymentRequest: {} }));
        if (!result.deletePaymentResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.deletePaymentResponse;
    }

    async getAvailablePlans() {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getAvailablePlansRequest: {} }));
        if (!result.getAvailablePlansResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }

        return result.getAvailablePlansResponse;
    }

    async getAvailableModels() {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getAvailableModelsRequest: {} }));
        if (!result.getAvailableModelsResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }

        return result.getAvailableModelsResponse;
    }

    async getJobs(query: apiproto.api.IJobQuery = { limit: 100 }) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({
            getJobsRequest: {
                query: query
            }
        }));
        if (!result.getJobsResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }

        // modify image urls
        const s3Bucket = process.env['REACT_APP_S3_BUCKET'];
        if (s3Bucket) {
            if (result.getJobsResponse && result.getJobsResponse.jobs) {
                for (const job of result.getJobsResponse.jobs) {
                    if (job.output && job.output.responses) {
                        for (const response of job.output.responses) {
                            if (response.images) {
                                for (const image of response.images) {
                                    if (image.imageUrl) {
                                        const url = new URL(image.imageUrl);
                                        image.imageUrl = s3Bucket + url.pathname;
                                    }
                                    if (image.thumbnailUrl) {
                                        const url = new URL(image.thumbnailUrl);
                                        image.thumbnailUrl = s3Bucket + url.pathname;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return result.getJobsResponse;
    }

    async getGeneratedImages(query: apiproto.api.IGeneratedImageQuery) {
        // if mediaTypes is not set, by default it only includes images, this is for backwards compatibility
        // query.mediaTypes = { includeAudio: true, includeImage: true, includeVideo: true };
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getGeneratedImagesRequest: { query: query } }));
        if (!result.getGeneratedImagesResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }

        // modify image urls
        const s3Bucket = process.env['REACT_APP_S3_BUCKET'];
        if (s3Bucket) {
            if (result.getGeneratedImagesResponse && result.getGeneratedImagesResponse.generatedImages) {
                for (const image of result.getGeneratedImagesResponse.generatedImages) {
                    if (image.imageUrl) {
                        const url = new URL(image.imageUrl);
                        image.imageUrl = s3Bucket + url.pathname;
                    }
                    // if we have a thumbnail url, we need to modify it as well
                    if (image.thumbnailUrl && image.thumbnailUrl.length > 0) {
                        const url = new URL(image.thumbnailUrl);
                        image.thumbnailUrl = s3Bucket + url.pathname;
                    }
                }
            }
        }

        return result.getGeneratedImagesResponse;
    }

    async getSegment(imageId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getSegmentRequest: { fileId: imageId } }));
        if (!result.getSegmentResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        if (this.dispatch) {
            this.dispatch({ type: 'updateTokensLeft', updateTokensLeftPayload: { tokensLeft: result.getSegmentResponse.tokensLeft ? result.getSegmentResponse.tokensLeft as number : 0 } });
        }
        return result.getSegmentResponse;
    }

    async getMask(imageId: string, maskMethod: string = "") {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getMaskRequest: { fileId: imageId, maskMethod: maskMethod } }));
        if (!result.getMaskResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        if (this.dispatch) {
            this.dispatch({ type: 'updateTokensLeft', updateTokensLeftPayload: { tokensLeft: result.getMaskResponse.tokensLeft ? result.getMaskResponse.tokensLeft as number : 0 } });
        }
        return result.getMaskResponse;
    }

    async getCaption(imageId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getCaptionRequest: { fileId: imageId } }));
        if (!result.getCaptionResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }

        return result.getCaptionResponse;
    }

    async updateGeneratedImage(update: apiproto.api.IUpdateGeneratedImage, generatedImage: apiproto.api.IGeneratedImage) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateGeneratedImageRequest: { update: update, generatedImage: generatedImage } }));
        if (!result.updateGeneratedImageResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.updateGeneratedImageResponse;
    }

    async sendValidationCode(emailAddress: string, newPassword: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ sendValidationCodeRequest: { emailAddress: emailAddress, newPassword: newPassword } }));
        if (!result.sendValidationCodeResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.sendValidationCodeResponse;
    }

    async validate(emailAddress: string, validationCode: string, newPassword: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ validateRequest: { emailAddress: emailAddress, validationCode: validationCode, newPassword: newPassword } }));
        if (!result.validateResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.validateResponse;
    }

    async getFiles(parentFileId: string, query: apiproto.api.IFileQuery) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getFilesRequest: { parentFileId: parentFileId, query: query } }));
        if (!result.getFilesResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.getFilesResponse;
    }


    async getFileUrl(fileId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getFileUrlRequest: { fileId: fileId } }));
        if (!result.getFileUrlResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.getFileUrlResponse;
    }

    async deleteFile(fileId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteFileRequest: { fileId: fileId } }));
        if (!result.deleteFileResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.deleteFileResponse;
    }

    async deleteGeneratedImage(imageId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteGeneratedImageRequest: { imageId: imageId } }));
        if (!result.deleteGeneratedImage) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.deleteGeneratedImage;
    }

    async createComprehendFromUrl(parentFileId: string, url: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createComprehendRequest: { parentFileId: parentFileId, url: url } }));
        if (!result.createComprehendResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.createComprehendResponse;
    }

    async checkComprehendTaskStatus(taskId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ checkComprehendTaskStatusRequest: { taskId: taskId } }));
        if (!result.checkComprehendTaskStatusResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        if (result.checkComprehendTaskStatusResponse.status === apiproto.api.ComprehendTaskStatus.Completed) {
            // update tokens left if task completed
            if (this.dispatch) {
                this.dispatch({ type: 'updateTokensLeft', updateTokensLeftPayload: { tokensLeft: result.checkComprehendTaskStatusResponse.tokensLeft ? result.checkComprehendTaskStatusResponse.tokensLeft as number : 0 } });
            }
        }

        return result.checkComprehendTaskStatusResponse;
    }

    async createProject(parentFileId: string, name: string, projectInfo: apiproto.api.IProjectInfo) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createProjectRequest: { parentFileId: parentFileId, name: name, projectInfo: projectInfo } }));
        if (!result.createProjectResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.createProjectResponse;
    }

    async createFolder(parentFileId: string, name: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createFolderRequest: { parentFileId: parentFileId, name: name } }));
        if (!result.createFolderResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.createFolderResponse;
    }

    async updateFile(update: apiproto.api.IFileUpdate, fileInfo: apiproto.api.IFile) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateFileRequest: { update: update, file: fileInfo } }));
        if (!result.updateFileResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.updateFileResponse;
    }

    async updateProject(fileId: string, projectInfo: apiproto.api.IProjectInfo) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateProjectRequest: { fileId: fileId, projectInfo: projectInfo } }));
        if (!result.updateProjectResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.updateProjectResponse;
    }

    async executeAdminTask(taskType: number) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ executeAdminTaskRequest: { taskType: taskType } }));
        if (!result.executeAdminTaskResponse) {
            throw this.newError('Unexpected response from server', 'INVALID_RESPONSE_FROM_SERVER');
        }
        return result.executeAdminTaskResponse;
    }

}

export const useAPI = () => {
    const { state, dispatch } = React.useContext(AuthContext);
    return React.useMemo(() => {
        const api = new API(dispatch);
        if (state.token && state.userId && state.expirationTime && state.expirationTime > (new Date()).getTime()) {
            api.setToken(state.token, state.anonymous);
        }
        return api;
    }, [state, dispatch])
}

