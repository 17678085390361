


import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Paper, Radio, RadioGroup } from "@mui/material";
import { useAPI } from "../../utils/useAPI";
import { RequireAuth, useUser } from "../../utils/useUser";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Stripe } from "@stripe/stripe-js/";
import { Elements } from "@stripe/react-stripe-js";
import ProductCheckout from "./productCheckout";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as apiproto from "../../apiproto";
import { DialogMessageBox } from "../../components/DialogMessageBox";
import { sendEvent, sendPurchaseEvent, tdrAlert } from "../../utils/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import PageTitle from "../../layout/PageTitle";
import Layout from "../../layout/Layout";
import RefreshIcon from '@mui/icons-material/Refresh';
import React from 'react';
import { Typography } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LayersIcon from '@mui/icons-material/Layers';
import PhotoIcon from '@mui/icons-material/Photo';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { match } from "assert";


type StripeKeys = {
    clientSecret: string;
    publishableKey: string;
};
const ProductPurchasev3 = () => {
    const api = useAPI();
    const user = useUser();
    const [search] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState<string | undefined | null>(null);
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const [stripeKeys, setStripeKeys] = useState<StripeKeys>({ clientSecret: "", publishableKey: "" });
    const [existingPayment, setExistingPayment] = useState("");
    const [products, setProducts] = useState<apiproto.api.IPlanInfo[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<apiproto.api.IPlanInfo>();
    const [autoRecharge, setAutoRecharge] = useState(true);
    const [accountType, setAccountType] = useState("individual");
    const [prevSavedData, setPrevSavedData] = useState({ team: "individual", plan: "" });
    const [planRadioValue, setPlanRadioValue] = useState("topup_130k")
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });
    const [updatePaymentInfo, setUpdatePaymentInfo] = useState(false);
    const [userInfo, setUserInfo] = useState<apiproto.api.IUser | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [readyToRender, setReadyToRender] = useState(false);
    const [refreshTime, setRefreshTime] = useState(0);
    const [page, setPage] = useState(0);
    const redirect = new URLSearchParams(search).get('redirect');

    const handleRechargeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoRecharge(event.target.checked);
    };
    /*
        const handleAccountTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setAccountType((event.target as HTMLInputElement).value);
        };
    */
    const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlanRadioValue((event.target as HTMLInputElement).value);
    };
    const paymentSuccess = () => {
        (async () => {
            const value = ((selectedPlan && selectedPlan.amount) ? selectedPlan?.amount : 0)
            const planId = ((selectedPlan && selectedPlan.planId) ? selectedPlan?.planId : "")
            sendEvent('Purchase', 'Top up', planId, value);
            sendEvent('Upgrade', 'UpgradeSuccess', planId, value);
            sendEvent('Upgrade', 'UpgradeSuccess' + planId, planId, value);
            const now = Math.floor(Date.now() / 1000);
            sendPurchaseEvent(now.toString(), value, planId);
            setUpdatePaymentInfo(false);
            updateUser();
            setupPayment();
        })();
    }

    const refreshToken = () => {
        setRefreshTime(refreshTime + 1);
    }

    const updateUser = () => {
        (async () => {
            setIsLoading(true);
            try {

                const userInfo = await api.getUser({ credentials: { slackUser: {} } }, { userId: user.userId });

                if (userInfo && userInfo.user && accountType && selectedPlan && selectedPlan.planId && userInfo !== null) {

                    const tmpuser = userInfo.user;
                    if (tmpuser.credentials && tmpuser.credentials.length > 0 && tmpuser.credentials![0].slackUser) {
                        tmpuser.credentials![0].slackUser!.teamPaymentUser = (accountType === "team" ? true : false);
                    }
                    if (selectedPlan.planId === "0d") {
                        tmpuser.topupPlanId = "";
                    }
                    else {
                        tmpuser.topupPlanId = selectedPlan.planId;
                    }
                    await api.updateUser({ topupPlanId: true }, tmpuser);
                    if (tmpuser.credentials && tmpuser.credentials.length > 0 && tmpuser.credentials![0].slackUser) {
                        await api.updateSlackUser({ teamPaymentUser: true }, tmpuser.credentials![0].slackUser!);
                    }
                    setUserInfo(tmpuser);
                    setIsLoading(false);
                    setPrevSavedData({ team: accountType, plan: tmpuser.topupPlanId });
                    setDialogMessage({ title: 'Plan Update Success', message: selectedPlan.planId === "0d" ? "You have successfully cancel your plan. Your subscription will not be renewed in the next subscription period." : "You have successfully update changes to your plan. Your new payment will only be charged in the next subscription period or during a AI units recharge when your AI units falls below a threshold.", meta: { msg: "paymentSuccess" }, confirm: false });
                }
            } catch (e: any) {
                if (e.name === "TEAM_ADMIN_EXISTED") {
                    setDialogMessage({ title: 'Plan Update Error', message: "There can only be one person (the admin) who top up for the team in a workspace. Please contact the admin to switch their account plan to Individual. Meanwhile, your settings will now be set to Individual.", meta: {}, confirm: false });

                }
                else {
                    setDialogMessage({ title: 'Plan update error', message: "An error occurred during the purchase of your selected plan.", meta: {}, confirm: false });
                    tdrAlert(e.name + " " + e.message)
                }
            }
            setIsLoading(false);
            return null;
        })();
    }

    const paymentCancelled = () => {
        setPage(0);
        if (!existingPayment) {
            // This is a new payment and so if user hits cancel, it should just return
            cancelWindow();
            return;
        }
        setUpdatePaymentInfo(false);
        sendEvent('Upgrade', 'UpgradeCancel', user.tokensLeft + "");

        //        closeWindow();
    }

    const navigate = useNavigate();
    const cancelWindow = () => {
        if (page === 1) {
            setPage(0)
            return;
        }
        // This cancel is different since it comes from cancel
        if (redirect === "editor") {
            // Special handling: This is flutter app so can't just go use router navigate
            let host = window.location.origin;
            if (host.indexOf("dev.shared") > 0) {
                // since dev does not have route to editor
                host = "https://chat.timepiper.com";
            }
            // Editor is not part of navigation route and hence a window.location.href
            const url = host + "/editor";
            window.location.href = url;
            return;
        }
        else {
            navigate(-1);
        }
    }
    const closeWindow = () => {

        setDialogMessage({ title: '', message: "", meta: {}, confirm: false });
        sendEvent('Upgrade', 'UpgradeClose', user.tokensLeft + "");
        const message: any = dialogMessage.meta;
        if (message && message.msg === "paymentSuccess") {
            // This is a success so we are done.
            if (document.referrer !== null && document.referrer.indexOf("/editor") > 0) {
                // This handles when click comes from flutter editor.
                // We could technically close the tab too? instead of refreshing since
                // the other tab with editor may still be there?
                // window.close();
                window.location.href = document.referrer;
                return;
            }
            else if (redirect === "editor") {
                // Special handling: This is flutter app so can't just go use router navigate
                let host = window.location.origin;
                if (host.indexOf("dev.shared") > 0) {
                    // since dev does not have route to editor
                    host = "https://chat.timepiper.com";
                }
                // Editor is not part of navigation route and hence a window.location.href
                const url = host + "/editor";
                window.location.href = url;
                return;
            }
            else {
                let host = window.location.origin;
                window.location.href = host + "/ai-art";
            }
        }
        else {
            navigate(-1)
        }

        //        navigate("/profile");
    }

    const confirmWindow = () => {
    }

    const handleSubmit = () => {

    }

    const updatePaymentUI = () => {
        setUpdatePaymentInfo(true);
        setPage(1);
    }

    const setupPayment = async () => {
        try {
            if (user !== null && user.authenticated) {
                // Only need to set up if user is authenticated                
                const result = await api.updatePayment()
                if (result !== null && result.publishableKey && result.publishableKey?.length > 0) {
                    const stripeKeysResult = { clientSecret: result.paymentClientSecret!, publishableKey: result.publishableKey! };
                    setStripeKeys(stripeKeysResult)
                    if (result.paymentClientSecret && result.paymentClientSecret.length > 0 && stripeKeysResult.publishableKey.length > 0) {
                        setStripePromise(loadStripe
                            (stripeKeysResult.publishableKey!, {
                            }));
                    }
                    if (result !== null && result.existingPaymentDescription && result.existingPaymentDescription.length > 0) {
                        setExistingPayment(result.existingPaymentDescription);
                    }
                    else {
                        setExistingPayment("");
                    }
                }
                setReadyToRender(true);
            }
        }
        catch (err: any) {
            tdrAlert(JSON.stringify(err));
        }

    }
    // Define subscription plans
    const planFeatures = [
        {
            planid: "0d",
            name: "Free",
            price: "$0/month",
            storage: "100MB",
            features: [
                true, // AI Units
                true, // Access to AI Photo editor
                false, // Access to video models
                false, // Access to audio & music models
                false, // Private generation
                false, // Faster generations
                false, // Priority generations
                false, // Multiple pending requests
                false, // Download with no watermark
                false, // Commercial use
            ],
        },
        {
            planid: "10d",
            name: "Standard",
            price: "$10/month",
            storage: "5GB",
            features: [
                true, // AI Units
                true, // Access to AI Photo editor
                true, // Access to video models
                true, // Access to audio & music models
                true, // Private generation
                true, // Download with no watermark
                false, // Faster generations
                false, // Priority generations              
                false, // Multiple pending requests
                false, // Commercial use
            ],
        },
        {
            planid: "20d",
            name: "Pro",
            price: "$20/month",
            storage: "20GB",
            features: [
                true, // AI Units
                true, // Access to AI Photo editor
                true, // Access to video models
                true, // Access to audio & music models
                true, // Private generation
                true, // Download with no watermark
                true, // Faster generations
                true, // Priority generations              
                true, // Multiple pending requests
                true, // Commercial use

            ],
        },
        {
            planid: "50d",
            name: "Diamond",
            price: "$50/month",
            storage: "100GB",
            features: [
                true, // Image generation
                true, // Access to AI Photo editor
                true, // Access to video models
                true, // Access to audio & music models
                true, // Private generation
                true, // Download with no watermark
                true, // Faster generations
                true, // Priority generations              
                true, // Multiple pending requests
                true, // Commercial use
            ],
        },
    ];

    const featureDescriptions = [
        "Image generation",
        "Access to AI Photo editor",
        "Access to video models",
        "Access to audio & music models",
        "Private generation",
        "Download with no watermark",
        "Faster generations",
        "Priority generations",
        "Multiple pending requests",
        "Commercial use"

    ];


    const handlePlanSelect = (selectedPlanId: string) => {
        let planId = selectedPlanId;
        if (prevSavedData.plan === planId && selectedPlanId !== "0d") {
            // Choose to cancel current plan
            planId = "0d";
        }
        const plan = products.filter((obj: apiproto.api.IPlanInfo) => obj.planId === planId)
        if (plan && plan.length > 0) {
            setSelectedPlan(plan[0]);

        }
        setPage(1);
    };
    useEffect(() => {
        if (user.authenticated) {
            setErrorMessage("");
            setupPayment();
            setIsLoading(true);
            // This is where we get user information to update payment, product plan, recharge choice, etc
            (async () => {
                const productInfo = await api.getAvailablePlans();
                if (productInfo && productInfo !== null && productInfo.plans) {
                    //    if (existingPayment.length > 0) {
                    productInfo.plans.push({ planId: "0d", amount: 0, tokens: 0 })
                    //  }
                    productInfo.plans.sort((a, b) => (
                        (a.amount === null || b.amount === null || a.amount === undefined || b.amount === undefined) ? 0 :
                            ((a.amount > b.amount) ? 1 : ((b.amount > a.amount) ? -1 : 0))));
                    setProducts(productInfo.plans);
                    //                    tdrAlert(JSON.stringify(productInfo.plans))
                    setSelectedPlan(productInfo.plans[0])
                    if (productInfo.plans[0].planId) {
                        setPlanRadioValue(productInfo.plans[0].planId)
                    }
                }

                const userInfo = await api.getUser({ credentials: { slackUser: {} } }, { userId: user.userId });
                if (userInfo && userInfo.user) {
                    setUserInfo(userInfo.user);
                }
                let team = "individual";
                setAccountType("individual")

                if (userInfo.user) {
                    if (userInfo.user.credentials && userInfo.user.credentials.length > 0 && userInfo.user.credentials![0].slackUser && userInfo.user.credentials![0].slackUser!.teamPaymentUser) {
                        setAccountType("team")
                        team = "team";
                    }
                    else {
                        setAccountType("individual")
                    }

                    let planid = userInfo.user.topupPlanId;

                    if (planid === "") {
                        // This is kind of hack unless server supports some 0 token plan and not just blank planid
                        planid = "0d";
                        if (productInfo.plans && productInfo.plans.length > 2) {
                            // IF no plan we set default plan to the second plan which is $10
                            planid = productInfo.plans[0].planId;
                        }
                    }

                    if (planid && planid !== null) {
                        setPrevSavedData({ team: team, plan: planid });
                        const plan = productInfo.plans?.filter((obj: apiproto.api.IPlanInfo) => obj.planId === planid)
                        if (plan && plan.length > 0) {
                            setSelectedPlan(plan[0]);
                            if (plan[0].planId) {

                                setPlanRadioValue(plan[0].planId)
                            }

                        }
                    }

                    /** A/B Testing
                     * Simplify choice
                     * 
                     * 
                     */
                    if (userInfo.user && !userInfo.user.isPaid) {

                    }
                }

                setIsLoading(false);
            })();
        }

    }, [user.userId, user.authenticated, refreshTime]);

    useEffect(() => {

        const chosenProduct = products.filter((obj: apiproto.api.IPlanInfo) => obj.planId === planRadioValue);
        if (chosenProduct && chosenProduct.length > 0) {
            setSelectedPlan(chosenProduct[0]);
        }
    }, [planRadioValue]);

    useEffect(() => {
        // Send signal that upgrade was shown
        sendEvent('Upgrade', 'UpgradeShown', user.tokensLeft + "");
        //sendPurchaseEvent("testid2", 1, "testplan2");

    }, []);

    const FeatureList = () => {
        if (!selectedPlan) return <div></div>;
        const matchedPlan = planFeatures.find(p => p.planid === selectedPlan.planId);

        return (

            <div>
                <h3 className="plan-name">{matchedPlan?.name}</h3>
                <p className="plan-price">${selectedPlan.planId === "0d" ? 0 : selectedPlan.amount + "/month"}</p>
                <ul className="plan-features">
                    <li key={"cr" + selectedPlan.planId} className="feature-item">
                        <span
                            className={"feature-icon feature-available"}
                        >
                            ✔
                        </span>
                        {(Math.max(60, Number(selectedPlan.tokens || "60") / 10000)) + ""} AI Units/month
                    </li>
                    <li key={"cr" + selectedPlan.planId} className="feature-item">
                        <span
                            className={"feature-icon feature-available"}
                        >
                            ✔
                        </span>
                        {matchedPlan?.storage} storage
                    </li>
                    {matchedPlan
                        ? matchedPlan.features.map((hasFeature, index) => (
                            <li key={index} className="feature-item">
                                <span
                                    className={`feature-icon ${hasFeature ? "feature-available" : "feature-unavailable"}`}
                                >
                                    {hasFeature ? "✔" : "✘"}
                                </span>
                                {featureDescriptions[index]}
                            </li>
                        ))
                        : <li>No features available</li>
                    }
                </ul>
            </div>
        )
    };



    return (
        <div>
            < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={closeWindow} onConfirm={confirmWindow} visible={dialogMessage.title.length > 0} />
            <RequireAuth disAllowAnonymous={true}>
                <Layout headerBtn footer={3} hideHeader={redirect === "editor" ? true : false}>
                    <PageTitle pageTitle="Billing Plan for AI Units" />
                    {readyToRender ?
                        <ValidatorForm id="payment-form" onSubmit={handleSubmit}>
                            {page === 0 ?
                                <div className="plan-container">
                                    <h1 className="plan-title">Choose your plan</h1>
                                    <div className="plan-grid">
                                        {products.map((plan) => {
                                            // Find the corresponding features using plan.planid
                                            const matchedPlan = planFeatures.find(p => p.planid === plan.planId);

                                            return ((plan.planId !== "5d" && ((prevSavedData.plan !== "0d" && plan.planId !== "0d") || prevSavedData.plan === "0d")) &&
                                                <div
                                                    className={`plan-card ${selectedPlan === plan.planId ? "selected" : ""}`}
                                                >
                                                    <h3 className="plan-name">{matchedPlan?.name}</h3>
                                                    <p className="plan-price">${plan.planId === "0d" ? 0 : plan.amount + "/month"}</p>
                                                    <ul className="plan-features">
                                                        <li key={"cr" + plan.planId} className="feature-item">
                                                            <span
                                                                className={"feature-icon feature-available"}
                                                            >
                                                                ✔
                                                            </span>
                                                            {(Math.max(60, Number(plan.tokens || "60") / 10000)) + ""} AI units/month
                                                        </li>
                                                        <li key={"cr" + plan.planId} className="feature-item">
                                                            <span
                                                                className={"feature-icon feature-available"}
                                                            >
                                                                ✔
                                                            </span>
                                                            {matchedPlan?.storage} storage
                                                        </li>
                                                        {matchedPlan
                                                            ? matchedPlan.features.map((hasFeature, index) => (
                                                                <li key={index} className="feature-item">
                                                                    <span
                                                                        className={`feature-icon ${hasFeature ? "feature-available" : "feature-unavailable"}`}
                                                                    >
                                                                        {hasFeature ? "✔" : "✘"}
                                                                    </span>
                                                                    {featureDescriptions[index]}
                                                                </li>
                                                            ))
                                                            : <li>No features available</li>
                                                        }
                                                    </ul>
                                                    <button
                                                        className={prevSavedData.plan === plan.planId ? "subscribe-button-disabled" : "subscribe-button"}
                                                        onClick={() => handlePlanSelect(plan.planId || "0d")}
                                                        disabled={prevSavedData.plan === "0d" && plan.planId === "0d"}
                                                    >
                                                        {prevSavedData.plan === plan.planId ? (prevSavedData.plan === "0d" ? "Current Plan" : "Cancel Current Plan") : "Subscribe to " + matchedPlan?.name}
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>


                                </div>
                                :
                                <div className="plan-container">
                                    <br />
                                    <Paper elevation={6} sx={{ maxWidth: 1280, width: "100%", padding: '30px' }}>
                                        <p style={{ textAlign: 'right', display: 'none' }}>
                                            {isLoading ? "" : (userInfo?.tokensLeft ? (Number((+userInfo?.tokensLeft)) / 10000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }) : "0")} AI Units Left
                                            <LoadingButton
                                                sx={{ mb: 1 }}
                                                size="large"
                                                variant="text"
                                                loading={isLoading}
                                                onClick={refreshToken}
                                                startIcon={<RefreshIcon />} />
                                        </p>
                                        <Row>
                                            {(!(existingPayment && !updatePaymentInfo) && readyToRender) ?
                                                < Col md="7" style={{ border: '1px solid gray', padding: 20 }} >
                                                    <Row>
                                                        <Col>
                                                            <div className="form-group form-input">
                                                                {errorMessage && (
                                                                    <p className="form-error">
                                                                        Error:{errorMessage}
                                                                    </p>)
                                                                }
                                                                <Elements options={{
                                                                    clientSecret: stripeKeys.clientSecret, appearance: {
                                                                        theme: 'stripe', variables: {
                                                                            colorPrimary: '#0570de',
                                                                            colorBackground: '#ffffff',
                                                                            colorText: '#30313d',
                                                                            colorDanger: '#df1b41',
                                                                            fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                                                            fontSizeBase: '18px',
                                                                            spacingUnit: '4px',
                                                                            borderRadius: '4px',
                                                                            // See all possible variables below
                                                                        }
                                                                    }

                                                                }} stripe={stripePromise} key={stripeKeys.clientSecret}>
                                                                    <ProductCheckout planSelected={selectedPlan} update={updatePaymentInfo} stripeKeys={stripeKeys} existingPayment={existingPayment} paymentCancel={paymentCancelled} paymentSuccess={paymentSuccess} />
                                                                </Elements>
                                                            </div>

                                                        </Col>

                                                    </Row>
                                                </Col> :
                                                < Col md="7" style={{ textAlign: 'center', margin: 'auto' }} >
                                                    <video src="/video/video-cogvideox.mp4" width="640" poster="/images/video/video-poster-cogvideox.jpg"
                                                        playsInline={true} autoPlay={true} muted={true} loop={true} />
                                                </Col>

                                            }

                                            <Col md="5" style={{ padding: "0px" }} >
                                                <div
                                                    className={`plan-card-billing`}
                                                >
                                                    <FeatureList />
                                                    <img alt="Top up" width="180" src="/images/lightbulb.jpg" />
                                                </div>
                                            </Col>


                                        </Row>
                                        {(existingPayment && !updatePaymentInfo) &&
                                            <Row>
                                                <Col>
                                                    <hr />
                                                    <Button
                                                        sx={{ float: 'right', mr: 2 }}
                                                        size="large"
                                                        variant="text"
                                                        onClick={cancelWindow}
                                                        disabled={isLoading}>
                                                        Cancel
                                                    </Button>

                                                    <LoadingButton
                                                        sx={{ float: 'right', mr: 2, mb: 2 }}
                                                        size="large"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        onClick={updateUser}
                                                        disabled={existingPayment.length > 0 && prevSavedData.team === accountType && (selectedPlan && prevSavedData.plan === selectedPlan.planId)}
                                                        hidden={false}>
                                                        {existingPayment ? "Change to Selected Plan" : "Save"}
                                                    </LoadingButton>
                                                    <br /><br />
                                                </Col>
                                            </Row>
                                        }
                                        <div style={{ width: "100%", padding: "5px", maxWidth: "1200px", fontSize: "0.75rem" }}>
                                            {(selectedPlan?.amount && selectedPlan.tokens) ?
                                                <div style={{ marginBottom: '15px' }}>
                                                    {existingPayment.length > 0 ? <div><b>{existingPayment}</b> will be charged <b>{selectedPlan.amount.toLocaleString("en-US", { style: "currency", currency: "USD" })}</b> to top up <b>{(+selectedPlan?.tokens / 10000).toLocaleString()}</b> AI Units when it falls below a threshold. You can {!updatePaymentInfo ? (<Link style={{ textDecoration: "underline" }} to="#" onClick={updatePaymentUI}>update payment information</Link>) : "update payment info"} if your payment information has changed.</div> : <div> You will be charged <b>{selectedPlan.amount.toLocaleString("en-US", { style: "currency", currency: "USD" })}</b> per month for the subscription. When your monthly AI credits run out before your next subscription renewal date, you will automatically be charged to top up with  <b>{(+selectedPlan?.tokens / 10000).toLocaleString()}</b> AI Units rollover AI Units. AI Units are used for image and video generations as well as other AI related services using AI models.</div>}
                                                </div> :
                                                (selectedPlan?.planId === "0d" ?
                                                    <div>
                                                        You are cancelling your current plan. Your subscription will not be renewed in the next renewal period. Your storage will automatically be reduced and your assets will be deleted. Your AI Units will not be topped up when your balance runs to zero. This could cause a disruption to your use of the service. We recommend that you set a minimum paid plan.
                                                    </div> :
                                                    <div>
                                                        You can top up your AI Units balance by adding a credit card to your payment information.
                                                    </div>)
                                            }
                                        </div>
                                        <br /><br />

                                    </Paper>
                                </div>
                            }
                        </ValidatorForm> :
                        <div id="loading-main">
                            <div id="loading-anim">
                                <div className="cssload-loading">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                </div>
                            </div>
                        </div>
                    }
                </Layout>
            </RequireAuth>
        </div >
    )
}

export default ProductPurchasev3;
