/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const api = $root.api = (() => {

    /**
     * Namespace api.
     * @exports api
     * @namespace
     */
    const api = {};

    api.MemberEntityQuery = (function() {

        /**
         * Properties of a MemberEntityQuery.
         * @memberof api
         * @interface IMemberEntityQuery
         * @property {api.IUserQuery|null} [user] MemberEntityQuery user
         * @property {api.IGroupQuery|null} [group] MemberEntityQuery group
         */

        /**
         * Constructs a new MemberEntityQuery.
         * @memberof api
         * @classdesc Represents a MemberEntityQuery.
         * @implements IMemberEntityQuery
         * @constructor
         * @param {api.IMemberEntityQuery=} [properties] Properties to set
         */
        function MemberEntityQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberEntityQuery user.
         * @member {api.IUserQuery|null|undefined} user
         * @memberof api.MemberEntityQuery
         * @instance
         */
        MemberEntityQuery.prototype.user = null;

        /**
         * MemberEntityQuery group.
         * @member {api.IGroupQuery|null|undefined} group
         * @memberof api.MemberEntityQuery
         * @instance
         */
        MemberEntityQuery.prototype.group = null;

        /**
         * Creates a new MemberEntityQuery instance using the specified properties.
         * @function create
         * @memberof api.MemberEntityQuery
         * @static
         * @param {api.IMemberEntityQuery=} [properties] Properties to set
         * @returns {api.MemberEntityQuery} MemberEntityQuery instance
         */
        MemberEntityQuery.create = function create(properties) {
            return new MemberEntityQuery(properties);
        };

        /**
         * Encodes the specified MemberEntityQuery message. Does not implicitly {@link api.MemberEntityQuery.verify|verify} messages.
         * @function encode
         * @memberof api.MemberEntityQuery
         * @static
         * @param {api.IMemberEntityQuery} message MemberEntityQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberEntityQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.UserQuery.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupQuery.encode(message.group, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberEntityQuery message, length delimited. Does not implicitly {@link api.MemberEntityQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberEntityQuery
         * @static
         * @param {api.IMemberEntityQuery} message MemberEntityQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberEntityQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberEntityQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberEntityQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberEntityQuery} MemberEntityQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberEntityQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberEntityQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.api.UserQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.group = $root.api.GroupQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberEntityQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberEntityQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberEntityQuery} MemberEntityQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberEntityQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberEntityQuery message.
         * @function verify
         * @memberof api.MemberEntityQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberEntityQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.UserQuery.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.GroupQuery.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates a MemberEntityQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberEntityQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberEntityQuery} MemberEntityQuery
         */
        MemberEntityQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberEntityQuery)
                return object;
            let message = new $root.api.MemberEntityQuery();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.MemberEntityQuery.user: object expected");
                message.user = $root.api.UserQuery.fromObject(object.user);
            }
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.MemberEntityQuery.group: object expected");
                message.group = $root.api.GroupQuery.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberEntityQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberEntityQuery
         * @static
         * @param {api.MemberEntityQuery} message MemberEntityQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberEntityQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.user = null;
                object.group = null;
            }
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.UserQuery.toObject(message.user, options);
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.GroupQuery.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this MemberEntityQuery to JSON.
         * @function toJSON
         * @memberof api.MemberEntityQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberEntityQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberEntityQuery;
    })();

    api.GroupQuery = (function() {

        /**
         * Properties of a GroupQuery.
         * @memberof api
         * @interface IGroupQuery
         * @property {api.IMemberQuery|null} [membersOf] GroupQuery membersOf
         * @property {api.IMemberQuery|null} [members] GroupQuery members
         */

        /**
         * Constructs a new GroupQuery.
         * @memberof api
         * @classdesc Represents a GroupQuery.
         * @implements IGroupQuery
         * @constructor
         * @param {api.IGroupQuery=} [properties] Properties to set
         */
        function GroupQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GroupQuery membersOf.
         * @member {api.IMemberQuery|null|undefined} membersOf
         * @memberof api.GroupQuery
         * @instance
         */
        GroupQuery.prototype.membersOf = null;

        /**
         * GroupQuery members.
         * @member {api.IMemberQuery|null|undefined} members
         * @memberof api.GroupQuery
         * @instance
         */
        GroupQuery.prototype.members = null;

        /**
         * Creates a new GroupQuery instance using the specified properties.
         * @function create
         * @memberof api.GroupQuery
         * @static
         * @param {api.IGroupQuery=} [properties] Properties to set
         * @returns {api.GroupQuery} GroupQuery instance
         */
        GroupQuery.create = function create(properties) {
            return new GroupQuery(properties);
        };

        /**
         * Encodes the specified GroupQuery message. Does not implicitly {@link api.GroupQuery.verify|verify} messages.
         * @function encode
         * @memberof api.GroupQuery
         * @static
         * @param {api.IGroupQuery} message GroupQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.membersOf != null && Object.hasOwnProperty.call(message, "membersOf"))
                $root.api.MemberQuery.encode(message.membersOf, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.members != null && Object.hasOwnProperty.call(message, "members"))
                $root.api.MemberQuery.encode(message.members, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GroupQuery message, length delimited. Does not implicitly {@link api.GroupQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GroupQuery
         * @static
         * @param {api.IGroupQuery} message GroupQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GroupQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.GroupQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GroupQuery} GroupQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GroupQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.membersOf = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.members = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GroupQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GroupQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GroupQuery} GroupQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GroupQuery message.
         * @function verify
         * @memberof api.GroupQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GroupQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.membersOf != null && message.hasOwnProperty("membersOf")) {
                let error = $root.api.MemberQuery.verify(message.membersOf);
                if (error)
                    return "membersOf." + error;
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                let error = $root.api.MemberQuery.verify(message.members);
                if (error)
                    return "members." + error;
            }
            return null;
        };

        /**
         * Creates a GroupQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GroupQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GroupQuery} GroupQuery
         */
        GroupQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GroupQuery)
                return object;
            let message = new $root.api.GroupQuery();
            if (object.membersOf != null) {
                if (typeof object.membersOf !== "object")
                    throw TypeError(".api.GroupQuery.membersOf: object expected");
                message.membersOf = $root.api.MemberQuery.fromObject(object.membersOf);
            }
            if (object.members != null) {
                if (typeof object.members !== "object")
                    throw TypeError(".api.GroupQuery.members: object expected");
                message.members = $root.api.MemberQuery.fromObject(object.members);
            }
            return message;
        };

        /**
         * Creates a plain object from a GroupQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GroupQuery
         * @static
         * @param {api.GroupQuery} message GroupQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GroupQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.membersOf = null;
                object.members = null;
            }
            if (message.membersOf != null && message.hasOwnProperty("membersOf"))
                object.membersOf = $root.api.MemberQuery.toObject(message.membersOf, options);
            if (message.members != null && message.hasOwnProperty("members"))
                object.members = $root.api.MemberQuery.toObject(message.members, options);
            return object;
        };

        /**
         * Converts this GroupQuery to JSON.
         * @function toJSON
         * @memberof api.GroupQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GroupQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GroupQuery;
    })();

    api.MemberObjectQuery = (function() {

        /**
         * Properties of a MemberObjectQuery.
         * @memberof api
         * @interface IMemberObjectQuery
         * @property {api.IGroupQuery|null} [group] MemberObjectQuery group
         */

        /**
         * Constructs a new MemberObjectQuery.
         * @memberof api
         * @classdesc Represents a MemberObjectQuery.
         * @implements IMemberObjectQuery
         * @constructor
         * @param {api.IMemberObjectQuery=} [properties] Properties to set
         */
        function MemberObjectQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberObjectQuery group.
         * @member {api.IGroupQuery|null|undefined} group
         * @memberof api.MemberObjectQuery
         * @instance
         */
        MemberObjectQuery.prototype.group = null;

        /**
         * Creates a new MemberObjectQuery instance using the specified properties.
         * @function create
         * @memberof api.MemberObjectQuery
         * @static
         * @param {api.IMemberObjectQuery=} [properties] Properties to set
         * @returns {api.MemberObjectQuery} MemberObjectQuery instance
         */
        MemberObjectQuery.create = function create(properties) {
            return new MemberObjectQuery(properties);
        };

        /**
         * Encodes the specified MemberObjectQuery message. Does not implicitly {@link api.MemberObjectQuery.verify|verify} messages.
         * @function encode
         * @memberof api.MemberObjectQuery
         * @static
         * @param {api.IMemberObjectQuery} message MemberObjectQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberObjectQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupQuery.encode(message.group, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberObjectQuery message, length delimited. Does not implicitly {@link api.MemberObjectQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberObjectQuery
         * @static
         * @param {api.IMemberObjectQuery} message MemberObjectQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberObjectQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberObjectQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberObjectQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberObjectQuery} MemberObjectQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberObjectQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberObjectQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.group = $root.api.GroupQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberObjectQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberObjectQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberObjectQuery} MemberObjectQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberObjectQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberObjectQuery message.
         * @function verify
         * @memberof api.MemberObjectQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberObjectQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.GroupQuery.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates a MemberObjectQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberObjectQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberObjectQuery} MemberObjectQuery
         */
        MemberObjectQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberObjectQuery)
                return object;
            let message = new $root.api.MemberObjectQuery();
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.MemberObjectQuery.group: object expected");
                message.group = $root.api.GroupQuery.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberObjectQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberObjectQuery
         * @static
         * @param {api.MemberObjectQuery} message MemberObjectQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberObjectQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group = null;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.GroupQuery.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this MemberObjectQuery to JSON.
         * @function toJSON
         * @memberof api.MemberObjectQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberObjectQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberObjectQuery;
    })();

    api.BoolFilter = (function() {

        /**
         * Properties of a BoolFilter.
         * @memberof api
         * @interface IBoolFilter
         * @property {boolean|null} [filterValue] BoolFilter filterValue
         */

        /**
         * Constructs a new BoolFilter.
         * @memberof api
         * @classdesc Represents a BoolFilter.
         * @implements IBoolFilter
         * @constructor
         * @param {api.IBoolFilter=} [properties] Properties to set
         */
        function BoolFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BoolFilter filterValue.
         * @member {boolean} filterValue
         * @memberof api.BoolFilter
         * @instance
         */
        BoolFilter.prototype.filterValue = false;

        /**
         * Creates a new BoolFilter instance using the specified properties.
         * @function create
         * @memberof api.BoolFilter
         * @static
         * @param {api.IBoolFilter=} [properties] Properties to set
         * @returns {api.BoolFilter} BoolFilter instance
         */
        BoolFilter.create = function create(properties) {
            return new BoolFilter(properties);
        };

        /**
         * Encodes the specified BoolFilter message. Does not implicitly {@link api.BoolFilter.verify|verify} messages.
         * @function encode
         * @memberof api.BoolFilter
         * @static
         * @param {api.IBoolFilter} message BoolFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoolFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterValue != null && Object.hasOwnProperty.call(message, "filterValue"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.filterValue);
            return writer;
        };

        /**
         * Encodes the specified BoolFilter message, length delimited. Does not implicitly {@link api.BoolFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.BoolFilter
         * @static
         * @param {api.IBoolFilter} message BoolFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoolFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BoolFilter message from the specified reader or buffer.
         * @function decode
         * @memberof api.BoolFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.BoolFilter} BoolFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoolFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.BoolFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.filterValue = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BoolFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.BoolFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.BoolFilter} BoolFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoolFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BoolFilter message.
         * @function verify
         * @memberof api.BoolFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BoolFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.filterValue != null && message.hasOwnProperty("filterValue"))
                if (typeof message.filterValue !== "boolean")
                    return "filterValue: boolean expected";
            return null;
        };

        /**
         * Creates a BoolFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.BoolFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.BoolFilter} BoolFilter
         */
        BoolFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.BoolFilter)
                return object;
            let message = new $root.api.BoolFilter();
            if (object.filterValue != null)
                message.filterValue = Boolean(object.filterValue);
            return message;
        };

        /**
         * Creates a plain object from a BoolFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.BoolFilter
         * @static
         * @param {api.BoolFilter} message BoolFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoolFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.filterValue = false;
            if (message.filterValue != null && message.hasOwnProperty("filterValue"))
                object.filterValue = message.filterValue;
            return object;
        };

        /**
         * Converts this BoolFilter to JSON.
         * @function toJSON
         * @memberof api.BoolFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoolFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BoolFilter;
    })();

    api.StringFilter = (function() {

        /**
         * Properties of a StringFilter.
         * @memberof api
         * @interface IStringFilter
         * @property {string|null} [filterValue] StringFilter filterValue
         */

        /**
         * Constructs a new StringFilter.
         * @memberof api
         * @classdesc Represents a StringFilter.
         * @implements IStringFilter
         * @constructor
         * @param {api.IStringFilter=} [properties] Properties to set
         */
        function StringFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringFilter filterValue.
         * @member {string} filterValue
         * @memberof api.StringFilter
         * @instance
         */
        StringFilter.prototype.filterValue = "";

        /**
         * Creates a new StringFilter instance using the specified properties.
         * @function create
         * @memberof api.StringFilter
         * @static
         * @param {api.IStringFilter=} [properties] Properties to set
         * @returns {api.StringFilter} StringFilter instance
         */
        StringFilter.create = function create(properties) {
            return new StringFilter(properties);
        };

        /**
         * Encodes the specified StringFilter message. Does not implicitly {@link api.StringFilter.verify|verify} messages.
         * @function encode
         * @memberof api.StringFilter
         * @static
         * @param {api.IStringFilter} message StringFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterValue != null && Object.hasOwnProperty.call(message, "filterValue"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.filterValue);
            return writer;
        };

        /**
         * Encodes the specified StringFilter message, length delimited. Does not implicitly {@link api.StringFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.StringFilter
         * @static
         * @param {api.IStringFilter} message StringFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StringFilter message from the specified reader or buffer.
         * @function decode
         * @memberof api.StringFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.StringFilter} StringFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.StringFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.filterValue = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StringFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.StringFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.StringFilter} StringFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StringFilter message.
         * @function verify
         * @memberof api.StringFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StringFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.filterValue != null && message.hasOwnProperty("filterValue"))
                if (!$util.isString(message.filterValue))
                    return "filterValue: string expected";
            return null;
        };

        /**
         * Creates a StringFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.StringFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.StringFilter} StringFilter
         */
        StringFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.StringFilter)
                return object;
            let message = new $root.api.StringFilter();
            if (object.filterValue != null)
                message.filterValue = String(object.filterValue);
            return message;
        };

        /**
         * Creates a plain object from a StringFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.StringFilter
         * @static
         * @param {api.StringFilter} message StringFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StringFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.filterValue = "";
            if (message.filterValue != null && message.hasOwnProperty("filterValue"))
                object.filterValue = message.filterValue;
            return object;
        };

        /**
         * Converts this StringFilter to JSON.
         * @function toJSON
         * @memberof api.StringFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StringFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StringFilter;
    })();

    api.JobStatusFilter = (function() {

        /**
         * Properties of a JobStatusFilter.
         * @memberof api
         * @interface IJobStatusFilter
         * @property {boolean|null} [includeQueued] JobStatusFilter includeQueued
         * @property {boolean|null} [includeRunning] JobStatusFilter includeRunning
         * @property {boolean|null} [includeCompleted] JobStatusFilter includeCompleted
         * @property {boolean|null} [includeFailed] JobStatusFilter includeFailed
         * @property {boolean|null} [includeCancelled] JobStatusFilter includeCancelled
         */

        /**
         * Constructs a new JobStatusFilter.
         * @memberof api
         * @classdesc Represents a JobStatusFilter.
         * @implements IJobStatusFilter
         * @constructor
         * @param {api.IJobStatusFilter=} [properties] Properties to set
         */
        function JobStatusFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JobStatusFilter includeQueued.
         * @member {boolean} includeQueued
         * @memberof api.JobStatusFilter
         * @instance
         */
        JobStatusFilter.prototype.includeQueued = false;

        /**
         * JobStatusFilter includeRunning.
         * @member {boolean} includeRunning
         * @memberof api.JobStatusFilter
         * @instance
         */
        JobStatusFilter.prototype.includeRunning = false;

        /**
         * JobStatusFilter includeCompleted.
         * @member {boolean} includeCompleted
         * @memberof api.JobStatusFilter
         * @instance
         */
        JobStatusFilter.prototype.includeCompleted = false;

        /**
         * JobStatusFilter includeFailed.
         * @member {boolean} includeFailed
         * @memberof api.JobStatusFilter
         * @instance
         */
        JobStatusFilter.prototype.includeFailed = false;

        /**
         * JobStatusFilter includeCancelled.
         * @member {boolean} includeCancelled
         * @memberof api.JobStatusFilter
         * @instance
         */
        JobStatusFilter.prototype.includeCancelled = false;

        /**
         * Creates a new JobStatusFilter instance using the specified properties.
         * @function create
         * @memberof api.JobStatusFilter
         * @static
         * @param {api.IJobStatusFilter=} [properties] Properties to set
         * @returns {api.JobStatusFilter} JobStatusFilter instance
         */
        JobStatusFilter.create = function create(properties) {
            return new JobStatusFilter(properties);
        };

        /**
         * Encodes the specified JobStatusFilter message. Does not implicitly {@link api.JobStatusFilter.verify|verify} messages.
         * @function encode
         * @memberof api.JobStatusFilter
         * @static
         * @param {api.IJobStatusFilter} message JobStatusFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobStatusFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeQueued != null && Object.hasOwnProperty.call(message, "includeQueued"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeQueued);
            if (message.includeRunning != null && Object.hasOwnProperty.call(message, "includeRunning"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeRunning);
            if (message.includeCompleted != null && Object.hasOwnProperty.call(message, "includeCompleted"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeCompleted);
            if (message.includeFailed != null && Object.hasOwnProperty.call(message, "includeFailed"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeFailed);
            if (message.includeCancelled != null && Object.hasOwnProperty.call(message, "includeCancelled"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeCancelled);
            return writer;
        };

        /**
         * Encodes the specified JobStatusFilter message, length delimited. Does not implicitly {@link api.JobStatusFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.JobStatusFilter
         * @static
         * @param {api.IJobStatusFilter} message JobStatusFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobStatusFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobStatusFilter message from the specified reader or buffer.
         * @function decode
         * @memberof api.JobStatusFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.JobStatusFilter} JobStatusFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobStatusFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.JobStatusFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.includeQueued = reader.bool();
                    break;
                case 2:
                    message.includeRunning = reader.bool();
                    break;
                case 3:
                    message.includeCompleted = reader.bool();
                    break;
                case 4:
                    message.includeFailed = reader.bool();
                    break;
                case 5:
                    message.includeCancelled = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JobStatusFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.JobStatusFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.JobStatusFilter} JobStatusFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobStatusFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JobStatusFilter message.
         * @function verify
         * @memberof api.JobStatusFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobStatusFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.includeQueued != null && message.hasOwnProperty("includeQueued"))
                if (typeof message.includeQueued !== "boolean")
                    return "includeQueued: boolean expected";
            if (message.includeRunning != null && message.hasOwnProperty("includeRunning"))
                if (typeof message.includeRunning !== "boolean")
                    return "includeRunning: boolean expected";
            if (message.includeCompleted != null && message.hasOwnProperty("includeCompleted"))
                if (typeof message.includeCompleted !== "boolean")
                    return "includeCompleted: boolean expected";
            if (message.includeFailed != null && message.hasOwnProperty("includeFailed"))
                if (typeof message.includeFailed !== "boolean")
                    return "includeFailed: boolean expected";
            if (message.includeCancelled != null && message.hasOwnProperty("includeCancelled"))
                if (typeof message.includeCancelled !== "boolean")
                    return "includeCancelled: boolean expected";
            return null;
        };

        /**
         * Creates a JobStatusFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.JobStatusFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.JobStatusFilter} JobStatusFilter
         */
        JobStatusFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.JobStatusFilter)
                return object;
            let message = new $root.api.JobStatusFilter();
            if (object.includeQueued != null)
                message.includeQueued = Boolean(object.includeQueued);
            if (object.includeRunning != null)
                message.includeRunning = Boolean(object.includeRunning);
            if (object.includeCompleted != null)
                message.includeCompleted = Boolean(object.includeCompleted);
            if (object.includeFailed != null)
                message.includeFailed = Boolean(object.includeFailed);
            if (object.includeCancelled != null)
                message.includeCancelled = Boolean(object.includeCancelled);
            return message;
        };

        /**
         * Creates a plain object from a JobStatusFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.JobStatusFilter
         * @static
         * @param {api.JobStatusFilter} message JobStatusFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobStatusFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.includeQueued = false;
                object.includeRunning = false;
                object.includeCompleted = false;
                object.includeFailed = false;
                object.includeCancelled = false;
            }
            if (message.includeQueued != null && message.hasOwnProperty("includeQueued"))
                object.includeQueued = message.includeQueued;
            if (message.includeRunning != null && message.hasOwnProperty("includeRunning"))
                object.includeRunning = message.includeRunning;
            if (message.includeCompleted != null && message.hasOwnProperty("includeCompleted"))
                object.includeCompleted = message.includeCompleted;
            if (message.includeFailed != null && message.hasOwnProperty("includeFailed"))
                object.includeFailed = message.includeFailed;
            if (message.includeCancelled != null && message.hasOwnProperty("includeCancelled"))
                object.includeCancelled = message.includeCancelled;
            return object;
        };

        /**
         * Converts this JobStatusFilter to JSON.
         * @function toJSON
         * @memberof api.JobStatusFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobStatusFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return JobStatusFilter;
    })();

    api.MediaFilter = (function() {

        /**
         * Properties of a MediaFilter.
         * @memberof api
         * @interface IMediaFilter
         * @property {boolean|null} [includeImage] MediaFilter includeImage
         * @property {boolean|null} [includeVideo] MediaFilter includeVideo
         * @property {boolean|null} [includeAudio] MediaFilter includeAudio
         */

        /**
         * Constructs a new MediaFilter.
         * @memberof api
         * @classdesc Represents a MediaFilter.
         * @implements IMediaFilter
         * @constructor
         * @param {api.IMediaFilter=} [properties] Properties to set
         */
        function MediaFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MediaFilter includeImage.
         * @member {boolean} includeImage
         * @memberof api.MediaFilter
         * @instance
         */
        MediaFilter.prototype.includeImage = false;

        /**
         * MediaFilter includeVideo.
         * @member {boolean} includeVideo
         * @memberof api.MediaFilter
         * @instance
         */
        MediaFilter.prototype.includeVideo = false;

        /**
         * MediaFilter includeAudio.
         * @member {boolean} includeAudio
         * @memberof api.MediaFilter
         * @instance
         */
        MediaFilter.prototype.includeAudio = false;

        /**
         * Creates a new MediaFilter instance using the specified properties.
         * @function create
         * @memberof api.MediaFilter
         * @static
         * @param {api.IMediaFilter=} [properties] Properties to set
         * @returns {api.MediaFilter} MediaFilter instance
         */
        MediaFilter.create = function create(properties) {
            return new MediaFilter(properties);
        };

        /**
         * Encodes the specified MediaFilter message. Does not implicitly {@link api.MediaFilter.verify|verify} messages.
         * @function encode
         * @memberof api.MediaFilter
         * @static
         * @param {api.IMediaFilter} message MediaFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MediaFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeImage != null && Object.hasOwnProperty.call(message, "includeImage"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeImage);
            if (message.includeVideo != null && Object.hasOwnProperty.call(message, "includeVideo"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeVideo);
            if (message.includeAudio != null && Object.hasOwnProperty.call(message, "includeAudio"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeAudio);
            return writer;
        };

        /**
         * Encodes the specified MediaFilter message, length delimited. Does not implicitly {@link api.MediaFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MediaFilter
         * @static
         * @param {api.IMediaFilter} message MediaFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MediaFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MediaFilter message from the specified reader or buffer.
         * @function decode
         * @memberof api.MediaFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MediaFilter} MediaFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MediaFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MediaFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.includeImage = reader.bool();
                    break;
                case 2:
                    message.includeVideo = reader.bool();
                    break;
                case 3:
                    message.includeAudio = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MediaFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MediaFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MediaFilter} MediaFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MediaFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MediaFilter message.
         * @function verify
         * @memberof api.MediaFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MediaFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.includeImage != null && message.hasOwnProperty("includeImage"))
                if (typeof message.includeImage !== "boolean")
                    return "includeImage: boolean expected";
            if (message.includeVideo != null && message.hasOwnProperty("includeVideo"))
                if (typeof message.includeVideo !== "boolean")
                    return "includeVideo: boolean expected";
            if (message.includeAudio != null && message.hasOwnProperty("includeAudio"))
                if (typeof message.includeAudio !== "boolean")
                    return "includeAudio: boolean expected";
            return null;
        };

        /**
         * Creates a MediaFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MediaFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MediaFilter} MediaFilter
         */
        MediaFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MediaFilter)
                return object;
            let message = new $root.api.MediaFilter();
            if (object.includeImage != null)
                message.includeImage = Boolean(object.includeImage);
            if (object.includeVideo != null)
                message.includeVideo = Boolean(object.includeVideo);
            if (object.includeAudio != null)
                message.includeAudio = Boolean(object.includeAudio);
            return message;
        };

        /**
         * Creates a plain object from a MediaFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MediaFilter
         * @static
         * @param {api.MediaFilter} message MediaFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MediaFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.includeImage = false;
                object.includeVideo = false;
                object.includeAudio = false;
            }
            if (message.includeImage != null && message.hasOwnProperty("includeImage"))
                object.includeImage = message.includeImage;
            if (message.includeVideo != null && message.hasOwnProperty("includeVideo"))
                object.includeVideo = message.includeVideo;
            if (message.includeAudio != null && message.hasOwnProperty("includeAudio"))
                object.includeAudio = message.includeAudio;
            return object;
        };

        /**
         * Converts this MediaFilter to JSON.
         * @function toJSON
         * @memberof api.MediaFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MediaFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MediaFilter;
    })();

    api.MemberQuery = (function() {

        /**
         * Properties of a MemberQuery.
         * @memberof api
         * @interface IMemberQuery
         * @property {api.IMemberObjectQuery|null} [obj] MemberQuery obj
         * @property {api.IBoolFilter|null} [ownerFilter] MemberQuery ownerFilter
         * @property {api.IBoolFilter|null} [instructorFilter] MemberQuery instructorFilter
         * @property {api.IStringFilter|null} [entityFilter] MemberQuery entityFilter
         * @property {api.IMemberEntityQuery|null} [entity] MemberQuery entity
         */

        /**
         * Constructs a new MemberQuery.
         * @memberof api
         * @classdesc Represents a MemberQuery.
         * @implements IMemberQuery
         * @constructor
         * @param {api.IMemberQuery=} [properties] Properties to set
         */
        function MemberQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberQuery obj.
         * @member {api.IMemberObjectQuery|null|undefined} obj
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.obj = null;

        /**
         * MemberQuery ownerFilter.
         * @member {api.IBoolFilter|null|undefined} ownerFilter
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.ownerFilter = null;

        /**
         * MemberQuery instructorFilter.
         * @member {api.IBoolFilter|null|undefined} instructorFilter
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.instructorFilter = null;

        /**
         * MemberQuery entityFilter.
         * @member {api.IStringFilter|null|undefined} entityFilter
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.entityFilter = null;

        /**
         * MemberQuery entity.
         * @member {api.IMemberEntityQuery|null|undefined} entity
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.entity = null;

        /**
         * Creates a new MemberQuery instance using the specified properties.
         * @function create
         * @memberof api.MemberQuery
         * @static
         * @param {api.IMemberQuery=} [properties] Properties to set
         * @returns {api.MemberQuery} MemberQuery instance
         */
        MemberQuery.create = function create(properties) {
            return new MemberQuery(properties);
        };

        /**
         * Encodes the specified MemberQuery message. Does not implicitly {@link api.MemberQuery.verify|verify} messages.
         * @function encode
         * @memberof api.MemberQuery
         * @static
         * @param {api.IMemberQuery} message MemberQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
                $root.api.MemberObjectQuery.encode(message.obj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.ownerFilter != null && Object.hasOwnProperty.call(message, "ownerFilter"))
                $root.api.BoolFilter.encode(message.ownerFilter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.api.MemberEntityQuery.encode(message.entity, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.instructorFilter != null && Object.hasOwnProperty.call(message, "instructorFilter"))
                $root.api.BoolFilter.encode(message.instructorFilter, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.entityFilter != null && Object.hasOwnProperty.call(message, "entityFilter"))
                $root.api.StringFilter.encode(message.entityFilter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberQuery message, length delimited. Does not implicitly {@link api.MemberQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberQuery
         * @static
         * @param {api.IMemberQuery} message MemberQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberQuery} MemberQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.obj = $root.api.MemberObjectQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.ownerFilter = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.instructorFilter = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.entityFilter = $root.api.StringFilter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.entity = $root.api.MemberEntityQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberQuery} MemberQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberQuery message.
         * @function verify
         * @memberof api.MemberQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.obj != null && message.hasOwnProperty("obj")) {
                let error = $root.api.MemberObjectQuery.verify(message.obj);
                if (error)
                    return "obj." + error;
            }
            if (message.ownerFilter != null && message.hasOwnProperty("ownerFilter")) {
                let error = $root.api.BoolFilter.verify(message.ownerFilter);
                if (error)
                    return "ownerFilter." + error;
            }
            if (message.instructorFilter != null && message.hasOwnProperty("instructorFilter")) {
                let error = $root.api.BoolFilter.verify(message.instructorFilter);
                if (error)
                    return "instructorFilter." + error;
            }
            if (message.entityFilter != null && message.hasOwnProperty("entityFilter")) {
                let error = $root.api.StringFilter.verify(message.entityFilter);
                if (error)
                    return "entityFilter." + error;
            }
            if (message.entity != null && message.hasOwnProperty("entity")) {
                let error = $root.api.MemberEntityQuery.verify(message.entity);
                if (error)
                    return "entity." + error;
            }
            return null;
        };

        /**
         * Creates a MemberQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberQuery} MemberQuery
         */
        MemberQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberQuery)
                return object;
            let message = new $root.api.MemberQuery();
            if (object.obj != null) {
                if (typeof object.obj !== "object")
                    throw TypeError(".api.MemberQuery.obj: object expected");
                message.obj = $root.api.MemberObjectQuery.fromObject(object.obj);
            }
            if (object.ownerFilter != null) {
                if (typeof object.ownerFilter !== "object")
                    throw TypeError(".api.MemberQuery.ownerFilter: object expected");
                message.ownerFilter = $root.api.BoolFilter.fromObject(object.ownerFilter);
            }
            if (object.instructorFilter != null) {
                if (typeof object.instructorFilter !== "object")
                    throw TypeError(".api.MemberQuery.instructorFilter: object expected");
                message.instructorFilter = $root.api.BoolFilter.fromObject(object.instructorFilter);
            }
            if (object.entityFilter != null) {
                if (typeof object.entityFilter !== "object")
                    throw TypeError(".api.MemberQuery.entityFilter: object expected");
                message.entityFilter = $root.api.StringFilter.fromObject(object.entityFilter);
            }
            if (object.entity != null) {
                if (typeof object.entity !== "object")
                    throw TypeError(".api.MemberQuery.entity: object expected");
                message.entity = $root.api.MemberEntityQuery.fromObject(object.entity);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberQuery
         * @static
         * @param {api.MemberQuery} message MemberQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.obj = null;
                object.ownerFilter = null;
                object.entity = null;
                object.instructorFilter = null;
                object.entityFilter = null;
            }
            if (message.obj != null && message.hasOwnProperty("obj"))
                object.obj = $root.api.MemberObjectQuery.toObject(message.obj, options);
            if (message.ownerFilter != null && message.hasOwnProperty("ownerFilter"))
                object.ownerFilter = $root.api.BoolFilter.toObject(message.ownerFilter, options);
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = $root.api.MemberEntityQuery.toObject(message.entity, options);
            if (message.instructorFilter != null && message.hasOwnProperty("instructorFilter"))
                object.instructorFilter = $root.api.BoolFilter.toObject(message.instructorFilter, options);
            if (message.entityFilter != null && message.hasOwnProperty("entityFilter"))
                object.entityFilter = $root.api.StringFilter.toObject(message.entityFilter, options);
            return object;
        };

        /**
         * Converts this MemberQuery to JSON.
         * @function toJSON
         * @memberof api.MemberQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberQuery;
    })();

    api.UserUpdate = (function() {

        /**
         * Properties of a UserUpdate.
         * @memberof api
         * @interface IUserUpdate
         * @property {boolean|null} [adChannel] UserUpdate adChannel
         * @property {boolean|null} [timeZone] UserUpdate timeZone
         * @property {boolean|null} [topupPlanId] UserUpdate topupPlanId
         * @property {boolean|null} [modelName] UserUpdate modelName
         * @property {boolean|null} [userName] UserUpdate userName
         */

        /**
         * Constructs a new UserUpdate.
         * @memberof api
         * @classdesc Represents a UserUpdate.
         * @implements IUserUpdate
         * @constructor
         * @param {api.IUserUpdate=} [properties] Properties to set
         */
        function UserUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserUpdate adChannel.
         * @member {boolean} adChannel
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.adChannel = false;

        /**
         * UserUpdate timeZone.
         * @member {boolean} timeZone
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.timeZone = false;

        /**
         * UserUpdate topupPlanId.
         * @member {boolean} topupPlanId
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.topupPlanId = false;

        /**
         * UserUpdate modelName.
         * @member {boolean} modelName
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.modelName = false;

        /**
         * UserUpdate userName.
         * @member {boolean} userName
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.userName = false;

        /**
         * Creates a new UserUpdate instance using the specified properties.
         * @function create
         * @memberof api.UserUpdate
         * @static
         * @param {api.IUserUpdate=} [properties] Properties to set
         * @returns {api.UserUpdate} UserUpdate instance
         */
        UserUpdate.create = function create(properties) {
            return new UserUpdate(properties);
        };

        /**
         * Encodes the specified UserUpdate message. Does not implicitly {@link api.UserUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.UserUpdate
         * @static
         * @param {api.IUserUpdate} message UserUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.adChannel != null && Object.hasOwnProperty.call(message, "adChannel"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.adChannel);
            if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.timeZone);
            if (message.topupPlanId != null && Object.hasOwnProperty.call(message, "topupPlanId"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.topupPlanId);
            if (message.modelName != null && Object.hasOwnProperty.call(message, "modelName"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.modelName);
            if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.userName);
            return writer;
        };

        /**
         * Encodes the specified UserUpdate message, length delimited. Does not implicitly {@link api.UserUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserUpdate
         * @static
         * @param {api.IUserUpdate} message UserUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserUpdate} UserUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 7:
                    message.adChannel = reader.bool();
                    break;
                case 8:
                    message.timeZone = reader.bool();
                    break;
                case 10:
                    message.topupPlanId = reader.bool();
                    break;
                case 12:
                    message.modelName = reader.bool();
                    break;
                case 13:
                    message.userName = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserUpdate} UserUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserUpdate message.
         * @function verify
         * @memberof api.UserUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.adChannel != null && message.hasOwnProperty("adChannel"))
                if (typeof message.adChannel !== "boolean")
                    return "adChannel: boolean expected";
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                if (typeof message.timeZone !== "boolean")
                    return "timeZone: boolean expected";
            if (message.topupPlanId != null && message.hasOwnProperty("topupPlanId"))
                if (typeof message.topupPlanId !== "boolean")
                    return "topupPlanId: boolean expected";
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                if (typeof message.modelName !== "boolean")
                    return "modelName: boolean expected";
            if (message.userName != null && message.hasOwnProperty("userName"))
                if (typeof message.userName !== "boolean")
                    return "userName: boolean expected";
            return null;
        };

        /**
         * Creates a UserUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserUpdate} UserUpdate
         */
        UserUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserUpdate)
                return object;
            let message = new $root.api.UserUpdate();
            if (object.adChannel != null)
                message.adChannel = Boolean(object.adChannel);
            if (object.timeZone != null)
                message.timeZone = Boolean(object.timeZone);
            if (object.topupPlanId != null)
                message.topupPlanId = Boolean(object.topupPlanId);
            if (object.modelName != null)
                message.modelName = Boolean(object.modelName);
            if (object.userName != null)
                message.userName = Boolean(object.userName);
            return message;
        };

        /**
         * Creates a plain object from a UserUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserUpdate
         * @static
         * @param {api.UserUpdate} message UserUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.adChannel = false;
                object.timeZone = false;
                object.topupPlanId = false;
                object.modelName = false;
                object.userName = false;
            }
            if (message.adChannel != null && message.hasOwnProperty("adChannel"))
                object.adChannel = message.adChannel;
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                object.timeZone = message.timeZone;
            if (message.topupPlanId != null && message.hasOwnProperty("topupPlanId"))
                object.topupPlanId = message.topupPlanId;
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                object.modelName = message.modelName;
            if (message.userName != null && message.hasOwnProperty("userName"))
                object.userName = message.userName;
            return object;
        };

        /**
         * Converts this UserUpdate to JSON.
         * @function toJSON
         * @memberof api.UserUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserUpdate;
    })();

    api.UserQuery = (function() {

        /**
         * Properties of a UserQuery.
         * @memberof api
         * @interface IUserQuery
         * @property {api.IMemberQuery|null} [membersOf] UserQuery membersOf
         * @property {api.ICredentialQuery|null} [credentials] UserQuery credentials
         */

        /**
         * Constructs a new UserQuery.
         * @memberof api
         * @classdesc Represents a UserQuery.
         * @implements IUserQuery
         * @constructor
         * @param {api.IUserQuery=} [properties] Properties to set
         */
        function UserQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserQuery membersOf.
         * @member {api.IMemberQuery|null|undefined} membersOf
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.membersOf = null;

        /**
         * UserQuery credentials.
         * @member {api.ICredentialQuery|null|undefined} credentials
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.credentials = null;

        /**
         * Creates a new UserQuery instance using the specified properties.
         * @function create
         * @memberof api.UserQuery
         * @static
         * @param {api.IUserQuery=} [properties] Properties to set
         * @returns {api.UserQuery} UserQuery instance
         */
        UserQuery.create = function create(properties) {
            return new UserQuery(properties);
        };

        /**
         * Encodes the specified UserQuery message. Does not implicitly {@link api.UserQuery.verify|verify} messages.
         * @function encode
         * @memberof api.UserQuery
         * @static
         * @param {api.IUserQuery} message UserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.membersOf != null && Object.hasOwnProperty.call(message, "membersOf"))
                $root.api.MemberQuery.encode(message.membersOf, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.credentials != null && Object.hasOwnProperty.call(message, "credentials"))
                $root.api.CredentialQuery.encode(message.credentials, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UserQuery message, length delimited. Does not implicitly {@link api.UserQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserQuery
         * @static
         * @param {api.IUserQuery} message UserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserQuery} UserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.membersOf = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.credentials = $root.api.CredentialQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserQuery} UserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserQuery message.
         * @function verify
         * @memberof api.UserQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.membersOf != null && message.hasOwnProperty("membersOf")) {
                let error = $root.api.MemberQuery.verify(message.membersOf);
                if (error)
                    return "membersOf." + error;
            }
            if (message.credentials != null && message.hasOwnProperty("credentials")) {
                let error = $root.api.CredentialQuery.verify(message.credentials);
                if (error)
                    return "credentials." + error;
            }
            return null;
        };

        /**
         * Creates a UserQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserQuery} UserQuery
         */
        UserQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserQuery)
                return object;
            let message = new $root.api.UserQuery();
            if (object.membersOf != null) {
                if (typeof object.membersOf !== "object")
                    throw TypeError(".api.UserQuery.membersOf: object expected");
                message.membersOf = $root.api.MemberQuery.fromObject(object.membersOf);
            }
            if (object.credentials != null) {
                if (typeof object.credentials !== "object")
                    throw TypeError(".api.UserQuery.credentials: object expected");
                message.credentials = $root.api.CredentialQuery.fromObject(object.credentials);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserQuery
         * @static
         * @param {api.UserQuery} message UserQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.membersOf = null;
                object.credentials = null;
            }
            if (message.membersOf != null && message.hasOwnProperty("membersOf"))
                object.membersOf = $root.api.MemberQuery.toObject(message.membersOf, options);
            if (message.credentials != null && message.hasOwnProperty("credentials"))
                object.credentials = $root.api.CredentialQuery.toObject(message.credentials, options);
            return object;
        };

        /**
         * Converts this UserQuery to JSON.
         * @function toJSON
         * @memberof api.UserQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserQuery;
    })();

    api.AddSlackTeamRequest = (function() {

        /**
         * Properties of an AddSlackTeamRequest.
         * @memberof api
         * @interface IAddSlackTeamRequest
         * @property {string|null} [slackAuthCode] AddSlackTeamRequest slackAuthCode
         * @property {string|null} [slackRedirectUrl] AddSlackTeamRequest slackRedirectUrl
         */

        /**
         * Constructs a new AddSlackTeamRequest.
         * @memberof api
         * @classdesc Represents an AddSlackTeamRequest.
         * @implements IAddSlackTeamRequest
         * @constructor
         * @param {api.IAddSlackTeamRequest=} [properties] Properties to set
         */
        function AddSlackTeamRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddSlackTeamRequest slackAuthCode.
         * @member {string} slackAuthCode
         * @memberof api.AddSlackTeamRequest
         * @instance
         */
        AddSlackTeamRequest.prototype.slackAuthCode = "";

        /**
         * AddSlackTeamRequest slackRedirectUrl.
         * @member {string} slackRedirectUrl
         * @memberof api.AddSlackTeamRequest
         * @instance
         */
        AddSlackTeamRequest.prototype.slackRedirectUrl = "";

        /**
         * Creates a new AddSlackTeamRequest instance using the specified properties.
         * @function create
         * @memberof api.AddSlackTeamRequest
         * @static
         * @param {api.IAddSlackTeamRequest=} [properties] Properties to set
         * @returns {api.AddSlackTeamRequest} AddSlackTeamRequest instance
         */
        AddSlackTeamRequest.create = function create(properties) {
            return new AddSlackTeamRequest(properties);
        };

        /**
         * Encodes the specified AddSlackTeamRequest message. Does not implicitly {@link api.AddSlackTeamRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddSlackTeamRequest
         * @static
         * @param {api.IAddSlackTeamRequest} message AddSlackTeamRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddSlackTeamRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slackAuthCode != null && Object.hasOwnProperty.call(message, "slackAuthCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slackAuthCode);
            if (message.slackRedirectUrl != null && Object.hasOwnProperty.call(message, "slackRedirectUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.slackRedirectUrl);
            return writer;
        };

        /**
         * Encodes the specified AddSlackTeamRequest message, length delimited. Does not implicitly {@link api.AddSlackTeamRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddSlackTeamRequest
         * @static
         * @param {api.IAddSlackTeamRequest} message AddSlackTeamRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddSlackTeamRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddSlackTeamRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddSlackTeamRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddSlackTeamRequest} AddSlackTeamRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddSlackTeamRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddSlackTeamRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slackAuthCode = reader.string();
                    break;
                case 2:
                    message.slackRedirectUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddSlackTeamRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddSlackTeamRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddSlackTeamRequest} AddSlackTeamRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddSlackTeamRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddSlackTeamRequest message.
         * @function verify
         * @memberof api.AddSlackTeamRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddSlackTeamRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.slackAuthCode != null && message.hasOwnProperty("slackAuthCode"))
                if (!$util.isString(message.slackAuthCode))
                    return "slackAuthCode: string expected";
            if (message.slackRedirectUrl != null && message.hasOwnProperty("slackRedirectUrl"))
                if (!$util.isString(message.slackRedirectUrl))
                    return "slackRedirectUrl: string expected";
            return null;
        };

        /**
         * Creates an AddSlackTeamRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddSlackTeamRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddSlackTeamRequest} AddSlackTeamRequest
         */
        AddSlackTeamRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddSlackTeamRequest)
                return object;
            let message = new $root.api.AddSlackTeamRequest();
            if (object.slackAuthCode != null)
                message.slackAuthCode = String(object.slackAuthCode);
            if (object.slackRedirectUrl != null)
                message.slackRedirectUrl = String(object.slackRedirectUrl);
            return message;
        };

        /**
         * Creates a plain object from an AddSlackTeamRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddSlackTeamRequest
         * @static
         * @param {api.AddSlackTeamRequest} message AddSlackTeamRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddSlackTeamRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.slackAuthCode = "";
                object.slackRedirectUrl = "";
            }
            if (message.slackAuthCode != null && message.hasOwnProperty("slackAuthCode"))
                object.slackAuthCode = message.slackAuthCode;
            if (message.slackRedirectUrl != null && message.hasOwnProperty("slackRedirectUrl"))
                object.slackRedirectUrl = message.slackRedirectUrl;
            return object;
        };

        /**
         * Converts this AddSlackTeamRequest to JSON.
         * @function toJSON
         * @memberof api.AddSlackTeamRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddSlackTeamRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddSlackTeamRequest;
    })();

    api.AddSlackTeamResponse = (function() {

        /**
         * Properties of an AddSlackTeamResponse.
         * @memberof api
         * @interface IAddSlackTeamResponse
         * @property {string|null} [teamId] AddSlackTeamResponse teamId
         * @property {string|null} [teamName] AddSlackTeamResponse teamName
         * @property {string|null} [appId] AddSlackTeamResponse appId
         */

        /**
         * Constructs a new AddSlackTeamResponse.
         * @memberof api
         * @classdesc Represents an AddSlackTeamResponse.
         * @implements IAddSlackTeamResponse
         * @constructor
         * @param {api.IAddSlackTeamResponse=} [properties] Properties to set
         */
        function AddSlackTeamResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddSlackTeamResponse teamId.
         * @member {string} teamId
         * @memberof api.AddSlackTeamResponse
         * @instance
         */
        AddSlackTeamResponse.prototype.teamId = "";

        /**
         * AddSlackTeamResponse teamName.
         * @member {string} teamName
         * @memberof api.AddSlackTeamResponse
         * @instance
         */
        AddSlackTeamResponse.prototype.teamName = "";

        /**
         * AddSlackTeamResponse appId.
         * @member {string} appId
         * @memberof api.AddSlackTeamResponse
         * @instance
         */
        AddSlackTeamResponse.prototype.appId = "";

        /**
         * Creates a new AddSlackTeamResponse instance using the specified properties.
         * @function create
         * @memberof api.AddSlackTeamResponse
         * @static
         * @param {api.IAddSlackTeamResponse=} [properties] Properties to set
         * @returns {api.AddSlackTeamResponse} AddSlackTeamResponse instance
         */
        AddSlackTeamResponse.create = function create(properties) {
            return new AddSlackTeamResponse(properties);
        };

        /**
         * Encodes the specified AddSlackTeamResponse message. Does not implicitly {@link api.AddSlackTeamResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddSlackTeamResponse
         * @static
         * @param {api.IAddSlackTeamResponse} message AddSlackTeamResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddSlackTeamResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.teamId != null && Object.hasOwnProperty.call(message, "teamId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.teamId);
            if (message.teamName != null && Object.hasOwnProperty.call(message, "teamName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.teamName);
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.appId);
            return writer;
        };

        /**
         * Encodes the specified AddSlackTeamResponse message, length delimited. Does not implicitly {@link api.AddSlackTeamResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddSlackTeamResponse
         * @static
         * @param {api.IAddSlackTeamResponse} message AddSlackTeamResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddSlackTeamResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddSlackTeamResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddSlackTeamResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddSlackTeamResponse} AddSlackTeamResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddSlackTeamResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddSlackTeamResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.teamId = reader.string();
                    break;
                case 2:
                    message.teamName = reader.string();
                    break;
                case 3:
                    message.appId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddSlackTeamResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddSlackTeamResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddSlackTeamResponse} AddSlackTeamResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddSlackTeamResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddSlackTeamResponse message.
         * @function verify
         * @memberof api.AddSlackTeamResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddSlackTeamResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.teamId != null && message.hasOwnProperty("teamId"))
                if (!$util.isString(message.teamId))
                    return "teamId: string expected";
            if (message.teamName != null && message.hasOwnProperty("teamName"))
                if (!$util.isString(message.teamName))
                    return "teamName: string expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            return null;
        };

        /**
         * Creates an AddSlackTeamResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddSlackTeamResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddSlackTeamResponse} AddSlackTeamResponse
         */
        AddSlackTeamResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddSlackTeamResponse)
                return object;
            let message = new $root.api.AddSlackTeamResponse();
            if (object.teamId != null)
                message.teamId = String(object.teamId);
            if (object.teamName != null)
                message.teamName = String(object.teamName);
            if (object.appId != null)
                message.appId = String(object.appId);
            return message;
        };

        /**
         * Creates a plain object from an AddSlackTeamResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddSlackTeamResponse
         * @static
         * @param {api.AddSlackTeamResponse} message AddSlackTeamResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddSlackTeamResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.teamId = "";
                object.teamName = "";
                object.appId = "";
            }
            if (message.teamId != null && message.hasOwnProperty("teamId"))
                object.teamId = message.teamId;
            if (message.teamName != null && message.hasOwnProperty("teamName"))
                object.teamName = message.teamName;
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            return object;
        };

        /**
         * Converts this AddSlackTeamResponse to JSON.
         * @function toJSON
         * @memberof api.AddSlackTeamResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddSlackTeamResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddSlackTeamResponse;
    })();

    api.SlackUserUpdate = (function() {

        /**
         * Properties of a SlackUserUpdate.
         * @memberof api
         * @interface ISlackUserUpdate
         * @property {boolean|null} [teamPaymentUser] SlackUserUpdate teamPaymentUser
         */

        /**
         * Constructs a new SlackUserUpdate.
         * @memberof api
         * @classdesc Represents a SlackUserUpdate.
         * @implements ISlackUserUpdate
         * @constructor
         * @param {api.ISlackUserUpdate=} [properties] Properties to set
         */
        function SlackUserUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SlackUserUpdate teamPaymentUser.
         * @member {boolean} teamPaymentUser
         * @memberof api.SlackUserUpdate
         * @instance
         */
        SlackUserUpdate.prototype.teamPaymentUser = false;

        /**
         * Creates a new SlackUserUpdate instance using the specified properties.
         * @function create
         * @memberof api.SlackUserUpdate
         * @static
         * @param {api.ISlackUserUpdate=} [properties] Properties to set
         * @returns {api.SlackUserUpdate} SlackUserUpdate instance
         */
        SlackUserUpdate.create = function create(properties) {
            return new SlackUserUpdate(properties);
        };

        /**
         * Encodes the specified SlackUserUpdate message. Does not implicitly {@link api.SlackUserUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.SlackUserUpdate
         * @static
         * @param {api.ISlackUserUpdate} message SlackUserUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackUserUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.teamPaymentUser != null && Object.hasOwnProperty.call(message, "teamPaymentUser"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.teamPaymentUser);
            return writer;
        };

        /**
         * Encodes the specified SlackUserUpdate message, length delimited. Does not implicitly {@link api.SlackUserUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SlackUserUpdate
         * @static
         * @param {api.ISlackUserUpdate} message SlackUserUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackUserUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SlackUserUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.SlackUserUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SlackUserUpdate} SlackUserUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackUserUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SlackUserUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.teamPaymentUser = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SlackUserUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SlackUserUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SlackUserUpdate} SlackUserUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackUserUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SlackUserUpdate message.
         * @function verify
         * @memberof api.SlackUserUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SlackUserUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.teamPaymentUser != null && message.hasOwnProperty("teamPaymentUser"))
                if (typeof message.teamPaymentUser !== "boolean")
                    return "teamPaymentUser: boolean expected";
            return null;
        };

        /**
         * Creates a SlackUserUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SlackUserUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SlackUserUpdate} SlackUserUpdate
         */
        SlackUserUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SlackUserUpdate)
                return object;
            let message = new $root.api.SlackUserUpdate();
            if (object.teamPaymentUser != null)
                message.teamPaymentUser = Boolean(object.teamPaymentUser);
            return message;
        };

        /**
         * Creates a plain object from a SlackUserUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SlackUserUpdate
         * @static
         * @param {api.SlackUserUpdate} message SlackUserUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SlackUserUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.teamPaymentUser = false;
            if (message.teamPaymentUser != null && message.hasOwnProperty("teamPaymentUser"))
                object.teamPaymentUser = message.teamPaymentUser;
            return object;
        };

        /**
         * Converts this SlackUserUpdate to JSON.
         * @function toJSON
         * @memberof api.SlackUserUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SlackUserUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SlackUserUpdate;
    })();

    api.UpdateSlackUserRequest = (function() {

        /**
         * Properties of an UpdateSlackUserRequest.
         * @memberof api
         * @interface IUpdateSlackUserRequest
         * @property {api.ISlackUserUpdate|null} [update] UpdateSlackUserRequest update
         * @property {api.ISlackUser|null} [slackUser] UpdateSlackUserRequest slackUser
         */

        /**
         * Constructs a new UpdateSlackUserRequest.
         * @memberof api
         * @classdesc Represents an UpdateSlackUserRequest.
         * @implements IUpdateSlackUserRequest
         * @constructor
         * @param {api.IUpdateSlackUserRequest=} [properties] Properties to set
         */
        function UpdateSlackUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateSlackUserRequest update.
         * @member {api.ISlackUserUpdate|null|undefined} update
         * @memberof api.UpdateSlackUserRequest
         * @instance
         */
        UpdateSlackUserRequest.prototype.update = null;

        /**
         * UpdateSlackUserRequest slackUser.
         * @member {api.ISlackUser|null|undefined} slackUser
         * @memberof api.UpdateSlackUserRequest
         * @instance
         */
        UpdateSlackUserRequest.prototype.slackUser = null;

        /**
         * Creates a new UpdateSlackUserRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateSlackUserRequest
         * @static
         * @param {api.IUpdateSlackUserRequest=} [properties] Properties to set
         * @returns {api.UpdateSlackUserRequest} UpdateSlackUserRequest instance
         */
        UpdateSlackUserRequest.create = function create(properties) {
            return new UpdateSlackUserRequest(properties);
        };

        /**
         * Encodes the specified UpdateSlackUserRequest message. Does not implicitly {@link api.UpdateSlackUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateSlackUserRequest
         * @static
         * @param {api.IUpdateSlackUserRequest} message UpdateSlackUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSlackUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.SlackUserUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.slackUser != null && Object.hasOwnProperty.call(message, "slackUser"))
                $root.api.SlackUser.encode(message.slackUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateSlackUserRequest message, length delimited. Does not implicitly {@link api.UpdateSlackUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateSlackUserRequest
         * @static
         * @param {api.IUpdateSlackUserRequest} message UpdateSlackUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSlackUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateSlackUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateSlackUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateSlackUserRequest} UpdateSlackUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSlackUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateSlackUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.SlackUserUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.slackUser = $root.api.SlackUser.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateSlackUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateSlackUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateSlackUserRequest} UpdateSlackUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSlackUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateSlackUserRequest message.
         * @function verify
         * @memberof api.UpdateSlackUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateSlackUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.SlackUserUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.slackUser != null && message.hasOwnProperty("slackUser")) {
                let error = $root.api.SlackUser.verify(message.slackUser);
                if (error)
                    return "slackUser." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateSlackUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateSlackUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateSlackUserRequest} UpdateSlackUserRequest
         */
        UpdateSlackUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateSlackUserRequest)
                return object;
            let message = new $root.api.UpdateSlackUserRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateSlackUserRequest.update: object expected");
                message.update = $root.api.SlackUserUpdate.fromObject(object.update);
            }
            if (object.slackUser != null) {
                if (typeof object.slackUser !== "object")
                    throw TypeError(".api.UpdateSlackUserRequest.slackUser: object expected");
                message.slackUser = $root.api.SlackUser.fromObject(object.slackUser);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateSlackUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateSlackUserRequest
         * @static
         * @param {api.UpdateSlackUserRequest} message UpdateSlackUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSlackUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.slackUser = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.SlackUserUpdate.toObject(message.update, options);
            if (message.slackUser != null && message.hasOwnProperty("slackUser"))
                object.slackUser = $root.api.SlackUser.toObject(message.slackUser, options);
            return object;
        };

        /**
         * Converts this UpdateSlackUserRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateSlackUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSlackUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateSlackUserRequest;
    })();

    api.UpdateSlackUserResponse = (function() {

        /**
         * Properties of an UpdateSlackUserResponse.
         * @memberof api
         * @interface IUpdateSlackUserResponse
         */

        /**
         * Constructs a new UpdateSlackUserResponse.
         * @memberof api
         * @classdesc Represents an UpdateSlackUserResponse.
         * @implements IUpdateSlackUserResponse
         * @constructor
         * @param {api.IUpdateSlackUserResponse=} [properties] Properties to set
         */
        function UpdateSlackUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateSlackUserResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateSlackUserResponse
         * @static
         * @param {api.IUpdateSlackUserResponse=} [properties] Properties to set
         * @returns {api.UpdateSlackUserResponse} UpdateSlackUserResponse instance
         */
        UpdateSlackUserResponse.create = function create(properties) {
            return new UpdateSlackUserResponse(properties);
        };

        /**
         * Encodes the specified UpdateSlackUserResponse message. Does not implicitly {@link api.UpdateSlackUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateSlackUserResponse
         * @static
         * @param {api.IUpdateSlackUserResponse} message UpdateSlackUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSlackUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateSlackUserResponse message, length delimited. Does not implicitly {@link api.UpdateSlackUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateSlackUserResponse
         * @static
         * @param {api.IUpdateSlackUserResponse} message UpdateSlackUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSlackUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateSlackUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateSlackUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateSlackUserResponse} UpdateSlackUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSlackUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateSlackUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateSlackUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateSlackUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateSlackUserResponse} UpdateSlackUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSlackUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateSlackUserResponse message.
         * @function verify
         * @memberof api.UpdateSlackUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateSlackUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateSlackUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateSlackUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateSlackUserResponse} UpdateSlackUserResponse
         */
        UpdateSlackUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateSlackUserResponse)
                return object;
            return new $root.api.UpdateSlackUserResponse();
        };

        /**
         * Creates a plain object from an UpdateSlackUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateSlackUserResponse
         * @static
         * @param {api.UpdateSlackUserResponse} message UpdateSlackUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSlackUserResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateSlackUserResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateSlackUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSlackUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateSlackUserResponse;
    })();

    api.UpdateUserRequest = (function() {

        /**
         * Properties of an UpdateUserRequest.
         * @memberof api
         * @interface IUpdateUserRequest
         * @property {api.IUserUpdate|null} [update] UpdateUserRequest update
         * @property {api.IUser|null} [user] UpdateUserRequest user
         */

        /**
         * Constructs a new UpdateUserRequest.
         * @memberof api
         * @classdesc Represents an UpdateUserRequest.
         * @implements IUpdateUserRequest
         * @constructor
         * @param {api.IUpdateUserRequest=} [properties] Properties to set
         */
        function UpdateUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateUserRequest update.
         * @member {api.IUserUpdate|null|undefined} update
         * @memberof api.UpdateUserRequest
         * @instance
         */
        UpdateUserRequest.prototype.update = null;

        /**
         * UpdateUserRequest user.
         * @member {api.IUser|null|undefined} user
         * @memberof api.UpdateUserRequest
         * @instance
         */
        UpdateUserRequest.prototype.user = null;

        /**
         * Creates a new UpdateUserRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateUserRequest
         * @static
         * @param {api.IUpdateUserRequest=} [properties] Properties to set
         * @returns {api.UpdateUserRequest} UpdateUserRequest instance
         */
        UpdateUserRequest.create = function create(properties) {
            return new UpdateUserRequest(properties);
        };

        /**
         * Encodes the specified UpdateUserRequest message. Does not implicitly {@link api.UpdateUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateUserRequest
         * @static
         * @param {api.IUpdateUserRequest} message UpdateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.UserUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateUserRequest message, length delimited. Does not implicitly {@link api.UpdateUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateUserRequest
         * @static
         * @param {api.IUpdateUserRequest} message UpdateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateUserRequest} UpdateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.UserUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.user = $root.api.User.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateUserRequest} UpdateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateUserRequest message.
         * @function verify
         * @memberof api.UpdateUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.UserUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateUserRequest} UpdateUserRequest
         */
        UpdateUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateUserRequest)
                return object;
            let message = new $root.api.UpdateUserRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateUserRequest.update: object expected");
                message.update = $root.api.UserUpdate.fromObject(object.update);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.UpdateUserRequest.user: object expected");
                message.user = $root.api.User.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateUserRequest
         * @static
         * @param {api.UpdateUserRequest} message UpdateUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.user = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.UserUpdate.toObject(message.update, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.User.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this UpdateUserRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateUserRequest;
    })();

    api.UpdateUserResponse = (function() {

        /**
         * Properties of an UpdateUserResponse.
         * @memberof api
         * @interface IUpdateUserResponse
         */

        /**
         * Constructs a new UpdateUserResponse.
         * @memberof api
         * @classdesc Represents an UpdateUserResponse.
         * @implements IUpdateUserResponse
         * @constructor
         * @param {api.IUpdateUserResponse=} [properties] Properties to set
         */
        function UpdateUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateUserResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateUserResponse
         * @static
         * @param {api.IUpdateUserResponse=} [properties] Properties to set
         * @returns {api.UpdateUserResponse} UpdateUserResponse instance
         */
        UpdateUserResponse.create = function create(properties) {
            return new UpdateUserResponse(properties);
        };

        /**
         * Encodes the specified UpdateUserResponse message. Does not implicitly {@link api.UpdateUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateUserResponse
         * @static
         * @param {api.IUpdateUserResponse} message UpdateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateUserResponse message, length delimited. Does not implicitly {@link api.UpdateUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateUserResponse
         * @static
         * @param {api.IUpdateUserResponse} message UpdateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateUserResponse} UpdateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateUserResponse} UpdateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateUserResponse message.
         * @function verify
         * @memberof api.UpdateUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateUserResponse} UpdateUserResponse
         */
        UpdateUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateUserResponse)
                return object;
            return new $root.api.UpdateUserResponse();
        };

        /**
         * Creates a plain object from an UpdateUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateUserResponse
         * @static
         * @param {api.UpdateUserResponse} message UpdateUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateUserResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateUserResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateUserResponse;
    })();

    api.User = (function() {

        /**
         * Properties of a User.
         * @memberof api
         * @interface IUser
         * @property {string|null} [userId] User userId
         * @property {string|null} [emailAddress] User emailAddress
         * @property {string|null} [firstName] User firstName
         * @property {string|null} [lastName] User lastName
         * @property {string|null} [password] User password
         * @property {Array.<api.IMember>|null} [membersOf] User membersOf
         * @property {number|Long|null} [createDate] User createDate
         * @property {number|Long|null} [updateDate] User updateDate
         * @property {boolean|null} [validated] User validated
         * @property {string|null} [adChannel] User adChannel
         * @property {string|null} [timeZone] User timeZone
         * @property {number|Long|null} [tokensLeft] User tokensLeft
         * @property {string|null} [topupPlanId] User topupPlanId
         * @property {string|null} [modelName] User modelName
         * @property {string|null} [appId] User appId
         * @property {Array.<api.ICredential>|null} [credentials] User credentials
         * @property {number|null} [debug] User debug
         * @property {boolean|null} [isPaid] User isPaid
         * @property {string|null} [userName] User userName
         * @property {string|null} [subscription] User subscription
         * @property {boolean|null} [showLegacyBeautyTools] User showLegacyBeautyTools
         */

        /**
         * Constructs a new User.
         * @memberof api
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {api.IUser=} [properties] Properties to set
         */
        function User(properties) {
            this.membersOf = [];
            this.credentials = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * User userId.
         * @member {string} userId
         * @memberof api.User
         * @instance
         */
        User.prototype.userId = "";

        /**
         * User emailAddress.
         * @member {string} emailAddress
         * @memberof api.User
         * @instance
         */
        User.prototype.emailAddress = "";

        /**
         * User firstName.
         * @member {string} firstName
         * @memberof api.User
         * @instance
         */
        User.prototype.firstName = "";

        /**
         * User lastName.
         * @member {string} lastName
         * @memberof api.User
         * @instance
         */
        User.prototype.lastName = "";

        /**
         * User password.
         * @member {string} password
         * @memberof api.User
         * @instance
         */
        User.prototype.password = "";

        /**
         * User membersOf.
         * @member {Array.<api.IMember>} membersOf
         * @memberof api.User
         * @instance
         */
        User.prototype.membersOf = $util.emptyArray;

        /**
         * User createDate.
         * @member {number|Long} createDate
         * @memberof api.User
         * @instance
         */
        User.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * User updateDate.
         * @member {number|Long} updateDate
         * @memberof api.User
         * @instance
         */
        User.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * User validated.
         * @member {boolean} validated
         * @memberof api.User
         * @instance
         */
        User.prototype.validated = false;

        /**
         * User adChannel.
         * @member {string} adChannel
         * @memberof api.User
         * @instance
         */
        User.prototype.adChannel = "";

        /**
         * User timeZone.
         * @member {string} timeZone
         * @memberof api.User
         * @instance
         */
        User.prototype.timeZone = "";

        /**
         * User tokensLeft.
         * @member {number|Long} tokensLeft
         * @memberof api.User
         * @instance
         */
        User.prototype.tokensLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * User topupPlanId.
         * @member {string} topupPlanId
         * @memberof api.User
         * @instance
         */
        User.prototype.topupPlanId = "";

        /**
         * User modelName.
         * @member {string} modelName
         * @memberof api.User
         * @instance
         */
        User.prototype.modelName = "";

        /**
         * User appId.
         * @member {string} appId
         * @memberof api.User
         * @instance
         */
        User.prototype.appId = "";

        /**
         * User credentials.
         * @member {Array.<api.ICredential>} credentials
         * @memberof api.User
         * @instance
         */
        User.prototype.credentials = $util.emptyArray;

        /**
         * User debug.
         * @member {number} debug
         * @memberof api.User
         * @instance
         */
        User.prototype.debug = 0;

        /**
         * User isPaid.
         * @member {boolean} isPaid
         * @memberof api.User
         * @instance
         */
        User.prototype.isPaid = false;

        /**
         * User userName.
         * @member {string} userName
         * @memberof api.User
         * @instance
         */
        User.prototype.userName = "";

        /**
         * User subscription.
         * @member {string} subscription
         * @memberof api.User
         * @instance
         */
        User.prototype.subscription = "";

        /**
         * User showLegacyBeautyTools.
         * @member {boolean} showLegacyBeautyTools
         * @memberof api.User
         * @instance
         */
        User.prototype.showLegacyBeautyTools = false;

        /**
         * Creates a new User instance using the specified properties.
         * @function create
         * @memberof api.User
         * @static
         * @param {api.IUser=} [properties] Properties to set
         * @returns {api.User} User instance
         */
        User.create = function create(properties) {
            return new User(properties);
        };

        /**
         * Encodes the specified User message. Does not implicitly {@link api.User.verify|verify} messages.
         * @function encode
         * @memberof api.User
         * @static
         * @param {api.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.password);
            if (message.membersOf != null && message.membersOf.length)
                for (let i = 0; i < message.membersOf.length; ++i)
                    $root.api.Member.encode(message.membersOf[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.updateDate);
            if (message.validated != null && Object.hasOwnProperty.call(message, "validated"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.validated);
            if (message.adChannel != null && Object.hasOwnProperty.call(message, "adChannel"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.adChannel);
            if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.timeZone);
            if (message.tokensLeft != null && Object.hasOwnProperty.call(message, "tokensLeft"))
                writer.uint32(/* id 14, wireType 0 =*/112).int64(message.tokensLeft);
            if (message.topupPlanId != null && Object.hasOwnProperty.call(message, "topupPlanId"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.topupPlanId);
            if (message.modelName != null && Object.hasOwnProperty.call(message, "modelName"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.modelName);
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.appId);
            if (message.credentials != null && message.credentials.length)
                for (let i = 0; i < message.credentials.length; ++i)
                    $root.api.Credential.encode(message.credentials[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.debug != null && Object.hasOwnProperty.call(message, "debug"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.debug);
            if (message.isPaid != null && Object.hasOwnProperty.call(message, "isPaid"))
                writer.uint32(/* id 22, wireType 0 =*/176).bool(message.isPaid);
            if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.userName);
            if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.subscription);
            if (message.showLegacyBeautyTools != null && Object.hasOwnProperty.call(message, "showLegacyBeautyTools"))
                writer.uint32(/* id 25, wireType 0 =*/200).bool(message.showLegacyBeautyTools);
            return writer;
        };

        /**
         * Encodes the specified User message, length delimited. Does not implicitly {@link api.User.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.User
         * @static
         * @param {api.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof api.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.User();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 6:
                    message.password = reader.string();
                    break;
                case 7:
                    if (!(message.membersOf && message.membersOf.length))
                        message.membersOf = [];
                    message.membersOf.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.createDate = reader.int64();
                    break;
                case 9:
                    message.updateDate = reader.int64();
                    break;
                case 10:
                    message.validated = reader.bool();
                    break;
                case 11:
                    message.adChannel = reader.string();
                    break;
                case 12:
                    message.timeZone = reader.string();
                    break;
                case 14:
                    message.tokensLeft = reader.int64();
                    break;
                case 16:
                    message.topupPlanId = reader.string();
                    break;
                case 18:
                    message.modelName = reader.string();
                    break;
                case 19:
                    message.appId = reader.string();
                    break;
                case 20:
                    if (!(message.credentials && message.credentials.length))
                        message.credentials = [];
                    message.credentials.push($root.api.Credential.decode(reader, reader.uint32()));
                    break;
                case 21:
                    message.debug = reader.int32();
                    break;
                case 22:
                    message.isPaid = reader.bool();
                    break;
                case 23:
                    message.userName = reader.string();
                    break;
                case 24:
                    message.subscription = reader.string();
                    break;
                case 25:
                    message.showLegacyBeautyTools = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a User message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a User message.
         * @function verify
         * @memberof api.User
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        User.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            if (message.membersOf != null && message.hasOwnProperty("membersOf")) {
                if (!Array.isArray(message.membersOf))
                    return "membersOf: array expected";
                for (let i = 0; i < message.membersOf.length; ++i) {
                    let error = $root.api.Member.verify(message.membersOf[i]);
                    if (error)
                        return "membersOf." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            if (message.validated != null && message.hasOwnProperty("validated"))
                if (typeof message.validated !== "boolean")
                    return "validated: boolean expected";
            if (message.adChannel != null && message.hasOwnProperty("adChannel"))
                if (!$util.isString(message.adChannel))
                    return "adChannel: string expected";
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                if (!$util.isString(message.timeZone))
                    return "timeZone: string expected";
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (!$util.isInteger(message.tokensLeft) && !(message.tokensLeft && $util.isInteger(message.tokensLeft.low) && $util.isInteger(message.tokensLeft.high)))
                    return "tokensLeft: integer|Long expected";
            if (message.topupPlanId != null && message.hasOwnProperty("topupPlanId"))
                if (!$util.isString(message.topupPlanId))
                    return "topupPlanId: string expected";
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                if (!$util.isString(message.modelName))
                    return "modelName: string expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.credentials != null && message.hasOwnProperty("credentials")) {
                if (!Array.isArray(message.credentials))
                    return "credentials: array expected";
                for (let i = 0; i < message.credentials.length; ++i) {
                    let error = $root.api.Credential.verify(message.credentials[i]);
                    if (error)
                        return "credentials." + error;
                }
            }
            if (message.debug != null && message.hasOwnProperty("debug"))
                if (!$util.isInteger(message.debug))
                    return "debug: integer expected";
            if (message.isPaid != null && message.hasOwnProperty("isPaid"))
                if (typeof message.isPaid !== "boolean")
                    return "isPaid: boolean expected";
            if (message.userName != null && message.hasOwnProperty("userName"))
                if (!$util.isString(message.userName))
                    return "userName: string expected";
            if (message.subscription != null && message.hasOwnProperty("subscription"))
                if (!$util.isString(message.subscription))
                    return "subscription: string expected";
            if (message.showLegacyBeautyTools != null && message.hasOwnProperty("showLegacyBeautyTools"))
                if (typeof message.showLegacyBeautyTools !== "boolean")
                    return "showLegacyBeautyTools: boolean expected";
            return null;
        };

        /**
         * Creates a User message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.User
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.User} User
         */
        User.fromObject = function fromObject(object) {
            if (object instanceof $root.api.User)
                return object;
            let message = new $root.api.User();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.password != null)
                message.password = String(object.password);
            if (object.membersOf) {
                if (!Array.isArray(object.membersOf))
                    throw TypeError(".api.User.membersOf: array expected");
                message.membersOf = [];
                for (let i = 0; i < object.membersOf.length; ++i) {
                    if (typeof object.membersOf[i] !== "object")
                        throw TypeError(".api.User.membersOf: object expected");
                    message.membersOf[i] = $root.api.Member.fromObject(object.membersOf[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            if (object.validated != null)
                message.validated = Boolean(object.validated);
            if (object.adChannel != null)
                message.adChannel = String(object.adChannel);
            if (object.timeZone != null)
                message.timeZone = String(object.timeZone);
            if (object.tokensLeft != null)
                if ($util.Long)
                    (message.tokensLeft = $util.Long.fromValue(object.tokensLeft)).unsigned = false;
                else if (typeof object.tokensLeft === "string")
                    message.tokensLeft = parseInt(object.tokensLeft, 10);
                else if (typeof object.tokensLeft === "number")
                    message.tokensLeft = object.tokensLeft;
                else if (typeof object.tokensLeft === "object")
                    message.tokensLeft = new $util.LongBits(object.tokensLeft.low >>> 0, object.tokensLeft.high >>> 0).toNumber();
            if (object.topupPlanId != null)
                message.topupPlanId = String(object.topupPlanId);
            if (object.modelName != null)
                message.modelName = String(object.modelName);
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.credentials) {
                if (!Array.isArray(object.credentials))
                    throw TypeError(".api.User.credentials: array expected");
                message.credentials = [];
                for (let i = 0; i < object.credentials.length; ++i) {
                    if (typeof object.credentials[i] !== "object")
                        throw TypeError(".api.User.credentials: object expected");
                    message.credentials[i] = $root.api.Credential.fromObject(object.credentials[i]);
                }
            }
            if (object.debug != null)
                message.debug = object.debug | 0;
            if (object.isPaid != null)
                message.isPaid = Boolean(object.isPaid);
            if (object.userName != null)
                message.userName = String(object.userName);
            if (object.subscription != null)
                message.subscription = String(object.subscription);
            if (object.showLegacyBeautyTools != null)
                message.showLegacyBeautyTools = Boolean(object.showLegacyBeautyTools);
            return message;
        };

        /**
         * Creates a plain object from a User message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.User
         * @static
         * @param {api.User} message User
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        User.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.membersOf = [];
                object.credentials = [];
            }
            if (options.defaults) {
                object.userId = "";
                object.emailAddress = "";
                object.firstName = "";
                object.lastName = "";
                object.password = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                object.validated = false;
                object.adChannel = "";
                object.timeZone = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensLeft = options.longs === String ? "0" : 0;
                object.topupPlanId = "";
                object.modelName = "";
                object.appId = "";
                object.debug = 0;
                object.isPaid = false;
                object.userName = "";
                object.subscription = "";
                object.showLegacyBeautyTools = false;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            if (message.membersOf && message.membersOf.length) {
                object.membersOf = [];
                for (let j = 0; j < message.membersOf.length; ++j)
                    object.membersOf[j] = $root.api.Member.toObject(message.membersOf[j], options);
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.validated != null && message.hasOwnProperty("validated"))
                object.validated = message.validated;
            if (message.adChannel != null && message.hasOwnProperty("adChannel"))
                object.adChannel = message.adChannel;
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                object.timeZone = message.timeZone;
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (typeof message.tokensLeft === "number")
                    object.tokensLeft = options.longs === String ? String(message.tokensLeft) : message.tokensLeft;
                else
                    object.tokensLeft = options.longs === String ? $util.Long.prototype.toString.call(message.tokensLeft) : options.longs === Number ? new $util.LongBits(message.tokensLeft.low >>> 0, message.tokensLeft.high >>> 0).toNumber() : message.tokensLeft;
            if (message.topupPlanId != null && message.hasOwnProperty("topupPlanId"))
                object.topupPlanId = message.topupPlanId;
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                object.modelName = message.modelName;
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.credentials && message.credentials.length) {
                object.credentials = [];
                for (let j = 0; j < message.credentials.length; ++j)
                    object.credentials[j] = $root.api.Credential.toObject(message.credentials[j], options);
            }
            if (message.debug != null && message.hasOwnProperty("debug"))
                object.debug = message.debug;
            if (message.isPaid != null && message.hasOwnProperty("isPaid"))
                object.isPaid = message.isPaid;
            if (message.userName != null && message.hasOwnProperty("userName"))
                object.userName = message.userName;
            if (message.subscription != null && message.hasOwnProperty("subscription"))
                object.subscription = message.subscription;
            if (message.showLegacyBeautyTools != null && message.hasOwnProperty("showLegacyBeautyTools"))
                object.showLegacyBeautyTools = message.showLegacyBeautyTools;
            return object;
        };

        /**
         * Converts this User to JSON.
         * @function toJSON
         * @memberof api.User
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return User;
    })();

    api.CredentialQuery = (function() {

        /**
         * Properties of a CredentialQuery.
         * @memberof api
         * @interface ICredentialQuery
         * @property {api.ISlackUserQuery|null} [slackUser] CredentialQuery slackUser
         * @property {api.INativeUserQuery|null} [nativeUser] CredentialQuery nativeUser
         * @property {api.IGoogleUserQuery|null} [googleUser] CredentialQuery googleUser
         * @property {api.IAppleUserQuery|null} [appleUser] CredentialQuery appleUser
         */

        /**
         * Constructs a new CredentialQuery.
         * @memberof api
         * @classdesc Represents a CredentialQuery.
         * @implements ICredentialQuery
         * @constructor
         * @param {api.ICredentialQuery=} [properties] Properties to set
         */
        function CredentialQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CredentialQuery slackUser.
         * @member {api.ISlackUserQuery|null|undefined} slackUser
         * @memberof api.CredentialQuery
         * @instance
         */
        CredentialQuery.prototype.slackUser = null;

        /**
         * CredentialQuery nativeUser.
         * @member {api.INativeUserQuery|null|undefined} nativeUser
         * @memberof api.CredentialQuery
         * @instance
         */
        CredentialQuery.prototype.nativeUser = null;

        /**
         * CredentialQuery googleUser.
         * @member {api.IGoogleUserQuery|null|undefined} googleUser
         * @memberof api.CredentialQuery
         * @instance
         */
        CredentialQuery.prototype.googleUser = null;

        /**
         * CredentialQuery appleUser.
         * @member {api.IAppleUserQuery|null|undefined} appleUser
         * @memberof api.CredentialQuery
         * @instance
         */
        CredentialQuery.prototype.appleUser = null;

        /**
         * Creates a new CredentialQuery instance using the specified properties.
         * @function create
         * @memberof api.CredentialQuery
         * @static
         * @param {api.ICredentialQuery=} [properties] Properties to set
         * @returns {api.CredentialQuery} CredentialQuery instance
         */
        CredentialQuery.create = function create(properties) {
            return new CredentialQuery(properties);
        };

        /**
         * Encodes the specified CredentialQuery message. Does not implicitly {@link api.CredentialQuery.verify|verify} messages.
         * @function encode
         * @memberof api.CredentialQuery
         * @static
         * @param {api.ICredentialQuery} message CredentialQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CredentialQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slackUser != null && Object.hasOwnProperty.call(message, "slackUser"))
                $root.api.SlackUserQuery.encode(message.slackUser, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.nativeUser != null && Object.hasOwnProperty.call(message, "nativeUser"))
                $root.api.NativeUserQuery.encode(message.nativeUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.googleUser != null && Object.hasOwnProperty.call(message, "googleUser"))
                $root.api.GoogleUserQuery.encode(message.googleUser, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.appleUser != null && Object.hasOwnProperty.call(message, "appleUser"))
                $root.api.AppleUserQuery.encode(message.appleUser, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CredentialQuery message, length delimited. Does not implicitly {@link api.CredentialQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CredentialQuery
         * @static
         * @param {api.ICredentialQuery} message CredentialQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CredentialQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CredentialQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.CredentialQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CredentialQuery} CredentialQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CredentialQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CredentialQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slackUser = $root.api.SlackUserQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.nativeUser = $root.api.NativeUserQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.googleUser = $root.api.GoogleUserQuery.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.appleUser = $root.api.AppleUserQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CredentialQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CredentialQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CredentialQuery} CredentialQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CredentialQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CredentialQuery message.
         * @function verify
         * @memberof api.CredentialQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CredentialQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.slackUser != null && message.hasOwnProperty("slackUser")) {
                let error = $root.api.SlackUserQuery.verify(message.slackUser);
                if (error)
                    return "slackUser." + error;
            }
            if (message.nativeUser != null && message.hasOwnProperty("nativeUser")) {
                let error = $root.api.NativeUserQuery.verify(message.nativeUser);
                if (error)
                    return "nativeUser." + error;
            }
            if (message.googleUser != null && message.hasOwnProperty("googleUser")) {
                let error = $root.api.GoogleUserQuery.verify(message.googleUser);
                if (error)
                    return "googleUser." + error;
            }
            if (message.appleUser != null && message.hasOwnProperty("appleUser")) {
                let error = $root.api.AppleUserQuery.verify(message.appleUser);
                if (error)
                    return "appleUser." + error;
            }
            return null;
        };

        /**
         * Creates a CredentialQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CredentialQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CredentialQuery} CredentialQuery
         */
        CredentialQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CredentialQuery)
                return object;
            let message = new $root.api.CredentialQuery();
            if (object.slackUser != null) {
                if (typeof object.slackUser !== "object")
                    throw TypeError(".api.CredentialQuery.slackUser: object expected");
                message.slackUser = $root.api.SlackUserQuery.fromObject(object.slackUser);
            }
            if (object.nativeUser != null) {
                if (typeof object.nativeUser !== "object")
                    throw TypeError(".api.CredentialQuery.nativeUser: object expected");
                message.nativeUser = $root.api.NativeUserQuery.fromObject(object.nativeUser);
            }
            if (object.googleUser != null) {
                if (typeof object.googleUser !== "object")
                    throw TypeError(".api.CredentialQuery.googleUser: object expected");
                message.googleUser = $root.api.GoogleUserQuery.fromObject(object.googleUser);
            }
            if (object.appleUser != null) {
                if (typeof object.appleUser !== "object")
                    throw TypeError(".api.CredentialQuery.appleUser: object expected");
                message.appleUser = $root.api.AppleUserQuery.fromObject(object.appleUser);
            }
            return message;
        };

        /**
         * Creates a plain object from a CredentialQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CredentialQuery
         * @static
         * @param {api.CredentialQuery} message CredentialQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CredentialQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.slackUser = null;
                object.nativeUser = null;
                object.googleUser = null;
                object.appleUser = null;
            }
            if (message.slackUser != null && message.hasOwnProperty("slackUser"))
                object.slackUser = $root.api.SlackUserQuery.toObject(message.slackUser, options);
            if (message.nativeUser != null && message.hasOwnProperty("nativeUser"))
                object.nativeUser = $root.api.NativeUserQuery.toObject(message.nativeUser, options);
            if (message.googleUser != null && message.hasOwnProperty("googleUser"))
                object.googleUser = $root.api.GoogleUserQuery.toObject(message.googleUser, options);
            if (message.appleUser != null && message.hasOwnProperty("appleUser"))
                object.appleUser = $root.api.AppleUserQuery.toObject(message.appleUser, options);
            return object;
        };

        /**
         * Converts this CredentialQuery to JSON.
         * @function toJSON
         * @memberof api.CredentialQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CredentialQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CredentialQuery;
    })();

    api.Credential = (function() {

        /**
         * Properties of a Credential.
         * @memberof api
         * @interface ICredential
         * @property {api.ISlackUser|null} [slackUser] Credential slackUser
         * @property {api.INativeUser|null} [nativeUser] Credential nativeUser
         * @property {api.IGoogleUser|null} [googleUser] Credential googleUser
         * @property {api.IAppleUser|null} [appleUser] Credential appleUser
         */

        /**
         * Constructs a new Credential.
         * @memberof api
         * @classdesc Represents a Credential.
         * @implements ICredential
         * @constructor
         * @param {api.ICredential=} [properties] Properties to set
         */
        function Credential(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Credential slackUser.
         * @member {api.ISlackUser|null|undefined} slackUser
         * @memberof api.Credential
         * @instance
         */
        Credential.prototype.slackUser = null;

        /**
         * Credential nativeUser.
         * @member {api.INativeUser|null|undefined} nativeUser
         * @memberof api.Credential
         * @instance
         */
        Credential.prototype.nativeUser = null;

        /**
         * Credential googleUser.
         * @member {api.IGoogleUser|null|undefined} googleUser
         * @memberof api.Credential
         * @instance
         */
        Credential.prototype.googleUser = null;

        /**
         * Credential appleUser.
         * @member {api.IAppleUser|null|undefined} appleUser
         * @memberof api.Credential
         * @instance
         */
        Credential.prototype.appleUser = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Credential credentialOption.
         * @member {"slackUser"|"nativeUser"|"googleUser"|"appleUser"|undefined} credentialOption
         * @memberof api.Credential
         * @instance
         */
        Object.defineProperty(Credential.prototype, "credentialOption", {
            get: $util.oneOfGetter($oneOfFields = ["slackUser", "nativeUser", "googleUser", "appleUser"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Credential instance using the specified properties.
         * @function create
         * @memberof api.Credential
         * @static
         * @param {api.ICredential=} [properties] Properties to set
         * @returns {api.Credential} Credential instance
         */
        Credential.create = function create(properties) {
            return new Credential(properties);
        };

        /**
         * Encodes the specified Credential message. Does not implicitly {@link api.Credential.verify|verify} messages.
         * @function encode
         * @memberof api.Credential
         * @static
         * @param {api.ICredential} message Credential message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Credential.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slackUser != null && Object.hasOwnProperty.call(message, "slackUser"))
                $root.api.SlackUser.encode(message.slackUser, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.nativeUser != null && Object.hasOwnProperty.call(message, "nativeUser"))
                $root.api.NativeUser.encode(message.nativeUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.googleUser != null && Object.hasOwnProperty.call(message, "googleUser"))
                $root.api.GoogleUser.encode(message.googleUser, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.appleUser != null && Object.hasOwnProperty.call(message, "appleUser"))
                $root.api.AppleUser.encode(message.appleUser, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Credential message, length delimited. Does not implicitly {@link api.Credential.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Credential
         * @static
         * @param {api.ICredential} message Credential message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Credential.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Credential message from the specified reader or buffer.
         * @function decode
         * @memberof api.Credential
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Credential} Credential
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Credential.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Credential();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slackUser = $root.api.SlackUser.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.nativeUser = $root.api.NativeUser.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.googleUser = $root.api.GoogleUser.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.appleUser = $root.api.AppleUser.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Credential message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Credential
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Credential} Credential
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Credential.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Credential message.
         * @function verify
         * @memberof api.Credential
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Credential.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.slackUser != null && message.hasOwnProperty("slackUser")) {
                properties.credentialOption = 1;
                {
                    let error = $root.api.SlackUser.verify(message.slackUser);
                    if (error)
                        return "slackUser." + error;
                }
            }
            if (message.nativeUser != null && message.hasOwnProperty("nativeUser")) {
                if (properties.credentialOption === 1)
                    return "credentialOption: multiple values";
                properties.credentialOption = 1;
                {
                    let error = $root.api.NativeUser.verify(message.nativeUser);
                    if (error)
                        return "nativeUser." + error;
                }
            }
            if (message.googleUser != null && message.hasOwnProperty("googleUser")) {
                if (properties.credentialOption === 1)
                    return "credentialOption: multiple values";
                properties.credentialOption = 1;
                {
                    let error = $root.api.GoogleUser.verify(message.googleUser);
                    if (error)
                        return "googleUser." + error;
                }
            }
            if (message.appleUser != null && message.hasOwnProperty("appleUser")) {
                if (properties.credentialOption === 1)
                    return "credentialOption: multiple values";
                properties.credentialOption = 1;
                {
                    let error = $root.api.AppleUser.verify(message.appleUser);
                    if (error)
                        return "appleUser." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Credential message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Credential
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Credential} Credential
         */
        Credential.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Credential)
                return object;
            let message = new $root.api.Credential();
            if (object.slackUser != null) {
                if (typeof object.slackUser !== "object")
                    throw TypeError(".api.Credential.slackUser: object expected");
                message.slackUser = $root.api.SlackUser.fromObject(object.slackUser);
            }
            if (object.nativeUser != null) {
                if (typeof object.nativeUser !== "object")
                    throw TypeError(".api.Credential.nativeUser: object expected");
                message.nativeUser = $root.api.NativeUser.fromObject(object.nativeUser);
            }
            if (object.googleUser != null) {
                if (typeof object.googleUser !== "object")
                    throw TypeError(".api.Credential.googleUser: object expected");
                message.googleUser = $root.api.GoogleUser.fromObject(object.googleUser);
            }
            if (object.appleUser != null) {
                if (typeof object.appleUser !== "object")
                    throw TypeError(".api.Credential.appleUser: object expected");
                message.appleUser = $root.api.AppleUser.fromObject(object.appleUser);
            }
            return message;
        };

        /**
         * Creates a plain object from a Credential message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Credential
         * @static
         * @param {api.Credential} message Credential
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Credential.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.slackUser != null && message.hasOwnProperty("slackUser")) {
                object.slackUser = $root.api.SlackUser.toObject(message.slackUser, options);
                if (options.oneofs)
                    object.credentialOption = "slackUser";
            }
            if (message.nativeUser != null && message.hasOwnProperty("nativeUser")) {
                object.nativeUser = $root.api.NativeUser.toObject(message.nativeUser, options);
                if (options.oneofs)
                    object.credentialOption = "nativeUser";
            }
            if (message.googleUser != null && message.hasOwnProperty("googleUser")) {
                object.googleUser = $root.api.GoogleUser.toObject(message.googleUser, options);
                if (options.oneofs)
                    object.credentialOption = "googleUser";
            }
            if (message.appleUser != null && message.hasOwnProperty("appleUser")) {
                object.appleUser = $root.api.AppleUser.toObject(message.appleUser, options);
                if (options.oneofs)
                    object.credentialOption = "appleUser";
            }
            return object;
        };

        /**
         * Converts this Credential to JSON.
         * @function toJSON
         * @memberof api.Credential
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Credential.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Credential;
    })();

    api.NativeUserQuery = (function() {

        /**
         * Properties of a NativeUserQuery.
         * @memberof api
         * @interface INativeUserQuery
         */

        /**
         * Constructs a new NativeUserQuery.
         * @memberof api
         * @classdesc Represents a NativeUserQuery.
         * @implements INativeUserQuery
         * @constructor
         * @param {api.INativeUserQuery=} [properties] Properties to set
         */
        function NativeUserQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new NativeUserQuery instance using the specified properties.
         * @function create
         * @memberof api.NativeUserQuery
         * @static
         * @param {api.INativeUserQuery=} [properties] Properties to set
         * @returns {api.NativeUserQuery} NativeUserQuery instance
         */
        NativeUserQuery.create = function create(properties) {
            return new NativeUserQuery(properties);
        };

        /**
         * Encodes the specified NativeUserQuery message. Does not implicitly {@link api.NativeUserQuery.verify|verify} messages.
         * @function encode
         * @memberof api.NativeUserQuery
         * @static
         * @param {api.INativeUserQuery} message NativeUserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NativeUserQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified NativeUserQuery message, length delimited. Does not implicitly {@link api.NativeUserQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.NativeUserQuery
         * @static
         * @param {api.INativeUserQuery} message NativeUserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NativeUserQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NativeUserQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.NativeUserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.NativeUserQuery} NativeUserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NativeUserQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.NativeUserQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NativeUserQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.NativeUserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.NativeUserQuery} NativeUserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NativeUserQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NativeUserQuery message.
         * @function verify
         * @memberof api.NativeUserQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NativeUserQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a NativeUserQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.NativeUserQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.NativeUserQuery} NativeUserQuery
         */
        NativeUserQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.NativeUserQuery)
                return object;
            return new $root.api.NativeUserQuery();
        };

        /**
         * Creates a plain object from a NativeUserQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.NativeUserQuery
         * @static
         * @param {api.NativeUserQuery} message NativeUserQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NativeUserQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this NativeUserQuery to JSON.
         * @function toJSON
         * @memberof api.NativeUserQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NativeUserQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NativeUserQuery;
    })();

    api.NativeUser = (function() {

        /**
         * Properties of a NativeUser.
         * @memberof api
         * @interface INativeUser
         * @property {string|null} [emailAddress] NativeUser emailAddress
         * @property {string|null} [firstName] NativeUser firstName
         * @property {string|null} [lastName] NativeUser lastName
         */

        /**
         * Constructs a new NativeUser.
         * @memberof api
         * @classdesc Represents a NativeUser.
         * @implements INativeUser
         * @constructor
         * @param {api.INativeUser=} [properties] Properties to set
         */
        function NativeUser(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NativeUser emailAddress.
         * @member {string} emailAddress
         * @memberof api.NativeUser
         * @instance
         */
        NativeUser.prototype.emailAddress = "";

        /**
         * NativeUser firstName.
         * @member {string} firstName
         * @memberof api.NativeUser
         * @instance
         */
        NativeUser.prototype.firstName = "";

        /**
         * NativeUser lastName.
         * @member {string} lastName
         * @memberof api.NativeUser
         * @instance
         */
        NativeUser.prototype.lastName = "";

        /**
         * Creates a new NativeUser instance using the specified properties.
         * @function create
         * @memberof api.NativeUser
         * @static
         * @param {api.INativeUser=} [properties] Properties to set
         * @returns {api.NativeUser} NativeUser instance
         */
        NativeUser.create = function create(properties) {
            return new NativeUser(properties);
        };

        /**
         * Encodes the specified NativeUser message. Does not implicitly {@link api.NativeUser.verify|verify} messages.
         * @function encode
         * @memberof api.NativeUser
         * @static
         * @param {api.INativeUser} message NativeUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NativeUser.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailAddress);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
            return writer;
        };

        /**
         * Encodes the specified NativeUser message, length delimited. Does not implicitly {@link api.NativeUser.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.NativeUser
         * @static
         * @param {api.INativeUser} message NativeUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NativeUser.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NativeUser message from the specified reader or buffer.
         * @function decode
         * @memberof api.NativeUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.NativeUser} NativeUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NativeUser.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.NativeUser();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.emailAddress = reader.string();
                    break;
                case 2:
                    message.firstName = reader.string();
                    break;
                case 3:
                    message.lastName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NativeUser message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.NativeUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.NativeUser} NativeUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NativeUser.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NativeUser message.
         * @function verify
         * @memberof api.NativeUser
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NativeUser.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            return null;
        };

        /**
         * Creates a NativeUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.NativeUser
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.NativeUser} NativeUser
         */
        NativeUser.fromObject = function fromObject(object) {
            if (object instanceof $root.api.NativeUser)
                return object;
            let message = new $root.api.NativeUser();
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            return message;
        };

        /**
         * Creates a plain object from a NativeUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.NativeUser
         * @static
         * @param {api.NativeUser} message NativeUser
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NativeUser.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.emailAddress = "";
                object.firstName = "";
                object.lastName = "";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            return object;
        };

        /**
         * Converts this NativeUser to JSON.
         * @function toJSON
         * @memberof api.NativeUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NativeUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NativeUser;
    })();

    api.GoogleUserQuery = (function() {

        /**
         * Properties of a GoogleUserQuery.
         * @memberof api
         * @interface IGoogleUserQuery
         */

        /**
         * Constructs a new GoogleUserQuery.
         * @memberof api
         * @classdesc Represents a GoogleUserQuery.
         * @implements IGoogleUserQuery
         * @constructor
         * @param {api.IGoogleUserQuery=} [properties] Properties to set
         */
        function GoogleUserQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GoogleUserQuery instance using the specified properties.
         * @function create
         * @memberof api.GoogleUserQuery
         * @static
         * @param {api.IGoogleUserQuery=} [properties] Properties to set
         * @returns {api.GoogleUserQuery} GoogleUserQuery instance
         */
        GoogleUserQuery.create = function create(properties) {
            return new GoogleUserQuery(properties);
        };

        /**
         * Encodes the specified GoogleUserQuery message. Does not implicitly {@link api.GoogleUserQuery.verify|verify} messages.
         * @function encode
         * @memberof api.GoogleUserQuery
         * @static
         * @param {api.IGoogleUserQuery} message GoogleUserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleUserQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GoogleUserQuery message, length delimited. Does not implicitly {@link api.GoogleUserQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GoogleUserQuery
         * @static
         * @param {api.IGoogleUserQuery} message GoogleUserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleUserQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GoogleUserQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.GoogleUserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GoogleUserQuery} GoogleUserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleUserQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GoogleUserQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GoogleUserQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GoogleUserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GoogleUserQuery} GoogleUserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleUserQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GoogleUserQuery message.
         * @function verify
         * @memberof api.GoogleUserQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GoogleUserQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GoogleUserQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GoogleUserQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GoogleUserQuery} GoogleUserQuery
         */
        GoogleUserQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GoogleUserQuery)
                return object;
            return new $root.api.GoogleUserQuery();
        };

        /**
         * Creates a plain object from a GoogleUserQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GoogleUserQuery
         * @static
         * @param {api.GoogleUserQuery} message GoogleUserQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GoogleUserQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GoogleUserQuery to JSON.
         * @function toJSON
         * @memberof api.GoogleUserQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GoogleUserQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GoogleUserQuery;
    })();

    api.AppleUserQuery = (function() {

        /**
         * Properties of an AppleUserQuery.
         * @memberof api
         * @interface IAppleUserQuery
         */

        /**
         * Constructs a new AppleUserQuery.
         * @memberof api
         * @classdesc Represents an AppleUserQuery.
         * @implements IAppleUserQuery
         * @constructor
         * @param {api.IAppleUserQuery=} [properties] Properties to set
         */
        function AppleUserQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AppleUserQuery instance using the specified properties.
         * @function create
         * @memberof api.AppleUserQuery
         * @static
         * @param {api.IAppleUserQuery=} [properties] Properties to set
         * @returns {api.AppleUserQuery} AppleUserQuery instance
         */
        AppleUserQuery.create = function create(properties) {
            return new AppleUserQuery(properties);
        };

        /**
         * Encodes the specified AppleUserQuery message. Does not implicitly {@link api.AppleUserQuery.verify|verify} messages.
         * @function encode
         * @memberof api.AppleUserQuery
         * @static
         * @param {api.IAppleUserQuery} message AppleUserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppleUserQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AppleUserQuery message, length delimited. Does not implicitly {@link api.AppleUserQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AppleUserQuery
         * @static
         * @param {api.IAppleUserQuery} message AppleUserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppleUserQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppleUserQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.AppleUserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AppleUserQuery} AppleUserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppleUserQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AppleUserQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppleUserQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AppleUserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AppleUserQuery} AppleUserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppleUserQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppleUserQuery message.
         * @function verify
         * @memberof api.AppleUserQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppleUserQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AppleUserQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AppleUserQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AppleUserQuery} AppleUserQuery
         */
        AppleUserQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AppleUserQuery)
                return object;
            return new $root.api.AppleUserQuery();
        };

        /**
         * Creates a plain object from an AppleUserQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AppleUserQuery
         * @static
         * @param {api.AppleUserQuery} message AppleUserQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppleUserQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AppleUserQuery to JSON.
         * @function toJSON
         * @memberof api.AppleUserQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppleUserQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppleUserQuery;
    })();

    api.GoogleUser = (function() {

        /**
         * Properties of a GoogleUser.
         * @memberof api
         * @interface IGoogleUser
         * @property {string|null} [googleUserId] GoogleUser googleUserId
         * @property {string|null} [emailAddress] GoogleUser emailAddress
         * @property {string|null} [firstName] GoogleUser firstName
         * @property {string|null} [lastName] GoogleUser lastName
         */

        /**
         * Constructs a new GoogleUser.
         * @memberof api
         * @classdesc Represents a GoogleUser.
         * @implements IGoogleUser
         * @constructor
         * @param {api.IGoogleUser=} [properties] Properties to set
         */
        function GoogleUser(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GoogleUser googleUserId.
         * @member {string} googleUserId
         * @memberof api.GoogleUser
         * @instance
         */
        GoogleUser.prototype.googleUserId = "";

        /**
         * GoogleUser emailAddress.
         * @member {string} emailAddress
         * @memberof api.GoogleUser
         * @instance
         */
        GoogleUser.prototype.emailAddress = "";

        /**
         * GoogleUser firstName.
         * @member {string} firstName
         * @memberof api.GoogleUser
         * @instance
         */
        GoogleUser.prototype.firstName = "";

        /**
         * GoogleUser lastName.
         * @member {string} lastName
         * @memberof api.GoogleUser
         * @instance
         */
        GoogleUser.prototype.lastName = "";

        /**
         * Creates a new GoogleUser instance using the specified properties.
         * @function create
         * @memberof api.GoogleUser
         * @static
         * @param {api.IGoogleUser=} [properties] Properties to set
         * @returns {api.GoogleUser} GoogleUser instance
         */
        GoogleUser.create = function create(properties) {
            return new GoogleUser(properties);
        };

        /**
         * Encodes the specified GoogleUser message. Does not implicitly {@link api.GoogleUser.verify|verify} messages.
         * @function encode
         * @memberof api.GoogleUser
         * @static
         * @param {api.IGoogleUser} message GoogleUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleUser.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.googleUserId != null && Object.hasOwnProperty.call(message, "googleUserId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.googleUserId);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            return writer;
        };

        /**
         * Encodes the specified GoogleUser message, length delimited. Does not implicitly {@link api.GoogleUser.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GoogleUser
         * @static
         * @param {api.IGoogleUser} message GoogleUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleUser.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GoogleUser message from the specified reader or buffer.
         * @function decode
         * @memberof api.GoogleUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GoogleUser} GoogleUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleUser.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GoogleUser();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.googleUserId = reader.string();
                    break;
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GoogleUser message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GoogleUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GoogleUser} GoogleUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleUser.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GoogleUser message.
         * @function verify
         * @memberof api.GoogleUser
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GoogleUser.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.googleUserId != null && message.hasOwnProperty("googleUserId"))
                if (!$util.isString(message.googleUserId))
                    return "googleUserId: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            return null;
        };

        /**
         * Creates a GoogleUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GoogleUser
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GoogleUser} GoogleUser
         */
        GoogleUser.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GoogleUser)
                return object;
            let message = new $root.api.GoogleUser();
            if (object.googleUserId != null)
                message.googleUserId = String(object.googleUserId);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            return message;
        };

        /**
         * Creates a plain object from a GoogleUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GoogleUser
         * @static
         * @param {api.GoogleUser} message GoogleUser
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GoogleUser.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.googleUserId = "";
                object.emailAddress = "";
                object.firstName = "";
                object.lastName = "";
            }
            if (message.googleUserId != null && message.hasOwnProperty("googleUserId"))
                object.googleUserId = message.googleUserId;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            return object;
        };

        /**
         * Converts this GoogleUser to JSON.
         * @function toJSON
         * @memberof api.GoogleUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GoogleUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GoogleUser;
    })();

    api.AppleUser = (function() {

        /**
         * Properties of an AppleUser.
         * @memberof api
         * @interface IAppleUser
         * @property {string|null} [appleUserId] AppleUser appleUserId
         * @property {string|null} [emailAddress] AppleUser emailAddress
         */

        /**
         * Constructs a new AppleUser.
         * @memberof api
         * @classdesc Represents an AppleUser.
         * @implements IAppleUser
         * @constructor
         * @param {api.IAppleUser=} [properties] Properties to set
         */
        function AppleUser(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppleUser appleUserId.
         * @member {string} appleUserId
         * @memberof api.AppleUser
         * @instance
         */
        AppleUser.prototype.appleUserId = "";

        /**
         * AppleUser emailAddress.
         * @member {string} emailAddress
         * @memberof api.AppleUser
         * @instance
         */
        AppleUser.prototype.emailAddress = "";

        /**
         * Creates a new AppleUser instance using the specified properties.
         * @function create
         * @memberof api.AppleUser
         * @static
         * @param {api.IAppleUser=} [properties] Properties to set
         * @returns {api.AppleUser} AppleUser instance
         */
        AppleUser.create = function create(properties) {
            return new AppleUser(properties);
        };

        /**
         * Encodes the specified AppleUser message. Does not implicitly {@link api.AppleUser.verify|verify} messages.
         * @function encode
         * @memberof api.AppleUser
         * @static
         * @param {api.IAppleUser} message AppleUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppleUser.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appleUserId != null && Object.hasOwnProperty.call(message, "appleUserId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.appleUserId);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            return writer;
        };

        /**
         * Encodes the specified AppleUser message, length delimited. Does not implicitly {@link api.AppleUser.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AppleUser
         * @static
         * @param {api.IAppleUser} message AppleUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppleUser.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppleUser message from the specified reader or buffer.
         * @function decode
         * @memberof api.AppleUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AppleUser} AppleUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppleUser.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AppleUser();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.appleUserId = reader.string();
                    break;
                case 2:
                    message.emailAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppleUser message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AppleUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AppleUser} AppleUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppleUser.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppleUser message.
         * @function verify
         * @memberof api.AppleUser
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppleUser.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appleUserId != null && message.hasOwnProperty("appleUserId"))
                if (!$util.isString(message.appleUserId))
                    return "appleUserId: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            return null;
        };

        /**
         * Creates an AppleUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AppleUser
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AppleUser} AppleUser
         */
        AppleUser.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AppleUser)
                return object;
            let message = new $root.api.AppleUser();
            if (object.appleUserId != null)
                message.appleUserId = String(object.appleUserId);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            return message;
        };

        /**
         * Creates a plain object from an AppleUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AppleUser
         * @static
         * @param {api.AppleUser} message AppleUser
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppleUser.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.appleUserId = "";
                object.emailAddress = "";
            }
            if (message.appleUserId != null && message.hasOwnProperty("appleUserId"))
                object.appleUserId = message.appleUserId;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            return object;
        };

        /**
         * Converts this AppleUser to JSON.
         * @function toJSON
         * @memberof api.AppleUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppleUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppleUser;
    })();

    api.SlackUserQuery = (function() {

        /**
         * Properties of a SlackUserQuery.
         * @memberof api
         * @interface ISlackUserQuery
         */

        /**
         * Constructs a new SlackUserQuery.
         * @memberof api
         * @classdesc Represents a SlackUserQuery.
         * @implements ISlackUserQuery
         * @constructor
         * @param {api.ISlackUserQuery=} [properties] Properties to set
         */
        function SlackUserQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SlackUserQuery instance using the specified properties.
         * @function create
         * @memberof api.SlackUserQuery
         * @static
         * @param {api.ISlackUserQuery=} [properties] Properties to set
         * @returns {api.SlackUserQuery} SlackUserQuery instance
         */
        SlackUserQuery.create = function create(properties) {
            return new SlackUserQuery(properties);
        };

        /**
         * Encodes the specified SlackUserQuery message. Does not implicitly {@link api.SlackUserQuery.verify|verify} messages.
         * @function encode
         * @memberof api.SlackUserQuery
         * @static
         * @param {api.ISlackUserQuery} message SlackUserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackUserQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SlackUserQuery message, length delimited. Does not implicitly {@link api.SlackUserQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SlackUserQuery
         * @static
         * @param {api.ISlackUserQuery} message SlackUserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackUserQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SlackUserQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.SlackUserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SlackUserQuery} SlackUserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackUserQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SlackUserQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SlackUserQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SlackUserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SlackUserQuery} SlackUserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackUserQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SlackUserQuery message.
         * @function verify
         * @memberof api.SlackUserQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SlackUserQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SlackUserQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SlackUserQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SlackUserQuery} SlackUserQuery
         */
        SlackUserQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SlackUserQuery)
                return object;
            return new $root.api.SlackUserQuery();
        };

        /**
         * Creates a plain object from a SlackUserQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SlackUserQuery
         * @static
         * @param {api.SlackUserQuery} message SlackUserQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SlackUserQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SlackUserQuery to JSON.
         * @function toJSON
         * @memberof api.SlackUserQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SlackUserQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SlackUserQuery;
    })();

    api.SlackUser = (function() {

        /**
         * Properties of a SlackUser.
         * @memberof api
         * @interface ISlackUser
         * @property {string|null} [slackUserId] SlackUser slackUserId
         * @property {string|null} [emailAddress] SlackUser emailAddress
         * @property {string|null} [firstName] SlackUser firstName
         * @property {string|null} [lastName] SlackUser lastName
         * @property {string|null} [teamId] SlackUser teamId
         * @property {boolean|null} [teamPaymentUser] SlackUser teamPaymentUser
         */

        /**
         * Constructs a new SlackUser.
         * @memberof api
         * @classdesc Represents a SlackUser.
         * @implements ISlackUser
         * @constructor
         * @param {api.ISlackUser=} [properties] Properties to set
         */
        function SlackUser(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SlackUser slackUserId.
         * @member {string} slackUserId
         * @memberof api.SlackUser
         * @instance
         */
        SlackUser.prototype.slackUserId = "";

        /**
         * SlackUser emailAddress.
         * @member {string} emailAddress
         * @memberof api.SlackUser
         * @instance
         */
        SlackUser.prototype.emailAddress = "";

        /**
         * SlackUser firstName.
         * @member {string} firstName
         * @memberof api.SlackUser
         * @instance
         */
        SlackUser.prototype.firstName = "";

        /**
         * SlackUser lastName.
         * @member {string} lastName
         * @memberof api.SlackUser
         * @instance
         */
        SlackUser.prototype.lastName = "";

        /**
         * SlackUser teamId.
         * @member {string} teamId
         * @memberof api.SlackUser
         * @instance
         */
        SlackUser.prototype.teamId = "";

        /**
         * SlackUser teamPaymentUser.
         * @member {boolean} teamPaymentUser
         * @memberof api.SlackUser
         * @instance
         */
        SlackUser.prototype.teamPaymentUser = false;

        /**
         * Creates a new SlackUser instance using the specified properties.
         * @function create
         * @memberof api.SlackUser
         * @static
         * @param {api.ISlackUser=} [properties] Properties to set
         * @returns {api.SlackUser} SlackUser instance
         */
        SlackUser.create = function create(properties) {
            return new SlackUser(properties);
        };

        /**
         * Encodes the specified SlackUser message. Does not implicitly {@link api.SlackUser.verify|verify} messages.
         * @function encode
         * @memberof api.SlackUser
         * @static
         * @param {api.ISlackUser} message SlackUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackUser.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slackUserId != null && Object.hasOwnProperty.call(message, "slackUserId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slackUserId);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.teamId != null && Object.hasOwnProperty.call(message, "teamId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.teamId);
            if (message.teamPaymentUser != null && Object.hasOwnProperty.call(message, "teamPaymentUser"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.teamPaymentUser);
            return writer;
        };

        /**
         * Encodes the specified SlackUser message, length delimited. Does not implicitly {@link api.SlackUser.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SlackUser
         * @static
         * @param {api.ISlackUser} message SlackUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackUser.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SlackUser message from the specified reader or buffer.
         * @function decode
         * @memberof api.SlackUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SlackUser} SlackUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackUser.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SlackUser();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slackUserId = reader.string();
                    break;
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.teamId = reader.string();
                    break;
                case 6:
                    message.teamPaymentUser = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SlackUser message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SlackUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SlackUser} SlackUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackUser.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SlackUser message.
         * @function verify
         * @memberof api.SlackUser
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SlackUser.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.slackUserId != null && message.hasOwnProperty("slackUserId"))
                if (!$util.isString(message.slackUserId))
                    return "slackUserId: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            if (message.teamId != null && message.hasOwnProperty("teamId"))
                if (!$util.isString(message.teamId))
                    return "teamId: string expected";
            if (message.teamPaymentUser != null && message.hasOwnProperty("teamPaymentUser"))
                if (typeof message.teamPaymentUser !== "boolean")
                    return "teamPaymentUser: boolean expected";
            return null;
        };

        /**
         * Creates a SlackUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SlackUser
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SlackUser} SlackUser
         */
        SlackUser.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SlackUser)
                return object;
            let message = new $root.api.SlackUser();
            if (object.slackUserId != null)
                message.slackUserId = String(object.slackUserId);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.teamId != null)
                message.teamId = String(object.teamId);
            if (object.teamPaymentUser != null)
                message.teamPaymentUser = Boolean(object.teamPaymentUser);
            return message;
        };

        /**
         * Creates a plain object from a SlackUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SlackUser
         * @static
         * @param {api.SlackUser} message SlackUser
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SlackUser.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.slackUserId = "";
                object.emailAddress = "";
                object.firstName = "";
                object.lastName = "";
                object.teamId = "";
                object.teamPaymentUser = false;
            }
            if (message.slackUserId != null && message.hasOwnProperty("slackUserId"))
                object.slackUserId = message.slackUserId;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.teamId != null && message.hasOwnProperty("teamId"))
                object.teamId = message.teamId;
            if (message.teamPaymentUser != null && message.hasOwnProperty("teamPaymentUser"))
                object.teamPaymentUser = message.teamPaymentUser;
            return object;
        };

        /**
         * Converts this SlackUser to JSON.
         * @function toJSON
         * @memberof api.SlackUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SlackUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SlackUser;
    })();

    api.Group = (function() {

        /**
         * Properties of a Group.
         * @memberof api
         * @interface IGroup
         * @property {string|null} [groupId] Group groupId
         * @property {string|null} [groupName] Group groupName
         * @property {string|null} [description] Group description
         * @property {Array.<api.IMember>|null} [membersOf] Group membersOf
         * @property {Array.<api.IMember>|null} [members] Group members
         * @property {number|Long|null} [createDate] Group createDate
         * @property {number|Long|null} [updateDate] Group updateDate
         */

        /**
         * Constructs a new Group.
         * @memberof api
         * @classdesc Represents a Group.
         * @implements IGroup
         * @constructor
         * @param {api.IGroup=} [properties] Properties to set
         */
        function Group(properties) {
            this.membersOf = [];
            this.members = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Group groupId.
         * @member {string} groupId
         * @memberof api.Group
         * @instance
         */
        Group.prototype.groupId = "";

        /**
         * Group groupName.
         * @member {string} groupName
         * @memberof api.Group
         * @instance
         */
        Group.prototype.groupName = "";

        /**
         * Group description.
         * @member {string} description
         * @memberof api.Group
         * @instance
         */
        Group.prototype.description = "";

        /**
         * Group membersOf.
         * @member {Array.<api.IMember>} membersOf
         * @memberof api.Group
         * @instance
         */
        Group.prototype.membersOf = $util.emptyArray;

        /**
         * Group members.
         * @member {Array.<api.IMember>} members
         * @memberof api.Group
         * @instance
         */
        Group.prototype.members = $util.emptyArray;

        /**
         * Group createDate.
         * @member {number|Long} createDate
         * @memberof api.Group
         * @instance
         */
        Group.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Group updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Group
         * @instance
         */
        Group.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Group instance using the specified properties.
         * @function create
         * @memberof api.Group
         * @static
         * @param {api.IGroup=} [properties] Properties to set
         * @returns {api.Group} Group instance
         */
        Group.create = function create(properties) {
            return new Group(properties);
        };

        /**
         * Encodes the specified Group message. Does not implicitly {@link api.Group.verify|verify} messages.
         * @function encode
         * @memberof api.Group
         * @static
         * @param {api.IGroup} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupId);
            if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.groupName);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            if (message.membersOf != null && message.membersOf.length)
                for (let i = 0; i < message.membersOf.length; ++i)
                    $root.api.Member.encode(message.membersOf[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.api.Member.encode(message.members[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.updateDate);
            return writer;
        };

        /**
         * Encodes the specified Group message, length delimited. Does not implicitly {@link api.Group.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Group
         * @static
         * @param {api.IGroup} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Group message from the specified reader or buffer.
         * @function decode
         * @memberof api.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Group();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.groupId = reader.string();
                    break;
                case 2:
                    message.groupName = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                case 4:
                    if (!(message.membersOf && message.membersOf.length))
                        message.membersOf = [];
                    message.membersOf.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.members && message.members.length))
                        message.members = [];
                    message.members.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createDate = reader.int64();
                    break;
                case 7:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Group message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Group message.
         * @function verify
         * @memberof api.Group
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Group.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                if (!$util.isString(message.groupId))
                    return "groupId: string expected";
            if (message.groupName != null && message.hasOwnProperty("groupName"))
                if (!$util.isString(message.groupName))
                    return "groupName: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.membersOf != null && message.hasOwnProperty("membersOf")) {
                if (!Array.isArray(message.membersOf))
                    return "membersOf: array expected";
                for (let i = 0; i < message.membersOf.length; ++i) {
                    let error = $root.api.Member.verify(message.membersOf[i]);
                    if (error)
                        return "membersOf." + error;
                }
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.api.Member.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a Group message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Group
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Group} Group
         */
        Group.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Group)
                return object;
            let message = new $root.api.Group();
            if (object.groupId != null)
                message.groupId = String(object.groupId);
            if (object.groupName != null)
                message.groupName = String(object.groupName);
            if (object.description != null)
                message.description = String(object.description);
            if (object.membersOf) {
                if (!Array.isArray(object.membersOf))
                    throw TypeError(".api.Group.membersOf: array expected");
                message.membersOf = [];
                for (let i = 0; i < object.membersOf.length; ++i) {
                    if (typeof object.membersOf[i] !== "object")
                        throw TypeError(".api.Group.membersOf: object expected");
                    message.membersOf[i] = $root.api.Member.fromObject(object.membersOf[i]);
                }
            }
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".api.Group.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".api.Group.members: object expected");
                    message.members[i] = $root.api.Member.fromObject(object.members[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Group message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Group
         * @static
         * @param {api.Group} message Group
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Group.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.membersOf = [];
                object.members = [];
            }
            if (options.defaults) {
                object.groupId = "";
                object.groupName = "";
                object.description = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
            }
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                object.groupId = message.groupId;
            if (message.groupName != null && message.hasOwnProperty("groupName"))
                object.groupName = message.groupName;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.membersOf && message.membersOf.length) {
                object.membersOf = [];
                for (let j = 0; j < message.membersOf.length; ++j)
                    object.membersOf[j] = $root.api.Member.toObject(message.membersOf[j], options);
            }
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.api.Member.toObject(message.members[j], options);
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            return object;
        };

        /**
         * Converts this Group to JSON.
         * @function toJSON
         * @memberof api.Group
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Group.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Group;
    })();

    api.UserOption = (function() {

        /**
         * Properties of a UserOption.
         * @memberof api
         * @interface IUserOption
         * @property {string|null} [userId] UserOption userId
         * @property {api.IUser|null} [user] UserOption user
         * @property {string|null} [firstName] UserOption firstName
         * @property {string|null} [lastName] UserOption lastName
         */

        /**
         * Constructs a new UserOption.
         * @memberof api
         * @classdesc Represents a UserOption.
         * @implements IUserOption
         * @constructor
         * @param {api.IUserOption=} [properties] Properties to set
         */
        function UserOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserOption userId.
         * @member {string} userId
         * @memberof api.UserOption
         * @instance
         */
        UserOption.prototype.userId = "";

        /**
         * UserOption user.
         * @member {api.IUser|null|undefined} user
         * @memberof api.UserOption
         * @instance
         */
        UserOption.prototype.user = null;

        /**
         * UserOption firstName.
         * @member {string} firstName
         * @memberof api.UserOption
         * @instance
         */
        UserOption.prototype.firstName = "";

        /**
         * UserOption lastName.
         * @member {string} lastName
         * @memberof api.UserOption
         * @instance
         */
        UserOption.prototype.lastName = "";

        /**
         * Creates a new UserOption instance using the specified properties.
         * @function create
         * @memberof api.UserOption
         * @static
         * @param {api.IUserOption=} [properties] Properties to set
         * @returns {api.UserOption} UserOption instance
         */
        UserOption.create = function create(properties) {
            return new UserOption(properties);
        };

        /**
         * Encodes the specified UserOption message. Does not implicitly {@link api.UserOption.verify|verify} messages.
         * @function encode
         * @memberof api.UserOption
         * @static
         * @param {api.IUserOption} message UserOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            return writer;
        };

        /**
         * Encodes the specified UserOption message, length delimited. Does not implicitly {@link api.UserOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserOption
         * @static
         * @param {api.IUserOption} message UserOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserOption} UserOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.user = $root.api.User.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserOption} UserOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserOption message.
         * @function verify
         * @memberof api.UserOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            return null;
        };

        /**
         * Creates a UserOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserOption} UserOption
         */
        UserOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserOption)
                return object;
            let message = new $root.api.UserOption();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.UserOption.user: object expected");
                message.user = $root.api.User.fromObject(object.user);
            }
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            return message;
        };

        /**
         * Creates a plain object from a UserOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserOption
         * @static
         * @param {api.UserOption} message UserOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.user = null;
                object.firstName = "";
                object.lastName = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.User.toObject(message.user, options);
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            return object;
        };

        /**
         * Converts this UserOption to JSON.
         * @function toJSON
         * @memberof api.UserOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserOption;
    })();

    api.GroupOption = (function() {

        /**
         * Properties of a GroupOption.
         * @memberof api
         * @interface IGroupOption
         * @property {string|null} [groupId] GroupOption groupId
         * @property {api.IGroup|null} [group] GroupOption group
         * @property {string|null} [description] GroupOption description
         */

        /**
         * Constructs a new GroupOption.
         * @memberof api
         * @classdesc Represents a GroupOption.
         * @implements IGroupOption
         * @constructor
         * @param {api.IGroupOption=} [properties] Properties to set
         */
        function GroupOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GroupOption groupId.
         * @member {string} groupId
         * @memberof api.GroupOption
         * @instance
         */
        GroupOption.prototype.groupId = "";

        /**
         * GroupOption group.
         * @member {api.IGroup|null|undefined} group
         * @memberof api.GroupOption
         * @instance
         */
        GroupOption.prototype.group = null;

        /**
         * GroupOption description.
         * @member {string} description
         * @memberof api.GroupOption
         * @instance
         */
        GroupOption.prototype.description = "";

        /**
         * Creates a new GroupOption instance using the specified properties.
         * @function create
         * @memberof api.GroupOption
         * @static
         * @param {api.IGroupOption=} [properties] Properties to set
         * @returns {api.GroupOption} GroupOption instance
         */
        GroupOption.create = function create(properties) {
            return new GroupOption(properties);
        };

        /**
         * Encodes the specified GroupOption message. Does not implicitly {@link api.GroupOption.verify|verify} messages.
         * @function encode
         * @memberof api.GroupOption
         * @static
         * @param {api.IGroupOption} message GroupOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupId);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.Group.encode(message.group, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified GroupOption message, length delimited. Does not implicitly {@link api.GroupOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GroupOption
         * @static
         * @param {api.IGroupOption} message GroupOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GroupOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.GroupOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GroupOption} GroupOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GroupOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.groupId = reader.string();
                    break;
                case 2:
                    message.group = $root.api.Group.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GroupOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GroupOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GroupOption} GroupOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GroupOption message.
         * @function verify
         * @memberof api.GroupOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GroupOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                if (!$util.isString(message.groupId))
                    return "groupId: string expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.Group.verify(message.group);
                if (error)
                    return "group." + error;
            }
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a GroupOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GroupOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GroupOption} GroupOption
         */
        GroupOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GroupOption)
                return object;
            let message = new $root.api.GroupOption();
            if (object.groupId != null)
                message.groupId = String(object.groupId);
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.GroupOption.group: object expected");
                message.group = $root.api.Group.fromObject(object.group);
            }
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a GroupOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GroupOption
         * @static
         * @param {api.GroupOption} message GroupOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GroupOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.groupId = "";
                object.group = null;
                object.description = "";
            }
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                object.groupId = message.groupId;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.Group.toObject(message.group, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this GroupOption to JSON.
         * @function toJSON
         * @memberof api.GroupOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GroupOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GroupOption;
    })();

    api.MemberObject = (function() {

        /**
         * Properties of a MemberObject.
         * @memberof api
         * @interface IMemberObject
         * @property {api.IGroupOption|null} [group] MemberObject group
         */

        /**
         * Constructs a new MemberObject.
         * @memberof api
         * @classdesc Represents a MemberObject.
         * @implements IMemberObject
         * @constructor
         * @param {api.IMemberObject=} [properties] Properties to set
         */
        function MemberObject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberObject group.
         * @member {api.IGroupOption|null|undefined} group
         * @memberof api.MemberObject
         * @instance
         */
        MemberObject.prototype.group = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MemberObject option.
         * @member {"group"|undefined} option
         * @memberof api.MemberObject
         * @instance
         */
        Object.defineProperty(MemberObject.prototype, "option", {
            get: $util.oneOfGetter($oneOfFields = ["group"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MemberObject instance using the specified properties.
         * @function create
         * @memberof api.MemberObject
         * @static
         * @param {api.IMemberObject=} [properties] Properties to set
         * @returns {api.MemberObject} MemberObject instance
         */
        MemberObject.create = function create(properties) {
            return new MemberObject(properties);
        };

        /**
         * Encodes the specified MemberObject message. Does not implicitly {@link api.MemberObject.verify|verify} messages.
         * @function encode
         * @memberof api.MemberObject
         * @static
         * @param {api.IMemberObject} message MemberObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupOption.encode(message.group, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberObject message, length delimited. Does not implicitly {@link api.MemberObject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberObject
         * @static
         * @param {api.IMemberObject} message MemberObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberObject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberObject message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberObject} MemberObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberObject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.group = $root.api.GroupOption.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberObject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberObject} MemberObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberObject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberObject message.
         * @function verify
         * @memberof api.MemberObject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberObject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.group != null && message.hasOwnProperty("group")) {
                properties.option = 1;
                {
                    let error = $root.api.GroupOption.verify(message.group);
                    if (error)
                        return "group." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MemberObject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberObject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberObject} MemberObject
         */
        MemberObject.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberObject)
                return object;
            let message = new $root.api.MemberObject();
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.MemberObject.group: object expected");
                message.group = $root.api.GroupOption.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberObject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberObject
         * @static
         * @param {api.MemberObject} message MemberObject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberObject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.group != null && message.hasOwnProperty("group")) {
                object.group = $root.api.GroupOption.toObject(message.group, options);
                if (options.oneofs)
                    object.option = "group";
            }
            return object;
        };

        /**
         * Converts this MemberObject to JSON.
         * @function toJSON
         * @memberof api.MemberObject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberObject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberObject;
    })();

    api.MemberEntity = (function() {

        /**
         * Properties of a MemberEntity.
         * @memberof api
         * @interface IMemberEntity
         * @property {api.IUserOption|null} [user] MemberEntity user
         * @property {api.IGroupOption|null} [group] MemberEntity group
         */

        /**
         * Constructs a new MemberEntity.
         * @memberof api
         * @classdesc Represents a MemberEntity.
         * @implements IMemberEntity
         * @constructor
         * @param {api.IMemberEntity=} [properties] Properties to set
         */
        function MemberEntity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberEntity user.
         * @member {api.IUserOption|null|undefined} user
         * @memberof api.MemberEntity
         * @instance
         */
        MemberEntity.prototype.user = null;

        /**
         * MemberEntity group.
         * @member {api.IGroupOption|null|undefined} group
         * @memberof api.MemberEntity
         * @instance
         */
        MemberEntity.prototype.group = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MemberEntity option.
         * @member {"user"|"group"|undefined} option
         * @memberof api.MemberEntity
         * @instance
         */
        Object.defineProperty(MemberEntity.prototype, "option", {
            get: $util.oneOfGetter($oneOfFields = ["user", "group"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MemberEntity instance using the specified properties.
         * @function create
         * @memberof api.MemberEntity
         * @static
         * @param {api.IMemberEntity=} [properties] Properties to set
         * @returns {api.MemberEntity} MemberEntity instance
         */
        MemberEntity.create = function create(properties) {
            return new MemberEntity(properties);
        };

        /**
         * Encodes the specified MemberEntity message. Does not implicitly {@link api.MemberEntity.verify|verify} messages.
         * @function encode
         * @memberof api.MemberEntity
         * @static
         * @param {api.IMemberEntity} message MemberEntity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberEntity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.UserOption.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupOption.encode(message.group, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberEntity message, length delimited. Does not implicitly {@link api.MemberEntity.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberEntity
         * @static
         * @param {api.IMemberEntity} message MemberEntity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberEntity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberEntity message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberEntity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberEntity} MemberEntity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberEntity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberEntity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.api.UserOption.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.group = $root.api.GroupOption.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberEntity message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberEntity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberEntity} MemberEntity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberEntity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberEntity message.
         * @function verify
         * @memberof api.MemberEntity
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberEntity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.user != null && message.hasOwnProperty("user")) {
                properties.option = 1;
                {
                    let error = $root.api.UserOption.verify(message.user);
                    if (error)
                        return "user." + error;
                }
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.GroupOption.verify(message.group);
                    if (error)
                        return "group." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MemberEntity message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberEntity
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberEntity} MemberEntity
         */
        MemberEntity.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberEntity)
                return object;
            let message = new $root.api.MemberEntity();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.MemberEntity.user: object expected");
                message.user = $root.api.UserOption.fromObject(object.user);
            }
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.MemberEntity.group: object expected");
                message.group = $root.api.GroupOption.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberEntity message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberEntity
         * @static
         * @param {api.MemberEntity} message MemberEntity
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberEntity.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.user != null && message.hasOwnProperty("user")) {
                object.user = $root.api.UserOption.toObject(message.user, options);
                if (options.oneofs)
                    object.option = "user";
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                object.group = $root.api.GroupOption.toObject(message.group, options);
                if (options.oneofs)
                    object.option = "group";
            }
            return object;
        };

        /**
         * Converts this MemberEntity to JSON.
         * @function toJSON
         * @memberof api.MemberEntity
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberEntity.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberEntity;
    })();

    api.Member = (function() {

        /**
         * Properties of a Member.
         * @memberof api
         * @interface IMember
         * @property {api.IMemberObject|null} [obj] Member obj
         * @property {api.IMemberEntity|null} [entity] Member entity
         * @property {boolean|null} [instructor] Member instructor
         * @property {boolean|null} [owner] Member owner
         * @property {boolean|null} [modify] Member modify
         * @property {boolean|null} [accepted] Member accepted
         * @property {number|Long|null} [createDate] Member createDate
         * @property {number|Long|null} [updateDate] Member updateDate
         * @property {string|null} [membershipId] Member membershipId
         */

        /**
         * Constructs a new Member.
         * @memberof api
         * @classdesc Represents a Member.
         * @implements IMember
         * @constructor
         * @param {api.IMember=} [properties] Properties to set
         */
        function Member(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Member obj.
         * @member {api.IMemberObject|null|undefined} obj
         * @memberof api.Member
         * @instance
         */
        Member.prototype.obj = null;

        /**
         * Member entity.
         * @member {api.IMemberEntity|null|undefined} entity
         * @memberof api.Member
         * @instance
         */
        Member.prototype.entity = null;

        /**
         * Member instructor.
         * @member {boolean} instructor
         * @memberof api.Member
         * @instance
         */
        Member.prototype.instructor = false;

        /**
         * Member owner.
         * @member {boolean} owner
         * @memberof api.Member
         * @instance
         */
        Member.prototype.owner = false;

        /**
         * Member modify.
         * @member {boolean} modify
         * @memberof api.Member
         * @instance
         */
        Member.prototype.modify = false;

        /**
         * Member accepted.
         * @member {boolean} accepted
         * @memberof api.Member
         * @instance
         */
        Member.prototype.accepted = false;

        /**
         * Member createDate.
         * @member {number|Long} createDate
         * @memberof api.Member
         * @instance
         */
        Member.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Member updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Member
         * @instance
         */
        Member.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Member membershipId.
         * @member {string} membershipId
         * @memberof api.Member
         * @instance
         */
        Member.prototype.membershipId = "";

        /**
         * Creates a new Member instance using the specified properties.
         * @function create
         * @memberof api.Member
         * @static
         * @param {api.IMember=} [properties] Properties to set
         * @returns {api.Member} Member instance
         */
        Member.create = function create(properties) {
            return new Member(properties);
        };

        /**
         * Encodes the specified Member message. Does not implicitly {@link api.Member.verify|verify} messages.
         * @function encode
         * @memberof api.Member
         * @static
         * @param {api.IMember} message Member message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Member.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
                $root.api.MemberObject.encode(message.obj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.api.MemberEntity.encode(message.entity, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.instructor != null && Object.hasOwnProperty.call(message, "instructor"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.instructor);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.owner);
            if (message.modify != null && Object.hasOwnProperty.call(message, "modify"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.modify);
            if (message.accepted != null && Object.hasOwnProperty.call(message, "accepted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.accepted);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.updateDate);
            if (message.membershipId != null && Object.hasOwnProperty.call(message, "membershipId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.membershipId);
            return writer;
        };

        /**
         * Encodes the specified Member message, length delimited. Does not implicitly {@link api.Member.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Member
         * @static
         * @param {api.IMember} message Member message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Member.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Member message from the specified reader or buffer.
         * @function decode
         * @memberof api.Member
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Member} Member
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Member.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Member();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.obj = $root.api.MemberObject.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.entity = $root.api.MemberEntity.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.instructor = reader.bool();
                    break;
                case 4:
                    message.owner = reader.bool();
                    break;
                case 5:
                    message.modify = reader.bool();
                    break;
                case 6:
                    message.accepted = reader.bool();
                    break;
                case 7:
                    message.createDate = reader.int64();
                    break;
                case 8:
                    message.updateDate = reader.int64();
                    break;
                case 9:
                    message.membershipId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Member message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Member
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Member} Member
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Member.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Member message.
         * @function verify
         * @memberof api.Member
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Member.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.obj != null && message.hasOwnProperty("obj")) {
                let error = $root.api.MemberObject.verify(message.obj);
                if (error)
                    return "obj." + error;
            }
            if (message.entity != null && message.hasOwnProperty("entity")) {
                let error = $root.api.MemberEntity.verify(message.entity);
                if (error)
                    return "entity." + error;
            }
            if (message.instructor != null && message.hasOwnProperty("instructor"))
                if (typeof message.instructor !== "boolean")
                    return "instructor: boolean expected";
            if (message.owner != null && message.hasOwnProperty("owner"))
                if (typeof message.owner !== "boolean")
                    return "owner: boolean expected";
            if (message.modify != null && message.hasOwnProperty("modify"))
                if (typeof message.modify !== "boolean")
                    return "modify: boolean expected";
            if (message.accepted != null && message.hasOwnProperty("accepted"))
                if (typeof message.accepted !== "boolean")
                    return "accepted: boolean expected";
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            if (message.membershipId != null && message.hasOwnProperty("membershipId"))
                if (!$util.isString(message.membershipId))
                    return "membershipId: string expected";
            return null;
        };

        /**
         * Creates a Member message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Member
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Member} Member
         */
        Member.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Member)
                return object;
            let message = new $root.api.Member();
            if (object.obj != null) {
                if (typeof object.obj !== "object")
                    throw TypeError(".api.Member.obj: object expected");
                message.obj = $root.api.MemberObject.fromObject(object.obj);
            }
            if (object.entity != null) {
                if (typeof object.entity !== "object")
                    throw TypeError(".api.Member.entity: object expected");
                message.entity = $root.api.MemberEntity.fromObject(object.entity);
            }
            if (object.instructor != null)
                message.instructor = Boolean(object.instructor);
            if (object.owner != null)
                message.owner = Boolean(object.owner);
            if (object.modify != null)
                message.modify = Boolean(object.modify);
            if (object.accepted != null)
                message.accepted = Boolean(object.accepted);
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            if (object.membershipId != null)
                message.membershipId = String(object.membershipId);
            return message;
        };

        /**
         * Creates a plain object from a Member message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Member
         * @static
         * @param {api.Member} message Member
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Member.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.obj = null;
                object.entity = null;
                object.instructor = false;
                object.owner = false;
                object.modify = false;
                object.accepted = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                object.membershipId = "";
            }
            if (message.obj != null && message.hasOwnProperty("obj"))
                object.obj = $root.api.MemberObject.toObject(message.obj, options);
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = $root.api.MemberEntity.toObject(message.entity, options);
            if (message.instructor != null && message.hasOwnProperty("instructor"))
                object.instructor = message.instructor;
            if (message.owner != null && message.hasOwnProperty("owner"))
                object.owner = message.owner;
            if (message.modify != null && message.hasOwnProperty("modify"))
                object.modify = message.modify;
            if (message.accepted != null && message.hasOwnProperty("accepted"))
                object.accepted = message.accepted;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.membershipId != null && message.hasOwnProperty("membershipId"))
                object.membershipId = message.membershipId;
            return object;
        };

        /**
         * Converts this Member to JSON.
         * @function toJSON
         * @memberof api.Member
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Member.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Member;
    })();

    api.EmailPassword = (function() {

        /**
         * Properties of an EmailPassword.
         * @memberof api
         * @interface IEmailPassword
         * @property {string|null} [emailAddress] EmailPassword emailAddress
         * @property {string|null} [password] EmailPassword password
         */

        /**
         * Constructs a new EmailPassword.
         * @memberof api
         * @classdesc Represents an EmailPassword.
         * @implements IEmailPassword
         * @constructor
         * @param {api.IEmailPassword=} [properties] Properties to set
         */
        function EmailPassword(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailPassword emailAddress.
         * @member {string} emailAddress
         * @memberof api.EmailPassword
         * @instance
         */
        EmailPassword.prototype.emailAddress = "";

        /**
         * EmailPassword password.
         * @member {string} password
         * @memberof api.EmailPassword
         * @instance
         */
        EmailPassword.prototype.password = "";

        /**
         * Creates a new EmailPassword instance using the specified properties.
         * @function create
         * @memberof api.EmailPassword
         * @static
         * @param {api.IEmailPassword=} [properties] Properties to set
         * @returns {api.EmailPassword} EmailPassword instance
         */
        EmailPassword.create = function create(properties) {
            return new EmailPassword(properties);
        };

        /**
         * Encodes the specified EmailPassword message. Does not implicitly {@link api.EmailPassword.verify|verify} messages.
         * @function encode
         * @memberof api.EmailPassword
         * @static
         * @param {api.IEmailPassword} message EmailPassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailPassword.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailAddress);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified EmailPassword message, length delimited. Does not implicitly {@link api.EmailPassword.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.EmailPassword
         * @static
         * @param {api.IEmailPassword} message EmailPassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailPassword.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailPassword message from the specified reader or buffer.
         * @function decode
         * @memberof api.EmailPassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.EmailPassword} EmailPassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailPassword.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.EmailPassword();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.emailAddress = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailPassword message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.EmailPassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.EmailPassword} EmailPassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailPassword.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailPassword message.
         * @function verify
         * @memberof api.EmailPassword
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailPassword.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates an EmailPassword message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.EmailPassword
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.EmailPassword} EmailPassword
         */
        EmailPassword.fromObject = function fromObject(object) {
            if (object instanceof $root.api.EmailPassword)
                return object;
            let message = new $root.api.EmailPassword();
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from an EmailPassword message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.EmailPassword
         * @static
         * @param {api.EmailPassword} message EmailPassword
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailPassword.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.emailAddress = "";
                object.password = "";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this EmailPassword to JSON.
         * @function toJSON
         * @memberof api.EmailPassword
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailPassword.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EmailPassword;
    })();

    api.GoogleCredential = (function() {

        /**
         * Properties of a GoogleCredential.
         * @memberof api
         * @interface IGoogleCredential
         * @property {string|null} [jwtToken] GoogleCredential jwtToken
         */

        /**
         * Constructs a new GoogleCredential.
         * @memberof api
         * @classdesc Represents a GoogleCredential.
         * @implements IGoogleCredential
         * @constructor
         * @param {api.IGoogleCredential=} [properties] Properties to set
         */
        function GoogleCredential(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GoogleCredential jwtToken.
         * @member {string} jwtToken
         * @memberof api.GoogleCredential
         * @instance
         */
        GoogleCredential.prototype.jwtToken = "";

        /**
         * Creates a new GoogleCredential instance using the specified properties.
         * @function create
         * @memberof api.GoogleCredential
         * @static
         * @param {api.IGoogleCredential=} [properties] Properties to set
         * @returns {api.GoogleCredential} GoogleCredential instance
         */
        GoogleCredential.create = function create(properties) {
            return new GoogleCredential(properties);
        };

        /**
         * Encodes the specified GoogleCredential message. Does not implicitly {@link api.GoogleCredential.verify|verify} messages.
         * @function encode
         * @memberof api.GoogleCredential
         * @static
         * @param {api.IGoogleCredential} message GoogleCredential message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleCredential.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jwtToken != null && Object.hasOwnProperty.call(message, "jwtToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.jwtToken);
            return writer;
        };

        /**
         * Encodes the specified GoogleCredential message, length delimited. Does not implicitly {@link api.GoogleCredential.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GoogleCredential
         * @static
         * @param {api.IGoogleCredential} message GoogleCredential message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleCredential.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GoogleCredential message from the specified reader or buffer.
         * @function decode
         * @memberof api.GoogleCredential
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GoogleCredential} GoogleCredential
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleCredential.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GoogleCredential();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.jwtToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GoogleCredential message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GoogleCredential
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GoogleCredential} GoogleCredential
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleCredential.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GoogleCredential message.
         * @function verify
         * @memberof api.GoogleCredential
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GoogleCredential.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jwtToken != null && message.hasOwnProperty("jwtToken"))
                if (!$util.isString(message.jwtToken))
                    return "jwtToken: string expected";
            return null;
        };

        /**
         * Creates a GoogleCredential message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GoogleCredential
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GoogleCredential} GoogleCredential
         */
        GoogleCredential.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GoogleCredential)
                return object;
            let message = new $root.api.GoogleCredential();
            if (object.jwtToken != null)
                message.jwtToken = String(object.jwtToken);
            return message;
        };

        /**
         * Creates a plain object from a GoogleCredential message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GoogleCredential
         * @static
         * @param {api.GoogleCredential} message GoogleCredential
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GoogleCredential.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.jwtToken = "";
            if (message.jwtToken != null && message.hasOwnProperty("jwtToken"))
                object.jwtToken = message.jwtToken;
            return object;
        };

        /**
         * Converts this GoogleCredential to JSON.
         * @function toJSON
         * @memberof api.GoogleCredential
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GoogleCredential.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GoogleCredential;
    })();

    api.AppleCredential = (function() {

        /**
         * Properties of an AppleCredential.
         * @memberof api
         * @interface IAppleCredential
         * @property {string|null} [authToken] AppleCredential authToken
         * @property {string|null} [clientId] AppleCredential clientId
         */

        /**
         * Constructs a new AppleCredential.
         * @memberof api
         * @classdesc Represents an AppleCredential.
         * @implements IAppleCredential
         * @constructor
         * @param {api.IAppleCredential=} [properties] Properties to set
         */
        function AppleCredential(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppleCredential authToken.
         * @member {string} authToken
         * @memberof api.AppleCredential
         * @instance
         */
        AppleCredential.prototype.authToken = "";

        /**
         * AppleCredential clientId.
         * @member {string} clientId
         * @memberof api.AppleCredential
         * @instance
         */
        AppleCredential.prototype.clientId = "";

        /**
         * Creates a new AppleCredential instance using the specified properties.
         * @function create
         * @memberof api.AppleCredential
         * @static
         * @param {api.IAppleCredential=} [properties] Properties to set
         * @returns {api.AppleCredential} AppleCredential instance
         */
        AppleCredential.create = function create(properties) {
            return new AppleCredential(properties);
        };

        /**
         * Encodes the specified AppleCredential message. Does not implicitly {@link api.AppleCredential.verify|verify} messages.
         * @function encode
         * @memberof api.AppleCredential
         * @static
         * @param {api.IAppleCredential} message AppleCredential message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppleCredential.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.authToken);
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientId);
            return writer;
        };

        /**
         * Encodes the specified AppleCredential message, length delimited. Does not implicitly {@link api.AppleCredential.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AppleCredential
         * @static
         * @param {api.IAppleCredential} message AppleCredential message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppleCredential.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppleCredential message from the specified reader or buffer.
         * @function decode
         * @memberof api.AppleCredential
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AppleCredential} AppleCredential
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppleCredential.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AppleCredential();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.authToken = reader.string();
                    break;
                case 2:
                    message.clientId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppleCredential message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AppleCredential
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AppleCredential} AppleCredential
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppleCredential.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppleCredential message.
         * @function verify
         * @memberof api.AppleCredential
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppleCredential.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.authToken != null && message.hasOwnProperty("authToken"))
                if (!$util.isString(message.authToken))
                    return "authToken: string expected";
            if (message.clientId != null && message.hasOwnProperty("clientId"))
                if (!$util.isString(message.clientId))
                    return "clientId: string expected";
            return null;
        };

        /**
         * Creates an AppleCredential message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AppleCredential
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AppleCredential} AppleCredential
         */
        AppleCredential.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AppleCredential)
                return object;
            let message = new $root.api.AppleCredential();
            if (object.authToken != null)
                message.authToken = String(object.authToken);
            if (object.clientId != null)
                message.clientId = String(object.clientId);
            return message;
        };

        /**
         * Creates a plain object from an AppleCredential message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AppleCredential
         * @static
         * @param {api.AppleCredential} message AppleCredential
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppleCredential.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.authToken = "";
                object.clientId = "";
            }
            if (message.authToken != null && message.hasOwnProperty("authToken"))
                object.authToken = message.authToken;
            if (message.clientId != null && message.hasOwnProperty("clientId"))
                object.clientId = message.clientId;
            return object;
        };

        /**
         * Converts this AppleCredential to JSON.
         * @function toJSON
         * @memberof api.AppleCredential
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppleCredential.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppleCredential;
    })();

    api.SlackOAuth = (function() {

        /**
         * Properties of a SlackOAuth.
         * @memberof api
         * @interface ISlackOAuth
         * @property {string|null} [slackAuthCode] SlackOAuth slackAuthCode
         * @property {string|null} [slackRedirectUrl] SlackOAuth slackRedirectUrl
         */

        /**
         * Constructs a new SlackOAuth.
         * @memberof api
         * @classdesc Represents a SlackOAuth.
         * @implements ISlackOAuth
         * @constructor
         * @param {api.ISlackOAuth=} [properties] Properties to set
         */
        function SlackOAuth(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SlackOAuth slackAuthCode.
         * @member {string} slackAuthCode
         * @memberof api.SlackOAuth
         * @instance
         */
        SlackOAuth.prototype.slackAuthCode = "";

        /**
         * SlackOAuth slackRedirectUrl.
         * @member {string} slackRedirectUrl
         * @memberof api.SlackOAuth
         * @instance
         */
        SlackOAuth.prototype.slackRedirectUrl = "";

        /**
         * Creates a new SlackOAuth instance using the specified properties.
         * @function create
         * @memberof api.SlackOAuth
         * @static
         * @param {api.ISlackOAuth=} [properties] Properties to set
         * @returns {api.SlackOAuth} SlackOAuth instance
         */
        SlackOAuth.create = function create(properties) {
            return new SlackOAuth(properties);
        };

        /**
         * Encodes the specified SlackOAuth message. Does not implicitly {@link api.SlackOAuth.verify|verify} messages.
         * @function encode
         * @memberof api.SlackOAuth
         * @static
         * @param {api.ISlackOAuth} message SlackOAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackOAuth.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slackAuthCode != null && Object.hasOwnProperty.call(message, "slackAuthCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slackAuthCode);
            if (message.slackRedirectUrl != null && Object.hasOwnProperty.call(message, "slackRedirectUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.slackRedirectUrl);
            return writer;
        };

        /**
         * Encodes the specified SlackOAuth message, length delimited. Does not implicitly {@link api.SlackOAuth.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SlackOAuth
         * @static
         * @param {api.ISlackOAuth} message SlackOAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackOAuth.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SlackOAuth message from the specified reader or buffer.
         * @function decode
         * @memberof api.SlackOAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SlackOAuth} SlackOAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackOAuth.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SlackOAuth();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slackAuthCode = reader.string();
                    break;
                case 2:
                    message.slackRedirectUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SlackOAuth message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SlackOAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SlackOAuth} SlackOAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackOAuth.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SlackOAuth message.
         * @function verify
         * @memberof api.SlackOAuth
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SlackOAuth.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.slackAuthCode != null && message.hasOwnProperty("slackAuthCode"))
                if (!$util.isString(message.slackAuthCode))
                    return "slackAuthCode: string expected";
            if (message.slackRedirectUrl != null && message.hasOwnProperty("slackRedirectUrl"))
                if (!$util.isString(message.slackRedirectUrl))
                    return "slackRedirectUrl: string expected";
            return null;
        };

        /**
         * Creates a SlackOAuth message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SlackOAuth
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SlackOAuth} SlackOAuth
         */
        SlackOAuth.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SlackOAuth)
                return object;
            let message = new $root.api.SlackOAuth();
            if (object.slackAuthCode != null)
                message.slackAuthCode = String(object.slackAuthCode);
            if (object.slackRedirectUrl != null)
                message.slackRedirectUrl = String(object.slackRedirectUrl);
            return message;
        };

        /**
         * Creates a plain object from a SlackOAuth message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SlackOAuth
         * @static
         * @param {api.SlackOAuth} message SlackOAuth
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SlackOAuth.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.slackAuthCode = "";
                object.slackRedirectUrl = "";
            }
            if (message.slackAuthCode != null && message.hasOwnProperty("slackAuthCode"))
                object.slackAuthCode = message.slackAuthCode;
            if (message.slackRedirectUrl != null && message.hasOwnProperty("slackRedirectUrl"))
                object.slackRedirectUrl = message.slackRedirectUrl;
            return object;
        };

        /**
         * Converts this SlackOAuth to JSON.
         * @function toJSON
         * @memberof api.SlackOAuth
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SlackOAuth.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SlackOAuth;
    })();

    api.SlackPassthrough = (function() {

        /**
         * Properties of a SlackPassthrough.
         * @memberof api
         * @interface ISlackPassthrough
         * @property {string|null} [sessionId] SlackPassthrough sessionId
         */

        /**
         * Constructs a new SlackPassthrough.
         * @memberof api
         * @classdesc Represents a SlackPassthrough.
         * @implements ISlackPassthrough
         * @constructor
         * @param {api.ISlackPassthrough=} [properties] Properties to set
         */
        function SlackPassthrough(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SlackPassthrough sessionId.
         * @member {string} sessionId
         * @memberof api.SlackPassthrough
         * @instance
         */
        SlackPassthrough.prototype.sessionId = "";

        /**
         * Creates a new SlackPassthrough instance using the specified properties.
         * @function create
         * @memberof api.SlackPassthrough
         * @static
         * @param {api.ISlackPassthrough=} [properties] Properties to set
         * @returns {api.SlackPassthrough} SlackPassthrough instance
         */
        SlackPassthrough.create = function create(properties) {
            return new SlackPassthrough(properties);
        };

        /**
         * Encodes the specified SlackPassthrough message. Does not implicitly {@link api.SlackPassthrough.verify|verify} messages.
         * @function encode
         * @memberof api.SlackPassthrough
         * @static
         * @param {api.ISlackPassthrough} message SlackPassthrough message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackPassthrough.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified SlackPassthrough message, length delimited. Does not implicitly {@link api.SlackPassthrough.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SlackPassthrough
         * @static
         * @param {api.ISlackPassthrough} message SlackPassthrough message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SlackPassthrough.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SlackPassthrough message from the specified reader or buffer.
         * @function decode
         * @memberof api.SlackPassthrough
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SlackPassthrough} SlackPassthrough
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackPassthrough.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SlackPassthrough();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sessionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SlackPassthrough message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SlackPassthrough
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SlackPassthrough} SlackPassthrough
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SlackPassthrough.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SlackPassthrough message.
         * @function verify
         * @memberof api.SlackPassthrough
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SlackPassthrough.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates a SlackPassthrough message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SlackPassthrough
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SlackPassthrough} SlackPassthrough
         */
        SlackPassthrough.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SlackPassthrough)
                return object;
            let message = new $root.api.SlackPassthrough();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from a SlackPassthrough message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SlackPassthrough
         * @static
         * @param {api.SlackPassthrough} message SlackPassthrough
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SlackPassthrough.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this SlackPassthrough to JSON.
         * @function toJSON
         * @memberof api.SlackPassthrough
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SlackPassthrough.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SlackPassthrough;
    })();

    api.MergeUserRequest = (function() {

        /**
         * Properties of a MergeUserRequest.
         * @memberof api
         * @interface IMergeUserRequest
         * @property {string|null} [primaryToken] MergeUserRequest primaryToken
         * @property {string|null} [secondaryToken] MergeUserRequest secondaryToken
         */

        /**
         * Constructs a new MergeUserRequest.
         * @memberof api
         * @classdesc Represents a MergeUserRequest.
         * @implements IMergeUserRequest
         * @constructor
         * @param {api.IMergeUserRequest=} [properties] Properties to set
         */
        function MergeUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MergeUserRequest primaryToken.
         * @member {string} primaryToken
         * @memberof api.MergeUserRequest
         * @instance
         */
        MergeUserRequest.prototype.primaryToken = "";

        /**
         * MergeUserRequest secondaryToken.
         * @member {string} secondaryToken
         * @memberof api.MergeUserRequest
         * @instance
         */
        MergeUserRequest.prototype.secondaryToken = "";

        /**
         * Creates a new MergeUserRequest instance using the specified properties.
         * @function create
         * @memberof api.MergeUserRequest
         * @static
         * @param {api.IMergeUserRequest=} [properties] Properties to set
         * @returns {api.MergeUserRequest} MergeUserRequest instance
         */
        MergeUserRequest.create = function create(properties) {
            return new MergeUserRequest(properties);
        };

        /**
         * Encodes the specified MergeUserRequest message. Does not implicitly {@link api.MergeUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.MergeUserRequest
         * @static
         * @param {api.IMergeUserRequest} message MergeUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.primaryToken != null && Object.hasOwnProperty.call(message, "primaryToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.primaryToken);
            if (message.secondaryToken != null && Object.hasOwnProperty.call(message, "secondaryToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.secondaryToken);
            return writer;
        };

        /**
         * Encodes the specified MergeUserRequest message, length delimited. Does not implicitly {@link api.MergeUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MergeUserRequest
         * @static
         * @param {api.IMergeUserRequest} message MergeUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MergeUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.MergeUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MergeUserRequest} MergeUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MergeUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.primaryToken = reader.string();
                    break;
                case 2:
                    message.secondaryToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MergeUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MergeUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MergeUserRequest} MergeUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MergeUserRequest message.
         * @function verify
         * @memberof api.MergeUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MergeUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.primaryToken != null && message.hasOwnProperty("primaryToken"))
                if (!$util.isString(message.primaryToken))
                    return "primaryToken: string expected";
            if (message.secondaryToken != null && message.hasOwnProperty("secondaryToken"))
                if (!$util.isString(message.secondaryToken))
                    return "secondaryToken: string expected";
            return null;
        };

        /**
         * Creates a MergeUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MergeUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MergeUserRequest} MergeUserRequest
         */
        MergeUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MergeUserRequest)
                return object;
            let message = new $root.api.MergeUserRequest();
            if (object.primaryToken != null)
                message.primaryToken = String(object.primaryToken);
            if (object.secondaryToken != null)
                message.secondaryToken = String(object.secondaryToken);
            return message;
        };

        /**
         * Creates a plain object from a MergeUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MergeUserRequest
         * @static
         * @param {api.MergeUserRequest} message MergeUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MergeUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.primaryToken = "";
                object.secondaryToken = "";
            }
            if (message.primaryToken != null && message.hasOwnProperty("primaryToken"))
                object.primaryToken = message.primaryToken;
            if (message.secondaryToken != null && message.hasOwnProperty("secondaryToken"))
                object.secondaryToken = message.secondaryToken;
            return object;
        };

        /**
         * Converts this MergeUserRequest to JSON.
         * @function toJSON
         * @memberof api.MergeUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MergeUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MergeUserRequest;
    })();

    api.MergeUserResponse = (function() {

        /**
         * Properties of a MergeUserResponse.
         * @memberof api
         * @interface IMergeUserResponse
         */

        /**
         * Constructs a new MergeUserResponse.
         * @memberof api
         * @classdesc Represents a MergeUserResponse.
         * @implements IMergeUserResponse
         * @constructor
         * @param {api.IMergeUserResponse=} [properties] Properties to set
         */
        function MergeUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new MergeUserResponse instance using the specified properties.
         * @function create
         * @memberof api.MergeUserResponse
         * @static
         * @param {api.IMergeUserResponse=} [properties] Properties to set
         * @returns {api.MergeUserResponse} MergeUserResponse instance
         */
        MergeUserResponse.create = function create(properties) {
            return new MergeUserResponse(properties);
        };

        /**
         * Encodes the specified MergeUserResponse message. Does not implicitly {@link api.MergeUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.MergeUserResponse
         * @static
         * @param {api.IMergeUserResponse} message MergeUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified MergeUserResponse message, length delimited. Does not implicitly {@link api.MergeUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MergeUserResponse
         * @static
         * @param {api.IMergeUserResponse} message MergeUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MergeUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MergeUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.MergeUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MergeUserResponse} MergeUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MergeUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MergeUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MergeUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MergeUserResponse} MergeUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MergeUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MergeUserResponse message.
         * @function verify
         * @memberof api.MergeUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MergeUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a MergeUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MergeUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MergeUserResponse} MergeUserResponse
         */
        MergeUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MergeUserResponse)
                return object;
            return new $root.api.MergeUserResponse();
        };

        /**
         * Creates a plain object from a MergeUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MergeUserResponse
         * @static
         * @param {api.MergeUserResponse} message MergeUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MergeUserResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this MergeUserResponse to JSON.
         * @function toJSON
         * @memberof api.MergeUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MergeUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MergeUserResponse;
    })();

    api.LoginRequest = (function() {

        /**
         * Properties of a LoginRequest.
         * @memberof api
         * @interface ILoginRequest
         * @property {string|null} [existingUserToken] LoginRequest existingUserToken
         * @property {string|null} [source] LoginRequest source
         * @property {api.ISlackOAuth|null} [slackOauth] LoginRequest slackOauth
         * @property {api.ISlackPassthrough|null} [slackPassthrough] LoginRequest slackPassthrough
         * @property {api.IEmailPassword|null} [emailPassword] LoginRequest emailPassword
         * @property {api.IGoogleCredential|null} [googleCredential] LoginRequest googleCredential
         * @property {api.IAppleCredential|null} [appleCredential] LoginRequest appleCredential
         */

        /**
         * Constructs a new LoginRequest.
         * @memberof api
         * @classdesc Represents a LoginRequest.
         * @implements ILoginRequest
         * @constructor
         * @param {api.ILoginRequest=} [properties] Properties to set
         */
        function LoginRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginRequest existingUserToken.
         * @member {string} existingUserToken
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.existingUserToken = "";

        /**
         * LoginRequest source.
         * @member {string} source
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.source = "";

        /**
         * LoginRequest slackOauth.
         * @member {api.ISlackOAuth|null|undefined} slackOauth
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.slackOauth = null;

        /**
         * LoginRequest slackPassthrough.
         * @member {api.ISlackPassthrough|null|undefined} slackPassthrough
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.slackPassthrough = null;

        /**
         * LoginRequest emailPassword.
         * @member {api.IEmailPassword|null|undefined} emailPassword
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.emailPassword = null;

        /**
         * LoginRequest googleCredential.
         * @member {api.IGoogleCredential|null|undefined} googleCredential
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.googleCredential = null;

        /**
         * LoginRequest appleCredential.
         * @member {api.IAppleCredential|null|undefined} appleCredential
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.appleCredential = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * LoginRequest credentialOption.
         * @member {"slackOauth"|"slackPassthrough"|"emailPassword"|"googleCredential"|"appleCredential"|undefined} credentialOption
         * @memberof api.LoginRequest
         * @instance
         */
        Object.defineProperty(LoginRequest.prototype, "credentialOption", {
            get: $util.oneOfGetter($oneOfFields = ["slackOauth", "slackPassthrough", "emailPassword", "googleCredential", "appleCredential"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new LoginRequest instance using the specified properties.
         * @function create
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest=} [properties] Properties to set
         * @returns {api.LoginRequest} LoginRequest instance
         */
        LoginRequest.create = function create(properties) {
            return new LoginRequest(properties);
        };

        /**
         * Encodes the specified LoginRequest message. Does not implicitly {@link api.LoginRequest.verify|verify} messages.
         * @function encode
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slackOauth != null && Object.hasOwnProperty.call(message, "slackOauth"))
                $root.api.SlackOAuth.encode(message.slackOauth, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.slackPassthrough != null && Object.hasOwnProperty.call(message, "slackPassthrough"))
                $root.api.SlackPassthrough.encode(message.slackPassthrough, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.emailPassword != null && Object.hasOwnProperty.call(message, "emailPassword"))
                $root.api.EmailPassword.encode(message.emailPassword, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.googleCredential != null && Object.hasOwnProperty.call(message, "googleCredential"))
                $root.api.GoogleCredential.encode(message.googleCredential, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.appleCredential != null && Object.hasOwnProperty.call(message, "appleCredential"))
                $root.api.AppleCredential.encode(message.appleCredential, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.existingUserToken != null && Object.hasOwnProperty.call(message, "existingUserToken"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.existingUserToken);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.source);
            return writer;
        };

        /**
         * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link api.LoginRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LoginRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6:
                    message.existingUserToken = reader.string();
                    break;
                case 7:
                    message.source = reader.string();
                    break;
                case 1:
                    message.slackOauth = $root.api.SlackOAuth.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.slackPassthrough = $root.api.SlackPassthrough.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.emailPassword = $root.api.EmailPassword.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.googleCredential = $root.api.GoogleCredential.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.appleCredential = $root.api.AppleCredential.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginRequest message.
         * @function verify
         * @memberof api.LoginRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.existingUserToken != null && message.hasOwnProperty("existingUserToken"))
                if (!$util.isString(message.existingUserToken))
                    return "existingUserToken: string expected";
            if (message.source != null && message.hasOwnProperty("source"))
                if (!$util.isString(message.source))
                    return "source: string expected";
            if (message.slackOauth != null && message.hasOwnProperty("slackOauth")) {
                properties.credentialOption = 1;
                {
                    let error = $root.api.SlackOAuth.verify(message.slackOauth);
                    if (error)
                        return "slackOauth." + error;
                }
            }
            if (message.slackPassthrough != null && message.hasOwnProperty("slackPassthrough")) {
                if (properties.credentialOption === 1)
                    return "credentialOption: multiple values";
                properties.credentialOption = 1;
                {
                    let error = $root.api.SlackPassthrough.verify(message.slackPassthrough);
                    if (error)
                        return "slackPassthrough." + error;
                }
            }
            if (message.emailPassword != null && message.hasOwnProperty("emailPassword")) {
                if (properties.credentialOption === 1)
                    return "credentialOption: multiple values";
                properties.credentialOption = 1;
                {
                    let error = $root.api.EmailPassword.verify(message.emailPassword);
                    if (error)
                        return "emailPassword." + error;
                }
            }
            if (message.googleCredential != null && message.hasOwnProperty("googleCredential")) {
                if (properties.credentialOption === 1)
                    return "credentialOption: multiple values";
                properties.credentialOption = 1;
                {
                    let error = $root.api.GoogleCredential.verify(message.googleCredential);
                    if (error)
                        return "googleCredential." + error;
                }
            }
            if (message.appleCredential != null && message.hasOwnProperty("appleCredential")) {
                if (properties.credentialOption === 1)
                    return "credentialOption: multiple values";
                properties.credentialOption = 1;
                {
                    let error = $root.api.AppleCredential.verify(message.appleCredential);
                    if (error)
                        return "appleCredential." + error;
                }
            }
            return null;
        };

        /**
         * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LoginRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LoginRequest} LoginRequest
         */
        LoginRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LoginRequest)
                return object;
            let message = new $root.api.LoginRequest();
            if (object.existingUserToken != null)
                message.existingUserToken = String(object.existingUserToken);
            if (object.source != null)
                message.source = String(object.source);
            if (object.slackOauth != null) {
                if (typeof object.slackOauth !== "object")
                    throw TypeError(".api.LoginRequest.slackOauth: object expected");
                message.slackOauth = $root.api.SlackOAuth.fromObject(object.slackOauth);
            }
            if (object.slackPassthrough != null) {
                if (typeof object.slackPassthrough !== "object")
                    throw TypeError(".api.LoginRequest.slackPassthrough: object expected");
                message.slackPassthrough = $root.api.SlackPassthrough.fromObject(object.slackPassthrough);
            }
            if (object.emailPassword != null) {
                if (typeof object.emailPassword !== "object")
                    throw TypeError(".api.LoginRequest.emailPassword: object expected");
                message.emailPassword = $root.api.EmailPassword.fromObject(object.emailPassword);
            }
            if (object.googleCredential != null) {
                if (typeof object.googleCredential !== "object")
                    throw TypeError(".api.LoginRequest.googleCredential: object expected");
                message.googleCredential = $root.api.GoogleCredential.fromObject(object.googleCredential);
            }
            if (object.appleCredential != null) {
                if (typeof object.appleCredential !== "object")
                    throw TypeError(".api.LoginRequest.appleCredential: object expected");
                message.appleCredential = $root.api.AppleCredential.fromObject(object.appleCredential);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LoginRequest
         * @static
         * @param {api.LoginRequest} message LoginRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.existingUserToken = "";
                object.source = "";
            }
            if (message.slackOauth != null && message.hasOwnProperty("slackOauth")) {
                object.slackOauth = $root.api.SlackOAuth.toObject(message.slackOauth, options);
                if (options.oneofs)
                    object.credentialOption = "slackOauth";
            }
            if (message.slackPassthrough != null && message.hasOwnProperty("slackPassthrough")) {
                object.slackPassthrough = $root.api.SlackPassthrough.toObject(message.slackPassthrough, options);
                if (options.oneofs)
                    object.credentialOption = "slackPassthrough";
            }
            if (message.emailPassword != null && message.hasOwnProperty("emailPassword")) {
                object.emailPassword = $root.api.EmailPassword.toObject(message.emailPassword, options);
                if (options.oneofs)
                    object.credentialOption = "emailPassword";
            }
            if (message.googleCredential != null && message.hasOwnProperty("googleCredential")) {
                object.googleCredential = $root.api.GoogleCredential.toObject(message.googleCredential, options);
                if (options.oneofs)
                    object.credentialOption = "googleCredential";
            }
            if (message.appleCredential != null && message.hasOwnProperty("appleCredential")) {
                object.appleCredential = $root.api.AppleCredential.toObject(message.appleCredential, options);
                if (options.oneofs)
                    object.credentialOption = "appleCredential";
            }
            if (message.existingUserToken != null && message.hasOwnProperty("existingUserToken"))
                object.existingUserToken = message.existingUserToken;
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = message.source;
            return object;
        };

        /**
         * Converts this LoginRequest to JSON.
         * @function toJSON
         * @memberof api.LoginRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginRequest;
    })();

    api.LoginResponse = (function() {

        /**
         * Properties of a LoginResponse.
         * @memberof api
         * @interface ILoginResponse
         * @property {string|null} [userId] LoginResponse userId
         * @property {string|null} [token] LoginResponse token
         * @property {number|Long|null} [expirationTime] LoginResponse expirationTime
         * @property {number|Long|null} [tokensLeft] LoginResponse tokensLeft
         * @property {string|null} [emailAddress] LoginResponse emailAddress
         */

        /**
         * Constructs a new LoginResponse.
         * @memberof api
         * @classdesc Represents a LoginResponse.
         * @implements ILoginResponse
         * @constructor
         * @param {api.ILoginResponse=} [properties] Properties to set
         */
        function LoginResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginResponse userId.
         * @member {string} userId
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.userId = "";

        /**
         * LoginResponse token.
         * @member {string} token
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.token = "";

        /**
         * LoginResponse expirationTime.
         * @member {number|Long} expirationTime
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.expirationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LoginResponse tokensLeft.
         * @member {number|Long} tokensLeft
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.tokensLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LoginResponse emailAddress.
         * @member {string} emailAddress
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.emailAddress = "";

        /**
         * Creates a new LoginResponse instance using the specified properties.
         * @function create
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse=} [properties] Properties to set
         * @returns {api.LoginResponse} LoginResponse instance
         */
        LoginResponse.create = function create(properties) {
            return new LoginResponse(properties);
        };

        /**
         * Encodes the specified LoginResponse message. Does not implicitly {@link api.LoginResponse.verify|verify} messages.
         * @function encode
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse} message LoginResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.expirationTime != null && Object.hasOwnProperty.call(message, "expirationTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.expirationTime);
            if (message.tokensLeft != null && Object.hasOwnProperty.call(message, "tokensLeft"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.tokensLeft);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.emailAddress);
            return writer;
        };

        /**
         * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link api.LoginResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse} message LoginResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.LoginResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LoginResponse} LoginResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LoginResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.expirationTime = reader.int64();
                    break;
                case 4:
                    message.tokensLeft = reader.int64();
                    break;
                case 5:
                    message.emailAddress = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LoginResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LoginResponse} LoginResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginResponse message.
         * @function verify
         * @memberof api.LoginResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (!$util.isInteger(message.expirationTime) && !(message.expirationTime && $util.isInteger(message.expirationTime.low) && $util.isInteger(message.expirationTime.high)))
                    return "expirationTime: integer|Long expected";
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (!$util.isInteger(message.tokensLeft) && !(message.tokensLeft && $util.isInteger(message.tokensLeft.low) && $util.isInteger(message.tokensLeft.high)))
                    return "tokensLeft: integer|Long expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            return null;
        };

        /**
         * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LoginResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LoginResponse} LoginResponse
         */
        LoginResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LoginResponse)
                return object;
            let message = new $root.api.LoginResponse();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.token != null)
                message.token = String(object.token);
            if (object.expirationTime != null)
                if ($util.Long)
                    (message.expirationTime = $util.Long.fromValue(object.expirationTime)).unsigned = false;
                else if (typeof object.expirationTime === "string")
                    message.expirationTime = parseInt(object.expirationTime, 10);
                else if (typeof object.expirationTime === "number")
                    message.expirationTime = object.expirationTime;
                else if (typeof object.expirationTime === "object")
                    message.expirationTime = new $util.LongBits(object.expirationTime.low >>> 0, object.expirationTime.high >>> 0).toNumber();
            if (object.tokensLeft != null)
                if ($util.Long)
                    (message.tokensLeft = $util.Long.fromValue(object.tokensLeft)).unsigned = false;
                else if (typeof object.tokensLeft === "string")
                    message.tokensLeft = parseInt(object.tokensLeft, 10);
                else if (typeof object.tokensLeft === "number")
                    message.tokensLeft = object.tokensLeft;
                else if (typeof object.tokensLeft === "object")
                    message.tokensLeft = new $util.LongBits(object.tokensLeft.low >>> 0, object.tokensLeft.high >>> 0).toNumber();
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            return message;
        };

        /**
         * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LoginResponse
         * @static
         * @param {api.LoginResponse} message LoginResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.token = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expirationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expirationTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensLeft = options.longs === String ? "0" : 0;
                object.emailAddress = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (typeof message.expirationTime === "number")
                    object.expirationTime = options.longs === String ? String(message.expirationTime) : message.expirationTime;
                else
                    object.expirationTime = options.longs === String ? $util.Long.prototype.toString.call(message.expirationTime) : options.longs === Number ? new $util.LongBits(message.expirationTime.low >>> 0, message.expirationTime.high >>> 0).toNumber() : message.expirationTime;
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (typeof message.tokensLeft === "number")
                    object.tokensLeft = options.longs === String ? String(message.tokensLeft) : message.tokensLeft;
                else
                    object.tokensLeft = options.longs === String ? $util.Long.prototype.toString.call(message.tokensLeft) : options.longs === Number ? new $util.LongBits(message.tokensLeft.low >>> 0, message.tokensLeft.high >>> 0).toNumber() : message.tokensLeft;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            return object;
        };

        /**
         * Converts this LoginResponse to JSON.
         * @function toJSON
         * @memberof api.LoginResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginResponse;
    })();

    api.ValidateRequest = (function() {

        /**
         * Properties of a ValidateRequest.
         * @memberof api
         * @interface IValidateRequest
         * @property {string|null} [validationCode] ValidateRequest validationCode
         * @property {string|null} [emailAddress] ValidateRequest emailAddress
         * @property {string|null} [newPassword] ValidateRequest newPassword
         */

        /**
         * Constructs a new ValidateRequest.
         * @memberof api
         * @classdesc Represents a ValidateRequest.
         * @implements IValidateRequest
         * @constructor
         * @param {api.IValidateRequest=} [properties] Properties to set
         */
        function ValidateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidateRequest validationCode.
         * @member {string} validationCode
         * @memberof api.ValidateRequest
         * @instance
         */
        ValidateRequest.prototype.validationCode = "";

        /**
         * ValidateRequest emailAddress.
         * @member {string} emailAddress
         * @memberof api.ValidateRequest
         * @instance
         */
        ValidateRequest.prototype.emailAddress = "";

        /**
         * ValidateRequest newPassword.
         * @member {string} newPassword
         * @memberof api.ValidateRequest
         * @instance
         */
        ValidateRequest.prototype.newPassword = "";

        /**
         * Creates a new ValidateRequest instance using the specified properties.
         * @function create
         * @memberof api.ValidateRequest
         * @static
         * @param {api.IValidateRequest=} [properties] Properties to set
         * @returns {api.ValidateRequest} ValidateRequest instance
         */
        ValidateRequest.create = function create(properties) {
            return new ValidateRequest(properties);
        };

        /**
         * Encodes the specified ValidateRequest message. Does not implicitly {@link api.ValidateRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ValidateRequest
         * @static
         * @param {api.IValidateRequest} message ValidateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.validationCode != null && Object.hasOwnProperty.call(message, "validationCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.validationCode);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.newPassword);
            return writer;
        };

        /**
         * Encodes the specified ValidateRequest message, length delimited. Does not implicitly {@link api.ValidateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ValidateRequest
         * @static
         * @param {api.IValidateRequest} message ValidateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ValidateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ValidateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ValidateRequest} ValidateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ValidateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.validationCode = reader.string();
                    break;
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 3:
                    message.newPassword = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ValidateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ValidateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ValidateRequest} ValidateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ValidateRequest message.
         * @function verify
         * @memberof api.ValidateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ValidateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                if (!$util.isString(message.validationCode))
                    return "validationCode: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                if (!$util.isString(message.newPassword))
                    return "newPassword: string expected";
            return null;
        };

        /**
         * Creates a ValidateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ValidateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ValidateRequest} ValidateRequest
         */
        ValidateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ValidateRequest)
                return object;
            let message = new $root.api.ValidateRequest();
            if (object.validationCode != null)
                message.validationCode = String(object.validationCode);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.newPassword != null)
                message.newPassword = String(object.newPassword);
            return message;
        };

        /**
         * Creates a plain object from a ValidateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ValidateRequest
         * @static
         * @param {api.ValidateRequest} message ValidateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.validationCode = "";
                object.emailAddress = "";
                object.newPassword = "";
            }
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                object.validationCode = message.validationCode;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                object.newPassword = message.newPassword;
            return object;
        };

        /**
         * Converts this ValidateRequest to JSON.
         * @function toJSON
         * @memberof api.ValidateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ValidateRequest;
    })();

    api.ValidateResponse = (function() {

        /**
         * Properties of a ValidateResponse.
         * @memberof api
         * @interface IValidateResponse
         * @property {string|null} [userId] ValidateResponse userId
         * @property {string|null} [token] ValidateResponse token
         * @property {number|Long|null} [expirationTime] ValidateResponse expirationTime
         */

        /**
         * Constructs a new ValidateResponse.
         * @memberof api
         * @classdesc Represents a ValidateResponse.
         * @implements IValidateResponse
         * @constructor
         * @param {api.IValidateResponse=} [properties] Properties to set
         */
        function ValidateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidateResponse userId.
         * @member {string} userId
         * @memberof api.ValidateResponse
         * @instance
         */
        ValidateResponse.prototype.userId = "";

        /**
         * ValidateResponse token.
         * @member {string} token
         * @memberof api.ValidateResponse
         * @instance
         */
        ValidateResponse.prototype.token = "";

        /**
         * ValidateResponse expirationTime.
         * @member {number|Long} expirationTime
         * @memberof api.ValidateResponse
         * @instance
         */
        ValidateResponse.prototype.expirationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ValidateResponse instance using the specified properties.
         * @function create
         * @memberof api.ValidateResponse
         * @static
         * @param {api.IValidateResponse=} [properties] Properties to set
         * @returns {api.ValidateResponse} ValidateResponse instance
         */
        ValidateResponse.create = function create(properties) {
            return new ValidateResponse(properties);
        };

        /**
         * Encodes the specified ValidateResponse message. Does not implicitly {@link api.ValidateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ValidateResponse
         * @static
         * @param {api.IValidateResponse} message ValidateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.expirationTime != null && Object.hasOwnProperty.call(message, "expirationTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.expirationTime);
            return writer;
        };

        /**
         * Encodes the specified ValidateResponse message, length delimited. Does not implicitly {@link api.ValidateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ValidateResponse
         * @static
         * @param {api.IValidateResponse} message ValidateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ValidateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ValidateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ValidateResponse} ValidateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ValidateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.expirationTime = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ValidateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ValidateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ValidateResponse} ValidateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ValidateResponse message.
         * @function verify
         * @memberof api.ValidateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ValidateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (!$util.isInteger(message.expirationTime) && !(message.expirationTime && $util.isInteger(message.expirationTime.low) && $util.isInteger(message.expirationTime.high)))
                    return "expirationTime: integer|Long expected";
            return null;
        };

        /**
         * Creates a ValidateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ValidateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ValidateResponse} ValidateResponse
         */
        ValidateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ValidateResponse)
                return object;
            let message = new $root.api.ValidateResponse();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.token != null)
                message.token = String(object.token);
            if (object.expirationTime != null)
                if ($util.Long)
                    (message.expirationTime = $util.Long.fromValue(object.expirationTime)).unsigned = false;
                else if (typeof object.expirationTime === "string")
                    message.expirationTime = parseInt(object.expirationTime, 10);
                else if (typeof object.expirationTime === "number")
                    message.expirationTime = object.expirationTime;
                else if (typeof object.expirationTime === "object")
                    message.expirationTime = new $util.LongBits(object.expirationTime.low >>> 0, object.expirationTime.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a ValidateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ValidateResponse
         * @static
         * @param {api.ValidateResponse} message ValidateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.token = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expirationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expirationTime = options.longs === String ? "0" : 0;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (typeof message.expirationTime === "number")
                    object.expirationTime = options.longs === String ? String(message.expirationTime) : message.expirationTime;
                else
                    object.expirationTime = options.longs === String ? $util.Long.prototype.toString.call(message.expirationTime) : options.longs === Number ? new $util.LongBits(message.expirationTime.low >>> 0, message.expirationTime.high >>> 0).toNumber() : message.expirationTime;
            return object;
        };

        /**
         * Converts this ValidateResponse to JSON.
         * @function toJSON
         * @memberof api.ValidateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ValidateResponse;
    })();

    api.SendValidationCodeRequest = (function() {

        /**
         * Properties of a SendValidationCodeRequest.
         * @memberof api
         * @interface ISendValidationCodeRequest
         * @property {string|null} [emailAddress] SendValidationCodeRequest emailAddress
         * @property {boolean|null} [newPassword] SendValidationCodeRequest newPassword
         */

        /**
         * Constructs a new SendValidationCodeRequest.
         * @memberof api
         * @classdesc Represents a SendValidationCodeRequest.
         * @implements ISendValidationCodeRequest
         * @constructor
         * @param {api.ISendValidationCodeRequest=} [properties] Properties to set
         */
        function SendValidationCodeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendValidationCodeRequest emailAddress.
         * @member {string} emailAddress
         * @memberof api.SendValidationCodeRequest
         * @instance
         */
        SendValidationCodeRequest.prototype.emailAddress = "";

        /**
         * SendValidationCodeRequest newPassword.
         * @member {boolean} newPassword
         * @memberof api.SendValidationCodeRequest
         * @instance
         */
        SendValidationCodeRequest.prototype.newPassword = false;

        /**
         * Creates a new SendValidationCodeRequest instance using the specified properties.
         * @function create
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {api.ISendValidationCodeRequest=} [properties] Properties to set
         * @returns {api.SendValidationCodeRequest} SendValidationCodeRequest instance
         */
        SendValidationCodeRequest.create = function create(properties) {
            return new SendValidationCodeRequest(properties);
        };

        /**
         * Encodes the specified SendValidationCodeRequest message. Does not implicitly {@link api.SendValidationCodeRequest.verify|verify} messages.
         * @function encode
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {api.ISendValidationCodeRequest} message SendValidationCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendValidationCodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.newPassword);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            return writer;
        };

        /**
         * Encodes the specified SendValidationCodeRequest message, length delimited. Does not implicitly {@link api.SendValidationCodeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {api.ISendValidationCodeRequest} message SendValidationCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendValidationCodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendValidationCodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SendValidationCodeRequest} SendValidationCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendValidationCodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SendValidationCodeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 1:
                    message.newPassword = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendValidationCodeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SendValidationCodeRequest} SendValidationCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendValidationCodeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendValidationCodeRequest message.
         * @function verify
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendValidationCodeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                if (typeof message.newPassword !== "boolean")
                    return "newPassword: boolean expected";
            return null;
        };

        /**
         * Creates a SendValidationCodeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SendValidationCodeRequest} SendValidationCodeRequest
         */
        SendValidationCodeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SendValidationCodeRequest)
                return object;
            let message = new $root.api.SendValidationCodeRequest();
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.newPassword != null)
                message.newPassword = Boolean(object.newPassword);
            return message;
        };

        /**
         * Creates a plain object from a SendValidationCodeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {api.SendValidationCodeRequest} message SendValidationCodeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendValidationCodeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.newPassword = false;
                object.emailAddress = "";
            }
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                object.newPassword = message.newPassword;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            return object;
        };

        /**
         * Converts this SendValidationCodeRequest to JSON.
         * @function toJSON
         * @memberof api.SendValidationCodeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendValidationCodeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendValidationCodeRequest;
    })();

    api.SendValidationCodeResponse = (function() {

        /**
         * Properties of a SendValidationCodeResponse.
         * @memberof api
         * @interface ISendValidationCodeResponse
         */

        /**
         * Constructs a new SendValidationCodeResponse.
         * @memberof api
         * @classdesc Represents a SendValidationCodeResponse.
         * @implements ISendValidationCodeResponse
         * @constructor
         * @param {api.ISendValidationCodeResponse=} [properties] Properties to set
         */
        function SendValidationCodeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SendValidationCodeResponse instance using the specified properties.
         * @function create
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {api.ISendValidationCodeResponse=} [properties] Properties to set
         * @returns {api.SendValidationCodeResponse} SendValidationCodeResponse instance
         */
        SendValidationCodeResponse.create = function create(properties) {
            return new SendValidationCodeResponse(properties);
        };

        /**
         * Encodes the specified SendValidationCodeResponse message. Does not implicitly {@link api.SendValidationCodeResponse.verify|verify} messages.
         * @function encode
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {api.ISendValidationCodeResponse} message SendValidationCodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendValidationCodeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SendValidationCodeResponse message, length delimited. Does not implicitly {@link api.SendValidationCodeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {api.ISendValidationCodeResponse} message SendValidationCodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendValidationCodeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendValidationCodeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SendValidationCodeResponse} SendValidationCodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendValidationCodeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SendValidationCodeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendValidationCodeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SendValidationCodeResponse} SendValidationCodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendValidationCodeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendValidationCodeResponse message.
         * @function verify
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendValidationCodeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SendValidationCodeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SendValidationCodeResponse} SendValidationCodeResponse
         */
        SendValidationCodeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SendValidationCodeResponse)
                return object;
            return new $root.api.SendValidationCodeResponse();
        };

        /**
         * Creates a plain object from a SendValidationCodeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {api.SendValidationCodeResponse} message SendValidationCodeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendValidationCodeResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SendValidationCodeResponse to JSON.
         * @function toJSON
         * @memberof api.SendValidationCodeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendValidationCodeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendValidationCodeResponse;
    })();

    api.DeleteUserRequest = (function() {

        /**
         * Properties of a DeleteUserRequest.
         * @memberof api
         * @interface IDeleteUserRequest
         * @property {string|null} [userId] DeleteUserRequest userId
         */

        /**
         * Constructs a new DeleteUserRequest.
         * @memberof api
         * @classdesc Represents a DeleteUserRequest.
         * @implements IDeleteUserRequest
         * @constructor
         * @param {api.IDeleteUserRequest=} [properties] Properties to set
         */
        function DeleteUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUserRequest userId.
         * @member {string} userId
         * @memberof api.DeleteUserRequest
         * @instance
         */
        DeleteUserRequest.prototype.userId = "";

        /**
         * Creates a new DeleteUserRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteUserRequest
         * @static
         * @param {api.IDeleteUserRequest=} [properties] Properties to set
         * @returns {api.DeleteUserRequest} DeleteUserRequest instance
         */
        DeleteUserRequest.create = function create(properties) {
            return new DeleteUserRequest(properties);
        };

        /**
         * Encodes the specified DeleteUserRequest message. Does not implicitly {@link api.DeleteUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteUserRequest
         * @static
         * @param {api.IDeleteUserRequest} message DeleteUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            return writer;
        };

        /**
         * Encodes the specified DeleteUserRequest message, length delimited. Does not implicitly {@link api.DeleteUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteUserRequest
         * @static
         * @param {api.IDeleteUserRequest} message DeleteUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteUserRequest} DeleteUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteUserRequest} DeleteUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUserRequest message.
         * @function verify
         * @memberof api.DeleteUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            return null;
        };

        /**
         * Creates a DeleteUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteUserRequest} DeleteUserRequest
         */
        DeleteUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteUserRequest)
                return object;
            let message = new $root.api.DeleteUserRequest();
            if (object.userId != null)
                message.userId = String(object.userId);
            return message;
        };

        /**
         * Creates a plain object from a DeleteUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteUserRequest
         * @static
         * @param {api.DeleteUserRequest} message DeleteUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userId = "";
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            return object;
        };

        /**
         * Converts this DeleteUserRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteUserRequest;
    })();

    api.DeleteUserResponse = (function() {

        /**
         * Properties of a DeleteUserResponse.
         * @memberof api
         * @interface IDeleteUserResponse
         */

        /**
         * Constructs a new DeleteUserResponse.
         * @memberof api
         * @classdesc Represents a DeleteUserResponse.
         * @implements IDeleteUserResponse
         * @constructor
         * @param {api.IDeleteUserResponse=} [properties] Properties to set
         */
        function DeleteUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteUserResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteUserResponse
         * @static
         * @param {api.IDeleteUserResponse=} [properties] Properties to set
         * @returns {api.DeleteUserResponse} DeleteUserResponse instance
         */
        DeleteUserResponse.create = function create(properties) {
            return new DeleteUserResponse(properties);
        };

        /**
         * Encodes the specified DeleteUserResponse message. Does not implicitly {@link api.DeleteUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteUserResponse
         * @static
         * @param {api.IDeleteUserResponse} message DeleteUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteUserResponse message, length delimited. Does not implicitly {@link api.DeleteUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteUserResponse
         * @static
         * @param {api.IDeleteUserResponse} message DeleteUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteUserResponse} DeleteUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteUserResponse} DeleteUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUserResponse message.
         * @function verify
         * @memberof api.DeleteUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteUserResponse} DeleteUserResponse
         */
        DeleteUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteUserResponse)
                return object;
            return new $root.api.DeleteUserResponse();
        };

        /**
         * Creates a plain object from a DeleteUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteUserResponse
         * @static
         * @param {api.DeleteUserResponse} message DeleteUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUserResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteUserResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteUserResponse;
    })();

    api.CreateNativeUserRequest = (function() {

        /**
         * Properties of a CreateNativeUserRequest.
         * @memberof api
         * @interface ICreateNativeUserRequest
         * @property {string|null} [existingUserToken] CreateNativeUserRequest existingUserToken
         * @property {string|null} [parentGroupId] CreateNativeUserRequest parentGroupId
         * @property {api.IUser|null} [user] CreateNativeUserRequest user
         * @property {string|null} [source] CreateNativeUserRequest source
         */

        /**
         * Constructs a new CreateNativeUserRequest.
         * @memberof api
         * @classdesc Represents a CreateNativeUserRequest.
         * @implements ICreateNativeUserRequest
         * @constructor
         * @param {api.ICreateNativeUserRequest=} [properties] Properties to set
         */
        function CreateNativeUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateNativeUserRequest existingUserToken.
         * @member {string} existingUserToken
         * @memberof api.CreateNativeUserRequest
         * @instance
         */
        CreateNativeUserRequest.prototype.existingUserToken = "";

        /**
         * CreateNativeUserRequest parentGroupId.
         * @member {string} parentGroupId
         * @memberof api.CreateNativeUserRequest
         * @instance
         */
        CreateNativeUserRequest.prototype.parentGroupId = "";

        /**
         * CreateNativeUserRequest user.
         * @member {api.IUser|null|undefined} user
         * @memberof api.CreateNativeUserRequest
         * @instance
         */
        CreateNativeUserRequest.prototype.user = null;

        /**
         * CreateNativeUserRequest source.
         * @member {string} source
         * @memberof api.CreateNativeUserRequest
         * @instance
         */
        CreateNativeUserRequest.prototype.source = "";

        /**
         * Creates a new CreateNativeUserRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateNativeUserRequest
         * @static
         * @param {api.ICreateNativeUserRequest=} [properties] Properties to set
         * @returns {api.CreateNativeUserRequest} CreateNativeUserRequest instance
         */
        CreateNativeUserRequest.create = function create(properties) {
            return new CreateNativeUserRequest(properties);
        };

        /**
         * Encodes the specified CreateNativeUserRequest message. Does not implicitly {@link api.CreateNativeUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateNativeUserRequest
         * @static
         * @param {api.ICreateNativeUserRequest} message CreateNativeUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateNativeUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parentGroupId != null && Object.hasOwnProperty.call(message, "parentGroupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parentGroupId);
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.existingUserToken != null && Object.hasOwnProperty.call(message, "existingUserToken"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.existingUserToken);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.source);
            return writer;
        };

        /**
         * Encodes the specified CreateNativeUserRequest message, length delimited. Does not implicitly {@link api.CreateNativeUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateNativeUserRequest
         * @static
         * @param {api.ICreateNativeUserRequest} message CreateNativeUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateNativeUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateNativeUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateNativeUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateNativeUserRequest} CreateNativeUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateNativeUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateNativeUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.existingUserToken = reader.string();
                    break;
                case 1:
                    message.parentGroupId = reader.string();
                    break;
                case 2:
                    message.user = $root.api.User.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.source = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateNativeUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateNativeUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateNativeUserRequest} CreateNativeUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateNativeUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateNativeUserRequest message.
         * @function verify
         * @memberof api.CreateNativeUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateNativeUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.existingUserToken != null && message.hasOwnProperty("existingUserToken"))
                if (!$util.isString(message.existingUserToken))
                    return "existingUserToken: string expected";
            if (message.parentGroupId != null && message.hasOwnProperty("parentGroupId"))
                if (!$util.isString(message.parentGroupId))
                    return "parentGroupId: string expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.source != null && message.hasOwnProperty("source"))
                if (!$util.isString(message.source))
                    return "source: string expected";
            return null;
        };

        /**
         * Creates a CreateNativeUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateNativeUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateNativeUserRequest} CreateNativeUserRequest
         */
        CreateNativeUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateNativeUserRequest)
                return object;
            let message = new $root.api.CreateNativeUserRequest();
            if (object.existingUserToken != null)
                message.existingUserToken = String(object.existingUserToken);
            if (object.parentGroupId != null)
                message.parentGroupId = String(object.parentGroupId);
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.CreateNativeUserRequest.user: object expected");
                message.user = $root.api.User.fromObject(object.user);
            }
            if (object.source != null)
                message.source = String(object.source);
            return message;
        };

        /**
         * Creates a plain object from a CreateNativeUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateNativeUserRequest
         * @static
         * @param {api.CreateNativeUserRequest} message CreateNativeUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateNativeUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.parentGroupId = "";
                object.user = null;
                object.existingUserToken = "";
                object.source = "";
            }
            if (message.parentGroupId != null && message.hasOwnProperty("parentGroupId"))
                object.parentGroupId = message.parentGroupId;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.User.toObject(message.user, options);
            if (message.existingUserToken != null && message.hasOwnProperty("existingUserToken"))
                object.existingUserToken = message.existingUserToken;
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = message.source;
            return object;
        };

        /**
         * Converts this CreateNativeUserRequest to JSON.
         * @function toJSON
         * @memberof api.CreateNativeUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateNativeUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateNativeUserRequest;
    })();

    api.CreateNativeUserResponse = (function() {

        /**
         * Properties of a CreateNativeUserResponse.
         * @memberof api
         * @interface ICreateNativeUserResponse
         * @property {string|null} [userId] CreateNativeUserResponse userId
         * @property {string|null} [token] CreateNativeUserResponse token
         * @property {number|Long|null} [expirationTime] CreateNativeUserResponse expirationTime
         * @property {boolean|null} [emailValidationRequired] CreateNativeUserResponse emailValidationRequired
         * @property {number|Long|null} [tokensLeft] CreateNativeUserResponse tokensLeft
         */

        /**
         * Constructs a new CreateNativeUserResponse.
         * @memberof api
         * @classdesc Represents a CreateNativeUserResponse.
         * @implements ICreateNativeUserResponse
         * @constructor
         * @param {api.ICreateNativeUserResponse=} [properties] Properties to set
         */
        function CreateNativeUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateNativeUserResponse userId.
         * @member {string} userId
         * @memberof api.CreateNativeUserResponse
         * @instance
         */
        CreateNativeUserResponse.prototype.userId = "";

        /**
         * CreateNativeUserResponse token.
         * @member {string} token
         * @memberof api.CreateNativeUserResponse
         * @instance
         */
        CreateNativeUserResponse.prototype.token = "";

        /**
         * CreateNativeUserResponse expirationTime.
         * @member {number|Long} expirationTime
         * @memberof api.CreateNativeUserResponse
         * @instance
         */
        CreateNativeUserResponse.prototype.expirationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CreateNativeUserResponse emailValidationRequired.
         * @member {boolean} emailValidationRequired
         * @memberof api.CreateNativeUserResponse
         * @instance
         */
        CreateNativeUserResponse.prototype.emailValidationRequired = false;

        /**
         * CreateNativeUserResponse tokensLeft.
         * @member {number|Long} tokensLeft
         * @memberof api.CreateNativeUserResponse
         * @instance
         */
        CreateNativeUserResponse.prototype.tokensLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CreateNativeUserResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateNativeUserResponse
         * @static
         * @param {api.ICreateNativeUserResponse=} [properties] Properties to set
         * @returns {api.CreateNativeUserResponse} CreateNativeUserResponse instance
         */
        CreateNativeUserResponse.create = function create(properties) {
            return new CreateNativeUserResponse(properties);
        };

        /**
         * Encodes the specified CreateNativeUserResponse message. Does not implicitly {@link api.CreateNativeUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateNativeUserResponse
         * @static
         * @param {api.ICreateNativeUserResponse} message CreateNativeUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateNativeUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.expirationTime != null && Object.hasOwnProperty.call(message, "expirationTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.expirationTime);
            if (message.emailValidationRequired != null && Object.hasOwnProperty.call(message, "emailValidationRequired"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.emailValidationRequired);
            if (message.tokensLeft != null && Object.hasOwnProperty.call(message, "tokensLeft"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.tokensLeft);
            return writer;
        };

        /**
         * Encodes the specified CreateNativeUserResponse message, length delimited. Does not implicitly {@link api.CreateNativeUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateNativeUserResponse
         * @static
         * @param {api.ICreateNativeUserResponse} message CreateNativeUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateNativeUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateNativeUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateNativeUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateNativeUserResponse} CreateNativeUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateNativeUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateNativeUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.expirationTime = reader.int64();
                    break;
                case 4:
                    message.emailValidationRequired = reader.bool();
                    break;
                case 5:
                    message.tokensLeft = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateNativeUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateNativeUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateNativeUserResponse} CreateNativeUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateNativeUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateNativeUserResponse message.
         * @function verify
         * @memberof api.CreateNativeUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateNativeUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (!$util.isInteger(message.expirationTime) && !(message.expirationTime && $util.isInteger(message.expirationTime.low) && $util.isInteger(message.expirationTime.high)))
                    return "expirationTime: integer|Long expected";
            if (message.emailValidationRequired != null && message.hasOwnProperty("emailValidationRequired"))
                if (typeof message.emailValidationRequired !== "boolean")
                    return "emailValidationRequired: boolean expected";
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (!$util.isInteger(message.tokensLeft) && !(message.tokensLeft && $util.isInteger(message.tokensLeft.low) && $util.isInteger(message.tokensLeft.high)))
                    return "tokensLeft: integer|Long expected";
            return null;
        };

        /**
         * Creates a CreateNativeUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateNativeUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateNativeUserResponse} CreateNativeUserResponse
         */
        CreateNativeUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateNativeUserResponse)
                return object;
            let message = new $root.api.CreateNativeUserResponse();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.token != null)
                message.token = String(object.token);
            if (object.expirationTime != null)
                if ($util.Long)
                    (message.expirationTime = $util.Long.fromValue(object.expirationTime)).unsigned = false;
                else if (typeof object.expirationTime === "string")
                    message.expirationTime = parseInt(object.expirationTime, 10);
                else if (typeof object.expirationTime === "number")
                    message.expirationTime = object.expirationTime;
                else if (typeof object.expirationTime === "object")
                    message.expirationTime = new $util.LongBits(object.expirationTime.low >>> 0, object.expirationTime.high >>> 0).toNumber();
            if (object.emailValidationRequired != null)
                message.emailValidationRequired = Boolean(object.emailValidationRequired);
            if (object.tokensLeft != null)
                if ($util.Long)
                    (message.tokensLeft = $util.Long.fromValue(object.tokensLeft)).unsigned = false;
                else if (typeof object.tokensLeft === "string")
                    message.tokensLeft = parseInt(object.tokensLeft, 10);
                else if (typeof object.tokensLeft === "number")
                    message.tokensLeft = object.tokensLeft;
                else if (typeof object.tokensLeft === "object")
                    message.tokensLeft = new $util.LongBits(object.tokensLeft.low >>> 0, object.tokensLeft.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CreateNativeUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateNativeUserResponse
         * @static
         * @param {api.CreateNativeUserResponse} message CreateNativeUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateNativeUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.token = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expirationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expirationTime = options.longs === String ? "0" : 0;
                object.emailValidationRequired = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensLeft = options.longs === String ? "0" : 0;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (typeof message.expirationTime === "number")
                    object.expirationTime = options.longs === String ? String(message.expirationTime) : message.expirationTime;
                else
                    object.expirationTime = options.longs === String ? $util.Long.prototype.toString.call(message.expirationTime) : options.longs === Number ? new $util.LongBits(message.expirationTime.low >>> 0, message.expirationTime.high >>> 0).toNumber() : message.expirationTime;
            if (message.emailValidationRequired != null && message.hasOwnProperty("emailValidationRequired"))
                object.emailValidationRequired = message.emailValidationRequired;
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (typeof message.tokensLeft === "number")
                    object.tokensLeft = options.longs === String ? String(message.tokensLeft) : message.tokensLeft;
                else
                    object.tokensLeft = options.longs === String ? $util.Long.prototype.toString.call(message.tokensLeft) : options.longs === Number ? new $util.LongBits(message.tokensLeft.low >>> 0, message.tokensLeft.high >>> 0).toNumber() : message.tokensLeft;
            return object;
        };

        /**
         * Converts this CreateNativeUserResponse to JSON.
         * @function toJSON
         * @memberof api.CreateNativeUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateNativeUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateNativeUserResponse;
    })();

    api.AuthenticateResponse = (function() {

        /**
         * Properties of an AuthenticateResponse.
         * @memberof api
         * @interface IAuthenticateResponse
         * @property {string|null} [newToken] AuthenticateResponse newToken
         */

        /**
         * Constructs a new AuthenticateResponse.
         * @memberof api
         * @classdesc Represents an AuthenticateResponse.
         * @implements IAuthenticateResponse
         * @constructor
         * @param {api.IAuthenticateResponse=} [properties] Properties to set
         */
        function AuthenticateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticateResponse newToken.
         * @member {string} newToken
         * @memberof api.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.newToken = "";

        /**
         * Creates a new AuthenticateResponse instance using the specified properties.
         * @function create
         * @memberof api.AuthenticateResponse
         * @static
         * @param {api.IAuthenticateResponse=} [properties] Properties to set
         * @returns {api.AuthenticateResponse} AuthenticateResponse instance
         */
        AuthenticateResponse.create = function create(properties) {
            return new AuthenticateResponse(properties);
        };

        /**
         * Encodes the specified AuthenticateResponse message. Does not implicitly {@link api.AuthenticateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AuthenticateResponse
         * @static
         * @param {api.IAuthenticateResponse} message AuthenticateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.newToken != null && Object.hasOwnProperty.call(message, "newToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.newToken);
            return writer;
        };

        /**
         * Encodes the specified AuthenticateResponse message, length delimited. Does not implicitly {@link api.AuthenticateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AuthenticateResponse
         * @static
         * @param {api.IAuthenticateResponse} message AuthenticateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AuthenticateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AuthenticateResponse} AuthenticateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AuthenticateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.newToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AuthenticateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AuthenticateResponse} AuthenticateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticateResponse message.
         * @function verify
         * @memberof api.AuthenticateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.newToken != null && message.hasOwnProperty("newToken"))
                if (!$util.isString(message.newToken))
                    return "newToken: string expected";
            return null;
        };

        /**
         * Creates an AuthenticateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AuthenticateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AuthenticateResponse} AuthenticateResponse
         */
        AuthenticateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AuthenticateResponse)
                return object;
            let message = new $root.api.AuthenticateResponse();
            if (object.newToken != null)
                message.newToken = String(object.newToken);
            return message;
        };

        /**
         * Creates a plain object from an AuthenticateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AuthenticateResponse
         * @static
         * @param {api.AuthenticateResponse} message AuthenticateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.newToken = "";
            if (message.newToken != null && message.hasOwnProperty("newToken"))
                object.newToken = message.newToken;
            return object;
        };

        /**
         * Converts this AuthenticateResponse to JSON.
         * @function toJSON
         * @memberof api.AuthenticateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AuthenticateResponse;
    })();

    api.AuthenticateRequest = (function() {

        /**
         * Properties of an AuthenticateRequest.
         * @memberof api
         * @interface IAuthenticateRequest
         * @property {string|null} [token] AuthenticateRequest token
         */

        /**
         * Constructs a new AuthenticateRequest.
         * @memberof api
         * @classdesc Represents an AuthenticateRequest.
         * @implements IAuthenticateRequest
         * @constructor
         * @param {api.IAuthenticateRequest=} [properties] Properties to set
         */
        function AuthenticateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticateRequest token.
         * @member {string} token
         * @memberof api.AuthenticateRequest
         * @instance
         */
        AuthenticateRequest.prototype.token = "";

        /**
         * Creates a new AuthenticateRequest instance using the specified properties.
         * @function create
         * @memberof api.AuthenticateRequest
         * @static
         * @param {api.IAuthenticateRequest=} [properties] Properties to set
         * @returns {api.AuthenticateRequest} AuthenticateRequest instance
         */
        AuthenticateRequest.create = function create(properties) {
            return new AuthenticateRequest(properties);
        };

        /**
         * Encodes the specified AuthenticateRequest message. Does not implicitly {@link api.AuthenticateRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AuthenticateRequest
         * @static
         * @param {api.IAuthenticateRequest} message AuthenticateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified AuthenticateRequest message, length delimited. Does not implicitly {@link api.AuthenticateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AuthenticateRequest
         * @static
         * @param {api.IAuthenticateRequest} message AuthenticateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AuthenticateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AuthenticateRequest} AuthenticateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AuthenticateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AuthenticateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AuthenticateRequest} AuthenticateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticateRequest message.
         * @function verify
         * @memberof api.AuthenticateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates an AuthenticateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AuthenticateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AuthenticateRequest} AuthenticateRequest
         */
        AuthenticateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AuthenticateRequest)
                return object;
            let message = new $root.api.AuthenticateRequest();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from an AuthenticateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AuthenticateRequest
         * @static
         * @param {api.AuthenticateRequest} message AuthenticateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this AuthenticateRequest to JSON.
         * @function toJSON
         * @memberof api.AuthenticateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AuthenticateRequest;
    })();

    api.GetUserRequest = (function() {

        /**
         * Properties of a GetUserRequest.
         * @memberof api
         * @interface IGetUserRequest
         * @property {string|null} [userId] GetUserRequest userId
         * @property {api.IUserQuery|null} [query] GetUserRequest query
         */

        /**
         * Constructs a new GetUserRequest.
         * @memberof api
         * @classdesc Represents a GetUserRequest.
         * @implements IGetUserRequest
         * @constructor
         * @param {api.IGetUserRequest=} [properties] Properties to set
         */
        function GetUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserRequest userId.
         * @member {string|null|undefined} userId
         * @memberof api.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.userId = null;

        /**
         * GetUserRequest query.
         * @member {api.IUserQuery|null|undefined} query
         * @memberof api.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.query = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GetUserRequest lookupChoice.
         * @member {"userId"|undefined} lookupChoice
         * @memberof api.GetUserRequest
         * @instance
         */
        Object.defineProperty(GetUserRequest.prototype, "lookupChoice", {
            get: $util.oneOfGetter($oneOfFields = ["userId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetUserRequest instance using the specified properties.
         * @function create
         * @memberof api.GetUserRequest
         * @static
         * @param {api.IGetUserRequest=} [properties] Properties to set
         * @returns {api.GetUserRequest} GetUserRequest instance
         */
        GetUserRequest.create = function create(properties) {
            return new GetUserRequest(properties);
        };

        /**
         * Encodes the specified GetUserRequest message. Does not implicitly {@link api.GetUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetUserRequest
         * @static
         * @param {api.IGetUserRequest} message GetUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.UserQuery.encode(message.query, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserRequest message, length delimited. Does not implicitly {@link api.GetUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetUserRequest
         * @static
         * @param {api.IGetUserRequest} message GetUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetUserRequest} GetUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.query = $root.api.UserQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetUserRequest} GetUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserRequest message.
         * @function verify
         * @memberof api.GetUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.userId != null && message.hasOwnProperty("userId")) {
                properties.lookupChoice = 1;
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            }
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.UserQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetUserRequest} GetUserRequest
         */
        GetUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetUserRequest)
                return object;
            let message = new $root.api.GetUserRequest();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetUserRequest.query: object expected");
                message.query = $root.api.UserQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetUserRequest
         * @static
         * @param {api.GetUserRequest} message GetUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.query = null;
            if (message.userId != null && message.hasOwnProperty("userId")) {
                object.userId = message.userId;
                if (options.oneofs)
                    object.lookupChoice = "userId";
            }
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.UserQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetUserRequest to JSON.
         * @function toJSON
         * @memberof api.GetUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserRequest;
    })();

    api.GetUserResponse = (function() {

        /**
         * Properties of a GetUserResponse.
         * @memberof api
         * @interface IGetUserResponse
         * @property {api.IUser|null} [user] GetUserResponse user
         */

        /**
         * Constructs a new GetUserResponse.
         * @memberof api
         * @classdesc Represents a GetUserResponse.
         * @implements IGetUserResponse
         * @constructor
         * @param {api.IGetUserResponse=} [properties] Properties to set
         */
        function GetUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserResponse user.
         * @member {api.IUser|null|undefined} user
         * @memberof api.GetUserResponse
         * @instance
         */
        GetUserResponse.prototype.user = null;

        /**
         * Creates a new GetUserResponse instance using the specified properties.
         * @function create
         * @memberof api.GetUserResponse
         * @static
         * @param {api.IGetUserResponse=} [properties] Properties to set
         * @returns {api.GetUserResponse} GetUserResponse instance
         */
        GetUserResponse.create = function create(properties) {
            return new GetUserResponse(properties);
        };

        /**
         * Encodes the specified GetUserResponse message. Does not implicitly {@link api.GetUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetUserResponse
         * @static
         * @param {api.IGetUserResponse} message GetUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserResponse message, length delimited. Does not implicitly {@link api.GetUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetUserResponse
         * @static
         * @param {api.IGetUserResponse} message GetUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetUserResponse} GetUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.api.User.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetUserResponse} GetUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserResponse message.
         * @function verify
         * @memberof api.GetUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            return null;
        };

        /**
         * Creates a GetUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetUserResponse} GetUserResponse
         */
        GetUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetUserResponse)
                return object;
            let message = new $root.api.GetUserResponse();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.GetUserResponse.user: object expected");
                message.user = $root.api.User.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetUserResponse
         * @static
         * @param {api.GetUserResponse} message GetUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.user = null;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.User.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this GetUserResponse to JSON.
         * @function toJSON
         * @memberof api.GetUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserResponse;
    })();

    api.UpdatePaymentRequest = (function() {

        /**
         * Properties of an UpdatePaymentRequest.
         * @memberof api
         * @interface IUpdatePaymentRequest
         */

        /**
         * Constructs a new UpdatePaymentRequest.
         * @memberof api
         * @classdesc Represents an UpdatePaymentRequest.
         * @implements IUpdatePaymentRequest
         * @constructor
         * @param {api.IUpdatePaymentRequest=} [properties] Properties to set
         */
        function UpdatePaymentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdatePaymentRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {api.IUpdatePaymentRequest=} [properties] Properties to set
         * @returns {api.UpdatePaymentRequest} UpdatePaymentRequest instance
         */
        UpdatePaymentRequest.create = function create(properties) {
            return new UpdatePaymentRequest(properties);
        };

        /**
         * Encodes the specified UpdatePaymentRequest message. Does not implicitly {@link api.UpdatePaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {api.IUpdatePaymentRequest} message UpdatePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdatePaymentRequest message, length delimited. Does not implicitly {@link api.UpdatePaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {api.IUpdatePaymentRequest} message UpdatePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdatePaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdatePaymentRequest} UpdatePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdatePaymentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdatePaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdatePaymentRequest} UpdatePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdatePaymentRequest message.
         * @function verify
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdatePaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdatePaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdatePaymentRequest} UpdatePaymentRequest
         */
        UpdatePaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdatePaymentRequest)
                return object;
            return new $root.api.UpdatePaymentRequest();
        };

        /**
         * Creates a plain object from an UpdatePaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {api.UpdatePaymentRequest} message UpdatePaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdatePaymentRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdatePaymentRequest to JSON.
         * @function toJSON
         * @memberof api.UpdatePaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdatePaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdatePaymentRequest;
    })();

    api.UpdatePaymentResponse = (function() {

        /**
         * Properties of an UpdatePaymentResponse.
         * @memberof api
         * @interface IUpdatePaymentResponse
         * @property {string|null} [paymentClientSecret] UpdatePaymentResponse paymentClientSecret
         * @property {string|null} [publishableKey] UpdatePaymentResponse publishableKey
         * @property {boolean|null} [hasExistingPayment] UpdatePaymentResponse hasExistingPayment
         * @property {string|null} [existingPaymentDescription] UpdatePaymentResponse existingPaymentDescription
         */

        /**
         * Constructs a new UpdatePaymentResponse.
         * @memberof api
         * @classdesc Represents an UpdatePaymentResponse.
         * @implements IUpdatePaymentResponse
         * @constructor
         * @param {api.IUpdatePaymentResponse=} [properties] Properties to set
         */
        function UpdatePaymentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePaymentResponse paymentClientSecret.
         * @member {string} paymentClientSecret
         * @memberof api.UpdatePaymentResponse
         * @instance
         */
        UpdatePaymentResponse.prototype.paymentClientSecret = "";

        /**
         * UpdatePaymentResponse publishableKey.
         * @member {string} publishableKey
         * @memberof api.UpdatePaymentResponse
         * @instance
         */
        UpdatePaymentResponse.prototype.publishableKey = "";

        /**
         * UpdatePaymentResponse hasExistingPayment.
         * @member {boolean} hasExistingPayment
         * @memberof api.UpdatePaymentResponse
         * @instance
         */
        UpdatePaymentResponse.prototype.hasExistingPayment = false;

        /**
         * UpdatePaymentResponse existingPaymentDescription.
         * @member {string} existingPaymentDescription
         * @memberof api.UpdatePaymentResponse
         * @instance
         */
        UpdatePaymentResponse.prototype.existingPaymentDescription = "";

        /**
         * Creates a new UpdatePaymentResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {api.IUpdatePaymentResponse=} [properties] Properties to set
         * @returns {api.UpdatePaymentResponse} UpdatePaymentResponse instance
         */
        UpdatePaymentResponse.create = function create(properties) {
            return new UpdatePaymentResponse(properties);
        };

        /**
         * Encodes the specified UpdatePaymentResponse message. Does not implicitly {@link api.UpdatePaymentResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {api.IUpdatePaymentResponse} message UpdatePaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentClientSecret != null && Object.hasOwnProperty.call(message, "paymentClientSecret"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentClientSecret);
            if (message.publishableKey != null && Object.hasOwnProperty.call(message, "publishableKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.publishableKey);
            if (message.hasExistingPayment != null && Object.hasOwnProperty.call(message, "hasExistingPayment"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.hasExistingPayment);
            if (message.existingPaymentDescription != null && Object.hasOwnProperty.call(message, "existingPaymentDescription"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.existingPaymentDescription);
            return writer;
        };

        /**
         * Encodes the specified UpdatePaymentResponse message, length delimited. Does not implicitly {@link api.UpdatePaymentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {api.IUpdatePaymentResponse} message UpdatePaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdatePaymentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdatePaymentResponse} UpdatePaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdatePaymentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.paymentClientSecret = reader.string();
                    break;
                case 3:
                    message.publishableKey = reader.string();
                    break;
                case 4:
                    message.hasExistingPayment = reader.bool();
                    break;
                case 5:
                    message.existingPaymentDescription = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdatePaymentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdatePaymentResponse} UpdatePaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdatePaymentResponse message.
         * @function verify
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdatePaymentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentClientSecret != null && message.hasOwnProperty("paymentClientSecret"))
                if (!$util.isString(message.paymentClientSecret))
                    return "paymentClientSecret: string expected";
            if (message.publishableKey != null && message.hasOwnProperty("publishableKey"))
                if (!$util.isString(message.publishableKey))
                    return "publishableKey: string expected";
            if (message.hasExistingPayment != null && message.hasOwnProperty("hasExistingPayment"))
                if (typeof message.hasExistingPayment !== "boolean")
                    return "hasExistingPayment: boolean expected";
            if (message.existingPaymentDescription != null && message.hasOwnProperty("existingPaymentDescription"))
                if (!$util.isString(message.existingPaymentDescription))
                    return "existingPaymentDescription: string expected";
            return null;
        };

        /**
         * Creates an UpdatePaymentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdatePaymentResponse} UpdatePaymentResponse
         */
        UpdatePaymentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdatePaymentResponse)
                return object;
            let message = new $root.api.UpdatePaymentResponse();
            if (object.paymentClientSecret != null)
                message.paymentClientSecret = String(object.paymentClientSecret);
            if (object.publishableKey != null)
                message.publishableKey = String(object.publishableKey);
            if (object.hasExistingPayment != null)
                message.hasExistingPayment = Boolean(object.hasExistingPayment);
            if (object.existingPaymentDescription != null)
                message.existingPaymentDescription = String(object.existingPaymentDescription);
            return message;
        };

        /**
         * Creates a plain object from an UpdatePaymentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {api.UpdatePaymentResponse} message UpdatePaymentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdatePaymentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.paymentClientSecret = "";
                object.publishableKey = "";
                object.hasExistingPayment = false;
                object.existingPaymentDescription = "";
            }
            if (message.paymentClientSecret != null && message.hasOwnProperty("paymentClientSecret"))
                object.paymentClientSecret = message.paymentClientSecret;
            if (message.publishableKey != null && message.hasOwnProperty("publishableKey"))
                object.publishableKey = message.publishableKey;
            if (message.hasExistingPayment != null && message.hasOwnProperty("hasExistingPayment"))
                object.hasExistingPayment = message.hasExistingPayment;
            if (message.existingPaymentDescription != null && message.hasOwnProperty("existingPaymentDescription"))
                object.existingPaymentDescription = message.existingPaymentDescription;
            return object;
        };

        /**
         * Converts this UpdatePaymentResponse to JSON.
         * @function toJSON
         * @memberof api.UpdatePaymentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdatePaymentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdatePaymentResponse;
    })();

    api.DeletePaymentRequest = (function() {

        /**
         * Properties of a DeletePaymentRequest.
         * @memberof api
         * @interface IDeletePaymentRequest
         */

        /**
         * Constructs a new DeletePaymentRequest.
         * @memberof api
         * @classdesc Represents a DeletePaymentRequest.
         * @implements IDeletePaymentRequest
         * @constructor
         * @param {api.IDeletePaymentRequest=} [properties] Properties to set
         */
        function DeletePaymentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeletePaymentRequest instance using the specified properties.
         * @function create
         * @memberof api.DeletePaymentRequest
         * @static
         * @param {api.IDeletePaymentRequest=} [properties] Properties to set
         * @returns {api.DeletePaymentRequest} DeletePaymentRequest instance
         */
        DeletePaymentRequest.create = function create(properties) {
            return new DeletePaymentRequest(properties);
        };

        /**
         * Encodes the specified DeletePaymentRequest message. Does not implicitly {@link api.DeletePaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeletePaymentRequest
         * @static
         * @param {api.IDeletePaymentRequest} message DeletePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeletePaymentRequest message, length delimited. Does not implicitly {@link api.DeletePaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeletePaymentRequest
         * @static
         * @param {api.IDeletePaymentRequest} message DeletePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeletePaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeletePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeletePaymentRequest} DeletePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeletePaymentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeletePaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeletePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeletePaymentRequest} DeletePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeletePaymentRequest message.
         * @function verify
         * @memberof api.DeletePaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeletePaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeletePaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeletePaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeletePaymentRequest} DeletePaymentRequest
         */
        DeletePaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeletePaymentRequest)
                return object;
            return new $root.api.DeletePaymentRequest();
        };

        /**
         * Creates a plain object from a DeletePaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeletePaymentRequest
         * @static
         * @param {api.DeletePaymentRequest} message DeletePaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeletePaymentRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeletePaymentRequest to JSON.
         * @function toJSON
         * @memberof api.DeletePaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeletePaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeletePaymentRequest;
    })();

    api.DeletePaymentResponse = (function() {

        /**
         * Properties of a DeletePaymentResponse.
         * @memberof api
         * @interface IDeletePaymentResponse
         */

        /**
         * Constructs a new DeletePaymentResponse.
         * @memberof api
         * @classdesc Represents a DeletePaymentResponse.
         * @implements IDeletePaymentResponse
         * @constructor
         * @param {api.IDeletePaymentResponse=} [properties] Properties to set
         */
        function DeletePaymentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeletePaymentResponse instance using the specified properties.
         * @function create
         * @memberof api.DeletePaymentResponse
         * @static
         * @param {api.IDeletePaymentResponse=} [properties] Properties to set
         * @returns {api.DeletePaymentResponse} DeletePaymentResponse instance
         */
        DeletePaymentResponse.create = function create(properties) {
            return new DeletePaymentResponse(properties);
        };

        /**
         * Encodes the specified DeletePaymentResponse message. Does not implicitly {@link api.DeletePaymentResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeletePaymentResponse
         * @static
         * @param {api.IDeletePaymentResponse} message DeletePaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePaymentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeletePaymentResponse message, length delimited. Does not implicitly {@link api.DeletePaymentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeletePaymentResponse
         * @static
         * @param {api.IDeletePaymentResponse} message DeletePaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePaymentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeletePaymentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeletePaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeletePaymentResponse} DeletePaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePaymentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeletePaymentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeletePaymentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeletePaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeletePaymentResponse} DeletePaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePaymentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeletePaymentResponse message.
         * @function verify
         * @memberof api.DeletePaymentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeletePaymentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeletePaymentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeletePaymentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeletePaymentResponse} DeletePaymentResponse
         */
        DeletePaymentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeletePaymentResponse)
                return object;
            return new $root.api.DeletePaymentResponse();
        };

        /**
         * Creates a plain object from a DeletePaymentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeletePaymentResponse
         * @static
         * @param {api.DeletePaymentResponse} message DeletePaymentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeletePaymentResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeletePaymentResponse to JSON.
         * @function toJSON
         * @memberof api.DeletePaymentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeletePaymentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeletePaymentResponse;
    })();

    api.GetAvailableModelsRequest = (function() {

        /**
         * Properties of a GetAvailableModelsRequest.
         * @memberof api
         * @interface IGetAvailableModelsRequest
         */

        /**
         * Constructs a new GetAvailableModelsRequest.
         * @memberof api
         * @classdesc Represents a GetAvailableModelsRequest.
         * @implements IGetAvailableModelsRequest
         * @constructor
         * @param {api.IGetAvailableModelsRequest=} [properties] Properties to set
         */
        function GetAvailableModelsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetAvailableModelsRequest instance using the specified properties.
         * @function create
         * @memberof api.GetAvailableModelsRequest
         * @static
         * @param {api.IGetAvailableModelsRequest=} [properties] Properties to set
         * @returns {api.GetAvailableModelsRequest} GetAvailableModelsRequest instance
         */
        GetAvailableModelsRequest.create = function create(properties) {
            return new GetAvailableModelsRequest(properties);
        };

        /**
         * Encodes the specified GetAvailableModelsRequest message. Does not implicitly {@link api.GetAvailableModelsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetAvailableModelsRequest
         * @static
         * @param {api.IGetAvailableModelsRequest} message GetAvailableModelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableModelsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetAvailableModelsRequest message, length delimited. Does not implicitly {@link api.GetAvailableModelsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetAvailableModelsRequest
         * @static
         * @param {api.IGetAvailableModelsRequest} message GetAvailableModelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableModelsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAvailableModelsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetAvailableModelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetAvailableModelsRequest} GetAvailableModelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableModelsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetAvailableModelsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAvailableModelsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetAvailableModelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetAvailableModelsRequest} GetAvailableModelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableModelsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAvailableModelsRequest message.
         * @function verify
         * @memberof api.GetAvailableModelsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAvailableModelsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetAvailableModelsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetAvailableModelsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetAvailableModelsRequest} GetAvailableModelsRequest
         */
        GetAvailableModelsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetAvailableModelsRequest)
                return object;
            return new $root.api.GetAvailableModelsRequest();
        };

        /**
         * Creates a plain object from a GetAvailableModelsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetAvailableModelsRequest
         * @static
         * @param {api.GetAvailableModelsRequest} message GetAvailableModelsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAvailableModelsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetAvailableModelsRequest to JSON.
         * @function toJSON
         * @memberof api.GetAvailableModelsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAvailableModelsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetAvailableModelsRequest;
    })();

    /**
     * ImageParameterType enum.
     * @name api.ImageParameterType
     * @enum {number}
     * @property {number} StringType=0 StringType value
     * @property {number} IntegerType=1 IntegerType value
     * @property {number} FloatType=2 FloatType value
     */
    api.ImageParameterType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "StringType"] = 0;
        values[valuesById[1] = "IntegerType"] = 1;
        values[valuesById[2] = "FloatType"] = 2;
        return values;
    })();

    api.ChoiceParameter = (function() {

        /**
         * Properties of a ChoiceParameter.
         * @memberof api
         * @interface IChoiceParameter
         * @property {string|null} [choiceValue] ChoiceParameter choiceValue
         * @property {string|null} [description] ChoiceParameter description
         */

        /**
         * Constructs a new ChoiceParameter.
         * @memberof api
         * @classdesc Represents a ChoiceParameter.
         * @implements IChoiceParameter
         * @constructor
         * @param {api.IChoiceParameter=} [properties] Properties to set
         */
        function ChoiceParameter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChoiceParameter choiceValue.
         * @member {string} choiceValue
         * @memberof api.ChoiceParameter
         * @instance
         */
        ChoiceParameter.prototype.choiceValue = "";

        /**
         * ChoiceParameter description.
         * @member {string} description
         * @memberof api.ChoiceParameter
         * @instance
         */
        ChoiceParameter.prototype.description = "";

        /**
         * Creates a new ChoiceParameter instance using the specified properties.
         * @function create
         * @memberof api.ChoiceParameter
         * @static
         * @param {api.IChoiceParameter=} [properties] Properties to set
         * @returns {api.ChoiceParameter} ChoiceParameter instance
         */
        ChoiceParameter.create = function create(properties) {
            return new ChoiceParameter(properties);
        };

        /**
         * Encodes the specified ChoiceParameter message. Does not implicitly {@link api.ChoiceParameter.verify|verify} messages.
         * @function encode
         * @memberof api.ChoiceParameter
         * @static
         * @param {api.IChoiceParameter} message ChoiceParameter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChoiceParameter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.choiceValue != null && Object.hasOwnProperty.call(message, "choiceValue"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.choiceValue);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified ChoiceParameter message, length delimited. Does not implicitly {@link api.ChoiceParameter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChoiceParameter
         * @static
         * @param {api.IChoiceParameter} message ChoiceParameter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChoiceParameter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChoiceParameter message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChoiceParameter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChoiceParameter} ChoiceParameter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChoiceParameter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChoiceParameter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.choiceValue = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChoiceParameter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChoiceParameter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChoiceParameter} ChoiceParameter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChoiceParameter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChoiceParameter message.
         * @function verify
         * @memberof api.ChoiceParameter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChoiceParameter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.choiceValue != null && message.hasOwnProperty("choiceValue"))
                if (!$util.isString(message.choiceValue))
                    return "choiceValue: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a ChoiceParameter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChoiceParameter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChoiceParameter} ChoiceParameter
         */
        ChoiceParameter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChoiceParameter)
                return object;
            let message = new $root.api.ChoiceParameter();
            if (object.choiceValue != null)
                message.choiceValue = String(object.choiceValue);
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a ChoiceParameter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChoiceParameter
         * @static
         * @param {api.ChoiceParameter} message ChoiceParameter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChoiceParameter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.choiceValue = "";
                object.description = "";
            }
            if (message.choiceValue != null && message.hasOwnProperty("choiceValue"))
                object.choiceValue = message.choiceValue;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this ChoiceParameter to JSON.
         * @function toJSON
         * @memberof api.ChoiceParameter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChoiceParameter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChoiceParameter;
    })();

    api.ImageParameter = (function() {

        /**
         * Properties of an ImageParameter.
         * @memberof api
         * @interface IImageParameter
         * @property {string|null} [parameterName] ImageParameter parameterName
         * @property {string|null} [description] ImageParameter description
         * @property {boolean|null} [required] ImageParameter required
         * @property {api.ImageParameterType|null} [parameterType] ImageParameter parameterType
         * @property {string|null} [minValue] ImageParameter minValue
         * @property {string|null} [maxValue] ImageParameter maxValue
         * @property {Array.<api.IChoiceParameter>|null} [choices] ImageParameter choices
         * @property {string|null} [defaultValue] ImageParameter defaultValue
         */

        /**
         * Constructs a new ImageParameter.
         * @memberof api
         * @classdesc Represents an ImageParameter.
         * @implements IImageParameter
         * @constructor
         * @param {api.IImageParameter=} [properties] Properties to set
         */
        function ImageParameter(properties) {
            this.choices = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageParameter parameterName.
         * @member {string} parameterName
         * @memberof api.ImageParameter
         * @instance
         */
        ImageParameter.prototype.parameterName = "";

        /**
         * ImageParameter description.
         * @member {string} description
         * @memberof api.ImageParameter
         * @instance
         */
        ImageParameter.prototype.description = "";

        /**
         * ImageParameter required.
         * @member {boolean} required
         * @memberof api.ImageParameter
         * @instance
         */
        ImageParameter.prototype.required = false;

        /**
         * ImageParameter parameterType.
         * @member {api.ImageParameterType} parameterType
         * @memberof api.ImageParameter
         * @instance
         */
        ImageParameter.prototype.parameterType = 0;

        /**
         * ImageParameter minValue.
         * @member {string} minValue
         * @memberof api.ImageParameter
         * @instance
         */
        ImageParameter.prototype.minValue = "";

        /**
         * ImageParameter maxValue.
         * @member {string} maxValue
         * @memberof api.ImageParameter
         * @instance
         */
        ImageParameter.prototype.maxValue = "";

        /**
         * ImageParameter choices.
         * @member {Array.<api.IChoiceParameter>} choices
         * @memberof api.ImageParameter
         * @instance
         */
        ImageParameter.prototype.choices = $util.emptyArray;

        /**
         * ImageParameter defaultValue.
         * @member {string} defaultValue
         * @memberof api.ImageParameter
         * @instance
         */
        ImageParameter.prototype.defaultValue = "";

        /**
         * Creates a new ImageParameter instance using the specified properties.
         * @function create
         * @memberof api.ImageParameter
         * @static
         * @param {api.IImageParameter=} [properties] Properties to set
         * @returns {api.ImageParameter} ImageParameter instance
         */
        ImageParameter.create = function create(properties) {
            return new ImageParameter(properties);
        };

        /**
         * Encodes the specified ImageParameter message. Does not implicitly {@link api.ImageParameter.verify|verify} messages.
         * @function encode
         * @memberof api.ImageParameter
         * @static
         * @param {api.IImageParameter} message ImageParameter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageParameter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parameterName != null && Object.hasOwnProperty.call(message, "parameterName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parameterName);
            if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.required);
            if (message.parameterType != null && Object.hasOwnProperty.call(message, "parameterType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.parameterType);
            if (message.minValue != null && Object.hasOwnProperty.call(message, "minValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.minValue);
            if (message.maxValue != null && Object.hasOwnProperty.call(message, "maxValue"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.maxValue);
            if (message.choices != null && message.choices.length)
                for (let i = 0; i < message.choices.length; ++i)
                    $root.api.ChoiceParameter.encode(message.choices[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.description);
            if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.defaultValue);
            return writer;
        };

        /**
         * Encodes the specified ImageParameter message, length delimited. Does not implicitly {@link api.ImageParameter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ImageParameter
         * @static
         * @param {api.IImageParameter} message ImageParameter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageParameter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageParameter message from the specified reader or buffer.
         * @function decode
         * @memberof api.ImageParameter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ImageParameter} ImageParameter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageParameter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ImageParameter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.parameterName = reader.string();
                    break;
                case 7:
                    message.description = reader.string();
                    break;
                case 2:
                    message.required = reader.bool();
                    break;
                case 3:
                    message.parameterType = reader.int32();
                    break;
                case 4:
                    message.minValue = reader.string();
                    break;
                case 5:
                    message.maxValue = reader.string();
                    break;
                case 6:
                    if (!(message.choices && message.choices.length))
                        message.choices = [];
                    message.choices.push($root.api.ChoiceParameter.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.defaultValue = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageParameter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ImageParameter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ImageParameter} ImageParameter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageParameter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageParameter message.
         * @function verify
         * @memberof api.ImageParameter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageParameter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.parameterName != null && message.hasOwnProperty("parameterName"))
                if (!$util.isString(message.parameterName))
                    return "parameterName: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.required != null && message.hasOwnProperty("required"))
                if (typeof message.required !== "boolean")
                    return "required: boolean expected";
            if (message.parameterType != null && message.hasOwnProperty("parameterType"))
                switch (message.parameterType) {
                default:
                    return "parameterType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.minValue != null && message.hasOwnProperty("minValue"))
                if (!$util.isString(message.minValue))
                    return "minValue: string expected";
            if (message.maxValue != null && message.hasOwnProperty("maxValue"))
                if (!$util.isString(message.maxValue))
                    return "maxValue: string expected";
            if (message.choices != null && message.hasOwnProperty("choices")) {
                if (!Array.isArray(message.choices))
                    return "choices: array expected";
                for (let i = 0; i < message.choices.length; ++i) {
                    let error = $root.api.ChoiceParameter.verify(message.choices[i]);
                    if (error)
                        return "choices." + error;
                }
            }
            if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                if (!$util.isString(message.defaultValue))
                    return "defaultValue: string expected";
            return null;
        };

        /**
         * Creates an ImageParameter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ImageParameter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ImageParameter} ImageParameter
         */
        ImageParameter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ImageParameter)
                return object;
            let message = new $root.api.ImageParameter();
            if (object.parameterName != null)
                message.parameterName = String(object.parameterName);
            if (object.description != null)
                message.description = String(object.description);
            if (object.required != null)
                message.required = Boolean(object.required);
            switch (object.parameterType) {
            case "StringType":
            case 0:
                message.parameterType = 0;
                break;
            case "IntegerType":
            case 1:
                message.parameterType = 1;
                break;
            case "FloatType":
            case 2:
                message.parameterType = 2;
                break;
            }
            if (object.minValue != null)
                message.minValue = String(object.minValue);
            if (object.maxValue != null)
                message.maxValue = String(object.maxValue);
            if (object.choices) {
                if (!Array.isArray(object.choices))
                    throw TypeError(".api.ImageParameter.choices: array expected");
                message.choices = [];
                for (let i = 0; i < object.choices.length; ++i) {
                    if (typeof object.choices[i] !== "object")
                        throw TypeError(".api.ImageParameter.choices: object expected");
                    message.choices[i] = $root.api.ChoiceParameter.fromObject(object.choices[i]);
                }
            }
            if (object.defaultValue != null)
                message.defaultValue = String(object.defaultValue);
            return message;
        };

        /**
         * Creates a plain object from an ImageParameter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ImageParameter
         * @static
         * @param {api.ImageParameter} message ImageParameter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageParameter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.choices = [];
            if (options.defaults) {
                object.parameterName = "";
                object.required = false;
                object.parameterType = options.enums === String ? "StringType" : 0;
                object.minValue = "";
                object.maxValue = "";
                object.description = "";
                object.defaultValue = "";
            }
            if (message.parameterName != null && message.hasOwnProperty("parameterName"))
                object.parameterName = message.parameterName;
            if (message.required != null && message.hasOwnProperty("required"))
                object.required = message.required;
            if (message.parameterType != null && message.hasOwnProperty("parameterType"))
                object.parameterType = options.enums === String ? $root.api.ImageParameterType[message.parameterType] : message.parameterType;
            if (message.minValue != null && message.hasOwnProperty("minValue"))
                object.minValue = message.minValue;
            if (message.maxValue != null && message.hasOwnProperty("maxValue"))
                object.maxValue = message.maxValue;
            if (message.choices && message.choices.length) {
                object.choices = [];
                for (let j = 0; j < message.choices.length; ++j)
                    object.choices[j] = $root.api.ChoiceParameter.toObject(message.choices[j], options);
            }
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                object.defaultValue = message.defaultValue;
            return object;
        };

        /**
         * Converts this ImageParameter to JSON.
         * @function toJSON
         * @memberof api.ImageParameter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageParameter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImageParameter;
    })();

    api.ImageModel = (function() {

        /**
         * Properties of an ImageModel.
         * @memberof api
         * @interface IImageModel
         * @property {string|null} [modelName] ImageModel modelName
         * @property {string|null} [description] ImageModel description
         * @property {string|null} [modelType] ImageModel modelType
         * @property {Array.<api.IImageParameter>|null} [parameters] ImageModel parameters
         * @property {number|null} [maxAnswers] ImageModel maxAnswers
         * @property {api.MediaType|null} [mediaType] ImageModel mediaType
         */

        /**
         * Constructs a new ImageModel.
         * @memberof api
         * @classdesc Represents an ImageModel.
         * @implements IImageModel
         * @constructor
         * @param {api.IImageModel=} [properties] Properties to set
         */
        function ImageModel(properties) {
            this.parameters = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageModel modelName.
         * @member {string} modelName
         * @memberof api.ImageModel
         * @instance
         */
        ImageModel.prototype.modelName = "";

        /**
         * ImageModel description.
         * @member {string} description
         * @memberof api.ImageModel
         * @instance
         */
        ImageModel.prototype.description = "";

        /**
         * ImageModel modelType.
         * @member {string} modelType
         * @memberof api.ImageModel
         * @instance
         */
        ImageModel.prototype.modelType = "";

        /**
         * ImageModel parameters.
         * @member {Array.<api.IImageParameter>} parameters
         * @memberof api.ImageModel
         * @instance
         */
        ImageModel.prototype.parameters = $util.emptyArray;

        /**
         * ImageModel maxAnswers.
         * @member {number} maxAnswers
         * @memberof api.ImageModel
         * @instance
         */
        ImageModel.prototype.maxAnswers = 0;

        /**
         * ImageModel mediaType.
         * @member {api.MediaType} mediaType
         * @memberof api.ImageModel
         * @instance
         */
        ImageModel.prototype.mediaType = 0;

        /**
         * Creates a new ImageModel instance using the specified properties.
         * @function create
         * @memberof api.ImageModel
         * @static
         * @param {api.IImageModel=} [properties] Properties to set
         * @returns {api.ImageModel} ImageModel instance
         */
        ImageModel.create = function create(properties) {
            return new ImageModel(properties);
        };

        /**
         * Encodes the specified ImageModel message. Does not implicitly {@link api.ImageModel.verify|verify} messages.
         * @function encode
         * @memberof api.ImageModel
         * @static
         * @param {api.IImageModel} message ImageModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageModel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.modelName != null && Object.hasOwnProperty.call(message, "modelName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.modelName);
            if (message.parameters != null && message.parameters.length)
                for (let i = 0; i < message.parameters.length; ++i)
                    $root.api.ImageParameter.encode(message.parameters[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.modelType != null && Object.hasOwnProperty.call(message, "modelType"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.modelType);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
            if (message.maxAnswers != null && Object.hasOwnProperty.call(message, "maxAnswers"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxAnswers);
            if (message.mediaType != null && Object.hasOwnProperty.call(message, "mediaType"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.mediaType);
            return writer;
        };

        /**
         * Encodes the specified ImageModel message, length delimited. Does not implicitly {@link api.ImageModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ImageModel
         * @static
         * @param {api.IImageModel} message ImageModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageModel message from the specified reader or buffer.
         * @function decode
         * @memberof api.ImageModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ImageModel} ImageModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageModel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ImageModel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.modelName = reader.string();
                    break;
                case 4:
                    message.description = reader.string();
                    break;
                case 3:
                    message.modelType = reader.string();
                    break;
                case 2:
                    if (!(message.parameters && message.parameters.length))
                        message.parameters = [];
                    message.parameters.push($root.api.ImageParameter.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.maxAnswers = reader.int32();
                    break;
                case 6:
                    message.mediaType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ImageModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ImageModel} ImageModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageModel message.
         * @function verify
         * @memberof api.ImageModel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageModel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                if (!$util.isString(message.modelName))
                    return "modelName: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.modelType != null && message.hasOwnProperty("modelType"))
                if (!$util.isString(message.modelType))
                    return "modelType: string expected";
            if (message.parameters != null && message.hasOwnProperty("parameters")) {
                if (!Array.isArray(message.parameters))
                    return "parameters: array expected";
                for (let i = 0; i < message.parameters.length; ++i) {
                    let error = $root.api.ImageParameter.verify(message.parameters[i]);
                    if (error)
                        return "parameters." + error;
                }
            }
            if (message.maxAnswers != null && message.hasOwnProperty("maxAnswers"))
                if (!$util.isInteger(message.maxAnswers))
                    return "maxAnswers: integer expected";
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                switch (message.mediaType) {
                default:
                    return "mediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates an ImageModel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ImageModel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ImageModel} ImageModel
         */
        ImageModel.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ImageModel)
                return object;
            let message = new $root.api.ImageModel();
            if (object.modelName != null)
                message.modelName = String(object.modelName);
            if (object.description != null)
                message.description = String(object.description);
            if (object.modelType != null)
                message.modelType = String(object.modelType);
            if (object.parameters) {
                if (!Array.isArray(object.parameters))
                    throw TypeError(".api.ImageModel.parameters: array expected");
                message.parameters = [];
                for (let i = 0; i < object.parameters.length; ++i) {
                    if (typeof object.parameters[i] !== "object")
                        throw TypeError(".api.ImageModel.parameters: object expected");
                    message.parameters[i] = $root.api.ImageParameter.fromObject(object.parameters[i]);
                }
            }
            if (object.maxAnswers != null)
                message.maxAnswers = object.maxAnswers | 0;
            switch (object.mediaType) {
            case "ImageMediaType":
            case 0:
                message.mediaType = 0;
                break;
            case "VideoMediaType":
            case 1:
                message.mediaType = 1;
                break;
            case "AudioMediaType":
            case 2:
                message.mediaType = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an ImageModel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ImageModel
         * @static
         * @param {api.ImageModel} message ImageModel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageModel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.parameters = [];
            if (options.defaults) {
                object.modelName = "";
                object.modelType = "";
                object.description = "";
                object.maxAnswers = 0;
                object.mediaType = options.enums === String ? "ImageMediaType" : 0;
            }
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                object.modelName = message.modelName;
            if (message.parameters && message.parameters.length) {
                object.parameters = [];
                for (let j = 0; j < message.parameters.length; ++j)
                    object.parameters[j] = $root.api.ImageParameter.toObject(message.parameters[j], options);
            }
            if (message.modelType != null && message.hasOwnProperty("modelType"))
                object.modelType = message.modelType;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.maxAnswers != null && message.hasOwnProperty("maxAnswers"))
                object.maxAnswers = message.maxAnswers;
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                object.mediaType = options.enums === String ? $root.api.MediaType[message.mediaType] : message.mediaType;
            return object;
        };

        /**
         * Converts this ImageModel to JSON.
         * @function toJSON
         * @memberof api.ImageModel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageModel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImageModel;
    })();

    api.ModelInfo = (function() {

        /**
         * Properties of a ModelInfo.
         * @memberof api
         * @interface IModelInfo
         * @property {string|null} [modelName] ModelInfo modelName
         * @property {string|null} [description] ModelInfo description
         */

        /**
         * Constructs a new ModelInfo.
         * @memberof api
         * @classdesc Represents a ModelInfo.
         * @implements IModelInfo
         * @constructor
         * @param {api.IModelInfo=} [properties] Properties to set
         */
        function ModelInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ModelInfo modelName.
         * @member {string} modelName
         * @memberof api.ModelInfo
         * @instance
         */
        ModelInfo.prototype.modelName = "";

        /**
         * ModelInfo description.
         * @member {string} description
         * @memberof api.ModelInfo
         * @instance
         */
        ModelInfo.prototype.description = "";

        /**
         * Creates a new ModelInfo instance using the specified properties.
         * @function create
         * @memberof api.ModelInfo
         * @static
         * @param {api.IModelInfo=} [properties] Properties to set
         * @returns {api.ModelInfo} ModelInfo instance
         */
        ModelInfo.create = function create(properties) {
            return new ModelInfo(properties);
        };

        /**
         * Encodes the specified ModelInfo message. Does not implicitly {@link api.ModelInfo.verify|verify} messages.
         * @function encode
         * @memberof api.ModelInfo
         * @static
         * @param {api.IModelInfo} message ModelInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModelInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.modelName != null && Object.hasOwnProperty.call(message, "modelName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.modelName);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified ModelInfo message, length delimited. Does not implicitly {@link api.ModelInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ModelInfo
         * @static
         * @param {api.IModelInfo} message ModelInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModelInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ModelInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.ModelInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ModelInfo} ModelInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModelInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ModelInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.modelName = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ModelInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ModelInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ModelInfo} ModelInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModelInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ModelInfo message.
         * @function verify
         * @memberof api.ModelInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ModelInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                if (!$util.isString(message.modelName))
                    return "modelName: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a ModelInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ModelInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ModelInfo} ModelInfo
         */
        ModelInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ModelInfo)
                return object;
            let message = new $root.api.ModelInfo();
            if (object.modelName != null)
                message.modelName = String(object.modelName);
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a ModelInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ModelInfo
         * @static
         * @param {api.ModelInfo} message ModelInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ModelInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.modelName = "";
                object.description = "";
            }
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                object.modelName = message.modelName;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this ModelInfo to JSON.
         * @function toJSON
         * @memberof api.ModelInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ModelInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ModelInfo;
    })();

    api.GetAvailableModelsResponse = (function() {

        /**
         * Properties of a GetAvailableModelsResponse.
         * @memberof api
         * @interface IGetAvailableModelsResponse
         * @property {Array.<api.IModelInfo>|null} [models] GetAvailableModelsResponse models
         * @property {Array.<api.IImageModel>|null} [imageModels] GetAvailableModelsResponse imageModels
         */

        /**
         * Constructs a new GetAvailableModelsResponse.
         * @memberof api
         * @classdesc Represents a GetAvailableModelsResponse.
         * @implements IGetAvailableModelsResponse
         * @constructor
         * @param {api.IGetAvailableModelsResponse=} [properties] Properties to set
         */
        function GetAvailableModelsResponse(properties) {
            this.models = [];
            this.imageModels = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableModelsResponse models.
         * @member {Array.<api.IModelInfo>} models
         * @memberof api.GetAvailableModelsResponse
         * @instance
         */
        GetAvailableModelsResponse.prototype.models = $util.emptyArray;

        /**
         * GetAvailableModelsResponse imageModels.
         * @member {Array.<api.IImageModel>} imageModels
         * @memberof api.GetAvailableModelsResponse
         * @instance
         */
        GetAvailableModelsResponse.prototype.imageModels = $util.emptyArray;

        /**
         * Creates a new GetAvailableModelsResponse instance using the specified properties.
         * @function create
         * @memberof api.GetAvailableModelsResponse
         * @static
         * @param {api.IGetAvailableModelsResponse=} [properties] Properties to set
         * @returns {api.GetAvailableModelsResponse} GetAvailableModelsResponse instance
         */
        GetAvailableModelsResponse.create = function create(properties) {
            return new GetAvailableModelsResponse(properties);
        };

        /**
         * Encodes the specified GetAvailableModelsResponse message. Does not implicitly {@link api.GetAvailableModelsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetAvailableModelsResponse
         * @static
         * @param {api.IGetAvailableModelsResponse} message GetAvailableModelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableModelsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.models != null && message.models.length)
                for (let i = 0; i < message.models.length; ++i)
                    $root.api.ModelInfo.encode(message.models[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.imageModels != null && message.imageModels.length)
                for (let i = 0; i < message.imageModels.length; ++i)
                    $root.api.ImageModel.encode(message.imageModels[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetAvailableModelsResponse message, length delimited. Does not implicitly {@link api.GetAvailableModelsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetAvailableModelsResponse
         * @static
         * @param {api.IGetAvailableModelsResponse} message GetAvailableModelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableModelsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAvailableModelsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetAvailableModelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetAvailableModelsResponse} GetAvailableModelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableModelsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetAvailableModelsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.models && message.models.length))
                        message.models = [];
                    message.models.push($root.api.ModelInfo.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (!(message.imageModels && message.imageModels.length))
                        message.imageModels = [];
                    message.imageModels.push($root.api.ImageModel.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAvailableModelsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetAvailableModelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetAvailableModelsResponse} GetAvailableModelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableModelsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAvailableModelsResponse message.
         * @function verify
         * @memberof api.GetAvailableModelsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAvailableModelsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.models != null && message.hasOwnProperty("models")) {
                if (!Array.isArray(message.models))
                    return "models: array expected";
                for (let i = 0; i < message.models.length; ++i) {
                    let error = $root.api.ModelInfo.verify(message.models[i]);
                    if (error)
                        return "models." + error;
                }
            }
            if (message.imageModels != null && message.hasOwnProperty("imageModels")) {
                if (!Array.isArray(message.imageModels))
                    return "imageModels: array expected";
                for (let i = 0; i < message.imageModels.length; ++i) {
                    let error = $root.api.ImageModel.verify(message.imageModels[i]);
                    if (error)
                        return "imageModels." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetAvailableModelsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetAvailableModelsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetAvailableModelsResponse} GetAvailableModelsResponse
         */
        GetAvailableModelsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetAvailableModelsResponse)
                return object;
            let message = new $root.api.GetAvailableModelsResponse();
            if (object.models) {
                if (!Array.isArray(object.models))
                    throw TypeError(".api.GetAvailableModelsResponse.models: array expected");
                message.models = [];
                for (let i = 0; i < object.models.length; ++i) {
                    if (typeof object.models[i] !== "object")
                        throw TypeError(".api.GetAvailableModelsResponse.models: object expected");
                    message.models[i] = $root.api.ModelInfo.fromObject(object.models[i]);
                }
            }
            if (object.imageModels) {
                if (!Array.isArray(object.imageModels))
                    throw TypeError(".api.GetAvailableModelsResponse.imageModels: array expected");
                message.imageModels = [];
                for (let i = 0; i < object.imageModels.length; ++i) {
                    if (typeof object.imageModels[i] !== "object")
                        throw TypeError(".api.GetAvailableModelsResponse.imageModels: object expected");
                    message.imageModels[i] = $root.api.ImageModel.fromObject(object.imageModels[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetAvailableModelsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetAvailableModelsResponse
         * @static
         * @param {api.GetAvailableModelsResponse} message GetAvailableModelsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAvailableModelsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.models = [];
                object.imageModels = [];
            }
            if (message.models && message.models.length) {
                object.models = [];
                for (let j = 0; j < message.models.length; ++j)
                    object.models[j] = $root.api.ModelInfo.toObject(message.models[j], options);
            }
            if (message.imageModels && message.imageModels.length) {
                object.imageModels = [];
                for (let j = 0; j < message.imageModels.length; ++j)
                    object.imageModels[j] = $root.api.ImageModel.toObject(message.imageModels[j], options);
            }
            return object;
        };

        /**
         * Converts this GetAvailableModelsResponse to JSON.
         * @function toJSON
         * @memberof api.GetAvailableModelsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAvailableModelsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetAvailableModelsResponse;
    })();

    api.GetAvailablePlansRequest = (function() {

        /**
         * Properties of a GetAvailablePlansRequest.
         * @memberof api
         * @interface IGetAvailablePlansRequest
         */

        /**
         * Constructs a new GetAvailablePlansRequest.
         * @memberof api
         * @classdesc Represents a GetAvailablePlansRequest.
         * @implements IGetAvailablePlansRequest
         * @constructor
         * @param {api.IGetAvailablePlansRequest=} [properties] Properties to set
         */
        function GetAvailablePlansRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetAvailablePlansRequest instance using the specified properties.
         * @function create
         * @memberof api.GetAvailablePlansRequest
         * @static
         * @param {api.IGetAvailablePlansRequest=} [properties] Properties to set
         * @returns {api.GetAvailablePlansRequest} GetAvailablePlansRequest instance
         */
        GetAvailablePlansRequest.create = function create(properties) {
            return new GetAvailablePlansRequest(properties);
        };

        /**
         * Encodes the specified GetAvailablePlansRequest message. Does not implicitly {@link api.GetAvailablePlansRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetAvailablePlansRequest
         * @static
         * @param {api.IGetAvailablePlansRequest} message GetAvailablePlansRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailablePlansRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetAvailablePlansRequest message, length delimited. Does not implicitly {@link api.GetAvailablePlansRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetAvailablePlansRequest
         * @static
         * @param {api.IGetAvailablePlansRequest} message GetAvailablePlansRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailablePlansRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAvailablePlansRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetAvailablePlansRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetAvailablePlansRequest} GetAvailablePlansRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailablePlansRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetAvailablePlansRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAvailablePlansRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetAvailablePlansRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetAvailablePlansRequest} GetAvailablePlansRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailablePlansRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAvailablePlansRequest message.
         * @function verify
         * @memberof api.GetAvailablePlansRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAvailablePlansRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetAvailablePlansRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetAvailablePlansRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetAvailablePlansRequest} GetAvailablePlansRequest
         */
        GetAvailablePlansRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetAvailablePlansRequest)
                return object;
            return new $root.api.GetAvailablePlansRequest();
        };

        /**
         * Creates a plain object from a GetAvailablePlansRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetAvailablePlansRequest
         * @static
         * @param {api.GetAvailablePlansRequest} message GetAvailablePlansRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAvailablePlansRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetAvailablePlansRequest to JSON.
         * @function toJSON
         * @memberof api.GetAvailablePlansRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAvailablePlansRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetAvailablePlansRequest;
    })();

    api.PlanInfo = (function() {

        /**
         * Properties of a PlanInfo.
         * @memberof api
         * @interface IPlanInfo
         * @property {string|null} [planId] PlanInfo planId
         * @property {number|null} [amount] PlanInfo amount
         * @property {number|Long|null} [tokens] PlanInfo tokens
         */

        /**
         * Constructs a new PlanInfo.
         * @memberof api
         * @classdesc Represents a PlanInfo.
         * @implements IPlanInfo
         * @constructor
         * @param {api.IPlanInfo=} [properties] Properties to set
         */
        function PlanInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlanInfo planId.
         * @member {string} planId
         * @memberof api.PlanInfo
         * @instance
         */
        PlanInfo.prototype.planId = "";

        /**
         * PlanInfo amount.
         * @member {number} amount
         * @memberof api.PlanInfo
         * @instance
         */
        PlanInfo.prototype.amount = 0;

        /**
         * PlanInfo tokens.
         * @member {number|Long} tokens
         * @memberof api.PlanInfo
         * @instance
         */
        PlanInfo.prototype.tokens = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new PlanInfo instance using the specified properties.
         * @function create
         * @memberof api.PlanInfo
         * @static
         * @param {api.IPlanInfo=} [properties] Properties to set
         * @returns {api.PlanInfo} PlanInfo instance
         */
        PlanInfo.create = function create(properties) {
            return new PlanInfo(properties);
        };

        /**
         * Encodes the specified PlanInfo message. Does not implicitly {@link api.PlanInfo.verify|verify} messages.
         * @function encode
         * @memberof api.PlanInfo
         * @static
         * @param {api.IPlanInfo} message PlanInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlanInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.planId != null && Object.hasOwnProperty.call(message, "planId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.planId);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.amount);
            if (message.tokens != null && Object.hasOwnProperty.call(message, "tokens"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.tokens);
            return writer;
        };

        /**
         * Encodes the specified PlanInfo message, length delimited. Does not implicitly {@link api.PlanInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PlanInfo
         * @static
         * @param {api.IPlanInfo} message PlanInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlanInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlanInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.PlanInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PlanInfo} PlanInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlanInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PlanInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.planId = reader.string();
                    break;
                case 2:
                    message.amount = reader.double();
                    break;
                case 3:
                    message.tokens = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlanInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PlanInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PlanInfo} PlanInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlanInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlanInfo message.
         * @function verify
         * @memberof api.PlanInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlanInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.planId != null && message.hasOwnProperty("planId"))
                if (!$util.isString(message.planId))
                    return "planId: string expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount !== "number")
                    return "amount: number expected";
            if (message.tokens != null && message.hasOwnProperty("tokens"))
                if (!$util.isInteger(message.tokens) && !(message.tokens && $util.isInteger(message.tokens.low) && $util.isInteger(message.tokens.high)))
                    return "tokens: integer|Long expected";
            return null;
        };

        /**
         * Creates a PlanInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PlanInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PlanInfo} PlanInfo
         */
        PlanInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PlanInfo)
                return object;
            let message = new $root.api.PlanInfo();
            if (object.planId != null)
                message.planId = String(object.planId);
            if (object.amount != null)
                message.amount = Number(object.amount);
            if (object.tokens != null)
                if ($util.Long)
                    (message.tokens = $util.Long.fromValue(object.tokens)).unsigned = false;
                else if (typeof object.tokens === "string")
                    message.tokens = parseInt(object.tokens, 10);
                else if (typeof object.tokens === "number")
                    message.tokens = object.tokens;
                else if (typeof object.tokens === "object")
                    message.tokens = new $util.LongBits(object.tokens.low >>> 0, object.tokens.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a PlanInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PlanInfo
         * @static
         * @param {api.PlanInfo} message PlanInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlanInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.planId = "";
                object.amount = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokens = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokens = options.longs === String ? "0" : 0;
            }
            if (message.planId != null && message.hasOwnProperty("planId"))
                object.planId = message.planId;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
            if (message.tokens != null && message.hasOwnProperty("tokens"))
                if (typeof message.tokens === "number")
                    object.tokens = options.longs === String ? String(message.tokens) : message.tokens;
                else
                    object.tokens = options.longs === String ? $util.Long.prototype.toString.call(message.tokens) : options.longs === Number ? new $util.LongBits(message.tokens.low >>> 0, message.tokens.high >>> 0).toNumber() : message.tokens;
            return object;
        };

        /**
         * Converts this PlanInfo to JSON.
         * @function toJSON
         * @memberof api.PlanInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlanInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlanInfo;
    })();

    api.GetAvailablePlansResponse = (function() {

        /**
         * Properties of a GetAvailablePlansResponse.
         * @memberof api
         * @interface IGetAvailablePlansResponse
         * @property {Array.<api.IPlanInfo>|null} [plans] GetAvailablePlansResponse plans
         */

        /**
         * Constructs a new GetAvailablePlansResponse.
         * @memberof api
         * @classdesc Represents a GetAvailablePlansResponse.
         * @implements IGetAvailablePlansResponse
         * @constructor
         * @param {api.IGetAvailablePlansResponse=} [properties] Properties to set
         */
        function GetAvailablePlansResponse(properties) {
            this.plans = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailablePlansResponse plans.
         * @member {Array.<api.IPlanInfo>} plans
         * @memberof api.GetAvailablePlansResponse
         * @instance
         */
        GetAvailablePlansResponse.prototype.plans = $util.emptyArray;

        /**
         * Creates a new GetAvailablePlansResponse instance using the specified properties.
         * @function create
         * @memberof api.GetAvailablePlansResponse
         * @static
         * @param {api.IGetAvailablePlansResponse=} [properties] Properties to set
         * @returns {api.GetAvailablePlansResponse} GetAvailablePlansResponse instance
         */
        GetAvailablePlansResponse.create = function create(properties) {
            return new GetAvailablePlansResponse(properties);
        };

        /**
         * Encodes the specified GetAvailablePlansResponse message. Does not implicitly {@link api.GetAvailablePlansResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetAvailablePlansResponse
         * @static
         * @param {api.IGetAvailablePlansResponse} message GetAvailablePlansResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailablePlansResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.plans != null && message.plans.length)
                for (let i = 0; i < message.plans.length; ++i)
                    $root.api.PlanInfo.encode(message.plans[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetAvailablePlansResponse message, length delimited. Does not implicitly {@link api.GetAvailablePlansResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetAvailablePlansResponse
         * @static
         * @param {api.IGetAvailablePlansResponse} message GetAvailablePlansResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailablePlansResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAvailablePlansResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetAvailablePlansResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetAvailablePlansResponse} GetAvailablePlansResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailablePlansResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetAvailablePlansResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.plans && message.plans.length))
                        message.plans = [];
                    message.plans.push($root.api.PlanInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAvailablePlansResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetAvailablePlansResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetAvailablePlansResponse} GetAvailablePlansResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailablePlansResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAvailablePlansResponse message.
         * @function verify
         * @memberof api.GetAvailablePlansResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAvailablePlansResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.plans != null && message.hasOwnProperty("plans")) {
                if (!Array.isArray(message.plans))
                    return "plans: array expected";
                for (let i = 0; i < message.plans.length; ++i) {
                    let error = $root.api.PlanInfo.verify(message.plans[i]);
                    if (error)
                        return "plans." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetAvailablePlansResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetAvailablePlansResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetAvailablePlansResponse} GetAvailablePlansResponse
         */
        GetAvailablePlansResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetAvailablePlansResponse)
                return object;
            let message = new $root.api.GetAvailablePlansResponse();
            if (object.plans) {
                if (!Array.isArray(object.plans))
                    throw TypeError(".api.GetAvailablePlansResponse.plans: array expected");
                message.plans = [];
                for (let i = 0; i < object.plans.length; ++i) {
                    if (typeof object.plans[i] !== "object")
                        throw TypeError(".api.GetAvailablePlansResponse.plans: object expected");
                    message.plans[i] = $root.api.PlanInfo.fromObject(object.plans[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetAvailablePlansResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetAvailablePlansResponse
         * @static
         * @param {api.GetAvailablePlansResponse} message GetAvailablePlansResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAvailablePlansResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.plans = [];
            if (message.plans && message.plans.length) {
                object.plans = [];
                for (let j = 0; j < message.plans.length; ++j)
                    object.plans[j] = $root.api.PlanInfo.toObject(message.plans[j], options);
            }
            return object;
        };

        /**
         * Converts this GetAvailablePlansResponse to JSON.
         * @function toJSON
         * @memberof api.GetAvailablePlansResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAvailablePlansResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetAvailablePlansResponse;
    })();

    api.GenerateParameter = (function() {

        /**
         * Properties of a GenerateParameter.
         * @memberof api
         * @interface IGenerateParameter
         * @property {string|null} [name] GenerateParameter name
         * @property {string|null} [value] GenerateParameter value
         */

        /**
         * Constructs a new GenerateParameter.
         * @memberof api
         * @classdesc Represents a GenerateParameter.
         * @implements IGenerateParameter
         * @constructor
         * @param {api.IGenerateParameter=} [properties] Properties to set
         */
        function GenerateParameter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateParameter name.
         * @member {string} name
         * @memberof api.GenerateParameter
         * @instance
         */
        GenerateParameter.prototype.name = "";

        /**
         * GenerateParameter value.
         * @member {string} value
         * @memberof api.GenerateParameter
         * @instance
         */
        GenerateParameter.prototype.value = "";

        /**
         * Creates a new GenerateParameter instance using the specified properties.
         * @function create
         * @memberof api.GenerateParameter
         * @static
         * @param {api.IGenerateParameter=} [properties] Properties to set
         * @returns {api.GenerateParameter} GenerateParameter instance
         */
        GenerateParameter.create = function create(properties) {
            return new GenerateParameter(properties);
        };

        /**
         * Encodes the specified GenerateParameter message. Does not implicitly {@link api.GenerateParameter.verify|verify} messages.
         * @function encode
         * @memberof api.GenerateParameter
         * @static
         * @param {api.IGenerateParameter} message GenerateParameter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateParameter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified GenerateParameter message, length delimited. Does not implicitly {@link api.GenerateParameter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GenerateParameter
         * @static
         * @param {api.IGenerateParameter} message GenerateParameter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateParameter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateParameter message from the specified reader or buffer.
         * @function decode
         * @memberof api.GenerateParameter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GenerateParameter} GenerateParameter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateParameter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GenerateParameter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateParameter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GenerateParameter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GenerateParameter} GenerateParameter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateParameter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateParameter message.
         * @function verify
         * @memberof api.GenerateParameter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateParameter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };

        /**
         * Creates a GenerateParameter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GenerateParameter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GenerateParameter} GenerateParameter
         */
        GenerateParameter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GenerateParameter)
                return object;
            let message = new $root.api.GenerateParameter();
            if (object.name != null)
                message.name = String(object.name);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from a GenerateParameter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GenerateParameter
         * @static
         * @param {api.GenerateParameter} message GenerateParameter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateParameter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.value = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this GenerateParameter to JSON.
         * @function toJSON
         * @memberof api.GenerateParameter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateParameter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenerateParameter;
    })();

    api.GenerateTextRequest = (function() {

        /**
         * Properties of a GenerateTextRequest.
         * @memberof api
         * @interface IGenerateTextRequest
         * @property {string|null} [conversationId] GenerateTextRequest conversationId
         * @property {Array.<string>|null} [fileIds] GenerateTextRequest fileIds
         * @property {Array.<string>|null} [urls] GenerateTextRequest urls
         */

        /**
         * Constructs a new GenerateTextRequest.
         * @memberof api
         * @classdesc Represents a GenerateTextRequest.
         * @implements IGenerateTextRequest
         * @constructor
         * @param {api.IGenerateTextRequest=} [properties] Properties to set
         */
        function GenerateTextRequest(properties) {
            this.fileIds = [];
            this.urls = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateTextRequest conversationId.
         * @member {string} conversationId
         * @memberof api.GenerateTextRequest
         * @instance
         */
        GenerateTextRequest.prototype.conversationId = "";

        /**
         * GenerateTextRequest fileIds.
         * @member {Array.<string>} fileIds
         * @memberof api.GenerateTextRequest
         * @instance
         */
        GenerateTextRequest.prototype.fileIds = $util.emptyArray;

        /**
         * GenerateTextRequest urls.
         * @member {Array.<string>} urls
         * @memberof api.GenerateTextRequest
         * @instance
         */
        GenerateTextRequest.prototype.urls = $util.emptyArray;

        /**
         * Creates a new GenerateTextRequest instance using the specified properties.
         * @function create
         * @memberof api.GenerateTextRequest
         * @static
         * @param {api.IGenerateTextRequest=} [properties] Properties to set
         * @returns {api.GenerateTextRequest} GenerateTextRequest instance
         */
        GenerateTextRequest.create = function create(properties) {
            return new GenerateTextRequest(properties);
        };

        /**
         * Encodes the specified GenerateTextRequest message. Does not implicitly {@link api.GenerateTextRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GenerateTextRequest
         * @static
         * @param {api.IGenerateTextRequest} message GenerateTextRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateTextRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileIds != null && message.fileIds.length)
                for (let i = 0; i < message.fileIds.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.fileIds[i]);
            if (message.urls != null && message.urls.length)
                for (let i = 0; i < message.urls.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.urls[i]);
            if (message.conversationId != null && Object.hasOwnProperty.call(message, "conversationId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.conversationId);
            return writer;
        };

        /**
         * Encodes the specified GenerateTextRequest message, length delimited. Does not implicitly {@link api.GenerateTextRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GenerateTextRequest
         * @static
         * @param {api.IGenerateTextRequest} message GenerateTextRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateTextRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateTextRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GenerateTextRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GenerateTextRequest} GenerateTextRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateTextRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GenerateTextRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6:
                    message.conversationId = reader.string();
                    break;
                case 3:
                    if (!(message.fileIds && message.fileIds.length))
                        message.fileIds = [];
                    message.fileIds.push(reader.string());
                    break;
                case 4:
                    if (!(message.urls && message.urls.length))
                        message.urls = [];
                    message.urls.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateTextRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GenerateTextRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GenerateTextRequest} GenerateTextRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateTextRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateTextRequest message.
         * @function verify
         * @memberof api.GenerateTextRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateTextRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                if (!$util.isString(message.conversationId))
                    return "conversationId: string expected";
            if (message.fileIds != null && message.hasOwnProperty("fileIds")) {
                if (!Array.isArray(message.fileIds))
                    return "fileIds: array expected";
                for (let i = 0; i < message.fileIds.length; ++i)
                    if (!$util.isString(message.fileIds[i]))
                        return "fileIds: string[] expected";
            }
            if (message.urls != null && message.hasOwnProperty("urls")) {
                if (!Array.isArray(message.urls))
                    return "urls: array expected";
                for (let i = 0; i < message.urls.length; ++i)
                    if (!$util.isString(message.urls[i]))
                        return "urls: string[] expected";
            }
            return null;
        };

        /**
         * Creates a GenerateTextRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GenerateTextRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GenerateTextRequest} GenerateTextRequest
         */
        GenerateTextRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GenerateTextRequest)
                return object;
            let message = new $root.api.GenerateTextRequest();
            if (object.conversationId != null)
                message.conversationId = String(object.conversationId);
            if (object.fileIds) {
                if (!Array.isArray(object.fileIds))
                    throw TypeError(".api.GenerateTextRequest.fileIds: array expected");
                message.fileIds = [];
                for (let i = 0; i < object.fileIds.length; ++i)
                    message.fileIds[i] = String(object.fileIds[i]);
            }
            if (object.urls) {
                if (!Array.isArray(object.urls))
                    throw TypeError(".api.GenerateTextRequest.urls: array expected");
                message.urls = [];
                for (let i = 0; i < object.urls.length; ++i)
                    message.urls[i] = String(object.urls[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a GenerateTextRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GenerateTextRequest
         * @static
         * @param {api.GenerateTextRequest} message GenerateTextRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateTextRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.fileIds = [];
                object.urls = [];
            }
            if (options.defaults)
                object.conversationId = "";
            if (message.fileIds && message.fileIds.length) {
                object.fileIds = [];
                for (let j = 0; j < message.fileIds.length; ++j)
                    object.fileIds[j] = message.fileIds[j];
            }
            if (message.urls && message.urls.length) {
                object.urls = [];
                for (let j = 0; j < message.urls.length; ++j)
                    object.urls[j] = message.urls[j];
            }
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                object.conversationId = message.conversationId;
            return object;
        };

        /**
         * Converts this GenerateTextRequest to JSON.
         * @function toJSON
         * @memberof api.GenerateTextRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateTextRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenerateTextRequest;
    })();

    api.GenerateImageRequest = (function() {

        /**
         * Properties of a GenerateImageRequest.
         * @memberof api
         * @interface IGenerateImageRequest
         * @property {string|null} [negativePrompt] GenerateImageRequest negativePrompt
         * @property {string|null} [modelName] GenerateImageRequest modelName
         * @property {string|null} [imageSize] GenerateImageRequest imageSize
         * @property {number|Long|null} [seed] GenerateImageRequest seed
         * @property {number|null} [guidanceScale] GenerateImageRequest guidanceScale
         * @property {string|null} [scheduler] GenerateImageRequest scheduler
         * @property {number|null} [steps] GenerateImageRequest steps
         * @property {string|null} [sourceImageId] GenerateImageRequest sourceImageId
         * @property {string|null} [maskImageId] GenerateImageRequest maskImageId
         */

        /**
         * Constructs a new GenerateImageRequest.
         * @memberof api
         * @classdesc Represents a GenerateImageRequest.
         * @implements IGenerateImageRequest
         * @constructor
         * @param {api.IGenerateImageRequest=} [properties] Properties to set
         */
        function GenerateImageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateImageRequest negativePrompt.
         * @member {string} negativePrompt
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.negativePrompt = "";

        /**
         * GenerateImageRequest modelName.
         * @member {string} modelName
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.modelName = "";

        /**
         * GenerateImageRequest imageSize.
         * @member {string} imageSize
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.imageSize = "";

        /**
         * GenerateImageRequest seed.
         * @member {number|Long} seed
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.seed = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * GenerateImageRequest guidanceScale.
         * @member {number} guidanceScale
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.guidanceScale = 0;

        /**
         * GenerateImageRequest scheduler.
         * @member {string} scheduler
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.scheduler = "";

        /**
         * GenerateImageRequest steps.
         * @member {number} steps
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.steps = 0;

        /**
         * GenerateImageRequest sourceImageId.
         * @member {string} sourceImageId
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.sourceImageId = "";

        /**
         * GenerateImageRequest maskImageId.
         * @member {string} maskImageId
         * @memberof api.GenerateImageRequest
         * @instance
         */
        GenerateImageRequest.prototype.maskImageId = "";

        /**
         * Creates a new GenerateImageRequest instance using the specified properties.
         * @function create
         * @memberof api.GenerateImageRequest
         * @static
         * @param {api.IGenerateImageRequest=} [properties] Properties to set
         * @returns {api.GenerateImageRequest} GenerateImageRequest instance
         */
        GenerateImageRequest.create = function create(properties) {
            return new GenerateImageRequest(properties);
        };

        /**
         * Encodes the specified GenerateImageRequest message. Does not implicitly {@link api.GenerateImageRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GenerateImageRequest
         * @static
         * @param {api.IGenerateImageRequest} message GenerateImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageSize != null && Object.hasOwnProperty.call(message, "imageSize"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageSize);
            if (message.negativePrompt != null && Object.hasOwnProperty.call(message, "negativePrompt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.negativePrompt);
            if (message.modelName != null && Object.hasOwnProperty.call(message, "modelName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.modelName);
            if (message.seed != null && Object.hasOwnProperty.call(message, "seed"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.seed);
            if (message.guidanceScale != null && Object.hasOwnProperty.call(message, "guidanceScale"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.guidanceScale);
            if (message.scheduler != null && Object.hasOwnProperty.call(message, "scheduler"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.scheduler);
            if (message.steps != null && Object.hasOwnProperty.call(message, "steps"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.steps);
            if (message.sourceImageId != null && Object.hasOwnProperty.call(message, "sourceImageId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.sourceImageId);
            if (message.maskImageId != null && Object.hasOwnProperty.call(message, "maskImageId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.maskImageId);
            return writer;
        };

        /**
         * Encodes the specified GenerateImageRequest message, length delimited. Does not implicitly {@link api.GenerateImageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GenerateImageRequest
         * @static
         * @param {api.IGenerateImageRequest} message GenerateImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateImageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GenerateImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GenerateImageRequest} GenerateImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GenerateImageRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.negativePrompt = reader.string();
                    break;
                case 4:
                    message.modelName = reader.string();
                    break;
                case 2:
                    message.imageSize = reader.string();
                    break;
                case 5:
                    message.seed = reader.uint64();
                    break;
                case 6:
                    message.guidanceScale = reader.double();
                    break;
                case 7:
                    message.scheduler = reader.string();
                    break;
                case 8:
                    message.steps = reader.int32();
                    break;
                case 9:
                    message.sourceImageId = reader.string();
                    break;
                case 10:
                    message.maskImageId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateImageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GenerateImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GenerateImageRequest} GenerateImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateImageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateImageRequest message.
         * @function verify
         * @memberof api.GenerateImageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateImageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.negativePrompt != null && message.hasOwnProperty("negativePrompt"))
                if (!$util.isString(message.negativePrompt))
                    return "negativePrompt: string expected";
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                if (!$util.isString(message.modelName))
                    return "modelName: string expected";
            if (message.imageSize != null && message.hasOwnProperty("imageSize"))
                if (!$util.isString(message.imageSize))
                    return "imageSize: string expected";
            if (message.seed != null && message.hasOwnProperty("seed"))
                if (!$util.isInteger(message.seed) && !(message.seed && $util.isInteger(message.seed.low) && $util.isInteger(message.seed.high)))
                    return "seed: integer|Long expected";
            if (message.guidanceScale != null && message.hasOwnProperty("guidanceScale"))
                if (typeof message.guidanceScale !== "number")
                    return "guidanceScale: number expected";
            if (message.scheduler != null && message.hasOwnProperty("scheduler"))
                if (!$util.isString(message.scheduler))
                    return "scheduler: string expected";
            if (message.steps != null && message.hasOwnProperty("steps"))
                if (!$util.isInteger(message.steps))
                    return "steps: integer expected";
            if (message.sourceImageId != null && message.hasOwnProperty("sourceImageId"))
                if (!$util.isString(message.sourceImageId))
                    return "sourceImageId: string expected";
            if (message.maskImageId != null && message.hasOwnProperty("maskImageId"))
                if (!$util.isString(message.maskImageId))
                    return "maskImageId: string expected";
            return null;
        };

        /**
         * Creates a GenerateImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GenerateImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GenerateImageRequest} GenerateImageRequest
         */
        GenerateImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GenerateImageRequest)
                return object;
            let message = new $root.api.GenerateImageRequest();
            if (object.negativePrompt != null)
                message.negativePrompt = String(object.negativePrompt);
            if (object.modelName != null)
                message.modelName = String(object.modelName);
            if (object.imageSize != null)
                message.imageSize = String(object.imageSize);
            if (object.seed != null)
                if ($util.Long)
                    (message.seed = $util.Long.fromValue(object.seed)).unsigned = true;
                else if (typeof object.seed === "string")
                    message.seed = parseInt(object.seed, 10);
                else if (typeof object.seed === "number")
                    message.seed = object.seed;
                else if (typeof object.seed === "object")
                    message.seed = new $util.LongBits(object.seed.low >>> 0, object.seed.high >>> 0).toNumber(true);
            if (object.guidanceScale != null)
                message.guidanceScale = Number(object.guidanceScale);
            if (object.scheduler != null)
                message.scheduler = String(object.scheduler);
            if (object.steps != null)
                message.steps = object.steps | 0;
            if (object.sourceImageId != null)
                message.sourceImageId = String(object.sourceImageId);
            if (object.maskImageId != null)
                message.maskImageId = String(object.maskImageId);
            return message;
        };

        /**
         * Creates a plain object from a GenerateImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GenerateImageRequest
         * @static
         * @param {api.GenerateImageRequest} message GenerateImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.imageSize = "";
                object.negativePrompt = "";
                object.modelName = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.seed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.seed = options.longs === String ? "0" : 0;
                object.guidanceScale = 0;
                object.scheduler = "";
                object.steps = 0;
                object.sourceImageId = "";
                object.maskImageId = "";
            }
            if (message.imageSize != null && message.hasOwnProperty("imageSize"))
                object.imageSize = message.imageSize;
            if (message.negativePrompt != null && message.hasOwnProperty("negativePrompt"))
                object.negativePrompt = message.negativePrompt;
            if (message.modelName != null && message.hasOwnProperty("modelName"))
                object.modelName = message.modelName;
            if (message.seed != null && message.hasOwnProperty("seed"))
                if (typeof message.seed === "number")
                    object.seed = options.longs === String ? String(message.seed) : message.seed;
                else
                    object.seed = options.longs === String ? $util.Long.prototype.toString.call(message.seed) : options.longs === Number ? new $util.LongBits(message.seed.low >>> 0, message.seed.high >>> 0).toNumber(true) : message.seed;
            if (message.guidanceScale != null && message.hasOwnProperty("guidanceScale"))
                object.guidanceScale = options.json && !isFinite(message.guidanceScale) ? String(message.guidanceScale) : message.guidanceScale;
            if (message.scheduler != null && message.hasOwnProperty("scheduler"))
                object.scheduler = message.scheduler;
            if (message.steps != null && message.hasOwnProperty("steps"))
                object.steps = message.steps;
            if (message.sourceImageId != null && message.hasOwnProperty("sourceImageId"))
                object.sourceImageId = message.sourceImageId;
            if (message.maskImageId != null && message.hasOwnProperty("maskImageId"))
                object.maskImageId = message.maskImageId;
            return object;
        };

        /**
         * Converts this GenerateImageRequest to JSON.
         * @function toJSON
         * @memberof api.GenerateImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenerateImageRequest;
    })();

    api.GenerateAIRequest = (function() {

        /**
         * Properties of a GenerateAIRequest.
         * @memberof api
         * @interface IGenerateAIRequest
         * @property {number|null} [numAnswers] GenerateAIRequest numAnswers
         * @property {string|null} [template] GenerateAIRequest template
         * @property {Object.<string,string>|null} [parameters] GenerateAIRequest parameters
         * @property {api.IGenerateTextRequest|null} [generateTextRequest] GenerateAIRequest generateTextRequest
         * @property {api.IGenerateImageRequest|null} [generateImageRequest] GenerateAIRequest generateImageRequest
         * @property {number|Long|null} [timeout] GenerateAIRequest timeout
         * @property {number|null} [priority] GenerateAIRequest priority
         * @property {boolean|null} [paidOnly] GenerateAIRequest paidOnly
         */

        /**
         * Constructs a new GenerateAIRequest.
         * @memberof api
         * @classdesc Represents a GenerateAIRequest.
         * @implements IGenerateAIRequest
         * @constructor
         * @param {api.IGenerateAIRequest=} [properties] Properties to set
         */
        function GenerateAIRequest(properties) {
            this.parameters = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateAIRequest numAnswers.
         * @member {number} numAnswers
         * @memberof api.GenerateAIRequest
         * @instance
         */
        GenerateAIRequest.prototype.numAnswers = 0;

        /**
         * GenerateAIRequest template.
         * @member {string} template
         * @memberof api.GenerateAIRequest
         * @instance
         */
        GenerateAIRequest.prototype.template = "";

        /**
         * GenerateAIRequest parameters.
         * @member {Object.<string,string>} parameters
         * @memberof api.GenerateAIRequest
         * @instance
         */
        GenerateAIRequest.prototype.parameters = $util.emptyObject;

        /**
         * GenerateAIRequest generateTextRequest.
         * @member {api.IGenerateTextRequest|null|undefined} generateTextRequest
         * @memberof api.GenerateAIRequest
         * @instance
         */
        GenerateAIRequest.prototype.generateTextRequest = null;

        /**
         * GenerateAIRequest generateImageRequest.
         * @member {api.IGenerateImageRequest|null|undefined} generateImageRequest
         * @memberof api.GenerateAIRequest
         * @instance
         */
        GenerateAIRequest.prototype.generateImageRequest = null;

        /**
         * GenerateAIRequest timeout.
         * @member {number|Long} timeout
         * @memberof api.GenerateAIRequest
         * @instance
         */
        GenerateAIRequest.prototype.timeout = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GenerateAIRequest priority.
         * @member {number} priority
         * @memberof api.GenerateAIRequest
         * @instance
         */
        GenerateAIRequest.prototype.priority = 0;

        /**
         * GenerateAIRequest paidOnly.
         * @member {boolean} paidOnly
         * @memberof api.GenerateAIRequest
         * @instance
         */
        GenerateAIRequest.prototype.paidOnly = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GenerateAIRequest requestType.
         * @member {"generateTextRequest"|"generateImageRequest"|undefined} requestType
         * @memberof api.GenerateAIRequest
         * @instance
         */
        Object.defineProperty(GenerateAIRequest.prototype, "requestType", {
            get: $util.oneOfGetter($oneOfFields = ["generateTextRequest", "generateImageRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GenerateAIRequest instance using the specified properties.
         * @function create
         * @memberof api.GenerateAIRequest
         * @static
         * @param {api.IGenerateAIRequest=} [properties] Properties to set
         * @returns {api.GenerateAIRequest} GenerateAIRequest instance
         */
        GenerateAIRequest.create = function create(properties) {
            return new GenerateAIRequest(properties);
        };

        /**
         * Encodes the specified GenerateAIRequest message. Does not implicitly {@link api.GenerateAIRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GenerateAIRequest
         * @static
         * @param {api.IGenerateAIRequest} message GenerateAIRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateAIRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.generateTextRequest != null && Object.hasOwnProperty.call(message, "generateTextRequest"))
                $root.api.GenerateTextRequest.encode(message.generateTextRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.generateImageRequest != null && Object.hasOwnProperty.call(message, "generateImageRequest"))
                $root.api.GenerateImageRequest.encode(message.generateImageRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.numAnswers != null && Object.hasOwnProperty.call(message, "numAnswers"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.numAnswers);
            if (message.template != null && Object.hasOwnProperty.call(message, "template"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.template);
            if (message.parameters != null && Object.hasOwnProperty.call(message, "parameters"))
                for (let keys = Object.keys(message.parameters), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.parameters[keys[i]]).ldelim();
            if (message.timeout != null && Object.hasOwnProperty.call(message, "timeout"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.timeout);
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.priority);
            if (message.paidOnly != null && Object.hasOwnProperty.call(message, "paidOnly"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.paidOnly);
            return writer;
        };

        /**
         * Encodes the specified GenerateAIRequest message, length delimited. Does not implicitly {@link api.GenerateAIRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GenerateAIRequest
         * @static
         * @param {api.IGenerateAIRequest} message GenerateAIRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateAIRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateAIRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GenerateAIRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GenerateAIRequest} GenerateAIRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateAIRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GenerateAIRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4:
                    message.numAnswers = reader.int32();
                    break;
                case 5:
                    message.template = reader.string();
                    break;
                case 6:
                    if (message.parameters === $util.emptyObject)
                        message.parameters = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.parameters[key] = value;
                    break;
                case 1:
                    message.generateTextRequest = $root.api.GenerateTextRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.generateImageRequest = $root.api.GenerateImageRequest.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.timeout = reader.int64();
                    break;
                case 8:
                    message.priority = reader.int32();
                    break;
                case 9:
                    message.paidOnly = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateAIRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GenerateAIRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GenerateAIRequest} GenerateAIRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateAIRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateAIRequest message.
         * @function verify
         * @memberof api.GenerateAIRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateAIRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.numAnswers != null && message.hasOwnProperty("numAnswers"))
                if (!$util.isInteger(message.numAnswers))
                    return "numAnswers: integer expected";
            if (message.template != null && message.hasOwnProperty("template"))
                if (!$util.isString(message.template))
                    return "template: string expected";
            if (message.parameters != null && message.hasOwnProperty("parameters")) {
                if (!$util.isObject(message.parameters))
                    return "parameters: object expected";
                let key = Object.keys(message.parameters);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.parameters[key[i]]))
                        return "parameters: string{k:string} expected";
            }
            if (message.generateTextRequest != null && message.hasOwnProperty("generateTextRequest")) {
                properties.requestType = 1;
                {
                    let error = $root.api.GenerateTextRequest.verify(message.generateTextRequest);
                    if (error)
                        return "generateTextRequest." + error;
                }
            }
            if (message.generateImageRequest != null && message.hasOwnProperty("generateImageRequest")) {
                if (properties.requestType === 1)
                    return "requestType: multiple values";
                properties.requestType = 1;
                {
                    let error = $root.api.GenerateImageRequest.verify(message.generateImageRequest);
                    if (error)
                        return "generateImageRequest." + error;
                }
            }
            if (message.timeout != null && message.hasOwnProperty("timeout"))
                if (!$util.isInteger(message.timeout) && !(message.timeout && $util.isInteger(message.timeout.low) && $util.isInteger(message.timeout.high)))
                    return "timeout: integer|Long expected";
            if (message.priority != null && message.hasOwnProperty("priority"))
                if (!$util.isInteger(message.priority))
                    return "priority: integer expected";
            if (message.paidOnly != null && message.hasOwnProperty("paidOnly"))
                if (typeof message.paidOnly !== "boolean")
                    return "paidOnly: boolean expected";
            return null;
        };

        /**
         * Creates a GenerateAIRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GenerateAIRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GenerateAIRequest} GenerateAIRequest
         */
        GenerateAIRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GenerateAIRequest)
                return object;
            let message = new $root.api.GenerateAIRequest();
            if (object.numAnswers != null)
                message.numAnswers = object.numAnswers | 0;
            if (object.template != null)
                message.template = String(object.template);
            if (object.parameters) {
                if (typeof object.parameters !== "object")
                    throw TypeError(".api.GenerateAIRequest.parameters: object expected");
                message.parameters = {};
                for (let keys = Object.keys(object.parameters), i = 0; i < keys.length; ++i)
                    message.parameters[keys[i]] = String(object.parameters[keys[i]]);
            }
            if (object.generateTextRequest != null) {
                if (typeof object.generateTextRequest !== "object")
                    throw TypeError(".api.GenerateAIRequest.generateTextRequest: object expected");
                message.generateTextRequest = $root.api.GenerateTextRequest.fromObject(object.generateTextRequest);
            }
            if (object.generateImageRequest != null) {
                if (typeof object.generateImageRequest !== "object")
                    throw TypeError(".api.GenerateAIRequest.generateImageRequest: object expected");
                message.generateImageRequest = $root.api.GenerateImageRequest.fromObject(object.generateImageRequest);
            }
            if (object.timeout != null)
                if ($util.Long)
                    (message.timeout = $util.Long.fromValue(object.timeout)).unsigned = false;
                else if (typeof object.timeout === "string")
                    message.timeout = parseInt(object.timeout, 10);
                else if (typeof object.timeout === "number")
                    message.timeout = object.timeout;
                else if (typeof object.timeout === "object")
                    message.timeout = new $util.LongBits(object.timeout.low >>> 0, object.timeout.high >>> 0).toNumber();
            if (object.priority != null)
                message.priority = object.priority | 0;
            if (object.paidOnly != null)
                message.paidOnly = Boolean(object.paidOnly);
            return message;
        };

        /**
         * Creates a plain object from a GenerateAIRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GenerateAIRequest
         * @static
         * @param {api.GenerateAIRequest} message GenerateAIRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateAIRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.parameters = {};
            if (options.defaults) {
                object.numAnswers = 0;
                object.template = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timeout = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timeout = options.longs === String ? "0" : 0;
                object.priority = 0;
                object.paidOnly = false;
            }
            if (message.generateTextRequest != null && message.hasOwnProperty("generateTextRequest")) {
                object.generateTextRequest = $root.api.GenerateTextRequest.toObject(message.generateTextRequest, options);
                if (options.oneofs)
                    object.requestType = "generateTextRequest";
            }
            if (message.generateImageRequest != null && message.hasOwnProperty("generateImageRequest")) {
                object.generateImageRequest = $root.api.GenerateImageRequest.toObject(message.generateImageRequest, options);
                if (options.oneofs)
                    object.requestType = "generateImageRequest";
            }
            if (message.numAnswers != null && message.hasOwnProperty("numAnswers"))
                object.numAnswers = message.numAnswers;
            if (message.template != null && message.hasOwnProperty("template"))
                object.template = message.template;
            let keys2;
            if (message.parameters && (keys2 = Object.keys(message.parameters)).length) {
                object.parameters = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.parameters[keys2[j]] = message.parameters[keys2[j]];
            }
            if (message.timeout != null && message.hasOwnProperty("timeout"))
                if (typeof message.timeout === "number")
                    object.timeout = options.longs === String ? String(message.timeout) : message.timeout;
                else
                    object.timeout = options.longs === String ? $util.Long.prototype.toString.call(message.timeout) : options.longs === Number ? new $util.LongBits(message.timeout.low >>> 0, message.timeout.high >>> 0).toNumber() : message.timeout;
            if (message.priority != null && message.hasOwnProperty("priority"))
                object.priority = message.priority;
            if (message.paidOnly != null && message.hasOwnProperty("paidOnly"))
                object.paidOnly = message.paidOnly;
            return object;
        };

        /**
         * Converts this GenerateAIRequest to JSON.
         * @function toJSON
         * @memberof api.GenerateAIRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateAIRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenerateAIRequest;
    })();

    api.GetConversationsRequest = (function() {

        /**
         * Properties of a GetConversationsRequest.
         * @memberof api
         * @interface IGetConversationsRequest
         */

        /**
         * Constructs a new GetConversationsRequest.
         * @memberof api
         * @classdesc Represents a GetConversationsRequest.
         * @implements IGetConversationsRequest
         * @constructor
         * @param {api.IGetConversationsRequest=} [properties] Properties to set
         */
        function GetConversationsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetConversationsRequest instance using the specified properties.
         * @function create
         * @memberof api.GetConversationsRequest
         * @static
         * @param {api.IGetConversationsRequest=} [properties] Properties to set
         * @returns {api.GetConversationsRequest} GetConversationsRequest instance
         */
        GetConversationsRequest.create = function create(properties) {
            return new GetConversationsRequest(properties);
        };

        /**
         * Encodes the specified GetConversationsRequest message. Does not implicitly {@link api.GetConversationsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetConversationsRequest
         * @static
         * @param {api.IGetConversationsRequest} message GetConversationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConversationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetConversationsRequest message, length delimited. Does not implicitly {@link api.GetConversationsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetConversationsRequest
         * @static
         * @param {api.IGetConversationsRequest} message GetConversationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConversationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetConversationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetConversationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetConversationsRequest} GetConversationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConversationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetConversationsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetConversationsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetConversationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetConversationsRequest} GetConversationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConversationsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetConversationsRequest message.
         * @function verify
         * @memberof api.GetConversationsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetConversationsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetConversationsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetConversationsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetConversationsRequest} GetConversationsRequest
         */
        GetConversationsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetConversationsRequest)
                return object;
            return new $root.api.GetConversationsRequest();
        };

        /**
         * Creates a plain object from a GetConversationsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetConversationsRequest
         * @static
         * @param {api.GetConversationsRequest} message GetConversationsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConversationsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetConversationsRequest to JSON.
         * @function toJSON
         * @memberof api.GetConversationsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConversationsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetConversationsRequest;
    })();

    api.ConversationInfo = (function() {

        /**
         * Properties of a ConversationInfo.
         * @memberof api
         * @interface IConversationInfo
         * @property {string|null} [conversationId] ConversationInfo conversationId
         * @property {number|Long|null} [createDate] ConversationInfo createDate
         * @property {number|Long|null} [updateDate] ConversationInfo updateDate
         */

        /**
         * Constructs a new ConversationInfo.
         * @memberof api
         * @classdesc Represents a ConversationInfo.
         * @implements IConversationInfo
         * @constructor
         * @param {api.IConversationInfo=} [properties] Properties to set
         */
        function ConversationInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConversationInfo conversationId.
         * @member {string} conversationId
         * @memberof api.ConversationInfo
         * @instance
         */
        ConversationInfo.prototype.conversationId = "";

        /**
         * ConversationInfo createDate.
         * @member {number|Long} createDate
         * @memberof api.ConversationInfo
         * @instance
         */
        ConversationInfo.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ConversationInfo updateDate.
         * @member {number|Long} updateDate
         * @memberof api.ConversationInfo
         * @instance
         */
        ConversationInfo.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ConversationInfo instance using the specified properties.
         * @function create
         * @memberof api.ConversationInfo
         * @static
         * @param {api.IConversationInfo=} [properties] Properties to set
         * @returns {api.ConversationInfo} ConversationInfo instance
         */
        ConversationInfo.create = function create(properties) {
            return new ConversationInfo(properties);
        };

        /**
         * Encodes the specified ConversationInfo message. Does not implicitly {@link api.ConversationInfo.verify|verify} messages.
         * @function encode
         * @memberof api.ConversationInfo
         * @static
         * @param {api.IConversationInfo} message ConversationInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConversationInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.conversationId != null && Object.hasOwnProperty.call(message, "conversationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationId);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.updateDate);
            return writer;
        };

        /**
         * Encodes the specified ConversationInfo message, length delimited. Does not implicitly {@link api.ConversationInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ConversationInfo
         * @static
         * @param {api.IConversationInfo} message ConversationInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConversationInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConversationInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.ConversationInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ConversationInfo} ConversationInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConversationInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ConversationInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.conversationId = reader.string();
                    break;
                case 2:
                    message.createDate = reader.int64();
                    break;
                case 3:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConversationInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ConversationInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ConversationInfo} ConversationInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConversationInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConversationInfo message.
         * @function verify
         * @memberof api.ConversationInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConversationInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                if (!$util.isString(message.conversationId))
                    return "conversationId: string expected";
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a ConversationInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ConversationInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ConversationInfo} ConversationInfo
         */
        ConversationInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ConversationInfo)
                return object;
            let message = new $root.api.ConversationInfo();
            if (object.conversationId != null)
                message.conversationId = String(object.conversationId);
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a ConversationInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ConversationInfo
         * @static
         * @param {api.ConversationInfo} message ConversationInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConversationInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.conversationId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
            }
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                object.conversationId = message.conversationId;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            return object;
        };

        /**
         * Converts this ConversationInfo to JSON.
         * @function toJSON
         * @memberof api.ConversationInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConversationInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConversationInfo;
    })();

    api.GetConversationsResponse = (function() {

        /**
         * Properties of a GetConversationsResponse.
         * @memberof api
         * @interface IGetConversationsResponse
         * @property {Array.<api.IConversationInfo>|null} [conversations] GetConversationsResponse conversations
         */

        /**
         * Constructs a new GetConversationsResponse.
         * @memberof api
         * @classdesc Represents a GetConversationsResponse.
         * @implements IGetConversationsResponse
         * @constructor
         * @param {api.IGetConversationsResponse=} [properties] Properties to set
         */
        function GetConversationsResponse(properties) {
            this.conversations = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetConversationsResponse conversations.
         * @member {Array.<api.IConversationInfo>} conversations
         * @memberof api.GetConversationsResponse
         * @instance
         */
        GetConversationsResponse.prototype.conversations = $util.emptyArray;

        /**
         * Creates a new GetConversationsResponse instance using the specified properties.
         * @function create
         * @memberof api.GetConversationsResponse
         * @static
         * @param {api.IGetConversationsResponse=} [properties] Properties to set
         * @returns {api.GetConversationsResponse} GetConversationsResponse instance
         */
        GetConversationsResponse.create = function create(properties) {
            return new GetConversationsResponse(properties);
        };

        /**
         * Encodes the specified GetConversationsResponse message. Does not implicitly {@link api.GetConversationsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetConversationsResponse
         * @static
         * @param {api.IGetConversationsResponse} message GetConversationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConversationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.conversations != null && message.conversations.length)
                for (let i = 0; i < message.conversations.length; ++i)
                    $root.api.ConversationInfo.encode(message.conversations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetConversationsResponse message, length delimited. Does not implicitly {@link api.GetConversationsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetConversationsResponse
         * @static
         * @param {api.IGetConversationsResponse} message GetConversationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConversationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetConversationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetConversationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetConversationsResponse} GetConversationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConversationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetConversationsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.conversations && message.conversations.length))
                        message.conversations = [];
                    message.conversations.push($root.api.ConversationInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetConversationsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetConversationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetConversationsResponse} GetConversationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConversationsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetConversationsResponse message.
         * @function verify
         * @memberof api.GetConversationsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetConversationsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.conversations != null && message.hasOwnProperty("conversations")) {
                if (!Array.isArray(message.conversations))
                    return "conversations: array expected";
                for (let i = 0; i < message.conversations.length; ++i) {
                    let error = $root.api.ConversationInfo.verify(message.conversations[i]);
                    if (error)
                        return "conversations." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetConversationsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetConversationsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetConversationsResponse} GetConversationsResponse
         */
        GetConversationsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetConversationsResponse)
                return object;
            let message = new $root.api.GetConversationsResponse();
            if (object.conversations) {
                if (!Array.isArray(object.conversations))
                    throw TypeError(".api.GetConversationsResponse.conversations: array expected");
                message.conversations = [];
                for (let i = 0; i < object.conversations.length; ++i) {
                    if (typeof object.conversations[i] !== "object")
                        throw TypeError(".api.GetConversationsResponse.conversations: object expected");
                    message.conversations[i] = $root.api.ConversationInfo.fromObject(object.conversations[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetConversationsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetConversationsResponse
         * @static
         * @param {api.GetConversationsResponse} message GetConversationsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConversationsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.conversations = [];
            if (message.conversations && message.conversations.length) {
                object.conversations = [];
                for (let j = 0; j < message.conversations.length; ++j)
                    object.conversations[j] = $root.api.ConversationInfo.toObject(message.conversations[j], options);
            }
            return object;
        };

        /**
         * Converts this GetConversationsResponse to JSON.
         * @function toJSON
         * @memberof api.GetConversationsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConversationsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetConversationsResponse;
    })();

    api.GetMessagesRequest = (function() {

        /**
         * Properties of a GetMessagesRequest.
         * @memberof api
         * @interface IGetMessagesRequest
         * @property {string|null} [conversationId] GetMessagesRequest conversationId
         */

        /**
         * Constructs a new GetMessagesRequest.
         * @memberof api
         * @classdesc Represents a GetMessagesRequest.
         * @implements IGetMessagesRequest
         * @constructor
         * @param {api.IGetMessagesRequest=} [properties] Properties to set
         */
        function GetMessagesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMessagesRequest conversationId.
         * @member {string} conversationId
         * @memberof api.GetMessagesRequest
         * @instance
         */
        GetMessagesRequest.prototype.conversationId = "";

        /**
         * Creates a new GetMessagesRequest instance using the specified properties.
         * @function create
         * @memberof api.GetMessagesRequest
         * @static
         * @param {api.IGetMessagesRequest=} [properties] Properties to set
         * @returns {api.GetMessagesRequest} GetMessagesRequest instance
         */
        GetMessagesRequest.create = function create(properties) {
            return new GetMessagesRequest(properties);
        };

        /**
         * Encodes the specified GetMessagesRequest message. Does not implicitly {@link api.GetMessagesRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetMessagesRequest
         * @static
         * @param {api.IGetMessagesRequest} message GetMessagesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMessagesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.conversationId != null && Object.hasOwnProperty.call(message, "conversationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationId);
            return writer;
        };

        /**
         * Encodes the specified GetMessagesRequest message, length delimited. Does not implicitly {@link api.GetMessagesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetMessagesRequest
         * @static
         * @param {api.IGetMessagesRequest} message GetMessagesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMessagesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMessagesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetMessagesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetMessagesRequest} GetMessagesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMessagesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetMessagesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.conversationId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMessagesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetMessagesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetMessagesRequest} GetMessagesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMessagesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMessagesRequest message.
         * @function verify
         * @memberof api.GetMessagesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMessagesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                if (!$util.isString(message.conversationId))
                    return "conversationId: string expected";
            return null;
        };

        /**
         * Creates a GetMessagesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetMessagesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetMessagesRequest} GetMessagesRequest
         */
        GetMessagesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetMessagesRequest)
                return object;
            let message = new $root.api.GetMessagesRequest();
            if (object.conversationId != null)
                message.conversationId = String(object.conversationId);
            return message;
        };

        /**
         * Creates a plain object from a GetMessagesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetMessagesRequest
         * @static
         * @param {api.GetMessagesRequest} message GetMessagesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMessagesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.conversationId = "";
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                object.conversationId = message.conversationId;
            return object;
        };

        /**
         * Converts this GetMessagesRequest to JSON.
         * @function toJSON
         * @memberof api.GetMessagesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMessagesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMessagesRequest;
    })();

    /**
     * MessageSource enum.
     * @name api.MessageSource
     * @enum {number}
     * @property {number} UserMessage=0 UserMessage value
     * @property {number} AIMessage=1 AIMessage value
     */
    api.MessageSource = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UserMessage"] = 0;
        values[valuesById[1] = "AIMessage"] = 1;
        return values;
    })();

    /**
     * MessageDataType enum.
     * @name api.MessageDataType
     * @enum {number}
     * @property {number} text=0 text value
     */
    api.MessageDataType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "text"] = 0;
        return values;
    })();

    api.MessageInfo = (function() {

        /**
         * Properties of a MessageInfo.
         * @memberof api
         * @interface IMessageInfo
         * @property {string|null} [conversationId] MessageInfo conversationId
         * @property {string|null} [messageId] MessageInfo messageId
         * @property {api.MessageSource|null} [source] MessageInfo source
         * @property {api.MessageDataType|null} [dataType] MessageInfo dataType
         * @property {string|null} [message] MessageInfo message
         * @property {number|Long|null} [createDate] MessageInfo createDate
         * @property {number|Long|null} [updateDate] MessageInfo updateDate
         */

        /**
         * Constructs a new MessageInfo.
         * @memberof api
         * @classdesc Represents a MessageInfo.
         * @implements IMessageInfo
         * @constructor
         * @param {api.IMessageInfo=} [properties] Properties to set
         */
        function MessageInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageInfo conversationId.
         * @member {string} conversationId
         * @memberof api.MessageInfo
         * @instance
         */
        MessageInfo.prototype.conversationId = "";

        /**
         * MessageInfo messageId.
         * @member {string} messageId
         * @memberof api.MessageInfo
         * @instance
         */
        MessageInfo.prototype.messageId = "";

        /**
         * MessageInfo source.
         * @member {api.MessageSource} source
         * @memberof api.MessageInfo
         * @instance
         */
        MessageInfo.prototype.source = 0;

        /**
         * MessageInfo dataType.
         * @member {api.MessageDataType} dataType
         * @memberof api.MessageInfo
         * @instance
         */
        MessageInfo.prototype.dataType = 0;

        /**
         * MessageInfo message.
         * @member {string} message
         * @memberof api.MessageInfo
         * @instance
         */
        MessageInfo.prototype.message = "";

        /**
         * MessageInfo createDate.
         * @member {number|Long} createDate
         * @memberof api.MessageInfo
         * @instance
         */
        MessageInfo.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MessageInfo updateDate.
         * @member {number|Long} updateDate
         * @memberof api.MessageInfo
         * @instance
         */
        MessageInfo.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new MessageInfo instance using the specified properties.
         * @function create
         * @memberof api.MessageInfo
         * @static
         * @param {api.IMessageInfo=} [properties] Properties to set
         * @returns {api.MessageInfo} MessageInfo instance
         */
        MessageInfo.create = function create(properties) {
            return new MessageInfo(properties);
        };

        /**
         * Encodes the specified MessageInfo message. Does not implicitly {@link api.MessageInfo.verify|verify} messages.
         * @function encode
         * @memberof api.MessageInfo
         * @static
         * @param {api.IMessageInfo} message MessageInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.conversationId != null && Object.hasOwnProperty.call(message, "conversationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationId);
            if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.messageId);
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.source);
            if (message.dataType != null && Object.hasOwnProperty.call(message, "dataType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dataType);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.message);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.updateDate);
            return writer;
        };

        /**
         * Encodes the specified MessageInfo message, length delimited. Does not implicitly {@link api.MessageInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MessageInfo
         * @static
         * @param {api.IMessageInfo} message MessageInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MessageInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.MessageInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MessageInfo} MessageInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MessageInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.conversationId = reader.string();
                    break;
                case 2:
                    message.messageId = reader.string();
                    break;
                case 3:
                    message.source = reader.int32();
                    break;
                case 4:
                    message.dataType = reader.int32();
                    break;
                case 5:
                    message.message = reader.string();
                    break;
                case 6:
                    message.createDate = reader.int64();
                    break;
                case 7:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MessageInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MessageInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MessageInfo} MessageInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MessageInfo message.
         * @function verify
         * @memberof api.MessageInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MessageInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                if (!$util.isString(message.conversationId))
                    return "conversationId: string expected";
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (!$util.isString(message.messageId))
                    return "messageId: string expected";
            if (message.source != null && message.hasOwnProperty("source"))
                switch (message.source) {
                default:
                    return "source: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.dataType != null && message.hasOwnProperty("dataType"))
                switch (message.dataType) {
                default:
                    return "dataType: enum value expected";
                case 0:
                    break;
                }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a MessageInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MessageInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MessageInfo} MessageInfo
         */
        MessageInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MessageInfo)
                return object;
            let message = new $root.api.MessageInfo();
            if (object.conversationId != null)
                message.conversationId = String(object.conversationId);
            if (object.messageId != null)
                message.messageId = String(object.messageId);
            switch (object.source) {
            case "UserMessage":
            case 0:
                message.source = 0;
                break;
            case "AIMessage":
            case 1:
                message.source = 1;
                break;
            }
            switch (object.dataType) {
            case "text":
            case 0:
                message.dataType = 0;
                break;
            }
            if (object.message != null)
                message.message = String(object.message);
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a MessageInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MessageInfo
         * @static
         * @param {api.MessageInfo} message MessageInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MessageInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.conversationId = "";
                object.messageId = "";
                object.source = options.enums === String ? "UserMessage" : 0;
                object.dataType = options.enums === String ? "text" : 0;
                object.message = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
            }
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                object.conversationId = message.conversationId;
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                object.messageId = message.messageId;
            if (message.source != null && message.hasOwnProperty("source"))
                object.source = options.enums === String ? $root.api.MessageSource[message.source] : message.source;
            if (message.dataType != null && message.hasOwnProperty("dataType"))
                object.dataType = options.enums === String ? $root.api.MessageDataType[message.dataType] : message.dataType;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            return object;
        };

        /**
         * Converts this MessageInfo to JSON.
         * @function toJSON
         * @memberof api.MessageInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MessageInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MessageInfo;
    })();

    api.GetMessagesResponse = (function() {

        /**
         * Properties of a GetMessagesResponse.
         * @memberof api
         * @interface IGetMessagesResponse
         * @property {Array.<api.IMessageInfo>|null} [messages] GetMessagesResponse messages
         */

        /**
         * Constructs a new GetMessagesResponse.
         * @memberof api
         * @classdesc Represents a GetMessagesResponse.
         * @implements IGetMessagesResponse
         * @constructor
         * @param {api.IGetMessagesResponse=} [properties] Properties to set
         */
        function GetMessagesResponse(properties) {
            this.messages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMessagesResponse messages.
         * @member {Array.<api.IMessageInfo>} messages
         * @memberof api.GetMessagesResponse
         * @instance
         */
        GetMessagesResponse.prototype.messages = $util.emptyArray;

        /**
         * Creates a new GetMessagesResponse instance using the specified properties.
         * @function create
         * @memberof api.GetMessagesResponse
         * @static
         * @param {api.IGetMessagesResponse=} [properties] Properties to set
         * @returns {api.GetMessagesResponse} GetMessagesResponse instance
         */
        GetMessagesResponse.create = function create(properties) {
            return new GetMessagesResponse(properties);
        };

        /**
         * Encodes the specified GetMessagesResponse message. Does not implicitly {@link api.GetMessagesResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetMessagesResponse
         * @static
         * @param {api.IGetMessagesResponse} message GetMessagesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMessagesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.messages != null && message.messages.length)
                for (let i = 0; i < message.messages.length; ++i)
                    $root.api.MessageInfo.encode(message.messages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetMessagesResponse message, length delimited. Does not implicitly {@link api.GetMessagesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetMessagesResponse
         * @static
         * @param {api.IGetMessagesResponse} message GetMessagesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMessagesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMessagesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetMessagesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetMessagesResponse} GetMessagesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMessagesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetMessagesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    if (!(message.messages && message.messages.length))
                        message.messages = [];
                    message.messages.push($root.api.MessageInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMessagesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetMessagesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetMessagesResponse} GetMessagesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMessagesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMessagesResponse message.
         * @function verify
         * @memberof api.GetMessagesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMessagesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.messages != null && message.hasOwnProperty("messages")) {
                if (!Array.isArray(message.messages))
                    return "messages: array expected";
                for (let i = 0; i < message.messages.length; ++i) {
                    let error = $root.api.MessageInfo.verify(message.messages[i]);
                    if (error)
                        return "messages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetMessagesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetMessagesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetMessagesResponse} GetMessagesResponse
         */
        GetMessagesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetMessagesResponse)
                return object;
            let message = new $root.api.GetMessagesResponse();
            if (object.messages) {
                if (!Array.isArray(object.messages))
                    throw TypeError(".api.GetMessagesResponse.messages: array expected");
                message.messages = [];
                for (let i = 0; i < object.messages.length; ++i) {
                    if (typeof object.messages[i] !== "object")
                        throw TypeError(".api.GetMessagesResponse.messages: object expected");
                    message.messages[i] = $root.api.MessageInfo.fromObject(object.messages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMessagesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetMessagesResponse
         * @static
         * @param {api.GetMessagesResponse} message GetMessagesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMessagesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.messages = [];
            if (message.messages && message.messages.length) {
                object.messages = [];
                for (let j = 0; j < message.messages.length; ++j)
                    object.messages[j] = $root.api.MessageInfo.toObject(message.messages[j], options);
            }
            return object;
        };

        /**
         * Converts this GetMessagesResponse to JSON.
         * @function toJSON
         * @memberof api.GetMessagesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMessagesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMessagesResponse;
    })();

    api.UpdateMessage = (function() {

        /**
         * Properties of an UpdateMessage.
         * @memberof api
         * @interface IUpdateMessage
         * @property {boolean|null} [message] UpdateMessage message
         */

        /**
         * Constructs a new UpdateMessage.
         * @memberof api
         * @classdesc Represents an UpdateMessage.
         * @implements IUpdateMessage
         * @constructor
         * @param {api.IUpdateMessage=} [properties] Properties to set
         */
        function UpdateMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateMessage message.
         * @member {boolean} message
         * @memberof api.UpdateMessage
         * @instance
         */
        UpdateMessage.prototype.message = false;

        /**
         * Creates a new UpdateMessage instance using the specified properties.
         * @function create
         * @memberof api.UpdateMessage
         * @static
         * @param {api.IUpdateMessage=} [properties] Properties to set
         * @returns {api.UpdateMessage} UpdateMessage instance
         */
        UpdateMessage.create = function create(properties) {
            return new UpdateMessage(properties);
        };

        /**
         * Encodes the specified UpdateMessage message. Does not implicitly {@link api.UpdateMessage.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateMessage
         * @static
         * @param {api.IUpdateMessage} message UpdateMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.message);
            return writer;
        };

        /**
         * Encodes the specified UpdateMessage message, length delimited. Does not implicitly {@link api.UpdateMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateMessage
         * @static
         * @param {api.IUpdateMessage} message UpdateMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateMessage message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateMessage} UpdateMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateMessage} UpdateMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateMessage message.
         * @function verify
         * @memberof api.UpdateMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (typeof message.message !== "boolean")
                    return "message: boolean expected";
            return null;
        };

        /**
         * Creates an UpdateMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateMessage} UpdateMessage
         */
        UpdateMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateMessage)
                return object;
            let message = new $root.api.UpdateMessage();
            if (object.message != null)
                message.message = Boolean(object.message);
            return message;
        };

        /**
         * Creates a plain object from an UpdateMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateMessage
         * @static
         * @param {api.UpdateMessage} message UpdateMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.message = false;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this UpdateMessage to JSON.
         * @function toJSON
         * @memberof api.UpdateMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateMessage;
    })();

    api.UpdateMessageRequest = (function() {

        /**
         * Properties of an UpdateMessageRequest.
         * @memberof api
         * @interface IUpdateMessageRequest
         * @property {api.IUpdateMessage|null} [update] UpdateMessageRequest update
         * @property {api.IMessageInfo|null} [message] UpdateMessageRequest message
         */

        /**
         * Constructs a new UpdateMessageRequest.
         * @memberof api
         * @classdesc Represents an UpdateMessageRequest.
         * @implements IUpdateMessageRequest
         * @constructor
         * @param {api.IUpdateMessageRequest=} [properties] Properties to set
         */
        function UpdateMessageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateMessageRequest update.
         * @member {api.IUpdateMessage|null|undefined} update
         * @memberof api.UpdateMessageRequest
         * @instance
         */
        UpdateMessageRequest.prototype.update = null;

        /**
         * UpdateMessageRequest message.
         * @member {api.IMessageInfo|null|undefined} message
         * @memberof api.UpdateMessageRequest
         * @instance
         */
        UpdateMessageRequest.prototype.message = null;

        /**
         * Creates a new UpdateMessageRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateMessageRequest
         * @static
         * @param {api.IUpdateMessageRequest=} [properties] Properties to set
         * @returns {api.UpdateMessageRequest} UpdateMessageRequest instance
         */
        UpdateMessageRequest.create = function create(properties) {
            return new UpdateMessageRequest(properties);
        };

        /**
         * Encodes the specified UpdateMessageRequest message. Does not implicitly {@link api.UpdateMessageRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateMessageRequest
         * @static
         * @param {api.IUpdateMessageRequest} message UpdateMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMessageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.UpdateMessage.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.api.MessageInfo.encode(message.message, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateMessageRequest message, length delimited. Does not implicitly {@link api.UpdateMessageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateMessageRequest
         * @static
         * @param {api.IUpdateMessageRequest} message UpdateMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateMessageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateMessageRequest} UpdateMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMessageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateMessageRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.UpdateMessage.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.message = $root.api.MessageInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateMessageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateMessageRequest} UpdateMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMessageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateMessageRequest message.
         * @function verify
         * @memberof api.UpdateMessageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateMessageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.UpdateMessage.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.message != null && message.hasOwnProperty("message")) {
                let error = $root.api.MessageInfo.verify(message.message);
                if (error)
                    return "message." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateMessageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateMessageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateMessageRequest} UpdateMessageRequest
         */
        UpdateMessageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateMessageRequest)
                return object;
            let message = new $root.api.UpdateMessageRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateMessageRequest.update: object expected");
                message.update = $root.api.UpdateMessage.fromObject(object.update);
            }
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".api.UpdateMessageRequest.message: object expected");
                message.message = $root.api.MessageInfo.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateMessageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateMessageRequest
         * @static
         * @param {api.UpdateMessageRequest} message UpdateMessageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateMessageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.message = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.UpdateMessage.toObject(message.update, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.api.MessageInfo.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this UpdateMessageRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateMessageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateMessageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateMessageRequest;
    })();

    api.UpdateMessageResponse = (function() {

        /**
         * Properties of an UpdateMessageResponse.
         * @memberof api
         * @interface IUpdateMessageResponse
         */

        /**
         * Constructs a new UpdateMessageResponse.
         * @memberof api
         * @classdesc Represents an UpdateMessageResponse.
         * @implements IUpdateMessageResponse
         * @constructor
         * @param {api.IUpdateMessageResponse=} [properties] Properties to set
         */
        function UpdateMessageResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateMessageResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateMessageResponse
         * @static
         * @param {api.IUpdateMessageResponse=} [properties] Properties to set
         * @returns {api.UpdateMessageResponse} UpdateMessageResponse instance
         */
        UpdateMessageResponse.create = function create(properties) {
            return new UpdateMessageResponse(properties);
        };

        /**
         * Encodes the specified UpdateMessageResponse message. Does not implicitly {@link api.UpdateMessageResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateMessageResponse
         * @static
         * @param {api.IUpdateMessageResponse} message UpdateMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMessageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateMessageResponse message, length delimited. Does not implicitly {@link api.UpdateMessageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateMessageResponse
         * @static
         * @param {api.IUpdateMessageResponse} message UpdateMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateMessageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateMessageResponse} UpdateMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMessageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateMessageResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateMessageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateMessageResponse} UpdateMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMessageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateMessageResponse message.
         * @function verify
         * @memberof api.UpdateMessageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateMessageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateMessageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateMessageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateMessageResponse} UpdateMessageResponse
         */
        UpdateMessageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateMessageResponse)
                return object;
            return new $root.api.UpdateMessageResponse();
        };

        /**
         * Creates a plain object from an UpdateMessageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateMessageResponse
         * @static
         * @param {api.UpdateMessageResponse} message UpdateMessageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateMessageResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateMessageResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateMessageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateMessageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateMessageResponse;
    })();

    api.JobCreatedResponse = (function() {

        /**
         * Properties of a JobCreatedResponse.
         * @memberof api
         * @interface IJobCreatedResponse
         * @property {string|null} [jobId] JobCreatedResponse jobId
         */

        /**
         * Constructs a new JobCreatedResponse.
         * @memberof api
         * @classdesc Represents a JobCreatedResponse.
         * @implements IJobCreatedResponse
         * @constructor
         * @param {api.IJobCreatedResponse=} [properties] Properties to set
         */
        function JobCreatedResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JobCreatedResponse jobId.
         * @member {string} jobId
         * @memberof api.JobCreatedResponse
         * @instance
         */
        JobCreatedResponse.prototype.jobId = "";

        /**
         * Creates a new JobCreatedResponse instance using the specified properties.
         * @function create
         * @memberof api.JobCreatedResponse
         * @static
         * @param {api.IJobCreatedResponse=} [properties] Properties to set
         * @returns {api.JobCreatedResponse} JobCreatedResponse instance
         */
        JobCreatedResponse.create = function create(properties) {
            return new JobCreatedResponse(properties);
        };

        /**
         * Encodes the specified JobCreatedResponse message. Does not implicitly {@link api.JobCreatedResponse.verify|verify} messages.
         * @function encode
         * @memberof api.JobCreatedResponse
         * @static
         * @param {api.IJobCreatedResponse} message JobCreatedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobCreatedResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.jobId);
            return writer;
        };

        /**
         * Encodes the specified JobCreatedResponse message, length delimited. Does not implicitly {@link api.JobCreatedResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.JobCreatedResponse
         * @static
         * @param {api.IJobCreatedResponse} message JobCreatedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobCreatedResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobCreatedResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.JobCreatedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.JobCreatedResponse} JobCreatedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobCreatedResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.JobCreatedResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.jobId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JobCreatedResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.JobCreatedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.JobCreatedResponse} JobCreatedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobCreatedResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JobCreatedResponse message.
         * @function verify
         * @memberof api.JobCreatedResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobCreatedResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                if (!$util.isString(message.jobId))
                    return "jobId: string expected";
            return null;
        };

        /**
         * Creates a JobCreatedResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.JobCreatedResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.JobCreatedResponse} JobCreatedResponse
         */
        JobCreatedResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.JobCreatedResponse)
                return object;
            let message = new $root.api.JobCreatedResponse();
            if (object.jobId != null)
                message.jobId = String(object.jobId);
            return message;
        };

        /**
         * Creates a plain object from a JobCreatedResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.JobCreatedResponse
         * @static
         * @param {api.JobCreatedResponse} message JobCreatedResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobCreatedResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.jobId = "";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = message.jobId;
            return object;
        };

        /**
         * Converts this JobCreatedResponse to JSON.
         * @function toJSON
         * @memberof api.JobCreatedResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobCreatedResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return JobCreatedResponse;
    })();

    api.JobStatusResponse = (function() {

        /**
         * Properties of a JobStatusResponse.
         * @memberof api
         * @interface IJobStatusResponse
         * @property {string|null} [status] JobStatusResponse status
         * @property {string|null} [description] JobStatusResponse description
         */

        /**
         * Constructs a new JobStatusResponse.
         * @memberof api
         * @classdesc Represents a JobStatusResponse.
         * @implements IJobStatusResponse
         * @constructor
         * @param {api.IJobStatusResponse=} [properties] Properties to set
         */
        function JobStatusResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JobStatusResponse status.
         * @member {string} status
         * @memberof api.JobStatusResponse
         * @instance
         */
        JobStatusResponse.prototype.status = "";

        /**
         * JobStatusResponse description.
         * @member {string} description
         * @memberof api.JobStatusResponse
         * @instance
         */
        JobStatusResponse.prototype.description = "";

        /**
         * Creates a new JobStatusResponse instance using the specified properties.
         * @function create
         * @memberof api.JobStatusResponse
         * @static
         * @param {api.IJobStatusResponse=} [properties] Properties to set
         * @returns {api.JobStatusResponse} JobStatusResponse instance
         */
        JobStatusResponse.create = function create(properties) {
            return new JobStatusResponse(properties);
        };

        /**
         * Encodes the specified JobStatusResponse message. Does not implicitly {@link api.JobStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof api.JobStatusResponse
         * @static
         * @param {api.IJobStatusResponse} message JobStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified JobStatusResponse message, length delimited. Does not implicitly {@link api.JobStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.JobStatusResponse
         * @static
         * @param {api.IJobStatusResponse} message JobStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.JobStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.JobStatusResponse} JobStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.JobStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.string();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JobStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.JobStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.JobStatusResponse} JobStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JobStatusResponse message.
         * @function verify
         * @memberof api.JobStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a JobStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.JobStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.JobStatusResponse} JobStatusResponse
         */
        JobStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.JobStatusResponse)
                return object;
            let message = new $root.api.JobStatusResponse();
            if (object.status != null)
                message.status = String(object.status);
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a JobStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.JobStatusResponse
         * @static
         * @param {api.JobStatusResponse} message JobStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = "";
                object.description = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this JobStatusResponse to JSON.
         * @function toJSON
         * @memberof api.JobStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return JobStatusResponse;
    })();

    api.JobUpdateResponse = (function() {

        /**
         * Properties of a JobUpdateResponse.
         * @memberof api
         * @interface IJobUpdateResponse
         * @property {api.IMessageInfo|null} [newMessage] JobUpdateResponse newMessage
         * @property {api.IMessageInfo|null} [partialMessage] JobUpdateResponse partialMessage
         */

        /**
         * Constructs a new JobUpdateResponse.
         * @memberof api
         * @classdesc Represents a JobUpdateResponse.
         * @implements IJobUpdateResponse
         * @constructor
         * @param {api.IJobUpdateResponse=} [properties] Properties to set
         */
        function JobUpdateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JobUpdateResponse newMessage.
         * @member {api.IMessageInfo|null|undefined} newMessage
         * @memberof api.JobUpdateResponse
         * @instance
         */
        JobUpdateResponse.prototype.newMessage = null;

        /**
         * JobUpdateResponse partialMessage.
         * @member {api.IMessageInfo|null|undefined} partialMessage
         * @memberof api.JobUpdateResponse
         * @instance
         */
        JobUpdateResponse.prototype.partialMessage = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * JobUpdateResponse update.
         * @member {"newMessage"|"partialMessage"|undefined} update
         * @memberof api.JobUpdateResponse
         * @instance
         */
        Object.defineProperty(JobUpdateResponse.prototype, "update", {
            get: $util.oneOfGetter($oneOfFields = ["newMessage", "partialMessage"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new JobUpdateResponse instance using the specified properties.
         * @function create
         * @memberof api.JobUpdateResponse
         * @static
         * @param {api.IJobUpdateResponse=} [properties] Properties to set
         * @returns {api.JobUpdateResponse} JobUpdateResponse instance
         */
        JobUpdateResponse.create = function create(properties) {
            return new JobUpdateResponse(properties);
        };

        /**
         * Encodes the specified JobUpdateResponse message. Does not implicitly {@link api.JobUpdateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.JobUpdateResponse
         * @static
         * @param {api.IJobUpdateResponse} message JobUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobUpdateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.newMessage != null && Object.hasOwnProperty.call(message, "newMessage"))
                $root.api.MessageInfo.encode(message.newMessage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.partialMessage != null && Object.hasOwnProperty.call(message, "partialMessage"))
                $root.api.MessageInfo.encode(message.partialMessage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified JobUpdateResponse message, length delimited. Does not implicitly {@link api.JobUpdateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.JobUpdateResponse
         * @static
         * @param {api.IJobUpdateResponse} message JobUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobUpdateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.JobUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.JobUpdateResponse} JobUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobUpdateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.JobUpdateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.newMessage = $root.api.MessageInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.partialMessage = $root.api.MessageInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JobUpdateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.JobUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.JobUpdateResponse} JobUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JobUpdateResponse message.
         * @function verify
         * @memberof api.JobUpdateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobUpdateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.newMessage != null && message.hasOwnProperty("newMessage")) {
                properties.update = 1;
                {
                    let error = $root.api.MessageInfo.verify(message.newMessage);
                    if (error)
                        return "newMessage." + error;
                }
            }
            if (message.partialMessage != null && message.hasOwnProperty("partialMessage")) {
                if (properties.update === 1)
                    return "update: multiple values";
                properties.update = 1;
                {
                    let error = $root.api.MessageInfo.verify(message.partialMessage);
                    if (error)
                        return "partialMessage." + error;
                }
            }
            return null;
        };

        /**
         * Creates a JobUpdateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.JobUpdateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.JobUpdateResponse} JobUpdateResponse
         */
        JobUpdateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.JobUpdateResponse)
                return object;
            let message = new $root.api.JobUpdateResponse();
            if (object.newMessage != null) {
                if (typeof object.newMessage !== "object")
                    throw TypeError(".api.JobUpdateResponse.newMessage: object expected");
                message.newMessage = $root.api.MessageInfo.fromObject(object.newMessage);
            }
            if (object.partialMessage != null) {
                if (typeof object.partialMessage !== "object")
                    throw TypeError(".api.JobUpdateResponse.partialMessage: object expected");
                message.partialMessage = $root.api.MessageInfo.fromObject(object.partialMessage);
            }
            return message;
        };

        /**
         * Creates a plain object from a JobUpdateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.JobUpdateResponse
         * @static
         * @param {api.JobUpdateResponse} message JobUpdateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobUpdateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.newMessage != null && message.hasOwnProperty("newMessage")) {
                object.newMessage = $root.api.MessageInfo.toObject(message.newMessage, options);
                if (options.oneofs)
                    object.update = "newMessage";
            }
            if (message.partialMessage != null && message.hasOwnProperty("partialMessage")) {
                object.partialMessage = $root.api.MessageInfo.toObject(message.partialMessage, options);
                if (options.oneofs)
                    object.update = "partialMessage";
            }
            return object;
        };

        /**
         * Converts this JobUpdateResponse to JSON.
         * @function toJSON
         * @memberof api.JobUpdateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobUpdateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return JobUpdateResponse;
    })();

    api.GetJobEventsRequest = (function() {

        /**
         * Properties of a GetJobEventsRequest.
         * @memberof api
         * @interface IGetJobEventsRequest
         * @property {string|null} [jobId] GetJobEventsRequest jobId
         */

        /**
         * Constructs a new GetJobEventsRequest.
         * @memberof api
         * @classdesc Represents a GetJobEventsRequest.
         * @implements IGetJobEventsRequest
         * @constructor
         * @param {api.IGetJobEventsRequest=} [properties] Properties to set
         */
        function GetJobEventsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetJobEventsRequest jobId.
         * @member {string} jobId
         * @memberof api.GetJobEventsRequest
         * @instance
         */
        GetJobEventsRequest.prototype.jobId = "";

        /**
         * Creates a new GetJobEventsRequest instance using the specified properties.
         * @function create
         * @memberof api.GetJobEventsRequest
         * @static
         * @param {api.IGetJobEventsRequest=} [properties] Properties to set
         * @returns {api.GetJobEventsRequest} GetJobEventsRequest instance
         */
        GetJobEventsRequest.create = function create(properties) {
            return new GetJobEventsRequest(properties);
        };

        /**
         * Encodes the specified GetJobEventsRequest message. Does not implicitly {@link api.GetJobEventsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetJobEventsRequest
         * @static
         * @param {api.IGetJobEventsRequest} message GetJobEventsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetJobEventsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.jobId);
            return writer;
        };

        /**
         * Encodes the specified GetJobEventsRequest message, length delimited. Does not implicitly {@link api.GetJobEventsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetJobEventsRequest
         * @static
         * @param {api.IGetJobEventsRequest} message GetJobEventsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetJobEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetJobEventsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetJobEventsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetJobEventsRequest} GetJobEventsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetJobEventsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetJobEventsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.jobId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetJobEventsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetJobEventsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetJobEventsRequest} GetJobEventsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetJobEventsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetJobEventsRequest message.
         * @function verify
         * @memberof api.GetJobEventsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetJobEventsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                if (!$util.isString(message.jobId))
                    return "jobId: string expected";
            return null;
        };

        /**
         * Creates a GetJobEventsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetJobEventsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetJobEventsRequest} GetJobEventsRequest
         */
        GetJobEventsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetJobEventsRequest)
                return object;
            let message = new $root.api.GetJobEventsRequest();
            if (object.jobId != null)
                message.jobId = String(object.jobId);
            return message;
        };

        /**
         * Creates a plain object from a GetJobEventsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetJobEventsRequest
         * @static
         * @param {api.GetJobEventsRequest} message GetJobEventsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetJobEventsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.jobId = "";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = message.jobId;
            return object;
        };

        /**
         * Converts this GetJobEventsRequest to JSON.
         * @function toJSON
         * @memberof api.GetJobEventsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetJobEventsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetJobEventsRequest;
    })();

    api.GetSiteEmbeddingRequest = (function() {

        /**
         * Properties of a GetSiteEmbeddingRequest.
         * @memberof api
         * @interface IGetSiteEmbeddingRequest
         * @property {string|null} [url] GetSiteEmbeddingRequest url
         */

        /**
         * Constructs a new GetSiteEmbeddingRequest.
         * @memberof api
         * @classdesc Represents a GetSiteEmbeddingRequest.
         * @implements IGetSiteEmbeddingRequest
         * @constructor
         * @param {api.IGetSiteEmbeddingRequest=} [properties] Properties to set
         */
        function GetSiteEmbeddingRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetSiteEmbeddingRequest url.
         * @member {string} url
         * @memberof api.GetSiteEmbeddingRequest
         * @instance
         */
        GetSiteEmbeddingRequest.prototype.url = "";

        /**
         * Creates a new GetSiteEmbeddingRequest instance using the specified properties.
         * @function create
         * @memberof api.GetSiteEmbeddingRequest
         * @static
         * @param {api.IGetSiteEmbeddingRequest=} [properties] Properties to set
         * @returns {api.GetSiteEmbeddingRequest} GetSiteEmbeddingRequest instance
         */
        GetSiteEmbeddingRequest.create = function create(properties) {
            return new GetSiteEmbeddingRequest(properties);
        };

        /**
         * Encodes the specified GetSiteEmbeddingRequest message. Does not implicitly {@link api.GetSiteEmbeddingRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetSiteEmbeddingRequest
         * @static
         * @param {api.IGetSiteEmbeddingRequest} message GetSiteEmbeddingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSiteEmbeddingRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            return writer;
        };

        /**
         * Encodes the specified GetSiteEmbeddingRequest message, length delimited. Does not implicitly {@link api.GetSiteEmbeddingRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetSiteEmbeddingRequest
         * @static
         * @param {api.IGetSiteEmbeddingRequest} message GetSiteEmbeddingRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSiteEmbeddingRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSiteEmbeddingRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetSiteEmbeddingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetSiteEmbeddingRequest} GetSiteEmbeddingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSiteEmbeddingRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetSiteEmbeddingRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSiteEmbeddingRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetSiteEmbeddingRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetSiteEmbeddingRequest} GetSiteEmbeddingRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSiteEmbeddingRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSiteEmbeddingRequest message.
         * @function verify
         * @memberof api.GetSiteEmbeddingRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSiteEmbeddingRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            return null;
        };

        /**
         * Creates a GetSiteEmbeddingRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetSiteEmbeddingRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetSiteEmbeddingRequest} GetSiteEmbeddingRequest
         */
        GetSiteEmbeddingRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetSiteEmbeddingRequest)
                return object;
            let message = new $root.api.GetSiteEmbeddingRequest();
            if (object.url != null)
                message.url = String(object.url);
            return message;
        };

        /**
         * Creates a plain object from a GetSiteEmbeddingRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetSiteEmbeddingRequest
         * @static
         * @param {api.GetSiteEmbeddingRequest} message GetSiteEmbeddingRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSiteEmbeddingRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.url = "";
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            return object;
        };

        /**
         * Converts this GetSiteEmbeddingRequest to JSON.
         * @function toJSON
         * @memberof api.GetSiteEmbeddingRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSiteEmbeddingRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetSiteEmbeddingRequest;
    })();

    api.GetSiteEmbeddingResponse = (function() {

        /**
         * Properties of a GetSiteEmbeddingResponse.
         * @memberof api
         * @interface IGetSiteEmbeddingResponse
         * @property {string|null} [parameters] GetSiteEmbeddingResponse parameters
         * @property {string|null} [creditDepletionMessage] GetSiteEmbeddingResponse creditDepletionMessage
         */

        /**
         * Constructs a new GetSiteEmbeddingResponse.
         * @memberof api
         * @classdesc Represents a GetSiteEmbeddingResponse.
         * @implements IGetSiteEmbeddingResponse
         * @constructor
         * @param {api.IGetSiteEmbeddingResponse=} [properties] Properties to set
         */
        function GetSiteEmbeddingResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetSiteEmbeddingResponse parameters.
         * @member {string} parameters
         * @memberof api.GetSiteEmbeddingResponse
         * @instance
         */
        GetSiteEmbeddingResponse.prototype.parameters = "";

        /**
         * GetSiteEmbeddingResponse creditDepletionMessage.
         * @member {string} creditDepletionMessage
         * @memberof api.GetSiteEmbeddingResponse
         * @instance
         */
        GetSiteEmbeddingResponse.prototype.creditDepletionMessage = "";

        /**
         * Creates a new GetSiteEmbeddingResponse instance using the specified properties.
         * @function create
         * @memberof api.GetSiteEmbeddingResponse
         * @static
         * @param {api.IGetSiteEmbeddingResponse=} [properties] Properties to set
         * @returns {api.GetSiteEmbeddingResponse} GetSiteEmbeddingResponse instance
         */
        GetSiteEmbeddingResponse.create = function create(properties) {
            return new GetSiteEmbeddingResponse(properties);
        };

        /**
         * Encodes the specified GetSiteEmbeddingResponse message. Does not implicitly {@link api.GetSiteEmbeddingResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetSiteEmbeddingResponse
         * @static
         * @param {api.IGetSiteEmbeddingResponse} message GetSiteEmbeddingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSiteEmbeddingResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parameters != null && Object.hasOwnProperty.call(message, "parameters"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parameters);
            if (message.creditDepletionMessage != null && Object.hasOwnProperty.call(message, "creditDepletionMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.creditDepletionMessage);
            return writer;
        };

        /**
         * Encodes the specified GetSiteEmbeddingResponse message, length delimited. Does not implicitly {@link api.GetSiteEmbeddingResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetSiteEmbeddingResponse
         * @static
         * @param {api.IGetSiteEmbeddingResponse} message GetSiteEmbeddingResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSiteEmbeddingResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSiteEmbeddingResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetSiteEmbeddingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetSiteEmbeddingResponse} GetSiteEmbeddingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSiteEmbeddingResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetSiteEmbeddingResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.parameters = reader.string();
                    break;
                case 2:
                    message.creditDepletionMessage = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSiteEmbeddingResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetSiteEmbeddingResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetSiteEmbeddingResponse} GetSiteEmbeddingResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSiteEmbeddingResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSiteEmbeddingResponse message.
         * @function verify
         * @memberof api.GetSiteEmbeddingResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSiteEmbeddingResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.parameters != null && message.hasOwnProperty("parameters"))
                if (!$util.isString(message.parameters))
                    return "parameters: string expected";
            if (message.creditDepletionMessage != null && message.hasOwnProperty("creditDepletionMessage"))
                if (!$util.isString(message.creditDepletionMessage))
                    return "creditDepletionMessage: string expected";
            return null;
        };

        /**
         * Creates a GetSiteEmbeddingResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetSiteEmbeddingResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetSiteEmbeddingResponse} GetSiteEmbeddingResponse
         */
        GetSiteEmbeddingResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetSiteEmbeddingResponse)
                return object;
            let message = new $root.api.GetSiteEmbeddingResponse();
            if (object.parameters != null)
                message.parameters = String(object.parameters);
            if (object.creditDepletionMessage != null)
                message.creditDepletionMessage = String(object.creditDepletionMessage);
            return message;
        };

        /**
         * Creates a plain object from a GetSiteEmbeddingResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetSiteEmbeddingResponse
         * @static
         * @param {api.GetSiteEmbeddingResponse} message GetSiteEmbeddingResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSiteEmbeddingResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.parameters = "";
                object.creditDepletionMessage = "";
            }
            if (message.parameters != null && message.hasOwnProperty("parameters"))
                object.parameters = message.parameters;
            if (message.creditDepletionMessage != null && message.hasOwnProperty("creditDepletionMessage"))
                object.creditDepletionMessage = message.creditDepletionMessage;
            return object;
        };

        /**
         * Converts this GetSiteEmbeddingResponse to JSON.
         * @function toJSON
         * @memberof api.GetSiteEmbeddingResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSiteEmbeddingResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetSiteEmbeddingResponse;
    })();

    api.SendFeedbackRequest = (function() {

        /**
         * Properties of a SendFeedbackRequest.
         * @memberof api
         * @interface ISendFeedbackRequest
         * @property {string|null} [comment] SendFeedbackRequest comment
         */

        /**
         * Constructs a new SendFeedbackRequest.
         * @memberof api
         * @classdesc Represents a SendFeedbackRequest.
         * @implements ISendFeedbackRequest
         * @constructor
         * @param {api.ISendFeedbackRequest=} [properties] Properties to set
         */
        function SendFeedbackRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendFeedbackRequest comment.
         * @member {string} comment
         * @memberof api.SendFeedbackRequest
         * @instance
         */
        SendFeedbackRequest.prototype.comment = "";

        /**
         * Creates a new SendFeedbackRequest instance using the specified properties.
         * @function create
         * @memberof api.SendFeedbackRequest
         * @static
         * @param {api.ISendFeedbackRequest=} [properties] Properties to set
         * @returns {api.SendFeedbackRequest} SendFeedbackRequest instance
         */
        SendFeedbackRequest.create = function create(properties) {
            return new SendFeedbackRequest(properties);
        };

        /**
         * Encodes the specified SendFeedbackRequest message. Does not implicitly {@link api.SendFeedbackRequest.verify|verify} messages.
         * @function encode
         * @memberof api.SendFeedbackRequest
         * @static
         * @param {api.ISendFeedbackRequest} message SendFeedbackRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendFeedbackRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.comment);
            return writer;
        };

        /**
         * Encodes the specified SendFeedbackRequest message, length delimited. Does not implicitly {@link api.SendFeedbackRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SendFeedbackRequest
         * @static
         * @param {api.ISendFeedbackRequest} message SendFeedbackRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendFeedbackRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendFeedbackRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.SendFeedbackRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SendFeedbackRequest} SendFeedbackRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendFeedbackRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SendFeedbackRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendFeedbackRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SendFeedbackRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SendFeedbackRequest} SendFeedbackRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendFeedbackRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendFeedbackRequest message.
         * @function verify
         * @memberof api.SendFeedbackRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendFeedbackRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.comment != null && message.hasOwnProperty("comment"))
                if (!$util.isString(message.comment))
                    return "comment: string expected";
            return null;
        };

        /**
         * Creates a SendFeedbackRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SendFeedbackRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SendFeedbackRequest} SendFeedbackRequest
         */
        SendFeedbackRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SendFeedbackRequest)
                return object;
            let message = new $root.api.SendFeedbackRequest();
            if (object.comment != null)
                message.comment = String(object.comment);
            return message;
        };

        /**
         * Creates a plain object from a SendFeedbackRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SendFeedbackRequest
         * @static
         * @param {api.SendFeedbackRequest} message SendFeedbackRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendFeedbackRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.comment = "";
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            return object;
        };

        /**
         * Converts this SendFeedbackRequest to JSON.
         * @function toJSON
         * @memberof api.SendFeedbackRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendFeedbackRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendFeedbackRequest;
    })();

    api.SendFeedbackResponse = (function() {

        /**
         * Properties of a SendFeedbackResponse.
         * @memberof api
         * @interface ISendFeedbackResponse
         */

        /**
         * Constructs a new SendFeedbackResponse.
         * @memberof api
         * @classdesc Represents a SendFeedbackResponse.
         * @implements ISendFeedbackResponse
         * @constructor
         * @param {api.ISendFeedbackResponse=} [properties] Properties to set
         */
        function SendFeedbackResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SendFeedbackResponse instance using the specified properties.
         * @function create
         * @memberof api.SendFeedbackResponse
         * @static
         * @param {api.ISendFeedbackResponse=} [properties] Properties to set
         * @returns {api.SendFeedbackResponse} SendFeedbackResponse instance
         */
        SendFeedbackResponse.create = function create(properties) {
            return new SendFeedbackResponse(properties);
        };

        /**
         * Encodes the specified SendFeedbackResponse message. Does not implicitly {@link api.SendFeedbackResponse.verify|verify} messages.
         * @function encode
         * @memberof api.SendFeedbackResponse
         * @static
         * @param {api.ISendFeedbackResponse} message SendFeedbackResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendFeedbackResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SendFeedbackResponse message, length delimited. Does not implicitly {@link api.SendFeedbackResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SendFeedbackResponse
         * @static
         * @param {api.ISendFeedbackResponse} message SendFeedbackResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendFeedbackResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendFeedbackResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.SendFeedbackResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SendFeedbackResponse} SendFeedbackResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendFeedbackResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SendFeedbackResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendFeedbackResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SendFeedbackResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SendFeedbackResponse} SendFeedbackResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendFeedbackResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendFeedbackResponse message.
         * @function verify
         * @memberof api.SendFeedbackResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendFeedbackResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SendFeedbackResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SendFeedbackResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SendFeedbackResponse} SendFeedbackResponse
         */
        SendFeedbackResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SendFeedbackResponse)
                return object;
            return new $root.api.SendFeedbackResponse();
        };

        /**
         * Creates a plain object from a SendFeedbackResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SendFeedbackResponse
         * @static
         * @param {api.SendFeedbackResponse} message SendFeedbackResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendFeedbackResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SendFeedbackResponse to JSON.
         * @function toJSON
         * @memberof api.SendFeedbackResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendFeedbackResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendFeedbackResponse;
    })();

    api.ImageInfo = (function() {

        /**
         * Properties of an ImageInfo.
         * @memberof api
         * @interface IImageInfo
         * @property {string|null} [imageId] ImageInfo imageId
         * @property {string|null} [imageDescription] ImageInfo imageDescription
         * @property {string|null} [imageUrl] ImageInfo imageUrl
         * @property {string|null} [thumbnailUrl] ImageInfo thumbnailUrl
         * @property {number|Long|null} [seed] ImageInfo seed
         * @property {number|null} [width] ImageInfo width
         * @property {number|null} [height] ImageInfo height
         */

        /**
         * Constructs a new ImageInfo.
         * @memberof api
         * @classdesc Represents an ImageInfo.
         * @implements IImageInfo
         * @constructor
         * @param {api.IImageInfo=} [properties] Properties to set
         */
        function ImageInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageInfo imageId.
         * @member {string} imageId
         * @memberof api.ImageInfo
         * @instance
         */
        ImageInfo.prototype.imageId = "";

        /**
         * ImageInfo imageDescription.
         * @member {string} imageDescription
         * @memberof api.ImageInfo
         * @instance
         */
        ImageInfo.prototype.imageDescription = "";

        /**
         * ImageInfo imageUrl.
         * @member {string} imageUrl
         * @memberof api.ImageInfo
         * @instance
         */
        ImageInfo.prototype.imageUrl = "";

        /**
         * ImageInfo thumbnailUrl.
         * @member {string} thumbnailUrl
         * @memberof api.ImageInfo
         * @instance
         */
        ImageInfo.prototype.thumbnailUrl = "";

        /**
         * ImageInfo seed.
         * @member {number|Long} seed
         * @memberof api.ImageInfo
         * @instance
         */
        ImageInfo.prototype.seed = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ImageInfo width.
         * @member {number} width
         * @memberof api.ImageInfo
         * @instance
         */
        ImageInfo.prototype.width = 0;

        /**
         * ImageInfo height.
         * @member {number} height
         * @memberof api.ImageInfo
         * @instance
         */
        ImageInfo.prototype.height = 0;

        /**
         * Creates a new ImageInfo instance using the specified properties.
         * @function create
         * @memberof api.ImageInfo
         * @static
         * @param {api.IImageInfo=} [properties] Properties to set
         * @returns {api.ImageInfo} ImageInfo instance
         */
        ImageInfo.create = function create(properties) {
            return new ImageInfo(properties);
        };

        /**
         * Encodes the specified ImageInfo message. Does not implicitly {@link api.ImageInfo.verify|verify} messages.
         * @function encode
         * @memberof api.ImageInfo
         * @static
         * @param {api.IImageInfo} message ImageInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageDescription != null && Object.hasOwnProperty.call(message, "imageDescription"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageDescription);
            if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageUrl);
            if (message.seed != null && Object.hasOwnProperty.call(message, "seed"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.seed);
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.imageId);
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.thumbnailUrl);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.height);
            return writer;
        };

        /**
         * Encodes the specified ImageInfo message, length delimited. Does not implicitly {@link api.ImageInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ImageInfo
         * @static
         * @param {api.IImageInfo} message ImageInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.ImageInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ImageInfo} ImageInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ImageInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4:
                    message.imageId = reader.string();
                    break;
                case 1:
                    message.imageDescription = reader.string();
                    break;
                case 2:
                    message.imageUrl = reader.string();
                    break;
                case 5:
                    message.thumbnailUrl = reader.string();
                    break;
                case 3:
                    message.seed = reader.uint64();
                    break;
                case 6:
                    message.width = reader.int32();
                    break;
                case 7:
                    message.height = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ImageInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ImageInfo} ImageInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageInfo message.
         * @function verify
         * @memberof api.ImageInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                if (!$util.isString(message.imageId))
                    return "imageId: string expected";
            if (message.imageDescription != null && message.hasOwnProperty("imageDescription"))
                if (!$util.isString(message.imageDescription))
                    return "imageDescription: string expected";
            if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                if (!$util.isString(message.imageUrl))
                    return "imageUrl: string expected";
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                if (!$util.isString(message.thumbnailUrl))
                    return "thumbnailUrl: string expected";
            if (message.seed != null && message.hasOwnProperty("seed"))
                if (!$util.isInteger(message.seed) && !(message.seed && $util.isInteger(message.seed.low) && $util.isInteger(message.seed.high)))
                    return "seed: integer|Long expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates an ImageInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ImageInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ImageInfo} ImageInfo
         */
        ImageInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ImageInfo)
                return object;
            let message = new $root.api.ImageInfo();
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            if (object.imageDescription != null)
                message.imageDescription = String(object.imageDescription);
            if (object.imageUrl != null)
                message.imageUrl = String(object.imageUrl);
            if (object.thumbnailUrl != null)
                message.thumbnailUrl = String(object.thumbnailUrl);
            if (object.seed != null)
                if ($util.Long)
                    (message.seed = $util.Long.fromValue(object.seed)).unsigned = true;
                else if (typeof object.seed === "string")
                    message.seed = parseInt(object.seed, 10);
                else if (typeof object.seed === "number")
                    message.seed = object.seed;
                else if (typeof object.seed === "object")
                    message.seed = new $util.LongBits(object.seed.low >>> 0, object.seed.high >>> 0).toNumber(true);
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            return message;
        };

        /**
         * Creates a plain object from an ImageInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ImageInfo
         * @static
         * @param {api.ImageInfo} message ImageInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.imageDescription = "";
                object.imageUrl = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.seed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.seed = options.longs === String ? "0" : 0;
                object.imageId = "";
                object.thumbnailUrl = "";
                object.width = 0;
                object.height = 0;
            }
            if (message.imageDescription != null && message.hasOwnProperty("imageDescription"))
                object.imageDescription = message.imageDescription;
            if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                object.imageUrl = message.imageUrl;
            if (message.seed != null && message.hasOwnProperty("seed"))
                if (typeof message.seed === "number")
                    object.seed = options.longs === String ? String(message.seed) : message.seed;
                else
                    object.seed = options.longs === String ? $util.Long.prototype.toString.call(message.seed) : options.longs === Number ? new $util.LongBits(message.seed.low >>> 0, message.seed.high >>> 0).toNumber(true) : message.seed;
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = message.thumbnailUrl;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this ImageInfo to JSON.
         * @function toJSON
         * @memberof api.ImageInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImageInfo;
    })();

    api.ContextInfo = (function() {

        /**
         * Properties of a ContextInfo.
         * @memberof api
         * @interface IContextInfo
         * @property {string|null} [fileId] ContextInfo fileId
         * @property {string|null} [text] ContextInfo text
         * @property {number|null} [pageNo] ContextInfo pageNo
         */

        /**
         * Constructs a new ContextInfo.
         * @memberof api
         * @classdesc Represents a ContextInfo.
         * @implements IContextInfo
         * @constructor
         * @param {api.IContextInfo=} [properties] Properties to set
         */
        function ContextInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContextInfo fileId.
         * @member {string} fileId
         * @memberof api.ContextInfo
         * @instance
         */
        ContextInfo.prototype.fileId = "";

        /**
         * ContextInfo text.
         * @member {string} text
         * @memberof api.ContextInfo
         * @instance
         */
        ContextInfo.prototype.text = "";

        /**
         * ContextInfo pageNo.
         * @member {number} pageNo
         * @memberof api.ContextInfo
         * @instance
         */
        ContextInfo.prototype.pageNo = 0;

        /**
         * Creates a new ContextInfo instance using the specified properties.
         * @function create
         * @memberof api.ContextInfo
         * @static
         * @param {api.IContextInfo=} [properties] Properties to set
         * @returns {api.ContextInfo} ContextInfo instance
         */
        ContextInfo.create = function create(properties) {
            return new ContextInfo(properties);
        };

        /**
         * Encodes the specified ContextInfo message. Does not implicitly {@link api.ContextInfo.verify|verify} messages.
         * @function encode
         * @memberof api.ContextInfo
         * @static
         * @param {api.IContextInfo} message ContextInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContextInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
            if (message.pageNo != null && Object.hasOwnProperty.call(message, "pageNo"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageNo);
            return writer;
        };

        /**
         * Encodes the specified ContextInfo message, length delimited. Does not implicitly {@link api.ContextInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ContextInfo
         * @static
         * @param {api.IContextInfo} message ContextInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContextInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ContextInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.ContextInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ContextInfo} ContextInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContextInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ContextInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                case 2:
                    message.text = reader.string();
                    break;
                case 3:
                    message.pageNo = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ContextInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ContextInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ContextInfo} ContextInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContextInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ContextInfo message.
         * @function verify
         * @memberof api.ContextInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ContextInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.pageNo != null && message.hasOwnProperty("pageNo"))
                if (!$util.isInteger(message.pageNo))
                    return "pageNo: integer expected";
            return null;
        };

        /**
         * Creates a ContextInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ContextInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ContextInfo} ContextInfo
         */
        ContextInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ContextInfo)
                return object;
            let message = new $root.api.ContextInfo();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.text != null)
                message.text = String(object.text);
            if (object.pageNo != null)
                message.pageNo = object.pageNo | 0;
            return message;
        };

        /**
         * Creates a plain object from a ContextInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ContextInfo
         * @static
         * @param {api.ContextInfo} message ContextInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ContextInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.text = "";
                object.pageNo = 0;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.pageNo != null && message.hasOwnProperty("pageNo"))
                object.pageNo = message.pageNo;
            return object;
        };

        /**
         * Converts this ContextInfo to JSON.
         * @function toJSON
         * @memberof api.ContextInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ContextInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContextInfo;
    })();

    api.ResponseInfo = (function() {

        /**
         * Properties of a ResponseInfo.
         * @memberof api
         * @interface IResponseInfo
         * @property {string|null} [text] ResponseInfo text
         * @property {Array.<api.IImageInfo>|null} [images] ResponseInfo images
         */

        /**
         * Constructs a new ResponseInfo.
         * @memberof api
         * @classdesc Represents a ResponseInfo.
         * @implements IResponseInfo
         * @constructor
         * @param {api.IResponseInfo=} [properties] Properties to set
         */
        function ResponseInfo(properties) {
            this.images = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResponseInfo text.
         * @member {string} text
         * @memberof api.ResponseInfo
         * @instance
         */
        ResponseInfo.prototype.text = "";

        /**
         * ResponseInfo images.
         * @member {Array.<api.IImageInfo>} images
         * @memberof api.ResponseInfo
         * @instance
         */
        ResponseInfo.prototype.images = $util.emptyArray;

        /**
         * Creates a new ResponseInfo instance using the specified properties.
         * @function create
         * @memberof api.ResponseInfo
         * @static
         * @param {api.IResponseInfo=} [properties] Properties to set
         * @returns {api.ResponseInfo} ResponseInfo instance
         */
        ResponseInfo.create = function create(properties) {
            return new ResponseInfo(properties);
        };

        /**
         * Encodes the specified ResponseInfo message. Does not implicitly {@link api.ResponseInfo.verify|verify} messages.
         * @function encode
         * @memberof api.ResponseInfo
         * @static
         * @param {api.IResponseInfo} message ResponseInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
            if (message.images != null && message.images.length)
                for (let i = 0; i < message.images.length; ++i)
                    $root.api.ImageInfo.encode(message.images[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ResponseInfo message, length delimited. Does not implicitly {@link api.ResponseInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ResponseInfo
         * @static
         * @param {api.IResponseInfo} message ResponseInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.ResponseInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ResponseInfo} ResponseInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ResponseInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.text = reader.string();
                    break;
                case 2:
                    if (!(message.images && message.images.length))
                        message.images = [];
                    message.images.push($root.api.ImageInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ResponseInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ResponseInfo} ResponseInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseInfo message.
         * @function verify
         * @memberof api.ResponseInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.images != null && message.hasOwnProperty("images")) {
                if (!Array.isArray(message.images))
                    return "images: array expected";
                for (let i = 0; i < message.images.length; ++i) {
                    let error = $root.api.ImageInfo.verify(message.images[i]);
                    if (error)
                        return "images." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ResponseInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ResponseInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ResponseInfo} ResponseInfo
         */
        ResponseInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ResponseInfo)
                return object;
            let message = new $root.api.ResponseInfo();
            if (object.text != null)
                message.text = String(object.text);
            if (object.images) {
                if (!Array.isArray(object.images))
                    throw TypeError(".api.ResponseInfo.images: array expected");
                message.images = [];
                for (let i = 0; i < object.images.length; ++i) {
                    if (typeof object.images[i] !== "object")
                        throw TypeError(".api.ResponseInfo.images: object expected");
                    message.images[i] = $root.api.ImageInfo.fromObject(object.images[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ResponseInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ResponseInfo
         * @static
         * @param {api.ResponseInfo} message ResponseInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.images = [];
            if (options.defaults)
                object.text = "";
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.images && message.images.length) {
                object.images = [];
                for (let j = 0; j < message.images.length; ++j)
                    object.images[j] = $root.api.ImageInfo.toObject(message.images[j], options);
            }
            return object;
        };

        /**
         * Converts this ResponseInfo to JSON.
         * @function toJSON
         * @memberof api.ResponseInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseInfo;
    })();

    api.GenerateAIResponse = (function() {

        /**
         * Properties of a GenerateAIResponse.
         * @memberof api
         * @interface IGenerateAIResponse
         * @property {string|null} [conversationId] GenerateAIResponse conversationId
         * @property {Array.<api.IResponseInfo>|null} [responses] GenerateAIResponse responses
         * @property {number|Long|null} [tokensLeft] GenerateAIResponse tokensLeft
         * @property {Array.<api.IContextInfo>|null} [contexts] GenerateAIResponse contexts
         * @property {number|Long|null} [queueTime] GenerateAIResponse queueTime
         * @property {number|Long|null} [processTime] GenerateAIResponse processTime
         * @property {number|Long|null} [tokensUsed] GenerateAIResponse tokensUsed
         */

        /**
         * Constructs a new GenerateAIResponse.
         * @memberof api
         * @classdesc Represents a GenerateAIResponse.
         * @implements IGenerateAIResponse
         * @constructor
         * @param {api.IGenerateAIResponse=} [properties] Properties to set
         */
        function GenerateAIResponse(properties) {
            this.responses = [];
            this.contexts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateAIResponse conversationId.
         * @member {string} conversationId
         * @memberof api.GenerateAIResponse
         * @instance
         */
        GenerateAIResponse.prototype.conversationId = "";

        /**
         * GenerateAIResponse responses.
         * @member {Array.<api.IResponseInfo>} responses
         * @memberof api.GenerateAIResponse
         * @instance
         */
        GenerateAIResponse.prototype.responses = $util.emptyArray;

        /**
         * GenerateAIResponse tokensLeft.
         * @member {number|Long} tokensLeft
         * @memberof api.GenerateAIResponse
         * @instance
         */
        GenerateAIResponse.prototype.tokensLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GenerateAIResponse contexts.
         * @member {Array.<api.IContextInfo>} contexts
         * @memberof api.GenerateAIResponse
         * @instance
         */
        GenerateAIResponse.prototype.contexts = $util.emptyArray;

        /**
         * GenerateAIResponse queueTime.
         * @member {number|Long} queueTime
         * @memberof api.GenerateAIResponse
         * @instance
         */
        GenerateAIResponse.prototype.queueTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GenerateAIResponse processTime.
         * @member {number|Long} processTime
         * @memberof api.GenerateAIResponse
         * @instance
         */
        GenerateAIResponse.prototype.processTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GenerateAIResponse tokensUsed.
         * @member {number|Long} tokensUsed
         * @memberof api.GenerateAIResponse
         * @instance
         */
        GenerateAIResponse.prototype.tokensUsed = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new GenerateAIResponse instance using the specified properties.
         * @function create
         * @memberof api.GenerateAIResponse
         * @static
         * @param {api.IGenerateAIResponse=} [properties] Properties to set
         * @returns {api.GenerateAIResponse} GenerateAIResponse instance
         */
        GenerateAIResponse.create = function create(properties) {
            return new GenerateAIResponse(properties);
        };

        /**
         * Encodes the specified GenerateAIResponse message. Does not implicitly {@link api.GenerateAIResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GenerateAIResponse
         * @static
         * @param {api.IGenerateAIResponse} message GenerateAIResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateAIResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.conversationId != null && Object.hasOwnProperty.call(message, "conversationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.conversationId);
            if (message.responses != null && message.responses.length)
                for (let i = 0; i < message.responses.length; ++i)
                    $root.api.ResponseInfo.encode(message.responses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tokensLeft != null && Object.hasOwnProperty.call(message, "tokensLeft"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.tokensLeft);
            if (message.contexts != null && message.contexts.length)
                for (let i = 0; i < message.contexts.length; ++i)
                    $root.api.ContextInfo.encode(message.contexts[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.queueTime != null && Object.hasOwnProperty.call(message, "queueTime"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.queueTime);
            if (message.processTime != null && Object.hasOwnProperty.call(message, "processTime"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.processTime);
            if (message.tokensUsed != null && Object.hasOwnProperty.call(message, "tokensUsed"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.tokensUsed);
            return writer;
        };

        /**
         * Encodes the specified GenerateAIResponse message, length delimited. Does not implicitly {@link api.GenerateAIResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GenerateAIResponse
         * @static
         * @param {api.IGenerateAIResponse} message GenerateAIResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateAIResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateAIResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GenerateAIResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GenerateAIResponse} GenerateAIResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateAIResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GenerateAIResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.conversationId = reader.string();
                    break;
                case 2:
                    if (!(message.responses && message.responses.length))
                        message.responses = [];
                    message.responses.push($root.api.ResponseInfo.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.tokensLeft = reader.int64();
                    break;
                case 4:
                    if (!(message.contexts && message.contexts.length))
                        message.contexts = [];
                    message.contexts.push($root.api.ContextInfo.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.queueTime = reader.int64();
                    break;
                case 8:
                    message.processTime = reader.int64();
                    break;
                case 9:
                    message.tokensUsed = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateAIResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GenerateAIResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GenerateAIResponse} GenerateAIResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateAIResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateAIResponse message.
         * @function verify
         * @memberof api.GenerateAIResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateAIResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                if (!$util.isString(message.conversationId))
                    return "conversationId: string expected";
            if (message.responses != null && message.hasOwnProperty("responses")) {
                if (!Array.isArray(message.responses))
                    return "responses: array expected";
                for (let i = 0; i < message.responses.length; ++i) {
                    let error = $root.api.ResponseInfo.verify(message.responses[i]);
                    if (error)
                        return "responses." + error;
                }
            }
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (!$util.isInteger(message.tokensLeft) && !(message.tokensLeft && $util.isInteger(message.tokensLeft.low) && $util.isInteger(message.tokensLeft.high)))
                    return "tokensLeft: integer|Long expected";
            if (message.contexts != null && message.hasOwnProperty("contexts")) {
                if (!Array.isArray(message.contexts))
                    return "contexts: array expected";
                for (let i = 0; i < message.contexts.length; ++i) {
                    let error = $root.api.ContextInfo.verify(message.contexts[i]);
                    if (error)
                        return "contexts." + error;
                }
            }
            if (message.queueTime != null && message.hasOwnProperty("queueTime"))
                if (!$util.isInteger(message.queueTime) && !(message.queueTime && $util.isInteger(message.queueTime.low) && $util.isInteger(message.queueTime.high)))
                    return "queueTime: integer|Long expected";
            if (message.processTime != null && message.hasOwnProperty("processTime"))
                if (!$util.isInteger(message.processTime) && !(message.processTime && $util.isInteger(message.processTime.low) && $util.isInteger(message.processTime.high)))
                    return "processTime: integer|Long expected";
            if (message.tokensUsed != null && message.hasOwnProperty("tokensUsed"))
                if (!$util.isInteger(message.tokensUsed) && !(message.tokensUsed && $util.isInteger(message.tokensUsed.low) && $util.isInteger(message.tokensUsed.high)))
                    return "tokensUsed: integer|Long expected";
            return null;
        };

        /**
         * Creates a GenerateAIResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GenerateAIResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GenerateAIResponse} GenerateAIResponse
         */
        GenerateAIResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GenerateAIResponse)
                return object;
            let message = new $root.api.GenerateAIResponse();
            if (object.conversationId != null)
                message.conversationId = String(object.conversationId);
            if (object.responses) {
                if (!Array.isArray(object.responses))
                    throw TypeError(".api.GenerateAIResponse.responses: array expected");
                message.responses = [];
                for (let i = 0; i < object.responses.length; ++i) {
                    if (typeof object.responses[i] !== "object")
                        throw TypeError(".api.GenerateAIResponse.responses: object expected");
                    message.responses[i] = $root.api.ResponseInfo.fromObject(object.responses[i]);
                }
            }
            if (object.tokensLeft != null)
                if ($util.Long)
                    (message.tokensLeft = $util.Long.fromValue(object.tokensLeft)).unsigned = false;
                else if (typeof object.tokensLeft === "string")
                    message.tokensLeft = parseInt(object.tokensLeft, 10);
                else if (typeof object.tokensLeft === "number")
                    message.tokensLeft = object.tokensLeft;
                else if (typeof object.tokensLeft === "object")
                    message.tokensLeft = new $util.LongBits(object.tokensLeft.low >>> 0, object.tokensLeft.high >>> 0).toNumber();
            if (object.contexts) {
                if (!Array.isArray(object.contexts))
                    throw TypeError(".api.GenerateAIResponse.contexts: array expected");
                message.contexts = [];
                for (let i = 0; i < object.contexts.length; ++i) {
                    if (typeof object.contexts[i] !== "object")
                        throw TypeError(".api.GenerateAIResponse.contexts: object expected");
                    message.contexts[i] = $root.api.ContextInfo.fromObject(object.contexts[i]);
                }
            }
            if (object.queueTime != null)
                if ($util.Long)
                    (message.queueTime = $util.Long.fromValue(object.queueTime)).unsigned = false;
                else if (typeof object.queueTime === "string")
                    message.queueTime = parseInt(object.queueTime, 10);
                else if (typeof object.queueTime === "number")
                    message.queueTime = object.queueTime;
                else if (typeof object.queueTime === "object")
                    message.queueTime = new $util.LongBits(object.queueTime.low >>> 0, object.queueTime.high >>> 0).toNumber();
            if (object.processTime != null)
                if ($util.Long)
                    (message.processTime = $util.Long.fromValue(object.processTime)).unsigned = false;
                else if (typeof object.processTime === "string")
                    message.processTime = parseInt(object.processTime, 10);
                else if (typeof object.processTime === "number")
                    message.processTime = object.processTime;
                else if (typeof object.processTime === "object")
                    message.processTime = new $util.LongBits(object.processTime.low >>> 0, object.processTime.high >>> 0).toNumber();
            if (object.tokensUsed != null)
                if ($util.Long)
                    (message.tokensUsed = $util.Long.fromValue(object.tokensUsed)).unsigned = false;
                else if (typeof object.tokensUsed === "string")
                    message.tokensUsed = parseInt(object.tokensUsed, 10);
                else if (typeof object.tokensUsed === "number")
                    message.tokensUsed = object.tokensUsed;
                else if (typeof object.tokensUsed === "object")
                    message.tokensUsed = new $util.LongBits(object.tokensUsed.low >>> 0, object.tokensUsed.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a GenerateAIResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GenerateAIResponse
         * @static
         * @param {api.GenerateAIResponse} message GenerateAIResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateAIResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.responses = [];
                object.contexts = [];
            }
            if (options.defaults) {
                object.conversationId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensLeft = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.queueTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.queueTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.processTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.processTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensUsed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensUsed = options.longs === String ? "0" : 0;
            }
            if (message.conversationId != null && message.hasOwnProperty("conversationId"))
                object.conversationId = message.conversationId;
            if (message.responses && message.responses.length) {
                object.responses = [];
                for (let j = 0; j < message.responses.length; ++j)
                    object.responses[j] = $root.api.ResponseInfo.toObject(message.responses[j], options);
            }
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (typeof message.tokensLeft === "number")
                    object.tokensLeft = options.longs === String ? String(message.tokensLeft) : message.tokensLeft;
                else
                    object.tokensLeft = options.longs === String ? $util.Long.prototype.toString.call(message.tokensLeft) : options.longs === Number ? new $util.LongBits(message.tokensLeft.low >>> 0, message.tokensLeft.high >>> 0).toNumber() : message.tokensLeft;
            if (message.contexts && message.contexts.length) {
                object.contexts = [];
                for (let j = 0; j < message.contexts.length; ++j)
                    object.contexts[j] = $root.api.ContextInfo.toObject(message.contexts[j], options);
            }
            if (message.queueTime != null && message.hasOwnProperty("queueTime"))
                if (typeof message.queueTime === "number")
                    object.queueTime = options.longs === String ? String(message.queueTime) : message.queueTime;
                else
                    object.queueTime = options.longs === String ? $util.Long.prototype.toString.call(message.queueTime) : options.longs === Number ? new $util.LongBits(message.queueTime.low >>> 0, message.queueTime.high >>> 0).toNumber() : message.queueTime;
            if (message.processTime != null && message.hasOwnProperty("processTime"))
                if (typeof message.processTime === "number")
                    object.processTime = options.longs === String ? String(message.processTime) : message.processTime;
                else
                    object.processTime = options.longs === String ? $util.Long.prototype.toString.call(message.processTime) : options.longs === Number ? new $util.LongBits(message.processTime.low >>> 0, message.processTime.high >>> 0).toNumber() : message.processTime;
            if (message.tokensUsed != null && message.hasOwnProperty("tokensUsed"))
                if (typeof message.tokensUsed === "number")
                    object.tokensUsed = options.longs === String ? String(message.tokensUsed) : message.tokensUsed;
                else
                    object.tokensUsed = options.longs === String ? $util.Long.prototype.toString.call(message.tokensUsed) : options.longs === Number ? new $util.LongBits(message.tokensUsed.low >>> 0, message.tokensUsed.high >>> 0).toNumber() : message.tokensUsed;
            return object;
        };

        /**
         * Converts this GenerateAIResponse to JSON.
         * @function toJSON
         * @memberof api.GenerateAIResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateAIResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenerateAIResponse;
    })();

    api.Generation = (function() {

        /**
         * Properties of a Generation.
         * @memberof api
         * @interface IGeneration
         * @property {Array.<string>|null} [generatedContent] Generation generatedContent
         */

        /**
         * Constructs a new Generation.
         * @memberof api
         * @classdesc Represents a Generation.
         * @implements IGeneration
         * @constructor
         * @param {api.IGeneration=} [properties] Properties to set
         */
        function Generation(properties) {
            this.generatedContent = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Generation generatedContent.
         * @member {Array.<string>} generatedContent
         * @memberof api.Generation
         * @instance
         */
        Generation.prototype.generatedContent = $util.emptyArray;

        /**
         * Creates a new Generation instance using the specified properties.
         * @function create
         * @memberof api.Generation
         * @static
         * @param {api.IGeneration=} [properties] Properties to set
         * @returns {api.Generation} Generation instance
         */
        Generation.create = function create(properties) {
            return new Generation(properties);
        };

        /**
         * Encodes the specified Generation message. Does not implicitly {@link api.Generation.verify|verify} messages.
         * @function encode
         * @memberof api.Generation
         * @static
         * @param {api.IGeneration} message Generation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Generation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.generatedContent != null && message.generatedContent.length)
                for (let i = 0; i < message.generatedContent.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.generatedContent[i]);
            return writer;
        };

        /**
         * Encodes the specified Generation message, length delimited. Does not implicitly {@link api.Generation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Generation
         * @static
         * @param {api.IGeneration} message Generation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Generation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Generation message from the specified reader or buffer.
         * @function decode
         * @memberof api.Generation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Generation} Generation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Generation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Generation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.generatedContent && message.generatedContent.length))
                        message.generatedContent = [];
                    message.generatedContent.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Generation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Generation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Generation} Generation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Generation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Generation message.
         * @function verify
         * @memberof api.Generation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Generation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.generatedContent != null && message.hasOwnProperty("generatedContent")) {
                if (!Array.isArray(message.generatedContent))
                    return "generatedContent: array expected";
                for (let i = 0; i < message.generatedContent.length; ++i)
                    if (!$util.isString(message.generatedContent[i]))
                        return "generatedContent: string[] expected";
            }
            return null;
        };

        /**
         * Creates a Generation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Generation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Generation} Generation
         */
        Generation.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Generation)
                return object;
            let message = new $root.api.Generation();
            if (object.generatedContent) {
                if (!Array.isArray(object.generatedContent))
                    throw TypeError(".api.Generation.generatedContent: array expected");
                message.generatedContent = [];
                for (let i = 0; i < object.generatedContent.length; ++i)
                    message.generatedContent[i] = String(object.generatedContent[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a Generation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Generation
         * @static
         * @param {api.Generation} message Generation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Generation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.generatedContent = [];
            if (message.generatedContent && message.generatedContent.length) {
                object.generatedContent = [];
                for (let j = 0; j < message.generatedContent.length; ++j)
                    object.generatedContent[j] = message.generatedContent[j];
            }
            return object;
        };

        /**
         * Converts this Generation to JSON.
         * @function toJSON
         * @memberof api.Generation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Generation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Generation;
    })();

    api.ProjectInfo = (function() {

        /**
         * Properties of a ProjectInfo.
         * @memberof api
         * @interface IProjectInfo
         * @property {Array.<api.IGeneration>|null} [generations] ProjectInfo generations
         * @property {string|null} [content] ProjectInfo content
         */

        /**
         * Constructs a new ProjectInfo.
         * @memberof api
         * @classdesc Represents a ProjectInfo.
         * @implements IProjectInfo
         * @constructor
         * @param {api.IProjectInfo=} [properties] Properties to set
         */
        function ProjectInfo(properties) {
            this.generations = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProjectInfo generations.
         * @member {Array.<api.IGeneration>} generations
         * @memberof api.ProjectInfo
         * @instance
         */
        ProjectInfo.prototype.generations = $util.emptyArray;

        /**
         * ProjectInfo content.
         * @member {string} content
         * @memberof api.ProjectInfo
         * @instance
         */
        ProjectInfo.prototype.content = "";

        /**
         * Creates a new ProjectInfo instance using the specified properties.
         * @function create
         * @memberof api.ProjectInfo
         * @static
         * @param {api.IProjectInfo=} [properties] Properties to set
         * @returns {api.ProjectInfo} ProjectInfo instance
         */
        ProjectInfo.create = function create(properties) {
            return new ProjectInfo(properties);
        };

        /**
         * Encodes the specified ProjectInfo message. Does not implicitly {@link api.ProjectInfo.verify|verify} messages.
         * @function encode
         * @memberof api.ProjectInfo
         * @static
         * @param {api.IProjectInfo} message ProjectInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.generations != null && message.generations.length)
                for (let i = 0; i < message.generations.length; ++i)
                    $root.api.Generation.encode(message.generations[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.content);
            return writer;
        };

        /**
         * Encodes the specified ProjectInfo message, length delimited. Does not implicitly {@link api.ProjectInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ProjectInfo
         * @static
         * @param {api.IProjectInfo} message ProjectInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProjectInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProjectInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.ProjectInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ProjectInfo} ProjectInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ProjectInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    if (!(message.generations && message.generations.length))
                        message.generations = [];
                    message.generations.push($root.api.Generation.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.content = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProjectInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ProjectInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ProjectInfo} ProjectInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProjectInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProjectInfo message.
         * @function verify
         * @memberof api.ProjectInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProjectInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.generations != null && message.hasOwnProperty("generations")) {
                if (!Array.isArray(message.generations))
                    return "generations: array expected";
                for (let i = 0; i < message.generations.length; ++i) {
                    let error = $root.api.Generation.verify(message.generations[i]);
                    if (error)
                        return "generations." + error;
                }
            }
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            return null;
        };

        /**
         * Creates a ProjectInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ProjectInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ProjectInfo} ProjectInfo
         */
        ProjectInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ProjectInfo)
                return object;
            let message = new $root.api.ProjectInfo();
            if (object.generations) {
                if (!Array.isArray(object.generations))
                    throw TypeError(".api.ProjectInfo.generations: array expected");
                message.generations = [];
                for (let i = 0; i < object.generations.length; ++i) {
                    if (typeof object.generations[i] !== "object")
                        throw TypeError(".api.ProjectInfo.generations: object expected");
                    message.generations[i] = $root.api.Generation.fromObject(object.generations[i]);
                }
            }
            if (object.content != null)
                message.content = String(object.content);
            return message;
        };

        /**
         * Creates a plain object from a ProjectInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ProjectInfo
         * @static
         * @param {api.ProjectInfo} message ProjectInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProjectInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.generations = [];
            if (options.defaults)
                object.content = "";
            if (message.generations && message.generations.length) {
                object.generations = [];
                for (let j = 0; j < message.generations.length; ++j)
                    object.generations[j] = $root.api.Generation.toObject(message.generations[j], options);
            }
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            return object;
        };

        /**
         * Converts this ProjectInfo to JSON.
         * @function toJSON
         * @memberof api.ProjectInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProjectInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProjectInfo;
    })();

    api.FileQuery = (function() {

        /**
         * Properties of a FileQuery.
         * @memberof api
         * @interface IFileQuery
         * @property {string|null} [fileId] FileQuery fileId
         * @property {boolean|null} [includeFolders] FileQuery includeFolders
         * @property {boolean|null} [includeProjectFiles] FileQuery includeProjectFiles
         * @property {boolean|null} [includeComprehendFiles] FileQuery includeComprehendFiles
         * @property {boolean|null} [includeImageFiles] FileQuery includeImageFiles
         * @property {boolean|null} [includeAudioFiles] FileQuery includeAudioFiles
         * @property {boolean|null} [includeVideoFiles] FileQuery includeVideoFiles
         * @property {boolean|null} [includeMaskFiles] FileQuery includeMaskFiles
         * @property {boolean|null} [includeVectorFiles] FileQuery includeVectorFiles
         * @property {number|null} [limit] FileQuery limit
         * @property {api.IDateFilter|null} [dateFilter] FileQuery dateFilter
         * @property {api.IBoolFilter|null} [approved] FileQuery approved
         * @property {api.IStringFilter|null} [category] FileQuery category
         */

        /**
         * Constructs a new FileQuery.
         * @memberof api
         * @classdesc Represents a FileQuery.
         * @implements IFileQuery
         * @constructor
         * @param {api.IFileQuery=} [properties] Properties to set
         */
        function FileQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileQuery fileId.
         * @member {string} fileId
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.fileId = "";

        /**
         * FileQuery includeFolders.
         * @member {boolean} includeFolders
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.includeFolders = false;

        /**
         * FileQuery includeProjectFiles.
         * @member {boolean} includeProjectFiles
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.includeProjectFiles = false;

        /**
         * FileQuery includeComprehendFiles.
         * @member {boolean} includeComprehendFiles
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.includeComprehendFiles = false;

        /**
         * FileQuery includeImageFiles.
         * @member {boolean} includeImageFiles
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.includeImageFiles = false;

        /**
         * FileQuery includeAudioFiles.
         * @member {boolean} includeAudioFiles
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.includeAudioFiles = false;

        /**
         * FileQuery includeVideoFiles.
         * @member {boolean} includeVideoFiles
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.includeVideoFiles = false;

        /**
         * FileQuery includeMaskFiles.
         * @member {boolean} includeMaskFiles
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.includeMaskFiles = false;

        /**
         * FileQuery includeVectorFiles.
         * @member {boolean} includeVectorFiles
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.includeVectorFiles = false;

        /**
         * FileQuery limit.
         * @member {number} limit
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.limit = 0;

        /**
         * FileQuery dateFilter.
         * @member {api.IDateFilter|null|undefined} dateFilter
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.dateFilter = null;

        /**
         * FileQuery approved.
         * @member {api.IBoolFilter|null|undefined} approved
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.approved = null;

        /**
         * FileQuery category.
         * @member {api.IStringFilter|null|undefined} category
         * @memberof api.FileQuery
         * @instance
         */
        FileQuery.prototype.category = null;

        /**
         * Creates a new FileQuery instance using the specified properties.
         * @function create
         * @memberof api.FileQuery
         * @static
         * @param {api.IFileQuery=} [properties] Properties to set
         * @returns {api.FileQuery} FileQuery instance
         */
        FileQuery.create = function create(properties) {
            return new FileQuery(properties);
        };

        /**
         * Encodes the specified FileQuery message. Does not implicitly {@link api.FileQuery.verify|verify} messages.
         * @function encode
         * @memberof api.FileQuery
         * @static
         * @param {api.IFileQuery} message FileQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeFolders != null && Object.hasOwnProperty.call(message, "includeFolders"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeFolders);
            if (message.includeProjectFiles != null && Object.hasOwnProperty.call(message, "includeProjectFiles"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeProjectFiles);
            if (message.includeComprehendFiles != null && Object.hasOwnProperty.call(message, "includeComprehendFiles"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeComprehendFiles);
            if (message.includeImageFiles != null && Object.hasOwnProperty.call(message, "includeImageFiles"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeImageFiles);
            if (message.includeMaskFiles != null && Object.hasOwnProperty.call(message, "includeMaskFiles"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.includeMaskFiles);
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.fileId);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.limit);
            if (message.dateFilter != null && Object.hasOwnProperty.call(message, "dateFilter"))
                $root.api.DateFilter.encode(message.dateFilter, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.approved != null && Object.hasOwnProperty.call(message, "approved"))
                $root.api.BoolFilter.encode(message.approved, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                $root.api.StringFilter.encode(message.category, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.includeVectorFiles != null && Object.hasOwnProperty.call(message, "includeVectorFiles"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.includeVectorFiles);
            if (message.includeAudioFiles != null && Object.hasOwnProperty.call(message, "includeAudioFiles"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.includeAudioFiles);
            if (message.includeVideoFiles != null && Object.hasOwnProperty.call(message, "includeVideoFiles"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.includeVideoFiles);
            return writer;
        };

        /**
         * Encodes the specified FileQuery message, length delimited. Does not implicitly {@link api.FileQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.FileQuery
         * @static
         * @param {api.IFileQuery} message FileQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.FileQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.FileQuery} FileQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.FileQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6:
                    message.fileId = reader.string();
                    break;
                case 1:
                    message.includeFolders = reader.bool();
                    break;
                case 2:
                    message.includeProjectFiles = reader.bool();
                    break;
                case 3:
                    message.includeComprehendFiles = reader.bool();
                    break;
                case 4:
                    message.includeImageFiles = reader.bool();
                    break;
                case 12:
                    message.includeAudioFiles = reader.bool();
                    break;
                case 13:
                    message.includeVideoFiles = reader.bool();
                    break;
                case 5:
                    message.includeMaskFiles = reader.bool();
                    break;
                case 11:
                    message.includeVectorFiles = reader.bool();
                    break;
                case 7:
                    message.limit = reader.int32();
                    break;
                case 8:
                    message.dateFilter = $root.api.DateFilter.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.approved = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.category = $root.api.StringFilter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.FileQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.FileQuery} FileQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileQuery message.
         * @function verify
         * @memberof api.FileQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.includeFolders != null && message.hasOwnProperty("includeFolders"))
                if (typeof message.includeFolders !== "boolean")
                    return "includeFolders: boolean expected";
            if (message.includeProjectFiles != null && message.hasOwnProperty("includeProjectFiles"))
                if (typeof message.includeProjectFiles !== "boolean")
                    return "includeProjectFiles: boolean expected";
            if (message.includeComprehendFiles != null && message.hasOwnProperty("includeComprehendFiles"))
                if (typeof message.includeComprehendFiles !== "boolean")
                    return "includeComprehendFiles: boolean expected";
            if (message.includeImageFiles != null && message.hasOwnProperty("includeImageFiles"))
                if (typeof message.includeImageFiles !== "boolean")
                    return "includeImageFiles: boolean expected";
            if (message.includeAudioFiles != null && message.hasOwnProperty("includeAudioFiles"))
                if (typeof message.includeAudioFiles !== "boolean")
                    return "includeAudioFiles: boolean expected";
            if (message.includeVideoFiles != null && message.hasOwnProperty("includeVideoFiles"))
                if (typeof message.includeVideoFiles !== "boolean")
                    return "includeVideoFiles: boolean expected";
            if (message.includeMaskFiles != null && message.hasOwnProperty("includeMaskFiles"))
                if (typeof message.includeMaskFiles !== "boolean")
                    return "includeMaskFiles: boolean expected";
            if (message.includeVectorFiles != null && message.hasOwnProperty("includeVectorFiles"))
                if (typeof message.includeVectorFiles !== "boolean")
                    return "includeVectorFiles: boolean expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.dateFilter != null && message.hasOwnProperty("dateFilter")) {
                let error = $root.api.DateFilter.verify(message.dateFilter);
                if (error)
                    return "dateFilter." + error;
            }
            if (message.approved != null && message.hasOwnProperty("approved")) {
                let error = $root.api.BoolFilter.verify(message.approved);
                if (error)
                    return "approved." + error;
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                let error = $root.api.StringFilter.verify(message.category);
                if (error)
                    return "category." + error;
            }
            return null;
        };

        /**
         * Creates a FileQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.FileQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.FileQuery} FileQuery
         */
        FileQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.FileQuery)
                return object;
            let message = new $root.api.FileQuery();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.includeFolders != null)
                message.includeFolders = Boolean(object.includeFolders);
            if (object.includeProjectFiles != null)
                message.includeProjectFiles = Boolean(object.includeProjectFiles);
            if (object.includeComprehendFiles != null)
                message.includeComprehendFiles = Boolean(object.includeComprehendFiles);
            if (object.includeImageFiles != null)
                message.includeImageFiles = Boolean(object.includeImageFiles);
            if (object.includeAudioFiles != null)
                message.includeAudioFiles = Boolean(object.includeAudioFiles);
            if (object.includeVideoFiles != null)
                message.includeVideoFiles = Boolean(object.includeVideoFiles);
            if (object.includeMaskFiles != null)
                message.includeMaskFiles = Boolean(object.includeMaskFiles);
            if (object.includeVectorFiles != null)
                message.includeVectorFiles = Boolean(object.includeVectorFiles);
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.dateFilter != null) {
                if (typeof object.dateFilter !== "object")
                    throw TypeError(".api.FileQuery.dateFilter: object expected");
                message.dateFilter = $root.api.DateFilter.fromObject(object.dateFilter);
            }
            if (object.approved != null) {
                if (typeof object.approved !== "object")
                    throw TypeError(".api.FileQuery.approved: object expected");
                message.approved = $root.api.BoolFilter.fromObject(object.approved);
            }
            if (object.category != null) {
                if (typeof object.category !== "object")
                    throw TypeError(".api.FileQuery.category: object expected");
                message.category = $root.api.StringFilter.fromObject(object.category);
            }
            return message;
        };

        /**
         * Creates a plain object from a FileQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.FileQuery
         * @static
         * @param {api.FileQuery} message FileQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.includeFolders = false;
                object.includeProjectFiles = false;
                object.includeComprehendFiles = false;
                object.includeImageFiles = false;
                object.includeMaskFiles = false;
                object.fileId = "";
                object.limit = 0;
                object.dateFilter = null;
                object.approved = null;
                object.category = null;
                object.includeVectorFiles = false;
                object.includeAudioFiles = false;
                object.includeVideoFiles = false;
            }
            if (message.includeFolders != null && message.hasOwnProperty("includeFolders"))
                object.includeFolders = message.includeFolders;
            if (message.includeProjectFiles != null && message.hasOwnProperty("includeProjectFiles"))
                object.includeProjectFiles = message.includeProjectFiles;
            if (message.includeComprehendFiles != null && message.hasOwnProperty("includeComprehendFiles"))
                object.includeComprehendFiles = message.includeComprehendFiles;
            if (message.includeImageFiles != null && message.hasOwnProperty("includeImageFiles"))
                object.includeImageFiles = message.includeImageFiles;
            if (message.includeMaskFiles != null && message.hasOwnProperty("includeMaskFiles"))
                object.includeMaskFiles = message.includeMaskFiles;
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.dateFilter != null && message.hasOwnProperty("dateFilter"))
                object.dateFilter = $root.api.DateFilter.toObject(message.dateFilter, options);
            if (message.approved != null && message.hasOwnProperty("approved"))
                object.approved = $root.api.BoolFilter.toObject(message.approved, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = $root.api.StringFilter.toObject(message.category, options);
            if (message.includeVectorFiles != null && message.hasOwnProperty("includeVectorFiles"))
                object.includeVectorFiles = message.includeVectorFiles;
            if (message.includeAudioFiles != null && message.hasOwnProperty("includeAudioFiles"))
                object.includeAudioFiles = message.includeAudioFiles;
            if (message.includeVideoFiles != null && message.hasOwnProperty("includeVideoFiles"))
                object.includeVideoFiles = message.includeVideoFiles;
            return object;
        };

        /**
         * Converts this FileQuery to JSON.
         * @function toJSON
         * @memberof api.FileQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileQuery;
    })();

    api.GetFilesRequest = (function() {

        /**
         * Properties of a GetFilesRequest.
         * @memberof api
         * @interface IGetFilesRequest
         * @property {api.IFileQuery|null} [query] GetFilesRequest query
         * @property {string|null} [parentFileId] GetFilesRequest parentFileId
         */

        /**
         * Constructs a new GetFilesRequest.
         * @memberof api
         * @classdesc Represents a GetFilesRequest.
         * @implements IGetFilesRequest
         * @constructor
         * @param {api.IGetFilesRequest=} [properties] Properties to set
         */
        function GetFilesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetFilesRequest query.
         * @member {api.IFileQuery|null|undefined} query
         * @memberof api.GetFilesRequest
         * @instance
         */
        GetFilesRequest.prototype.query = null;

        /**
         * GetFilesRequest parentFileId.
         * @member {string} parentFileId
         * @memberof api.GetFilesRequest
         * @instance
         */
        GetFilesRequest.prototype.parentFileId = "";

        /**
         * Creates a new GetFilesRequest instance using the specified properties.
         * @function create
         * @memberof api.GetFilesRequest
         * @static
         * @param {api.IGetFilesRequest=} [properties] Properties to set
         * @returns {api.GetFilesRequest} GetFilesRequest instance
         */
        GetFilesRequest.create = function create(properties) {
            return new GetFilesRequest(properties);
        };

        /**
         * Encodes the specified GetFilesRequest message. Does not implicitly {@link api.GetFilesRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetFilesRequest
         * @static
         * @param {api.IGetFilesRequest} message GetFilesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFilesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parentFileId != null && Object.hasOwnProperty.call(message, "parentFileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parentFileId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.FileQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetFilesRequest message, length delimited. Does not implicitly {@link api.GetFilesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetFilesRequest
         * @static
         * @param {api.IGetFilesRequest} message GetFilesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFilesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetFilesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetFilesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetFilesRequest} GetFilesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFilesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetFilesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.query = $root.api.FileQuery.decode(reader, reader.uint32());
                    break;
                case 1:
                    message.parentFileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetFilesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetFilesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetFilesRequest} GetFilesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFilesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetFilesRequest message.
         * @function verify
         * @memberof api.GetFilesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetFilesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.FileQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            if (message.parentFileId != null && message.hasOwnProperty("parentFileId"))
                if (!$util.isString(message.parentFileId))
                    return "parentFileId: string expected";
            return null;
        };

        /**
         * Creates a GetFilesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetFilesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetFilesRequest} GetFilesRequest
         */
        GetFilesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetFilesRequest)
                return object;
            let message = new $root.api.GetFilesRequest();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetFilesRequest.query: object expected");
                message.query = $root.api.FileQuery.fromObject(object.query);
            }
            if (object.parentFileId != null)
                message.parentFileId = String(object.parentFileId);
            return message;
        };

        /**
         * Creates a plain object from a GetFilesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetFilesRequest
         * @static
         * @param {api.GetFilesRequest} message GetFilesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetFilesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.parentFileId = "";
                object.query = null;
            }
            if (message.parentFileId != null && message.hasOwnProperty("parentFileId"))
                object.parentFileId = message.parentFileId;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.FileQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetFilesRequest to JSON.
         * @function toJSON
         * @memberof api.GetFilesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetFilesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetFilesRequest;
    })();

    /**
     * FileType enum.
     * @name api.FileType
     * @enum {number}
     * @property {number} Folder=0 Folder value
     * @property {number} ProjectFile=1 ProjectFile value
     * @property {number} ComprehendFile=2 ComprehendFile value
     * @property {number} ImageFile=3 ImageFile value
     * @property {number} MaskFile=4 MaskFile value
     * @property {number} VectorFile=5 VectorFile value
     * @property {number} AudioFile=6 AudioFile value
     * @property {number} VideoFile=7 VideoFile value
     */
    api.FileType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Folder"] = 0;
        values[valuesById[1] = "ProjectFile"] = 1;
        values[valuesById[2] = "ComprehendFile"] = 2;
        values[valuesById[3] = "ImageFile"] = 3;
        values[valuesById[4] = "MaskFile"] = 4;
        values[valuesById[5] = "VectorFile"] = 5;
        values[valuesById[6] = "AudioFile"] = 6;
        values[valuesById[7] = "VideoFile"] = 7;
        return values;
    })();

    api.File = (function() {

        /**
         * Properties of a File.
         * @memberof api
         * @interface IFile
         * @property {string|null} [fileId] File fileId
         * @property {string|null} [name] File name
         * @property {api.FileType|null} [fileType] File fileType
         * @property {number|Long|null} [createDate] File createDate
         * @property {number|Long|null} [updateDate] File updateDate
         * @property {string|null} [fileUrl] File fileUrl
         * @property {string|null} [thumbnailUrl] File thumbnailUrl
         * @property {number|null} [width] File width
         * @property {number|null} [height] File height
         * @property {boolean|null} [approved] File approved
         */

        /**
         * Constructs a new File.
         * @memberof api
         * @classdesc Represents a File.
         * @implements IFile
         * @constructor
         * @param {api.IFile=} [properties] Properties to set
         */
        function File(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * File fileId.
         * @member {string} fileId
         * @memberof api.File
         * @instance
         */
        File.prototype.fileId = "";

        /**
         * File name.
         * @member {string} name
         * @memberof api.File
         * @instance
         */
        File.prototype.name = "";

        /**
         * File fileType.
         * @member {api.FileType} fileType
         * @memberof api.File
         * @instance
         */
        File.prototype.fileType = 0;

        /**
         * File createDate.
         * @member {number|Long} createDate
         * @memberof api.File
         * @instance
         */
        File.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * File updateDate.
         * @member {number|Long} updateDate
         * @memberof api.File
         * @instance
         */
        File.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * File fileUrl.
         * @member {string} fileUrl
         * @memberof api.File
         * @instance
         */
        File.prototype.fileUrl = "";

        /**
         * File thumbnailUrl.
         * @member {string} thumbnailUrl
         * @memberof api.File
         * @instance
         */
        File.prototype.thumbnailUrl = "";

        /**
         * File width.
         * @member {number} width
         * @memberof api.File
         * @instance
         */
        File.prototype.width = 0;

        /**
         * File height.
         * @member {number} height
         * @memberof api.File
         * @instance
         */
        File.prototype.height = 0;

        /**
         * File approved.
         * @member {boolean} approved
         * @memberof api.File
         * @instance
         */
        File.prototype.approved = false;

        /**
         * Creates a new File instance using the specified properties.
         * @function create
         * @memberof api.File
         * @static
         * @param {api.IFile=} [properties] Properties to set
         * @returns {api.File} File instance
         */
        File.create = function create(properties) {
            return new File(properties);
        };

        /**
         * Encodes the specified File message. Does not implicitly {@link api.File.verify|verify} messages.
         * @function encode
         * @memberof api.File
         * @static
         * @param {api.IFile} message File message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        File.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.fileType != null && Object.hasOwnProperty.call(message, "fileType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.fileType);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.updateDate);
            if (message.fileUrl != null && Object.hasOwnProperty.call(message, "fileUrl"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.fileUrl);
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.thumbnailUrl);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.height);
            if (message.approved != null && Object.hasOwnProperty.call(message, "approved"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.approved);
            return writer;
        };

        /**
         * Encodes the specified File message, length delimited. Does not implicitly {@link api.File.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.File
         * @static
         * @param {api.IFile} message File message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        File.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a File message from the specified reader or buffer.
         * @function decode
         * @memberof api.File
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.File} File
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        File.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.File();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.fileType = reader.int32();
                    break;
                case 4:
                    message.createDate = reader.int64();
                    break;
                case 5:
                    message.updateDate = reader.int64();
                    break;
                case 6:
                    message.fileUrl = reader.string();
                    break;
                case 7:
                    message.thumbnailUrl = reader.string();
                    break;
                case 8:
                    message.width = reader.int32();
                    break;
                case 9:
                    message.height = reader.int32();
                    break;
                case 10:
                    message.approved = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a File message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.File
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.File} File
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        File.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a File message.
         * @function verify
         * @memberof api.File
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        File.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.fileType != null && message.hasOwnProperty("fileType"))
                switch (message.fileType) {
                default:
                    return "fileType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            if (message.fileUrl != null && message.hasOwnProperty("fileUrl"))
                if (!$util.isString(message.fileUrl))
                    return "fileUrl: string expected";
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                if (!$util.isString(message.thumbnailUrl))
                    return "thumbnailUrl: string expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.approved != null && message.hasOwnProperty("approved"))
                if (typeof message.approved !== "boolean")
                    return "approved: boolean expected";
            return null;
        };

        /**
         * Creates a File message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.File
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.File} File
         */
        File.fromObject = function fromObject(object) {
            if (object instanceof $root.api.File)
                return object;
            let message = new $root.api.File();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.name != null)
                message.name = String(object.name);
            switch (object.fileType) {
            case "Folder":
            case 0:
                message.fileType = 0;
                break;
            case "ProjectFile":
            case 1:
                message.fileType = 1;
                break;
            case "ComprehendFile":
            case 2:
                message.fileType = 2;
                break;
            case "ImageFile":
            case 3:
                message.fileType = 3;
                break;
            case "MaskFile":
            case 4:
                message.fileType = 4;
                break;
            case "VectorFile":
            case 5:
                message.fileType = 5;
                break;
            case "AudioFile":
            case 6:
                message.fileType = 6;
                break;
            case "VideoFile":
            case 7:
                message.fileType = 7;
                break;
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            if (object.fileUrl != null)
                message.fileUrl = String(object.fileUrl);
            if (object.thumbnailUrl != null)
                message.thumbnailUrl = String(object.thumbnailUrl);
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            if (object.approved != null)
                message.approved = Boolean(object.approved);
            return message;
        };

        /**
         * Creates a plain object from a File message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.File
         * @static
         * @param {api.File} message File
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        File.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.name = "";
                object.fileType = options.enums === String ? "Folder" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                object.fileUrl = "";
                object.thumbnailUrl = "";
                object.width = 0;
                object.height = 0;
                object.approved = false;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.fileType != null && message.hasOwnProperty("fileType"))
                object.fileType = options.enums === String ? $root.api.FileType[message.fileType] : message.fileType;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.fileUrl != null && message.hasOwnProperty("fileUrl"))
                object.fileUrl = message.fileUrl;
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = message.thumbnailUrl;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.approved != null && message.hasOwnProperty("approved"))
                object.approved = message.approved;
            return object;
        };

        /**
         * Converts this File to JSON.
         * @function toJSON
         * @memberof api.File
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        File.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return File;
    })();

    api.GetFilesResponse = (function() {

        /**
         * Properties of a GetFilesResponse.
         * @memberof api
         * @interface IGetFilesResponse
         * @property {Array.<api.IFile>|null} [files] GetFilesResponse files
         */

        /**
         * Constructs a new GetFilesResponse.
         * @memberof api
         * @classdesc Represents a GetFilesResponse.
         * @implements IGetFilesResponse
         * @constructor
         * @param {api.IGetFilesResponse=} [properties] Properties to set
         */
        function GetFilesResponse(properties) {
            this.files = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetFilesResponse files.
         * @member {Array.<api.IFile>} files
         * @memberof api.GetFilesResponse
         * @instance
         */
        GetFilesResponse.prototype.files = $util.emptyArray;

        /**
         * Creates a new GetFilesResponse instance using the specified properties.
         * @function create
         * @memberof api.GetFilesResponse
         * @static
         * @param {api.IGetFilesResponse=} [properties] Properties to set
         * @returns {api.GetFilesResponse} GetFilesResponse instance
         */
        GetFilesResponse.create = function create(properties) {
            return new GetFilesResponse(properties);
        };

        /**
         * Encodes the specified GetFilesResponse message. Does not implicitly {@link api.GetFilesResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetFilesResponse
         * @static
         * @param {api.IGetFilesResponse} message GetFilesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFilesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.files != null && message.files.length)
                for (let i = 0; i < message.files.length; ++i)
                    $root.api.File.encode(message.files[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetFilesResponse message, length delimited. Does not implicitly {@link api.GetFilesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetFilesResponse
         * @static
         * @param {api.IGetFilesResponse} message GetFilesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFilesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetFilesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetFilesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetFilesResponse} GetFilesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFilesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetFilesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.files && message.files.length))
                        message.files = [];
                    message.files.push($root.api.File.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetFilesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetFilesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetFilesResponse} GetFilesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFilesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetFilesResponse message.
         * @function verify
         * @memberof api.GetFilesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetFilesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.files != null && message.hasOwnProperty("files")) {
                if (!Array.isArray(message.files))
                    return "files: array expected";
                for (let i = 0; i < message.files.length; ++i) {
                    let error = $root.api.File.verify(message.files[i]);
                    if (error)
                        return "files." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetFilesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetFilesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetFilesResponse} GetFilesResponse
         */
        GetFilesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetFilesResponse)
                return object;
            let message = new $root.api.GetFilesResponse();
            if (object.files) {
                if (!Array.isArray(object.files))
                    throw TypeError(".api.GetFilesResponse.files: array expected");
                message.files = [];
                for (let i = 0; i < object.files.length; ++i) {
                    if (typeof object.files[i] !== "object")
                        throw TypeError(".api.GetFilesResponse.files: object expected");
                    message.files[i] = $root.api.File.fromObject(object.files[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetFilesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetFilesResponse
         * @static
         * @param {api.GetFilesResponse} message GetFilesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetFilesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.files = [];
            if (message.files && message.files.length) {
                object.files = [];
                for (let j = 0; j < message.files.length; ++j)
                    object.files[j] = $root.api.File.toObject(message.files[j], options);
            }
            return object;
        };

        /**
         * Converts this GetFilesResponse to JSON.
         * @function toJSON
         * @memberof api.GetFilesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetFilesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetFilesResponse;
    })();

    api.DeleteFileRequest = (function() {

        /**
         * Properties of a DeleteFileRequest.
         * @memberof api
         * @interface IDeleteFileRequest
         * @property {string|null} [fileId] DeleteFileRequest fileId
         */

        /**
         * Constructs a new DeleteFileRequest.
         * @memberof api
         * @classdesc Represents a DeleteFileRequest.
         * @implements IDeleteFileRequest
         * @constructor
         * @param {api.IDeleteFileRequest=} [properties] Properties to set
         */
        function DeleteFileRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteFileRequest fileId.
         * @member {string} fileId
         * @memberof api.DeleteFileRequest
         * @instance
         */
        DeleteFileRequest.prototype.fileId = "";

        /**
         * Creates a new DeleteFileRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteFileRequest
         * @static
         * @param {api.IDeleteFileRequest=} [properties] Properties to set
         * @returns {api.DeleteFileRequest} DeleteFileRequest instance
         */
        DeleteFileRequest.create = function create(properties) {
            return new DeleteFileRequest(properties);
        };

        /**
         * Encodes the specified DeleteFileRequest message. Does not implicitly {@link api.DeleteFileRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteFileRequest
         * @static
         * @param {api.IDeleteFileRequest} message DeleteFileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified DeleteFileRequest message, length delimited. Does not implicitly {@link api.DeleteFileRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteFileRequest
         * @static
         * @param {api.IDeleteFileRequest} message DeleteFileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFileRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteFileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteFileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteFileRequest} DeleteFileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteFileRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteFileRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteFileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteFileRequest} DeleteFileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFileRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteFileRequest message.
         * @function verify
         * @memberof api.DeleteFileRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteFileRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            return null;
        };

        /**
         * Creates a DeleteFileRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteFileRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteFileRequest} DeleteFileRequest
         */
        DeleteFileRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteFileRequest)
                return object;
            let message = new $root.api.DeleteFileRequest();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            return message;
        };

        /**
         * Creates a plain object from a DeleteFileRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteFileRequest
         * @static
         * @param {api.DeleteFileRequest} message DeleteFileRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteFileRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileId = "";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this DeleteFileRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteFileRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteFileRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteFileRequest;
    })();

    api.DeleteFileResponse = (function() {

        /**
         * Properties of a DeleteFileResponse.
         * @memberof api
         * @interface IDeleteFileResponse
         */

        /**
         * Constructs a new DeleteFileResponse.
         * @memberof api
         * @classdesc Represents a DeleteFileResponse.
         * @implements IDeleteFileResponse
         * @constructor
         * @param {api.IDeleteFileResponse=} [properties] Properties to set
         */
        function DeleteFileResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteFileResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteFileResponse
         * @static
         * @param {api.IDeleteFileResponse=} [properties] Properties to set
         * @returns {api.DeleteFileResponse} DeleteFileResponse instance
         */
        DeleteFileResponse.create = function create(properties) {
            return new DeleteFileResponse(properties);
        };

        /**
         * Encodes the specified DeleteFileResponse message. Does not implicitly {@link api.DeleteFileResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteFileResponse
         * @static
         * @param {api.IDeleteFileResponse} message DeleteFileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFileResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteFileResponse message, length delimited. Does not implicitly {@link api.DeleteFileResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteFileResponse
         * @static
         * @param {api.IDeleteFileResponse} message DeleteFileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFileResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteFileResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteFileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteFileResponse} DeleteFileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFileResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteFileResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteFileResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteFileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteFileResponse} DeleteFileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFileResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteFileResponse message.
         * @function verify
         * @memberof api.DeleteFileResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteFileResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteFileResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteFileResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteFileResponse} DeleteFileResponse
         */
        DeleteFileResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteFileResponse)
                return object;
            return new $root.api.DeleteFileResponse();
        };

        /**
         * Creates a plain object from a DeleteFileResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteFileResponse
         * @static
         * @param {api.DeleteFileResponse} message DeleteFileResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteFileResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteFileResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteFileResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteFileResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteFileResponse;
    })();

    api.CreateFolderRequest = (function() {

        /**
         * Properties of a CreateFolderRequest.
         * @memberof api
         * @interface ICreateFolderRequest
         * @property {string|null} [parentFileId] CreateFolderRequest parentFileId
         * @property {string|null} [name] CreateFolderRequest name
         */

        /**
         * Constructs a new CreateFolderRequest.
         * @memberof api
         * @classdesc Represents a CreateFolderRequest.
         * @implements ICreateFolderRequest
         * @constructor
         * @param {api.ICreateFolderRequest=} [properties] Properties to set
         */
        function CreateFolderRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateFolderRequest parentFileId.
         * @member {string} parentFileId
         * @memberof api.CreateFolderRequest
         * @instance
         */
        CreateFolderRequest.prototype.parentFileId = "";

        /**
         * CreateFolderRequest name.
         * @member {string} name
         * @memberof api.CreateFolderRequest
         * @instance
         */
        CreateFolderRequest.prototype.name = "";

        /**
         * Creates a new CreateFolderRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateFolderRequest
         * @static
         * @param {api.ICreateFolderRequest=} [properties] Properties to set
         * @returns {api.CreateFolderRequest} CreateFolderRequest instance
         */
        CreateFolderRequest.create = function create(properties) {
            return new CreateFolderRequest(properties);
        };

        /**
         * Encodes the specified CreateFolderRequest message. Does not implicitly {@link api.CreateFolderRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateFolderRequest
         * @static
         * @param {api.ICreateFolderRequest} message CreateFolderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateFolderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parentFileId != null && Object.hasOwnProperty.call(message, "parentFileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parentFileId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified CreateFolderRequest message, length delimited. Does not implicitly {@link api.CreateFolderRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateFolderRequest
         * @static
         * @param {api.ICreateFolderRequest} message CreateFolderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateFolderRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateFolderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateFolderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateFolderRequest} CreateFolderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateFolderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateFolderRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.parentFileId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateFolderRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateFolderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateFolderRequest} CreateFolderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateFolderRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateFolderRequest message.
         * @function verify
         * @memberof api.CreateFolderRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateFolderRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.parentFileId != null && message.hasOwnProperty("parentFileId"))
                if (!$util.isString(message.parentFileId))
                    return "parentFileId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a CreateFolderRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateFolderRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateFolderRequest} CreateFolderRequest
         */
        CreateFolderRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateFolderRequest)
                return object;
            let message = new $root.api.CreateFolderRequest();
            if (object.parentFileId != null)
                message.parentFileId = String(object.parentFileId);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a CreateFolderRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateFolderRequest
         * @static
         * @param {api.CreateFolderRequest} message CreateFolderRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateFolderRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.parentFileId = "";
                object.name = "";
            }
            if (message.parentFileId != null && message.hasOwnProperty("parentFileId"))
                object.parentFileId = message.parentFileId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this CreateFolderRequest to JSON.
         * @function toJSON
         * @memberof api.CreateFolderRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateFolderRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateFolderRequest;
    })();

    api.CreateFolderResponse = (function() {

        /**
         * Properties of a CreateFolderResponse.
         * @memberof api
         * @interface ICreateFolderResponse
         * @property {string|null} [fileId] CreateFolderResponse fileId
         */

        /**
         * Constructs a new CreateFolderResponse.
         * @memberof api
         * @classdesc Represents a CreateFolderResponse.
         * @implements ICreateFolderResponse
         * @constructor
         * @param {api.ICreateFolderResponse=} [properties] Properties to set
         */
        function CreateFolderResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateFolderResponse fileId.
         * @member {string} fileId
         * @memberof api.CreateFolderResponse
         * @instance
         */
        CreateFolderResponse.prototype.fileId = "";

        /**
         * Creates a new CreateFolderResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateFolderResponse
         * @static
         * @param {api.ICreateFolderResponse=} [properties] Properties to set
         * @returns {api.CreateFolderResponse} CreateFolderResponse instance
         */
        CreateFolderResponse.create = function create(properties) {
            return new CreateFolderResponse(properties);
        };

        /**
         * Encodes the specified CreateFolderResponse message. Does not implicitly {@link api.CreateFolderResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateFolderResponse
         * @static
         * @param {api.ICreateFolderResponse} message CreateFolderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateFolderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified CreateFolderResponse message, length delimited. Does not implicitly {@link api.CreateFolderResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateFolderResponse
         * @static
         * @param {api.ICreateFolderResponse} message CreateFolderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateFolderResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateFolderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateFolderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateFolderResponse} CreateFolderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateFolderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateFolderResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateFolderResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateFolderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateFolderResponse} CreateFolderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateFolderResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateFolderResponse message.
         * @function verify
         * @memberof api.CreateFolderResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateFolderResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            return null;
        };

        /**
         * Creates a CreateFolderResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateFolderResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateFolderResponse} CreateFolderResponse
         */
        CreateFolderResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateFolderResponse)
                return object;
            let message = new $root.api.CreateFolderResponse();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            return message;
        };

        /**
         * Creates a plain object from a CreateFolderResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateFolderResponse
         * @static
         * @param {api.CreateFolderResponse} message CreateFolderResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateFolderResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileId = "";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this CreateFolderResponse to JSON.
         * @function toJSON
         * @memberof api.CreateFolderResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateFolderResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateFolderResponse;
    })();

    api.CreateProjectRequest = (function() {

        /**
         * Properties of a CreateProjectRequest.
         * @memberof api
         * @interface ICreateProjectRequest
         * @property {string|null} [parentFileId] CreateProjectRequest parentFileId
         * @property {string|null} [name] CreateProjectRequest name
         * @property {api.IProjectInfo|null} [projectInfo] CreateProjectRequest projectInfo
         */

        /**
         * Constructs a new CreateProjectRequest.
         * @memberof api
         * @classdesc Represents a CreateProjectRequest.
         * @implements ICreateProjectRequest
         * @constructor
         * @param {api.ICreateProjectRequest=} [properties] Properties to set
         */
        function CreateProjectRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateProjectRequest parentFileId.
         * @member {string} parentFileId
         * @memberof api.CreateProjectRequest
         * @instance
         */
        CreateProjectRequest.prototype.parentFileId = "";

        /**
         * CreateProjectRequest name.
         * @member {string} name
         * @memberof api.CreateProjectRequest
         * @instance
         */
        CreateProjectRequest.prototype.name = "";

        /**
         * CreateProjectRequest projectInfo.
         * @member {api.IProjectInfo|null|undefined} projectInfo
         * @memberof api.CreateProjectRequest
         * @instance
         */
        CreateProjectRequest.prototype.projectInfo = null;

        /**
         * Creates a new CreateProjectRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateProjectRequest
         * @static
         * @param {api.ICreateProjectRequest=} [properties] Properties to set
         * @returns {api.CreateProjectRequest} CreateProjectRequest instance
         */
        CreateProjectRequest.create = function create(properties) {
            return new CreateProjectRequest(properties);
        };

        /**
         * Encodes the specified CreateProjectRequest message. Does not implicitly {@link api.CreateProjectRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateProjectRequest
         * @static
         * @param {api.ICreateProjectRequest} message CreateProjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateProjectRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parentFileId != null && Object.hasOwnProperty.call(message, "parentFileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parentFileId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.projectInfo != null && Object.hasOwnProperty.call(message, "projectInfo"))
                $root.api.ProjectInfo.encode(message.projectInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateProjectRequest message, length delimited. Does not implicitly {@link api.CreateProjectRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateProjectRequest
         * @static
         * @param {api.ICreateProjectRequest} message CreateProjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateProjectRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateProjectRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateProjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateProjectRequest} CreateProjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateProjectRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateProjectRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.parentFileId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.projectInfo = $root.api.ProjectInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateProjectRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateProjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateProjectRequest} CreateProjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateProjectRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateProjectRequest message.
         * @function verify
         * @memberof api.CreateProjectRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateProjectRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.parentFileId != null && message.hasOwnProperty("parentFileId"))
                if (!$util.isString(message.parentFileId))
                    return "parentFileId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.projectInfo != null && message.hasOwnProperty("projectInfo")) {
                let error = $root.api.ProjectInfo.verify(message.projectInfo);
                if (error)
                    return "projectInfo." + error;
            }
            return null;
        };

        /**
         * Creates a CreateProjectRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateProjectRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateProjectRequest} CreateProjectRequest
         */
        CreateProjectRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateProjectRequest)
                return object;
            let message = new $root.api.CreateProjectRequest();
            if (object.parentFileId != null)
                message.parentFileId = String(object.parentFileId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.projectInfo != null) {
                if (typeof object.projectInfo !== "object")
                    throw TypeError(".api.CreateProjectRequest.projectInfo: object expected");
                message.projectInfo = $root.api.ProjectInfo.fromObject(object.projectInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateProjectRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateProjectRequest
         * @static
         * @param {api.CreateProjectRequest} message CreateProjectRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateProjectRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.parentFileId = "";
                object.name = "";
                object.projectInfo = null;
            }
            if (message.parentFileId != null && message.hasOwnProperty("parentFileId"))
                object.parentFileId = message.parentFileId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.projectInfo != null && message.hasOwnProperty("projectInfo"))
                object.projectInfo = $root.api.ProjectInfo.toObject(message.projectInfo, options);
            return object;
        };

        /**
         * Converts this CreateProjectRequest to JSON.
         * @function toJSON
         * @memberof api.CreateProjectRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateProjectRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateProjectRequest;
    })();

    api.CreateProjectResponse = (function() {

        /**
         * Properties of a CreateProjectResponse.
         * @memberof api
         * @interface ICreateProjectResponse
         * @property {string|null} [fileId] CreateProjectResponse fileId
         */

        /**
         * Constructs a new CreateProjectResponse.
         * @memberof api
         * @classdesc Represents a CreateProjectResponse.
         * @implements ICreateProjectResponse
         * @constructor
         * @param {api.ICreateProjectResponse=} [properties] Properties to set
         */
        function CreateProjectResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateProjectResponse fileId.
         * @member {string} fileId
         * @memberof api.CreateProjectResponse
         * @instance
         */
        CreateProjectResponse.prototype.fileId = "";

        /**
         * Creates a new CreateProjectResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateProjectResponse
         * @static
         * @param {api.ICreateProjectResponse=} [properties] Properties to set
         * @returns {api.CreateProjectResponse} CreateProjectResponse instance
         */
        CreateProjectResponse.create = function create(properties) {
            return new CreateProjectResponse(properties);
        };

        /**
         * Encodes the specified CreateProjectResponse message. Does not implicitly {@link api.CreateProjectResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateProjectResponse
         * @static
         * @param {api.ICreateProjectResponse} message CreateProjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateProjectResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified CreateProjectResponse message, length delimited. Does not implicitly {@link api.CreateProjectResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateProjectResponse
         * @static
         * @param {api.ICreateProjectResponse} message CreateProjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateProjectResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateProjectResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateProjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateProjectResponse} CreateProjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateProjectResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateProjectResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateProjectResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateProjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateProjectResponse} CreateProjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateProjectResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateProjectResponse message.
         * @function verify
         * @memberof api.CreateProjectResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateProjectResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            return null;
        };

        /**
         * Creates a CreateProjectResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateProjectResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateProjectResponse} CreateProjectResponse
         */
        CreateProjectResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateProjectResponse)
                return object;
            let message = new $root.api.CreateProjectResponse();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            return message;
        };

        /**
         * Creates a plain object from a CreateProjectResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateProjectResponse
         * @static
         * @param {api.CreateProjectResponse} message CreateProjectResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateProjectResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileId = "";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this CreateProjectResponse to JSON.
         * @function toJSON
         * @memberof api.CreateProjectResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateProjectResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateProjectResponse;
    })();

    api.CreateComprehendRequest = (function() {

        /**
         * Properties of a CreateComprehendRequest.
         * @memberof api
         * @interface ICreateComprehendRequest
         * @property {string|null} [parentFileId] CreateComprehendRequest parentFileId
         * @property {string|null} [url] CreateComprehendRequest url
         */

        /**
         * Constructs a new CreateComprehendRequest.
         * @memberof api
         * @classdesc Represents a CreateComprehendRequest.
         * @implements ICreateComprehendRequest
         * @constructor
         * @param {api.ICreateComprehendRequest=} [properties] Properties to set
         */
        function CreateComprehendRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateComprehendRequest parentFileId.
         * @member {string} parentFileId
         * @memberof api.CreateComprehendRequest
         * @instance
         */
        CreateComprehendRequest.prototype.parentFileId = "";

        /**
         * CreateComprehendRequest url.
         * @member {string} url
         * @memberof api.CreateComprehendRequest
         * @instance
         */
        CreateComprehendRequest.prototype.url = "";

        /**
         * Creates a new CreateComprehendRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateComprehendRequest
         * @static
         * @param {api.ICreateComprehendRequest=} [properties] Properties to set
         * @returns {api.CreateComprehendRequest} CreateComprehendRequest instance
         */
        CreateComprehendRequest.create = function create(properties) {
            return new CreateComprehendRequest(properties);
        };

        /**
         * Encodes the specified CreateComprehendRequest message. Does not implicitly {@link api.CreateComprehendRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateComprehendRequest
         * @static
         * @param {api.ICreateComprehendRequest} message CreateComprehendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateComprehendRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parentFileId != null && Object.hasOwnProperty.call(message, "parentFileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parentFileId);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            return writer;
        };

        /**
         * Encodes the specified CreateComprehendRequest message, length delimited. Does not implicitly {@link api.CreateComprehendRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateComprehendRequest
         * @static
         * @param {api.ICreateComprehendRequest} message CreateComprehendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateComprehendRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateComprehendRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateComprehendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateComprehendRequest} CreateComprehendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateComprehendRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateComprehendRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.parentFileId = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateComprehendRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateComprehendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateComprehendRequest} CreateComprehendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateComprehendRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateComprehendRequest message.
         * @function verify
         * @memberof api.CreateComprehendRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateComprehendRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.parentFileId != null && message.hasOwnProperty("parentFileId"))
                if (!$util.isString(message.parentFileId))
                    return "parentFileId: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            return null;
        };

        /**
         * Creates a CreateComprehendRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateComprehendRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateComprehendRequest} CreateComprehendRequest
         */
        CreateComprehendRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateComprehendRequest)
                return object;
            let message = new $root.api.CreateComprehendRequest();
            if (object.parentFileId != null)
                message.parentFileId = String(object.parentFileId);
            if (object.url != null)
                message.url = String(object.url);
            return message;
        };

        /**
         * Creates a plain object from a CreateComprehendRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateComprehendRequest
         * @static
         * @param {api.CreateComprehendRequest} message CreateComprehendRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateComprehendRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.parentFileId = "";
                object.url = "";
            }
            if (message.parentFileId != null && message.hasOwnProperty("parentFileId"))
                object.parentFileId = message.parentFileId;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            return object;
        };

        /**
         * Converts this CreateComprehendRequest to JSON.
         * @function toJSON
         * @memberof api.CreateComprehendRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateComprehendRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateComprehendRequest;
    })();

    api.CreateComprehendResponse = (function() {

        /**
         * Properties of a CreateComprehendResponse.
         * @memberof api
         * @interface ICreateComprehendResponse
         * @property {string|null} [taskId] CreateComprehendResponse taskId
         */

        /**
         * Constructs a new CreateComprehendResponse.
         * @memberof api
         * @classdesc Represents a CreateComprehendResponse.
         * @implements ICreateComprehendResponse
         * @constructor
         * @param {api.ICreateComprehendResponse=} [properties] Properties to set
         */
        function CreateComprehendResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateComprehendResponse taskId.
         * @member {string} taskId
         * @memberof api.CreateComprehendResponse
         * @instance
         */
        CreateComprehendResponse.prototype.taskId = "";

        /**
         * Creates a new CreateComprehendResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateComprehendResponse
         * @static
         * @param {api.ICreateComprehendResponse=} [properties] Properties to set
         * @returns {api.CreateComprehendResponse} CreateComprehendResponse instance
         */
        CreateComprehendResponse.create = function create(properties) {
            return new CreateComprehendResponse(properties);
        };

        /**
         * Encodes the specified CreateComprehendResponse message. Does not implicitly {@link api.CreateComprehendResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateComprehendResponse
         * @static
         * @param {api.ICreateComprehendResponse} message CreateComprehendResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateComprehendResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskId);
            return writer;
        };

        /**
         * Encodes the specified CreateComprehendResponse message, length delimited. Does not implicitly {@link api.CreateComprehendResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateComprehendResponse
         * @static
         * @param {api.ICreateComprehendResponse} message CreateComprehendResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateComprehendResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateComprehendResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateComprehendResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateComprehendResponse} CreateComprehendResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateComprehendResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateComprehendResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.taskId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateComprehendResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateComprehendResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateComprehendResponse} CreateComprehendResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateComprehendResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateComprehendResponse message.
         * @function verify
         * @memberof api.CreateComprehendResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateComprehendResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.taskId != null && message.hasOwnProperty("taskId"))
                if (!$util.isString(message.taskId))
                    return "taskId: string expected";
            return null;
        };

        /**
         * Creates a CreateComprehendResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateComprehendResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateComprehendResponse} CreateComprehendResponse
         */
        CreateComprehendResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateComprehendResponse)
                return object;
            let message = new $root.api.CreateComprehendResponse();
            if (object.taskId != null)
                message.taskId = String(object.taskId);
            return message;
        };

        /**
         * Creates a plain object from a CreateComprehendResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateComprehendResponse
         * @static
         * @param {api.CreateComprehendResponse} message CreateComprehendResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateComprehendResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.taskId = "";
            if (message.taskId != null && message.hasOwnProperty("taskId"))
                object.taskId = message.taskId;
            return object;
        };

        /**
         * Converts this CreateComprehendResponse to JSON.
         * @function toJSON
         * @memberof api.CreateComprehendResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateComprehendResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateComprehendResponse;
    })();

    api.CheckComprehendTaskStatusRequest = (function() {

        /**
         * Properties of a CheckComprehendTaskStatusRequest.
         * @memberof api
         * @interface ICheckComprehendTaskStatusRequest
         * @property {string|null} [taskId] CheckComprehendTaskStatusRequest taskId
         */

        /**
         * Constructs a new CheckComprehendTaskStatusRequest.
         * @memberof api
         * @classdesc Represents a CheckComprehendTaskStatusRequest.
         * @implements ICheckComprehendTaskStatusRequest
         * @constructor
         * @param {api.ICheckComprehendTaskStatusRequest=} [properties] Properties to set
         */
        function CheckComprehendTaskStatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CheckComprehendTaskStatusRequest taskId.
         * @member {string} taskId
         * @memberof api.CheckComprehendTaskStatusRequest
         * @instance
         */
        CheckComprehendTaskStatusRequest.prototype.taskId = "";

        /**
         * Creates a new CheckComprehendTaskStatusRequest instance using the specified properties.
         * @function create
         * @memberof api.CheckComprehendTaskStatusRequest
         * @static
         * @param {api.ICheckComprehendTaskStatusRequest=} [properties] Properties to set
         * @returns {api.CheckComprehendTaskStatusRequest} CheckComprehendTaskStatusRequest instance
         */
        CheckComprehendTaskStatusRequest.create = function create(properties) {
            return new CheckComprehendTaskStatusRequest(properties);
        };

        /**
         * Encodes the specified CheckComprehendTaskStatusRequest message. Does not implicitly {@link api.CheckComprehendTaskStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CheckComprehendTaskStatusRequest
         * @static
         * @param {api.ICheckComprehendTaskStatusRequest} message CheckComprehendTaskStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckComprehendTaskStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskId);
            return writer;
        };

        /**
         * Encodes the specified CheckComprehendTaskStatusRequest message, length delimited. Does not implicitly {@link api.CheckComprehendTaskStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CheckComprehendTaskStatusRequest
         * @static
         * @param {api.ICheckComprehendTaskStatusRequest} message CheckComprehendTaskStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckComprehendTaskStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CheckComprehendTaskStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CheckComprehendTaskStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CheckComprehendTaskStatusRequest} CheckComprehendTaskStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckComprehendTaskStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CheckComprehendTaskStatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.taskId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CheckComprehendTaskStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CheckComprehendTaskStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CheckComprehendTaskStatusRequest} CheckComprehendTaskStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckComprehendTaskStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CheckComprehendTaskStatusRequest message.
         * @function verify
         * @memberof api.CheckComprehendTaskStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CheckComprehendTaskStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.taskId != null && message.hasOwnProperty("taskId"))
                if (!$util.isString(message.taskId))
                    return "taskId: string expected";
            return null;
        };

        /**
         * Creates a CheckComprehendTaskStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CheckComprehendTaskStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CheckComprehendTaskStatusRequest} CheckComprehendTaskStatusRequest
         */
        CheckComprehendTaskStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CheckComprehendTaskStatusRequest)
                return object;
            let message = new $root.api.CheckComprehendTaskStatusRequest();
            if (object.taskId != null)
                message.taskId = String(object.taskId);
            return message;
        };

        /**
         * Creates a plain object from a CheckComprehendTaskStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CheckComprehendTaskStatusRequest
         * @static
         * @param {api.CheckComprehendTaskStatusRequest} message CheckComprehendTaskStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CheckComprehendTaskStatusRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.taskId = "";
            if (message.taskId != null && message.hasOwnProperty("taskId"))
                object.taskId = message.taskId;
            return object;
        };

        /**
         * Converts this CheckComprehendTaskStatusRequest to JSON.
         * @function toJSON
         * @memberof api.CheckComprehendTaskStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CheckComprehendTaskStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CheckComprehendTaskStatusRequest;
    })();

    /**
     * ComprehendTaskStatus enum.
     * @name api.ComprehendTaskStatus
     * @enum {number}
     * @property {number} InProgress=0 InProgress value
     * @property {number} Completed=1 Completed value
     * @property {number} Failed=2 Failed value
     */
    api.ComprehendTaskStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "InProgress"] = 0;
        values[valuesById[1] = "Completed"] = 1;
        values[valuesById[2] = "Failed"] = 2;
        return values;
    })();

    api.CheckComprehendTaskStatusResponse = (function() {

        /**
         * Properties of a CheckComprehendTaskStatusResponse.
         * @memberof api
         * @interface ICheckComprehendTaskStatusResponse
         * @property {api.ComprehendTaskStatus|null} [status] CheckComprehendTaskStatusResponse status
         * @property {string|null} [errorMessage] CheckComprehendTaskStatusResponse errorMessage
         * @property {Array.<string>|null} [fileIds] CheckComprehendTaskStatusResponse fileIds
         * @property {number|Long|null} [tokensLeft] CheckComprehendTaskStatusResponse tokensLeft
         */

        /**
         * Constructs a new CheckComprehendTaskStatusResponse.
         * @memberof api
         * @classdesc Represents a CheckComprehendTaskStatusResponse.
         * @implements ICheckComprehendTaskStatusResponse
         * @constructor
         * @param {api.ICheckComprehendTaskStatusResponse=} [properties] Properties to set
         */
        function CheckComprehendTaskStatusResponse(properties) {
            this.fileIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CheckComprehendTaskStatusResponse status.
         * @member {api.ComprehendTaskStatus} status
         * @memberof api.CheckComprehendTaskStatusResponse
         * @instance
         */
        CheckComprehendTaskStatusResponse.prototype.status = 0;

        /**
         * CheckComprehendTaskStatusResponse errorMessage.
         * @member {string} errorMessage
         * @memberof api.CheckComprehendTaskStatusResponse
         * @instance
         */
        CheckComprehendTaskStatusResponse.prototype.errorMessage = "";

        /**
         * CheckComprehendTaskStatusResponse fileIds.
         * @member {Array.<string>} fileIds
         * @memberof api.CheckComprehendTaskStatusResponse
         * @instance
         */
        CheckComprehendTaskStatusResponse.prototype.fileIds = $util.emptyArray;

        /**
         * CheckComprehendTaskStatusResponse tokensLeft.
         * @member {number|Long} tokensLeft
         * @memberof api.CheckComprehendTaskStatusResponse
         * @instance
         */
        CheckComprehendTaskStatusResponse.prototype.tokensLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CheckComprehendTaskStatusResponse instance using the specified properties.
         * @function create
         * @memberof api.CheckComprehendTaskStatusResponse
         * @static
         * @param {api.ICheckComprehendTaskStatusResponse=} [properties] Properties to set
         * @returns {api.CheckComprehendTaskStatusResponse} CheckComprehendTaskStatusResponse instance
         */
        CheckComprehendTaskStatusResponse.create = function create(properties) {
            return new CheckComprehendTaskStatusResponse(properties);
        };

        /**
         * Encodes the specified CheckComprehendTaskStatusResponse message. Does not implicitly {@link api.CheckComprehendTaskStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CheckComprehendTaskStatusResponse
         * @static
         * @param {api.ICheckComprehendTaskStatusResponse} message CheckComprehendTaskStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckComprehendTaskStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
            if (message.fileIds != null && message.fileIds.length)
                for (let i = 0; i < message.fileIds.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.fileIds[i]);
            if (message.tokensLeft != null && Object.hasOwnProperty.call(message, "tokensLeft"))
                writer.uint32(/* id 14, wireType 0 =*/112).int64(message.tokensLeft);
            return writer;
        };

        /**
         * Encodes the specified CheckComprehendTaskStatusResponse message, length delimited. Does not implicitly {@link api.CheckComprehendTaskStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CheckComprehendTaskStatusResponse
         * @static
         * @param {api.ICheckComprehendTaskStatusResponse} message CheckComprehendTaskStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckComprehendTaskStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CheckComprehendTaskStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CheckComprehendTaskStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CheckComprehendTaskStatusResponse} CheckComprehendTaskStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckComprehendTaskStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CheckComprehendTaskStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.status = reader.int32();
                    break;
                case 2:
                    message.errorMessage = reader.string();
                    break;
                case 3:
                    if (!(message.fileIds && message.fileIds.length))
                        message.fileIds = [];
                    message.fileIds.push(reader.string());
                    break;
                case 14:
                    message.tokensLeft = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CheckComprehendTaskStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CheckComprehendTaskStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CheckComprehendTaskStatusResponse} CheckComprehendTaskStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckComprehendTaskStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CheckComprehendTaskStatusResponse message.
         * @function verify
         * @memberof api.CheckComprehendTaskStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CheckComprehendTaskStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.fileIds != null && message.hasOwnProperty("fileIds")) {
                if (!Array.isArray(message.fileIds))
                    return "fileIds: array expected";
                for (let i = 0; i < message.fileIds.length; ++i)
                    if (!$util.isString(message.fileIds[i]))
                        return "fileIds: string[] expected";
            }
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (!$util.isInteger(message.tokensLeft) && !(message.tokensLeft && $util.isInteger(message.tokensLeft.low) && $util.isInteger(message.tokensLeft.high)))
                    return "tokensLeft: integer|Long expected";
            return null;
        };

        /**
         * Creates a CheckComprehendTaskStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CheckComprehendTaskStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CheckComprehendTaskStatusResponse} CheckComprehendTaskStatusResponse
         */
        CheckComprehendTaskStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CheckComprehendTaskStatusResponse)
                return object;
            let message = new $root.api.CheckComprehendTaskStatusResponse();
            switch (object.status) {
            case "InProgress":
            case 0:
                message.status = 0;
                break;
            case "Completed":
            case 1:
                message.status = 1;
                break;
            case "Failed":
            case 2:
                message.status = 2;
                break;
            }
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.fileIds) {
                if (!Array.isArray(object.fileIds))
                    throw TypeError(".api.CheckComprehendTaskStatusResponse.fileIds: array expected");
                message.fileIds = [];
                for (let i = 0; i < object.fileIds.length; ++i)
                    message.fileIds[i] = String(object.fileIds[i]);
            }
            if (object.tokensLeft != null)
                if ($util.Long)
                    (message.tokensLeft = $util.Long.fromValue(object.tokensLeft)).unsigned = false;
                else if (typeof object.tokensLeft === "string")
                    message.tokensLeft = parseInt(object.tokensLeft, 10);
                else if (typeof object.tokensLeft === "number")
                    message.tokensLeft = object.tokensLeft;
                else if (typeof object.tokensLeft === "object")
                    message.tokensLeft = new $util.LongBits(object.tokensLeft.low >>> 0, object.tokensLeft.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CheckComprehendTaskStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CheckComprehendTaskStatusResponse
         * @static
         * @param {api.CheckComprehendTaskStatusResponse} message CheckComprehendTaskStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CheckComprehendTaskStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.fileIds = [];
            if (options.defaults) {
                object.status = options.enums === String ? "InProgress" : 0;
                object.errorMessage = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensLeft = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.ComprehendTaskStatus[message.status] : message.status;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.fileIds && message.fileIds.length) {
                object.fileIds = [];
                for (let j = 0; j < message.fileIds.length; ++j)
                    object.fileIds[j] = message.fileIds[j];
            }
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (typeof message.tokensLeft === "number")
                    object.tokensLeft = options.longs === String ? String(message.tokensLeft) : message.tokensLeft;
                else
                    object.tokensLeft = options.longs === String ? $util.Long.prototype.toString.call(message.tokensLeft) : options.longs === Number ? new $util.LongBits(message.tokensLeft.low >>> 0, message.tokensLeft.high >>> 0).toNumber() : message.tokensLeft;
            return object;
        };

        /**
         * Converts this CheckComprehendTaskStatusResponse to JSON.
         * @function toJSON
         * @memberof api.CheckComprehendTaskStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CheckComprehendTaskStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CheckComprehendTaskStatusResponse;
    })();

    api.DateFilter = (function() {

        /**
         * Properties of a DateFilter.
         * @memberof api
         * @interface IDateFilter
         * @property {boolean|null} [increasing] DateFilter increasing
         * @property {number|Long|null} [startDate] DateFilter startDate
         */

        /**
         * Constructs a new DateFilter.
         * @memberof api
         * @classdesc Represents a DateFilter.
         * @implements IDateFilter
         * @constructor
         * @param {api.IDateFilter=} [properties] Properties to set
         */
        function DateFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DateFilter increasing.
         * @member {boolean} increasing
         * @memberof api.DateFilter
         * @instance
         */
        DateFilter.prototype.increasing = false;

        /**
         * DateFilter startDate.
         * @member {number|Long} startDate
         * @memberof api.DateFilter
         * @instance
         */
        DateFilter.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new DateFilter instance using the specified properties.
         * @function create
         * @memberof api.DateFilter
         * @static
         * @param {api.IDateFilter=} [properties] Properties to set
         * @returns {api.DateFilter} DateFilter instance
         */
        DateFilter.create = function create(properties) {
            return new DateFilter(properties);
        };

        /**
         * Encodes the specified DateFilter message. Does not implicitly {@link api.DateFilter.verify|verify} messages.
         * @function encode
         * @memberof api.DateFilter
         * @static
         * @param {api.IDateFilter} message DateFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.increasing != null && Object.hasOwnProperty.call(message, "increasing"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.increasing);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startDate);
            return writer;
        };

        /**
         * Encodes the specified DateFilter message, length delimited. Does not implicitly {@link api.DateFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DateFilter
         * @static
         * @param {api.IDateFilter} message DateFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DateFilter message from the specified reader or buffer.
         * @function decode
         * @memberof api.DateFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DateFilter} DateFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DateFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.increasing = reader.bool();
                    break;
                case 2:
                    message.startDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DateFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DateFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DateFilter} DateFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DateFilter message.
         * @function verify
         * @memberof api.DateFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DateFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.increasing != null && message.hasOwnProperty("increasing"))
                if (typeof message.increasing !== "boolean")
                    return "increasing: boolean expected";
            if (message.startDate != null && message.hasOwnProperty("startDate"))
                if (!$util.isInteger(message.startDate) && !(message.startDate && $util.isInteger(message.startDate.low) && $util.isInteger(message.startDate.high)))
                    return "startDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a DateFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DateFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DateFilter} DateFilter
         */
        DateFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DateFilter)
                return object;
            let message = new $root.api.DateFilter();
            if (object.increasing != null)
                message.increasing = Boolean(object.increasing);
            if (object.startDate != null)
                if ($util.Long)
                    (message.startDate = $util.Long.fromValue(object.startDate)).unsigned = false;
                else if (typeof object.startDate === "string")
                    message.startDate = parseInt(object.startDate, 10);
                else if (typeof object.startDate === "number")
                    message.startDate = object.startDate;
                else if (typeof object.startDate === "object")
                    message.startDate = new $util.LongBits(object.startDate.low >>> 0, object.startDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a DateFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DateFilter
         * @static
         * @param {api.DateFilter} message DateFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DateFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.increasing = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.startDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startDate = options.longs === String ? "0" : 0;
            }
            if (message.increasing != null && message.hasOwnProperty("increasing"))
                object.increasing = message.increasing;
            if (message.startDate != null && message.hasOwnProperty("startDate"))
                if (typeof message.startDate === "number")
                    object.startDate = options.longs === String ? String(message.startDate) : message.startDate;
                else
                    object.startDate = options.longs === String ? $util.Long.prototype.toString.call(message.startDate) : options.longs === Number ? new $util.LongBits(message.startDate.low >>> 0, message.startDate.high >>> 0).toNumber() : message.startDate;
            return object;
        };

        /**
         * Converts this DateFilter to JSON.
         * @function toJSON
         * @memberof api.DateFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DateFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DateFilter;
    })();

    api.GenerateParameterQuery = (function() {

        /**
         * Properties of a GenerateParameterQuery.
         * @memberof api
         * @interface IGenerateParameterQuery
         */

        /**
         * Constructs a new GenerateParameterQuery.
         * @memberof api
         * @classdesc Represents a GenerateParameterQuery.
         * @implements IGenerateParameterQuery
         * @constructor
         * @param {api.IGenerateParameterQuery=} [properties] Properties to set
         */
        function GenerateParameterQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GenerateParameterQuery instance using the specified properties.
         * @function create
         * @memberof api.GenerateParameterQuery
         * @static
         * @param {api.IGenerateParameterQuery=} [properties] Properties to set
         * @returns {api.GenerateParameterQuery} GenerateParameterQuery instance
         */
        GenerateParameterQuery.create = function create(properties) {
            return new GenerateParameterQuery(properties);
        };

        /**
         * Encodes the specified GenerateParameterQuery message. Does not implicitly {@link api.GenerateParameterQuery.verify|verify} messages.
         * @function encode
         * @memberof api.GenerateParameterQuery
         * @static
         * @param {api.IGenerateParameterQuery} message GenerateParameterQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateParameterQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GenerateParameterQuery message, length delimited. Does not implicitly {@link api.GenerateParameterQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GenerateParameterQuery
         * @static
         * @param {api.IGenerateParameterQuery} message GenerateParameterQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateParameterQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateParameterQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.GenerateParameterQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GenerateParameterQuery} GenerateParameterQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateParameterQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GenerateParameterQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateParameterQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GenerateParameterQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GenerateParameterQuery} GenerateParameterQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateParameterQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateParameterQuery message.
         * @function verify
         * @memberof api.GenerateParameterQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateParameterQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GenerateParameterQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GenerateParameterQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GenerateParameterQuery} GenerateParameterQuery
         */
        GenerateParameterQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GenerateParameterQuery)
                return object;
            return new $root.api.GenerateParameterQuery();
        };

        /**
         * Creates a plain object from a GenerateParameterQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GenerateParameterQuery
         * @static
         * @param {api.GenerateParameterQuery} message GenerateParameterQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateParameterQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GenerateParameterQuery to JSON.
         * @function toJSON
         * @memberof api.GenerateParameterQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateParameterQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenerateParameterQuery;
    })();

    api.GenerateDesignQuery = (function() {

        /**
         * Properties of a GenerateDesignQuery.
         * @memberof api
         * @interface IGenerateDesignQuery
         */

        /**
         * Constructs a new GenerateDesignQuery.
         * @memberof api
         * @classdesc Represents a GenerateDesignQuery.
         * @implements IGenerateDesignQuery
         * @constructor
         * @param {api.IGenerateDesignQuery=} [properties] Properties to set
         */
        function GenerateDesignQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GenerateDesignQuery instance using the specified properties.
         * @function create
         * @memberof api.GenerateDesignQuery
         * @static
         * @param {api.IGenerateDesignQuery=} [properties] Properties to set
         * @returns {api.GenerateDesignQuery} GenerateDesignQuery instance
         */
        GenerateDesignQuery.create = function create(properties) {
            return new GenerateDesignQuery(properties);
        };

        /**
         * Encodes the specified GenerateDesignQuery message. Does not implicitly {@link api.GenerateDesignQuery.verify|verify} messages.
         * @function encode
         * @memberof api.GenerateDesignQuery
         * @static
         * @param {api.IGenerateDesignQuery} message GenerateDesignQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateDesignQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GenerateDesignQuery message, length delimited. Does not implicitly {@link api.GenerateDesignQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GenerateDesignQuery
         * @static
         * @param {api.IGenerateDesignQuery} message GenerateDesignQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateDesignQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateDesignQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.GenerateDesignQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GenerateDesignQuery} GenerateDesignQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateDesignQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GenerateDesignQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateDesignQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GenerateDesignQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GenerateDesignQuery} GenerateDesignQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateDesignQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateDesignQuery message.
         * @function verify
         * @memberof api.GenerateDesignQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateDesignQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GenerateDesignQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GenerateDesignQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GenerateDesignQuery} GenerateDesignQuery
         */
        GenerateDesignQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GenerateDesignQuery)
                return object;
            return new $root.api.GenerateDesignQuery();
        };

        /**
         * Creates a plain object from a GenerateDesignQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GenerateDesignQuery
         * @static
         * @param {api.GenerateDesignQuery} message GenerateDesignQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateDesignQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GenerateDesignQuery to JSON.
         * @function toJSON
         * @memberof api.GenerateDesignQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateDesignQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenerateDesignQuery;
    })();

    api.GeneratedImageCommentQuery = (function() {

        /**
         * Properties of a GeneratedImageCommentQuery.
         * @memberof api
         * @interface IGeneratedImageCommentQuery
         */

        /**
         * Constructs a new GeneratedImageCommentQuery.
         * @memberof api
         * @classdesc Represents a GeneratedImageCommentQuery.
         * @implements IGeneratedImageCommentQuery
         * @constructor
         * @param {api.IGeneratedImageCommentQuery=} [properties] Properties to set
         */
        function GeneratedImageCommentQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GeneratedImageCommentQuery instance using the specified properties.
         * @function create
         * @memberof api.GeneratedImageCommentQuery
         * @static
         * @param {api.IGeneratedImageCommentQuery=} [properties] Properties to set
         * @returns {api.GeneratedImageCommentQuery} GeneratedImageCommentQuery instance
         */
        GeneratedImageCommentQuery.create = function create(properties) {
            return new GeneratedImageCommentQuery(properties);
        };

        /**
         * Encodes the specified GeneratedImageCommentQuery message. Does not implicitly {@link api.GeneratedImageCommentQuery.verify|verify} messages.
         * @function encode
         * @memberof api.GeneratedImageCommentQuery
         * @static
         * @param {api.IGeneratedImageCommentQuery} message GeneratedImageCommentQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImageCommentQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GeneratedImageCommentQuery message, length delimited. Does not implicitly {@link api.GeneratedImageCommentQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GeneratedImageCommentQuery
         * @static
         * @param {api.IGeneratedImageCommentQuery} message GeneratedImageCommentQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImageCommentQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GeneratedImageCommentQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.GeneratedImageCommentQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GeneratedImageCommentQuery} GeneratedImageCommentQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImageCommentQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GeneratedImageCommentQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GeneratedImageCommentQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GeneratedImageCommentQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GeneratedImageCommentQuery} GeneratedImageCommentQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImageCommentQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GeneratedImageCommentQuery message.
         * @function verify
         * @memberof api.GeneratedImageCommentQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GeneratedImageCommentQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GeneratedImageCommentQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GeneratedImageCommentQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GeneratedImageCommentQuery} GeneratedImageCommentQuery
         */
        GeneratedImageCommentQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GeneratedImageCommentQuery)
                return object;
            return new $root.api.GeneratedImageCommentQuery();
        };

        /**
         * Creates a plain object from a GeneratedImageCommentQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GeneratedImageCommentQuery
         * @static
         * @param {api.GeneratedImageCommentQuery} message GeneratedImageCommentQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GeneratedImageCommentQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GeneratedImageCommentQuery to JSON.
         * @function toJSON
         * @memberof api.GeneratedImageCommentQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GeneratedImageCommentQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GeneratedImageCommentQuery;
    })();

    api.GeneratedImageQuery = (function() {

        /**
         * Properties of a GeneratedImageQuery.
         * @memberof api
         * @interface IGeneratedImageQuery
         * @property {api.IStringFilter|null} [imageId] GeneratedImageQuery imageId
         * @property {api.IStringFilter|null} [keywords] GeneratedImageQuery keywords
         * @property {api.IBoolFilter|null} [approved] GeneratedImageQuery approved
         * @property {api.IBoolFilter|null} [reviewed] GeneratedImageQuery reviewed
         * @property {api.IBoolFilter|null} [shareRequested] GeneratedImageQuery shareRequested
         * @property {api.IDateFilter|null} [dateFilter] GeneratedImageQuery dateFilter
         * @property {api.IStringFilter|null} [shareGroupId] GeneratedImageQuery shareGroupId
         * @property {api.IGenerateParameterQuery|null} [parameter] GeneratedImageQuery parameter
         * @property {api.IGeneratedImageQuery|null} [dependencies] GeneratedImageQuery dependencies
         * @property {api.IGeneratedImageCommentQuery|null} [comments] GeneratedImageQuery comments
         * @property {number|null} [relatedImages] GeneratedImageQuery relatedImages
         * @property {number|null} [limit] GeneratedImageQuery limit
         * @property {api.IBoolFilter|null} [bookmarked] GeneratedImageQuery bookmarked
         * @property {api.IGenerateDesignQuery|null} [design] GeneratedImageQuery design
         * @property {api.IBoolFilter|null} ["public"] GeneratedImageQuery public
         * @property {api.IBoolFilter|null} [isDesign] GeneratedImageQuery isDesign
         * @property {api.IStringFilter|null} [category] GeneratedImageQuery category
         * @property {api.IMediaFilter|null} [mediaTypes] GeneratedImageQuery mediaTypes
         */

        /**
         * Constructs a new GeneratedImageQuery.
         * @memberof api
         * @classdesc Represents a GeneratedImageQuery.
         * @implements IGeneratedImageQuery
         * @constructor
         * @param {api.IGeneratedImageQuery=} [properties] Properties to set
         */
        function GeneratedImageQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GeneratedImageQuery imageId.
         * @member {api.IStringFilter|null|undefined} imageId
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.imageId = null;

        /**
         * GeneratedImageQuery keywords.
         * @member {api.IStringFilter|null|undefined} keywords
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.keywords = null;

        /**
         * GeneratedImageQuery approved.
         * @member {api.IBoolFilter|null|undefined} approved
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.approved = null;

        /**
         * GeneratedImageQuery reviewed.
         * @member {api.IBoolFilter|null|undefined} reviewed
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.reviewed = null;

        /**
         * GeneratedImageQuery shareRequested.
         * @member {api.IBoolFilter|null|undefined} shareRequested
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.shareRequested = null;

        /**
         * GeneratedImageQuery dateFilter.
         * @member {api.IDateFilter|null|undefined} dateFilter
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.dateFilter = null;

        /**
         * GeneratedImageQuery shareGroupId.
         * @member {api.IStringFilter|null|undefined} shareGroupId
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.shareGroupId = null;

        /**
         * GeneratedImageQuery parameter.
         * @member {api.IGenerateParameterQuery|null|undefined} parameter
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.parameter = null;

        /**
         * GeneratedImageQuery dependencies.
         * @member {api.IGeneratedImageQuery|null|undefined} dependencies
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.dependencies = null;

        /**
         * GeneratedImageQuery comments.
         * @member {api.IGeneratedImageCommentQuery|null|undefined} comments
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.comments = null;

        /**
         * GeneratedImageQuery relatedImages.
         * @member {number} relatedImages
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.relatedImages = 0;

        /**
         * GeneratedImageQuery limit.
         * @member {number} limit
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.limit = 0;

        /**
         * GeneratedImageQuery bookmarked.
         * @member {api.IBoolFilter|null|undefined} bookmarked
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.bookmarked = null;

        /**
         * GeneratedImageQuery design.
         * @member {api.IGenerateDesignQuery|null|undefined} design
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.design = null;

        /**
         * GeneratedImageQuery public.
         * @member {api.IBoolFilter|null|undefined} public
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype["public"] = null;

        /**
         * GeneratedImageQuery isDesign.
         * @member {api.IBoolFilter|null|undefined} isDesign
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.isDesign = null;

        /**
         * GeneratedImageQuery category.
         * @member {api.IStringFilter|null|undefined} category
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.category = null;

        /**
         * GeneratedImageQuery mediaTypes.
         * @member {api.IMediaFilter|null|undefined} mediaTypes
         * @memberof api.GeneratedImageQuery
         * @instance
         */
        GeneratedImageQuery.prototype.mediaTypes = null;

        /**
         * Creates a new GeneratedImageQuery instance using the specified properties.
         * @function create
         * @memberof api.GeneratedImageQuery
         * @static
         * @param {api.IGeneratedImageQuery=} [properties] Properties to set
         * @returns {api.GeneratedImageQuery} GeneratedImageQuery instance
         */
        GeneratedImageQuery.create = function create(properties) {
            return new GeneratedImageQuery(properties);
        };

        /**
         * Encodes the specified GeneratedImageQuery message. Does not implicitly {@link api.GeneratedImageQuery.verify|verify} messages.
         * @function encode
         * @memberof api.GeneratedImageQuery
         * @static
         * @param {api.IGeneratedImageQuery} message GeneratedImageQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImageQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.approved != null && Object.hasOwnProperty.call(message, "approved"))
                $root.api.BoolFilter.encode(message.approved, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.reviewed != null && Object.hasOwnProperty.call(message, "reviewed"))
                $root.api.BoolFilter.encode(message.reviewed, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.dateFilter != null && Object.hasOwnProperty.call(message, "dateFilter"))
                $root.api.DateFilter.encode(message.dateFilter, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                $root.api.StringFilter.encode(message.imageId, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.shareGroupId != null && Object.hasOwnProperty.call(message, "shareGroupId"))
                $root.api.StringFilter.encode(message.shareGroupId, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.parameter != null && Object.hasOwnProperty.call(message, "parameter"))
                $root.api.GenerateParameterQuery.encode(message.parameter, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.dependencies != null && Object.hasOwnProperty.call(message, "dependencies"))
                $root.api.GeneratedImageQuery.encode(message.dependencies, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.relatedImages != null && Object.hasOwnProperty.call(message, "relatedImages"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.relatedImages);
            if (message.keywords != null && Object.hasOwnProperty.call(message, "keywords"))
                $root.api.StringFilter.encode(message.keywords, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.shareRequested != null && Object.hasOwnProperty.call(message, "shareRequested"))
                $root.api.BoolFilter.encode(message.shareRequested, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                $root.api.GeneratedImageCommentQuery.encode(message.comments, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.bookmarked != null && Object.hasOwnProperty.call(message, "bookmarked"))
                $root.api.BoolFilter.encode(message.bookmarked, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.design != null && Object.hasOwnProperty.call(message, "design"))
                $root.api.GenerateDesignQuery.encode(message.design, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message["public"] != null && Object.hasOwnProperty.call(message, "public"))
                $root.api.BoolFilter.encode(message["public"], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.isDesign != null && Object.hasOwnProperty.call(message, "isDesign"))
                $root.api.BoolFilter.encode(message.isDesign, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                $root.api.StringFilter.encode(message.category, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.mediaTypes != null && Object.hasOwnProperty.call(message, "mediaTypes"))
                $root.api.MediaFilter.encode(message.mediaTypes, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GeneratedImageQuery message, length delimited. Does not implicitly {@link api.GeneratedImageQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GeneratedImageQuery
         * @static
         * @param {api.IGeneratedImageQuery} message GeneratedImageQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImageQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GeneratedImageQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.GeneratedImageQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GeneratedImageQuery} GeneratedImageQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImageQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GeneratedImageQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5:
                    message.imageId = $root.api.StringFilter.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.keywords = $root.api.StringFilter.decode(reader, reader.uint32());
                    break;
                case 1:
                    message.approved = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.reviewed = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.shareRequested = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.dateFilter = $root.api.DateFilter.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.shareGroupId = $root.api.StringFilter.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.parameter = $root.api.GenerateParameterQuery.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.dependencies = $root.api.GeneratedImageQuery.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.comments = $root.api.GeneratedImageCommentQuery.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.relatedImages = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 13:
                    message.bookmarked = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.design = $root.api.GenerateDesignQuery.decode(reader, reader.uint32());
                    break;
                case 15:
                    message["public"] = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.isDesign = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.category = $root.api.StringFilter.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.mediaTypes = $root.api.MediaFilter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GeneratedImageQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GeneratedImageQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GeneratedImageQuery} GeneratedImageQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImageQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GeneratedImageQuery message.
         * @function verify
         * @memberof api.GeneratedImageQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GeneratedImageQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageId != null && message.hasOwnProperty("imageId")) {
                let error = $root.api.StringFilter.verify(message.imageId);
                if (error)
                    return "imageId." + error;
            }
            if (message.keywords != null && message.hasOwnProperty("keywords")) {
                let error = $root.api.StringFilter.verify(message.keywords);
                if (error)
                    return "keywords." + error;
            }
            if (message.approved != null && message.hasOwnProperty("approved")) {
                let error = $root.api.BoolFilter.verify(message.approved);
                if (error)
                    return "approved." + error;
            }
            if (message.reviewed != null && message.hasOwnProperty("reviewed")) {
                let error = $root.api.BoolFilter.verify(message.reviewed);
                if (error)
                    return "reviewed." + error;
            }
            if (message.shareRequested != null && message.hasOwnProperty("shareRequested")) {
                let error = $root.api.BoolFilter.verify(message.shareRequested);
                if (error)
                    return "shareRequested." + error;
            }
            if (message.dateFilter != null && message.hasOwnProperty("dateFilter")) {
                let error = $root.api.DateFilter.verify(message.dateFilter);
                if (error)
                    return "dateFilter." + error;
            }
            if (message.shareGroupId != null && message.hasOwnProperty("shareGroupId")) {
                let error = $root.api.StringFilter.verify(message.shareGroupId);
                if (error)
                    return "shareGroupId." + error;
            }
            if (message.parameter != null && message.hasOwnProperty("parameter")) {
                let error = $root.api.GenerateParameterQuery.verify(message.parameter);
                if (error)
                    return "parameter." + error;
            }
            if (message.dependencies != null && message.hasOwnProperty("dependencies")) {
                let error = $root.api.GeneratedImageQuery.verify(message.dependencies);
                if (error)
                    return "dependencies." + error;
            }
            if (message.comments != null && message.hasOwnProperty("comments")) {
                let error = $root.api.GeneratedImageCommentQuery.verify(message.comments);
                if (error)
                    return "comments." + error;
            }
            if (message.relatedImages != null && message.hasOwnProperty("relatedImages"))
                if (!$util.isInteger(message.relatedImages))
                    return "relatedImages: integer expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.bookmarked != null && message.hasOwnProperty("bookmarked")) {
                let error = $root.api.BoolFilter.verify(message.bookmarked);
                if (error)
                    return "bookmarked." + error;
            }
            if (message.design != null && message.hasOwnProperty("design")) {
                let error = $root.api.GenerateDesignQuery.verify(message.design);
                if (error)
                    return "design." + error;
            }
            if (message["public"] != null && message.hasOwnProperty("public")) {
                let error = $root.api.BoolFilter.verify(message["public"]);
                if (error)
                    return "public." + error;
            }
            if (message.isDesign != null && message.hasOwnProperty("isDesign")) {
                let error = $root.api.BoolFilter.verify(message.isDesign);
                if (error)
                    return "isDesign." + error;
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                let error = $root.api.StringFilter.verify(message.category);
                if (error)
                    return "category." + error;
            }
            if (message.mediaTypes != null && message.hasOwnProperty("mediaTypes")) {
                let error = $root.api.MediaFilter.verify(message.mediaTypes);
                if (error)
                    return "mediaTypes." + error;
            }
            return null;
        };

        /**
         * Creates a GeneratedImageQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GeneratedImageQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GeneratedImageQuery} GeneratedImageQuery
         */
        GeneratedImageQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GeneratedImageQuery)
                return object;
            let message = new $root.api.GeneratedImageQuery();
            if (object.imageId != null) {
                if (typeof object.imageId !== "object")
                    throw TypeError(".api.GeneratedImageQuery.imageId: object expected");
                message.imageId = $root.api.StringFilter.fromObject(object.imageId);
            }
            if (object.keywords != null) {
                if (typeof object.keywords !== "object")
                    throw TypeError(".api.GeneratedImageQuery.keywords: object expected");
                message.keywords = $root.api.StringFilter.fromObject(object.keywords);
            }
            if (object.approved != null) {
                if (typeof object.approved !== "object")
                    throw TypeError(".api.GeneratedImageQuery.approved: object expected");
                message.approved = $root.api.BoolFilter.fromObject(object.approved);
            }
            if (object.reviewed != null) {
                if (typeof object.reviewed !== "object")
                    throw TypeError(".api.GeneratedImageQuery.reviewed: object expected");
                message.reviewed = $root.api.BoolFilter.fromObject(object.reviewed);
            }
            if (object.shareRequested != null) {
                if (typeof object.shareRequested !== "object")
                    throw TypeError(".api.GeneratedImageQuery.shareRequested: object expected");
                message.shareRequested = $root.api.BoolFilter.fromObject(object.shareRequested);
            }
            if (object.dateFilter != null) {
                if (typeof object.dateFilter !== "object")
                    throw TypeError(".api.GeneratedImageQuery.dateFilter: object expected");
                message.dateFilter = $root.api.DateFilter.fromObject(object.dateFilter);
            }
            if (object.shareGroupId != null) {
                if (typeof object.shareGroupId !== "object")
                    throw TypeError(".api.GeneratedImageQuery.shareGroupId: object expected");
                message.shareGroupId = $root.api.StringFilter.fromObject(object.shareGroupId);
            }
            if (object.parameter != null) {
                if (typeof object.parameter !== "object")
                    throw TypeError(".api.GeneratedImageQuery.parameter: object expected");
                message.parameter = $root.api.GenerateParameterQuery.fromObject(object.parameter);
            }
            if (object.dependencies != null) {
                if (typeof object.dependencies !== "object")
                    throw TypeError(".api.GeneratedImageQuery.dependencies: object expected");
                message.dependencies = $root.api.GeneratedImageQuery.fromObject(object.dependencies);
            }
            if (object.comments != null) {
                if (typeof object.comments !== "object")
                    throw TypeError(".api.GeneratedImageQuery.comments: object expected");
                message.comments = $root.api.GeneratedImageCommentQuery.fromObject(object.comments);
            }
            if (object.relatedImages != null)
                message.relatedImages = object.relatedImages | 0;
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.bookmarked != null) {
                if (typeof object.bookmarked !== "object")
                    throw TypeError(".api.GeneratedImageQuery.bookmarked: object expected");
                message.bookmarked = $root.api.BoolFilter.fromObject(object.bookmarked);
            }
            if (object.design != null) {
                if (typeof object.design !== "object")
                    throw TypeError(".api.GeneratedImageQuery.design: object expected");
                message.design = $root.api.GenerateDesignQuery.fromObject(object.design);
            }
            if (object["public"] != null) {
                if (typeof object["public"] !== "object")
                    throw TypeError(".api.GeneratedImageQuery.public: object expected");
                message["public"] = $root.api.BoolFilter.fromObject(object["public"]);
            }
            if (object.isDesign != null) {
                if (typeof object.isDesign !== "object")
                    throw TypeError(".api.GeneratedImageQuery.isDesign: object expected");
                message.isDesign = $root.api.BoolFilter.fromObject(object.isDesign);
            }
            if (object.category != null) {
                if (typeof object.category !== "object")
                    throw TypeError(".api.GeneratedImageQuery.category: object expected");
                message.category = $root.api.StringFilter.fromObject(object.category);
            }
            if (object.mediaTypes != null) {
                if (typeof object.mediaTypes !== "object")
                    throw TypeError(".api.GeneratedImageQuery.mediaTypes: object expected");
                message.mediaTypes = $root.api.MediaFilter.fromObject(object.mediaTypes);
            }
            return message;
        };

        /**
         * Creates a plain object from a GeneratedImageQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GeneratedImageQuery
         * @static
         * @param {api.GeneratedImageQuery} message GeneratedImageQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GeneratedImageQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.approved = null;
                object.limit = 0;
                object.reviewed = null;
                object.dateFilter = null;
                object.imageId = null;
                object.shareGroupId = null;
                object.parameter = null;
                object.dependencies = null;
                object.relatedImages = 0;
                object.keywords = null;
                object.shareRequested = null;
                object.comments = null;
                object.bookmarked = null;
                object.design = null;
                object["public"] = null;
                object.isDesign = null;
                object.category = null;
                object.mediaTypes = null;
            }
            if (message.approved != null && message.hasOwnProperty("approved"))
                object.approved = $root.api.BoolFilter.toObject(message.approved, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.reviewed != null && message.hasOwnProperty("reviewed"))
                object.reviewed = $root.api.BoolFilter.toObject(message.reviewed, options);
            if (message.dateFilter != null && message.hasOwnProperty("dateFilter"))
                object.dateFilter = $root.api.DateFilter.toObject(message.dateFilter, options);
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = $root.api.StringFilter.toObject(message.imageId, options);
            if (message.shareGroupId != null && message.hasOwnProperty("shareGroupId"))
                object.shareGroupId = $root.api.StringFilter.toObject(message.shareGroupId, options);
            if (message.parameter != null && message.hasOwnProperty("parameter"))
                object.parameter = $root.api.GenerateParameterQuery.toObject(message.parameter, options);
            if (message.dependencies != null && message.hasOwnProperty("dependencies"))
                object.dependencies = $root.api.GeneratedImageQuery.toObject(message.dependencies, options);
            if (message.relatedImages != null && message.hasOwnProperty("relatedImages"))
                object.relatedImages = message.relatedImages;
            if (message.keywords != null && message.hasOwnProperty("keywords"))
                object.keywords = $root.api.StringFilter.toObject(message.keywords, options);
            if (message.shareRequested != null && message.hasOwnProperty("shareRequested"))
                object.shareRequested = $root.api.BoolFilter.toObject(message.shareRequested, options);
            if (message.comments != null && message.hasOwnProperty("comments"))
                object.comments = $root.api.GeneratedImageCommentQuery.toObject(message.comments, options);
            if (message.bookmarked != null && message.hasOwnProperty("bookmarked"))
                object.bookmarked = $root.api.BoolFilter.toObject(message.bookmarked, options);
            if (message.design != null && message.hasOwnProperty("design"))
                object.design = $root.api.GenerateDesignQuery.toObject(message.design, options);
            if (message["public"] != null && message.hasOwnProperty("public"))
                object["public"] = $root.api.BoolFilter.toObject(message["public"], options);
            if (message.isDesign != null && message.hasOwnProperty("isDesign"))
                object.isDesign = $root.api.BoolFilter.toObject(message.isDesign, options);
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = $root.api.StringFilter.toObject(message.category, options);
            if (message.mediaTypes != null && message.hasOwnProperty("mediaTypes"))
                object.mediaTypes = $root.api.MediaFilter.toObject(message.mediaTypes, options);
            return object;
        };

        /**
         * Converts this GeneratedImageQuery to JSON.
         * @function toJSON
         * @memberof api.GeneratedImageQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GeneratedImageQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GeneratedImageQuery;
    })();

    api.GetGeneratedImagesRequest = (function() {

        /**
         * Properties of a GetGeneratedImagesRequest.
         * @memberof api
         * @interface IGetGeneratedImagesRequest
         * @property {api.IGeneratedImageQuery|null} [query] GetGeneratedImagesRequest query
         */

        /**
         * Constructs a new GetGeneratedImagesRequest.
         * @memberof api
         * @classdesc Represents a GetGeneratedImagesRequest.
         * @implements IGetGeneratedImagesRequest
         * @constructor
         * @param {api.IGetGeneratedImagesRequest=} [properties] Properties to set
         */
        function GetGeneratedImagesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetGeneratedImagesRequest query.
         * @member {api.IGeneratedImageQuery|null|undefined} query
         * @memberof api.GetGeneratedImagesRequest
         * @instance
         */
        GetGeneratedImagesRequest.prototype.query = null;

        /**
         * Creates a new GetGeneratedImagesRequest instance using the specified properties.
         * @function create
         * @memberof api.GetGeneratedImagesRequest
         * @static
         * @param {api.IGetGeneratedImagesRequest=} [properties] Properties to set
         * @returns {api.GetGeneratedImagesRequest} GetGeneratedImagesRequest instance
         */
        GetGeneratedImagesRequest.create = function create(properties) {
            return new GetGeneratedImagesRequest(properties);
        };

        /**
         * Encodes the specified GetGeneratedImagesRequest message. Does not implicitly {@link api.GetGeneratedImagesRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetGeneratedImagesRequest
         * @static
         * @param {api.IGetGeneratedImagesRequest} message GetGeneratedImagesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetGeneratedImagesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.GeneratedImageQuery.encode(message.query, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetGeneratedImagesRequest message, length delimited. Does not implicitly {@link api.GetGeneratedImagesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetGeneratedImagesRequest
         * @static
         * @param {api.IGetGeneratedImagesRequest} message GetGeneratedImagesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetGeneratedImagesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetGeneratedImagesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetGeneratedImagesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetGeneratedImagesRequest} GetGeneratedImagesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetGeneratedImagesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetGeneratedImagesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.query = $root.api.GeneratedImageQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetGeneratedImagesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetGeneratedImagesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetGeneratedImagesRequest} GetGeneratedImagesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetGeneratedImagesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetGeneratedImagesRequest message.
         * @function verify
         * @memberof api.GetGeneratedImagesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetGeneratedImagesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.GeneratedImageQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetGeneratedImagesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetGeneratedImagesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetGeneratedImagesRequest} GetGeneratedImagesRequest
         */
        GetGeneratedImagesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetGeneratedImagesRequest)
                return object;
            let message = new $root.api.GetGeneratedImagesRequest();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetGeneratedImagesRequest.query: object expected");
                message.query = $root.api.GeneratedImageQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetGeneratedImagesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetGeneratedImagesRequest
         * @static
         * @param {api.GetGeneratedImagesRequest} message GetGeneratedImagesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetGeneratedImagesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.query = null;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.GeneratedImageQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetGeneratedImagesRequest to JSON.
         * @function toJSON
         * @memberof api.GetGeneratedImagesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetGeneratedImagesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetGeneratedImagesRequest;
    })();

    api.DeleteGeneratedImageRequest = (function() {

        /**
         * Properties of a DeleteGeneratedImageRequest.
         * @memberof api
         * @interface IDeleteGeneratedImageRequest
         * @property {string|null} [imageId] DeleteGeneratedImageRequest imageId
         */

        /**
         * Constructs a new DeleteGeneratedImageRequest.
         * @memberof api
         * @classdesc Represents a DeleteGeneratedImageRequest.
         * @implements IDeleteGeneratedImageRequest
         * @constructor
         * @param {api.IDeleteGeneratedImageRequest=} [properties] Properties to set
         */
        function DeleteGeneratedImageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteGeneratedImageRequest imageId.
         * @member {string} imageId
         * @memberof api.DeleteGeneratedImageRequest
         * @instance
         */
        DeleteGeneratedImageRequest.prototype.imageId = "";

        /**
         * Creates a new DeleteGeneratedImageRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteGeneratedImageRequest
         * @static
         * @param {api.IDeleteGeneratedImageRequest=} [properties] Properties to set
         * @returns {api.DeleteGeneratedImageRequest} DeleteGeneratedImageRequest instance
         */
        DeleteGeneratedImageRequest.create = function create(properties) {
            return new DeleteGeneratedImageRequest(properties);
        };

        /**
         * Encodes the specified DeleteGeneratedImageRequest message. Does not implicitly {@link api.DeleteGeneratedImageRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteGeneratedImageRequest
         * @static
         * @param {api.IDeleteGeneratedImageRequest} message DeleteGeneratedImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGeneratedImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageId);
            return writer;
        };

        /**
         * Encodes the specified DeleteGeneratedImageRequest message, length delimited. Does not implicitly {@link api.DeleteGeneratedImageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteGeneratedImageRequest
         * @static
         * @param {api.IDeleteGeneratedImageRequest} message DeleteGeneratedImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGeneratedImageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteGeneratedImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteGeneratedImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteGeneratedImageRequest} DeleteGeneratedImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGeneratedImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteGeneratedImageRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.imageId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteGeneratedImageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteGeneratedImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteGeneratedImageRequest} DeleteGeneratedImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGeneratedImageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteGeneratedImageRequest message.
         * @function verify
         * @memberof api.DeleteGeneratedImageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteGeneratedImageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                if (!$util.isString(message.imageId))
                    return "imageId: string expected";
            return null;
        };

        /**
         * Creates a DeleteGeneratedImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteGeneratedImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteGeneratedImageRequest} DeleteGeneratedImageRequest
         */
        DeleteGeneratedImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteGeneratedImageRequest)
                return object;
            let message = new $root.api.DeleteGeneratedImageRequest();
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            return message;
        };

        /**
         * Creates a plain object from a DeleteGeneratedImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteGeneratedImageRequest
         * @static
         * @param {api.DeleteGeneratedImageRequest} message DeleteGeneratedImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteGeneratedImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.imageId = "";
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            return object;
        };

        /**
         * Converts this DeleteGeneratedImageRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteGeneratedImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteGeneratedImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteGeneratedImageRequest;
    })();

    api.DeleteGeneratedImageResponse = (function() {

        /**
         * Properties of a DeleteGeneratedImageResponse.
         * @memberof api
         * @interface IDeleteGeneratedImageResponse
         */

        /**
         * Constructs a new DeleteGeneratedImageResponse.
         * @memberof api
         * @classdesc Represents a DeleteGeneratedImageResponse.
         * @implements IDeleteGeneratedImageResponse
         * @constructor
         * @param {api.IDeleteGeneratedImageResponse=} [properties] Properties to set
         */
        function DeleteGeneratedImageResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteGeneratedImageResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteGeneratedImageResponse
         * @static
         * @param {api.IDeleteGeneratedImageResponse=} [properties] Properties to set
         * @returns {api.DeleteGeneratedImageResponse} DeleteGeneratedImageResponse instance
         */
        DeleteGeneratedImageResponse.create = function create(properties) {
            return new DeleteGeneratedImageResponse(properties);
        };

        /**
         * Encodes the specified DeleteGeneratedImageResponse message. Does not implicitly {@link api.DeleteGeneratedImageResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteGeneratedImageResponse
         * @static
         * @param {api.IDeleteGeneratedImageResponse} message DeleteGeneratedImageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGeneratedImageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteGeneratedImageResponse message, length delimited. Does not implicitly {@link api.DeleteGeneratedImageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteGeneratedImageResponse
         * @static
         * @param {api.IDeleteGeneratedImageResponse} message DeleteGeneratedImageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGeneratedImageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteGeneratedImageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteGeneratedImageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteGeneratedImageResponse} DeleteGeneratedImageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGeneratedImageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteGeneratedImageResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteGeneratedImageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteGeneratedImageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteGeneratedImageResponse} DeleteGeneratedImageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGeneratedImageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteGeneratedImageResponse message.
         * @function verify
         * @memberof api.DeleteGeneratedImageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteGeneratedImageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteGeneratedImageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteGeneratedImageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteGeneratedImageResponse} DeleteGeneratedImageResponse
         */
        DeleteGeneratedImageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteGeneratedImageResponse)
                return object;
            return new $root.api.DeleteGeneratedImageResponse();
        };

        /**
         * Creates a plain object from a DeleteGeneratedImageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteGeneratedImageResponse
         * @static
         * @param {api.DeleteGeneratedImageResponse} message DeleteGeneratedImageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteGeneratedImageResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteGeneratedImageResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteGeneratedImageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteGeneratedImageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteGeneratedImageResponse;
    })();

    api.AddGeneratedImageCommentRequest = (function() {

        /**
         * Properties of an AddGeneratedImageCommentRequest.
         * @memberof api
         * @interface IAddGeneratedImageCommentRequest
         * @property {string|null} [imageId] AddGeneratedImageCommentRequest imageId
         * @property {string|null} [comment] AddGeneratedImageCommentRequest comment
         */

        /**
         * Constructs a new AddGeneratedImageCommentRequest.
         * @memberof api
         * @classdesc Represents an AddGeneratedImageCommentRequest.
         * @implements IAddGeneratedImageCommentRequest
         * @constructor
         * @param {api.IAddGeneratedImageCommentRequest=} [properties] Properties to set
         */
        function AddGeneratedImageCommentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddGeneratedImageCommentRequest imageId.
         * @member {string} imageId
         * @memberof api.AddGeneratedImageCommentRequest
         * @instance
         */
        AddGeneratedImageCommentRequest.prototype.imageId = "";

        /**
         * AddGeneratedImageCommentRequest comment.
         * @member {string} comment
         * @memberof api.AddGeneratedImageCommentRequest
         * @instance
         */
        AddGeneratedImageCommentRequest.prototype.comment = "";

        /**
         * Creates a new AddGeneratedImageCommentRequest instance using the specified properties.
         * @function create
         * @memberof api.AddGeneratedImageCommentRequest
         * @static
         * @param {api.IAddGeneratedImageCommentRequest=} [properties] Properties to set
         * @returns {api.AddGeneratedImageCommentRequest} AddGeneratedImageCommentRequest instance
         */
        AddGeneratedImageCommentRequest.create = function create(properties) {
            return new AddGeneratedImageCommentRequest(properties);
        };

        /**
         * Encodes the specified AddGeneratedImageCommentRequest message. Does not implicitly {@link api.AddGeneratedImageCommentRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddGeneratedImageCommentRequest
         * @static
         * @param {api.IAddGeneratedImageCommentRequest} message AddGeneratedImageCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddGeneratedImageCommentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageId);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.comment);
            return writer;
        };

        /**
         * Encodes the specified AddGeneratedImageCommentRequest message, length delimited. Does not implicitly {@link api.AddGeneratedImageCommentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddGeneratedImageCommentRequest
         * @static
         * @param {api.IAddGeneratedImageCommentRequest} message AddGeneratedImageCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddGeneratedImageCommentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddGeneratedImageCommentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddGeneratedImageCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddGeneratedImageCommentRequest} AddGeneratedImageCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddGeneratedImageCommentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddGeneratedImageCommentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.imageId = reader.string();
                    break;
                case 2:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddGeneratedImageCommentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddGeneratedImageCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddGeneratedImageCommentRequest} AddGeneratedImageCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddGeneratedImageCommentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddGeneratedImageCommentRequest message.
         * @function verify
         * @memberof api.AddGeneratedImageCommentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddGeneratedImageCommentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                if (!$util.isString(message.imageId))
                    return "imageId: string expected";
            if (message.comment != null && message.hasOwnProperty("comment"))
                if (!$util.isString(message.comment))
                    return "comment: string expected";
            return null;
        };

        /**
         * Creates an AddGeneratedImageCommentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddGeneratedImageCommentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddGeneratedImageCommentRequest} AddGeneratedImageCommentRequest
         */
        AddGeneratedImageCommentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddGeneratedImageCommentRequest)
                return object;
            let message = new $root.api.AddGeneratedImageCommentRequest();
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            if (object.comment != null)
                message.comment = String(object.comment);
            return message;
        };

        /**
         * Creates a plain object from an AddGeneratedImageCommentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddGeneratedImageCommentRequest
         * @static
         * @param {api.AddGeneratedImageCommentRequest} message AddGeneratedImageCommentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddGeneratedImageCommentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.imageId = "";
                object.comment = "";
            }
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            return object;
        };

        /**
         * Converts this AddGeneratedImageCommentRequest to JSON.
         * @function toJSON
         * @memberof api.AddGeneratedImageCommentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddGeneratedImageCommentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddGeneratedImageCommentRequest;
    })();

    api.AddGeneratedImageCommentResponse = (function() {

        /**
         * Properties of an AddGeneratedImageCommentResponse.
         * @memberof api
         * @interface IAddGeneratedImageCommentResponse
         * @property {string|null} [commentId] AddGeneratedImageCommentResponse commentId
         */

        /**
         * Constructs a new AddGeneratedImageCommentResponse.
         * @memberof api
         * @classdesc Represents an AddGeneratedImageCommentResponse.
         * @implements IAddGeneratedImageCommentResponse
         * @constructor
         * @param {api.IAddGeneratedImageCommentResponse=} [properties] Properties to set
         */
        function AddGeneratedImageCommentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddGeneratedImageCommentResponse commentId.
         * @member {string} commentId
         * @memberof api.AddGeneratedImageCommentResponse
         * @instance
         */
        AddGeneratedImageCommentResponse.prototype.commentId = "";

        /**
         * Creates a new AddGeneratedImageCommentResponse instance using the specified properties.
         * @function create
         * @memberof api.AddGeneratedImageCommentResponse
         * @static
         * @param {api.IAddGeneratedImageCommentResponse=} [properties] Properties to set
         * @returns {api.AddGeneratedImageCommentResponse} AddGeneratedImageCommentResponse instance
         */
        AddGeneratedImageCommentResponse.create = function create(properties) {
            return new AddGeneratedImageCommentResponse(properties);
        };

        /**
         * Encodes the specified AddGeneratedImageCommentResponse message. Does not implicitly {@link api.AddGeneratedImageCommentResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddGeneratedImageCommentResponse
         * @static
         * @param {api.IAddGeneratedImageCommentResponse} message AddGeneratedImageCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddGeneratedImageCommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentId != null && Object.hasOwnProperty.call(message, "commentId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.commentId);
            return writer;
        };

        /**
         * Encodes the specified AddGeneratedImageCommentResponse message, length delimited. Does not implicitly {@link api.AddGeneratedImageCommentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddGeneratedImageCommentResponse
         * @static
         * @param {api.IAddGeneratedImageCommentResponse} message AddGeneratedImageCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddGeneratedImageCommentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddGeneratedImageCommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddGeneratedImageCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddGeneratedImageCommentResponse} AddGeneratedImageCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddGeneratedImageCommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddGeneratedImageCommentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddGeneratedImageCommentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddGeneratedImageCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddGeneratedImageCommentResponse} AddGeneratedImageCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddGeneratedImageCommentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddGeneratedImageCommentResponse message.
         * @function verify
         * @memberof api.AddGeneratedImageCommentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddGeneratedImageCommentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                if (!$util.isString(message.commentId))
                    return "commentId: string expected";
            return null;
        };

        /**
         * Creates an AddGeneratedImageCommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddGeneratedImageCommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddGeneratedImageCommentResponse} AddGeneratedImageCommentResponse
         */
        AddGeneratedImageCommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddGeneratedImageCommentResponse)
                return object;
            let message = new $root.api.AddGeneratedImageCommentResponse();
            if (object.commentId != null)
                message.commentId = String(object.commentId);
            return message;
        };

        /**
         * Creates a plain object from an AddGeneratedImageCommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddGeneratedImageCommentResponse
         * @static
         * @param {api.AddGeneratedImageCommentResponse} message AddGeneratedImageCommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddGeneratedImageCommentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.commentId = "";
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                object.commentId = message.commentId;
            return object;
        };

        /**
         * Converts this AddGeneratedImageCommentResponse to JSON.
         * @function toJSON
         * @memberof api.AddGeneratedImageCommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddGeneratedImageCommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddGeneratedImageCommentResponse;
    })();

    api.DeleteGeneratedImageCommentRequest = (function() {

        /**
         * Properties of a DeleteGeneratedImageCommentRequest.
         * @memberof api
         * @interface IDeleteGeneratedImageCommentRequest
         * @property {string|null} [commentId] DeleteGeneratedImageCommentRequest commentId
         */

        /**
         * Constructs a new DeleteGeneratedImageCommentRequest.
         * @memberof api
         * @classdesc Represents a DeleteGeneratedImageCommentRequest.
         * @implements IDeleteGeneratedImageCommentRequest
         * @constructor
         * @param {api.IDeleteGeneratedImageCommentRequest=} [properties] Properties to set
         */
        function DeleteGeneratedImageCommentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteGeneratedImageCommentRequest commentId.
         * @member {string} commentId
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @instance
         */
        DeleteGeneratedImageCommentRequest.prototype.commentId = "";

        /**
         * Creates a new DeleteGeneratedImageCommentRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @static
         * @param {api.IDeleteGeneratedImageCommentRequest=} [properties] Properties to set
         * @returns {api.DeleteGeneratedImageCommentRequest} DeleteGeneratedImageCommentRequest instance
         */
        DeleteGeneratedImageCommentRequest.create = function create(properties) {
            return new DeleteGeneratedImageCommentRequest(properties);
        };

        /**
         * Encodes the specified DeleteGeneratedImageCommentRequest message. Does not implicitly {@link api.DeleteGeneratedImageCommentRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @static
         * @param {api.IDeleteGeneratedImageCommentRequest} message DeleteGeneratedImageCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGeneratedImageCommentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentId != null && Object.hasOwnProperty.call(message, "commentId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.commentId);
            return writer;
        };

        /**
         * Encodes the specified DeleteGeneratedImageCommentRequest message, length delimited. Does not implicitly {@link api.DeleteGeneratedImageCommentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @static
         * @param {api.IDeleteGeneratedImageCommentRequest} message DeleteGeneratedImageCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGeneratedImageCommentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteGeneratedImageCommentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteGeneratedImageCommentRequest} DeleteGeneratedImageCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGeneratedImageCommentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteGeneratedImageCommentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commentId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteGeneratedImageCommentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteGeneratedImageCommentRequest} DeleteGeneratedImageCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGeneratedImageCommentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteGeneratedImageCommentRequest message.
         * @function verify
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteGeneratedImageCommentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                if (!$util.isString(message.commentId))
                    return "commentId: string expected";
            return null;
        };

        /**
         * Creates a DeleteGeneratedImageCommentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteGeneratedImageCommentRequest} DeleteGeneratedImageCommentRequest
         */
        DeleteGeneratedImageCommentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteGeneratedImageCommentRequest)
                return object;
            let message = new $root.api.DeleteGeneratedImageCommentRequest();
            if (object.commentId != null)
                message.commentId = String(object.commentId);
            return message;
        };

        /**
         * Creates a plain object from a DeleteGeneratedImageCommentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @static
         * @param {api.DeleteGeneratedImageCommentRequest} message DeleteGeneratedImageCommentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteGeneratedImageCommentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.commentId = "";
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                object.commentId = message.commentId;
            return object;
        };

        /**
         * Converts this DeleteGeneratedImageCommentRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteGeneratedImageCommentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteGeneratedImageCommentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteGeneratedImageCommentRequest;
    })();

    api.DeleteGeneratedImageCommentResponse = (function() {

        /**
         * Properties of a DeleteGeneratedImageCommentResponse.
         * @memberof api
         * @interface IDeleteGeneratedImageCommentResponse
         */

        /**
         * Constructs a new DeleteGeneratedImageCommentResponse.
         * @memberof api
         * @classdesc Represents a DeleteGeneratedImageCommentResponse.
         * @implements IDeleteGeneratedImageCommentResponse
         * @constructor
         * @param {api.IDeleteGeneratedImageCommentResponse=} [properties] Properties to set
         */
        function DeleteGeneratedImageCommentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteGeneratedImageCommentResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @static
         * @param {api.IDeleteGeneratedImageCommentResponse=} [properties] Properties to set
         * @returns {api.DeleteGeneratedImageCommentResponse} DeleteGeneratedImageCommentResponse instance
         */
        DeleteGeneratedImageCommentResponse.create = function create(properties) {
            return new DeleteGeneratedImageCommentResponse(properties);
        };

        /**
         * Encodes the specified DeleteGeneratedImageCommentResponse message. Does not implicitly {@link api.DeleteGeneratedImageCommentResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @static
         * @param {api.IDeleteGeneratedImageCommentResponse} message DeleteGeneratedImageCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGeneratedImageCommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteGeneratedImageCommentResponse message, length delimited. Does not implicitly {@link api.DeleteGeneratedImageCommentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @static
         * @param {api.IDeleteGeneratedImageCommentResponse} message DeleteGeneratedImageCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGeneratedImageCommentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteGeneratedImageCommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteGeneratedImageCommentResponse} DeleteGeneratedImageCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGeneratedImageCommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteGeneratedImageCommentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteGeneratedImageCommentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteGeneratedImageCommentResponse} DeleteGeneratedImageCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGeneratedImageCommentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteGeneratedImageCommentResponse message.
         * @function verify
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteGeneratedImageCommentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteGeneratedImageCommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteGeneratedImageCommentResponse} DeleteGeneratedImageCommentResponse
         */
        DeleteGeneratedImageCommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteGeneratedImageCommentResponse)
                return object;
            return new $root.api.DeleteGeneratedImageCommentResponse();
        };

        /**
         * Creates a plain object from a DeleteGeneratedImageCommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @static
         * @param {api.DeleteGeneratedImageCommentResponse} message DeleteGeneratedImageCommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteGeneratedImageCommentResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteGeneratedImageCommentResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteGeneratedImageCommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteGeneratedImageCommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteGeneratedImageCommentResponse;
    })();

    /**
     * ReactionType enum.
     * @name api.ReactionType
     * @enum {number}
     * @property {number} NoReaction=0 NoReaction value
     * @property {number} Like=1 Like value
     * @property {number} Dislike=2 Dislike value
     */
    api.ReactionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NoReaction"] = 0;
        values[valuesById[1] = "Like"] = 1;
        values[valuesById[2] = "Dislike"] = 2;
        return values;
    })();

    api.UpdateGeneratedImageComment = (function() {

        /**
         * Properties of an UpdateGeneratedImageComment.
         * @memberof api
         * @interface IUpdateGeneratedImageComment
         * @property {boolean|null} [reaction] UpdateGeneratedImageComment reaction
         * @property {boolean|null} [comment] UpdateGeneratedImageComment comment
         */

        /**
         * Constructs a new UpdateGeneratedImageComment.
         * @memberof api
         * @classdesc Represents an UpdateGeneratedImageComment.
         * @implements IUpdateGeneratedImageComment
         * @constructor
         * @param {api.IUpdateGeneratedImageComment=} [properties] Properties to set
         */
        function UpdateGeneratedImageComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateGeneratedImageComment reaction.
         * @member {boolean} reaction
         * @memberof api.UpdateGeneratedImageComment
         * @instance
         */
        UpdateGeneratedImageComment.prototype.reaction = false;

        /**
         * UpdateGeneratedImageComment comment.
         * @member {boolean} comment
         * @memberof api.UpdateGeneratedImageComment
         * @instance
         */
        UpdateGeneratedImageComment.prototype.comment = false;

        /**
         * Creates a new UpdateGeneratedImageComment instance using the specified properties.
         * @function create
         * @memberof api.UpdateGeneratedImageComment
         * @static
         * @param {api.IUpdateGeneratedImageComment=} [properties] Properties to set
         * @returns {api.UpdateGeneratedImageComment} UpdateGeneratedImageComment instance
         */
        UpdateGeneratedImageComment.create = function create(properties) {
            return new UpdateGeneratedImageComment(properties);
        };

        /**
         * Encodes the specified UpdateGeneratedImageComment message. Does not implicitly {@link api.UpdateGeneratedImageComment.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateGeneratedImageComment
         * @static
         * @param {api.IUpdateGeneratedImageComment} message UpdateGeneratedImageComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reaction != null && Object.hasOwnProperty.call(message, "reaction"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.reaction);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.comment);
            return writer;
        };

        /**
         * Encodes the specified UpdateGeneratedImageComment message, length delimited. Does not implicitly {@link api.UpdateGeneratedImageComment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateGeneratedImageComment
         * @static
         * @param {api.IUpdateGeneratedImageComment} message UpdateGeneratedImageComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageComment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateGeneratedImageComment message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateGeneratedImageComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateGeneratedImageComment} UpdateGeneratedImageComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateGeneratedImageComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.reaction = reader.bool();
                    break;
                case 2:
                    message.comment = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateGeneratedImageComment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateGeneratedImageComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateGeneratedImageComment} UpdateGeneratedImageComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageComment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateGeneratedImageComment message.
         * @function verify
         * @memberof api.UpdateGeneratedImageComment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateGeneratedImageComment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                if (typeof message.reaction !== "boolean")
                    return "reaction: boolean expected";
            if (message.comment != null && message.hasOwnProperty("comment"))
                if (typeof message.comment !== "boolean")
                    return "comment: boolean expected";
            return null;
        };

        /**
         * Creates an UpdateGeneratedImageComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateGeneratedImageComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateGeneratedImageComment} UpdateGeneratedImageComment
         */
        UpdateGeneratedImageComment.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateGeneratedImageComment)
                return object;
            let message = new $root.api.UpdateGeneratedImageComment();
            if (object.reaction != null)
                message.reaction = Boolean(object.reaction);
            if (object.comment != null)
                message.comment = Boolean(object.comment);
            return message;
        };

        /**
         * Creates a plain object from an UpdateGeneratedImageComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateGeneratedImageComment
         * @static
         * @param {api.UpdateGeneratedImageComment} message UpdateGeneratedImageComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateGeneratedImageComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.reaction = false;
                object.comment = false;
            }
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                object.reaction = message.reaction;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            return object;
        };

        /**
         * Converts this UpdateGeneratedImageComment to JSON.
         * @function toJSON
         * @memberof api.UpdateGeneratedImageComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateGeneratedImageComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateGeneratedImageComment;
    })();

    api.UpdateGeneratedImageCommentRequest = (function() {

        /**
         * Properties of an UpdateGeneratedImageCommentRequest.
         * @memberof api
         * @interface IUpdateGeneratedImageCommentRequest
         * @property {api.IUpdateGeneratedImageComment|null} [update] UpdateGeneratedImageCommentRequest update
         * @property {api.IGeneratedImageComment|null} [comment] UpdateGeneratedImageCommentRequest comment
         */

        /**
         * Constructs a new UpdateGeneratedImageCommentRequest.
         * @memberof api
         * @classdesc Represents an UpdateGeneratedImageCommentRequest.
         * @implements IUpdateGeneratedImageCommentRequest
         * @constructor
         * @param {api.IUpdateGeneratedImageCommentRequest=} [properties] Properties to set
         */
        function UpdateGeneratedImageCommentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateGeneratedImageCommentRequest update.
         * @member {api.IUpdateGeneratedImageComment|null|undefined} update
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @instance
         */
        UpdateGeneratedImageCommentRequest.prototype.update = null;

        /**
         * UpdateGeneratedImageCommentRequest comment.
         * @member {api.IGeneratedImageComment|null|undefined} comment
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @instance
         */
        UpdateGeneratedImageCommentRequest.prototype.comment = null;

        /**
         * Creates a new UpdateGeneratedImageCommentRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @static
         * @param {api.IUpdateGeneratedImageCommentRequest=} [properties] Properties to set
         * @returns {api.UpdateGeneratedImageCommentRequest} UpdateGeneratedImageCommentRequest instance
         */
        UpdateGeneratedImageCommentRequest.create = function create(properties) {
            return new UpdateGeneratedImageCommentRequest(properties);
        };

        /**
         * Encodes the specified UpdateGeneratedImageCommentRequest message. Does not implicitly {@link api.UpdateGeneratedImageCommentRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @static
         * @param {api.IUpdateGeneratedImageCommentRequest} message UpdateGeneratedImageCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageCommentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.UpdateGeneratedImageComment.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                $root.api.GeneratedImageComment.encode(message.comment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateGeneratedImageCommentRequest message, length delimited. Does not implicitly {@link api.UpdateGeneratedImageCommentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @static
         * @param {api.IUpdateGeneratedImageCommentRequest} message UpdateGeneratedImageCommentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageCommentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateGeneratedImageCommentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateGeneratedImageCommentRequest} UpdateGeneratedImageCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageCommentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateGeneratedImageCommentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.UpdateGeneratedImageComment.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.comment = $root.api.GeneratedImageComment.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateGeneratedImageCommentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateGeneratedImageCommentRequest} UpdateGeneratedImageCommentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageCommentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateGeneratedImageCommentRequest message.
         * @function verify
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateGeneratedImageCommentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.UpdateGeneratedImageComment.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.comment != null && message.hasOwnProperty("comment")) {
                let error = $root.api.GeneratedImageComment.verify(message.comment);
                if (error)
                    return "comment." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateGeneratedImageCommentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateGeneratedImageCommentRequest} UpdateGeneratedImageCommentRequest
         */
        UpdateGeneratedImageCommentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateGeneratedImageCommentRequest)
                return object;
            let message = new $root.api.UpdateGeneratedImageCommentRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateGeneratedImageCommentRequest.update: object expected");
                message.update = $root.api.UpdateGeneratedImageComment.fromObject(object.update);
            }
            if (object.comment != null) {
                if (typeof object.comment !== "object")
                    throw TypeError(".api.UpdateGeneratedImageCommentRequest.comment: object expected");
                message.comment = $root.api.GeneratedImageComment.fromObject(object.comment);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateGeneratedImageCommentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @static
         * @param {api.UpdateGeneratedImageCommentRequest} message UpdateGeneratedImageCommentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateGeneratedImageCommentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.comment = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.UpdateGeneratedImageComment.toObject(message.update, options);
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = $root.api.GeneratedImageComment.toObject(message.comment, options);
            return object;
        };

        /**
         * Converts this UpdateGeneratedImageCommentRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateGeneratedImageCommentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateGeneratedImageCommentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateGeneratedImageCommentRequest;
    })();

    api.UpdateGeneratedImageCommentResponse = (function() {

        /**
         * Properties of an UpdateGeneratedImageCommentResponse.
         * @memberof api
         * @interface IUpdateGeneratedImageCommentResponse
         */

        /**
         * Constructs a new UpdateGeneratedImageCommentResponse.
         * @memberof api
         * @classdesc Represents an UpdateGeneratedImageCommentResponse.
         * @implements IUpdateGeneratedImageCommentResponse
         * @constructor
         * @param {api.IUpdateGeneratedImageCommentResponse=} [properties] Properties to set
         */
        function UpdateGeneratedImageCommentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateGeneratedImageCommentResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @static
         * @param {api.IUpdateGeneratedImageCommentResponse=} [properties] Properties to set
         * @returns {api.UpdateGeneratedImageCommentResponse} UpdateGeneratedImageCommentResponse instance
         */
        UpdateGeneratedImageCommentResponse.create = function create(properties) {
            return new UpdateGeneratedImageCommentResponse(properties);
        };

        /**
         * Encodes the specified UpdateGeneratedImageCommentResponse message. Does not implicitly {@link api.UpdateGeneratedImageCommentResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @static
         * @param {api.IUpdateGeneratedImageCommentResponse} message UpdateGeneratedImageCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageCommentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateGeneratedImageCommentResponse message, length delimited. Does not implicitly {@link api.UpdateGeneratedImageCommentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @static
         * @param {api.IUpdateGeneratedImageCommentResponse} message UpdateGeneratedImageCommentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageCommentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateGeneratedImageCommentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateGeneratedImageCommentResponse} UpdateGeneratedImageCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageCommentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateGeneratedImageCommentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateGeneratedImageCommentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateGeneratedImageCommentResponse} UpdateGeneratedImageCommentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageCommentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateGeneratedImageCommentResponse message.
         * @function verify
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateGeneratedImageCommentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateGeneratedImageCommentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateGeneratedImageCommentResponse} UpdateGeneratedImageCommentResponse
         */
        UpdateGeneratedImageCommentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateGeneratedImageCommentResponse)
                return object;
            return new $root.api.UpdateGeneratedImageCommentResponse();
        };

        /**
         * Creates a plain object from an UpdateGeneratedImageCommentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @static
         * @param {api.UpdateGeneratedImageCommentResponse} message UpdateGeneratedImageCommentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateGeneratedImageCommentResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateGeneratedImageCommentResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateGeneratedImageCommentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateGeneratedImageCommentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateGeneratedImageCommentResponse;
    })();

    api.AppStoreReceipt = (function() {

        /**
         * Properties of an AppStoreReceipt.
         * @memberof api
         * @interface IAppStoreReceipt
         * @property {Array.<string>|null} [transactionId] AppStoreReceipt transactionId
         */

        /**
         * Constructs a new AppStoreReceipt.
         * @memberof api
         * @classdesc Represents an AppStoreReceipt.
         * @implements IAppStoreReceipt
         * @constructor
         * @param {api.IAppStoreReceipt=} [properties] Properties to set
         */
        function AppStoreReceipt(properties) {
            this.transactionId = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AppStoreReceipt transactionId.
         * @member {Array.<string>} transactionId
         * @memberof api.AppStoreReceipt
         * @instance
         */
        AppStoreReceipt.prototype.transactionId = $util.emptyArray;

        /**
         * Creates a new AppStoreReceipt instance using the specified properties.
         * @function create
         * @memberof api.AppStoreReceipt
         * @static
         * @param {api.IAppStoreReceipt=} [properties] Properties to set
         * @returns {api.AppStoreReceipt} AppStoreReceipt instance
         */
        AppStoreReceipt.create = function create(properties) {
            return new AppStoreReceipt(properties);
        };

        /**
         * Encodes the specified AppStoreReceipt message. Does not implicitly {@link api.AppStoreReceipt.verify|verify} messages.
         * @function encode
         * @memberof api.AppStoreReceipt
         * @static
         * @param {api.IAppStoreReceipt} message AppStoreReceipt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppStoreReceipt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.transactionId != null && message.transactionId.length)
                for (let i = 0; i < message.transactionId.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.transactionId[i]);
            return writer;
        };

        /**
         * Encodes the specified AppStoreReceipt message, length delimited. Does not implicitly {@link api.AppStoreReceipt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AppStoreReceipt
         * @static
         * @param {api.IAppStoreReceipt} message AppStoreReceipt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AppStoreReceipt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AppStoreReceipt message from the specified reader or buffer.
         * @function decode
         * @memberof api.AppStoreReceipt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AppStoreReceipt} AppStoreReceipt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppStoreReceipt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AppStoreReceipt();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.transactionId && message.transactionId.length))
                        message.transactionId = [];
                    message.transactionId.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AppStoreReceipt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AppStoreReceipt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AppStoreReceipt} AppStoreReceipt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AppStoreReceipt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AppStoreReceipt message.
         * @function verify
         * @memberof api.AppStoreReceipt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AppStoreReceipt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.transactionId != null && message.hasOwnProperty("transactionId")) {
                if (!Array.isArray(message.transactionId))
                    return "transactionId: array expected";
                for (let i = 0; i < message.transactionId.length; ++i)
                    if (!$util.isString(message.transactionId[i]))
                        return "transactionId: string[] expected";
            }
            return null;
        };

        /**
         * Creates an AppStoreReceipt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AppStoreReceipt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AppStoreReceipt} AppStoreReceipt
         */
        AppStoreReceipt.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AppStoreReceipt)
                return object;
            let message = new $root.api.AppStoreReceipt();
            if (object.transactionId) {
                if (!Array.isArray(object.transactionId))
                    throw TypeError(".api.AppStoreReceipt.transactionId: array expected");
                message.transactionId = [];
                for (let i = 0; i < object.transactionId.length; ++i)
                    message.transactionId[i] = String(object.transactionId[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an AppStoreReceipt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AppStoreReceipt
         * @static
         * @param {api.AppStoreReceipt} message AppStoreReceipt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AppStoreReceipt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.transactionId = [];
            if (message.transactionId && message.transactionId.length) {
                object.transactionId = [];
                for (let j = 0; j < message.transactionId.length; ++j)
                    object.transactionId[j] = message.transactionId[j];
            }
            return object;
        };

        /**
         * Converts this AppStoreReceipt to JSON.
         * @function toJSON
         * @memberof api.AppStoreReceipt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AppStoreReceipt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AppStoreReceipt;
    })();

    api.AddReceiptRequest = (function() {

        /**
         * Properties of an AddReceiptRequest.
         * @memberof api
         * @interface IAddReceiptRequest
         * @property {api.IAppStoreReceipt|null} [appStoreReceipt] AddReceiptRequest appStoreReceipt
         */

        /**
         * Constructs a new AddReceiptRequest.
         * @memberof api
         * @classdesc Represents an AddReceiptRequest.
         * @implements IAddReceiptRequest
         * @constructor
         * @param {api.IAddReceiptRequest=} [properties] Properties to set
         */
        function AddReceiptRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddReceiptRequest appStoreReceipt.
         * @member {api.IAppStoreReceipt|null|undefined} appStoreReceipt
         * @memberof api.AddReceiptRequest
         * @instance
         */
        AddReceiptRequest.prototype.appStoreReceipt = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AddReceiptRequest receipt.
         * @member {"appStoreReceipt"|undefined} receipt
         * @memberof api.AddReceiptRequest
         * @instance
         */
        Object.defineProperty(AddReceiptRequest.prototype, "receipt", {
            get: $util.oneOfGetter($oneOfFields = ["appStoreReceipt"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AddReceiptRequest instance using the specified properties.
         * @function create
         * @memberof api.AddReceiptRequest
         * @static
         * @param {api.IAddReceiptRequest=} [properties] Properties to set
         * @returns {api.AddReceiptRequest} AddReceiptRequest instance
         */
        AddReceiptRequest.create = function create(properties) {
            return new AddReceiptRequest(properties);
        };

        /**
         * Encodes the specified AddReceiptRequest message. Does not implicitly {@link api.AddReceiptRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddReceiptRequest
         * @static
         * @param {api.IAddReceiptRequest} message AddReceiptRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddReceiptRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appStoreReceipt != null && Object.hasOwnProperty.call(message, "appStoreReceipt"))
                $root.api.AppStoreReceipt.encode(message.appStoreReceipt, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AddReceiptRequest message, length delimited. Does not implicitly {@link api.AddReceiptRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddReceiptRequest
         * @static
         * @param {api.IAddReceiptRequest} message AddReceiptRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddReceiptRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddReceiptRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddReceiptRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddReceiptRequest} AddReceiptRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddReceiptRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddReceiptRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.appStoreReceipt = $root.api.AppStoreReceipt.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddReceiptRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddReceiptRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddReceiptRequest} AddReceiptRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddReceiptRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddReceiptRequest message.
         * @function verify
         * @memberof api.AddReceiptRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddReceiptRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.appStoreReceipt != null && message.hasOwnProperty("appStoreReceipt")) {
                properties.receipt = 1;
                {
                    let error = $root.api.AppStoreReceipt.verify(message.appStoreReceipt);
                    if (error)
                        return "appStoreReceipt." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AddReceiptRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddReceiptRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddReceiptRequest} AddReceiptRequest
         */
        AddReceiptRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddReceiptRequest)
                return object;
            let message = new $root.api.AddReceiptRequest();
            if (object.appStoreReceipt != null) {
                if (typeof object.appStoreReceipt !== "object")
                    throw TypeError(".api.AddReceiptRequest.appStoreReceipt: object expected");
                message.appStoreReceipt = $root.api.AppStoreReceipt.fromObject(object.appStoreReceipt);
            }
            return message;
        };

        /**
         * Creates a plain object from an AddReceiptRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddReceiptRequest
         * @static
         * @param {api.AddReceiptRequest} message AddReceiptRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddReceiptRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.appStoreReceipt != null && message.hasOwnProperty("appStoreReceipt")) {
                object.appStoreReceipt = $root.api.AppStoreReceipt.toObject(message.appStoreReceipt, options);
                if (options.oneofs)
                    object.receipt = "appStoreReceipt";
            }
            return object;
        };

        /**
         * Converts this AddReceiptRequest to JSON.
         * @function toJSON
         * @memberof api.AddReceiptRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddReceiptRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddReceiptRequest;
    })();

    api.AddReceiptResponse = (function() {

        /**
         * Properties of an AddReceiptResponse.
         * @memberof api
         * @interface IAddReceiptResponse
         * @property {number|Long|null} [tokensLeft] AddReceiptResponse tokensLeft
         * @property {boolean|null} [hasAnotherUserActiveSubscription] AddReceiptResponse hasAnotherUserActiveSubscription
         * @property {boolean|null} [hasActiveSubscription] AddReceiptResponse hasActiveSubscription
         */

        /**
         * Constructs a new AddReceiptResponse.
         * @memberof api
         * @classdesc Represents an AddReceiptResponse.
         * @implements IAddReceiptResponse
         * @constructor
         * @param {api.IAddReceiptResponse=} [properties] Properties to set
         */
        function AddReceiptResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddReceiptResponse tokensLeft.
         * @member {number|Long} tokensLeft
         * @memberof api.AddReceiptResponse
         * @instance
         */
        AddReceiptResponse.prototype.tokensLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AddReceiptResponse hasAnotherUserActiveSubscription.
         * @member {boolean} hasAnotherUserActiveSubscription
         * @memberof api.AddReceiptResponse
         * @instance
         */
        AddReceiptResponse.prototype.hasAnotherUserActiveSubscription = false;

        /**
         * AddReceiptResponse hasActiveSubscription.
         * @member {boolean} hasActiveSubscription
         * @memberof api.AddReceiptResponse
         * @instance
         */
        AddReceiptResponse.prototype.hasActiveSubscription = false;

        /**
         * Creates a new AddReceiptResponse instance using the specified properties.
         * @function create
         * @memberof api.AddReceiptResponse
         * @static
         * @param {api.IAddReceiptResponse=} [properties] Properties to set
         * @returns {api.AddReceiptResponse} AddReceiptResponse instance
         */
        AddReceiptResponse.create = function create(properties) {
            return new AddReceiptResponse(properties);
        };

        /**
         * Encodes the specified AddReceiptResponse message. Does not implicitly {@link api.AddReceiptResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddReceiptResponse
         * @static
         * @param {api.IAddReceiptResponse} message AddReceiptResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddReceiptResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tokensLeft != null && Object.hasOwnProperty.call(message, "tokensLeft"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.tokensLeft);
            if (message.hasAnotherUserActiveSubscription != null && Object.hasOwnProperty.call(message, "hasAnotherUserActiveSubscription"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hasAnotherUserActiveSubscription);
            if (message.hasActiveSubscription != null && Object.hasOwnProperty.call(message, "hasActiveSubscription"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.hasActiveSubscription);
            return writer;
        };

        /**
         * Encodes the specified AddReceiptResponse message, length delimited. Does not implicitly {@link api.AddReceiptResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddReceiptResponse
         * @static
         * @param {api.IAddReceiptResponse} message AddReceiptResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddReceiptResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddReceiptResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddReceiptResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddReceiptResponse} AddReceiptResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddReceiptResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddReceiptResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tokensLeft = reader.int64();
                    break;
                case 2:
                    message.hasAnotherUserActiveSubscription = reader.bool();
                    break;
                case 3:
                    message.hasActiveSubscription = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddReceiptResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddReceiptResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddReceiptResponse} AddReceiptResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddReceiptResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddReceiptResponse message.
         * @function verify
         * @memberof api.AddReceiptResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddReceiptResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (!$util.isInteger(message.tokensLeft) && !(message.tokensLeft && $util.isInteger(message.tokensLeft.low) && $util.isInteger(message.tokensLeft.high)))
                    return "tokensLeft: integer|Long expected";
            if (message.hasAnotherUserActiveSubscription != null && message.hasOwnProperty("hasAnotherUserActiveSubscription"))
                if (typeof message.hasAnotherUserActiveSubscription !== "boolean")
                    return "hasAnotherUserActiveSubscription: boolean expected";
            if (message.hasActiveSubscription != null && message.hasOwnProperty("hasActiveSubscription"))
                if (typeof message.hasActiveSubscription !== "boolean")
                    return "hasActiveSubscription: boolean expected";
            return null;
        };

        /**
         * Creates an AddReceiptResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddReceiptResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddReceiptResponse} AddReceiptResponse
         */
        AddReceiptResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddReceiptResponse)
                return object;
            let message = new $root.api.AddReceiptResponse();
            if (object.tokensLeft != null)
                if ($util.Long)
                    (message.tokensLeft = $util.Long.fromValue(object.tokensLeft)).unsigned = false;
                else if (typeof object.tokensLeft === "string")
                    message.tokensLeft = parseInt(object.tokensLeft, 10);
                else if (typeof object.tokensLeft === "number")
                    message.tokensLeft = object.tokensLeft;
                else if (typeof object.tokensLeft === "object")
                    message.tokensLeft = new $util.LongBits(object.tokensLeft.low >>> 0, object.tokensLeft.high >>> 0).toNumber();
            if (object.hasAnotherUserActiveSubscription != null)
                message.hasAnotherUserActiveSubscription = Boolean(object.hasAnotherUserActiveSubscription);
            if (object.hasActiveSubscription != null)
                message.hasActiveSubscription = Boolean(object.hasActiveSubscription);
            return message;
        };

        /**
         * Creates a plain object from an AddReceiptResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddReceiptResponse
         * @static
         * @param {api.AddReceiptResponse} message AddReceiptResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddReceiptResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensLeft = options.longs === String ? "0" : 0;
                object.hasAnotherUserActiveSubscription = false;
                object.hasActiveSubscription = false;
            }
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (typeof message.tokensLeft === "number")
                    object.tokensLeft = options.longs === String ? String(message.tokensLeft) : message.tokensLeft;
                else
                    object.tokensLeft = options.longs === String ? $util.Long.prototype.toString.call(message.tokensLeft) : options.longs === Number ? new $util.LongBits(message.tokensLeft.low >>> 0, message.tokensLeft.high >>> 0).toNumber() : message.tokensLeft;
            if (message.hasAnotherUserActiveSubscription != null && message.hasOwnProperty("hasAnotherUserActiveSubscription"))
                object.hasAnotherUserActiveSubscription = message.hasAnotherUserActiveSubscription;
            if (message.hasActiveSubscription != null && message.hasOwnProperty("hasActiveSubscription"))
                object.hasActiveSubscription = message.hasActiveSubscription;
            return object;
        };

        /**
         * Converts this AddReceiptResponse to JSON.
         * @function toJSON
         * @memberof api.AddReceiptResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddReceiptResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddReceiptResponse;
    })();

    api.GetAvailableProductsRequest = (function() {

        /**
         * Properties of a GetAvailableProductsRequest.
         * @memberof api
         * @interface IGetAvailableProductsRequest
         * @property {string|null} [platform] GetAvailableProductsRequest platform
         * @property {api.IBoolFilter|null} [subscription] GetAvailableProductsRequest subscription
         */

        /**
         * Constructs a new GetAvailableProductsRequest.
         * @memberof api
         * @classdesc Represents a GetAvailableProductsRequest.
         * @implements IGetAvailableProductsRequest
         * @constructor
         * @param {api.IGetAvailableProductsRequest=} [properties] Properties to set
         */
        function GetAvailableProductsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableProductsRequest platform.
         * @member {string} platform
         * @memberof api.GetAvailableProductsRequest
         * @instance
         */
        GetAvailableProductsRequest.prototype.platform = "";

        /**
         * GetAvailableProductsRequest subscription.
         * @member {api.IBoolFilter|null|undefined} subscription
         * @memberof api.GetAvailableProductsRequest
         * @instance
         */
        GetAvailableProductsRequest.prototype.subscription = null;

        /**
         * Creates a new GetAvailableProductsRequest instance using the specified properties.
         * @function create
         * @memberof api.GetAvailableProductsRequest
         * @static
         * @param {api.IGetAvailableProductsRequest=} [properties] Properties to set
         * @returns {api.GetAvailableProductsRequest} GetAvailableProductsRequest instance
         */
        GetAvailableProductsRequest.create = function create(properties) {
            return new GetAvailableProductsRequest(properties);
        };

        /**
         * Encodes the specified GetAvailableProductsRequest message. Does not implicitly {@link api.GetAvailableProductsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetAvailableProductsRequest
         * @static
         * @param {api.IGetAvailableProductsRequest} message GetAvailableProductsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableProductsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.platform);
            if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
                $root.api.BoolFilter.encode(message.subscription, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetAvailableProductsRequest message, length delimited. Does not implicitly {@link api.GetAvailableProductsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetAvailableProductsRequest
         * @static
         * @param {api.IGetAvailableProductsRequest} message GetAvailableProductsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableProductsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAvailableProductsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetAvailableProductsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetAvailableProductsRequest} GetAvailableProductsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableProductsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetAvailableProductsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.platform = reader.string();
                    break;
                case 2:
                    message.subscription = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAvailableProductsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetAvailableProductsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetAvailableProductsRequest} GetAvailableProductsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableProductsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAvailableProductsRequest message.
         * @function verify
         * @memberof api.GetAvailableProductsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAvailableProductsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.platform != null && message.hasOwnProperty("platform"))
                if (!$util.isString(message.platform))
                    return "platform: string expected";
            if (message.subscription != null && message.hasOwnProperty("subscription")) {
                let error = $root.api.BoolFilter.verify(message.subscription);
                if (error)
                    return "subscription." + error;
            }
            return null;
        };

        /**
         * Creates a GetAvailableProductsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetAvailableProductsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetAvailableProductsRequest} GetAvailableProductsRequest
         */
        GetAvailableProductsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetAvailableProductsRequest)
                return object;
            let message = new $root.api.GetAvailableProductsRequest();
            if (object.platform != null)
                message.platform = String(object.platform);
            if (object.subscription != null) {
                if (typeof object.subscription !== "object")
                    throw TypeError(".api.GetAvailableProductsRequest.subscription: object expected");
                message.subscription = $root.api.BoolFilter.fromObject(object.subscription);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetAvailableProductsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetAvailableProductsRequest
         * @static
         * @param {api.GetAvailableProductsRequest} message GetAvailableProductsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAvailableProductsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.platform = "";
                object.subscription = null;
            }
            if (message.platform != null && message.hasOwnProperty("platform"))
                object.platform = message.platform;
            if (message.subscription != null && message.hasOwnProperty("subscription"))
                object.subscription = $root.api.BoolFilter.toObject(message.subscription, options);
            return object;
        };

        /**
         * Converts this GetAvailableProductsRequest to JSON.
         * @function toJSON
         * @memberof api.GetAvailableProductsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAvailableProductsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetAvailableProductsRequest;
    })();

    api.JobQuery = (function() {

        /**
         * Properties of a JobQuery.
         * @memberof api
         * @interface IJobQuery
         * @property {api.IStringFilter|null} [jobId] JobQuery jobId
         * @property {api.IMediaFilter|null} [mediaTypes] JobQuery mediaTypes
         * @property {number|null} [limit] JobQuery limit
         * @property {api.IJobStatusFilter|null} [status] JobQuery status
         */

        /**
         * Constructs a new JobQuery.
         * @memberof api
         * @classdesc Represents a JobQuery.
         * @implements IJobQuery
         * @constructor
         * @param {api.IJobQuery=} [properties] Properties to set
         */
        function JobQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JobQuery jobId.
         * @member {api.IStringFilter|null|undefined} jobId
         * @memberof api.JobQuery
         * @instance
         */
        JobQuery.prototype.jobId = null;

        /**
         * JobQuery mediaTypes.
         * @member {api.IMediaFilter|null|undefined} mediaTypes
         * @memberof api.JobQuery
         * @instance
         */
        JobQuery.prototype.mediaTypes = null;

        /**
         * JobQuery limit.
         * @member {number} limit
         * @memberof api.JobQuery
         * @instance
         */
        JobQuery.prototype.limit = 0;

        /**
         * JobQuery status.
         * @member {api.IJobStatusFilter|null|undefined} status
         * @memberof api.JobQuery
         * @instance
         */
        JobQuery.prototype.status = null;

        /**
         * Creates a new JobQuery instance using the specified properties.
         * @function create
         * @memberof api.JobQuery
         * @static
         * @param {api.IJobQuery=} [properties] Properties to set
         * @returns {api.JobQuery} JobQuery instance
         */
        JobQuery.create = function create(properties) {
            return new JobQuery(properties);
        };

        /**
         * Encodes the specified JobQuery message. Does not implicitly {@link api.JobQuery.verify|verify} messages.
         * @function encode
         * @memberof api.JobQuery
         * @static
         * @param {api.IJobQuery} message JobQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                $root.api.StringFilter.encode(message.jobId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.mediaTypes != null && Object.hasOwnProperty.call(message, "mediaTypes"))
                $root.api.MediaFilter.encode(message.mediaTypes, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.limit);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                $root.api.JobStatusFilter.encode(message.status, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified JobQuery message, length delimited. Does not implicitly {@link api.JobQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.JobQuery
         * @static
         * @param {api.IJobQuery} message JobQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.JobQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.JobQuery} JobQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.JobQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.jobId = $root.api.StringFilter.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.mediaTypes = $root.api.MediaFilter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.limit = reader.int32();
                    break;
                case 4:
                    message.status = $root.api.JobStatusFilter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JobQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.JobQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.JobQuery} JobQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JobQuery message.
         * @function verify
         * @memberof api.JobQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId")) {
                let error = $root.api.StringFilter.verify(message.jobId);
                if (error)
                    return "jobId." + error;
            }
            if (message.mediaTypes != null && message.hasOwnProperty("mediaTypes")) {
                let error = $root.api.MediaFilter.verify(message.mediaTypes);
                if (error)
                    return "mediaTypes." + error;
            }
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.status != null && message.hasOwnProperty("status")) {
                let error = $root.api.JobStatusFilter.verify(message.status);
                if (error)
                    return "status." + error;
            }
            return null;
        };

        /**
         * Creates a JobQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.JobQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.JobQuery} JobQuery
         */
        JobQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.JobQuery)
                return object;
            let message = new $root.api.JobQuery();
            if (object.jobId != null) {
                if (typeof object.jobId !== "object")
                    throw TypeError(".api.JobQuery.jobId: object expected");
                message.jobId = $root.api.StringFilter.fromObject(object.jobId);
            }
            if (object.mediaTypes != null) {
                if (typeof object.mediaTypes !== "object")
                    throw TypeError(".api.JobQuery.mediaTypes: object expected");
                message.mediaTypes = $root.api.MediaFilter.fromObject(object.mediaTypes);
            }
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.status != null) {
                if (typeof object.status !== "object")
                    throw TypeError(".api.JobQuery.status: object expected");
                message.status = $root.api.JobStatusFilter.fromObject(object.status);
            }
            return message;
        };

        /**
         * Creates a plain object from a JobQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.JobQuery
         * @static
         * @param {api.JobQuery} message JobQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.jobId = null;
                object.mediaTypes = null;
                object.limit = 0;
                object.status = null;
            }
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = $root.api.StringFilter.toObject(message.jobId, options);
            if (message.mediaTypes != null && message.hasOwnProperty("mediaTypes"))
                object.mediaTypes = $root.api.MediaFilter.toObject(message.mediaTypes, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = $root.api.JobStatusFilter.toObject(message.status, options);
            return object;
        };

        /**
         * Converts this JobQuery to JSON.
         * @function toJSON
         * @memberof api.JobQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return JobQuery;
    })();

    api.GetJobsRequest = (function() {

        /**
         * Properties of a GetJobsRequest.
         * @memberof api
         * @interface IGetJobsRequest
         * @property {api.IJobQuery|null} [query] GetJobsRequest query
         */

        /**
         * Constructs a new GetJobsRequest.
         * @memberof api
         * @classdesc Represents a GetJobsRequest.
         * @implements IGetJobsRequest
         * @constructor
         * @param {api.IGetJobsRequest=} [properties] Properties to set
         */
        function GetJobsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetJobsRequest query.
         * @member {api.IJobQuery|null|undefined} query
         * @memberof api.GetJobsRequest
         * @instance
         */
        GetJobsRequest.prototype.query = null;

        /**
         * Creates a new GetJobsRequest instance using the specified properties.
         * @function create
         * @memberof api.GetJobsRequest
         * @static
         * @param {api.IGetJobsRequest=} [properties] Properties to set
         * @returns {api.GetJobsRequest} GetJobsRequest instance
         */
        GetJobsRequest.create = function create(properties) {
            return new GetJobsRequest(properties);
        };

        /**
         * Encodes the specified GetJobsRequest message. Does not implicitly {@link api.GetJobsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetJobsRequest
         * @static
         * @param {api.IGetJobsRequest} message GetJobsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetJobsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.JobQuery.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetJobsRequest message, length delimited. Does not implicitly {@link api.GetJobsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetJobsRequest
         * @static
         * @param {api.IGetJobsRequest} message GetJobsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetJobsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetJobsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetJobsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetJobsRequest} GetJobsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetJobsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetJobsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.query = $root.api.JobQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetJobsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetJobsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetJobsRequest} GetJobsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetJobsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetJobsRequest message.
         * @function verify
         * @memberof api.GetJobsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetJobsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.JobQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetJobsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetJobsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetJobsRequest} GetJobsRequest
         */
        GetJobsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetJobsRequest)
                return object;
            let message = new $root.api.GetJobsRequest();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetJobsRequest.query: object expected");
                message.query = $root.api.JobQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetJobsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetJobsRequest
         * @static
         * @param {api.GetJobsRequest} message GetJobsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetJobsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.query = null;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.JobQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetJobsRequest to JSON.
         * @function toJSON
         * @memberof api.GetJobsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetJobsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetJobsRequest;
    })();

    /**
     * JobStatus enum.
     * @name api.JobStatus
     * @enum {number}
     * @property {number} JobQueued=0 JobQueued value
     * @property {number} JobRunning=1 JobRunning value
     * @property {number} JobCompleted=2 JobCompleted value
     * @property {number} JobFailed=3 JobFailed value
     * @property {number} JobCancelled=4 JobCancelled value
     */
    api.JobStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "JobQueued"] = 0;
        values[valuesById[1] = "JobRunning"] = 1;
        values[valuesById[2] = "JobCompleted"] = 2;
        values[valuesById[3] = "JobFailed"] = 3;
        values[valuesById[4] = "JobCancelled"] = 4;
        return values;
    })();

    api.JobInfo = (function() {

        /**
         * Properties of a JobInfo.
         * @memberof api
         * @interface IJobInfo
         * @property {string|null} [jobId] JobInfo jobId
         * @property {api.JobStatus|null} [status] JobInfo status
         * @property {string|null} [errorMessage] JobInfo errorMessage
         * @property {api.MediaType|null} [mediaType] JobInfo mediaType
         * @property {number|Long|null} [submitDate] JobInfo submitDate
         * @property {api.IGenerateAIResponse|null} [output] JobInfo output
         */

        /**
         * Constructs a new JobInfo.
         * @memberof api
         * @classdesc Represents a JobInfo.
         * @implements IJobInfo
         * @constructor
         * @param {api.IJobInfo=} [properties] Properties to set
         */
        function JobInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JobInfo jobId.
         * @member {string} jobId
         * @memberof api.JobInfo
         * @instance
         */
        JobInfo.prototype.jobId = "";

        /**
         * JobInfo status.
         * @member {api.JobStatus} status
         * @memberof api.JobInfo
         * @instance
         */
        JobInfo.prototype.status = 0;

        /**
         * JobInfo errorMessage.
         * @member {string} errorMessage
         * @memberof api.JobInfo
         * @instance
         */
        JobInfo.prototype.errorMessage = "";

        /**
         * JobInfo mediaType.
         * @member {api.MediaType} mediaType
         * @memberof api.JobInfo
         * @instance
         */
        JobInfo.prototype.mediaType = 0;

        /**
         * JobInfo submitDate.
         * @member {number|Long} submitDate
         * @memberof api.JobInfo
         * @instance
         */
        JobInfo.prototype.submitDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * JobInfo output.
         * @member {api.IGenerateAIResponse|null|undefined} output
         * @memberof api.JobInfo
         * @instance
         */
        JobInfo.prototype.output = null;

        /**
         * Creates a new JobInfo instance using the specified properties.
         * @function create
         * @memberof api.JobInfo
         * @static
         * @param {api.IJobInfo=} [properties] Properties to set
         * @returns {api.JobInfo} JobInfo instance
         */
        JobInfo.create = function create(properties) {
            return new JobInfo(properties);
        };

        /**
         * Encodes the specified JobInfo message. Does not implicitly {@link api.JobInfo.verify|verify} messages.
         * @function encode
         * @memberof api.JobInfo
         * @static
         * @param {api.IJobInfo} message JobInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobId != null && Object.hasOwnProperty.call(message, "jobId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.jobId);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            if (message.mediaType != null && Object.hasOwnProperty.call(message, "mediaType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.mediaType);
            if (message.submitDate != null && Object.hasOwnProperty.call(message, "submitDate"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.submitDate);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
            if (message.output != null && Object.hasOwnProperty.call(message, "output"))
                $root.api.GenerateAIResponse.encode(message.output, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified JobInfo message, length delimited. Does not implicitly {@link api.JobInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.JobInfo
         * @static
         * @param {api.IJobInfo} message JobInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JobInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JobInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.JobInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.JobInfo} JobInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.JobInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.jobId = reader.string();
                    break;
                case 2:
                    message.status = reader.int32();
                    break;
                case 5:
                    message.errorMessage = reader.string();
                    break;
                case 3:
                    message.mediaType = reader.int32();
                    break;
                case 4:
                    message.submitDate = reader.int64();
                    break;
                case 6:
                    message.output = $root.api.GenerateAIResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JobInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.JobInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.JobInfo} JobInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JobInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JobInfo message.
         * @function verify
         * @memberof api.JobInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JobInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                if (!$util.isString(message.jobId))
                    return "jobId: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                switch (message.mediaType) {
                default:
                    return "mediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.submitDate != null && message.hasOwnProperty("submitDate"))
                if (!$util.isInteger(message.submitDate) && !(message.submitDate && $util.isInteger(message.submitDate.low) && $util.isInteger(message.submitDate.high)))
                    return "submitDate: integer|Long expected";
            if (message.output != null && message.hasOwnProperty("output")) {
                let error = $root.api.GenerateAIResponse.verify(message.output);
                if (error)
                    return "output." + error;
            }
            return null;
        };

        /**
         * Creates a JobInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.JobInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.JobInfo} JobInfo
         */
        JobInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.JobInfo)
                return object;
            let message = new $root.api.JobInfo();
            if (object.jobId != null)
                message.jobId = String(object.jobId);
            switch (object.status) {
            case "JobQueued":
            case 0:
                message.status = 0;
                break;
            case "JobRunning":
            case 1:
                message.status = 1;
                break;
            case "JobCompleted":
            case 2:
                message.status = 2;
                break;
            case "JobFailed":
            case 3:
                message.status = 3;
                break;
            case "JobCancelled":
            case 4:
                message.status = 4;
                break;
            }
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            switch (object.mediaType) {
            case "ImageMediaType":
            case 0:
                message.mediaType = 0;
                break;
            case "VideoMediaType":
            case 1:
                message.mediaType = 1;
                break;
            case "AudioMediaType":
            case 2:
                message.mediaType = 2;
                break;
            }
            if (object.submitDate != null)
                if ($util.Long)
                    (message.submitDate = $util.Long.fromValue(object.submitDate)).unsigned = false;
                else if (typeof object.submitDate === "string")
                    message.submitDate = parseInt(object.submitDate, 10);
                else if (typeof object.submitDate === "number")
                    message.submitDate = object.submitDate;
                else if (typeof object.submitDate === "object")
                    message.submitDate = new $util.LongBits(object.submitDate.low >>> 0, object.submitDate.high >>> 0).toNumber();
            if (object.output != null) {
                if (typeof object.output !== "object")
                    throw TypeError(".api.JobInfo.output: object expected");
                message.output = $root.api.GenerateAIResponse.fromObject(object.output);
            }
            return message;
        };

        /**
         * Creates a plain object from a JobInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.JobInfo
         * @static
         * @param {api.JobInfo} message JobInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JobInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.jobId = "";
                object.status = options.enums === String ? "JobQueued" : 0;
                object.mediaType = options.enums === String ? "ImageMediaType" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.submitDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.submitDate = options.longs === String ? "0" : 0;
                object.errorMessage = "";
                object.output = null;
            }
            if (message.jobId != null && message.hasOwnProperty("jobId"))
                object.jobId = message.jobId;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.api.JobStatus[message.status] : message.status;
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                object.mediaType = options.enums === String ? $root.api.MediaType[message.mediaType] : message.mediaType;
            if (message.submitDate != null && message.hasOwnProperty("submitDate"))
                if (typeof message.submitDate === "number")
                    object.submitDate = options.longs === String ? String(message.submitDate) : message.submitDate;
                else
                    object.submitDate = options.longs === String ? $util.Long.prototype.toString.call(message.submitDate) : options.longs === Number ? new $util.LongBits(message.submitDate.low >>> 0, message.submitDate.high >>> 0).toNumber() : message.submitDate;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.output != null && message.hasOwnProperty("output"))
                object.output = $root.api.GenerateAIResponse.toObject(message.output, options);
            return object;
        };

        /**
         * Converts this JobInfo to JSON.
         * @function toJSON
         * @memberof api.JobInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JobInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return JobInfo;
    })();

    api.GetJobsResponse = (function() {

        /**
         * Properties of a GetJobsResponse.
         * @memberof api
         * @interface IGetJobsResponse
         * @property {Array.<api.IJobInfo>|null} [jobs] GetJobsResponse jobs
         */

        /**
         * Constructs a new GetJobsResponse.
         * @memberof api
         * @classdesc Represents a GetJobsResponse.
         * @implements IGetJobsResponse
         * @constructor
         * @param {api.IGetJobsResponse=} [properties] Properties to set
         */
        function GetJobsResponse(properties) {
            this.jobs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetJobsResponse jobs.
         * @member {Array.<api.IJobInfo>} jobs
         * @memberof api.GetJobsResponse
         * @instance
         */
        GetJobsResponse.prototype.jobs = $util.emptyArray;

        /**
         * Creates a new GetJobsResponse instance using the specified properties.
         * @function create
         * @memberof api.GetJobsResponse
         * @static
         * @param {api.IGetJobsResponse=} [properties] Properties to set
         * @returns {api.GetJobsResponse} GetJobsResponse instance
         */
        GetJobsResponse.create = function create(properties) {
            return new GetJobsResponse(properties);
        };

        /**
         * Encodes the specified GetJobsResponse message. Does not implicitly {@link api.GetJobsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetJobsResponse
         * @static
         * @param {api.IGetJobsResponse} message GetJobsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetJobsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jobs != null && message.jobs.length)
                for (let i = 0; i < message.jobs.length; ++i)
                    $root.api.JobInfo.encode(message.jobs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetJobsResponse message, length delimited. Does not implicitly {@link api.GetJobsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetJobsResponse
         * @static
         * @param {api.IGetJobsResponse} message GetJobsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetJobsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetJobsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetJobsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetJobsResponse} GetJobsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetJobsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetJobsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.jobs && message.jobs.length))
                        message.jobs = [];
                    message.jobs.push($root.api.JobInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetJobsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetJobsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetJobsResponse} GetJobsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetJobsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetJobsResponse message.
         * @function verify
         * @memberof api.GetJobsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetJobsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jobs != null && message.hasOwnProperty("jobs")) {
                if (!Array.isArray(message.jobs))
                    return "jobs: array expected";
                for (let i = 0; i < message.jobs.length; ++i) {
                    let error = $root.api.JobInfo.verify(message.jobs[i]);
                    if (error)
                        return "jobs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetJobsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetJobsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetJobsResponse} GetJobsResponse
         */
        GetJobsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetJobsResponse)
                return object;
            let message = new $root.api.GetJobsResponse();
            if (object.jobs) {
                if (!Array.isArray(object.jobs))
                    throw TypeError(".api.GetJobsResponse.jobs: array expected");
                message.jobs = [];
                for (let i = 0; i < object.jobs.length; ++i) {
                    if (typeof object.jobs[i] !== "object")
                        throw TypeError(".api.GetJobsResponse.jobs: object expected");
                    message.jobs[i] = $root.api.JobInfo.fromObject(object.jobs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetJobsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetJobsResponse
         * @static
         * @param {api.GetJobsResponse} message GetJobsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetJobsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.jobs = [];
            if (message.jobs && message.jobs.length) {
                object.jobs = [];
                for (let j = 0; j < message.jobs.length; ++j)
                    object.jobs[j] = $root.api.JobInfo.toObject(message.jobs[j], options);
            }
            return object;
        };

        /**
         * Converts this GetJobsResponse to JSON.
         * @function toJSON
         * @memberof api.GetJobsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetJobsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetJobsResponse;
    })();

    /**
     * PeriodType enum.
     * @name api.PeriodType
     * @enum {number}
     * @property {number} None=0 None value
     * @property {number} Weekly=1 Weekly value
     * @property {number} Monthly=2 Monthly value
     * @property {number} Yearly=3 Yearly value
     */
    api.PeriodType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "None"] = 0;
        values[valuesById[1] = "Weekly"] = 1;
        values[valuesById[2] = "Monthly"] = 2;
        values[valuesById[3] = "Yearly"] = 3;
        return values;
    })();

    api.ProductInfo = (function() {

        /**
         * Properties of a ProductInfo.
         * @memberof api
         * @interface IProductInfo
         * @property {string|null} [productId] ProductInfo productId
         * @property {boolean|null} [subscription] ProductInfo subscription
         * @property {boolean|null} [freeTrial] ProductInfo freeTrial
         * @property {api.PeriodType|null} [period] ProductInfo period
         */

        /**
         * Constructs a new ProductInfo.
         * @memberof api
         * @classdesc Represents a ProductInfo.
         * @implements IProductInfo
         * @constructor
         * @param {api.IProductInfo=} [properties] Properties to set
         */
        function ProductInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProductInfo productId.
         * @member {string} productId
         * @memberof api.ProductInfo
         * @instance
         */
        ProductInfo.prototype.productId = "";

        /**
         * ProductInfo subscription.
         * @member {boolean} subscription
         * @memberof api.ProductInfo
         * @instance
         */
        ProductInfo.prototype.subscription = false;

        /**
         * ProductInfo freeTrial.
         * @member {boolean} freeTrial
         * @memberof api.ProductInfo
         * @instance
         */
        ProductInfo.prototype.freeTrial = false;

        /**
         * ProductInfo period.
         * @member {api.PeriodType} period
         * @memberof api.ProductInfo
         * @instance
         */
        ProductInfo.prototype.period = 0;

        /**
         * Creates a new ProductInfo instance using the specified properties.
         * @function create
         * @memberof api.ProductInfo
         * @static
         * @param {api.IProductInfo=} [properties] Properties to set
         * @returns {api.ProductInfo} ProductInfo instance
         */
        ProductInfo.create = function create(properties) {
            return new ProductInfo(properties);
        };

        /**
         * Encodes the specified ProductInfo message. Does not implicitly {@link api.ProductInfo.verify|verify} messages.
         * @function encode
         * @memberof api.ProductInfo
         * @static
         * @param {api.IProductInfo} message ProductInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productId);
            if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.subscription);
            if (message.freeTrial != null && Object.hasOwnProperty.call(message, "freeTrial"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.freeTrial);
            if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.period);
            return writer;
        };

        /**
         * Encodes the specified ProductInfo message, length delimited. Does not implicitly {@link api.ProductInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ProductInfo
         * @static
         * @param {api.IProductInfo} message ProductInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ProductInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.ProductInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ProductInfo} ProductInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ProductInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productId = reader.string();
                    break;
                case 2:
                    message.subscription = reader.bool();
                    break;
                case 3:
                    message.freeTrial = reader.bool();
                    break;
                case 4:
                    message.period = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ProductInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ProductInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ProductInfo} ProductInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ProductInfo message.
         * @function verify
         * @memberof api.ProductInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ProductInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.productId != null && message.hasOwnProperty("productId"))
                if (!$util.isString(message.productId))
                    return "productId: string expected";
            if (message.subscription != null && message.hasOwnProperty("subscription"))
                if (typeof message.subscription !== "boolean")
                    return "subscription: boolean expected";
            if (message.freeTrial != null && message.hasOwnProperty("freeTrial"))
                if (typeof message.freeTrial !== "boolean")
                    return "freeTrial: boolean expected";
            if (message.period != null && message.hasOwnProperty("period"))
                switch (message.period) {
                default:
                    return "period: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a ProductInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ProductInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ProductInfo} ProductInfo
         */
        ProductInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ProductInfo)
                return object;
            let message = new $root.api.ProductInfo();
            if (object.productId != null)
                message.productId = String(object.productId);
            if (object.subscription != null)
                message.subscription = Boolean(object.subscription);
            if (object.freeTrial != null)
                message.freeTrial = Boolean(object.freeTrial);
            switch (object.period) {
            case "None":
            case 0:
                message.period = 0;
                break;
            case "Weekly":
            case 1:
                message.period = 1;
                break;
            case "Monthly":
            case 2:
                message.period = 2;
                break;
            case "Yearly":
            case 3:
                message.period = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ProductInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ProductInfo
         * @static
         * @param {api.ProductInfo} message ProductInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ProductInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.productId = "";
                object.subscription = false;
                object.freeTrial = false;
                object.period = options.enums === String ? "None" : 0;
            }
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = message.productId;
            if (message.subscription != null && message.hasOwnProperty("subscription"))
                object.subscription = message.subscription;
            if (message.freeTrial != null && message.hasOwnProperty("freeTrial"))
                object.freeTrial = message.freeTrial;
            if (message.period != null && message.hasOwnProperty("period"))
                object.period = options.enums === String ? $root.api.PeriodType[message.period] : message.period;
            return object;
        };

        /**
         * Converts this ProductInfo to JSON.
         * @function toJSON
         * @memberof api.ProductInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ProductInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ProductInfo;
    })();

    api.GetAvailableProductsResponse = (function() {

        /**
         * Properties of a GetAvailableProductsResponse.
         * @memberof api
         * @interface IGetAvailableProductsResponse
         * @property {Array.<api.IProductInfo>|null} [products] GetAvailableProductsResponse products
         */

        /**
         * Constructs a new GetAvailableProductsResponse.
         * @memberof api
         * @classdesc Represents a GetAvailableProductsResponse.
         * @implements IGetAvailableProductsResponse
         * @constructor
         * @param {api.IGetAvailableProductsResponse=} [properties] Properties to set
         */
        function GetAvailableProductsResponse(properties) {
            this.products = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableProductsResponse products.
         * @member {Array.<api.IProductInfo>} products
         * @memberof api.GetAvailableProductsResponse
         * @instance
         */
        GetAvailableProductsResponse.prototype.products = $util.emptyArray;

        /**
         * Creates a new GetAvailableProductsResponse instance using the specified properties.
         * @function create
         * @memberof api.GetAvailableProductsResponse
         * @static
         * @param {api.IGetAvailableProductsResponse=} [properties] Properties to set
         * @returns {api.GetAvailableProductsResponse} GetAvailableProductsResponse instance
         */
        GetAvailableProductsResponse.create = function create(properties) {
            return new GetAvailableProductsResponse(properties);
        };

        /**
         * Encodes the specified GetAvailableProductsResponse message. Does not implicitly {@link api.GetAvailableProductsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetAvailableProductsResponse
         * @static
         * @param {api.IGetAvailableProductsResponse} message GetAvailableProductsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableProductsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.products != null && message.products.length)
                for (let i = 0; i < message.products.length; ++i)
                    $root.api.ProductInfo.encode(message.products[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetAvailableProductsResponse message, length delimited. Does not implicitly {@link api.GetAvailableProductsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetAvailableProductsResponse
         * @static
         * @param {api.IGetAvailableProductsResponse} message GetAvailableProductsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableProductsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetAvailableProductsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetAvailableProductsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetAvailableProductsResponse} GetAvailableProductsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableProductsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetAvailableProductsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.api.ProductInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetAvailableProductsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetAvailableProductsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetAvailableProductsResponse} GetAvailableProductsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableProductsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetAvailableProductsResponse message.
         * @function verify
         * @memberof api.GetAvailableProductsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetAvailableProductsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.products != null && message.hasOwnProperty("products")) {
                if (!Array.isArray(message.products))
                    return "products: array expected";
                for (let i = 0; i < message.products.length; ++i) {
                    let error = $root.api.ProductInfo.verify(message.products[i]);
                    if (error)
                        return "products." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetAvailableProductsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetAvailableProductsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetAvailableProductsResponse} GetAvailableProductsResponse
         */
        GetAvailableProductsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetAvailableProductsResponse)
                return object;
            let message = new $root.api.GetAvailableProductsResponse();
            if (object.products) {
                if (!Array.isArray(object.products))
                    throw TypeError(".api.GetAvailableProductsResponse.products: array expected");
                message.products = [];
                for (let i = 0; i < object.products.length; ++i) {
                    if (typeof object.products[i] !== "object")
                        throw TypeError(".api.GetAvailableProductsResponse.products: object expected");
                    message.products[i] = $root.api.ProductInfo.fromObject(object.products[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetAvailableProductsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetAvailableProductsResponse
         * @static
         * @param {api.GetAvailableProductsResponse} message GetAvailableProductsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetAvailableProductsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.products = [];
            if (message.products && message.products.length) {
                object.products = [];
                for (let j = 0; j < message.products.length; ++j)
                    object.products[j] = $root.api.ProductInfo.toObject(message.products[j], options);
            }
            return object;
        };

        /**
         * Converts this GetAvailableProductsResponse to JSON.
         * @function toJSON
         * @memberof api.GetAvailableProductsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetAvailableProductsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetAvailableProductsResponse;
    })();

    api.ImageFileInfo = (function() {

        /**
         * Properties of an ImageFileInfo.
         * @memberof api
         * @interface IImageFileInfo
         * @property {string|null} [imageUrl] ImageFileInfo imageUrl
         * @property {string|null} [thumbnailUrl] ImageFileInfo thumbnailUrl
         * @property {number|null} [width] ImageFileInfo width
         * @property {number|null} [height] ImageFileInfo height
         */

        /**
         * Constructs a new ImageFileInfo.
         * @memberof api
         * @classdesc Represents an ImageFileInfo.
         * @implements IImageFileInfo
         * @constructor
         * @param {api.IImageFileInfo=} [properties] Properties to set
         */
        function ImageFileInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageFileInfo imageUrl.
         * @member {string} imageUrl
         * @memberof api.ImageFileInfo
         * @instance
         */
        ImageFileInfo.prototype.imageUrl = "";

        /**
         * ImageFileInfo thumbnailUrl.
         * @member {string} thumbnailUrl
         * @memberof api.ImageFileInfo
         * @instance
         */
        ImageFileInfo.prototype.thumbnailUrl = "";

        /**
         * ImageFileInfo width.
         * @member {number} width
         * @memberof api.ImageFileInfo
         * @instance
         */
        ImageFileInfo.prototype.width = 0;

        /**
         * ImageFileInfo height.
         * @member {number} height
         * @memberof api.ImageFileInfo
         * @instance
         */
        ImageFileInfo.prototype.height = 0;

        /**
         * Creates a new ImageFileInfo instance using the specified properties.
         * @function create
         * @memberof api.ImageFileInfo
         * @static
         * @param {api.IImageFileInfo=} [properties] Properties to set
         * @returns {api.ImageFileInfo} ImageFileInfo instance
         */
        ImageFileInfo.create = function create(properties) {
            return new ImageFileInfo(properties);
        };

        /**
         * Encodes the specified ImageFileInfo message. Does not implicitly {@link api.ImageFileInfo.verify|verify} messages.
         * @function encode
         * @memberof api.ImageFileInfo
         * @static
         * @param {api.IImageFileInfo} message ImageFileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageFileInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageUrl);
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.thumbnailUrl);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.height);
            return writer;
        };

        /**
         * Encodes the specified ImageFileInfo message, length delimited. Does not implicitly {@link api.ImageFileInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ImageFileInfo
         * @static
         * @param {api.IImageFileInfo} message ImageFileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageFileInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageFileInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.ImageFileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ImageFileInfo} ImageFileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageFileInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ImageFileInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.imageUrl = reader.string();
                    break;
                case 2:
                    message.thumbnailUrl = reader.string();
                    break;
                case 3:
                    message.width = reader.int32();
                    break;
                case 4:
                    message.height = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageFileInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ImageFileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ImageFileInfo} ImageFileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageFileInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageFileInfo message.
         * @function verify
         * @memberof api.ImageFileInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageFileInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                if (!$util.isString(message.imageUrl))
                    return "imageUrl: string expected";
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                if (!$util.isString(message.thumbnailUrl))
                    return "thumbnailUrl: string expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates an ImageFileInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ImageFileInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ImageFileInfo} ImageFileInfo
         */
        ImageFileInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ImageFileInfo)
                return object;
            let message = new $root.api.ImageFileInfo();
            if (object.imageUrl != null)
                message.imageUrl = String(object.imageUrl);
            if (object.thumbnailUrl != null)
                message.thumbnailUrl = String(object.thumbnailUrl);
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            return message;
        };

        /**
         * Creates a plain object from an ImageFileInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ImageFileInfo
         * @static
         * @param {api.ImageFileInfo} message ImageFileInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageFileInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.imageUrl = "";
                object.thumbnailUrl = "";
                object.width = 0;
                object.height = 0;
            }
            if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                object.imageUrl = message.imageUrl;
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = message.thumbnailUrl;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this ImageFileInfo to JSON.
         * @function toJSON
         * @memberof api.ImageFileInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageFileInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImageFileInfo;
    })();

    api.MaskFileInfo = (function() {

        /**
         * Properties of a MaskFileInfo.
         * @memberof api
         * @interface IMaskFileInfo
         * @property {string|null} [maskUrl] MaskFileInfo maskUrl
         * @property {number|null} [width] MaskFileInfo width
         * @property {number|null} [height] MaskFileInfo height
         */

        /**
         * Constructs a new MaskFileInfo.
         * @memberof api
         * @classdesc Represents a MaskFileInfo.
         * @implements IMaskFileInfo
         * @constructor
         * @param {api.IMaskFileInfo=} [properties] Properties to set
         */
        function MaskFileInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MaskFileInfo maskUrl.
         * @member {string} maskUrl
         * @memberof api.MaskFileInfo
         * @instance
         */
        MaskFileInfo.prototype.maskUrl = "";

        /**
         * MaskFileInfo width.
         * @member {number} width
         * @memberof api.MaskFileInfo
         * @instance
         */
        MaskFileInfo.prototype.width = 0;

        /**
         * MaskFileInfo height.
         * @member {number} height
         * @memberof api.MaskFileInfo
         * @instance
         */
        MaskFileInfo.prototype.height = 0;

        /**
         * Creates a new MaskFileInfo instance using the specified properties.
         * @function create
         * @memberof api.MaskFileInfo
         * @static
         * @param {api.IMaskFileInfo=} [properties] Properties to set
         * @returns {api.MaskFileInfo} MaskFileInfo instance
         */
        MaskFileInfo.create = function create(properties) {
            return new MaskFileInfo(properties);
        };

        /**
         * Encodes the specified MaskFileInfo message. Does not implicitly {@link api.MaskFileInfo.verify|verify} messages.
         * @function encode
         * @memberof api.MaskFileInfo
         * @static
         * @param {api.IMaskFileInfo} message MaskFileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MaskFileInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maskUrl != null && Object.hasOwnProperty.call(message, "maskUrl"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.maskUrl);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.height);
            return writer;
        };

        /**
         * Encodes the specified MaskFileInfo message, length delimited. Does not implicitly {@link api.MaskFileInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MaskFileInfo
         * @static
         * @param {api.IMaskFileInfo} message MaskFileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MaskFileInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MaskFileInfo message from the specified reader or buffer.
         * @function decode
         * @memberof api.MaskFileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MaskFileInfo} MaskFileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MaskFileInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MaskFileInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.maskUrl = reader.string();
                    break;
                case 2:
                    message.width = reader.int32();
                    break;
                case 3:
                    message.height = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MaskFileInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MaskFileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MaskFileInfo} MaskFileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MaskFileInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MaskFileInfo message.
         * @function verify
         * @memberof api.MaskFileInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MaskFileInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maskUrl != null && message.hasOwnProperty("maskUrl"))
                if (!$util.isString(message.maskUrl))
                    return "maskUrl: string expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates a MaskFileInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MaskFileInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MaskFileInfo} MaskFileInfo
         */
        MaskFileInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MaskFileInfo)
                return object;
            let message = new $root.api.MaskFileInfo();
            if (object.maskUrl != null)
                message.maskUrl = String(object.maskUrl);
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            return message;
        };

        /**
         * Creates a plain object from a MaskFileInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MaskFileInfo
         * @static
         * @param {api.MaskFileInfo} message MaskFileInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MaskFileInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.maskUrl = "";
                object.width = 0;
                object.height = 0;
            }
            if (message.maskUrl != null && message.hasOwnProperty("maskUrl"))
                object.maskUrl = message.maskUrl;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this MaskFileInfo to JSON.
         * @function toJSON
         * @memberof api.MaskFileInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MaskFileInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MaskFileInfo;
    })();

    api.GenerateImageDependencies = (function() {

        /**
         * Properties of a GenerateImageDependencies.
         * @memberof api
         * @interface IGenerateImageDependencies
         * @property {Array.<api.IImageFileInfo>|null} [imageFiles] GenerateImageDependencies imageFiles
         * @property {Array.<api.IGeneratedImage>|null} [dependentGeneratedImages] GenerateImageDependencies dependentGeneratedImages
         * @property {Array.<api.IMaskFileInfo>|null} [maskFiles] GenerateImageDependencies maskFiles
         */

        /**
         * Constructs a new GenerateImageDependencies.
         * @memberof api
         * @classdesc Represents a GenerateImageDependencies.
         * @implements IGenerateImageDependencies
         * @constructor
         * @param {api.IGenerateImageDependencies=} [properties] Properties to set
         */
        function GenerateImageDependencies(properties) {
            this.imageFiles = [];
            this.dependentGeneratedImages = [];
            this.maskFiles = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenerateImageDependencies imageFiles.
         * @member {Array.<api.IImageFileInfo>} imageFiles
         * @memberof api.GenerateImageDependencies
         * @instance
         */
        GenerateImageDependencies.prototype.imageFiles = $util.emptyArray;

        /**
         * GenerateImageDependencies dependentGeneratedImages.
         * @member {Array.<api.IGeneratedImage>} dependentGeneratedImages
         * @memberof api.GenerateImageDependencies
         * @instance
         */
        GenerateImageDependencies.prototype.dependentGeneratedImages = $util.emptyArray;

        /**
         * GenerateImageDependencies maskFiles.
         * @member {Array.<api.IMaskFileInfo>} maskFiles
         * @memberof api.GenerateImageDependencies
         * @instance
         */
        GenerateImageDependencies.prototype.maskFiles = $util.emptyArray;

        /**
         * Creates a new GenerateImageDependencies instance using the specified properties.
         * @function create
         * @memberof api.GenerateImageDependencies
         * @static
         * @param {api.IGenerateImageDependencies=} [properties] Properties to set
         * @returns {api.GenerateImageDependencies} GenerateImageDependencies instance
         */
        GenerateImageDependencies.create = function create(properties) {
            return new GenerateImageDependencies(properties);
        };

        /**
         * Encodes the specified GenerateImageDependencies message. Does not implicitly {@link api.GenerateImageDependencies.verify|verify} messages.
         * @function encode
         * @memberof api.GenerateImageDependencies
         * @static
         * @param {api.IGenerateImageDependencies} message GenerateImageDependencies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateImageDependencies.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageFiles != null && message.imageFiles.length)
                for (let i = 0; i < message.imageFiles.length; ++i)
                    $root.api.ImageFileInfo.encode(message.imageFiles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.dependentGeneratedImages != null && message.dependentGeneratedImages.length)
                for (let i = 0; i < message.dependentGeneratedImages.length; ++i)
                    $root.api.GeneratedImage.encode(message.dependentGeneratedImages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.maskFiles != null && message.maskFiles.length)
                for (let i = 0; i < message.maskFiles.length; ++i)
                    $root.api.MaskFileInfo.encode(message.maskFiles[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GenerateImageDependencies message, length delimited. Does not implicitly {@link api.GenerateImageDependencies.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GenerateImageDependencies
         * @static
         * @param {api.IGenerateImageDependencies} message GenerateImageDependencies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenerateImageDependencies.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenerateImageDependencies message from the specified reader or buffer.
         * @function decode
         * @memberof api.GenerateImageDependencies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GenerateImageDependencies} GenerateImageDependencies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateImageDependencies.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GenerateImageDependencies();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.imageFiles && message.imageFiles.length))
                        message.imageFiles = [];
                    message.imageFiles.push($root.api.ImageFileInfo.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.dependentGeneratedImages && message.dependentGeneratedImages.length))
                        message.dependentGeneratedImages = [];
                    message.dependentGeneratedImages.push($root.api.GeneratedImage.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (!(message.maskFiles && message.maskFiles.length))
                        message.maskFiles = [];
                    message.maskFiles.push($root.api.MaskFileInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenerateImageDependencies message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GenerateImageDependencies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GenerateImageDependencies} GenerateImageDependencies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenerateImageDependencies.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenerateImageDependencies message.
         * @function verify
         * @memberof api.GenerateImageDependencies
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenerateImageDependencies.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageFiles != null && message.hasOwnProperty("imageFiles")) {
                if (!Array.isArray(message.imageFiles))
                    return "imageFiles: array expected";
                for (let i = 0; i < message.imageFiles.length; ++i) {
                    let error = $root.api.ImageFileInfo.verify(message.imageFiles[i]);
                    if (error)
                        return "imageFiles." + error;
                }
            }
            if (message.dependentGeneratedImages != null && message.hasOwnProperty("dependentGeneratedImages")) {
                if (!Array.isArray(message.dependentGeneratedImages))
                    return "dependentGeneratedImages: array expected";
                for (let i = 0; i < message.dependentGeneratedImages.length; ++i) {
                    let error = $root.api.GeneratedImage.verify(message.dependentGeneratedImages[i]);
                    if (error)
                        return "dependentGeneratedImages." + error;
                }
            }
            if (message.maskFiles != null && message.hasOwnProperty("maskFiles")) {
                if (!Array.isArray(message.maskFiles))
                    return "maskFiles: array expected";
                for (let i = 0; i < message.maskFiles.length; ++i) {
                    let error = $root.api.MaskFileInfo.verify(message.maskFiles[i]);
                    if (error)
                        return "maskFiles." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GenerateImageDependencies message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GenerateImageDependencies
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GenerateImageDependencies} GenerateImageDependencies
         */
        GenerateImageDependencies.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GenerateImageDependencies)
                return object;
            let message = new $root.api.GenerateImageDependencies();
            if (object.imageFiles) {
                if (!Array.isArray(object.imageFiles))
                    throw TypeError(".api.GenerateImageDependencies.imageFiles: array expected");
                message.imageFiles = [];
                for (let i = 0; i < object.imageFiles.length; ++i) {
                    if (typeof object.imageFiles[i] !== "object")
                        throw TypeError(".api.GenerateImageDependencies.imageFiles: object expected");
                    message.imageFiles[i] = $root.api.ImageFileInfo.fromObject(object.imageFiles[i]);
                }
            }
            if (object.dependentGeneratedImages) {
                if (!Array.isArray(object.dependentGeneratedImages))
                    throw TypeError(".api.GenerateImageDependencies.dependentGeneratedImages: array expected");
                message.dependentGeneratedImages = [];
                for (let i = 0; i < object.dependentGeneratedImages.length; ++i) {
                    if (typeof object.dependentGeneratedImages[i] !== "object")
                        throw TypeError(".api.GenerateImageDependencies.dependentGeneratedImages: object expected");
                    message.dependentGeneratedImages[i] = $root.api.GeneratedImage.fromObject(object.dependentGeneratedImages[i]);
                }
            }
            if (object.maskFiles) {
                if (!Array.isArray(object.maskFiles))
                    throw TypeError(".api.GenerateImageDependencies.maskFiles: array expected");
                message.maskFiles = [];
                for (let i = 0; i < object.maskFiles.length; ++i) {
                    if (typeof object.maskFiles[i] !== "object")
                        throw TypeError(".api.GenerateImageDependencies.maskFiles: object expected");
                    message.maskFiles[i] = $root.api.MaskFileInfo.fromObject(object.maskFiles[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GenerateImageDependencies message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GenerateImageDependencies
         * @static
         * @param {api.GenerateImageDependencies} message GenerateImageDependencies
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenerateImageDependencies.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.imageFiles = [];
                object.dependentGeneratedImages = [];
                object.maskFiles = [];
            }
            if (message.imageFiles && message.imageFiles.length) {
                object.imageFiles = [];
                for (let j = 0; j < message.imageFiles.length; ++j)
                    object.imageFiles[j] = $root.api.ImageFileInfo.toObject(message.imageFiles[j], options);
            }
            if (message.dependentGeneratedImages && message.dependentGeneratedImages.length) {
                object.dependentGeneratedImages = [];
                for (let j = 0; j < message.dependentGeneratedImages.length; ++j)
                    object.dependentGeneratedImages[j] = $root.api.GeneratedImage.toObject(message.dependentGeneratedImages[j], options);
            }
            if (message.maskFiles && message.maskFiles.length) {
                object.maskFiles = [];
                for (let j = 0; j < message.maskFiles.length; ++j)
                    object.maskFiles[j] = $root.api.MaskFileInfo.toObject(message.maskFiles[j], options);
            }
            return object;
        };

        /**
         * Converts this GenerateImageDependencies to JSON.
         * @function toJSON
         * @memberof api.GenerateImageDependencies
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenerateImageDependencies.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenerateImageDependencies;
    })();

    api.GeneratedImageComment = (function() {

        /**
         * Properties of a GeneratedImageComment.
         * @memberof api
         * @interface IGeneratedImageComment
         * @property {string|null} [commentId] GeneratedImageComment commentId
         * @property {number|Long|null} [commentDate] GeneratedImageComment commentDate
         * @property {string|null} [comment] GeneratedImageComment comment
         * @property {string|null} [userName] GeneratedImageComment userName
         * @property {number|null} [upVotes] GeneratedImageComment upVotes
         * @property {number|null} [downVotes] GeneratedImageComment downVotes
         * @property {api.ReactionType|null} [reaction] GeneratedImageComment reaction
         */

        /**
         * Constructs a new GeneratedImageComment.
         * @memberof api
         * @classdesc Represents a GeneratedImageComment.
         * @implements IGeneratedImageComment
         * @constructor
         * @param {api.IGeneratedImageComment=} [properties] Properties to set
         */
        function GeneratedImageComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GeneratedImageComment commentId.
         * @member {string} commentId
         * @memberof api.GeneratedImageComment
         * @instance
         */
        GeneratedImageComment.prototype.commentId = "";

        /**
         * GeneratedImageComment commentDate.
         * @member {number|Long} commentDate
         * @memberof api.GeneratedImageComment
         * @instance
         */
        GeneratedImageComment.prototype.commentDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GeneratedImageComment comment.
         * @member {string} comment
         * @memberof api.GeneratedImageComment
         * @instance
         */
        GeneratedImageComment.prototype.comment = "";

        /**
         * GeneratedImageComment userName.
         * @member {string} userName
         * @memberof api.GeneratedImageComment
         * @instance
         */
        GeneratedImageComment.prototype.userName = "";

        /**
         * GeneratedImageComment upVotes.
         * @member {number} upVotes
         * @memberof api.GeneratedImageComment
         * @instance
         */
        GeneratedImageComment.prototype.upVotes = 0;

        /**
         * GeneratedImageComment downVotes.
         * @member {number} downVotes
         * @memberof api.GeneratedImageComment
         * @instance
         */
        GeneratedImageComment.prototype.downVotes = 0;

        /**
         * GeneratedImageComment reaction.
         * @member {api.ReactionType} reaction
         * @memberof api.GeneratedImageComment
         * @instance
         */
        GeneratedImageComment.prototype.reaction = 0;

        /**
         * Creates a new GeneratedImageComment instance using the specified properties.
         * @function create
         * @memberof api.GeneratedImageComment
         * @static
         * @param {api.IGeneratedImageComment=} [properties] Properties to set
         * @returns {api.GeneratedImageComment} GeneratedImageComment instance
         */
        GeneratedImageComment.create = function create(properties) {
            return new GeneratedImageComment(properties);
        };

        /**
         * Encodes the specified GeneratedImageComment message. Does not implicitly {@link api.GeneratedImageComment.verify|verify} messages.
         * @function encode
         * @memberof api.GeneratedImageComment
         * @static
         * @param {api.IGeneratedImageComment} message GeneratedImageComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImageComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentId != null && Object.hasOwnProperty.call(message, "commentId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.commentId);
            if (message.commentDate != null && Object.hasOwnProperty.call(message, "commentDate"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.commentDate);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.comment);
            if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.userName);
            if (message.upVotes != null && Object.hasOwnProperty.call(message, "upVotes"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.upVotes);
            if (message.downVotes != null && Object.hasOwnProperty.call(message, "downVotes"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.downVotes);
            if (message.reaction != null && Object.hasOwnProperty.call(message, "reaction"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.reaction);
            return writer;
        };

        /**
         * Encodes the specified GeneratedImageComment message, length delimited. Does not implicitly {@link api.GeneratedImageComment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GeneratedImageComment
         * @static
         * @param {api.IGeneratedImageComment} message GeneratedImageComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImageComment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GeneratedImageComment message from the specified reader or buffer.
         * @function decode
         * @memberof api.GeneratedImageComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GeneratedImageComment} GeneratedImageComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImageComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GeneratedImageComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commentId = reader.string();
                    break;
                case 2:
                    message.commentDate = reader.int64();
                    break;
                case 3:
                    message.comment = reader.string();
                    break;
                case 4:
                    message.userName = reader.string();
                    break;
                case 5:
                    message.upVotes = reader.int32();
                    break;
                case 6:
                    message.downVotes = reader.int32();
                    break;
                case 7:
                    message.reaction = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GeneratedImageComment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GeneratedImageComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GeneratedImageComment} GeneratedImageComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImageComment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GeneratedImageComment message.
         * @function verify
         * @memberof api.GeneratedImageComment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GeneratedImageComment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                if (!$util.isString(message.commentId))
                    return "commentId: string expected";
            if (message.commentDate != null && message.hasOwnProperty("commentDate"))
                if (!$util.isInteger(message.commentDate) && !(message.commentDate && $util.isInteger(message.commentDate.low) && $util.isInteger(message.commentDate.high)))
                    return "commentDate: integer|Long expected";
            if (message.comment != null && message.hasOwnProperty("comment"))
                if (!$util.isString(message.comment))
                    return "comment: string expected";
            if (message.userName != null && message.hasOwnProperty("userName"))
                if (!$util.isString(message.userName))
                    return "userName: string expected";
            if (message.upVotes != null && message.hasOwnProperty("upVotes"))
                if (!$util.isInteger(message.upVotes))
                    return "upVotes: integer expected";
            if (message.downVotes != null && message.hasOwnProperty("downVotes"))
                if (!$util.isInteger(message.downVotes))
                    return "downVotes: integer expected";
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                switch (message.reaction) {
                default:
                    return "reaction: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates a GeneratedImageComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GeneratedImageComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GeneratedImageComment} GeneratedImageComment
         */
        GeneratedImageComment.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GeneratedImageComment)
                return object;
            let message = new $root.api.GeneratedImageComment();
            if (object.commentId != null)
                message.commentId = String(object.commentId);
            if (object.commentDate != null)
                if ($util.Long)
                    (message.commentDate = $util.Long.fromValue(object.commentDate)).unsigned = false;
                else if (typeof object.commentDate === "string")
                    message.commentDate = parseInt(object.commentDate, 10);
                else if (typeof object.commentDate === "number")
                    message.commentDate = object.commentDate;
                else if (typeof object.commentDate === "object")
                    message.commentDate = new $util.LongBits(object.commentDate.low >>> 0, object.commentDate.high >>> 0).toNumber();
            if (object.comment != null)
                message.comment = String(object.comment);
            if (object.userName != null)
                message.userName = String(object.userName);
            if (object.upVotes != null)
                message.upVotes = object.upVotes | 0;
            if (object.downVotes != null)
                message.downVotes = object.downVotes | 0;
            switch (object.reaction) {
            case "NoReaction":
            case 0:
                message.reaction = 0;
                break;
            case "Like":
            case 1:
                message.reaction = 1;
                break;
            case "Dislike":
            case 2:
                message.reaction = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GeneratedImageComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GeneratedImageComment
         * @static
         * @param {api.GeneratedImageComment} message GeneratedImageComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GeneratedImageComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.commentId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.commentDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.commentDate = options.longs === String ? "0" : 0;
                object.comment = "";
                object.userName = "";
                object.upVotes = 0;
                object.downVotes = 0;
                object.reaction = options.enums === String ? "NoReaction" : 0;
            }
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                object.commentId = message.commentId;
            if (message.commentDate != null && message.hasOwnProperty("commentDate"))
                if (typeof message.commentDate === "number")
                    object.commentDate = options.longs === String ? String(message.commentDate) : message.commentDate;
                else
                    object.commentDate = options.longs === String ? $util.Long.prototype.toString.call(message.commentDate) : options.longs === Number ? new $util.LongBits(message.commentDate.low >>> 0, message.commentDate.high >>> 0).toNumber() : message.commentDate;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            if (message.userName != null && message.hasOwnProperty("userName"))
                object.userName = message.userName;
            if (message.upVotes != null && message.hasOwnProperty("upVotes"))
                object.upVotes = message.upVotes;
            if (message.downVotes != null && message.hasOwnProperty("downVotes"))
                object.downVotes = message.downVotes;
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                object.reaction = options.enums === String ? $root.api.ReactionType[message.reaction] : message.reaction;
            return object;
        };

        /**
         * Converts this GeneratedImageComment to JSON.
         * @function toJSON
         * @memberof api.GeneratedImageComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GeneratedImageComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GeneratedImageComment;
    })();

    /**
     * MediaType enum.
     * @name api.MediaType
     * @enum {number}
     * @property {number} ImageMediaType=0 ImageMediaType value
     * @property {number} VideoMediaType=1 VideoMediaType value
     * @property {number} AudioMediaType=2 AudioMediaType value
     */
    api.MediaType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ImageMediaType"] = 0;
        values[valuesById[1] = "VideoMediaType"] = 1;
        values[valuesById[2] = "AudioMediaType"] = 2;
        return values;
    })();

    api.GeneratedImage = (function() {

        /**
         * Properties of a GeneratedImage.
         * @memberof api
         * @interface IGeneratedImage
         * @property {string|null} [imageId] GeneratedImage imageId
         * @property {string|null} [imageUrl] GeneratedImage imageUrl
         * @property {string|null} [thumbnailUrl] GeneratedImage thumbnailUrl
         * @property {number|Long|null} [seed] GeneratedImage seed
         * @property {string|null} [prompt] GeneratedImage prompt
         * @property {string|null} [negativePrompt] GeneratedImage negativePrompt
         * @property {number|null} [inferenceSteps] GeneratedImage inferenceSteps
         * @property {number|null} [guidanceScale] GeneratedImage guidanceScale
         * @property {string|null} [scheduler] GeneratedImage scheduler
         * @property {number|null} [width] GeneratedImage width
         * @property {number|null} [height] GeneratedImage height
         * @property {string|null} [model] GeneratedImage model
         * @property {number|Long|null} [createDate] GeneratedImage createDate
         * @property {number|null} [upVotes] GeneratedImage upVotes
         * @property {number|null} [downVotes] GeneratedImage downVotes
         * @property {boolean|null} [approved] GeneratedImage approved
         * @property {number|null} [pageViews] GeneratedImage pageViews
         * @property {boolean|null} [reviewed] GeneratedImage reviewed
         * @property {string|null} [shareGroupId] GeneratedImage shareGroupId
         * @property {boolean|null} [shareRequested] GeneratedImage shareRequested
         * @property {Object.<string,string>|null} [parameters] GeneratedImage parameters
         * @property {api.IGenerateImageDependencies|null} [dependencies] GeneratedImage dependencies
         * @property {string|null} [template] GeneratedImage template
         * @property {Array.<api.IGeneratedImage>|null} [relatedImages] GeneratedImage relatedImages
         * @property {Array.<api.IGeneratedImageComment>|null} [comments] GeneratedImage comments
         * @property {api.ReactionType|null} [reaction] GeneratedImage reaction
         * @property {string|null} [userName] GeneratedImage userName
         * @property {boolean|null} [bookmarked] GeneratedImage bookmarked
         * @property {boolean|null} [pinned] GeneratedImage pinned
         * @property {api.IDesign|null} [design] GeneratedImage design
         * @property {boolean|null} ["public"] GeneratedImage public
         * @property {string|null} [publicUrl] GeneratedImage publicUrl
         * @property {boolean|null} [isDesign] GeneratedImage isDesign
         * @property {string|null} [category] GeneratedImage category
         * @property {api.MediaType|null} [mediaType] GeneratedImage mediaType
         */

        /**
         * Constructs a new GeneratedImage.
         * @memberof api
         * @classdesc Represents a GeneratedImage.
         * @implements IGeneratedImage
         * @constructor
         * @param {api.IGeneratedImage=} [properties] Properties to set
         */
        function GeneratedImage(properties) {
            this.parameters = {};
            this.relatedImages = [];
            this.comments = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GeneratedImage imageId.
         * @member {string} imageId
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.imageId = "";

        /**
         * GeneratedImage imageUrl.
         * @member {string} imageUrl
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.imageUrl = "";

        /**
         * GeneratedImage thumbnailUrl.
         * @member {string} thumbnailUrl
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.thumbnailUrl = "";

        /**
         * GeneratedImage seed.
         * @member {number|Long} seed
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.seed = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * GeneratedImage prompt.
         * @member {string} prompt
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.prompt = "";

        /**
         * GeneratedImage negativePrompt.
         * @member {string} negativePrompt
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.negativePrompt = "";

        /**
         * GeneratedImage inferenceSteps.
         * @member {number} inferenceSteps
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.inferenceSteps = 0;

        /**
         * GeneratedImage guidanceScale.
         * @member {number} guidanceScale
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.guidanceScale = 0;

        /**
         * GeneratedImage scheduler.
         * @member {string} scheduler
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.scheduler = "";

        /**
         * GeneratedImage width.
         * @member {number} width
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.width = 0;

        /**
         * GeneratedImage height.
         * @member {number} height
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.height = 0;

        /**
         * GeneratedImage model.
         * @member {string} model
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.model = "";

        /**
         * GeneratedImage createDate.
         * @member {number|Long} createDate
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GeneratedImage upVotes.
         * @member {number} upVotes
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.upVotes = 0;

        /**
         * GeneratedImage downVotes.
         * @member {number} downVotes
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.downVotes = 0;

        /**
         * GeneratedImage approved.
         * @member {boolean} approved
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.approved = false;

        /**
         * GeneratedImage pageViews.
         * @member {number} pageViews
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.pageViews = 0;

        /**
         * GeneratedImage reviewed.
         * @member {boolean} reviewed
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.reviewed = false;

        /**
         * GeneratedImage shareGroupId.
         * @member {string} shareGroupId
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.shareGroupId = "";

        /**
         * GeneratedImage shareRequested.
         * @member {boolean} shareRequested
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.shareRequested = false;

        /**
         * GeneratedImage parameters.
         * @member {Object.<string,string>} parameters
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.parameters = $util.emptyObject;

        /**
         * GeneratedImage dependencies.
         * @member {api.IGenerateImageDependencies|null|undefined} dependencies
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.dependencies = null;

        /**
         * GeneratedImage template.
         * @member {string} template
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.template = "";

        /**
         * GeneratedImage relatedImages.
         * @member {Array.<api.IGeneratedImage>} relatedImages
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.relatedImages = $util.emptyArray;

        /**
         * GeneratedImage comments.
         * @member {Array.<api.IGeneratedImageComment>} comments
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.comments = $util.emptyArray;

        /**
         * GeneratedImage reaction.
         * @member {api.ReactionType} reaction
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.reaction = 0;

        /**
         * GeneratedImage userName.
         * @member {string} userName
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.userName = "";

        /**
         * GeneratedImage bookmarked.
         * @member {boolean} bookmarked
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.bookmarked = false;

        /**
         * GeneratedImage pinned.
         * @member {boolean} pinned
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.pinned = false;

        /**
         * GeneratedImage design.
         * @member {api.IDesign|null|undefined} design
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.design = null;

        /**
         * GeneratedImage public.
         * @member {boolean} public
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype["public"] = false;

        /**
         * GeneratedImage publicUrl.
         * @member {string} publicUrl
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.publicUrl = "";

        /**
         * GeneratedImage isDesign.
         * @member {boolean} isDesign
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.isDesign = false;

        /**
         * GeneratedImage category.
         * @member {string} category
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.category = "";

        /**
         * GeneratedImage mediaType.
         * @member {api.MediaType} mediaType
         * @memberof api.GeneratedImage
         * @instance
         */
        GeneratedImage.prototype.mediaType = 0;

        /**
         * Creates a new GeneratedImage instance using the specified properties.
         * @function create
         * @memberof api.GeneratedImage
         * @static
         * @param {api.IGeneratedImage=} [properties] Properties to set
         * @returns {api.GeneratedImage} GeneratedImage instance
         */
        GeneratedImage.create = function create(properties) {
            return new GeneratedImage(properties);
        };

        /**
         * Encodes the specified GeneratedImage message. Does not implicitly {@link api.GeneratedImage.verify|verify} messages.
         * @function encode
         * @memberof api.GeneratedImage
         * @static
         * @param {api.IGeneratedImage} message GeneratedImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageUrl);
            if (message.seed != null && Object.hasOwnProperty.call(message, "seed"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.seed);
            if (message.prompt != null && Object.hasOwnProperty.call(message, "prompt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.prompt);
            if (message.negativePrompt != null && Object.hasOwnProperty.call(message, "negativePrompt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.negativePrompt);
            if (message.inferenceSteps != null && Object.hasOwnProperty.call(message, "inferenceSteps"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.inferenceSteps);
            if (message.guidanceScale != null && Object.hasOwnProperty.call(message, "guidanceScale"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.guidanceScale);
            if (message.scheduler != null && Object.hasOwnProperty.call(message, "scheduler"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.scheduler);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.height);
            if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.model);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.createDate);
            if (message.upVotes != null && Object.hasOwnProperty.call(message, "upVotes"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.upVotes);
            if (message.downVotes != null && Object.hasOwnProperty.call(message, "downVotes"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.downVotes);
            if (message.approved != null && Object.hasOwnProperty.call(message, "approved"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.approved);
            if (message.pageViews != null && Object.hasOwnProperty.call(message, "pageViews"))
                writer.uint32(/* id 15, wireType 0 =*/120).int32(message.pageViews);
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.imageId);
            if (message.reviewed != null && Object.hasOwnProperty.call(message, "reviewed"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.reviewed);
            if (message.shareGroupId != null && Object.hasOwnProperty.call(message, "shareGroupId"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.shareGroupId);
            if (message.parameters != null && Object.hasOwnProperty.call(message, "parameters"))
                for (let keys = Object.keys(message.parameters), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 19, wireType 2 =*/154).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.parameters[keys[i]]).ldelim();
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.thumbnailUrl);
            if (message.dependencies != null && Object.hasOwnProperty.call(message, "dependencies"))
                $root.api.GenerateImageDependencies.encode(message.dependencies, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.template != null && Object.hasOwnProperty.call(message, "template"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.template);
            if (message.relatedImages != null && message.relatedImages.length)
                for (let i = 0; i < message.relatedImages.length; ++i)
                    $root.api.GeneratedImage.encode(message.relatedImages[i], writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.shareRequested != null && Object.hasOwnProperty.call(message, "shareRequested"))
                writer.uint32(/* id 24, wireType 0 =*/192).bool(message.shareRequested);
            if (message.comments != null && message.comments.length)
                for (let i = 0; i < message.comments.length; ++i)
                    $root.api.GeneratedImageComment.encode(message.comments[i], writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.reaction != null && Object.hasOwnProperty.call(message, "reaction"))
                writer.uint32(/* id 26, wireType 0 =*/208).int32(message.reaction);
            if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                writer.uint32(/* id 27, wireType 2 =*/218).string(message.userName);
            if (message.bookmarked != null && Object.hasOwnProperty.call(message, "bookmarked"))
                writer.uint32(/* id 28, wireType 0 =*/224).bool(message.bookmarked);
            if (message.pinned != null && Object.hasOwnProperty.call(message, "pinned"))
                writer.uint32(/* id 29, wireType 0 =*/232).bool(message.pinned);
            if (message.design != null && Object.hasOwnProperty.call(message, "design"))
                $root.api.Design.encode(message.design, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message["public"] != null && Object.hasOwnProperty.call(message, "public"))
                writer.uint32(/* id 31, wireType 0 =*/248).bool(message["public"]);
            if (message.publicUrl != null && Object.hasOwnProperty.call(message, "publicUrl"))
                writer.uint32(/* id 32, wireType 2 =*/258).string(message.publicUrl);
            if (message.isDesign != null && Object.hasOwnProperty.call(message, "isDesign"))
                writer.uint32(/* id 33, wireType 0 =*/264).bool(message.isDesign);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 34, wireType 2 =*/274).string(message.category);
            if (message.mediaType != null && Object.hasOwnProperty.call(message, "mediaType"))
                writer.uint32(/* id 35, wireType 0 =*/280).int32(message.mediaType);
            return writer;
        };

        /**
         * Encodes the specified GeneratedImage message, length delimited. Does not implicitly {@link api.GeneratedImage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GeneratedImage
         * @static
         * @param {api.IGeneratedImage} message GeneratedImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GeneratedImage message from the specified reader or buffer.
         * @function decode
         * @memberof api.GeneratedImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GeneratedImage} GeneratedImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GeneratedImage(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 16:
                    message.imageId = reader.string();
                    break;
                case 1:
                    message.imageUrl = reader.string();
                    break;
                case 20:
                    message.thumbnailUrl = reader.string();
                    break;
                case 2:
                    message.seed = reader.uint64();
                    break;
                case 3:
                    message.prompt = reader.string();
                    break;
                case 4:
                    message.negativePrompt = reader.string();
                    break;
                case 5:
                    message.inferenceSteps = reader.int32();
                    break;
                case 6:
                    message.guidanceScale = reader.double();
                    break;
                case 7:
                    message.scheduler = reader.string();
                    break;
                case 8:
                    message.width = reader.int32();
                    break;
                case 9:
                    message.height = reader.int32();
                    break;
                case 10:
                    message.model = reader.string();
                    break;
                case 11:
                    message.createDate = reader.int64();
                    break;
                case 12:
                    message.upVotes = reader.int32();
                    break;
                case 13:
                    message.downVotes = reader.int32();
                    break;
                case 14:
                    message.approved = reader.bool();
                    break;
                case 15:
                    message.pageViews = reader.int32();
                    break;
                case 17:
                    message.reviewed = reader.bool();
                    break;
                case 18:
                    message.shareGroupId = reader.string();
                    break;
                case 24:
                    message.shareRequested = reader.bool();
                    break;
                case 19:
                    if (message.parameters === $util.emptyObject)
                        message.parameters = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.parameters[key] = value;
                    break;
                case 21:
                    message.dependencies = $root.api.GenerateImageDependencies.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.template = reader.string();
                    break;
                case 23:
                    if (!(message.relatedImages && message.relatedImages.length))
                        message.relatedImages = [];
                    message.relatedImages.push($root.api.GeneratedImage.decode(reader, reader.uint32()));
                    break;
                case 25:
                    if (!(message.comments && message.comments.length))
                        message.comments = [];
                    message.comments.push($root.api.GeneratedImageComment.decode(reader, reader.uint32()));
                    break;
                case 26:
                    message.reaction = reader.int32();
                    break;
                case 27:
                    message.userName = reader.string();
                    break;
                case 28:
                    message.bookmarked = reader.bool();
                    break;
                case 29:
                    message.pinned = reader.bool();
                    break;
                case 30:
                    message.design = $root.api.Design.decode(reader, reader.uint32());
                    break;
                case 31:
                    message["public"] = reader.bool();
                    break;
                case 32:
                    message.publicUrl = reader.string();
                    break;
                case 33:
                    message.isDesign = reader.bool();
                    break;
                case 34:
                    message.category = reader.string();
                    break;
                case 35:
                    message.mediaType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GeneratedImage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GeneratedImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GeneratedImage} GeneratedImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GeneratedImage message.
         * @function verify
         * @memberof api.GeneratedImage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GeneratedImage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                if (!$util.isString(message.imageId))
                    return "imageId: string expected";
            if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                if (!$util.isString(message.imageUrl))
                    return "imageUrl: string expected";
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                if (!$util.isString(message.thumbnailUrl))
                    return "thumbnailUrl: string expected";
            if (message.seed != null && message.hasOwnProperty("seed"))
                if (!$util.isInteger(message.seed) && !(message.seed && $util.isInteger(message.seed.low) && $util.isInteger(message.seed.high)))
                    return "seed: integer|Long expected";
            if (message.prompt != null && message.hasOwnProperty("prompt"))
                if (!$util.isString(message.prompt))
                    return "prompt: string expected";
            if (message.negativePrompt != null && message.hasOwnProperty("negativePrompt"))
                if (!$util.isString(message.negativePrompt))
                    return "negativePrompt: string expected";
            if (message.inferenceSteps != null && message.hasOwnProperty("inferenceSteps"))
                if (!$util.isInteger(message.inferenceSteps))
                    return "inferenceSteps: integer expected";
            if (message.guidanceScale != null && message.hasOwnProperty("guidanceScale"))
                if (typeof message.guidanceScale !== "number")
                    return "guidanceScale: number expected";
            if (message.scheduler != null && message.hasOwnProperty("scheduler"))
                if (!$util.isString(message.scheduler))
                    return "scheduler: string expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.model != null && message.hasOwnProperty("model"))
                if (!$util.isString(message.model))
                    return "model: string expected";
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.upVotes != null && message.hasOwnProperty("upVotes"))
                if (!$util.isInteger(message.upVotes))
                    return "upVotes: integer expected";
            if (message.downVotes != null && message.hasOwnProperty("downVotes"))
                if (!$util.isInteger(message.downVotes))
                    return "downVotes: integer expected";
            if (message.approved != null && message.hasOwnProperty("approved"))
                if (typeof message.approved !== "boolean")
                    return "approved: boolean expected";
            if (message.pageViews != null && message.hasOwnProperty("pageViews"))
                if (!$util.isInteger(message.pageViews))
                    return "pageViews: integer expected";
            if (message.reviewed != null && message.hasOwnProperty("reviewed"))
                if (typeof message.reviewed !== "boolean")
                    return "reviewed: boolean expected";
            if (message.shareGroupId != null && message.hasOwnProperty("shareGroupId"))
                if (!$util.isString(message.shareGroupId))
                    return "shareGroupId: string expected";
            if (message.shareRequested != null && message.hasOwnProperty("shareRequested"))
                if (typeof message.shareRequested !== "boolean")
                    return "shareRequested: boolean expected";
            if (message.parameters != null && message.hasOwnProperty("parameters")) {
                if (!$util.isObject(message.parameters))
                    return "parameters: object expected";
                let key = Object.keys(message.parameters);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.parameters[key[i]]))
                        return "parameters: string{k:string} expected";
            }
            if (message.dependencies != null && message.hasOwnProperty("dependencies")) {
                let error = $root.api.GenerateImageDependencies.verify(message.dependencies);
                if (error)
                    return "dependencies." + error;
            }
            if (message.template != null && message.hasOwnProperty("template"))
                if (!$util.isString(message.template))
                    return "template: string expected";
            if (message.relatedImages != null && message.hasOwnProperty("relatedImages")) {
                if (!Array.isArray(message.relatedImages))
                    return "relatedImages: array expected";
                for (let i = 0; i < message.relatedImages.length; ++i) {
                    let error = $root.api.GeneratedImage.verify(message.relatedImages[i]);
                    if (error)
                        return "relatedImages." + error;
                }
            }
            if (message.comments != null && message.hasOwnProperty("comments")) {
                if (!Array.isArray(message.comments))
                    return "comments: array expected";
                for (let i = 0; i < message.comments.length; ++i) {
                    let error = $root.api.GeneratedImageComment.verify(message.comments[i]);
                    if (error)
                        return "comments." + error;
                }
            }
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                switch (message.reaction) {
                default:
                    return "reaction: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.userName != null && message.hasOwnProperty("userName"))
                if (!$util.isString(message.userName))
                    return "userName: string expected";
            if (message.bookmarked != null && message.hasOwnProperty("bookmarked"))
                if (typeof message.bookmarked !== "boolean")
                    return "bookmarked: boolean expected";
            if (message.pinned != null && message.hasOwnProperty("pinned"))
                if (typeof message.pinned !== "boolean")
                    return "pinned: boolean expected";
            if (message.design != null && message.hasOwnProperty("design")) {
                let error = $root.api.Design.verify(message.design);
                if (error)
                    return "design." + error;
            }
            if (message["public"] != null && message.hasOwnProperty("public"))
                if (typeof message["public"] !== "boolean")
                    return "public: boolean expected";
            if (message.publicUrl != null && message.hasOwnProperty("publicUrl"))
                if (!$util.isString(message.publicUrl))
                    return "publicUrl: string expected";
            if (message.isDesign != null && message.hasOwnProperty("isDesign"))
                if (typeof message.isDesign !== "boolean")
                    return "isDesign: boolean expected";
            if (message.category != null && message.hasOwnProperty("category"))
                if (!$util.isString(message.category))
                    return "category: string expected";
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                switch (message.mediaType) {
                default:
                    return "mediaType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates a GeneratedImage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GeneratedImage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GeneratedImage} GeneratedImage
         */
        GeneratedImage.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GeneratedImage)
                return object;
            let message = new $root.api.GeneratedImage();
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            if (object.imageUrl != null)
                message.imageUrl = String(object.imageUrl);
            if (object.thumbnailUrl != null)
                message.thumbnailUrl = String(object.thumbnailUrl);
            if (object.seed != null)
                if ($util.Long)
                    (message.seed = $util.Long.fromValue(object.seed)).unsigned = true;
                else if (typeof object.seed === "string")
                    message.seed = parseInt(object.seed, 10);
                else if (typeof object.seed === "number")
                    message.seed = object.seed;
                else if (typeof object.seed === "object")
                    message.seed = new $util.LongBits(object.seed.low >>> 0, object.seed.high >>> 0).toNumber(true);
            if (object.prompt != null)
                message.prompt = String(object.prompt);
            if (object.negativePrompt != null)
                message.negativePrompt = String(object.negativePrompt);
            if (object.inferenceSteps != null)
                message.inferenceSteps = object.inferenceSteps | 0;
            if (object.guidanceScale != null)
                message.guidanceScale = Number(object.guidanceScale);
            if (object.scheduler != null)
                message.scheduler = String(object.scheduler);
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            if (object.model != null)
                message.model = String(object.model);
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.upVotes != null)
                message.upVotes = object.upVotes | 0;
            if (object.downVotes != null)
                message.downVotes = object.downVotes | 0;
            if (object.approved != null)
                message.approved = Boolean(object.approved);
            if (object.pageViews != null)
                message.pageViews = object.pageViews | 0;
            if (object.reviewed != null)
                message.reviewed = Boolean(object.reviewed);
            if (object.shareGroupId != null)
                message.shareGroupId = String(object.shareGroupId);
            if (object.shareRequested != null)
                message.shareRequested = Boolean(object.shareRequested);
            if (object.parameters) {
                if (typeof object.parameters !== "object")
                    throw TypeError(".api.GeneratedImage.parameters: object expected");
                message.parameters = {};
                for (let keys = Object.keys(object.parameters), i = 0; i < keys.length; ++i)
                    message.parameters[keys[i]] = String(object.parameters[keys[i]]);
            }
            if (object.dependencies != null) {
                if (typeof object.dependencies !== "object")
                    throw TypeError(".api.GeneratedImage.dependencies: object expected");
                message.dependencies = $root.api.GenerateImageDependencies.fromObject(object.dependencies);
            }
            if (object.template != null)
                message.template = String(object.template);
            if (object.relatedImages) {
                if (!Array.isArray(object.relatedImages))
                    throw TypeError(".api.GeneratedImage.relatedImages: array expected");
                message.relatedImages = [];
                for (let i = 0; i < object.relatedImages.length; ++i) {
                    if (typeof object.relatedImages[i] !== "object")
                        throw TypeError(".api.GeneratedImage.relatedImages: object expected");
                    message.relatedImages[i] = $root.api.GeneratedImage.fromObject(object.relatedImages[i]);
                }
            }
            if (object.comments) {
                if (!Array.isArray(object.comments))
                    throw TypeError(".api.GeneratedImage.comments: array expected");
                message.comments = [];
                for (let i = 0; i < object.comments.length; ++i) {
                    if (typeof object.comments[i] !== "object")
                        throw TypeError(".api.GeneratedImage.comments: object expected");
                    message.comments[i] = $root.api.GeneratedImageComment.fromObject(object.comments[i]);
                }
            }
            switch (object.reaction) {
            case "NoReaction":
            case 0:
                message.reaction = 0;
                break;
            case "Like":
            case 1:
                message.reaction = 1;
                break;
            case "Dislike":
            case 2:
                message.reaction = 2;
                break;
            }
            if (object.userName != null)
                message.userName = String(object.userName);
            if (object.bookmarked != null)
                message.bookmarked = Boolean(object.bookmarked);
            if (object.pinned != null)
                message.pinned = Boolean(object.pinned);
            if (object.design != null) {
                if (typeof object.design !== "object")
                    throw TypeError(".api.GeneratedImage.design: object expected");
                message.design = $root.api.Design.fromObject(object.design);
            }
            if (object["public"] != null)
                message["public"] = Boolean(object["public"]);
            if (object.publicUrl != null)
                message.publicUrl = String(object.publicUrl);
            if (object.isDesign != null)
                message.isDesign = Boolean(object.isDesign);
            if (object.category != null)
                message.category = String(object.category);
            switch (object.mediaType) {
            case "ImageMediaType":
            case 0:
                message.mediaType = 0;
                break;
            case "VideoMediaType":
            case 1:
                message.mediaType = 1;
                break;
            case "AudioMediaType":
            case 2:
                message.mediaType = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GeneratedImage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GeneratedImage
         * @static
         * @param {api.GeneratedImage} message GeneratedImage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GeneratedImage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.relatedImages = [];
                object.comments = [];
            }
            if (options.objects || options.defaults)
                object.parameters = {};
            if (options.defaults) {
                object.imageUrl = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.seed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.seed = options.longs === String ? "0" : 0;
                object.prompt = "";
                object.negativePrompt = "";
                object.inferenceSteps = 0;
                object.guidanceScale = 0;
                object.scheduler = "";
                object.width = 0;
                object.height = 0;
                object.model = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                object.upVotes = 0;
                object.downVotes = 0;
                object.approved = false;
                object.pageViews = 0;
                object.imageId = "";
                object.reviewed = false;
                object.shareGroupId = "";
                object.thumbnailUrl = "";
                object.dependencies = null;
                object.template = "";
                object.shareRequested = false;
                object.reaction = options.enums === String ? "NoReaction" : 0;
                object.userName = "";
                object.bookmarked = false;
                object.pinned = false;
                object.design = null;
                object["public"] = false;
                object.publicUrl = "";
                object.isDesign = false;
                object.category = "";
                object.mediaType = options.enums === String ? "ImageMediaType" : 0;
            }
            if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                object.imageUrl = message.imageUrl;
            if (message.seed != null && message.hasOwnProperty("seed"))
                if (typeof message.seed === "number")
                    object.seed = options.longs === String ? String(message.seed) : message.seed;
                else
                    object.seed = options.longs === String ? $util.Long.prototype.toString.call(message.seed) : options.longs === Number ? new $util.LongBits(message.seed.low >>> 0, message.seed.high >>> 0).toNumber(true) : message.seed;
            if (message.prompt != null && message.hasOwnProperty("prompt"))
                object.prompt = message.prompt;
            if (message.negativePrompt != null && message.hasOwnProperty("negativePrompt"))
                object.negativePrompt = message.negativePrompt;
            if (message.inferenceSteps != null && message.hasOwnProperty("inferenceSteps"))
                object.inferenceSteps = message.inferenceSteps;
            if (message.guidanceScale != null && message.hasOwnProperty("guidanceScale"))
                object.guidanceScale = options.json && !isFinite(message.guidanceScale) ? String(message.guidanceScale) : message.guidanceScale;
            if (message.scheduler != null && message.hasOwnProperty("scheduler"))
                object.scheduler = message.scheduler;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.model != null && message.hasOwnProperty("model"))
                object.model = message.model;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.upVotes != null && message.hasOwnProperty("upVotes"))
                object.upVotes = message.upVotes;
            if (message.downVotes != null && message.hasOwnProperty("downVotes"))
                object.downVotes = message.downVotes;
            if (message.approved != null && message.hasOwnProperty("approved"))
                object.approved = message.approved;
            if (message.pageViews != null && message.hasOwnProperty("pageViews"))
                object.pageViews = message.pageViews;
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            if (message.reviewed != null && message.hasOwnProperty("reviewed"))
                object.reviewed = message.reviewed;
            if (message.shareGroupId != null && message.hasOwnProperty("shareGroupId"))
                object.shareGroupId = message.shareGroupId;
            let keys2;
            if (message.parameters && (keys2 = Object.keys(message.parameters)).length) {
                object.parameters = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.parameters[keys2[j]] = message.parameters[keys2[j]];
            }
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = message.thumbnailUrl;
            if (message.dependencies != null && message.hasOwnProperty("dependencies"))
                object.dependencies = $root.api.GenerateImageDependencies.toObject(message.dependencies, options);
            if (message.template != null && message.hasOwnProperty("template"))
                object.template = message.template;
            if (message.relatedImages && message.relatedImages.length) {
                object.relatedImages = [];
                for (let j = 0; j < message.relatedImages.length; ++j)
                    object.relatedImages[j] = $root.api.GeneratedImage.toObject(message.relatedImages[j], options);
            }
            if (message.shareRequested != null && message.hasOwnProperty("shareRequested"))
                object.shareRequested = message.shareRequested;
            if (message.comments && message.comments.length) {
                object.comments = [];
                for (let j = 0; j < message.comments.length; ++j)
                    object.comments[j] = $root.api.GeneratedImageComment.toObject(message.comments[j], options);
            }
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                object.reaction = options.enums === String ? $root.api.ReactionType[message.reaction] : message.reaction;
            if (message.userName != null && message.hasOwnProperty("userName"))
                object.userName = message.userName;
            if (message.bookmarked != null && message.hasOwnProperty("bookmarked"))
                object.bookmarked = message.bookmarked;
            if (message.pinned != null && message.hasOwnProperty("pinned"))
                object.pinned = message.pinned;
            if (message.design != null && message.hasOwnProperty("design"))
                object.design = $root.api.Design.toObject(message.design, options);
            if (message["public"] != null && message.hasOwnProperty("public"))
                object["public"] = message["public"];
            if (message.publicUrl != null && message.hasOwnProperty("publicUrl"))
                object.publicUrl = message.publicUrl;
            if (message.isDesign != null && message.hasOwnProperty("isDesign"))
                object.isDesign = message.isDesign;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = message.category;
            if (message.mediaType != null && message.hasOwnProperty("mediaType"))
                object.mediaType = options.enums === String ? $root.api.MediaType[message.mediaType] : message.mediaType;
            return object;
        };

        /**
         * Converts this GeneratedImage to JSON.
         * @function toJSON
         * @memberof api.GeneratedImage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GeneratedImage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GeneratedImage;
    })();

    api.GetGeneratedImagesResponse = (function() {

        /**
         * Properties of a GetGeneratedImagesResponse.
         * @memberof api
         * @interface IGetGeneratedImagesResponse
         * @property {Array.<api.IGeneratedImage>|null} [generatedImages] GetGeneratedImagesResponse generatedImages
         */

        /**
         * Constructs a new GetGeneratedImagesResponse.
         * @memberof api
         * @classdesc Represents a GetGeneratedImagesResponse.
         * @implements IGetGeneratedImagesResponse
         * @constructor
         * @param {api.IGetGeneratedImagesResponse=} [properties] Properties to set
         */
        function GetGeneratedImagesResponse(properties) {
            this.generatedImages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetGeneratedImagesResponse generatedImages.
         * @member {Array.<api.IGeneratedImage>} generatedImages
         * @memberof api.GetGeneratedImagesResponse
         * @instance
         */
        GetGeneratedImagesResponse.prototype.generatedImages = $util.emptyArray;

        /**
         * Creates a new GetGeneratedImagesResponse instance using the specified properties.
         * @function create
         * @memberof api.GetGeneratedImagesResponse
         * @static
         * @param {api.IGetGeneratedImagesResponse=} [properties] Properties to set
         * @returns {api.GetGeneratedImagesResponse} GetGeneratedImagesResponse instance
         */
        GetGeneratedImagesResponse.create = function create(properties) {
            return new GetGeneratedImagesResponse(properties);
        };

        /**
         * Encodes the specified GetGeneratedImagesResponse message. Does not implicitly {@link api.GetGeneratedImagesResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetGeneratedImagesResponse
         * @static
         * @param {api.IGetGeneratedImagesResponse} message GetGeneratedImagesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetGeneratedImagesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.generatedImages != null && message.generatedImages.length)
                for (let i = 0; i < message.generatedImages.length; ++i)
                    $root.api.GeneratedImage.encode(message.generatedImages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetGeneratedImagesResponse message, length delimited. Does not implicitly {@link api.GetGeneratedImagesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetGeneratedImagesResponse
         * @static
         * @param {api.IGetGeneratedImagesResponse} message GetGeneratedImagesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetGeneratedImagesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetGeneratedImagesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetGeneratedImagesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetGeneratedImagesResponse} GetGeneratedImagesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetGeneratedImagesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetGeneratedImagesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.generatedImages && message.generatedImages.length))
                        message.generatedImages = [];
                    message.generatedImages.push($root.api.GeneratedImage.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetGeneratedImagesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetGeneratedImagesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetGeneratedImagesResponse} GetGeneratedImagesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetGeneratedImagesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetGeneratedImagesResponse message.
         * @function verify
         * @memberof api.GetGeneratedImagesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetGeneratedImagesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.generatedImages != null && message.hasOwnProperty("generatedImages")) {
                if (!Array.isArray(message.generatedImages))
                    return "generatedImages: array expected";
                for (let i = 0; i < message.generatedImages.length; ++i) {
                    let error = $root.api.GeneratedImage.verify(message.generatedImages[i]);
                    if (error)
                        return "generatedImages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetGeneratedImagesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetGeneratedImagesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetGeneratedImagesResponse} GetGeneratedImagesResponse
         */
        GetGeneratedImagesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetGeneratedImagesResponse)
                return object;
            let message = new $root.api.GetGeneratedImagesResponse();
            if (object.generatedImages) {
                if (!Array.isArray(object.generatedImages))
                    throw TypeError(".api.GetGeneratedImagesResponse.generatedImages: array expected");
                message.generatedImages = [];
                for (let i = 0; i < object.generatedImages.length; ++i) {
                    if (typeof object.generatedImages[i] !== "object")
                        throw TypeError(".api.GetGeneratedImagesResponse.generatedImages: object expected");
                    message.generatedImages[i] = $root.api.GeneratedImage.fromObject(object.generatedImages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetGeneratedImagesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetGeneratedImagesResponse
         * @static
         * @param {api.GetGeneratedImagesResponse} message GetGeneratedImagesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetGeneratedImagesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.generatedImages = [];
            if (message.generatedImages && message.generatedImages.length) {
                object.generatedImages = [];
                for (let j = 0; j < message.generatedImages.length; ++j)
                    object.generatedImages[j] = $root.api.GeneratedImage.toObject(message.generatedImages[j], options);
            }
            return object;
        };

        /**
         * Converts this GetGeneratedImagesResponse to JSON.
         * @function toJSON
         * @memberof api.GetGeneratedImagesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetGeneratedImagesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetGeneratedImagesResponse;
    })();

    api.UpdateGeneratedImage = (function() {

        /**
         * Properties of an UpdateGeneratedImage.
         * @memberof api
         * @interface IUpdateGeneratedImage
         * @property {boolean|null} [approved] UpdateGeneratedImage approved
         * @property {boolean|null} [reviewed] UpdateGeneratedImage reviewed
         * @property {boolean|null} [shareRequested] UpdateGeneratedImage shareRequested
         * @property {boolean|null} [reaction] UpdateGeneratedImage reaction
         * @property {boolean|null} [bookmarked] UpdateGeneratedImage bookmarked
         * @property {boolean|null} [pinned] UpdateGeneratedImage pinned
         * @property {boolean|null} ["public"] UpdateGeneratedImage public
         * @property {boolean|null} [upVotes] UpdateGeneratedImage upVotes
         * @property {boolean|null} [downVotes] UpdateGeneratedImage downVotes
         * @property {boolean|null} [category] UpdateGeneratedImage category
         */

        /**
         * Constructs a new UpdateGeneratedImage.
         * @memberof api
         * @classdesc Represents an UpdateGeneratedImage.
         * @implements IUpdateGeneratedImage
         * @constructor
         * @param {api.IUpdateGeneratedImage=} [properties] Properties to set
         */
        function UpdateGeneratedImage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateGeneratedImage approved.
         * @member {boolean} approved
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.approved = false;

        /**
         * UpdateGeneratedImage reviewed.
         * @member {boolean} reviewed
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.reviewed = false;

        /**
         * UpdateGeneratedImage shareRequested.
         * @member {boolean} shareRequested
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.shareRequested = false;

        /**
         * UpdateGeneratedImage reaction.
         * @member {boolean} reaction
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.reaction = false;

        /**
         * UpdateGeneratedImage bookmarked.
         * @member {boolean} bookmarked
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.bookmarked = false;

        /**
         * UpdateGeneratedImage pinned.
         * @member {boolean} pinned
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.pinned = false;

        /**
         * UpdateGeneratedImage public.
         * @member {boolean} public
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype["public"] = false;

        /**
         * UpdateGeneratedImage upVotes.
         * @member {boolean} upVotes
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.upVotes = false;

        /**
         * UpdateGeneratedImage downVotes.
         * @member {boolean} downVotes
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.downVotes = false;

        /**
         * UpdateGeneratedImage category.
         * @member {boolean} category
         * @memberof api.UpdateGeneratedImage
         * @instance
         */
        UpdateGeneratedImage.prototype.category = false;

        /**
         * Creates a new UpdateGeneratedImage instance using the specified properties.
         * @function create
         * @memberof api.UpdateGeneratedImage
         * @static
         * @param {api.IUpdateGeneratedImage=} [properties] Properties to set
         * @returns {api.UpdateGeneratedImage} UpdateGeneratedImage instance
         */
        UpdateGeneratedImage.create = function create(properties) {
            return new UpdateGeneratedImage(properties);
        };

        /**
         * Encodes the specified UpdateGeneratedImage message. Does not implicitly {@link api.UpdateGeneratedImage.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateGeneratedImage
         * @static
         * @param {api.IUpdateGeneratedImage} message UpdateGeneratedImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.approved != null && Object.hasOwnProperty.call(message, "approved"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.approved);
            if (message.reviewed != null && Object.hasOwnProperty.call(message, "reviewed"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.reviewed);
            if (message.shareRequested != null && Object.hasOwnProperty.call(message, "shareRequested"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.shareRequested);
            if (message.reaction != null && Object.hasOwnProperty.call(message, "reaction"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.reaction);
            if (message.bookmarked != null && Object.hasOwnProperty.call(message, "bookmarked"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.bookmarked);
            if (message.pinned != null && Object.hasOwnProperty.call(message, "pinned"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.pinned);
            if (message["public"] != null && Object.hasOwnProperty.call(message, "public"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message["public"]);
            if (message.upVotes != null && Object.hasOwnProperty.call(message, "upVotes"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.upVotes);
            if (message.downVotes != null && Object.hasOwnProperty.call(message, "downVotes"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.downVotes);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.category);
            return writer;
        };

        /**
         * Encodes the specified UpdateGeneratedImage message, length delimited. Does not implicitly {@link api.UpdateGeneratedImage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateGeneratedImage
         * @static
         * @param {api.IUpdateGeneratedImage} message UpdateGeneratedImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateGeneratedImage message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateGeneratedImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateGeneratedImage} UpdateGeneratedImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateGeneratedImage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.approved = reader.bool();
                    break;
                case 2:
                    message.reviewed = reader.bool();
                    break;
                case 3:
                    message.shareRequested = reader.bool();
                    break;
                case 4:
                    message.reaction = reader.bool();
                    break;
                case 5:
                    message.bookmarked = reader.bool();
                    break;
                case 6:
                    message.pinned = reader.bool();
                    break;
                case 7:
                    message["public"] = reader.bool();
                    break;
                case 8:
                    message.upVotes = reader.bool();
                    break;
                case 9:
                    message.downVotes = reader.bool();
                    break;
                case 10:
                    message.category = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateGeneratedImage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateGeneratedImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateGeneratedImage} UpdateGeneratedImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateGeneratedImage message.
         * @function verify
         * @memberof api.UpdateGeneratedImage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateGeneratedImage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.approved != null && message.hasOwnProperty("approved"))
                if (typeof message.approved !== "boolean")
                    return "approved: boolean expected";
            if (message.reviewed != null && message.hasOwnProperty("reviewed"))
                if (typeof message.reviewed !== "boolean")
                    return "reviewed: boolean expected";
            if (message.shareRequested != null && message.hasOwnProperty("shareRequested"))
                if (typeof message.shareRequested !== "boolean")
                    return "shareRequested: boolean expected";
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                if (typeof message.reaction !== "boolean")
                    return "reaction: boolean expected";
            if (message.bookmarked != null && message.hasOwnProperty("bookmarked"))
                if (typeof message.bookmarked !== "boolean")
                    return "bookmarked: boolean expected";
            if (message.pinned != null && message.hasOwnProperty("pinned"))
                if (typeof message.pinned !== "boolean")
                    return "pinned: boolean expected";
            if (message["public"] != null && message.hasOwnProperty("public"))
                if (typeof message["public"] !== "boolean")
                    return "public: boolean expected";
            if (message.upVotes != null && message.hasOwnProperty("upVotes"))
                if (typeof message.upVotes !== "boolean")
                    return "upVotes: boolean expected";
            if (message.downVotes != null && message.hasOwnProperty("downVotes"))
                if (typeof message.downVotes !== "boolean")
                    return "downVotes: boolean expected";
            if (message.category != null && message.hasOwnProperty("category"))
                if (typeof message.category !== "boolean")
                    return "category: boolean expected";
            return null;
        };

        /**
         * Creates an UpdateGeneratedImage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateGeneratedImage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateGeneratedImage} UpdateGeneratedImage
         */
        UpdateGeneratedImage.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateGeneratedImage)
                return object;
            let message = new $root.api.UpdateGeneratedImage();
            if (object.approved != null)
                message.approved = Boolean(object.approved);
            if (object.reviewed != null)
                message.reviewed = Boolean(object.reviewed);
            if (object.shareRequested != null)
                message.shareRequested = Boolean(object.shareRequested);
            if (object.reaction != null)
                message.reaction = Boolean(object.reaction);
            if (object.bookmarked != null)
                message.bookmarked = Boolean(object.bookmarked);
            if (object.pinned != null)
                message.pinned = Boolean(object.pinned);
            if (object["public"] != null)
                message["public"] = Boolean(object["public"]);
            if (object.upVotes != null)
                message.upVotes = Boolean(object.upVotes);
            if (object.downVotes != null)
                message.downVotes = Boolean(object.downVotes);
            if (object.category != null)
                message.category = Boolean(object.category);
            return message;
        };

        /**
         * Creates a plain object from an UpdateGeneratedImage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateGeneratedImage
         * @static
         * @param {api.UpdateGeneratedImage} message UpdateGeneratedImage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateGeneratedImage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.approved = false;
                object.reviewed = false;
                object.shareRequested = false;
                object.reaction = false;
                object.bookmarked = false;
                object.pinned = false;
                object["public"] = false;
                object.upVotes = false;
                object.downVotes = false;
                object.category = false;
            }
            if (message.approved != null && message.hasOwnProperty("approved"))
                object.approved = message.approved;
            if (message.reviewed != null && message.hasOwnProperty("reviewed"))
                object.reviewed = message.reviewed;
            if (message.shareRequested != null && message.hasOwnProperty("shareRequested"))
                object.shareRequested = message.shareRequested;
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                object.reaction = message.reaction;
            if (message.bookmarked != null && message.hasOwnProperty("bookmarked"))
                object.bookmarked = message.bookmarked;
            if (message.pinned != null && message.hasOwnProperty("pinned"))
                object.pinned = message.pinned;
            if (message["public"] != null && message.hasOwnProperty("public"))
                object["public"] = message["public"];
            if (message.upVotes != null && message.hasOwnProperty("upVotes"))
                object.upVotes = message.upVotes;
            if (message.downVotes != null && message.hasOwnProperty("downVotes"))
                object.downVotes = message.downVotes;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = message.category;
            return object;
        };

        /**
         * Converts this UpdateGeneratedImage to JSON.
         * @function toJSON
         * @memberof api.UpdateGeneratedImage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateGeneratedImage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateGeneratedImage;
    })();

    api.UpdateGeneratedImageRequest = (function() {

        /**
         * Properties of an UpdateGeneratedImageRequest.
         * @memberof api
         * @interface IUpdateGeneratedImageRequest
         * @property {api.IUpdateGeneratedImage|null} [update] UpdateGeneratedImageRequest update
         * @property {api.IGeneratedImage|null} [generatedImage] UpdateGeneratedImageRequest generatedImage
         */

        /**
         * Constructs a new UpdateGeneratedImageRequest.
         * @memberof api
         * @classdesc Represents an UpdateGeneratedImageRequest.
         * @implements IUpdateGeneratedImageRequest
         * @constructor
         * @param {api.IUpdateGeneratedImageRequest=} [properties] Properties to set
         */
        function UpdateGeneratedImageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateGeneratedImageRequest update.
         * @member {api.IUpdateGeneratedImage|null|undefined} update
         * @memberof api.UpdateGeneratedImageRequest
         * @instance
         */
        UpdateGeneratedImageRequest.prototype.update = null;

        /**
         * UpdateGeneratedImageRequest generatedImage.
         * @member {api.IGeneratedImage|null|undefined} generatedImage
         * @memberof api.UpdateGeneratedImageRequest
         * @instance
         */
        UpdateGeneratedImageRequest.prototype.generatedImage = null;

        /**
         * Creates a new UpdateGeneratedImageRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateGeneratedImageRequest
         * @static
         * @param {api.IUpdateGeneratedImageRequest=} [properties] Properties to set
         * @returns {api.UpdateGeneratedImageRequest} UpdateGeneratedImageRequest instance
         */
        UpdateGeneratedImageRequest.create = function create(properties) {
            return new UpdateGeneratedImageRequest(properties);
        };

        /**
         * Encodes the specified UpdateGeneratedImageRequest message. Does not implicitly {@link api.UpdateGeneratedImageRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateGeneratedImageRequest
         * @static
         * @param {api.IUpdateGeneratedImageRequest} message UpdateGeneratedImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.UpdateGeneratedImage.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.generatedImage != null && Object.hasOwnProperty.call(message, "generatedImage"))
                $root.api.GeneratedImage.encode(message.generatedImage, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateGeneratedImageRequest message, length delimited. Does not implicitly {@link api.UpdateGeneratedImageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateGeneratedImageRequest
         * @static
         * @param {api.IUpdateGeneratedImageRequest} message UpdateGeneratedImageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateGeneratedImageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateGeneratedImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateGeneratedImageRequest} UpdateGeneratedImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateGeneratedImageRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.UpdateGeneratedImage.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.generatedImage = $root.api.GeneratedImage.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateGeneratedImageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateGeneratedImageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateGeneratedImageRequest} UpdateGeneratedImageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateGeneratedImageRequest message.
         * @function verify
         * @memberof api.UpdateGeneratedImageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateGeneratedImageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.UpdateGeneratedImage.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.generatedImage != null && message.hasOwnProperty("generatedImage")) {
                let error = $root.api.GeneratedImage.verify(message.generatedImage);
                if (error)
                    return "generatedImage." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateGeneratedImageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateGeneratedImageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateGeneratedImageRequest} UpdateGeneratedImageRequest
         */
        UpdateGeneratedImageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateGeneratedImageRequest)
                return object;
            let message = new $root.api.UpdateGeneratedImageRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateGeneratedImageRequest.update: object expected");
                message.update = $root.api.UpdateGeneratedImage.fromObject(object.update);
            }
            if (object.generatedImage != null) {
                if (typeof object.generatedImage !== "object")
                    throw TypeError(".api.UpdateGeneratedImageRequest.generatedImage: object expected");
                message.generatedImage = $root.api.GeneratedImage.fromObject(object.generatedImage);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateGeneratedImageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateGeneratedImageRequest
         * @static
         * @param {api.UpdateGeneratedImageRequest} message UpdateGeneratedImageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateGeneratedImageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.generatedImage = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.UpdateGeneratedImage.toObject(message.update, options);
            if (message.generatedImage != null && message.hasOwnProperty("generatedImage"))
                object.generatedImage = $root.api.GeneratedImage.toObject(message.generatedImage, options);
            return object;
        };

        /**
         * Converts this UpdateGeneratedImageRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateGeneratedImageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateGeneratedImageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateGeneratedImageRequest;
    })();

    api.UpdateGeneratedImageResponse = (function() {

        /**
         * Properties of an UpdateGeneratedImageResponse.
         * @memberof api
         * @interface IUpdateGeneratedImageResponse
         */

        /**
         * Constructs a new UpdateGeneratedImageResponse.
         * @memberof api
         * @classdesc Represents an UpdateGeneratedImageResponse.
         * @implements IUpdateGeneratedImageResponse
         * @constructor
         * @param {api.IUpdateGeneratedImageResponse=} [properties] Properties to set
         */
        function UpdateGeneratedImageResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateGeneratedImageResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateGeneratedImageResponse
         * @static
         * @param {api.IUpdateGeneratedImageResponse=} [properties] Properties to set
         * @returns {api.UpdateGeneratedImageResponse} UpdateGeneratedImageResponse instance
         */
        UpdateGeneratedImageResponse.create = function create(properties) {
            return new UpdateGeneratedImageResponse(properties);
        };

        /**
         * Encodes the specified UpdateGeneratedImageResponse message. Does not implicitly {@link api.UpdateGeneratedImageResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateGeneratedImageResponse
         * @static
         * @param {api.IUpdateGeneratedImageResponse} message UpdateGeneratedImageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateGeneratedImageResponse message, length delimited. Does not implicitly {@link api.UpdateGeneratedImageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateGeneratedImageResponse
         * @static
         * @param {api.IUpdateGeneratedImageResponse} message UpdateGeneratedImageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGeneratedImageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateGeneratedImageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateGeneratedImageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateGeneratedImageResponse} UpdateGeneratedImageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateGeneratedImageResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateGeneratedImageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateGeneratedImageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateGeneratedImageResponse} UpdateGeneratedImageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGeneratedImageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateGeneratedImageResponse message.
         * @function verify
         * @memberof api.UpdateGeneratedImageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateGeneratedImageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateGeneratedImageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateGeneratedImageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateGeneratedImageResponse} UpdateGeneratedImageResponse
         */
        UpdateGeneratedImageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateGeneratedImageResponse)
                return object;
            return new $root.api.UpdateGeneratedImageResponse();
        };

        /**
         * Creates a plain object from an UpdateGeneratedImageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateGeneratedImageResponse
         * @static
         * @param {api.UpdateGeneratedImageResponse} message UpdateGeneratedImageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateGeneratedImageResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateGeneratedImageResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateGeneratedImageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateGeneratedImageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateGeneratedImageResponse;
    })();

    api.GetFileUrlRequest = (function() {

        /**
         * Properties of a GetFileUrlRequest.
         * @memberof api
         * @interface IGetFileUrlRequest
         * @property {string|null} [fileId] GetFileUrlRequest fileId
         */

        /**
         * Constructs a new GetFileUrlRequest.
         * @memberof api
         * @classdesc Represents a GetFileUrlRequest.
         * @implements IGetFileUrlRequest
         * @constructor
         * @param {api.IGetFileUrlRequest=} [properties] Properties to set
         */
        function GetFileUrlRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetFileUrlRequest fileId.
         * @member {string} fileId
         * @memberof api.GetFileUrlRequest
         * @instance
         */
        GetFileUrlRequest.prototype.fileId = "";

        /**
         * Creates a new GetFileUrlRequest instance using the specified properties.
         * @function create
         * @memberof api.GetFileUrlRequest
         * @static
         * @param {api.IGetFileUrlRequest=} [properties] Properties to set
         * @returns {api.GetFileUrlRequest} GetFileUrlRequest instance
         */
        GetFileUrlRequest.create = function create(properties) {
            return new GetFileUrlRequest(properties);
        };

        /**
         * Encodes the specified GetFileUrlRequest message. Does not implicitly {@link api.GetFileUrlRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetFileUrlRequest
         * @static
         * @param {api.IGetFileUrlRequest} message GetFileUrlRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFileUrlRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified GetFileUrlRequest message, length delimited. Does not implicitly {@link api.GetFileUrlRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetFileUrlRequest
         * @static
         * @param {api.IGetFileUrlRequest} message GetFileUrlRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFileUrlRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetFileUrlRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetFileUrlRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetFileUrlRequest} GetFileUrlRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFileUrlRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetFileUrlRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetFileUrlRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetFileUrlRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetFileUrlRequest} GetFileUrlRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFileUrlRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetFileUrlRequest message.
         * @function verify
         * @memberof api.GetFileUrlRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetFileUrlRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            return null;
        };

        /**
         * Creates a GetFileUrlRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetFileUrlRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetFileUrlRequest} GetFileUrlRequest
         */
        GetFileUrlRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetFileUrlRequest)
                return object;
            let message = new $root.api.GetFileUrlRequest();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            return message;
        };

        /**
         * Creates a plain object from a GetFileUrlRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetFileUrlRequest
         * @static
         * @param {api.GetFileUrlRequest} message GetFileUrlRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetFileUrlRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileId = "";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this GetFileUrlRequest to JSON.
         * @function toJSON
         * @memberof api.GetFileUrlRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetFileUrlRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetFileUrlRequest;
    })();

    api.GetFileUrlResponse = (function() {

        /**
         * Properties of a GetFileUrlResponse.
         * @memberof api
         * @interface IGetFileUrlResponse
         * @property {string|null} [url] GetFileUrlResponse url
         * @property {string|null} [thumbnailUrl] GetFileUrlResponse thumbnailUrl
         * @property {number|null} [width] GetFileUrlResponse width
         * @property {number|null} [height] GetFileUrlResponse height
         * @property {string|null} [mimeType] GetFileUrlResponse mimeType
         */

        /**
         * Constructs a new GetFileUrlResponse.
         * @memberof api
         * @classdesc Represents a GetFileUrlResponse.
         * @implements IGetFileUrlResponse
         * @constructor
         * @param {api.IGetFileUrlResponse=} [properties] Properties to set
         */
        function GetFileUrlResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetFileUrlResponse url.
         * @member {string} url
         * @memberof api.GetFileUrlResponse
         * @instance
         */
        GetFileUrlResponse.prototype.url = "";

        /**
         * GetFileUrlResponse thumbnailUrl.
         * @member {string} thumbnailUrl
         * @memberof api.GetFileUrlResponse
         * @instance
         */
        GetFileUrlResponse.prototype.thumbnailUrl = "";

        /**
         * GetFileUrlResponse width.
         * @member {number} width
         * @memberof api.GetFileUrlResponse
         * @instance
         */
        GetFileUrlResponse.prototype.width = 0;

        /**
         * GetFileUrlResponse height.
         * @member {number} height
         * @memberof api.GetFileUrlResponse
         * @instance
         */
        GetFileUrlResponse.prototype.height = 0;

        /**
         * GetFileUrlResponse mimeType.
         * @member {string} mimeType
         * @memberof api.GetFileUrlResponse
         * @instance
         */
        GetFileUrlResponse.prototype.mimeType = "";

        /**
         * Creates a new GetFileUrlResponse instance using the specified properties.
         * @function create
         * @memberof api.GetFileUrlResponse
         * @static
         * @param {api.IGetFileUrlResponse=} [properties] Properties to set
         * @returns {api.GetFileUrlResponse} GetFileUrlResponse instance
         */
        GetFileUrlResponse.create = function create(properties) {
            return new GetFileUrlResponse(properties);
        };

        /**
         * Encodes the specified GetFileUrlResponse message. Does not implicitly {@link api.GetFileUrlResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetFileUrlResponse
         * @static
         * @param {api.IGetFileUrlResponse} message GetFileUrlResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFileUrlResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.thumbnailUrl);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.height);
            if (message.mimeType != null && Object.hasOwnProperty.call(message, "mimeType"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.mimeType);
            return writer;
        };

        /**
         * Encodes the specified GetFileUrlResponse message, length delimited. Does not implicitly {@link api.GetFileUrlResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetFileUrlResponse
         * @static
         * @param {api.IGetFileUrlResponse} message GetFileUrlResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetFileUrlResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetFileUrlResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetFileUrlResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetFileUrlResponse} GetFileUrlResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFileUrlResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetFileUrlResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.thumbnailUrl = reader.string();
                    break;
                case 3:
                    message.width = reader.int32();
                    break;
                case 4:
                    message.height = reader.int32();
                    break;
                case 5:
                    message.mimeType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetFileUrlResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetFileUrlResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetFileUrlResponse} GetFileUrlResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetFileUrlResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetFileUrlResponse message.
         * @function verify
         * @memberof api.GetFileUrlResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetFileUrlResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                if (!$util.isString(message.thumbnailUrl))
                    return "thumbnailUrl: string expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                if (!$util.isString(message.mimeType))
                    return "mimeType: string expected";
            return null;
        };

        /**
         * Creates a GetFileUrlResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetFileUrlResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetFileUrlResponse} GetFileUrlResponse
         */
        GetFileUrlResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetFileUrlResponse)
                return object;
            let message = new $root.api.GetFileUrlResponse();
            if (object.url != null)
                message.url = String(object.url);
            if (object.thumbnailUrl != null)
                message.thumbnailUrl = String(object.thumbnailUrl);
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            if (object.mimeType != null)
                message.mimeType = String(object.mimeType);
            return message;
        };

        /**
         * Creates a plain object from a GetFileUrlResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetFileUrlResponse
         * @static
         * @param {api.GetFileUrlResponse} message GetFileUrlResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetFileUrlResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                object.thumbnailUrl = "";
                object.width = 0;
                object.height = 0;
                object.mimeType = "";
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = message.thumbnailUrl;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.mimeType != null && message.hasOwnProperty("mimeType"))
                object.mimeType = message.mimeType;
            return object;
        };

        /**
         * Converts this GetFileUrlResponse to JSON.
         * @function toJSON
         * @memberof api.GetFileUrlResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetFileUrlResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetFileUrlResponse;
    })();

    api.GetProjectRequest = (function() {

        /**
         * Properties of a GetProjectRequest.
         * @memberof api
         * @interface IGetProjectRequest
         * @property {string|null} [fileId] GetProjectRequest fileId
         */

        /**
         * Constructs a new GetProjectRequest.
         * @memberof api
         * @classdesc Represents a GetProjectRequest.
         * @implements IGetProjectRequest
         * @constructor
         * @param {api.IGetProjectRequest=} [properties] Properties to set
         */
        function GetProjectRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectRequest fileId.
         * @member {string} fileId
         * @memberof api.GetProjectRequest
         * @instance
         */
        GetProjectRequest.prototype.fileId = "";

        /**
         * Creates a new GetProjectRequest instance using the specified properties.
         * @function create
         * @memberof api.GetProjectRequest
         * @static
         * @param {api.IGetProjectRequest=} [properties] Properties to set
         * @returns {api.GetProjectRequest} GetProjectRequest instance
         */
        GetProjectRequest.create = function create(properties) {
            return new GetProjectRequest(properties);
        };

        /**
         * Encodes the specified GetProjectRequest message. Does not implicitly {@link api.GetProjectRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetProjectRequest
         * @static
         * @param {api.IGetProjectRequest} message GetProjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified GetProjectRequest message, length delimited. Does not implicitly {@link api.GetProjectRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetProjectRequest
         * @static
         * @param {api.IGetProjectRequest} message GetProjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetProjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetProjectRequest} GetProjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetProjectRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetProjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetProjectRequest} GetProjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectRequest message.
         * @function verify
         * @memberof api.GetProjectRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            return null;
        };

        /**
         * Creates a GetProjectRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetProjectRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetProjectRequest} GetProjectRequest
         */
        GetProjectRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetProjectRequest)
                return object;
            let message = new $root.api.GetProjectRequest();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            return message;
        };

        /**
         * Creates a plain object from a GetProjectRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetProjectRequest
         * @static
         * @param {api.GetProjectRequest} message GetProjectRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileId = "";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this GetProjectRequest to JSON.
         * @function toJSON
         * @memberof api.GetProjectRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetProjectRequest;
    })();

    api.GetProjectResponse = (function() {

        /**
         * Properties of a GetProjectResponse.
         * @memberof api
         * @interface IGetProjectResponse
         * @property {api.IProjectInfo|null} [projectInfo] GetProjectResponse projectInfo
         */

        /**
         * Constructs a new GetProjectResponse.
         * @memberof api
         * @classdesc Represents a GetProjectResponse.
         * @implements IGetProjectResponse
         * @constructor
         * @param {api.IGetProjectResponse=} [properties] Properties to set
         */
        function GetProjectResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProjectResponse projectInfo.
         * @member {api.IProjectInfo|null|undefined} projectInfo
         * @memberof api.GetProjectResponse
         * @instance
         */
        GetProjectResponse.prototype.projectInfo = null;

        /**
         * Creates a new GetProjectResponse instance using the specified properties.
         * @function create
         * @memberof api.GetProjectResponse
         * @static
         * @param {api.IGetProjectResponse=} [properties] Properties to set
         * @returns {api.GetProjectResponse} GetProjectResponse instance
         */
        GetProjectResponse.create = function create(properties) {
            return new GetProjectResponse(properties);
        };

        /**
         * Encodes the specified GetProjectResponse message. Does not implicitly {@link api.GetProjectResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetProjectResponse
         * @static
         * @param {api.IGetProjectResponse} message GetProjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.projectInfo != null && Object.hasOwnProperty.call(message, "projectInfo"))
                $root.api.ProjectInfo.encode(message.projectInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetProjectResponse message, length delimited. Does not implicitly {@link api.GetProjectResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetProjectResponse
         * @static
         * @param {api.IGetProjectResponse} message GetProjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProjectResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetProjectResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetProjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetProjectResponse} GetProjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetProjectResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.projectInfo = $root.api.ProjectInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetProjectResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetProjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetProjectResponse} GetProjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProjectResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetProjectResponse message.
         * @function verify
         * @memberof api.GetProjectResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetProjectResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.projectInfo != null && message.hasOwnProperty("projectInfo")) {
                let error = $root.api.ProjectInfo.verify(message.projectInfo);
                if (error)
                    return "projectInfo." + error;
            }
            return null;
        };

        /**
         * Creates a GetProjectResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetProjectResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetProjectResponse} GetProjectResponse
         */
        GetProjectResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetProjectResponse)
                return object;
            let message = new $root.api.GetProjectResponse();
            if (object.projectInfo != null) {
                if (typeof object.projectInfo !== "object")
                    throw TypeError(".api.GetProjectResponse.projectInfo: object expected");
                message.projectInfo = $root.api.ProjectInfo.fromObject(object.projectInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetProjectResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetProjectResponse
         * @static
         * @param {api.GetProjectResponse} message GetProjectResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetProjectResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.projectInfo = null;
            if (message.projectInfo != null && message.hasOwnProperty("projectInfo"))
                object.projectInfo = $root.api.ProjectInfo.toObject(message.projectInfo, options);
            return object;
        };

        /**
         * Converts this GetProjectResponse to JSON.
         * @function toJSON
         * @memberof api.GetProjectResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetProjectResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetProjectResponse;
    })();

    api.UpdateProjectRequest = (function() {

        /**
         * Properties of an UpdateProjectRequest.
         * @memberof api
         * @interface IUpdateProjectRequest
         * @property {string|null} [fileId] UpdateProjectRequest fileId
         * @property {api.IProjectInfo|null} [projectInfo] UpdateProjectRequest projectInfo
         */

        /**
         * Constructs a new UpdateProjectRequest.
         * @memberof api
         * @classdesc Represents an UpdateProjectRequest.
         * @implements IUpdateProjectRequest
         * @constructor
         * @param {api.IUpdateProjectRequest=} [properties] Properties to set
         */
        function UpdateProjectRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateProjectRequest fileId.
         * @member {string} fileId
         * @memberof api.UpdateProjectRequest
         * @instance
         */
        UpdateProjectRequest.prototype.fileId = "";

        /**
         * UpdateProjectRequest projectInfo.
         * @member {api.IProjectInfo|null|undefined} projectInfo
         * @memberof api.UpdateProjectRequest
         * @instance
         */
        UpdateProjectRequest.prototype.projectInfo = null;

        /**
         * Creates a new UpdateProjectRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateProjectRequest
         * @static
         * @param {api.IUpdateProjectRequest=} [properties] Properties to set
         * @returns {api.UpdateProjectRequest} UpdateProjectRequest instance
         */
        UpdateProjectRequest.create = function create(properties) {
            return new UpdateProjectRequest(properties);
        };

        /**
         * Encodes the specified UpdateProjectRequest message. Does not implicitly {@link api.UpdateProjectRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateProjectRequest
         * @static
         * @param {api.IUpdateProjectRequest} message UpdateProjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProjectRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.projectInfo != null && Object.hasOwnProperty.call(message, "projectInfo"))
                $root.api.ProjectInfo.encode(message.projectInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateProjectRequest message, length delimited. Does not implicitly {@link api.UpdateProjectRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateProjectRequest
         * @static
         * @param {api.IUpdateProjectRequest} message UpdateProjectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProjectRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateProjectRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateProjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateProjectRequest} UpdateProjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProjectRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateProjectRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                case 2:
                    message.projectInfo = $root.api.ProjectInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateProjectRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateProjectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateProjectRequest} UpdateProjectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProjectRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateProjectRequest message.
         * @function verify
         * @memberof api.UpdateProjectRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateProjectRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.projectInfo != null && message.hasOwnProperty("projectInfo")) {
                let error = $root.api.ProjectInfo.verify(message.projectInfo);
                if (error)
                    return "projectInfo." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateProjectRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateProjectRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateProjectRequest} UpdateProjectRequest
         */
        UpdateProjectRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateProjectRequest)
                return object;
            let message = new $root.api.UpdateProjectRequest();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.projectInfo != null) {
                if (typeof object.projectInfo !== "object")
                    throw TypeError(".api.UpdateProjectRequest.projectInfo: object expected");
                message.projectInfo = $root.api.ProjectInfo.fromObject(object.projectInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateProjectRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateProjectRequest
         * @static
         * @param {api.UpdateProjectRequest} message UpdateProjectRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateProjectRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.projectInfo = null;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.projectInfo != null && message.hasOwnProperty("projectInfo"))
                object.projectInfo = $root.api.ProjectInfo.toObject(message.projectInfo, options);
            return object;
        };

        /**
         * Converts this UpdateProjectRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateProjectRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateProjectRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateProjectRequest;
    })();

    api.UpdateProjectResponse = (function() {

        /**
         * Properties of an UpdateProjectResponse.
         * @memberof api
         * @interface IUpdateProjectResponse
         */

        /**
         * Constructs a new UpdateProjectResponse.
         * @memberof api
         * @classdesc Represents an UpdateProjectResponse.
         * @implements IUpdateProjectResponse
         * @constructor
         * @param {api.IUpdateProjectResponse=} [properties] Properties to set
         */
        function UpdateProjectResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateProjectResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateProjectResponse
         * @static
         * @param {api.IUpdateProjectResponse=} [properties] Properties to set
         * @returns {api.UpdateProjectResponse} UpdateProjectResponse instance
         */
        UpdateProjectResponse.create = function create(properties) {
            return new UpdateProjectResponse(properties);
        };

        /**
         * Encodes the specified UpdateProjectResponse message. Does not implicitly {@link api.UpdateProjectResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateProjectResponse
         * @static
         * @param {api.IUpdateProjectResponse} message UpdateProjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProjectResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateProjectResponse message, length delimited. Does not implicitly {@link api.UpdateProjectResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateProjectResponse
         * @static
         * @param {api.IUpdateProjectResponse} message UpdateProjectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProjectResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateProjectResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateProjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateProjectResponse} UpdateProjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProjectResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateProjectResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateProjectResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateProjectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateProjectResponse} UpdateProjectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProjectResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateProjectResponse message.
         * @function verify
         * @memberof api.UpdateProjectResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateProjectResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateProjectResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateProjectResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateProjectResponse} UpdateProjectResponse
         */
        UpdateProjectResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateProjectResponse)
                return object;
            return new $root.api.UpdateProjectResponse();
        };

        /**
         * Creates a plain object from an UpdateProjectResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateProjectResponse
         * @static
         * @param {api.UpdateProjectResponse} message UpdateProjectResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateProjectResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateProjectResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateProjectResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateProjectResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateProjectResponse;
    })();

    api.ExecuteAdminTaskRequest = (function() {

        /**
         * Properties of an ExecuteAdminTaskRequest.
         * @memberof api
         * @interface IExecuteAdminTaskRequest
         * @property {number|null} [taskType] ExecuteAdminTaskRequest taskType
         */

        /**
         * Constructs a new ExecuteAdminTaskRequest.
         * @memberof api
         * @classdesc Represents an ExecuteAdminTaskRequest.
         * @implements IExecuteAdminTaskRequest
         * @constructor
         * @param {api.IExecuteAdminTaskRequest=} [properties] Properties to set
         */
        function ExecuteAdminTaskRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecuteAdminTaskRequest taskType.
         * @member {number} taskType
         * @memberof api.ExecuteAdminTaskRequest
         * @instance
         */
        ExecuteAdminTaskRequest.prototype.taskType = 0;

        /**
         * Creates a new ExecuteAdminTaskRequest instance using the specified properties.
         * @function create
         * @memberof api.ExecuteAdminTaskRequest
         * @static
         * @param {api.IExecuteAdminTaskRequest=} [properties] Properties to set
         * @returns {api.ExecuteAdminTaskRequest} ExecuteAdminTaskRequest instance
         */
        ExecuteAdminTaskRequest.create = function create(properties) {
            return new ExecuteAdminTaskRequest(properties);
        };

        /**
         * Encodes the specified ExecuteAdminTaskRequest message. Does not implicitly {@link api.ExecuteAdminTaskRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ExecuteAdminTaskRequest
         * @static
         * @param {api.IExecuteAdminTaskRequest} message ExecuteAdminTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteAdminTaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskType != null && Object.hasOwnProperty.call(message, "taskType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.taskType);
            return writer;
        };

        /**
         * Encodes the specified ExecuteAdminTaskRequest message, length delimited. Does not implicitly {@link api.ExecuteAdminTaskRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ExecuteAdminTaskRequest
         * @static
         * @param {api.IExecuteAdminTaskRequest} message ExecuteAdminTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteAdminTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteAdminTaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ExecuteAdminTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ExecuteAdminTaskRequest} ExecuteAdminTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteAdminTaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ExecuteAdminTaskRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.taskType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteAdminTaskRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ExecuteAdminTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ExecuteAdminTaskRequest} ExecuteAdminTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteAdminTaskRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteAdminTaskRequest message.
         * @function verify
         * @memberof api.ExecuteAdminTaskRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteAdminTaskRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.taskType != null && message.hasOwnProperty("taskType"))
                if (!$util.isInteger(message.taskType))
                    return "taskType: integer expected";
            return null;
        };

        /**
         * Creates an ExecuteAdminTaskRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ExecuteAdminTaskRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ExecuteAdminTaskRequest} ExecuteAdminTaskRequest
         */
        ExecuteAdminTaskRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ExecuteAdminTaskRequest)
                return object;
            let message = new $root.api.ExecuteAdminTaskRequest();
            if (object.taskType != null)
                message.taskType = object.taskType | 0;
            return message;
        };

        /**
         * Creates a plain object from an ExecuteAdminTaskRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ExecuteAdminTaskRequest
         * @static
         * @param {api.ExecuteAdminTaskRequest} message ExecuteAdminTaskRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteAdminTaskRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.taskType = 0;
            if (message.taskType != null && message.hasOwnProperty("taskType"))
                object.taskType = message.taskType;
            return object;
        };

        /**
         * Converts this ExecuteAdminTaskRequest to JSON.
         * @function toJSON
         * @memberof api.ExecuteAdminTaskRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteAdminTaskRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExecuteAdminTaskRequest;
    })();

    api.ExecuteAdminTaskResponse = (function() {

        /**
         * Properties of an ExecuteAdminTaskResponse.
         * @memberof api
         * @interface IExecuteAdminTaskResponse
         */

        /**
         * Constructs a new ExecuteAdminTaskResponse.
         * @memberof api
         * @classdesc Represents an ExecuteAdminTaskResponse.
         * @implements IExecuteAdminTaskResponse
         * @constructor
         * @param {api.IExecuteAdminTaskResponse=} [properties] Properties to set
         */
        function ExecuteAdminTaskResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ExecuteAdminTaskResponse instance using the specified properties.
         * @function create
         * @memberof api.ExecuteAdminTaskResponse
         * @static
         * @param {api.IExecuteAdminTaskResponse=} [properties] Properties to set
         * @returns {api.ExecuteAdminTaskResponse} ExecuteAdminTaskResponse instance
         */
        ExecuteAdminTaskResponse.create = function create(properties) {
            return new ExecuteAdminTaskResponse(properties);
        };

        /**
         * Encodes the specified ExecuteAdminTaskResponse message. Does not implicitly {@link api.ExecuteAdminTaskResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ExecuteAdminTaskResponse
         * @static
         * @param {api.IExecuteAdminTaskResponse} message ExecuteAdminTaskResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteAdminTaskResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ExecuteAdminTaskResponse message, length delimited. Does not implicitly {@link api.ExecuteAdminTaskResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ExecuteAdminTaskResponse
         * @static
         * @param {api.IExecuteAdminTaskResponse} message ExecuteAdminTaskResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecuteAdminTaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecuteAdminTaskResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ExecuteAdminTaskResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ExecuteAdminTaskResponse} ExecuteAdminTaskResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteAdminTaskResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ExecuteAdminTaskResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExecuteAdminTaskResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ExecuteAdminTaskResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ExecuteAdminTaskResponse} ExecuteAdminTaskResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecuteAdminTaskResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecuteAdminTaskResponse message.
         * @function verify
         * @memberof api.ExecuteAdminTaskResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecuteAdminTaskResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ExecuteAdminTaskResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ExecuteAdminTaskResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ExecuteAdminTaskResponse} ExecuteAdminTaskResponse
         */
        ExecuteAdminTaskResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ExecuteAdminTaskResponse)
                return object;
            return new $root.api.ExecuteAdminTaskResponse();
        };

        /**
         * Creates a plain object from an ExecuteAdminTaskResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ExecuteAdminTaskResponse
         * @static
         * @param {api.ExecuteAdminTaskResponse} message ExecuteAdminTaskResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecuteAdminTaskResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ExecuteAdminTaskResponse to JSON.
         * @function toJSON
         * @memberof api.ExecuteAdminTaskResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecuteAdminTaskResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExecuteAdminTaskResponse;
    })();

    api.FileUpdate = (function() {

        /**
         * Properties of a FileUpdate.
         * @memberof api
         * @interface IFileUpdate
         * @property {boolean|null} [name] FileUpdate name
         * @property {boolean|null} [approved] FileUpdate approved
         */

        /**
         * Constructs a new FileUpdate.
         * @memberof api
         * @classdesc Represents a FileUpdate.
         * @implements IFileUpdate
         * @constructor
         * @param {api.IFileUpdate=} [properties] Properties to set
         */
        function FileUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FileUpdate name.
         * @member {boolean} name
         * @memberof api.FileUpdate
         * @instance
         */
        FileUpdate.prototype.name = false;

        /**
         * FileUpdate approved.
         * @member {boolean} approved
         * @memberof api.FileUpdate
         * @instance
         */
        FileUpdate.prototype.approved = false;

        /**
         * Creates a new FileUpdate instance using the specified properties.
         * @function create
         * @memberof api.FileUpdate
         * @static
         * @param {api.IFileUpdate=} [properties] Properties to set
         * @returns {api.FileUpdate} FileUpdate instance
         */
        FileUpdate.create = function create(properties) {
            return new FileUpdate(properties);
        };

        /**
         * Encodes the specified FileUpdate message. Does not implicitly {@link api.FileUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.FileUpdate
         * @static
         * @param {api.IFileUpdate} message FileUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.name);
            if (message.approved != null && Object.hasOwnProperty.call(message, "approved"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.approved);
            return writer;
        };

        /**
         * Encodes the specified FileUpdate message, length delimited. Does not implicitly {@link api.FileUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.FileUpdate
         * @static
         * @param {api.IFileUpdate} message FileUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FileUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FileUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.FileUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.FileUpdate} FileUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.FileUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.bool();
                    break;
                case 2:
                    message.approved = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FileUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.FileUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.FileUpdate} FileUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FileUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FileUpdate message.
         * @function verify
         * @memberof api.FileUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FileUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (typeof message.name !== "boolean")
                    return "name: boolean expected";
            if (message.approved != null && message.hasOwnProperty("approved"))
                if (typeof message.approved !== "boolean")
                    return "approved: boolean expected";
            return null;
        };

        /**
         * Creates a FileUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.FileUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.FileUpdate} FileUpdate
         */
        FileUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.FileUpdate)
                return object;
            let message = new $root.api.FileUpdate();
            if (object.name != null)
                message.name = Boolean(object.name);
            if (object.approved != null)
                message.approved = Boolean(object.approved);
            return message;
        };

        /**
         * Creates a plain object from a FileUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.FileUpdate
         * @static
         * @param {api.FileUpdate} message FileUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FileUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = false;
                object.approved = false;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.approved != null && message.hasOwnProperty("approved"))
                object.approved = message.approved;
            return object;
        };

        /**
         * Converts this FileUpdate to JSON.
         * @function toJSON
         * @memberof api.FileUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FileUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FileUpdate;
    })();

    api.UpdateFileRequest = (function() {

        /**
         * Properties of an UpdateFileRequest.
         * @memberof api
         * @interface IUpdateFileRequest
         * @property {api.IFileUpdate|null} [update] UpdateFileRequest update
         * @property {api.IFile|null} [file] UpdateFileRequest file
         */

        /**
         * Constructs a new UpdateFileRequest.
         * @memberof api
         * @classdesc Represents an UpdateFileRequest.
         * @implements IUpdateFileRequest
         * @constructor
         * @param {api.IUpdateFileRequest=} [properties] Properties to set
         */
        function UpdateFileRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateFileRequest update.
         * @member {api.IFileUpdate|null|undefined} update
         * @memberof api.UpdateFileRequest
         * @instance
         */
        UpdateFileRequest.prototype.update = null;

        /**
         * UpdateFileRequest file.
         * @member {api.IFile|null|undefined} file
         * @memberof api.UpdateFileRequest
         * @instance
         */
        UpdateFileRequest.prototype.file = null;

        /**
         * Creates a new UpdateFileRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateFileRequest
         * @static
         * @param {api.IUpdateFileRequest=} [properties] Properties to set
         * @returns {api.UpdateFileRequest} UpdateFileRequest instance
         */
        UpdateFileRequest.create = function create(properties) {
            return new UpdateFileRequest(properties);
        };

        /**
         * Encodes the specified UpdateFileRequest message. Does not implicitly {@link api.UpdateFileRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateFileRequest
         * @static
         * @param {api.IUpdateFileRequest} message UpdateFileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.FileUpdate.encode(message.update, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.api.File.encode(message.file, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateFileRequest message, length delimited. Does not implicitly {@link api.UpdateFileRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateFileRequest
         * @static
         * @param {api.IUpdateFileRequest} message UpdateFileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFileRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateFileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateFileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateFileRequest} UpdateFileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateFileRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.update = $root.api.FileUpdate.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.file = $root.api.File.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateFileRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateFileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateFileRequest} UpdateFileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFileRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateFileRequest message.
         * @function verify
         * @memberof api.UpdateFileRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateFileRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.FileUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.api.File.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateFileRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateFileRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateFileRequest} UpdateFileRequest
         */
        UpdateFileRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateFileRequest)
                return object;
            let message = new $root.api.UpdateFileRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateFileRequest.update: object expected");
                message.update = $root.api.FileUpdate.fromObject(object.update);
            }
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".api.UpdateFileRequest.file: object expected");
                message.file = $root.api.File.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateFileRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateFileRequest
         * @static
         * @param {api.UpdateFileRequest} message UpdateFileRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateFileRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.file = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.FileUpdate.toObject(message.update, options);
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.api.File.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this UpdateFileRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateFileRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateFileRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateFileRequest;
    })();

    api.UpdateFileResponse = (function() {

        /**
         * Properties of an UpdateFileResponse.
         * @memberof api
         * @interface IUpdateFileResponse
         */

        /**
         * Constructs a new UpdateFileResponse.
         * @memberof api
         * @classdesc Represents an UpdateFileResponse.
         * @implements IUpdateFileResponse
         * @constructor
         * @param {api.IUpdateFileResponse=} [properties] Properties to set
         */
        function UpdateFileResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateFileResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateFileResponse
         * @static
         * @param {api.IUpdateFileResponse=} [properties] Properties to set
         * @returns {api.UpdateFileResponse} UpdateFileResponse instance
         */
        UpdateFileResponse.create = function create(properties) {
            return new UpdateFileResponse(properties);
        };

        /**
         * Encodes the specified UpdateFileResponse message. Does not implicitly {@link api.UpdateFileResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateFileResponse
         * @static
         * @param {api.IUpdateFileResponse} message UpdateFileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFileResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateFileResponse message, length delimited. Does not implicitly {@link api.UpdateFileResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateFileResponse
         * @static
         * @param {api.IUpdateFileResponse} message UpdateFileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFileResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateFileResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateFileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateFileResponse} UpdateFileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFileResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateFileResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateFileResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateFileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateFileResponse} UpdateFileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFileResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateFileResponse message.
         * @function verify
         * @memberof api.UpdateFileResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateFileResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateFileResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateFileResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateFileResponse} UpdateFileResponse
         */
        UpdateFileResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateFileResponse)
                return object;
            return new $root.api.UpdateFileResponse();
        };

        /**
         * Creates a plain object from an UpdateFileResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateFileResponse
         * @static
         * @param {api.UpdateFileResponse} message UpdateFileResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateFileResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateFileResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateFileResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateFileResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateFileResponse;
    })();

    api.GetSegmentRequest = (function() {

        /**
         * Properties of a GetSegmentRequest.
         * @memberof api
         * @interface IGetSegmentRequest
         * @property {string|null} [fileId] GetSegmentRequest fileId
         */

        /**
         * Constructs a new GetSegmentRequest.
         * @memberof api
         * @classdesc Represents a GetSegmentRequest.
         * @implements IGetSegmentRequest
         * @constructor
         * @param {api.IGetSegmentRequest=} [properties] Properties to set
         */
        function GetSegmentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetSegmentRequest fileId.
         * @member {string} fileId
         * @memberof api.GetSegmentRequest
         * @instance
         */
        GetSegmentRequest.prototype.fileId = "";

        /**
         * Creates a new GetSegmentRequest instance using the specified properties.
         * @function create
         * @memberof api.GetSegmentRequest
         * @static
         * @param {api.IGetSegmentRequest=} [properties] Properties to set
         * @returns {api.GetSegmentRequest} GetSegmentRequest instance
         */
        GetSegmentRequest.create = function create(properties) {
            return new GetSegmentRequest(properties);
        };

        /**
         * Encodes the specified GetSegmentRequest message. Does not implicitly {@link api.GetSegmentRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetSegmentRequest
         * @static
         * @param {api.IGetSegmentRequest} message GetSegmentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSegmentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified GetSegmentRequest message, length delimited. Does not implicitly {@link api.GetSegmentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetSegmentRequest
         * @static
         * @param {api.IGetSegmentRequest} message GetSegmentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSegmentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSegmentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetSegmentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetSegmentRequest} GetSegmentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSegmentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetSegmentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSegmentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetSegmentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetSegmentRequest} GetSegmentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSegmentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSegmentRequest message.
         * @function verify
         * @memberof api.GetSegmentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSegmentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            return null;
        };

        /**
         * Creates a GetSegmentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetSegmentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetSegmentRequest} GetSegmentRequest
         */
        GetSegmentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetSegmentRequest)
                return object;
            let message = new $root.api.GetSegmentRequest();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            return message;
        };

        /**
         * Creates a plain object from a GetSegmentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetSegmentRequest
         * @static
         * @param {api.GetSegmentRequest} message GetSegmentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSegmentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileId = "";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this GetSegmentRequest to JSON.
         * @function toJSON
         * @memberof api.GetSegmentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSegmentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetSegmentRequest;
    })();

    api.GetSegmentResponse = (function() {

        /**
         * Properties of a GetSegmentResponse.
         * @memberof api
         * @interface IGetSegmentResponse
         * @property {string|null} [url] GetSegmentResponse url
         * @property {number|Long|null} [tokensLeft] GetSegmentResponse tokensLeft
         * @property {number|Long|null} [queueTime] GetSegmentResponse queueTime
         * @property {number|Long|null} [processTime] GetSegmentResponse processTime
         * @property {number|Long|null} [tokensUsed] GetSegmentResponse tokensUsed
         */

        /**
         * Constructs a new GetSegmentResponse.
         * @memberof api
         * @classdesc Represents a GetSegmentResponse.
         * @implements IGetSegmentResponse
         * @constructor
         * @param {api.IGetSegmentResponse=} [properties] Properties to set
         */
        function GetSegmentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetSegmentResponse url.
         * @member {string} url
         * @memberof api.GetSegmentResponse
         * @instance
         */
        GetSegmentResponse.prototype.url = "";

        /**
         * GetSegmentResponse tokensLeft.
         * @member {number|Long} tokensLeft
         * @memberof api.GetSegmentResponse
         * @instance
         */
        GetSegmentResponse.prototype.tokensLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetSegmentResponse queueTime.
         * @member {number|Long} queueTime
         * @memberof api.GetSegmentResponse
         * @instance
         */
        GetSegmentResponse.prototype.queueTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetSegmentResponse processTime.
         * @member {number|Long} processTime
         * @memberof api.GetSegmentResponse
         * @instance
         */
        GetSegmentResponse.prototype.processTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetSegmentResponse tokensUsed.
         * @member {number|Long} tokensUsed
         * @memberof api.GetSegmentResponse
         * @instance
         */
        GetSegmentResponse.prototype.tokensUsed = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new GetSegmentResponse instance using the specified properties.
         * @function create
         * @memberof api.GetSegmentResponse
         * @static
         * @param {api.IGetSegmentResponse=} [properties] Properties to set
         * @returns {api.GetSegmentResponse} GetSegmentResponse instance
         */
        GetSegmentResponse.create = function create(properties) {
            return new GetSegmentResponse(properties);
        };

        /**
         * Encodes the specified GetSegmentResponse message. Does not implicitly {@link api.GetSegmentResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetSegmentResponse
         * @static
         * @param {api.IGetSegmentResponse} message GetSegmentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSegmentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            if (message.tokensLeft != null && Object.hasOwnProperty.call(message, "tokensLeft"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.tokensLeft);
            if (message.queueTime != null && Object.hasOwnProperty.call(message, "queueTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.queueTime);
            if (message.processTime != null && Object.hasOwnProperty.call(message, "processTime"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.processTime);
            if (message.tokensUsed != null && Object.hasOwnProperty.call(message, "tokensUsed"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.tokensUsed);
            return writer;
        };

        /**
         * Encodes the specified GetSegmentResponse message, length delimited. Does not implicitly {@link api.GetSegmentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetSegmentResponse
         * @static
         * @param {api.IGetSegmentResponse} message GetSegmentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetSegmentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetSegmentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetSegmentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetSegmentResponse} GetSegmentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSegmentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetSegmentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.tokensLeft = reader.int64();
                    break;
                case 3:
                    message.queueTime = reader.int64();
                    break;
                case 4:
                    message.processTime = reader.int64();
                    break;
                case 5:
                    message.tokensUsed = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetSegmentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetSegmentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetSegmentResponse} GetSegmentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetSegmentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetSegmentResponse message.
         * @function verify
         * @memberof api.GetSegmentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetSegmentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (!$util.isInteger(message.tokensLeft) && !(message.tokensLeft && $util.isInteger(message.tokensLeft.low) && $util.isInteger(message.tokensLeft.high)))
                    return "tokensLeft: integer|Long expected";
            if (message.queueTime != null && message.hasOwnProperty("queueTime"))
                if (!$util.isInteger(message.queueTime) && !(message.queueTime && $util.isInteger(message.queueTime.low) && $util.isInteger(message.queueTime.high)))
                    return "queueTime: integer|Long expected";
            if (message.processTime != null && message.hasOwnProperty("processTime"))
                if (!$util.isInteger(message.processTime) && !(message.processTime && $util.isInteger(message.processTime.low) && $util.isInteger(message.processTime.high)))
                    return "processTime: integer|Long expected";
            if (message.tokensUsed != null && message.hasOwnProperty("tokensUsed"))
                if (!$util.isInteger(message.tokensUsed) && !(message.tokensUsed && $util.isInteger(message.tokensUsed.low) && $util.isInteger(message.tokensUsed.high)))
                    return "tokensUsed: integer|Long expected";
            return null;
        };

        /**
         * Creates a GetSegmentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetSegmentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetSegmentResponse} GetSegmentResponse
         */
        GetSegmentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetSegmentResponse)
                return object;
            let message = new $root.api.GetSegmentResponse();
            if (object.url != null)
                message.url = String(object.url);
            if (object.tokensLeft != null)
                if ($util.Long)
                    (message.tokensLeft = $util.Long.fromValue(object.tokensLeft)).unsigned = false;
                else if (typeof object.tokensLeft === "string")
                    message.tokensLeft = parseInt(object.tokensLeft, 10);
                else if (typeof object.tokensLeft === "number")
                    message.tokensLeft = object.tokensLeft;
                else if (typeof object.tokensLeft === "object")
                    message.tokensLeft = new $util.LongBits(object.tokensLeft.low >>> 0, object.tokensLeft.high >>> 0).toNumber();
            if (object.queueTime != null)
                if ($util.Long)
                    (message.queueTime = $util.Long.fromValue(object.queueTime)).unsigned = false;
                else if (typeof object.queueTime === "string")
                    message.queueTime = parseInt(object.queueTime, 10);
                else if (typeof object.queueTime === "number")
                    message.queueTime = object.queueTime;
                else if (typeof object.queueTime === "object")
                    message.queueTime = new $util.LongBits(object.queueTime.low >>> 0, object.queueTime.high >>> 0).toNumber();
            if (object.processTime != null)
                if ($util.Long)
                    (message.processTime = $util.Long.fromValue(object.processTime)).unsigned = false;
                else if (typeof object.processTime === "string")
                    message.processTime = parseInt(object.processTime, 10);
                else if (typeof object.processTime === "number")
                    message.processTime = object.processTime;
                else if (typeof object.processTime === "object")
                    message.processTime = new $util.LongBits(object.processTime.low >>> 0, object.processTime.high >>> 0).toNumber();
            if (object.tokensUsed != null)
                if ($util.Long)
                    (message.tokensUsed = $util.Long.fromValue(object.tokensUsed)).unsigned = false;
                else if (typeof object.tokensUsed === "string")
                    message.tokensUsed = parseInt(object.tokensUsed, 10);
                else if (typeof object.tokensUsed === "number")
                    message.tokensUsed = object.tokensUsed;
                else if (typeof object.tokensUsed === "object")
                    message.tokensUsed = new $util.LongBits(object.tokensUsed.low >>> 0, object.tokensUsed.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a GetSegmentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetSegmentResponse
         * @static
         * @param {api.GetSegmentResponse} message GetSegmentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetSegmentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensLeft = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.queueTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.queueTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.processTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.processTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensUsed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensUsed = options.longs === String ? "0" : 0;
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (typeof message.tokensLeft === "number")
                    object.tokensLeft = options.longs === String ? String(message.tokensLeft) : message.tokensLeft;
                else
                    object.tokensLeft = options.longs === String ? $util.Long.prototype.toString.call(message.tokensLeft) : options.longs === Number ? new $util.LongBits(message.tokensLeft.low >>> 0, message.tokensLeft.high >>> 0).toNumber() : message.tokensLeft;
            if (message.queueTime != null && message.hasOwnProperty("queueTime"))
                if (typeof message.queueTime === "number")
                    object.queueTime = options.longs === String ? String(message.queueTime) : message.queueTime;
                else
                    object.queueTime = options.longs === String ? $util.Long.prototype.toString.call(message.queueTime) : options.longs === Number ? new $util.LongBits(message.queueTime.low >>> 0, message.queueTime.high >>> 0).toNumber() : message.queueTime;
            if (message.processTime != null && message.hasOwnProperty("processTime"))
                if (typeof message.processTime === "number")
                    object.processTime = options.longs === String ? String(message.processTime) : message.processTime;
                else
                    object.processTime = options.longs === String ? $util.Long.prototype.toString.call(message.processTime) : options.longs === Number ? new $util.LongBits(message.processTime.low >>> 0, message.processTime.high >>> 0).toNumber() : message.processTime;
            if (message.tokensUsed != null && message.hasOwnProperty("tokensUsed"))
                if (typeof message.tokensUsed === "number")
                    object.tokensUsed = options.longs === String ? String(message.tokensUsed) : message.tokensUsed;
                else
                    object.tokensUsed = options.longs === String ? $util.Long.prototype.toString.call(message.tokensUsed) : options.longs === Number ? new $util.LongBits(message.tokensUsed.low >>> 0, message.tokensUsed.high >>> 0).toNumber() : message.tokensUsed;
            return object;
        };

        /**
         * Converts this GetSegmentResponse to JSON.
         * @function toJSON
         * @memberof api.GetSegmentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetSegmentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetSegmentResponse;
    })();

    api.GetMaskRequest = (function() {

        /**
         * Properties of a GetMaskRequest.
         * @memberof api
         * @interface IGetMaskRequest
         * @property {string|null} [fileId] GetMaskRequest fileId
         * @property {string|null} [maskMethod] GetMaskRequest maskMethod
         */

        /**
         * Constructs a new GetMaskRequest.
         * @memberof api
         * @classdesc Represents a GetMaskRequest.
         * @implements IGetMaskRequest
         * @constructor
         * @param {api.IGetMaskRequest=} [properties] Properties to set
         */
        function GetMaskRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMaskRequest fileId.
         * @member {string} fileId
         * @memberof api.GetMaskRequest
         * @instance
         */
        GetMaskRequest.prototype.fileId = "";

        /**
         * GetMaskRequest maskMethod.
         * @member {string} maskMethod
         * @memberof api.GetMaskRequest
         * @instance
         */
        GetMaskRequest.prototype.maskMethod = "";

        /**
         * Creates a new GetMaskRequest instance using the specified properties.
         * @function create
         * @memberof api.GetMaskRequest
         * @static
         * @param {api.IGetMaskRequest=} [properties] Properties to set
         * @returns {api.GetMaskRequest} GetMaskRequest instance
         */
        GetMaskRequest.create = function create(properties) {
            return new GetMaskRequest(properties);
        };

        /**
         * Encodes the specified GetMaskRequest message. Does not implicitly {@link api.GetMaskRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetMaskRequest
         * @static
         * @param {api.IGetMaskRequest} message GetMaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.maskMethod != null && Object.hasOwnProperty.call(message, "maskMethod"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.maskMethod);
            return writer;
        };

        /**
         * Encodes the specified GetMaskRequest message, length delimited. Does not implicitly {@link api.GetMaskRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetMaskRequest
         * @static
         * @param {api.IGetMaskRequest} message GetMaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetMaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetMaskRequest} GetMaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetMaskRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                case 2:
                    message.maskMethod = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMaskRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetMaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetMaskRequest} GetMaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMaskRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMaskRequest message.
         * @function verify
         * @memberof api.GetMaskRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMaskRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.maskMethod != null && message.hasOwnProperty("maskMethod"))
                if (!$util.isString(message.maskMethod))
                    return "maskMethod: string expected";
            return null;
        };

        /**
         * Creates a GetMaskRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetMaskRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetMaskRequest} GetMaskRequest
         */
        GetMaskRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetMaskRequest)
                return object;
            let message = new $root.api.GetMaskRequest();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.maskMethod != null)
                message.maskMethod = String(object.maskMethod);
            return message;
        };

        /**
         * Creates a plain object from a GetMaskRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetMaskRequest
         * @static
         * @param {api.GetMaskRequest} message GetMaskRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMaskRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.maskMethod = "";
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.maskMethod != null && message.hasOwnProperty("maskMethod"))
                object.maskMethod = message.maskMethod;
            return object;
        };

        /**
         * Converts this GetMaskRequest to JSON.
         * @function toJSON
         * @memberof api.GetMaskRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMaskRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMaskRequest;
    })();

    api.GetMaskResponse = (function() {

        /**
         * Properties of a GetMaskResponse.
         * @memberof api
         * @interface IGetMaskResponse
         * @property {string|null} [url] GetMaskResponse url
         * @property {number|Long|null} [tokensLeft] GetMaskResponse tokensLeft
         * @property {number|Long|null} [queueTime] GetMaskResponse queueTime
         * @property {number|Long|null} [processTime] GetMaskResponse processTime
         * @property {number|Long|null} [tokensUsed] GetMaskResponse tokensUsed
         */

        /**
         * Constructs a new GetMaskResponse.
         * @memberof api
         * @classdesc Represents a GetMaskResponse.
         * @implements IGetMaskResponse
         * @constructor
         * @param {api.IGetMaskResponse=} [properties] Properties to set
         */
        function GetMaskResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMaskResponse url.
         * @member {string} url
         * @memberof api.GetMaskResponse
         * @instance
         */
        GetMaskResponse.prototype.url = "";

        /**
         * GetMaskResponse tokensLeft.
         * @member {number|Long} tokensLeft
         * @memberof api.GetMaskResponse
         * @instance
         */
        GetMaskResponse.prototype.tokensLeft = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetMaskResponse queueTime.
         * @member {number|Long} queueTime
         * @memberof api.GetMaskResponse
         * @instance
         */
        GetMaskResponse.prototype.queueTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetMaskResponse processTime.
         * @member {number|Long} processTime
         * @memberof api.GetMaskResponse
         * @instance
         */
        GetMaskResponse.prototype.processTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetMaskResponse tokensUsed.
         * @member {number|Long} tokensUsed
         * @memberof api.GetMaskResponse
         * @instance
         */
        GetMaskResponse.prototype.tokensUsed = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new GetMaskResponse instance using the specified properties.
         * @function create
         * @memberof api.GetMaskResponse
         * @static
         * @param {api.IGetMaskResponse=} [properties] Properties to set
         * @returns {api.GetMaskResponse} GetMaskResponse instance
         */
        GetMaskResponse.create = function create(properties) {
            return new GetMaskResponse(properties);
        };

        /**
         * Encodes the specified GetMaskResponse message. Does not implicitly {@link api.GetMaskResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetMaskResponse
         * @static
         * @param {api.IGetMaskResponse} message GetMaskResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMaskResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            if (message.tokensLeft != null && Object.hasOwnProperty.call(message, "tokensLeft"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.tokensLeft);
            if (message.queueTime != null && Object.hasOwnProperty.call(message, "queueTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.queueTime);
            if (message.processTime != null && Object.hasOwnProperty.call(message, "processTime"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.processTime);
            if (message.tokensUsed != null && Object.hasOwnProperty.call(message, "tokensUsed"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.tokensUsed);
            return writer;
        };

        /**
         * Encodes the specified GetMaskResponse message, length delimited. Does not implicitly {@link api.GetMaskResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetMaskResponse
         * @static
         * @param {api.IGetMaskResponse} message GetMaskResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMaskResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMaskResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetMaskResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetMaskResponse} GetMaskResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMaskResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetMaskResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.tokensLeft = reader.int64();
                    break;
                case 3:
                    message.queueTime = reader.int64();
                    break;
                case 4:
                    message.processTime = reader.int64();
                    break;
                case 5:
                    message.tokensUsed = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMaskResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetMaskResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetMaskResponse} GetMaskResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMaskResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMaskResponse message.
         * @function verify
         * @memberof api.GetMaskResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMaskResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (!$util.isInteger(message.tokensLeft) && !(message.tokensLeft && $util.isInteger(message.tokensLeft.low) && $util.isInteger(message.tokensLeft.high)))
                    return "tokensLeft: integer|Long expected";
            if (message.queueTime != null && message.hasOwnProperty("queueTime"))
                if (!$util.isInteger(message.queueTime) && !(message.queueTime && $util.isInteger(message.queueTime.low) && $util.isInteger(message.queueTime.high)))
                    return "queueTime: integer|Long expected";
            if (message.processTime != null && message.hasOwnProperty("processTime"))
                if (!$util.isInteger(message.processTime) && !(message.processTime && $util.isInteger(message.processTime.low) && $util.isInteger(message.processTime.high)))
                    return "processTime: integer|Long expected";
            if (message.tokensUsed != null && message.hasOwnProperty("tokensUsed"))
                if (!$util.isInteger(message.tokensUsed) && !(message.tokensUsed && $util.isInteger(message.tokensUsed.low) && $util.isInteger(message.tokensUsed.high)))
                    return "tokensUsed: integer|Long expected";
            return null;
        };

        /**
         * Creates a GetMaskResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetMaskResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetMaskResponse} GetMaskResponse
         */
        GetMaskResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetMaskResponse)
                return object;
            let message = new $root.api.GetMaskResponse();
            if (object.url != null)
                message.url = String(object.url);
            if (object.tokensLeft != null)
                if ($util.Long)
                    (message.tokensLeft = $util.Long.fromValue(object.tokensLeft)).unsigned = false;
                else if (typeof object.tokensLeft === "string")
                    message.tokensLeft = parseInt(object.tokensLeft, 10);
                else if (typeof object.tokensLeft === "number")
                    message.tokensLeft = object.tokensLeft;
                else if (typeof object.tokensLeft === "object")
                    message.tokensLeft = new $util.LongBits(object.tokensLeft.low >>> 0, object.tokensLeft.high >>> 0).toNumber();
            if (object.queueTime != null)
                if ($util.Long)
                    (message.queueTime = $util.Long.fromValue(object.queueTime)).unsigned = false;
                else if (typeof object.queueTime === "string")
                    message.queueTime = parseInt(object.queueTime, 10);
                else if (typeof object.queueTime === "number")
                    message.queueTime = object.queueTime;
                else if (typeof object.queueTime === "object")
                    message.queueTime = new $util.LongBits(object.queueTime.low >>> 0, object.queueTime.high >>> 0).toNumber();
            if (object.processTime != null)
                if ($util.Long)
                    (message.processTime = $util.Long.fromValue(object.processTime)).unsigned = false;
                else if (typeof object.processTime === "string")
                    message.processTime = parseInt(object.processTime, 10);
                else if (typeof object.processTime === "number")
                    message.processTime = object.processTime;
                else if (typeof object.processTime === "object")
                    message.processTime = new $util.LongBits(object.processTime.low >>> 0, object.processTime.high >>> 0).toNumber();
            if (object.tokensUsed != null)
                if ($util.Long)
                    (message.tokensUsed = $util.Long.fromValue(object.tokensUsed)).unsigned = false;
                else if (typeof object.tokensUsed === "string")
                    message.tokensUsed = parseInt(object.tokensUsed, 10);
                else if (typeof object.tokensUsed === "number")
                    message.tokensUsed = object.tokensUsed;
                else if (typeof object.tokensUsed === "object")
                    message.tokensUsed = new $util.LongBits(object.tokensUsed.low >>> 0, object.tokensUsed.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a GetMaskResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetMaskResponse
         * @static
         * @param {api.GetMaskResponse} message GetMaskResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMaskResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensLeft = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensLeft = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.queueTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.queueTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.processTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.processTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tokensUsed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tokensUsed = options.longs === String ? "0" : 0;
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.tokensLeft != null && message.hasOwnProperty("tokensLeft"))
                if (typeof message.tokensLeft === "number")
                    object.tokensLeft = options.longs === String ? String(message.tokensLeft) : message.tokensLeft;
                else
                    object.tokensLeft = options.longs === String ? $util.Long.prototype.toString.call(message.tokensLeft) : options.longs === Number ? new $util.LongBits(message.tokensLeft.low >>> 0, message.tokensLeft.high >>> 0).toNumber() : message.tokensLeft;
            if (message.queueTime != null && message.hasOwnProperty("queueTime"))
                if (typeof message.queueTime === "number")
                    object.queueTime = options.longs === String ? String(message.queueTime) : message.queueTime;
                else
                    object.queueTime = options.longs === String ? $util.Long.prototype.toString.call(message.queueTime) : options.longs === Number ? new $util.LongBits(message.queueTime.low >>> 0, message.queueTime.high >>> 0).toNumber() : message.queueTime;
            if (message.processTime != null && message.hasOwnProperty("processTime"))
                if (typeof message.processTime === "number")
                    object.processTime = options.longs === String ? String(message.processTime) : message.processTime;
                else
                    object.processTime = options.longs === String ? $util.Long.prototype.toString.call(message.processTime) : options.longs === Number ? new $util.LongBits(message.processTime.low >>> 0, message.processTime.high >>> 0).toNumber() : message.processTime;
            if (message.tokensUsed != null && message.hasOwnProperty("tokensUsed"))
                if (typeof message.tokensUsed === "number")
                    object.tokensUsed = options.longs === String ? String(message.tokensUsed) : message.tokensUsed;
                else
                    object.tokensUsed = options.longs === String ? $util.Long.prototype.toString.call(message.tokensUsed) : options.longs === Number ? new $util.LongBits(message.tokensUsed.low >>> 0, message.tokensUsed.high >>> 0).toNumber() : message.tokensUsed;
            return object;
        };

        /**
         * Converts this GetMaskResponse to JSON.
         * @function toJSON
         * @memberof api.GetMaskResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMaskResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMaskResponse;
    })();

    api.GetCaptionRequest = (function() {

        /**
         * Properties of a GetCaptionRequest.
         * @memberof api
         * @interface IGetCaptionRequest
         * @property {string|null} [fileId] GetCaptionRequest fileId
         */

        /**
         * Constructs a new GetCaptionRequest.
         * @memberof api
         * @classdesc Represents a GetCaptionRequest.
         * @implements IGetCaptionRequest
         * @constructor
         * @param {api.IGetCaptionRequest=} [properties] Properties to set
         */
        function GetCaptionRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCaptionRequest fileId.
         * @member {string} fileId
         * @memberof api.GetCaptionRequest
         * @instance
         */
        GetCaptionRequest.prototype.fileId = "";

        /**
         * Creates a new GetCaptionRequest instance using the specified properties.
         * @function create
         * @memberof api.GetCaptionRequest
         * @static
         * @param {api.IGetCaptionRequest=} [properties] Properties to set
         * @returns {api.GetCaptionRequest} GetCaptionRequest instance
         */
        GetCaptionRequest.create = function create(properties) {
            return new GetCaptionRequest(properties);
        };

        /**
         * Encodes the specified GetCaptionRequest message. Does not implicitly {@link api.GetCaptionRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetCaptionRequest
         * @static
         * @param {api.IGetCaptionRequest} message GetCaptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCaptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            return writer;
        };

        /**
         * Encodes the specified GetCaptionRequest message, length delimited. Does not implicitly {@link api.GetCaptionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetCaptionRequest
         * @static
         * @param {api.IGetCaptionRequest} message GetCaptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCaptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetCaptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetCaptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetCaptionRequest} GetCaptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCaptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetCaptionRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fileId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetCaptionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetCaptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetCaptionRequest} GetCaptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCaptionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetCaptionRequest message.
         * @function verify
         * @memberof api.GetCaptionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetCaptionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            return null;
        };

        /**
         * Creates a GetCaptionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetCaptionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetCaptionRequest} GetCaptionRequest
         */
        GetCaptionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetCaptionRequest)
                return object;
            let message = new $root.api.GetCaptionRequest();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            return message;
        };

        /**
         * Creates a plain object from a GetCaptionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetCaptionRequest
         * @static
         * @param {api.GetCaptionRequest} message GetCaptionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetCaptionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fileId = "";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            return object;
        };

        /**
         * Converts this GetCaptionRequest to JSON.
         * @function toJSON
         * @memberof api.GetCaptionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetCaptionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetCaptionRequest;
    })();

    api.GetCaptionResponse = (function() {

        /**
         * Properties of a GetCaptionResponse.
         * @memberof api
         * @interface IGetCaptionResponse
         * @property {string|null} [caption] GetCaptionResponse caption
         */

        /**
         * Constructs a new GetCaptionResponse.
         * @memberof api
         * @classdesc Represents a GetCaptionResponse.
         * @implements IGetCaptionResponse
         * @constructor
         * @param {api.IGetCaptionResponse=} [properties] Properties to set
         */
        function GetCaptionResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCaptionResponse caption.
         * @member {string} caption
         * @memberof api.GetCaptionResponse
         * @instance
         */
        GetCaptionResponse.prototype.caption = "";

        /**
         * Creates a new GetCaptionResponse instance using the specified properties.
         * @function create
         * @memberof api.GetCaptionResponse
         * @static
         * @param {api.IGetCaptionResponse=} [properties] Properties to set
         * @returns {api.GetCaptionResponse} GetCaptionResponse instance
         */
        GetCaptionResponse.create = function create(properties) {
            return new GetCaptionResponse(properties);
        };

        /**
         * Encodes the specified GetCaptionResponse message. Does not implicitly {@link api.GetCaptionResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetCaptionResponse
         * @static
         * @param {api.IGetCaptionResponse} message GetCaptionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCaptionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.caption != null && Object.hasOwnProperty.call(message, "caption"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.caption);
            return writer;
        };

        /**
         * Encodes the specified GetCaptionResponse message, length delimited. Does not implicitly {@link api.GetCaptionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetCaptionResponse
         * @static
         * @param {api.IGetCaptionResponse} message GetCaptionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCaptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetCaptionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetCaptionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetCaptionResponse} GetCaptionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCaptionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetCaptionResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.caption = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetCaptionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetCaptionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetCaptionResponse} GetCaptionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCaptionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetCaptionResponse message.
         * @function verify
         * @memberof api.GetCaptionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetCaptionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.caption != null && message.hasOwnProperty("caption"))
                if (!$util.isString(message.caption))
                    return "caption: string expected";
            return null;
        };

        /**
         * Creates a GetCaptionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetCaptionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetCaptionResponse} GetCaptionResponse
         */
        GetCaptionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetCaptionResponse)
                return object;
            let message = new $root.api.GetCaptionResponse();
            if (object.caption != null)
                message.caption = String(object.caption);
            return message;
        };

        /**
         * Creates a plain object from a GetCaptionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetCaptionResponse
         * @static
         * @param {api.GetCaptionResponse} message GetCaptionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetCaptionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.caption = "";
            if (message.caption != null && message.hasOwnProperty("caption"))
                object.caption = message.caption;
            return object;
        };

        /**
         * Converts this GetCaptionResponse to JSON.
         * @function toJSON
         * @memberof api.GetCaptionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetCaptionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetCaptionResponse;
    })();

    api.APIRequest = (function() {

        /**
         * Properties of a APIRequest.
         * @memberof api
         * @interface IAPIRequest
         * @property {number|null} [requestId] APIRequest requestId
         * @property {api.ILoginRequest|null} [loginRequest] APIRequest loginRequest
         * @property {api.ICreateNativeUserRequest|null} [createNativeUserRequest] APIRequest createNativeUserRequest
         * @property {api.IAuthenticateRequest|null} [authenticateRequest] APIRequest authenticateRequest
         * @property {api.IGetUserRequest|null} [getUserRequest] APIRequest getUserRequest
         * @property {api.IUpdateSlackUserRequest|null} [updateSlackUserRequest] APIRequest updateSlackUserRequest
         * @property {api.IUpdateUserRequest|null} [updateUserRequest] APIRequest updateUserRequest
         * @property {api.IAddSlackTeamRequest|null} [addSlackTeamRequest] APIRequest addSlackTeamRequest
         * @property {api.IUpdatePaymentRequest|null} [updatePaymentRequest] APIRequest updatePaymentRequest
         * @property {api.IDeletePaymentRequest|null} [deletePaymentRequest] APIRequest deletePaymentRequest
         * @property {api.IGetAvailablePlansRequest|null} [getAvailablePlansRequest] APIRequest getAvailablePlansRequest
         * @property {api.IGetAvailableModelsRequest|null} [getAvailableModelsRequest] APIRequest getAvailableModelsRequest
         * @property {api.IMergeUserRequest|null} [mergeUserRequest] APIRequest mergeUserRequest
         * @property {api.IGenerateAIRequest|null} [generateAiRequest] APIRequest generateAiRequest
         * @property {api.IValidateRequest|null} [validateRequest] APIRequest validateRequest
         * @property {api.ISendValidationCodeRequest|null} [sendValidationCodeRequest] APIRequest sendValidationCodeRequest
         * @property {api.IGetFilesRequest|null} [getFilesRequest] APIRequest getFilesRequest
         * @property {api.IDeleteFileRequest|null} [deleteFileRequest] APIRequest deleteFileRequest
         * @property {api.ICreateFolderRequest|null} [createFolderRequest] APIRequest createFolderRequest
         * @property {api.ICreateProjectRequest|null} [createProjectRequest] APIRequest createProjectRequest
         * @property {api.IGetProjectRequest|null} [getProjectRequest] APIRequest getProjectRequest
         * @property {api.IUpdateProjectRequest|null} [updateProjectRequest] APIRequest updateProjectRequest
         * @property {api.IUpdateFileRequest|null} [updateFileRequest] APIRequest updateFileRequest
         * @property {api.ICreateComprehendRequest|null} [createComprehendRequest] APIRequest createComprehendRequest
         * @property {api.IGetFileUrlRequest|null} [getFileUrlRequest] APIRequest getFileUrlRequest
         * @property {api.ICheckComprehendTaskStatusRequest|null} [checkComprehendTaskStatusRequest] APIRequest checkComprehendTaskStatusRequest
         * @property {api.IGetGeneratedImagesRequest|null} [getGeneratedImagesRequest] APIRequest getGeneratedImagesRequest
         * @property {api.IUpdateGeneratedImageRequest|null} [updateGeneratedImageRequest] APIRequest updateGeneratedImageRequest
         * @property {api.IExecuteAdminTaskRequest|null} [executeAdminTaskRequest] APIRequest executeAdminTaskRequest
         * @property {api.IGetSegmentRequest|null} [getSegmentRequest] APIRequest getSegmentRequest
         * @property {api.IGetMaskRequest|null} [getMaskRequest] APIRequest getMaskRequest
         * @property {api.IGetCaptionRequest|null} [getCaptionRequest] APIRequest getCaptionRequest
         * @property {api.IDeleteGeneratedImageRequest|null} [deleteGeneratedImageRequest] APIRequest deleteGeneratedImageRequest
         * @property {api.IAddGeneratedImageCommentRequest|null} [addGeneratedImageCommentRequest] APIRequest addGeneratedImageCommentRequest
         * @property {api.IDeleteGeneratedImageCommentRequest|null} [deleteGeneratedImageCommentRequest] APIRequest deleteGeneratedImageCommentRequest
         * @property {api.IUpdateGeneratedImageCommentRequest|null} [updateGeneratedImageCommentRequest] APIRequest updateGeneratedImageCommentRequest
         * @property {api.IAddReceiptRequest|null} [addReceiptRequest] APIRequest addReceiptRequest
         * @property {api.IGetAvailableProductsRequest|null} [getAvailableProductsRequest] APIRequest getAvailableProductsRequest
         * @property {api.IDeleteUserRequest|null} [deleteUserRequest] APIRequest deleteUserRequest
         * @property {api.IGetJobEventsRequest|null} [getJobEventsRequest] APIRequest getJobEventsRequest
         * @property {api.IGetSiteEmbeddingRequest|null} [getSiteEmbeddingRequest] APIRequest getSiteEmbeddingRequest
         * @property {api.ISendFeedbackRequest|null} [sendFeedbackRequest] APIRequest sendFeedbackRequest
         * @property {api.IGetJobsRequest|null} [getJobsRequest] APIRequest getJobsRequest
         * @property {api.IGetConversationsRequest|null} [getConversationsRequest] APIRequest getConversationsRequest
         * @property {api.IGetMessagesRequest|null} [getMessagesRequest] APIRequest getMessagesRequest
         * @property {api.IUpdateMessageRequest|null} [updateMessageRequest] APIRequest updateMessageRequest
         * @property {string|null} [domain] APIRequest domain
         */

        /**
         * Constructs a new APIRequest.
         * @memberof api
         * @classdesc Represents a APIRequest.
         * @implements IAPIRequest
         * @constructor
         * @param {api.IAPIRequest=} [properties] Properties to set
         */
        function APIRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIRequest requestId.
         * @member {number} requestId
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.requestId = 0;

        /**
         * APIRequest loginRequest.
         * @member {api.ILoginRequest|null|undefined} loginRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.loginRequest = null;

        /**
         * APIRequest createNativeUserRequest.
         * @member {api.ICreateNativeUserRequest|null|undefined} createNativeUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createNativeUserRequest = null;

        /**
         * APIRequest authenticateRequest.
         * @member {api.IAuthenticateRequest|null|undefined} authenticateRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.authenticateRequest = null;

        /**
         * APIRequest getUserRequest.
         * @member {api.IGetUserRequest|null|undefined} getUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getUserRequest = null;

        /**
         * APIRequest updateSlackUserRequest.
         * @member {api.IUpdateSlackUserRequest|null|undefined} updateSlackUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateSlackUserRequest = null;

        /**
         * APIRequest updateUserRequest.
         * @member {api.IUpdateUserRequest|null|undefined} updateUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateUserRequest = null;

        /**
         * APIRequest addSlackTeamRequest.
         * @member {api.IAddSlackTeamRequest|null|undefined} addSlackTeamRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addSlackTeamRequest = null;

        /**
         * APIRequest updatePaymentRequest.
         * @member {api.IUpdatePaymentRequest|null|undefined} updatePaymentRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updatePaymentRequest = null;

        /**
         * APIRequest deletePaymentRequest.
         * @member {api.IDeletePaymentRequest|null|undefined} deletePaymentRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deletePaymentRequest = null;

        /**
         * APIRequest getAvailablePlansRequest.
         * @member {api.IGetAvailablePlansRequest|null|undefined} getAvailablePlansRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getAvailablePlansRequest = null;

        /**
         * APIRequest getAvailableModelsRequest.
         * @member {api.IGetAvailableModelsRequest|null|undefined} getAvailableModelsRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getAvailableModelsRequest = null;

        /**
         * APIRequest mergeUserRequest.
         * @member {api.IMergeUserRequest|null|undefined} mergeUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.mergeUserRequest = null;

        /**
         * APIRequest generateAiRequest.
         * @member {api.IGenerateAIRequest|null|undefined} generateAiRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.generateAiRequest = null;

        /**
         * APIRequest validateRequest.
         * @member {api.IValidateRequest|null|undefined} validateRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.validateRequest = null;

        /**
         * APIRequest sendValidationCodeRequest.
         * @member {api.ISendValidationCodeRequest|null|undefined} sendValidationCodeRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.sendValidationCodeRequest = null;

        /**
         * APIRequest getFilesRequest.
         * @member {api.IGetFilesRequest|null|undefined} getFilesRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getFilesRequest = null;

        /**
         * APIRequest deleteFileRequest.
         * @member {api.IDeleteFileRequest|null|undefined} deleteFileRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteFileRequest = null;

        /**
         * APIRequest createFolderRequest.
         * @member {api.ICreateFolderRequest|null|undefined} createFolderRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createFolderRequest = null;

        /**
         * APIRequest createProjectRequest.
         * @member {api.ICreateProjectRequest|null|undefined} createProjectRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createProjectRequest = null;

        /**
         * APIRequest getProjectRequest.
         * @member {api.IGetProjectRequest|null|undefined} getProjectRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getProjectRequest = null;

        /**
         * APIRequest updateProjectRequest.
         * @member {api.IUpdateProjectRequest|null|undefined} updateProjectRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateProjectRequest = null;

        /**
         * APIRequest updateFileRequest.
         * @member {api.IUpdateFileRequest|null|undefined} updateFileRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateFileRequest = null;

        /**
         * APIRequest createComprehendRequest.
         * @member {api.ICreateComprehendRequest|null|undefined} createComprehendRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createComprehendRequest = null;

        /**
         * APIRequest getFileUrlRequest.
         * @member {api.IGetFileUrlRequest|null|undefined} getFileUrlRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getFileUrlRequest = null;

        /**
         * APIRequest checkComprehendTaskStatusRequest.
         * @member {api.ICheckComprehendTaskStatusRequest|null|undefined} checkComprehendTaskStatusRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.checkComprehendTaskStatusRequest = null;

        /**
         * APIRequest getGeneratedImagesRequest.
         * @member {api.IGetGeneratedImagesRequest|null|undefined} getGeneratedImagesRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getGeneratedImagesRequest = null;

        /**
         * APIRequest updateGeneratedImageRequest.
         * @member {api.IUpdateGeneratedImageRequest|null|undefined} updateGeneratedImageRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateGeneratedImageRequest = null;

        /**
         * APIRequest executeAdminTaskRequest.
         * @member {api.IExecuteAdminTaskRequest|null|undefined} executeAdminTaskRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.executeAdminTaskRequest = null;

        /**
         * APIRequest getSegmentRequest.
         * @member {api.IGetSegmentRequest|null|undefined} getSegmentRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getSegmentRequest = null;

        /**
         * APIRequest getMaskRequest.
         * @member {api.IGetMaskRequest|null|undefined} getMaskRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getMaskRequest = null;

        /**
         * APIRequest getCaptionRequest.
         * @member {api.IGetCaptionRequest|null|undefined} getCaptionRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getCaptionRequest = null;

        /**
         * APIRequest deleteGeneratedImageRequest.
         * @member {api.IDeleteGeneratedImageRequest|null|undefined} deleteGeneratedImageRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteGeneratedImageRequest = null;

        /**
         * APIRequest addGeneratedImageCommentRequest.
         * @member {api.IAddGeneratedImageCommentRequest|null|undefined} addGeneratedImageCommentRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addGeneratedImageCommentRequest = null;

        /**
         * APIRequest deleteGeneratedImageCommentRequest.
         * @member {api.IDeleteGeneratedImageCommentRequest|null|undefined} deleteGeneratedImageCommentRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteGeneratedImageCommentRequest = null;

        /**
         * APIRequest updateGeneratedImageCommentRequest.
         * @member {api.IUpdateGeneratedImageCommentRequest|null|undefined} updateGeneratedImageCommentRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateGeneratedImageCommentRequest = null;

        /**
         * APIRequest addReceiptRequest.
         * @member {api.IAddReceiptRequest|null|undefined} addReceiptRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addReceiptRequest = null;

        /**
         * APIRequest getAvailableProductsRequest.
         * @member {api.IGetAvailableProductsRequest|null|undefined} getAvailableProductsRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getAvailableProductsRequest = null;

        /**
         * APIRequest deleteUserRequest.
         * @member {api.IDeleteUserRequest|null|undefined} deleteUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteUserRequest = null;

        /**
         * APIRequest getJobEventsRequest.
         * @member {api.IGetJobEventsRequest|null|undefined} getJobEventsRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getJobEventsRequest = null;

        /**
         * APIRequest getSiteEmbeddingRequest.
         * @member {api.IGetSiteEmbeddingRequest|null|undefined} getSiteEmbeddingRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getSiteEmbeddingRequest = null;

        /**
         * APIRequest sendFeedbackRequest.
         * @member {api.ISendFeedbackRequest|null|undefined} sendFeedbackRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.sendFeedbackRequest = null;

        /**
         * APIRequest getJobsRequest.
         * @member {api.IGetJobsRequest|null|undefined} getJobsRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getJobsRequest = null;

        /**
         * APIRequest getConversationsRequest.
         * @member {api.IGetConversationsRequest|null|undefined} getConversationsRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getConversationsRequest = null;

        /**
         * APIRequest getMessagesRequest.
         * @member {api.IGetMessagesRequest|null|undefined} getMessagesRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getMessagesRequest = null;

        /**
         * APIRequest updateMessageRequest.
         * @member {api.IUpdateMessageRequest|null|undefined} updateMessageRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateMessageRequest = null;

        /**
         * APIRequest domain.
         * @member {string} domain
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.domain = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * APIRequest apiRequestInfo.
         * @member {"loginRequest"|"createNativeUserRequest"|"authenticateRequest"|"getUserRequest"|"updateSlackUserRequest"|"updateUserRequest"|"addSlackTeamRequest"|"updatePaymentRequest"|"deletePaymentRequest"|"getAvailablePlansRequest"|"getAvailableModelsRequest"|"mergeUserRequest"|"generateAiRequest"|"validateRequest"|"sendValidationCodeRequest"|"getFilesRequest"|"deleteFileRequest"|"createFolderRequest"|"createProjectRequest"|"getProjectRequest"|"updateProjectRequest"|"updateFileRequest"|"createComprehendRequest"|"getFileUrlRequest"|"checkComprehendTaskStatusRequest"|"getGeneratedImagesRequest"|"updateGeneratedImageRequest"|"executeAdminTaskRequest"|"getSegmentRequest"|"getMaskRequest"|"getCaptionRequest"|"deleteGeneratedImageRequest"|"addGeneratedImageCommentRequest"|"deleteGeneratedImageCommentRequest"|"updateGeneratedImageCommentRequest"|"addReceiptRequest"|"getAvailableProductsRequest"|"deleteUserRequest"|"getJobEventsRequest"|"getSiteEmbeddingRequest"|"sendFeedbackRequest"|"getJobsRequest"|"getConversationsRequest"|"getMessagesRequest"|"updateMessageRequest"|undefined} apiRequestInfo
         * @memberof api.APIRequest
         * @instance
         */
        Object.defineProperty(APIRequest.prototype, "apiRequestInfo", {
            get: $util.oneOfGetter($oneOfFields = ["loginRequest", "createNativeUserRequest", "authenticateRequest", "getUserRequest", "updateSlackUserRequest", "updateUserRequest", "addSlackTeamRequest", "updatePaymentRequest", "deletePaymentRequest", "getAvailablePlansRequest", "getAvailableModelsRequest", "mergeUserRequest", "generateAiRequest", "validateRequest", "sendValidationCodeRequest", "getFilesRequest", "deleteFileRequest", "createFolderRequest", "createProjectRequest", "getProjectRequest", "updateProjectRequest", "updateFileRequest", "createComprehendRequest", "getFileUrlRequest", "checkComprehendTaskStatusRequest", "getGeneratedImagesRequest", "updateGeneratedImageRequest", "executeAdminTaskRequest", "getSegmentRequest", "getMaskRequest", "getCaptionRequest", "deleteGeneratedImageRequest", "addGeneratedImageCommentRequest", "deleteGeneratedImageCommentRequest", "updateGeneratedImageCommentRequest", "addReceiptRequest", "getAvailableProductsRequest", "deleteUserRequest", "getJobEventsRequest", "getSiteEmbeddingRequest", "sendFeedbackRequest", "getJobsRequest", "getConversationsRequest", "getMessagesRequest", "updateMessageRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new APIRequest instance using the specified properties.
         * @function create
         * @memberof api.APIRequest
         * @static
         * @param {api.IAPIRequest=} [properties] Properties to set
         * @returns {api.APIRequest} APIRequest instance
         */
        APIRequest.create = function create(properties) {
            return new APIRequest(properties);
        };

        /**
         * Encodes the specified APIRequest message. Does not implicitly {@link api.APIRequest.verify|verify} messages.
         * @function encode
         * @memberof api.APIRequest
         * @static
         * @param {api.IAPIRequest} message APIRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.requestId);
            if (message.loginRequest != null && Object.hasOwnProperty.call(message, "loginRequest"))
                $root.api.LoginRequest.encode(message.loginRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.authenticateRequest != null && Object.hasOwnProperty.call(message, "authenticateRequest"))
                $root.api.AuthenticateRequest.encode(message.authenticateRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.getUserRequest != null && Object.hasOwnProperty.call(message, "getUserRequest"))
                $root.api.GetUserRequest.encode(message.getUserRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updateSlackUserRequest != null && Object.hasOwnProperty.call(message, "updateSlackUserRequest"))
                $root.api.UpdateSlackUserRequest.encode(message.updateSlackUserRequest, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.updateUserRequest != null && Object.hasOwnProperty.call(message, "updateUserRequest"))
                $root.api.UpdateUserRequest.encode(message.updateUserRequest, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.addSlackTeamRequest != null && Object.hasOwnProperty.call(message, "addSlackTeamRequest"))
                $root.api.AddSlackTeamRequest.encode(message.addSlackTeamRequest, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.updatePaymentRequest != null && Object.hasOwnProperty.call(message, "updatePaymentRequest"))
                $root.api.UpdatePaymentRequest.encode(message.updatePaymentRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.getAvailablePlansRequest != null && Object.hasOwnProperty.call(message, "getAvailablePlansRequest"))
                $root.api.GetAvailablePlansRequest.encode(message.getAvailablePlansRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.getAvailableModelsRequest != null && Object.hasOwnProperty.call(message, "getAvailableModelsRequest"))
                $root.api.GetAvailableModelsRequest.encode(message.getAvailableModelsRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.deletePaymentRequest != null && Object.hasOwnProperty.call(message, "deletePaymentRequest"))
                $root.api.DeletePaymentRequest.encode(message.deletePaymentRequest, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.createNativeUserRequest != null && Object.hasOwnProperty.call(message, "createNativeUserRequest"))
                $root.api.CreateNativeUserRequest.encode(message.createNativeUserRequest, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.mergeUserRequest != null && Object.hasOwnProperty.call(message, "mergeUserRequest"))
                $root.api.MergeUserRequest.encode(message.mergeUserRequest, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.generateAiRequest != null && Object.hasOwnProperty.call(message, "generateAiRequest"))
                $root.api.GenerateAIRequest.encode(message.generateAiRequest, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.validateRequest != null && Object.hasOwnProperty.call(message, "validateRequest"))
                $root.api.ValidateRequest.encode(message.validateRequest, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.sendValidationCodeRequest != null && Object.hasOwnProperty.call(message, "sendValidationCodeRequest"))
                $root.api.SendValidationCodeRequest.encode(message.sendValidationCodeRequest, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.getFilesRequest != null && Object.hasOwnProperty.call(message, "getFilesRequest"))
                $root.api.GetFilesRequest.encode(message.getFilesRequest, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.deleteFileRequest != null && Object.hasOwnProperty.call(message, "deleteFileRequest"))
                $root.api.DeleteFileRequest.encode(message.deleteFileRequest, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.createFolderRequest != null && Object.hasOwnProperty.call(message, "createFolderRequest"))
                $root.api.CreateFolderRequest.encode(message.createFolderRequest, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.createProjectRequest != null && Object.hasOwnProperty.call(message, "createProjectRequest"))
                $root.api.CreateProjectRequest.encode(message.createProjectRequest, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.getProjectRequest != null && Object.hasOwnProperty.call(message, "getProjectRequest"))
                $root.api.GetProjectRequest.encode(message.getProjectRequest, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.updateProjectRequest != null && Object.hasOwnProperty.call(message, "updateProjectRequest"))
                $root.api.UpdateProjectRequest.encode(message.updateProjectRequest, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.updateFileRequest != null && Object.hasOwnProperty.call(message, "updateFileRequest"))
                $root.api.UpdateFileRequest.encode(message.updateFileRequest, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.createComprehendRequest != null && Object.hasOwnProperty.call(message, "createComprehendRequest"))
                $root.api.CreateComprehendRequest.encode(message.createComprehendRequest, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.getFileUrlRequest != null && Object.hasOwnProperty.call(message, "getFileUrlRequest"))
                $root.api.GetFileUrlRequest.encode(message.getFileUrlRequest, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.checkComprehendTaskStatusRequest != null && Object.hasOwnProperty.call(message, "checkComprehendTaskStatusRequest"))
                $root.api.CheckComprehendTaskStatusRequest.encode(message.checkComprehendTaskStatusRequest, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.getGeneratedImagesRequest != null && Object.hasOwnProperty.call(message, "getGeneratedImagesRequest"))
                $root.api.GetGeneratedImagesRequest.encode(message.getGeneratedImagesRequest, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.updateGeneratedImageRequest != null && Object.hasOwnProperty.call(message, "updateGeneratedImageRequest"))
                $root.api.UpdateGeneratedImageRequest.encode(message.updateGeneratedImageRequest, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.executeAdminTaskRequest != null && Object.hasOwnProperty.call(message, "executeAdminTaskRequest"))
                $root.api.ExecuteAdminTaskRequest.encode(message.executeAdminTaskRequest, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.getSegmentRequest != null && Object.hasOwnProperty.call(message, "getSegmentRequest"))
                $root.api.GetSegmentRequest.encode(message.getSegmentRequest, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.getCaptionRequest != null && Object.hasOwnProperty.call(message, "getCaptionRequest"))
                $root.api.GetCaptionRequest.encode(message.getCaptionRequest, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.getMaskRequest != null && Object.hasOwnProperty.call(message, "getMaskRequest"))
                $root.api.GetMaskRequest.encode(message.getMaskRequest, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.deleteGeneratedImageRequest != null && Object.hasOwnProperty.call(message, "deleteGeneratedImageRequest"))
                $root.api.DeleteGeneratedImageRequest.encode(message.deleteGeneratedImageRequest, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            if (message.addGeneratedImageCommentRequest != null && Object.hasOwnProperty.call(message, "addGeneratedImageCommentRequest"))
                $root.api.AddGeneratedImageCommentRequest.encode(message.addGeneratedImageCommentRequest, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            if (message.deleteGeneratedImageCommentRequest != null && Object.hasOwnProperty.call(message, "deleteGeneratedImageCommentRequest"))
                $root.api.DeleteGeneratedImageCommentRequest.encode(message.deleteGeneratedImageCommentRequest, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
            if (message.updateGeneratedImageCommentRequest != null && Object.hasOwnProperty.call(message, "updateGeneratedImageCommentRequest"))
                $root.api.UpdateGeneratedImageCommentRequest.encode(message.updateGeneratedImageCommentRequest, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
            if (message.addReceiptRequest != null && Object.hasOwnProperty.call(message, "addReceiptRequest"))
                $root.api.AddReceiptRequest.encode(message.addReceiptRequest, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
            if (message.getAvailableProductsRequest != null && Object.hasOwnProperty.call(message, "getAvailableProductsRequest"))
                $root.api.GetAvailableProductsRequest.encode(message.getAvailableProductsRequest, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.deleteUserRequest != null && Object.hasOwnProperty.call(message, "deleteUserRequest"))
                $root.api.DeleteUserRequest.encode(message.deleteUserRequest, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.getJobEventsRequest != null && Object.hasOwnProperty.call(message, "getJobEventsRequest"))
                $root.api.GetJobEventsRequest.encode(message.getJobEventsRequest, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
            if (message.getSiteEmbeddingRequest != null && Object.hasOwnProperty.call(message, "getSiteEmbeddingRequest"))
                $root.api.GetSiteEmbeddingRequest.encode(message.getSiteEmbeddingRequest, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
            if (message.domain != null && Object.hasOwnProperty.call(message, "domain"))
                writer.uint32(/* id 44, wireType 2 =*/354).string(message.domain);
            if (message.sendFeedbackRequest != null && Object.hasOwnProperty.call(message, "sendFeedbackRequest"))
                $root.api.SendFeedbackRequest.encode(message.sendFeedbackRequest, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
            if (message.getJobsRequest != null && Object.hasOwnProperty.call(message, "getJobsRequest"))
                $root.api.GetJobsRequest.encode(message.getJobsRequest, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
            if (message.getConversationsRequest != null && Object.hasOwnProperty.call(message, "getConversationsRequest"))
                $root.api.GetConversationsRequest.encode(message.getConversationsRequest, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
            if (message.getMessagesRequest != null && Object.hasOwnProperty.call(message, "getMessagesRequest"))
                $root.api.GetMessagesRequest.encode(message.getMessagesRequest, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
            if (message.updateMessageRequest != null && Object.hasOwnProperty.call(message, "updateMessageRequest"))
                $root.api.UpdateMessageRequest.encode(message.updateMessageRequest, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified APIRequest message, length delimited. Does not implicitly {@link api.APIRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIRequest
         * @static
         * @param {api.IAPIRequest} message APIRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIRequest} APIRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestId = reader.int32();
                    break;
                case 2:
                    message.loginRequest = $root.api.LoginRequest.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.createNativeUserRequest = $root.api.CreateNativeUserRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.authenticateRequest = $root.api.AuthenticateRequest.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.getUserRequest = $root.api.GetUserRequest.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updateSlackUserRequest = $root.api.UpdateSlackUserRequest.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.updateUserRequest = $root.api.UpdateUserRequest.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.addSlackTeamRequest = $root.api.AddSlackTeamRequest.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.updatePaymentRequest = $root.api.UpdatePaymentRequest.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.deletePaymentRequest = $root.api.DeletePaymentRequest.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.getAvailablePlansRequest = $root.api.GetAvailablePlansRequest.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.getAvailableModelsRequest = $root.api.GetAvailableModelsRequest.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.mergeUserRequest = $root.api.MergeUserRequest.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.generateAiRequest = $root.api.GenerateAIRequest.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.validateRequest = $root.api.ValidateRequest.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.sendValidationCodeRequest = $root.api.SendValidationCodeRequest.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.getFilesRequest = $root.api.GetFilesRequest.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.deleteFileRequest = $root.api.DeleteFileRequest.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.createFolderRequest = $root.api.CreateFolderRequest.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.createProjectRequest = $root.api.CreateProjectRequest.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.getProjectRequest = $root.api.GetProjectRequest.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.updateProjectRequest = $root.api.UpdateProjectRequest.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.updateFileRequest = $root.api.UpdateFileRequest.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.createComprehendRequest = $root.api.CreateComprehendRequest.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.getFileUrlRequest = $root.api.GetFileUrlRequest.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.checkComprehendTaskStatusRequest = $root.api.CheckComprehendTaskStatusRequest.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.getGeneratedImagesRequest = $root.api.GetGeneratedImagesRequest.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.updateGeneratedImageRequest = $root.api.UpdateGeneratedImageRequest.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.executeAdminTaskRequest = $root.api.ExecuteAdminTaskRequest.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.getSegmentRequest = $root.api.GetSegmentRequest.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.getMaskRequest = $root.api.GetMaskRequest.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.getCaptionRequest = $root.api.GetCaptionRequest.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.deleteGeneratedImageRequest = $root.api.DeleteGeneratedImageRequest.decode(reader, reader.uint32());
                    break;
                case 36:
                    message.addGeneratedImageCommentRequest = $root.api.AddGeneratedImageCommentRequest.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.deleteGeneratedImageCommentRequest = $root.api.DeleteGeneratedImageCommentRequest.decode(reader, reader.uint32());
                    break;
                case 38:
                    message.updateGeneratedImageCommentRequest = $root.api.UpdateGeneratedImageCommentRequest.decode(reader, reader.uint32());
                    break;
                case 39:
                    message.addReceiptRequest = $root.api.AddReceiptRequest.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.getAvailableProductsRequest = $root.api.GetAvailableProductsRequest.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.deleteUserRequest = $root.api.DeleteUserRequest.decode(reader, reader.uint32());
                    break;
                case 42:
                    message.getJobEventsRequest = $root.api.GetJobEventsRequest.decode(reader, reader.uint32());
                    break;
                case 43:
                    message.getSiteEmbeddingRequest = $root.api.GetSiteEmbeddingRequest.decode(reader, reader.uint32());
                    break;
                case 45:
                    message.sendFeedbackRequest = $root.api.SendFeedbackRequest.decode(reader, reader.uint32());
                    break;
                case 46:
                    message.getJobsRequest = $root.api.GetJobsRequest.decode(reader, reader.uint32());
                    break;
                case 47:
                    message.getConversationsRequest = $root.api.GetConversationsRequest.decode(reader, reader.uint32());
                    break;
                case 48:
                    message.getMessagesRequest = $root.api.GetMessagesRequest.decode(reader, reader.uint32());
                    break;
                case 49:
                    message.updateMessageRequest = $root.api.UpdateMessageRequest.decode(reader, reader.uint32());
                    break;
                case 44:
                    message.domain = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIRequest} APIRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIRequest message.
         * @function verify
         * @memberof api.APIRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (!$util.isInteger(message.requestId))
                    return "requestId: integer expected";
            if (message.loginRequest != null && message.hasOwnProperty("loginRequest")) {
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.LoginRequest.verify(message.loginRequest);
                    if (error)
                        return "loginRequest." + error;
                }
            }
            if (message.createNativeUserRequest != null && message.hasOwnProperty("createNativeUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateNativeUserRequest.verify(message.createNativeUserRequest);
                    if (error)
                        return "createNativeUserRequest." + error;
                }
            }
            if (message.authenticateRequest != null && message.hasOwnProperty("authenticateRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AuthenticateRequest.verify(message.authenticateRequest);
                    if (error)
                        return "authenticateRequest." + error;
                }
            }
            if (message.getUserRequest != null && message.hasOwnProperty("getUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetUserRequest.verify(message.getUserRequest);
                    if (error)
                        return "getUserRequest." + error;
                }
            }
            if (message.updateSlackUserRequest != null && message.hasOwnProperty("updateSlackUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateSlackUserRequest.verify(message.updateSlackUserRequest);
                    if (error)
                        return "updateSlackUserRequest." + error;
                }
            }
            if (message.updateUserRequest != null && message.hasOwnProperty("updateUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateUserRequest.verify(message.updateUserRequest);
                    if (error)
                        return "updateUserRequest." + error;
                }
            }
            if (message.addSlackTeamRequest != null && message.hasOwnProperty("addSlackTeamRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddSlackTeamRequest.verify(message.addSlackTeamRequest);
                    if (error)
                        return "addSlackTeamRequest." + error;
                }
            }
            if (message.updatePaymentRequest != null && message.hasOwnProperty("updatePaymentRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdatePaymentRequest.verify(message.updatePaymentRequest);
                    if (error)
                        return "updatePaymentRequest." + error;
                }
            }
            if (message.deletePaymentRequest != null && message.hasOwnProperty("deletePaymentRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeletePaymentRequest.verify(message.deletePaymentRequest);
                    if (error)
                        return "deletePaymentRequest." + error;
                }
            }
            if (message.getAvailablePlansRequest != null && message.hasOwnProperty("getAvailablePlansRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetAvailablePlansRequest.verify(message.getAvailablePlansRequest);
                    if (error)
                        return "getAvailablePlansRequest." + error;
                }
            }
            if (message.getAvailableModelsRequest != null && message.hasOwnProperty("getAvailableModelsRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetAvailableModelsRequest.verify(message.getAvailableModelsRequest);
                    if (error)
                        return "getAvailableModelsRequest." + error;
                }
            }
            if (message.mergeUserRequest != null && message.hasOwnProperty("mergeUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.MergeUserRequest.verify(message.mergeUserRequest);
                    if (error)
                        return "mergeUserRequest." + error;
                }
            }
            if (message.generateAiRequest != null && message.hasOwnProperty("generateAiRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GenerateAIRequest.verify(message.generateAiRequest);
                    if (error)
                        return "generateAiRequest." + error;
                }
            }
            if (message.validateRequest != null && message.hasOwnProperty("validateRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.ValidateRequest.verify(message.validateRequest);
                    if (error)
                        return "validateRequest." + error;
                }
            }
            if (message.sendValidationCodeRequest != null && message.hasOwnProperty("sendValidationCodeRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.SendValidationCodeRequest.verify(message.sendValidationCodeRequest);
                    if (error)
                        return "sendValidationCodeRequest." + error;
                }
            }
            if (message.getFilesRequest != null && message.hasOwnProperty("getFilesRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetFilesRequest.verify(message.getFilesRequest);
                    if (error)
                        return "getFilesRequest." + error;
                }
            }
            if (message.deleteFileRequest != null && message.hasOwnProperty("deleteFileRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteFileRequest.verify(message.deleteFileRequest);
                    if (error)
                        return "deleteFileRequest." + error;
                }
            }
            if (message.createFolderRequest != null && message.hasOwnProperty("createFolderRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateFolderRequest.verify(message.createFolderRequest);
                    if (error)
                        return "createFolderRequest." + error;
                }
            }
            if (message.createProjectRequest != null && message.hasOwnProperty("createProjectRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateProjectRequest.verify(message.createProjectRequest);
                    if (error)
                        return "createProjectRequest." + error;
                }
            }
            if (message.getProjectRequest != null && message.hasOwnProperty("getProjectRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetProjectRequest.verify(message.getProjectRequest);
                    if (error)
                        return "getProjectRequest." + error;
                }
            }
            if (message.updateProjectRequest != null && message.hasOwnProperty("updateProjectRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateProjectRequest.verify(message.updateProjectRequest);
                    if (error)
                        return "updateProjectRequest." + error;
                }
            }
            if (message.updateFileRequest != null && message.hasOwnProperty("updateFileRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateFileRequest.verify(message.updateFileRequest);
                    if (error)
                        return "updateFileRequest." + error;
                }
            }
            if (message.createComprehendRequest != null && message.hasOwnProperty("createComprehendRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateComprehendRequest.verify(message.createComprehendRequest);
                    if (error)
                        return "createComprehendRequest." + error;
                }
            }
            if (message.getFileUrlRequest != null && message.hasOwnProperty("getFileUrlRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetFileUrlRequest.verify(message.getFileUrlRequest);
                    if (error)
                        return "getFileUrlRequest." + error;
                }
            }
            if (message.checkComprehendTaskStatusRequest != null && message.hasOwnProperty("checkComprehendTaskStatusRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CheckComprehendTaskStatusRequest.verify(message.checkComprehendTaskStatusRequest);
                    if (error)
                        return "checkComprehendTaskStatusRequest." + error;
                }
            }
            if (message.getGeneratedImagesRequest != null && message.hasOwnProperty("getGeneratedImagesRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetGeneratedImagesRequest.verify(message.getGeneratedImagesRequest);
                    if (error)
                        return "getGeneratedImagesRequest." + error;
                }
            }
            if (message.updateGeneratedImageRequest != null && message.hasOwnProperty("updateGeneratedImageRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateGeneratedImageRequest.verify(message.updateGeneratedImageRequest);
                    if (error)
                        return "updateGeneratedImageRequest." + error;
                }
            }
            if (message.executeAdminTaskRequest != null && message.hasOwnProperty("executeAdminTaskRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.ExecuteAdminTaskRequest.verify(message.executeAdminTaskRequest);
                    if (error)
                        return "executeAdminTaskRequest." + error;
                }
            }
            if (message.getSegmentRequest != null && message.hasOwnProperty("getSegmentRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetSegmentRequest.verify(message.getSegmentRequest);
                    if (error)
                        return "getSegmentRequest." + error;
                }
            }
            if (message.getMaskRequest != null && message.hasOwnProperty("getMaskRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetMaskRequest.verify(message.getMaskRequest);
                    if (error)
                        return "getMaskRequest." + error;
                }
            }
            if (message.getCaptionRequest != null && message.hasOwnProperty("getCaptionRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetCaptionRequest.verify(message.getCaptionRequest);
                    if (error)
                        return "getCaptionRequest." + error;
                }
            }
            if (message.deleteGeneratedImageRequest != null && message.hasOwnProperty("deleteGeneratedImageRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteGeneratedImageRequest.verify(message.deleteGeneratedImageRequest);
                    if (error)
                        return "deleteGeneratedImageRequest." + error;
                }
            }
            if (message.addGeneratedImageCommentRequest != null && message.hasOwnProperty("addGeneratedImageCommentRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddGeneratedImageCommentRequest.verify(message.addGeneratedImageCommentRequest);
                    if (error)
                        return "addGeneratedImageCommentRequest." + error;
                }
            }
            if (message.deleteGeneratedImageCommentRequest != null && message.hasOwnProperty("deleteGeneratedImageCommentRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteGeneratedImageCommentRequest.verify(message.deleteGeneratedImageCommentRequest);
                    if (error)
                        return "deleteGeneratedImageCommentRequest." + error;
                }
            }
            if (message.updateGeneratedImageCommentRequest != null && message.hasOwnProperty("updateGeneratedImageCommentRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateGeneratedImageCommentRequest.verify(message.updateGeneratedImageCommentRequest);
                    if (error)
                        return "updateGeneratedImageCommentRequest." + error;
                }
            }
            if (message.addReceiptRequest != null && message.hasOwnProperty("addReceiptRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddReceiptRequest.verify(message.addReceiptRequest);
                    if (error)
                        return "addReceiptRequest." + error;
                }
            }
            if (message.getAvailableProductsRequest != null && message.hasOwnProperty("getAvailableProductsRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetAvailableProductsRequest.verify(message.getAvailableProductsRequest);
                    if (error)
                        return "getAvailableProductsRequest." + error;
                }
            }
            if (message.deleteUserRequest != null && message.hasOwnProperty("deleteUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteUserRequest.verify(message.deleteUserRequest);
                    if (error)
                        return "deleteUserRequest." + error;
                }
            }
            if (message.getJobEventsRequest != null && message.hasOwnProperty("getJobEventsRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetJobEventsRequest.verify(message.getJobEventsRequest);
                    if (error)
                        return "getJobEventsRequest." + error;
                }
            }
            if (message.getSiteEmbeddingRequest != null && message.hasOwnProperty("getSiteEmbeddingRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetSiteEmbeddingRequest.verify(message.getSiteEmbeddingRequest);
                    if (error)
                        return "getSiteEmbeddingRequest." + error;
                }
            }
            if (message.sendFeedbackRequest != null && message.hasOwnProperty("sendFeedbackRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.SendFeedbackRequest.verify(message.sendFeedbackRequest);
                    if (error)
                        return "sendFeedbackRequest." + error;
                }
            }
            if (message.getJobsRequest != null && message.hasOwnProperty("getJobsRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetJobsRequest.verify(message.getJobsRequest);
                    if (error)
                        return "getJobsRequest." + error;
                }
            }
            if (message.getConversationsRequest != null && message.hasOwnProperty("getConversationsRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetConversationsRequest.verify(message.getConversationsRequest);
                    if (error)
                        return "getConversationsRequest." + error;
                }
            }
            if (message.getMessagesRequest != null && message.hasOwnProperty("getMessagesRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetMessagesRequest.verify(message.getMessagesRequest);
                    if (error)
                        return "getMessagesRequest." + error;
                }
            }
            if (message.updateMessageRequest != null && message.hasOwnProperty("updateMessageRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateMessageRequest.verify(message.updateMessageRequest);
                    if (error)
                        return "updateMessageRequest." + error;
                }
            }
            if (message.domain != null && message.hasOwnProperty("domain"))
                if (!$util.isString(message.domain))
                    return "domain: string expected";
            return null;
        };

        /**
         * Creates a APIRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIRequest} APIRequest
         */
        APIRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIRequest)
                return object;
            let message = new $root.api.APIRequest();
            if (object.requestId != null)
                message.requestId = object.requestId | 0;
            if (object.loginRequest != null) {
                if (typeof object.loginRequest !== "object")
                    throw TypeError(".api.APIRequest.loginRequest: object expected");
                message.loginRequest = $root.api.LoginRequest.fromObject(object.loginRequest);
            }
            if (object.createNativeUserRequest != null) {
                if (typeof object.createNativeUserRequest !== "object")
                    throw TypeError(".api.APIRequest.createNativeUserRequest: object expected");
                message.createNativeUserRequest = $root.api.CreateNativeUserRequest.fromObject(object.createNativeUserRequest);
            }
            if (object.authenticateRequest != null) {
                if (typeof object.authenticateRequest !== "object")
                    throw TypeError(".api.APIRequest.authenticateRequest: object expected");
                message.authenticateRequest = $root.api.AuthenticateRequest.fromObject(object.authenticateRequest);
            }
            if (object.getUserRequest != null) {
                if (typeof object.getUserRequest !== "object")
                    throw TypeError(".api.APIRequest.getUserRequest: object expected");
                message.getUserRequest = $root.api.GetUserRequest.fromObject(object.getUserRequest);
            }
            if (object.updateSlackUserRequest != null) {
                if (typeof object.updateSlackUserRequest !== "object")
                    throw TypeError(".api.APIRequest.updateSlackUserRequest: object expected");
                message.updateSlackUserRequest = $root.api.UpdateSlackUserRequest.fromObject(object.updateSlackUserRequest);
            }
            if (object.updateUserRequest != null) {
                if (typeof object.updateUserRequest !== "object")
                    throw TypeError(".api.APIRequest.updateUserRequest: object expected");
                message.updateUserRequest = $root.api.UpdateUserRequest.fromObject(object.updateUserRequest);
            }
            if (object.addSlackTeamRequest != null) {
                if (typeof object.addSlackTeamRequest !== "object")
                    throw TypeError(".api.APIRequest.addSlackTeamRequest: object expected");
                message.addSlackTeamRequest = $root.api.AddSlackTeamRequest.fromObject(object.addSlackTeamRequest);
            }
            if (object.updatePaymentRequest != null) {
                if (typeof object.updatePaymentRequest !== "object")
                    throw TypeError(".api.APIRequest.updatePaymentRequest: object expected");
                message.updatePaymentRequest = $root.api.UpdatePaymentRequest.fromObject(object.updatePaymentRequest);
            }
            if (object.deletePaymentRequest != null) {
                if (typeof object.deletePaymentRequest !== "object")
                    throw TypeError(".api.APIRequest.deletePaymentRequest: object expected");
                message.deletePaymentRequest = $root.api.DeletePaymentRequest.fromObject(object.deletePaymentRequest);
            }
            if (object.getAvailablePlansRequest != null) {
                if (typeof object.getAvailablePlansRequest !== "object")
                    throw TypeError(".api.APIRequest.getAvailablePlansRequest: object expected");
                message.getAvailablePlansRequest = $root.api.GetAvailablePlansRequest.fromObject(object.getAvailablePlansRequest);
            }
            if (object.getAvailableModelsRequest != null) {
                if (typeof object.getAvailableModelsRequest !== "object")
                    throw TypeError(".api.APIRequest.getAvailableModelsRequest: object expected");
                message.getAvailableModelsRequest = $root.api.GetAvailableModelsRequest.fromObject(object.getAvailableModelsRequest);
            }
            if (object.mergeUserRequest != null) {
                if (typeof object.mergeUserRequest !== "object")
                    throw TypeError(".api.APIRequest.mergeUserRequest: object expected");
                message.mergeUserRequest = $root.api.MergeUserRequest.fromObject(object.mergeUserRequest);
            }
            if (object.generateAiRequest != null) {
                if (typeof object.generateAiRequest !== "object")
                    throw TypeError(".api.APIRequest.generateAiRequest: object expected");
                message.generateAiRequest = $root.api.GenerateAIRequest.fromObject(object.generateAiRequest);
            }
            if (object.validateRequest != null) {
                if (typeof object.validateRequest !== "object")
                    throw TypeError(".api.APIRequest.validateRequest: object expected");
                message.validateRequest = $root.api.ValidateRequest.fromObject(object.validateRequest);
            }
            if (object.sendValidationCodeRequest != null) {
                if (typeof object.sendValidationCodeRequest !== "object")
                    throw TypeError(".api.APIRequest.sendValidationCodeRequest: object expected");
                message.sendValidationCodeRequest = $root.api.SendValidationCodeRequest.fromObject(object.sendValidationCodeRequest);
            }
            if (object.getFilesRequest != null) {
                if (typeof object.getFilesRequest !== "object")
                    throw TypeError(".api.APIRequest.getFilesRequest: object expected");
                message.getFilesRequest = $root.api.GetFilesRequest.fromObject(object.getFilesRequest);
            }
            if (object.deleteFileRequest != null) {
                if (typeof object.deleteFileRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteFileRequest: object expected");
                message.deleteFileRequest = $root.api.DeleteFileRequest.fromObject(object.deleteFileRequest);
            }
            if (object.createFolderRequest != null) {
                if (typeof object.createFolderRequest !== "object")
                    throw TypeError(".api.APIRequest.createFolderRequest: object expected");
                message.createFolderRequest = $root.api.CreateFolderRequest.fromObject(object.createFolderRequest);
            }
            if (object.createProjectRequest != null) {
                if (typeof object.createProjectRequest !== "object")
                    throw TypeError(".api.APIRequest.createProjectRequest: object expected");
                message.createProjectRequest = $root.api.CreateProjectRequest.fromObject(object.createProjectRequest);
            }
            if (object.getProjectRequest != null) {
                if (typeof object.getProjectRequest !== "object")
                    throw TypeError(".api.APIRequest.getProjectRequest: object expected");
                message.getProjectRequest = $root.api.GetProjectRequest.fromObject(object.getProjectRequest);
            }
            if (object.updateProjectRequest != null) {
                if (typeof object.updateProjectRequest !== "object")
                    throw TypeError(".api.APIRequest.updateProjectRequest: object expected");
                message.updateProjectRequest = $root.api.UpdateProjectRequest.fromObject(object.updateProjectRequest);
            }
            if (object.updateFileRequest != null) {
                if (typeof object.updateFileRequest !== "object")
                    throw TypeError(".api.APIRequest.updateFileRequest: object expected");
                message.updateFileRequest = $root.api.UpdateFileRequest.fromObject(object.updateFileRequest);
            }
            if (object.createComprehendRequest != null) {
                if (typeof object.createComprehendRequest !== "object")
                    throw TypeError(".api.APIRequest.createComprehendRequest: object expected");
                message.createComprehendRequest = $root.api.CreateComprehendRequest.fromObject(object.createComprehendRequest);
            }
            if (object.getFileUrlRequest != null) {
                if (typeof object.getFileUrlRequest !== "object")
                    throw TypeError(".api.APIRequest.getFileUrlRequest: object expected");
                message.getFileUrlRequest = $root.api.GetFileUrlRequest.fromObject(object.getFileUrlRequest);
            }
            if (object.checkComprehendTaskStatusRequest != null) {
                if (typeof object.checkComprehendTaskStatusRequest !== "object")
                    throw TypeError(".api.APIRequest.checkComprehendTaskStatusRequest: object expected");
                message.checkComprehendTaskStatusRequest = $root.api.CheckComprehendTaskStatusRequest.fromObject(object.checkComprehendTaskStatusRequest);
            }
            if (object.getGeneratedImagesRequest != null) {
                if (typeof object.getGeneratedImagesRequest !== "object")
                    throw TypeError(".api.APIRequest.getGeneratedImagesRequest: object expected");
                message.getGeneratedImagesRequest = $root.api.GetGeneratedImagesRequest.fromObject(object.getGeneratedImagesRequest);
            }
            if (object.updateGeneratedImageRequest != null) {
                if (typeof object.updateGeneratedImageRequest !== "object")
                    throw TypeError(".api.APIRequest.updateGeneratedImageRequest: object expected");
                message.updateGeneratedImageRequest = $root.api.UpdateGeneratedImageRequest.fromObject(object.updateGeneratedImageRequest);
            }
            if (object.executeAdminTaskRequest != null) {
                if (typeof object.executeAdminTaskRequest !== "object")
                    throw TypeError(".api.APIRequest.executeAdminTaskRequest: object expected");
                message.executeAdminTaskRequest = $root.api.ExecuteAdminTaskRequest.fromObject(object.executeAdminTaskRequest);
            }
            if (object.getSegmentRequest != null) {
                if (typeof object.getSegmentRequest !== "object")
                    throw TypeError(".api.APIRequest.getSegmentRequest: object expected");
                message.getSegmentRequest = $root.api.GetSegmentRequest.fromObject(object.getSegmentRequest);
            }
            if (object.getMaskRequest != null) {
                if (typeof object.getMaskRequest !== "object")
                    throw TypeError(".api.APIRequest.getMaskRequest: object expected");
                message.getMaskRequest = $root.api.GetMaskRequest.fromObject(object.getMaskRequest);
            }
            if (object.getCaptionRequest != null) {
                if (typeof object.getCaptionRequest !== "object")
                    throw TypeError(".api.APIRequest.getCaptionRequest: object expected");
                message.getCaptionRequest = $root.api.GetCaptionRequest.fromObject(object.getCaptionRequest);
            }
            if (object.deleteGeneratedImageRequest != null) {
                if (typeof object.deleteGeneratedImageRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteGeneratedImageRequest: object expected");
                message.deleteGeneratedImageRequest = $root.api.DeleteGeneratedImageRequest.fromObject(object.deleteGeneratedImageRequest);
            }
            if (object.addGeneratedImageCommentRequest != null) {
                if (typeof object.addGeneratedImageCommentRequest !== "object")
                    throw TypeError(".api.APIRequest.addGeneratedImageCommentRequest: object expected");
                message.addGeneratedImageCommentRequest = $root.api.AddGeneratedImageCommentRequest.fromObject(object.addGeneratedImageCommentRequest);
            }
            if (object.deleteGeneratedImageCommentRequest != null) {
                if (typeof object.deleteGeneratedImageCommentRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteGeneratedImageCommentRequest: object expected");
                message.deleteGeneratedImageCommentRequest = $root.api.DeleteGeneratedImageCommentRequest.fromObject(object.deleteGeneratedImageCommentRequest);
            }
            if (object.updateGeneratedImageCommentRequest != null) {
                if (typeof object.updateGeneratedImageCommentRequest !== "object")
                    throw TypeError(".api.APIRequest.updateGeneratedImageCommentRequest: object expected");
                message.updateGeneratedImageCommentRequest = $root.api.UpdateGeneratedImageCommentRequest.fromObject(object.updateGeneratedImageCommentRequest);
            }
            if (object.addReceiptRequest != null) {
                if (typeof object.addReceiptRequest !== "object")
                    throw TypeError(".api.APIRequest.addReceiptRequest: object expected");
                message.addReceiptRequest = $root.api.AddReceiptRequest.fromObject(object.addReceiptRequest);
            }
            if (object.getAvailableProductsRequest != null) {
                if (typeof object.getAvailableProductsRequest !== "object")
                    throw TypeError(".api.APIRequest.getAvailableProductsRequest: object expected");
                message.getAvailableProductsRequest = $root.api.GetAvailableProductsRequest.fromObject(object.getAvailableProductsRequest);
            }
            if (object.deleteUserRequest != null) {
                if (typeof object.deleteUserRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteUserRequest: object expected");
                message.deleteUserRequest = $root.api.DeleteUserRequest.fromObject(object.deleteUserRequest);
            }
            if (object.getJobEventsRequest != null) {
                if (typeof object.getJobEventsRequest !== "object")
                    throw TypeError(".api.APIRequest.getJobEventsRequest: object expected");
                message.getJobEventsRequest = $root.api.GetJobEventsRequest.fromObject(object.getJobEventsRequest);
            }
            if (object.getSiteEmbeddingRequest != null) {
                if (typeof object.getSiteEmbeddingRequest !== "object")
                    throw TypeError(".api.APIRequest.getSiteEmbeddingRequest: object expected");
                message.getSiteEmbeddingRequest = $root.api.GetSiteEmbeddingRequest.fromObject(object.getSiteEmbeddingRequest);
            }
            if (object.sendFeedbackRequest != null) {
                if (typeof object.sendFeedbackRequest !== "object")
                    throw TypeError(".api.APIRequest.sendFeedbackRequest: object expected");
                message.sendFeedbackRequest = $root.api.SendFeedbackRequest.fromObject(object.sendFeedbackRequest);
            }
            if (object.getJobsRequest != null) {
                if (typeof object.getJobsRequest !== "object")
                    throw TypeError(".api.APIRequest.getJobsRequest: object expected");
                message.getJobsRequest = $root.api.GetJobsRequest.fromObject(object.getJobsRequest);
            }
            if (object.getConversationsRequest != null) {
                if (typeof object.getConversationsRequest !== "object")
                    throw TypeError(".api.APIRequest.getConversationsRequest: object expected");
                message.getConversationsRequest = $root.api.GetConversationsRequest.fromObject(object.getConversationsRequest);
            }
            if (object.getMessagesRequest != null) {
                if (typeof object.getMessagesRequest !== "object")
                    throw TypeError(".api.APIRequest.getMessagesRequest: object expected");
                message.getMessagesRequest = $root.api.GetMessagesRequest.fromObject(object.getMessagesRequest);
            }
            if (object.updateMessageRequest != null) {
                if (typeof object.updateMessageRequest !== "object")
                    throw TypeError(".api.APIRequest.updateMessageRequest: object expected");
                message.updateMessageRequest = $root.api.UpdateMessageRequest.fromObject(object.updateMessageRequest);
            }
            if (object.domain != null)
                message.domain = String(object.domain);
            return message;
        };

        /**
         * Creates a plain object from a APIRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIRequest
         * @static
         * @param {api.APIRequest} message APIRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.requestId = 0;
                object.domain = "";
            }
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                object.requestId = message.requestId;
            if (message.loginRequest != null && message.hasOwnProperty("loginRequest")) {
                object.loginRequest = $root.api.LoginRequest.toObject(message.loginRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "loginRequest";
            }
            if (message.authenticateRequest != null && message.hasOwnProperty("authenticateRequest")) {
                object.authenticateRequest = $root.api.AuthenticateRequest.toObject(message.authenticateRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "authenticateRequest";
            }
            if (message.getUserRequest != null && message.hasOwnProperty("getUserRequest")) {
                object.getUserRequest = $root.api.GetUserRequest.toObject(message.getUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getUserRequest";
            }
            if (message.updateSlackUserRequest != null && message.hasOwnProperty("updateSlackUserRequest")) {
                object.updateSlackUserRequest = $root.api.UpdateSlackUserRequest.toObject(message.updateSlackUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateSlackUserRequest";
            }
            if (message.updateUserRequest != null && message.hasOwnProperty("updateUserRequest")) {
                object.updateUserRequest = $root.api.UpdateUserRequest.toObject(message.updateUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateUserRequest";
            }
            if (message.addSlackTeamRequest != null && message.hasOwnProperty("addSlackTeamRequest")) {
                object.addSlackTeamRequest = $root.api.AddSlackTeamRequest.toObject(message.addSlackTeamRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addSlackTeamRequest";
            }
            if (message.updatePaymentRequest != null && message.hasOwnProperty("updatePaymentRequest")) {
                object.updatePaymentRequest = $root.api.UpdatePaymentRequest.toObject(message.updatePaymentRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updatePaymentRequest";
            }
            if (message.getAvailablePlansRequest != null && message.hasOwnProperty("getAvailablePlansRequest")) {
                object.getAvailablePlansRequest = $root.api.GetAvailablePlansRequest.toObject(message.getAvailablePlansRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getAvailablePlansRequest";
            }
            if (message.getAvailableModelsRequest != null && message.hasOwnProperty("getAvailableModelsRequest")) {
                object.getAvailableModelsRequest = $root.api.GetAvailableModelsRequest.toObject(message.getAvailableModelsRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getAvailableModelsRequest";
            }
            if (message.deletePaymentRequest != null && message.hasOwnProperty("deletePaymentRequest")) {
                object.deletePaymentRequest = $root.api.DeletePaymentRequest.toObject(message.deletePaymentRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deletePaymentRequest";
            }
            if (message.createNativeUserRequest != null && message.hasOwnProperty("createNativeUserRequest")) {
                object.createNativeUserRequest = $root.api.CreateNativeUserRequest.toObject(message.createNativeUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createNativeUserRequest";
            }
            if (message.mergeUserRequest != null && message.hasOwnProperty("mergeUserRequest")) {
                object.mergeUserRequest = $root.api.MergeUserRequest.toObject(message.mergeUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "mergeUserRequest";
            }
            if (message.generateAiRequest != null && message.hasOwnProperty("generateAiRequest")) {
                object.generateAiRequest = $root.api.GenerateAIRequest.toObject(message.generateAiRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "generateAiRequest";
            }
            if (message.validateRequest != null && message.hasOwnProperty("validateRequest")) {
                object.validateRequest = $root.api.ValidateRequest.toObject(message.validateRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "validateRequest";
            }
            if (message.sendValidationCodeRequest != null && message.hasOwnProperty("sendValidationCodeRequest")) {
                object.sendValidationCodeRequest = $root.api.SendValidationCodeRequest.toObject(message.sendValidationCodeRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "sendValidationCodeRequest";
            }
            if (message.getFilesRequest != null && message.hasOwnProperty("getFilesRequest")) {
                object.getFilesRequest = $root.api.GetFilesRequest.toObject(message.getFilesRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getFilesRequest";
            }
            if (message.deleteFileRequest != null && message.hasOwnProperty("deleteFileRequest")) {
                object.deleteFileRequest = $root.api.DeleteFileRequest.toObject(message.deleteFileRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteFileRequest";
            }
            if (message.createFolderRequest != null && message.hasOwnProperty("createFolderRequest")) {
                object.createFolderRequest = $root.api.CreateFolderRequest.toObject(message.createFolderRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createFolderRequest";
            }
            if (message.createProjectRequest != null && message.hasOwnProperty("createProjectRequest")) {
                object.createProjectRequest = $root.api.CreateProjectRequest.toObject(message.createProjectRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createProjectRequest";
            }
            if (message.getProjectRequest != null && message.hasOwnProperty("getProjectRequest")) {
                object.getProjectRequest = $root.api.GetProjectRequest.toObject(message.getProjectRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getProjectRequest";
            }
            if (message.updateProjectRequest != null && message.hasOwnProperty("updateProjectRequest")) {
                object.updateProjectRequest = $root.api.UpdateProjectRequest.toObject(message.updateProjectRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateProjectRequest";
            }
            if (message.updateFileRequest != null && message.hasOwnProperty("updateFileRequest")) {
                object.updateFileRequest = $root.api.UpdateFileRequest.toObject(message.updateFileRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateFileRequest";
            }
            if (message.createComprehendRequest != null && message.hasOwnProperty("createComprehendRequest")) {
                object.createComprehendRequest = $root.api.CreateComprehendRequest.toObject(message.createComprehendRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createComprehendRequest";
            }
            if (message.getFileUrlRequest != null && message.hasOwnProperty("getFileUrlRequest")) {
                object.getFileUrlRequest = $root.api.GetFileUrlRequest.toObject(message.getFileUrlRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getFileUrlRequest";
            }
            if (message.checkComprehendTaskStatusRequest != null && message.hasOwnProperty("checkComprehendTaskStatusRequest")) {
                object.checkComprehendTaskStatusRequest = $root.api.CheckComprehendTaskStatusRequest.toObject(message.checkComprehendTaskStatusRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "checkComprehendTaskStatusRequest";
            }
            if (message.getGeneratedImagesRequest != null && message.hasOwnProperty("getGeneratedImagesRequest")) {
                object.getGeneratedImagesRequest = $root.api.GetGeneratedImagesRequest.toObject(message.getGeneratedImagesRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getGeneratedImagesRequest";
            }
            if (message.updateGeneratedImageRequest != null && message.hasOwnProperty("updateGeneratedImageRequest")) {
                object.updateGeneratedImageRequest = $root.api.UpdateGeneratedImageRequest.toObject(message.updateGeneratedImageRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateGeneratedImageRequest";
            }
            if (message.executeAdminTaskRequest != null && message.hasOwnProperty("executeAdminTaskRequest")) {
                object.executeAdminTaskRequest = $root.api.ExecuteAdminTaskRequest.toObject(message.executeAdminTaskRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "executeAdminTaskRequest";
            }
            if (message.getSegmentRequest != null && message.hasOwnProperty("getSegmentRequest")) {
                object.getSegmentRequest = $root.api.GetSegmentRequest.toObject(message.getSegmentRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getSegmentRequest";
            }
            if (message.getCaptionRequest != null && message.hasOwnProperty("getCaptionRequest")) {
                object.getCaptionRequest = $root.api.GetCaptionRequest.toObject(message.getCaptionRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getCaptionRequest";
            }
            if (message.getMaskRequest != null && message.hasOwnProperty("getMaskRequest")) {
                object.getMaskRequest = $root.api.GetMaskRequest.toObject(message.getMaskRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getMaskRequest";
            }
            if (message.deleteGeneratedImageRequest != null && message.hasOwnProperty("deleteGeneratedImageRequest")) {
                object.deleteGeneratedImageRequest = $root.api.DeleteGeneratedImageRequest.toObject(message.deleteGeneratedImageRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteGeneratedImageRequest";
            }
            if (message.addGeneratedImageCommentRequest != null && message.hasOwnProperty("addGeneratedImageCommentRequest")) {
                object.addGeneratedImageCommentRequest = $root.api.AddGeneratedImageCommentRequest.toObject(message.addGeneratedImageCommentRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addGeneratedImageCommentRequest";
            }
            if (message.deleteGeneratedImageCommentRequest != null && message.hasOwnProperty("deleteGeneratedImageCommentRequest")) {
                object.deleteGeneratedImageCommentRequest = $root.api.DeleteGeneratedImageCommentRequest.toObject(message.deleteGeneratedImageCommentRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteGeneratedImageCommentRequest";
            }
            if (message.updateGeneratedImageCommentRequest != null && message.hasOwnProperty("updateGeneratedImageCommentRequest")) {
                object.updateGeneratedImageCommentRequest = $root.api.UpdateGeneratedImageCommentRequest.toObject(message.updateGeneratedImageCommentRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateGeneratedImageCommentRequest";
            }
            if (message.addReceiptRequest != null && message.hasOwnProperty("addReceiptRequest")) {
                object.addReceiptRequest = $root.api.AddReceiptRequest.toObject(message.addReceiptRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addReceiptRequest";
            }
            if (message.getAvailableProductsRequest != null && message.hasOwnProperty("getAvailableProductsRequest")) {
                object.getAvailableProductsRequest = $root.api.GetAvailableProductsRequest.toObject(message.getAvailableProductsRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getAvailableProductsRequest";
            }
            if (message.deleteUserRequest != null && message.hasOwnProperty("deleteUserRequest")) {
                object.deleteUserRequest = $root.api.DeleteUserRequest.toObject(message.deleteUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteUserRequest";
            }
            if (message.getJobEventsRequest != null && message.hasOwnProperty("getJobEventsRequest")) {
                object.getJobEventsRequest = $root.api.GetJobEventsRequest.toObject(message.getJobEventsRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getJobEventsRequest";
            }
            if (message.getSiteEmbeddingRequest != null && message.hasOwnProperty("getSiteEmbeddingRequest")) {
                object.getSiteEmbeddingRequest = $root.api.GetSiteEmbeddingRequest.toObject(message.getSiteEmbeddingRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getSiteEmbeddingRequest";
            }
            if (message.domain != null && message.hasOwnProperty("domain"))
                object.domain = message.domain;
            if (message.sendFeedbackRequest != null && message.hasOwnProperty("sendFeedbackRequest")) {
                object.sendFeedbackRequest = $root.api.SendFeedbackRequest.toObject(message.sendFeedbackRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "sendFeedbackRequest";
            }
            if (message.getJobsRequest != null && message.hasOwnProperty("getJobsRequest")) {
                object.getJobsRequest = $root.api.GetJobsRequest.toObject(message.getJobsRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getJobsRequest";
            }
            if (message.getConversationsRequest != null && message.hasOwnProperty("getConversationsRequest")) {
                object.getConversationsRequest = $root.api.GetConversationsRequest.toObject(message.getConversationsRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getConversationsRequest";
            }
            if (message.getMessagesRequest != null && message.hasOwnProperty("getMessagesRequest")) {
                object.getMessagesRequest = $root.api.GetMessagesRequest.toObject(message.getMessagesRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getMessagesRequest";
            }
            if (message.updateMessageRequest != null && message.hasOwnProperty("updateMessageRequest")) {
                object.updateMessageRequest = $root.api.UpdateMessageRequest.toObject(message.updateMessageRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateMessageRequest";
            }
            return object;
        };

        /**
         * Converts this APIRequest to JSON.
         * @function toJSON
         * @memberof api.APIRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIRequest;
    })();

    api.APIResponse = (function() {

        /**
         * Properties of a APIResponse.
         * @memberof api
         * @interface IAPIResponse
         * @property {number|null} [requestId] APIResponse requestId
         * @property {string|null} [error] APIResponse error
         * @property {string|null} [errorCode] APIResponse errorCode
         * @property {api.ILoginResponse|null} [loginResponse] APIResponse loginResponse
         * @property {api.ICreateNativeUserResponse|null} [createNativeUserResponse] APIResponse createNativeUserResponse
         * @property {api.IAuthenticateResponse|null} [authenticateResponse] APIResponse authenticateResponse
         * @property {api.IGetUserResponse|null} [getUserResponse] APIResponse getUserResponse
         * @property {api.IUpdateSlackUserResponse|null} [updateSlackUserResponse] APIResponse updateSlackUserResponse
         * @property {api.IUpdateUserResponse|null} [updateUserResponse] APIResponse updateUserResponse
         * @property {api.IAddSlackTeamResponse|null} [addSlackTeamResponse] APIResponse addSlackTeamResponse
         * @property {api.IUpdatePaymentResponse|null} [updatePaymentResponse] APIResponse updatePaymentResponse
         * @property {api.IDeletePaymentResponse|null} [deletePaymentResponse] APIResponse deletePaymentResponse
         * @property {api.IGetAvailablePlansResponse|null} [getAvailablePlansResponse] APIResponse getAvailablePlansResponse
         * @property {api.IGetAvailableModelsResponse|null} [getAvailableModelsResponse] APIResponse getAvailableModelsResponse
         * @property {api.IMergeUserResponse|null} [mergeUserResponse] APIResponse mergeUserResponse
         * @property {api.IGenerateAIResponse|null} [generateAiResponse] APIResponse generateAiResponse
         * @property {api.IValidateResponse|null} [validateResponse] APIResponse validateResponse
         * @property {api.ISendValidationCodeResponse|null} [sendValidationCodeResponse] APIResponse sendValidationCodeResponse
         * @property {api.IGetFilesResponse|null} [getFilesResponse] APIResponse getFilesResponse
         * @property {api.IDeleteFileResponse|null} [deleteFileResponse] APIResponse deleteFileResponse
         * @property {api.ICreateFolderResponse|null} [createFolderResponse] APIResponse createFolderResponse
         * @property {api.ICreateProjectResponse|null} [createProjectResponse] APIResponse createProjectResponse
         * @property {api.IGetProjectResponse|null} [getProjectResponse] APIResponse getProjectResponse
         * @property {api.IUpdateProjectResponse|null} [updateProjectResponse] APIResponse updateProjectResponse
         * @property {api.IUpdateFileResponse|null} [updateFileResponse] APIResponse updateFileResponse
         * @property {api.ICreateComprehendResponse|null} [createComprehendResponse] APIResponse createComprehendResponse
         * @property {api.IGetFileUrlResponse|null} [getFileUrlResponse] APIResponse getFileUrlResponse
         * @property {api.ICheckComprehendTaskStatusResponse|null} [checkComprehendTaskStatusResponse] APIResponse checkComprehendTaskStatusResponse
         * @property {api.IGetGeneratedImagesResponse|null} [getGeneratedImagesResponse] APIResponse getGeneratedImagesResponse
         * @property {api.IUpdateGeneratedImageResponse|null} [updateGeneratedImageResponse] APIResponse updateGeneratedImageResponse
         * @property {api.IExecuteAdminTaskResponse|null} [executeAdminTaskResponse] APIResponse executeAdminTaskResponse
         * @property {api.IGetSegmentResponse|null} [getSegmentResponse] APIResponse getSegmentResponse
         * @property {api.IGetMaskResponse|null} [getMaskResponse] APIResponse getMaskResponse
         * @property {api.IGetCaptionResponse|null} [getCaptionResponse] APIResponse getCaptionResponse
         * @property {api.IDeleteGeneratedImageResponse|null} [deleteGeneratedImage] APIResponse deleteGeneratedImage
         * @property {api.IAddGeneratedImageCommentResponse|null} [addGeneratedImageCommentResponse] APIResponse addGeneratedImageCommentResponse
         * @property {api.IDeleteGeneratedImageCommentResponse|null} [deleteGeneratedImageCommentResponse] APIResponse deleteGeneratedImageCommentResponse
         * @property {api.IUpdateGeneratedImageCommentResponse|null} [updateGeneratedImageCommentResponse] APIResponse updateGeneratedImageCommentResponse
         * @property {api.IAddReceiptResponse|null} [addReceiptResponse] APIResponse addReceiptResponse
         * @property {api.IGetAvailableProductsResponse|null} [getAvailableProductsResponse] APIResponse getAvailableProductsResponse
         * @property {api.IDeleteUserResponse|null} [deleteUserResponse] APIResponse deleteUserResponse
         * @property {api.IJobCreatedResponse|null} [jobCreatedResponse] APIResponse jobCreatedResponse
         * @property {api.IJobUpdateResponse|null} [jobUpdateResponse] APIResponse jobUpdateResponse
         * @property {api.IJobStatusResponse|null} [jobStatusResponse] APIResponse jobStatusResponse
         * @property {api.IGetSiteEmbeddingResponse|null} [getSiteEmbeddingResponse] APIResponse getSiteEmbeddingResponse
         * @property {api.ISendFeedbackResponse|null} [sendFeedbackResponse] APIResponse sendFeedbackResponse
         * @property {api.IGetJobsResponse|null} [getJobsResponse] APIResponse getJobsResponse
         * @property {api.IGetConversationsResponse|null} [getConversationsResponse] APIResponse getConversationsResponse
         * @property {api.IGetMessagesResponse|null} [getMessagesResponse] APIResponse getMessagesResponse
         * @property {api.IUpdateMessageResponse|null} [updateMessageResponse] APIResponse updateMessageResponse
         */

        /**
         * Constructs a new APIResponse.
         * @memberof api
         * @classdesc Represents a APIResponse.
         * @implements IAPIResponse
         * @constructor
         * @param {api.IAPIResponse=} [properties] Properties to set
         */
        function APIResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIResponse requestId.
         * @member {number} requestId
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.requestId = 0;

        /**
         * APIResponse error.
         * @member {string} error
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.error = "";

        /**
         * APIResponse errorCode.
         * @member {string} errorCode
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.errorCode = "";

        /**
         * APIResponse loginResponse.
         * @member {api.ILoginResponse|null|undefined} loginResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.loginResponse = null;

        /**
         * APIResponse createNativeUserResponse.
         * @member {api.ICreateNativeUserResponse|null|undefined} createNativeUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createNativeUserResponse = null;

        /**
         * APIResponse authenticateResponse.
         * @member {api.IAuthenticateResponse|null|undefined} authenticateResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.authenticateResponse = null;

        /**
         * APIResponse getUserResponse.
         * @member {api.IGetUserResponse|null|undefined} getUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getUserResponse = null;

        /**
         * APIResponse updateSlackUserResponse.
         * @member {api.IUpdateSlackUserResponse|null|undefined} updateSlackUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateSlackUserResponse = null;

        /**
         * APIResponse updateUserResponse.
         * @member {api.IUpdateUserResponse|null|undefined} updateUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateUserResponse = null;

        /**
         * APIResponse addSlackTeamResponse.
         * @member {api.IAddSlackTeamResponse|null|undefined} addSlackTeamResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addSlackTeamResponse = null;

        /**
         * APIResponse updatePaymentResponse.
         * @member {api.IUpdatePaymentResponse|null|undefined} updatePaymentResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updatePaymentResponse = null;

        /**
         * APIResponse deletePaymentResponse.
         * @member {api.IDeletePaymentResponse|null|undefined} deletePaymentResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deletePaymentResponse = null;

        /**
         * APIResponse getAvailablePlansResponse.
         * @member {api.IGetAvailablePlansResponse|null|undefined} getAvailablePlansResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getAvailablePlansResponse = null;

        /**
         * APIResponse getAvailableModelsResponse.
         * @member {api.IGetAvailableModelsResponse|null|undefined} getAvailableModelsResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getAvailableModelsResponse = null;

        /**
         * APIResponse mergeUserResponse.
         * @member {api.IMergeUserResponse|null|undefined} mergeUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.mergeUserResponse = null;

        /**
         * APIResponse generateAiResponse.
         * @member {api.IGenerateAIResponse|null|undefined} generateAiResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.generateAiResponse = null;

        /**
         * APIResponse validateResponse.
         * @member {api.IValidateResponse|null|undefined} validateResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.validateResponse = null;

        /**
         * APIResponse sendValidationCodeResponse.
         * @member {api.ISendValidationCodeResponse|null|undefined} sendValidationCodeResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.sendValidationCodeResponse = null;

        /**
         * APIResponse getFilesResponse.
         * @member {api.IGetFilesResponse|null|undefined} getFilesResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getFilesResponse = null;

        /**
         * APIResponse deleteFileResponse.
         * @member {api.IDeleteFileResponse|null|undefined} deleteFileResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteFileResponse = null;

        /**
         * APIResponse createFolderResponse.
         * @member {api.ICreateFolderResponse|null|undefined} createFolderResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createFolderResponse = null;

        /**
         * APIResponse createProjectResponse.
         * @member {api.ICreateProjectResponse|null|undefined} createProjectResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createProjectResponse = null;

        /**
         * APIResponse getProjectResponse.
         * @member {api.IGetProjectResponse|null|undefined} getProjectResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getProjectResponse = null;

        /**
         * APIResponse updateProjectResponse.
         * @member {api.IUpdateProjectResponse|null|undefined} updateProjectResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateProjectResponse = null;

        /**
         * APIResponse updateFileResponse.
         * @member {api.IUpdateFileResponse|null|undefined} updateFileResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateFileResponse = null;

        /**
         * APIResponse createComprehendResponse.
         * @member {api.ICreateComprehendResponse|null|undefined} createComprehendResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createComprehendResponse = null;

        /**
         * APIResponse getFileUrlResponse.
         * @member {api.IGetFileUrlResponse|null|undefined} getFileUrlResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getFileUrlResponse = null;

        /**
         * APIResponse checkComprehendTaskStatusResponse.
         * @member {api.ICheckComprehendTaskStatusResponse|null|undefined} checkComprehendTaskStatusResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.checkComprehendTaskStatusResponse = null;

        /**
         * APIResponse getGeneratedImagesResponse.
         * @member {api.IGetGeneratedImagesResponse|null|undefined} getGeneratedImagesResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getGeneratedImagesResponse = null;

        /**
         * APIResponse updateGeneratedImageResponse.
         * @member {api.IUpdateGeneratedImageResponse|null|undefined} updateGeneratedImageResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateGeneratedImageResponse = null;

        /**
         * APIResponse executeAdminTaskResponse.
         * @member {api.IExecuteAdminTaskResponse|null|undefined} executeAdminTaskResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.executeAdminTaskResponse = null;

        /**
         * APIResponse getSegmentResponse.
         * @member {api.IGetSegmentResponse|null|undefined} getSegmentResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getSegmentResponse = null;

        /**
         * APIResponse getMaskResponse.
         * @member {api.IGetMaskResponse|null|undefined} getMaskResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getMaskResponse = null;

        /**
         * APIResponse getCaptionResponse.
         * @member {api.IGetCaptionResponse|null|undefined} getCaptionResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getCaptionResponse = null;

        /**
         * APIResponse deleteGeneratedImage.
         * @member {api.IDeleteGeneratedImageResponse|null|undefined} deleteGeneratedImage
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteGeneratedImage = null;

        /**
         * APIResponse addGeneratedImageCommentResponse.
         * @member {api.IAddGeneratedImageCommentResponse|null|undefined} addGeneratedImageCommentResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addGeneratedImageCommentResponse = null;

        /**
         * APIResponse deleteGeneratedImageCommentResponse.
         * @member {api.IDeleteGeneratedImageCommentResponse|null|undefined} deleteGeneratedImageCommentResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteGeneratedImageCommentResponse = null;

        /**
         * APIResponse updateGeneratedImageCommentResponse.
         * @member {api.IUpdateGeneratedImageCommentResponse|null|undefined} updateGeneratedImageCommentResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateGeneratedImageCommentResponse = null;

        /**
         * APIResponse addReceiptResponse.
         * @member {api.IAddReceiptResponse|null|undefined} addReceiptResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addReceiptResponse = null;

        /**
         * APIResponse getAvailableProductsResponse.
         * @member {api.IGetAvailableProductsResponse|null|undefined} getAvailableProductsResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getAvailableProductsResponse = null;

        /**
         * APIResponse deleteUserResponse.
         * @member {api.IDeleteUserResponse|null|undefined} deleteUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteUserResponse = null;

        /**
         * APIResponse jobCreatedResponse.
         * @member {api.IJobCreatedResponse|null|undefined} jobCreatedResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.jobCreatedResponse = null;

        /**
         * APIResponse jobUpdateResponse.
         * @member {api.IJobUpdateResponse|null|undefined} jobUpdateResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.jobUpdateResponse = null;

        /**
         * APIResponse jobStatusResponse.
         * @member {api.IJobStatusResponse|null|undefined} jobStatusResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.jobStatusResponse = null;

        /**
         * APIResponse getSiteEmbeddingResponse.
         * @member {api.IGetSiteEmbeddingResponse|null|undefined} getSiteEmbeddingResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getSiteEmbeddingResponse = null;

        /**
         * APIResponse sendFeedbackResponse.
         * @member {api.ISendFeedbackResponse|null|undefined} sendFeedbackResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.sendFeedbackResponse = null;

        /**
         * APIResponse getJobsResponse.
         * @member {api.IGetJobsResponse|null|undefined} getJobsResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getJobsResponse = null;

        /**
         * APIResponse getConversationsResponse.
         * @member {api.IGetConversationsResponse|null|undefined} getConversationsResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getConversationsResponse = null;

        /**
         * APIResponse getMessagesResponse.
         * @member {api.IGetMessagesResponse|null|undefined} getMessagesResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getMessagesResponse = null;

        /**
         * APIResponse updateMessageResponse.
         * @member {api.IUpdateMessageResponse|null|undefined} updateMessageResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateMessageResponse = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * APIResponse apiResponseInfo.
         * @member {"loginResponse"|"createNativeUserResponse"|"authenticateResponse"|"getUserResponse"|"updateSlackUserResponse"|"updateUserResponse"|"addSlackTeamResponse"|"updatePaymentResponse"|"deletePaymentResponse"|"getAvailablePlansResponse"|"getAvailableModelsResponse"|"mergeUserResponse"|"generateAiResponse"|"validateResponse"|"sendValidationCodeResponse"|"getFilesResponse"|"deleteFileResponse"|"createFolderResponse"|"createProjectResponse"|"getProjectResponse"|"updateProjectResponse"|"updateFileResponse"|"createComprehendResponse"|"getFileUrlResponse"|"checkComprehendTaskStatusResponse"|"getGeneratedImagesResponse"|"updateGeneratedImageResponse"|"executeAdminTaskResponse"|"getSegmentResponse"|"getMaskResponse"|"getCaptionResponse"|"deleteGeneratedImage"|"addGeneratedImageCommentResponse"|"deleteGeneratedImageCommentResponse"|"updateGeneratedImageCommentResponse"|"addReceiptResponse"|"getAvailableProductsResponse"|"deleteUserResponse"|"jobCreatedResponse"|"jobUpdateResponse"|"jobStatusResponse"|"getSiteEmbeddingResponse"|"sendFeedbackResponse"|"getJobsResponse"|"getConversationsResponse"|"getMessagesResponse"|"updateMessageResponse"|undefined} apiResponseInfo
         * @memberof api.APIResponse
         * @instance
         */
        Object.defineProperty(APIResponse.prototype, "apiResponseInfo", {
            get: $util.oneOfGetter($oneOfFields = ["loginResponse", "createNativeUserResponse", "authenticateResponse", "getUserResponse", "updateSlackUserResponse", "updateUserResponse", "addSlackTeamResponse", "updatePaymentResponse", "deletePaymentResponse", "getAvailablePlansResponse", "getAvailableModelsResponse", "mergeUserResponse", "generateAiResponse", "validateResponse", "sendValidationCodeResponse", "getFilesResponse", "deleteFileResponse", "createFolderResponse", "createProjectResponse", "getProjectResponse", "updateProjectResponse", "updateFileResponse", "createComprehendResponse", "getFileUrlResponse", "checkComprehendTaskStatusResponse", "getGeneratedImagesResponse", "updateGeneratedImageResponse", "executeAdminTaskResponse", "getSegmentResponse", "getMaskResponse", "getCaptionResponse", "deleteGeneratedImage", "addGeneratedImageCommentResponse", "deleteGeneratedImageCommentResponse", "updateGeneratedImageCommentResponse", "addReceiptResponse", "getAvailableProductsResponse", "deleteUserResponse", "jobCreatedResponse", "jobUpdateResponse", "jobStatusResponse", "getSiteEmbeddingResponse", "sendFeedbackResponse", "getJobsResponse", "getConversationsResponse", "getMessagesResponse", "updateMessageResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new APIResponse instance using the specified properties.
         * @function create
         * @memberof api.APIResponse
         * @static
         * @param {api.IAPIResponse=} [properties] Properties to set
         * @returns {api.APIResponse} APIResponse instance
         */
        APIResponse.create = function create(properties) {
            return new APIResponse(properties);
        };

        /**
         * Encodes the specified APIResponse message. Does not implicitly {@link api.APIResponse.verify|verify} messages.
         * @function encode
         * @memberof api.APIResponse
         * @static
         * @param {api.IAPIResponse} message APIResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.requestId);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorCode);
            if (message.loginResponse != null && Object.hasOwnProperty.call(message, "loginResponse"))
                $root.api.LoginResponse.encode(message.loginResponse, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.authenticateResponse != null && Object.hasOwnProperty.call(message, "authenticateResponse"))
                $root.api.AuthenticateResponse.encode(message.authenticateResponse, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.getUserResponse != null && Object.hasOwnProperty.call(message, "getUserResponse"))
                $root.api.GetUserResponse.encode(message.getUserResponse, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.updateSlackUserResponse != null && Object.hasOwnProperty.call(message, "updateSlackUserResponse"))
                $root.api.UpdateSlackUserResponse.encode(message.updateSlackUserResponse, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.updateUserResponse != null && Object.hasOwnProperty.call(message, "updateUserResponse"))
                $root.api.UpdateUserResponse.encode(message.updateUserResponse, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.addSlackTeamResponse != null && Object.hasOwnProperty.call(message, "addSlackTeamResponse"))
                $root.api.AddSlackTeamResponse.encode(message.addSlackTeamResponse, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.updatePaymentResponse != null && Object.hasOwnProperty.call(message, "updatePaymentResponse"))
                $root.api.UpdatePaymentResponse.encode(message.updatePaymentResponse, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.getAvailablePlansResponse != null && Object.hasOwnProperty.call(message, "getAvailablePlansResponse"))
                $root.api.GetAvailablePlansResponse.encode(message.getAvailablePlansResponse, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.getAvailableModelsResponse != null && Object.hasOwnProperty.call(message, "getAvailableModelsResponse"))
                $root.api.GetAvailableModelsResponse.encode(message.getAvailableModelsResponse, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.deletePaymentResponse != null && Object.hasOwnProperty.call(message, "deletePaymentResponse"))
                $root.api.DeletePaymentResponse.encode(message.deletePaymentResponse, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.createNativeUserResponse != null && Object.hasOwnProperty.call(message, "createNativeUserResponse"))
                $root.api.CreateNativeUserResponse.encode(message.createNativeUserResponse, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.mergeUserResponse != null && Object.hasOwnProperty.call(message, "mergeUserResponse"))
                $root.api.MergeUserResponse.encode(message.mergeUserResponse, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.generateAiResponse != null && Object.hasOwnProperty.call(message, "generateAiResponse"))
                $root.api.GenerateAIResponse.encode(message.generateAiResponse, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.validateResponse != null && Object.hasOwnProperty.call(message, "validateResponse"))
                $root.api.ValidateResponse.encode(message.validateResponse, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.sendValidationCodeResponse != null && Object.hasOwnProperty.call(message, "sendValidationCodeResponse"))
                $root.api.SendValidationCodeResponse.encode(message.sendValidationCodeResponse, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.getFilesResponse != null && Object.hasOwnProperty.call(message, "getFilesResponse"))
                $root.api.GetFilesResponse.encode(message.getFilesResponse, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.deleteFileResponse != null && Object.hasOwnProperty.call(message, "deleteFileResponse"))
                $root.api.DeleteFileResponse.encode(message.deleteFileResponse, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.createFolderResponse != null && Object.hasOwnProperty.call(message, "createFolderResponse"))
                $root.api.CreateFolderResponse.encode(message.createFolderResponse, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.createProjectResponse != null && Object.hasOwnProperty.call(message, "createProjectResponse"))
                $root.api.CreateProjectResponse.encode(message.createProjectResponse, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.getProjectResponse != null && Object.hasOwnProperty.call(message, "getProjectResponse"))
                $root.api.GetProjectResponse.encode(message.getProjectResponse, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.updateProjectResponse != null && Object.hasOwnProperty.call(message, "updateProjectResponse"))
                $root.api.UpdateProjectResponse.encode(message.updateProjectResponse, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.updateFileResponse != null && Object.hasOwnProperty.call(message, "updateFileResponse"))
                $root.api.UpdateFileResponse.encode(message.updateFileResponse, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.createComprehendResponse != null && Object.hasOwnProperty.call(message, "createComprehendResponse"))
                $root.api.CreateComprehendResponse.encode(message.createComprehendResponse, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.getFileUrlResponse != null && Object.hasOwnProperty.call(message, "getFileUrlResponse"))
                $root.api.GetFileUrlResponse.encode(message.getFileUrlResponse, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.checkComprehendTaskStatusResponse != null && Object.hasOwnProperty.call(message, "checkComprehendTaskStatusResponse"))
                $root.api.CheckComprehendTaskStatusResponse.encode(message.checkComprehendTaskStatusResponse, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.getGeneratedImagesResponse != null && Object.hasOwnProperty.call(message, "getGeneratedImagesResponse"))
                $root.api.GetGeneratedImagesResponse.encode(message.getGeneratedImagesResponse, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.updateGeneratedImageResponse != null && Object.hasOwnProperty.call(message, "updateGeneratedImageResponse"))
                $root.api.UpdateGeneratedImageResponse.encode(message.updateGeneratedImageResponse, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.executeAdminTaskResponse != null && Object.hasOwnProperty.call(message, "executeAdminTaskResponse"))
                $root.api.ExecuteAdminTaskResponse.encode(message.executeAdminTaskResponse, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.getSegmentResponse != null && Object.hasOwnProperty.call(message, "getSegmentResponse"))
                $root.api.GetSegmentResponse.encode(message.getSegmentResponse, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.getCaptionResponse != null && Object.hasOwnProperty.call(message, "getCaptionResponse"))
                $root.api.GetCaptionResponse.encode(message.getCaptionResponse, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            if (message.getMaskResponse != null && Object.hasOwnProperty.call(message, "getMaskResponse"))
                $root.api.GetMaskResponse.encode(message.getMaskResponse, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            if (message.deleteGeneratedImage != null && Object.hasOwnProperty.call(message, "deleteGeneratedImage"))
                $root.api.DeleteGeneratedImageResponse.encode(message.deleteGeneratedImage, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
            if (message.addGeneratedImageCommentResponse != null && Object.hasOwnProperty.call(message, "addGeneratedImageCommentResponse"))
                $root.api.AddGeneratedImageCommentResponse.encode(message.addGeneratedImageCommentResponse, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
            if (message.deleteGeneratedImageCommentResponse != null && Object.hasOwnProperty.call(message, "deleteGeneratedImageCommentResponse"))
                $root.api.DeleteGeneratedImageCommentResponse.encode(message.deleteGeneratedImageCommentResponse, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
            if (message.updateGeneratedImageCommentResponse != null && Object.hasOwnProperty.call(message, "updateGeneratedImageCommentResponse"))
                $root.api.UpdateGeneratedImageCommentResponse.encode(message.updateGeneratedImageCommentResponse, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.addReceiptResponse != null && Object.hasOwnProperty.call(message, "addReceiptResponse"))
                $root.api.AddReceiptResponse.encode(message.addReceiptResponse, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.getAvailableProductsResponse != null && Object.hasOwnProperty.call(message, "getAvailableProductsResponse"))
                $root.api.GetAvailableProductsResponse.encode(message.getAvailableProductsResponse, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
            if (message.deleteUserResponse != null && Object.hasOwnProperty.call(message, "deleteUserResponse"))
                $root.api.DeleteUserResponse.encode(message.deleteUserResponse, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
            if (message.jobCreatedResponse != null && Object.hasOwnProperty.call(message, "jobCreatedResponse"))
                $root.api.JobCreatedResponse.encode(message.jobCreatedResponse, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            if (message.jobStatusResponse != null && Object.hasOwnProperty.call(message, "jobStatusResponse"))
                $root.api.JobStatusResponse.encode(message.jobStatusResponse, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
            if (message.getSiteEmbeddingResponse != null && Object.hasOwnProperty.call(message, "getSiteEmbeddingResponse"))
                $root.api.GetSiteEmbeddingResponse.encode(message.getSiteEmbeddingResponse, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
            if (message.sendFeedbackResponse != null && Object.hasOwnProperty.call(message, "sendFeedbackResponse"))
                $root.api.SendFeedbackResponse.encode(message.sendFeedbackResponse, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
            if (message.getJobsResponse != null && Object.hasOwnProperty.call(message, "getJobsResponse"))
                $root.api.GetJobsResponse.encode(message.getJobsResponse, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
            if (message.getConversationsResponse != null && Object.hasOwnProperty.call(message, "getConversationsResponse"))
                $root.api.GetConversationsResponse.encode(message.getConversationsResponse, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
            if (message.getMessagesResponse != null && Object.hasOwnProperty.call(message, "getMessagesResponse"))
                $root.api.GetMessagesResponse.encode(message.getMessagesResponse, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.updateMessageResponse != null && Object.hasOwnProperty.call(message, "updateMessageResponse"))
                $root.api.UpdateMessageResponse.encode(message.updateMessageResponse, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.jobUpdateResponse != null && Object.hasOwnProperty.call(message, "jobUpdateResponse"))
                $root.api.JobUpdateResponse.encode(message.jobUpdateResponse, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified APIResponse message, length delimited. Does not implicitly {@link api.APIResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIResponse
         * @static
         * @param {api.IAPIResponse} message APIResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIResponse} APIResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestId = reader.int32();
                    break;
                case 2:
                    message.error = reader.string();
                    break;
                case 3:
                    message.errorCode = reader.string();
                    break;
                case 4:
                    message.loginResponse = $root.api.LoginResponse.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.createNativeUserResponse = $root.api.CreateNativeUserResponse.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.authenticateResponse = $root.api.AuthenticateResponse.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.getUserResponse = $root.api.GetUserResponse.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.updateSlackUserResponse = $root.api.UpdateSlackUserResponse.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.updateUserResponse = $root.api.UpdateUserResponse.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.addSlackTeamResponse = $root.api.AddSlackTeamResponse.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.updatePaymentResponse = $root.api.UpdatePaymentResponse.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.deletePaymentResponse = $root.api.DeletePaymentResponse.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.getAvailablePlansResponse = $root.api.GetAvailablePlansResponse.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.getAvailableModelsResponse = $root.api.GetAvailableModelsResponse.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.mergeUserResponse = $root.api.MergeUserResponse.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.generateAiResponse = $root.api.GenerateAIResponse.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.validateResponse = $root.api.ValidateResponse.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.sendValidationCodeResponse = $root.api.SendValidationCodeResponse.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.getFilesResponse = $root.api.GetFilesResponse.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.deleteFileResponse = $root.api.DeleteFileResponse.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.createFolderResponse = $root.api.CreateFolderResponse.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.createProjectResponse = $root.api.CreateProjectResponse.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.getProjectResponse = $root.api.GetProjectResponse.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.updateProjectResponse = $root.api.UpdateProjectResponse.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.updateFileResponse = $root.api.UpdateFileResponse.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.createComprehendResponse = $root.api.CreateComprehendResponse.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.getFileUrlResponse = $root.api.GetFileUrlResponse.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.checkComprehendTaskStatusResponse = $root.api.CheckComprehendTaskStatusResponse.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.getGeneratedImagesResponse = $root.api.GetGeneratedImagesResponse.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.updateGeneratedImageResponse = $root.api.UpdateGeneratedImageResponse.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.executeAdminTaskResponse = $root.api.ExecuteAdminTaskResponse.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.getSegmentResponse = $root.api.GetSegmentResponse.decode(reader, reader.uint32());
                    break;
                case 36:
                    message.getMaskResponse = $root.api.GetMaskResponse.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.getCaptionResponse = $root.api.GetCaptionResponse.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.deleteGeneratedImage = $root.api.DeleteGeneratedImageResponse.decode(reader, reader.uint32());
                    break;
                case 38:
                    message.addGeneratedImageCommentResponse = $root.api.AddGeneratedImageCommentResponse.decode(reader, reader.uint32());
                    break;
                case 39:
                    message.deleteGeneratedImageCommentResponse = $root.api.DeleteGeneratedImageCommentResponse.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.updateGeneratedImageCommentResponse = $root.api.UpdateGeneratedImageCommentResponse.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.addReceiptResponse = $root.api.AddReceiptResponse.decode(reader, reader.uint32());
                    break;
                case 42:
                    message.getAvailableProductsResponse = $root.api.GetAvailableProductsResponse.decode(reader, reader.uint32());
                    break;
                case 43:
                    message.deleteUserResponse = $root.api.DeleteUserResponse.decode(reader, reader.uint32());
                    break;
                case 44:
                    message.jobCreatedResponse = $root.api.JobCreatedResponse.decode(reader, reader.uint32());
                    break;
                case 52:
                    message.jobUpdateResponse = $root.api.JobUpdateResponse.decode(reader, reader.uint32());
                    break;
                case 45:
                    message.jobStatusResponse = $root.api.JobStatusResponse.decode(reader, reader.uint32());
                    break;
                case 46:
                    message.getSiteEmbeddingResponse = $root.api.GetSiteEmbeddingResponse.decode(reader, reader.uint32());
                    break;
                case 47:
                    message.sendFeedbackResponse = $root.api.SendFeedbackResponse.decode(reader, reader.uint32());
                    break;
                case 48:
                    message.getJobsResponse = $root.api.GetJobsResponse.decode(reader, reader.uint32());
                    break;
                case 49:
                    message.getConversationsResponse = $root.api.GetConversationsResponse.decode(reader, reader.uint32());
                    break;
                case 50:
                    message.getMessagesResponse = $root.api.GetMessagesResponse.decode(reader, reader.uint32());
                    break;
                case 51:
                    message.updateMessageResponse = $root.api.UpdateMessageResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIResponse} APIResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIResponse message.
         * @function verify
         * @memberof api.APIResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (!$util.isInteger(message.requestId))
                    return "requestId: integer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            if (message.loginResponse != null && message.hasOwnProperty("loginResponse")) {
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.LoginResponse.verify(message.loginResponse);
                    if (error)
                        return "loginResponse." + error;
                }
            }
            if (message.createNativeUserResponse != null && message.hasOwnProperty("createNativeUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateNativeUserResponse.verify(message.createNativeUserResponse);
                    if (error)
                        return "createNativeUserResponse." + error;
                }
            }
            if (message.authenticateResponse != null && message.hasOwnProperty("authenticateResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AuthenticateResponse.verify(message.authenticateResponse);
                    if (error)
                        return "authenticateResponse." + error;
                }
            }
            if (message.getUserResponse != null && message.hasOwnProperty("getUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetUserResponse.verify(message.getUserResponse);
                    if (error)
                        return "getUserResponse." + error;
                }
            }
            if (message.updateSlackUserResponse != null && message.hasOwnProperty("updateSlackUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateSlackUserResponse.verify(message.updateSlackUserResponse);
                    if (error)
                        return "updateSlackUserResponse." + error;
                }
            }
            if (message.updateUserResponse != null && message.hasOwnProperty("updateUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateUserResponse.verify(message.updateUserResponse);
                    if (error)
                        return "updateUserResponse." + error;
                }
            }
            if (message.addSlackTeamResponse != null && message.hasOwnProperty("addSlackTeamResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddSlackTeamResponse.verify(message.addSlackTeamResponse);
                    if (error)
                        return "addSlackTeamResponse." + error;
                }
            }
            if (message.updatePaymentResponse != null && message.hasOwnProperty("updatePaymentResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdatePaymentResponse.verify(message.updatePaymentResponse);
                    if (error)
                        return "updatePaymentResponse." + error;
                }
            }
            if (message.deletePaymentResponse != null && message.hasOwnProperty("deletePaymentResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeletePaymentResponse.verify(message.deletePaymentResponse);
                    if (error)
                        return "deletePaymentResponse." + error;
                }
            }
            if (message.getAvailablePlansResponse != null && message.hasOwnProperty("getAvailablePlansResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetAvailablePlansResponse.verify(message.getAvailablePlansResponse);
                    if (error)
                        return "getAvailablePlansResponse." + error;
                }
            }
            if (message.getAvailableModelsResponse != null && message.hasOwnProperty("getAvailableModelsResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetAvailableModelsResponse.verify(message.getAvailableModelsResponse);
                    if (error)
                        return "getAvailableModelsResponse." + error;
                }
            }
            if (message.mergeUserResponse != null && message.hasOwnProperty("mergeUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.MergeUserResponse.verify(message.mergeUserResponse);
                    if (error)
                        return "mergeUserResponse." + error;
                }
            }
            if (message.generateAiResponse != null && message.hasOwnProperty("generateAiResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GenerateAIResponse.verify(message.generateAiResponse);
                    if (error)
                        return "generateAiResponse." + error;
                }
            }
            if (message.validateResponse != null && message.hasOwnProperty("validateResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.ValidateResponse.verify(message.validateResponse);
                    if (error)
                        return "validateResponse." + error;
                }
            }
            if (message.sendValidationCodeResponse != null && message.hasOwnProperty("sendValidationCodeResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.SendValidationCodeResponse.verify(message.sendValidationCodeResponse);
                    if (error)
                        return "sendValidationCodeResponse." + error;
                }
            }
            if (message.getFilesResponse != null && message.hasOwnProperty("getFilesResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetFilesResponse.verify(message.getFilesResponse);
                    if (error)
                        return "getFilesResponse." + error;
                }
            }
            if (message.deleteFileResponse != null && message.hasOwnProperty("deleteFileResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteFileResponse.verify(message.deleteFileResponse);
                    if (error)
                        return "deleteFileResponse." + error;
                }
            }
            if (message.createFolderResponse != null && message.hasOwnProperty("createFolderResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateFolderResponse.verify(message.createFolderResponse);
                    if (error)
                        return "createFolderResponse." + error;
                }
            }
            if (message.createProjectResponse != null && message.hasOwnProperty("createProjectResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateProjectResponse.verify(message.createProjectResponse);
                    if (error)
                        return "createProjectResponse." + error;
                }
            }
            if (message.getProjectResponse != null && message.hasOwnProperty("getProjectResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetProjectResponse.verify(message.getProjectResponse);
                    if (error)
                        return "getProjectResponse." + error;
                }
            }
            if (message.updateProjectResponse != null && message.hasOwnProperty("updateProjectResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateProjectResponse.verify(message.updateProjectResponse);
                    if (error)
                        return "updateProjectResponse." + error;
                }
            }
            if (message.updateFileResponse != null && message.hasOwnProperty("updateFileResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateFileResponse.verify(message.updateFileResponse);
                    if (error)
                        return "updateFileResponse." + error;
                }
            }
            if (message.createComprehendResponse != null && message.hasOwnProperty("createComprehendResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateComprehendResponse.verify(message.createComprehendResponse);
                    if (error)
                        return "createComprehendResponse." + error;
                }
            }
            if (message.getFileUrlResponse != null && message.hasOwnProperty("getFileUrlResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetFileUrlResponse.verify(message.getFileUrlResponse);
                    if (error)
                        return "getFileUrlResponse." + error;
                }
            }
            if (message.checkComprehendTaskStatusResponse != null && message.hasOwnProperty("checkComprehendTaskStatusResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CheckComprehendTaskStatusResponse.verify(message.checkComprehendTaskStatusResponse);
                    if (error)
                        return "checkComprehendTaskStatusResponse." + error;
                }
            }
            if (message.getGeneratedImagesResponse != null && message.hasOwnProperty("getGeneratedImagesResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetGeneratedImagesResponse.verify(message.getGeneratedImagesResponse);
                    if (error)
                        return "getGeneratedImagesResponse." + error;
                }
            }
            if (message.updateGeneratedImageResponse != null && message.hasOwnProperty("updateGeneratedImageResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateGeneratedImageResponse.verify(message.updateGeneratedImageResponse);
                    if (error)
                        return "updateGeneratedImageResponse." + error;
                }
            }
            if (message.executeAdminTaskResponse != null && message.hasOwnProperty("executeAdminTaskResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.ExecuteAdminTaskResponse.verify(message.executeAdminTaskResponse);
                    if (error)
                        return "executeAdminTaskResponse." + error;
                }
            }
            if (message.getSegmentResponse != null && message.hasOwnProperty("getSegmentResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetSegmentResponse.verify(message.getSegmentResponse);
                    if (error)
                        return "getSegmentResponse." + error;
                }
            }
            if (message.getMaskResponse != null && message.hasOwnProperty("getMaskResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetMaskResponse.verify(message.getMaskResponse);
                    if (error)
                        return "getMaskResponse." + error;
                }
            }
            if (message.getCaptionResponse != null && message.hasOwnProperty("getCaptionResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetCaptionResponse.verify(message.getCaptionResponse);
                    if (error)
                        return "getCaptionResponse." + error;
                }
            }
            if (message.deleteGeneratedImage != null && message.hasOwnProperty("deleteGeneratedImage")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteGeneratedImageResponse.verify(message.deleteGeneratedImage);
                    if (error)
                        return "deleteGeneratedImage." + error;
                }
            }
            if (message.addGeneratedImageCommentResponse != null && message.hasOwnProperty("addGeneratedImageCommentResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddGeneratedImageCommentResponse.verify(message.addGeneratedImageCommentResponse);
                    if (error)
                        return "addGeneratedImageCommentResponse." + error;
                }
            }
            if (message.deleteGeneratedImageCommentResponse != null && message.hasOwnProperty("deleteGeneratedImageCommentResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteGeneratedImageCommentResponse.verify(message.deleteGeneratedImageCommentResponse);
                    if (error)
                        return "deleteGeneratedImageCommentResponse." + error;
                }
            }
            if (message.updateGeneratedImageCommentResponse != null && message.hasOwnProperty("updateGeneratedImageCommentResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateGeneratedImageCommentResponse.verify(message.updateGeneratedImageCommentResponse);
                    if (error)
                        return "updateGeneratedImageCommentResponse." + error;
                }
            }
            if (message.addReceiptResponse != null && message.hasOwnProperty("addReceiptResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddReceiptResponse.verify(message.addReceiptResponse);
                    if (error)
                        return "addReceiptResponse." + error;
                }
            }
            if (message.getAvailableProductsResponse != null && message.hasOwnProperty("getAvailableProductsResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetAvailableProductsResponse.verify(message.getAvailableProductsResponse);
                    if (error)
                        return "getAvailableProductsResponse." + error;
                }
            }
            if (message.deleteUserResponse != null && message.hasOwnProperty("deleteUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteUserResponse.verify(message.deleteUserResponse);
                    if (error)
                        return "deleteUserResponse." + error;
                }
            }
            if (message.jobCreatedResponse != null && message.hasOwnProperty("jobCreatedResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.JobCreatedResponse.verify(message.jobCreatedResponse);
                    if (error)
                        return "jobCreatedResponse." + error;
                }
            }
            if (message.jobUpdateResponse != null && message.hasOwnProperty("jobUpdateResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.JobUpdateResponse.verify(message.jobUpdateResponse);
                    if (error)
                        return "jobUpdateResponse." + error;
                }
            }
            if (message.jobStatusResponse != null && message.hasOwnProperty("jobStatusResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.JobStatusResponse.verify(message.jobStatusResponse);
                    if (error)
                        return "jobStatusResponse." + error;
                }
            }
            if (message.getSiteEmbeddingResponse != null && message.hasOwnProperty("getSiteEmbeddingResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetSiteEmbeddingResponse.verify(message.getSiteEmbeddingResponse);
                    if (error)
                        return "getSiteEmbeddingResponse." + error;
                }
            }
            if (message.sendFeedbackResponse != null && message.hasOwnProperty("sendFeedbackResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.SendFeedbackResponse.verify(message.sendFeedbackResponse);
                    if (error)
                        return "sendFeedbackResponse." + error;
                }
            }
            if (message.getJobsResponse != null && message.hasOwnProperty("getJobsResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetJobsResponse.verify(message.getJobsResponse);
                    if (error)
                        return "getJobsResponse." + error;
                }
            }
            if (message.getConversationsResponse != null && message.hasOwnProperty("getConversationsResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetConversationsResponse.verify(message.getConversationsResponse);
                    if (error)
                        return "getConversationsResponse." + error;
                }
            }
            if (message.getMessagesResponse != null && message.hasOwnProperty("getMessagesResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetMessagesResponse.verify(message.getMessagesResponse);
                    if (error)
                        return "getMessagesResponse." + error;
                }
            }
            if (message.updateMessageResponse != null && message.hasOwnProperty("updateMessageResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateMessageResponse.verify(message.updateMessageResponse);
                    if (error)
                        return "updateMessageResponse." + error;
                }
            }
            return null;
        };

        /**
         * Creates a APIResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIResponse} APIResponse
         */
        APIResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIResponse)
                return object;
            let message = new $root.api.APIResponse();
            if (object.requestId != null)
                message.requestId = object.requestId | 0;
            if (object.error != null)
                message.error = String(object.error);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            if (object.loginResponse != null) {
                if (typeof object.loginResponse !== "object")
                    throw TypeError(".api.APIResponse.loginResponse: object expected");
                message.loginResponse = $root.api.LoginResponse.fromObject(object.loginResponse);
            }
            if (object.createNativeUserResponse != null) {
                if (typeof object.createNativeUserResponse !== "object")
                    throw TypeError(".api.APIResponse.createNativeUserResponse: object expected");
                message.createNativeUserResponse = $root.api.CreateNativeUserResponse.fromObject(object.createNativeUserResponse);
            }
            if (object.authenticateResponse != null) {
                if (typeof object.authenticateResponse !== "object")
                    throw TypeError(".api.APIResponse.authenticateResponse: object expected");
                message.authenticateResponse = $root.api.AuthenticateResponse.fromObject(object.authenticateResponse);
            }
            if (object.getUserResponse != null) {
                if (typeof object.getUserResponse !== "object")
                    throw TypeError(".api.APIResponse.getUserResponse: object expected");
                message.getUserResponse = $root.api.GetUserResponse.fromObject(object.getUserResponse);
            }
            if (object.updateSlackUserResponse != null) {
                if (typeof object.updateSlackUserResponse !== "object")
                    throw TypeError(".api.APIResponse.updateSlackUserResponse: object expected");
                message.updateSlackUserResponse = $root.api.UpdateSlackUserResponse.fromObject(object.updateSlackUserResponse);
            }
            if (object.updateUserResponse != null) {
                if (typeof object.updateUserResponse !== "object")
                    throw TypeError(".api.APIResponse.updateUserResponse: object expected");
                message.updateUserResponse = $root.api.UpdateUserResponse.fromObject(object.updateUserResponse);
            }
            if (object.addSlackTeamResponse != null) {
                if (typeof object.addSlackTeamResponse !== "object")
                    throw TypeError(".api.APIResponse.addSlackTeamResponse: object expected");
                message.addSlackTeamResponse = $root.api.AddSlackTeamResponse.fromObject(object.addSlackTeamResponse);
            }
            if (object.updatePaymentResponse != null) {
                if (typeof object.updatePaymentResponse !== "object")
                    throw TypeError(".api.APIResponse.updatePaymentResponse: object expected");
                message.updatePaymentResponse = $root.api.UpdatePaymentResponse.fromObject(object.updatePaymentResponse);
            }
            if (object.deletePaymentResponse != null) {
                if (typeof object.deletePaymentResponse !== "object")
                    throw TypeError(".api.APIResponse.deletePaymentResponse: object expected");
                message.deletePaymentResponse = $root.api.DeletePaymentResponse.fromObject(object.deletePaymentResponse);
            }
            if (object.getAvailablePlansResponse != null) {
                if (typeof object.getAvailablePlansResponse !== "object")
                    throw TypeError(".api.APIResponse.getAvailablePlansResponse: object expected");
                message.getAvailablePlansResponse = $root.api.GetAvailablePlansResponse.fromObject(object.getAvailablePlansResponse);
            }
            if (object.getAvailableModelsResponse != null) {
                if (typeof object.getAvailableModelsResponse !== "object")
                    throw TypeError(".api.APIResponse.getAvailableModelsResponse: object expected");
                message.getAvailableModelsResponse = $root.api.GetAvailableModelsResponse.fromObject(object.getAvailableModelsResponse);
            }
            if (object.mergeUserResponse != null) {
                if (typeof object.mergeUserResponse !== "object")
                    throw TypeError(".api.APIResponse.mergeUserResponse: object expected");
                message.mergeUserResponse = $root.api.MergeUserResponse.fromObject(object.mergeUserResponse);
            }
            if (object.generateAiResponse != null) {
                if (typeof object.generateAiResponse !== "object")
                    throw TypeError(".api.APIResponse.generateAiResponse: object expected");
                message.generateAiResponse = $root.api.GenerateAIResponse.fromObject(object.generateAiResponse);
            }
            if (object.validateResponse != null) {
                if (typeof object.validateResponse !== "object")
                    throw TypeError(".api.APIResponse.validateResponse: object expected");
                message.validateResponse = $root.api.ValidateResponse.fromObject(object.validateResponse);
            }
            if (object.sendValidationCodeResponse != null) {
                if (typeof object.sendValidationCodeResponse !== "object")
                    throw TypeError(".api.APIResponse.sendValidationCodeResponse: object expected");
                message.sendValidationCodeResponse = $root.api.SendValidationCodeResponse.fromObject(object.sendValidationCodeResponse);
            }
            if (object.getFilesResponse != null) {
                if (typeof object.getFilesResponse !== "object")
                    throw TypeError(".api.APIResponse.getFilesResponse: object expected");
                message.getFilesResponse = $root.api.GetFilesResponse.fromObject(object.getFilesResponse);
            }
            if (object.deleteFileResponse != null) {
                if (typeof object.deleteFileResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteFileResponse: object expected");
                message.deleteFileResponse = $root.api.DeleteFileResponse.fromObject(object.deleteFileResponse);
            }
            if (object.createFolderResponse != null) {
                if (typeof object.createFolderResponse !== "object")
                    throw TypeError(".api.APIResponse.createFolderResponse: object expected");
                message.createFolderResponse = $root.api.CreateFolderResponse.fromObject(object.createFolderResponse);
            }
            if (object.createProjectResponse != null) {
                if (typeof object.createProjectResponse !== "object")
                    throw TypeError(".api.APIResponse.createProjectResponse: object expected");
                message.createProjectResponse = $root.api.CreateProjectResponse.fromObject(object.createProjectResponse);
            }
            if (object.getProjectResponse != null) {
                if (typeof object.getProjectResponse !== "object")
                    throw TypeError(".api.APIResponse.getProjectResponse: object expected");
                message.getProjectResponse = $root.api.GetProjectResponse.fromObject(object.getProjectResponse);
            }
            if (object.updateProjectResponse != null) {
                if (typeof object.updateProjectResponse !== "object")
                    throw TypeError(".api.APIResponse.updateProjectResponse: object expected");
                message.updateProjectResponse = $root.api.UpdateProjectResponse.fromObject(object.updateProjectResponse);
            }
            if (object.updateFileResponse != null) {
                if (typeof object.updateFileResponse !== "object")
                    throw TypeError(".api.APIResponse.updateFileResponse: object expected");
                message.updateFileResponse = $root.api.UpdateFileResponse.fromObject(object.updateFileResponse);
            }
            if (object.createComprehendResponse != null) {
                if (typeof object.createComprehendResponse !== "object")
                    throw TypeError(".api.APIResponse.createComprehendResponse: object expected");
                message.createComprehendResponse = $root.api.CreateComprehendResponse.fromObject(object.createComprehendResponse);
            }
            if (object.getFileUrlResponse != null) {
                if (typeof object.getFileUrlResponse !== "object")
                    throw TypeError(".api.APIResponse.getFileUrlResponse: object expected");
                message.getFileUrlResponse = $root.api.GetFileUrlResponse.fromObject(object.getFileUrlResponse);
            }
            if (object.checkComprehendTaskStatusResponse != null) {
                if (typeof object.checkComprehendTaskStatusResponse !== "object")
                    throw TypeError(".api.APIResponse.checkComprehendTaskStatusResponse: object expected");
                message.checkComprehendTaskStatusResponse = $root.api.CheckComprehendTaskStatusResponse.fromObject(object.checkComprehendTaskStatusResponse);
            }
            if (object.getGeneratedImagesResponse != null) {
                if (typeof object.getGeneratedImagesResponse !== "object")
                    throw TypeError(".api.APIResponse.getGeneratedImagesResponse: object expected");
                message.getGeneratedImagesResponse = $root.api.GetGeneratedImagesResponse.fromObject(object.getGeneratedImagesResponse);
            }
            if (object.updateGeneratedImageResponse != null) {
                if (typeof object.updateGeneratedImageResponse !== "object")
                    throw TypeError(".api.APIResponse.updateGeneratedImageResponse: object expected");
                message.updateGeneratedImageResponse = $root.api.UpdateGeneratedImageResponse.fromObject(object.updateGeneratedImageResponse);
            }
            if (object.executeAdminTaskResponse != null) {
                if (typeof object.executeAdminTaskResponse !== "object")
                    throw TypeError(".api.APIResponse.executeAdminTaskResponse: object expected");
                message.executeAdminTaskResponse = $root.api.ExecuteAdminTaskResponse.fromObject(object.executeAdminTaskResponse);
            }
            if (object.getSegmentResponse != null) {
                if (typeof object.getSegmentResponse !== "object")
                    throw TypeError(".api.APIResponse.getSegmentResponse: object expected");
                message.getSegmentResponse = $root.api.GetSegmentResponse.fromObject(object.getSegmentResponse);
            }
            if (object.getMaskResponse != null) {
                if (typeof object.getMaskResponse !== "object")
                    throw TypeError(".api.APIResponse.getMaskResponse: object expected");
                message.getMaskResponse = $root.api.GetMaskResponse.fromObject(object.getMaskResponse);
            }
            if (object.getCaptionResponse != null) {
                if (typeof object.getCaptionResponse !== "object")
                    throw TypeError(".api.APIResponse.getCaptionResponse: object expected");
                message.getCaptionResponse = $root.api.GetCaptionResponse.fromObject(object.getCaptionResponse);
            }
            if (object.deleteGeneratedImage != null) {
                if (typeof object.deleteGeneratedImage !== "object")
                    throw TypeError(".api.APIResponse.deleteGeneratedImage: object expected");
                message.deleteGeneratedImage = $root.api.DeleteGeneratedImageResponse.fromObject(object.deleteGeneratedImage);
            }
            if (object.addGeneratedImageCommentResponse != null) {
                if (typeof object.addGeneratedImageCommentResponse !== "object")
                    throw TypeError(".api.APIResponse.addGeneratedImageCommentResponse: object expected");
                message.addGeneratedImageCommentResponse = $root.api.AddGeneratedImageCommentResponse.fromObject(object.addGeneratedImageCommentResponse);
            }
            if (object.deleteGeneratedImageCommentResponse != null) {
                if (typeof object.deleteGeneratedImageCommentResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteGeneratedImageCommentResponse: object expected");
                message.deleteGeneratedImageCommentResponse = $root.api.DeleteGeneratedImageCommentResponse.fromObject(object.deleteGeneratedImageCommentResponse);
            }
            if (object.updateGeneratedImageCommentResponse != null) {
                if (typeof object.updateGeneratedImageCommentResponse !== "object")
                    throw TypeError(".api.APIResponse.updateGeneratedImageCommentResponse: object expected");
                message.updateGeneratedImageCommentResponse = $root.api.UpdateGeneratedImageCommentResponse.fromObject(object.updateGeneratedImageCommentResponse);
            }
            if (object.addReceiptResponse != null) {
                if (typeof object.addReceiptResponse !== "object")
                    throw TypeError(".api.APIResponse.addReceiptResponse: object expected");
                message.addReceiptResponse = $root.api.AddReceiptResponse.fromObject(object.addReceiptResponse);
            }
            if (object.getAvailableProductsResponse != null) {
                if (typeof object.getAvailableProductsResponse !== "object")
                    throw TypeError(".api.APIResponse.getAvailableProductsResponse: object expected");
                message.getAvailableProductsResponse = $root.api.GetAvailableProductsResponse.fromObject(object.getAvailableProductsResponse);
            }
            if (object.deleteUserResponse != null) {
                if (typeof object.deleteUserResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteUserResponse: object expected");
                message.deleteUserResponse = $root.api.DeleteUserResponse.fromObject(object.deleteUserResponse);
            }
            if (object.jobCreatedResponse != null) {
                if (typeof object.jobCreatedResponse !== "object")
                    throw TypeError(".api.APIResponse.jobCreatedResponse: object expected");
                message.jobCreatedResponse = $root.api.JobCreatedResponse.fromObject(object.jobCreatedResponse);
            }
            if (object.jobUpdateResponse != null) {
                if (typeof object.jobUpdateResponse !== "object")
                    throw TypeError(".api.APIResponse.jobUpdateResponse: object expected");
                message.jobUpdateResponse = $root.api.JobUpdateResponse.fromObject(object.jobUpdateResponse);
            }
            if (object.jobStatusResponse != null) {
                if (typeof object.jobStatusResponse !== "object")
                    throw TypeError(".api.APIResponse.jobStatusResponse: object expected");
                message.jobStatusResponse = $root.api.JobStatusResponse.fromObject(object.jobStatusResponse);
            }
            if (object.getSiteEmbeddingResponse != null) {
                if (typeof object.getSiteEmbeddingResponse !== "object")
                    throw TypeError(".api.APIResponse.getSiteEmbeddingResponse: object expected");
                message.getSiteEmbeddingResponse = $root.api.GetSiteEmbeddingResponse.fromObject(object.getSiteEmbeddingResponse);
            }
            if (object.sendFeedbackResponse != null) {
                if (typeof object.sendFeedbackResponse !== "object")
                    throw TypeError(".api.APIResponse.sendFeedbackResponse: object expected");
                message.sendFeedbackResponse = $root.api.SendFeedbackResponse.fromObject(object.sendFeedbackResponse);
            }
            if (object.getJobsResponse != null) {
                if (typeof object.getJobsResponse !== "object")
                    throw TypeError(".api.APIResponse.getJobsResponse: object expected");
                message.getJobsResponse = $root.api.GetJobsResponse.fromObject(object.getJobsResponse);
            }
            if (object.getConversationsResponse != null) {
                if (typeof object.getConversationsResponse !== "object")
                    throw TypeError(".api.APIResponse.getConversationsResponse: object expected");
                message.getConversationsResponse = $root.api.GetConversationsResponse.fromObject(object.getConversationsResponse);
            }
            if (object.getMessagesResponse != null) {
                if (typeof object.getMessagesResponse !== "object")
                    throw TypeError(".api.APIResponse.getMessagesResponse: object expected");
                message.getMessagesResponse = $root.api.GetMessagesResponse.fromObject(object.getMessagesResponse);
            }
            if (object.updateMessageResponse != null) {
                if (typeof object.updateMessageResponse !== "object")
                    throw TypeError(".api.APIResponse.updateMessageResponse: object expected");
                message.updateMessageResponse = $root.api.UpdateMessageResponse.fromObject(object.updateMessageResponse);
            }
            return message;
        };

        /**
         * Creates a plain object from a APIResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIResponse
         * @static
         * @param {api.APIResponse} message APIResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.requestId = 0;
                object.error = "";
                object.errorCode = "";
            }
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                object.requestId = message.requestId;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            if (message.loginResponse != null && message.hasOwnProperty("loginResponse")) {
                object.loginResponse = $root.api.LoginResponse.toObject(message.loginResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "loginResponse";
            }
            if (message.authenticateResponse != null && message.hasOwnProperty("authenticateResponse")) {
                object.authenticateResponse = $root.api.AuthenticateResponse.toObject(message.authenticateResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "authenticateResponse";
            }
            if (message.getUserResponse != null && message.hasOwnProperty("getUserResponse")) {
                object.getUserResponse = $root.api.GetUserResponse.toObject(message.getUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getUserResponse";
            }
            if (message.updateSlackUserResponse != null && message.hasOwnProperty("updateSlackUserResponse")) {
                object.updateSlackUserResponse = $root.api.UpdateSlackUserResponse.toObject(message.updateSlackUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateSlackUserResponse";
            }
            if (message.updateUserResponse != null && message.hasOwnProperty("updateUserResponse")) {
                object.updateUserResponse = $root.api.UpdateUserResponse.toObject(message.updateUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateUserResponse";
            }
            if (message.addSlackTeamResponse != null && message.hasOwnProperty("addSlackTeamResponse")) {
                object.addSlackTeamResponse = $root.api.AddSlackTeamResponse.toObject(message.addSlackTeamResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addSlackTeamResponse";
            }
            if (message.updatePaymentResponse != null && message.hasOwnProperty("updatePaymentResponse")) {
                object.updatePaymentResponse = $root.api.UpdatePaymentResponse.toObject(message.updatePaymentResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updatePaymentResponse";
            }
            if (message.getAvailablePlansResponse != null && message.hasOwnProperty("getAvailablePlansResponse")) {
                object.getAvailablePlansResponse = $root.api.GetAvailablePlansResponse.toObject(message.getAvailablePlansResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getAvailablePlansResponse";
            }
            if (message.getAvailableModelsResponse != null && message.hasOwnProperty("getAvailableModelsResponse")) {
                object.getAvailableModelsResponse = $root.api.GetAvailableModelsResponse.toObject(message.getAvailableModelsResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getAvailableModelsResponse";
            }
            if (message.deletePaymentResponse != null && message.hasOwnProperty("deletePaymentResponse")) {
                object.deletePaymentResponse = $root.api.DeletePaymentResponse.toObject(message.deletePaymentResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deletePaymentResponse";
            }
            if (message.createNativeUserResponse != null && message.hasOwnProperty("createNativeUserResponse")) {
                object.createNativeUserResponse = $root.api.CreateNativeUserResponse.toObject(message.createNativeUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createNativeUserResponse";
            }
            if (message.mergeUserResponse != null && message.hasOwnProperty("mergeUserResponse")) {
                object.mergeUserResponse = $root.api.MergeUserResponse.toObject(message.mergeUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "mergeUserResponse";
            }
            if (message.generateAiResponse != null && message.hasOwnProperty("generateAiResponse")) {
                object.generateAiResponse = $root.api.GenerateAIResponse.toObject(message.generateAiResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "generateAiResponse";
            }
            if (message.validateResponse != null && message.hasOwnProperty("validateResponse")) {
                object.validateResponse = $root.api.ValidateResponse.toObject(message.validateResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "validateResponse";
            }
            if (message.sendValidationCodeResponse != null && message.hasOwnProperty("sendValidationCodeResponse")) {
                object.sendValidationCodeResponse = $root.api.SendValidationCodeResponse.toObject(message.sendValidationCodeResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "sendValidationCodeResponse";
            }
            if (message.getFilesResponse != null && message.hasOwnProperty("getFilesResponse")) {
                object.getFilesResponse = $root.api.GetFilesResponse.toObject(message.getFilesResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getFilesResponse";
            }
            if (message.deleteFileResponse != null && message.hasOwnProperty("deleteFileResponse")) {
                object.deleteFileResponse = $root.api.DeleteFileResponse.toObject(message.deleteFileResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteFileResponse";
            }
            if (message.createFolderResponse != null && message.hasOwnProperty("createFolderResponse")) {
                object.createFolderResponse = $root.api.CreateFolderResponse.toObject(message.createFolderResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createFolderResponse";
            }
            if (message.createProjectResponse != null && message.hasOwnProperty("createProjectResponse")) {
                object.createProjectResponse = $root.api.CreateProjectResponse.toObject(message.createProjectResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createProjectResponse";
            }
            if (message.getProjectResponse != null && message.hasOwnProperty("getProjectResponse")) {
                object.getProjectResponse = $root.api.GetProjectResponse.toObject(message.getProjectResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getProjectResponse";
            }
            if (message.updateProjectResponse != null && message.hasOwnProperty("updateProjectResponse")) {
                object.updateProjectResponse = $root.api.UpdateProjectResponse.toObject(message.updateProjectResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateProjectResponse";
            }
            if (message.updateFileResponse != null && message.hasOwnProperty("updateFileResponse")) {
                object.updateFileResponse = $root.api.UpdateFileResponse.toObject(message.updateFileResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateFileResponse";
            }
            if (message.createComprehendResponse != null && message.hasOwnProperty("createComprehendResponse")) {
                object.createComprehendResponse = $root.api.CreateComprehendResponse.toObject(message.createComprehendResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createComprehendResponse";
            }
            if (message.getFileUrlResponse != null && message.hasOwnProperty("getFileUrlResponse")) {
                object.getFileUrlResponse = $root.api.GetFileUrlResponse.toObject(message.getFileUrlResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getFileUrlResponse";
            }
            if (message.checkComprehendTaskStatusResponse != null && message.hasOwnProperty("checkComprehendTaskStatusResponse")) {
                object.checkComprehendTaskStatusResponse = $root.api.CheckComprehendTaskStatusResponse.toObject(message.checkComprehendTaskStatusResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "checkComprehendTaskStatusResponse";
            }
            if (message.getGeneratedImagesResponse != null && message.hasOwnProperty("getGeneratedImagesResponse")) {
                object.getGeneratedImagesResponse = $root.api.GetGeneratedImagesResponse.toObject(message.getGeneratedImagesResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getGeneratedImagesResponse";
            }
            if (message.updateGeneratedImageResponse != null && message.hasOwnProperty("updateGeneratedImageResponse")) {
                object.updateGeneratedImageResponse = $root.api.UpdateGeneratedImageResponse.toObject(message.updateGeneratedImageResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateGeneratedImageResponse";
            }
            if (message.executeAdminTaskResponse != null && message.hasOwnProperty("executeAdminTaskResponse")) {
                object.executeAdminTaskResponse = $root.api.ExecuteAdminTaskResponse.toObject(message.executeAdminTaskResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "executeAdminTaskResponse";
            }
            if (message.getSegmentResponse != null && message.hasOwnProperty("getSegmentResponse")) {
                object.getSegmentResponse = $root.api.GetSegmentResponse.toObject(message.getSegmentResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getSegmentResponse";
            }
            if (message.getCaptionResponse != null && message.hasOwnProperty("getCaptionResponse")) {
                object.getCaptionResponse = $root.api.GetCaptionResponse.toObject(message.getCaptionResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getCaptionResponse";
            }
            if (message.getMaskResponse != null && message.hasOwnProperty("getMaskResponse")) {
                object.getMaskResponse = $root.api.GetMaskResponse.toObject(message.getMaskResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getMaskResponse";
            }
            if (message.deleteGeneratedImage != null && message.hasOwnProperty("deleteGeneratedImage")) {
                object.deleteGeneratedImage = $root.api.DeleteGeneratedImageResponse.toObject(message.deleteGeneratedImage, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteGeneratedImage";
            }
            if (message.addGeneratedImageCommentResponse != null && message.hasOwnProperty("addGeneratedImageCommentResponse")) {
                object.addGeneratedImageCommentResponse = $root.api.AddGeneratedImageCommentResponse.toObject(message.addGeneratedImageCommentResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addGeneratedImageCommentResponse";
            }
            if (message.deleteGeneratedImageCommentResponse != null && message.hasOwnProperty("deleteGeneratedImageCommentResponse")) {
                object.deleteGeneratedImageCommentResponse = $root.api.DeleteGeneratedImageCommentResponse.toObject(message.deleteGeneratedImageCommentResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteGeneratedImageCommentResponse";
            }
            if (message.updateGeneratedImageCommentResponse != null && message.hasOwnProperty("updateGeneratedImageCommentResponse")) {
                object.updateGeneratedImageCommentResponse = $root.api.UpdateGeneratedImageCommentResponse.toObject(message.updateGeneratedImageCommentResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateGeneratedImageCommentResponse";
            }
            if (message.addReceiptResponse != null && message.hasOwnProperty("addReceiptResponse")) {
                object.addReceiptResponse = $root.api.AddReceiptResponse.toObject(message.addReceiptResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addReceiptResponse";
            }
            if (message.getAvailableProductsResponse != null && message.hasOwnProperty("getAvailableProductsResponse")) {
                object.getAvailableProductsResponse = $root.api.GetAvailableProductsResponse.toObject(message.getAvailableProductsResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getAvailableProductsResponse";
            }
            if (message.deleteUserResponse != null && message.hasOwnProperty("deleteUserResponse")) {
                object.deleteUserResponse = $root.api.DeleteUserResponse.toObject(message.deleteUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteUserResponse";
            }
            if (message.jobCreatedResponse != null && message.hasOwnProperty("jobCreatedResponse")) {
                object.jobCreatedResponse = $root.api.JobCreatedResponse.toObject(message.jobCreatedResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "jobCreatedResponse";
            }
            if (message.jobStatusResponse != null && message.hasOwnProperty("jobStatusResponse")) {
                object.jobStatusResponse = $root.api.JobStatusResponse.toObject(message.jobStatusResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "jobStatusResponse";
            }
            if (message.getSiteEmbeddingResponse != null && message.hasOwnProperty("getSiteEmbeddingResponse")) {
                object.getSiteEmbeddingResponse = $root.api.GetSiteEmbeddingResponse.toObject(message.getSiteEmbeddingResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getSiteEmbeddingResponse";
            }
            if (message.sendFeedbackResponse != null && message.hasOwnProperty("sendFeedbackResponse")) {
                object.sendFeedbackResponse = $root.api.SendFeedbackResponse.toObject(message.sendFeedbackResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "sendFeedbackResponse";
            }
            if (message.getJobsResponse != null && message.hasOwnProperty("getJobsResponse")) {
                object.getJobsResponse = $root.api.GetJobsResponse.toObject(message.getJobsResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getJobsResponse";
            }
            if (message.getConversationsResponse != null && message.hasOwnProperty("getConversationsResponse")) {
                object.getConversationsResponse = $root.api.GetConversationsResponse.toObject(message.getConversationsResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getConversationsResponse";
            }
            if (message.getMessagesResponse != null && message.hasOwnProperty("getMessagesResponse")) {
                object.getMessagesResponse = $root.api.GetMessagesResponse.toObject(message.getMessagesResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getMessagesResponse";
            }
            if (message.updateMessageResponse != null && message.hasOwnProperty("updateMessageResponse")) {
                object.updateMessageResponse = $root.api.UpdateMessageResponse.toObject(message.updateMessageResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateMessageResponse";
            }
            if (message.jobUpdateResponse != null && message.hasOwnProperty("jobUpdateResponse")) {
                object.jobUpdateResponse = $root.api.JobUpdateResponse.toObject(message.jobUpdateResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "jobUpdateResponse";
            }
            return object;
        };

        /**
         * Converts this APIResponse to JSON.
         * @function toJSON
         * @memberof api.APIResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIResponse;
    })();

    api.APIResponses = (function() {

        /**
         * Properties of a APIResponses.
         * @memberof api
         * @interface IAPIResponses
         * @property {Array.<api.IAPIResponse>|null} [responses] APIResponses responses
         * @property {string|null} [error] APIResponses error
         * @property {string|null} [errorCode] APIResponses errorCode
         */

        /**
         * Constructs a new APIResponses.
         * @memberof api
         * @classdesc Represents a APIResponses.
         * @implements IAPIResponses
         * @constructor
         * @param {api.IAPIResponses=} [properties] Properties to set
         */
        function APIResponses(properties) {
            this.responses = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIResponses responses.
         * @member {Array.<api.IAPIResponse>} responses
         * @memberof api.APIResponses
         * @instance
         */
        APIResponses.prototype.responses = $util.emptyArray;

        /**
         * APIResponses error.
         * @member {string} error
         * @memberof api.APIResponses
         * @instance
         */
        APIResponses.prototype.error = "";

        /**
         * APIResponses errorCode.
         * @member {string} errorCode
         * @memberof api.APIResponses
         * @instance
         */
        APIResponses.prototype.errorCode = "";

        /**
         * Creates a new APIResponses instance using the specified properties.
         * @function create
         * @memberof api.APIResponses
         * @static
         * @param {api.IAPIResponses=} [properties] Properties to set
         * @returns {api.APIResponses} APIResponses instance
         */
        APIResponses.create = function create(properties) {
            return new APIResponses(properties);
        };

        /**
         * Encodes the specified APIResponses message. Does not implicitly {@link api.APIResponses.verify|verify} messages.
         * @function encode
         * @memberof api.APIResponses
         * @static
         * @param {api.IAPIResponses} message APIResponses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIResponses.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.responses != null && message.responses.length)
                for (let i = 0; i < message.responses.length; ++i)
                    $root.api.APIResponse.encode(message.responses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified APIResponses message, length delimited. Does not implicitly {@link api.APIResponses.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIResponses
         * @static
         * @param {api.IAPIResponses} message APIResponses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIResponses.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIResponses message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIResponses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIResponses} APIResponses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIResponses.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIResponses();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.responses && message.responses.length))
                        message.responses = [];
                    message.responses.push($root.api.APIResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.error = reader.string();
                    break;
                case 3:
                    message.errorCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIResponses message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIResponses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIResponses} APIResponses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIResponses.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIResponses message.
         * @function verify
         * @memberof api.APIResponses
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIResponses.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.responses != null && message.hasOwnProperty("responses")) {
                if (!Array.isArray(message.responses))
                    return "responses: array expected";
                for (let i = 0; i < message.responses.length; ++i) {
                    let error = $root.api.APIResponse.verify(message.responses[i]);
                    if (error)
                        return "responses." + error;
                }
            }
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            return null;
        };

        /**
         * Creates a APIResponses message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIResponses
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIResponses} APIResponses
         */
        APIResponses.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIResponses)
                return object;
            let message = new $root.api.APIResponses();
            if (object.responses) {
                if (!Array.isArray(object.responses))
                    throw TypeError(".api.APIResponses.responses: array expected");
                message.responses = [];
                for (let i = 0; i < object.responses.length; ++i) {
                    if (typeof object.responses[i] !== "object")
                        throw TypeError(".api.APIResponses.responses: object expected");
                    message.responses[i] = $root.api.APIResponse.fromObject(object.responses[i]);
                }
            }
            if (object.error != null)
                message.error = String(object.error);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            return message;
        };

        /**
         * Creates a plain object from a APIResponses message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIResponses
         * @static
         * @param {api.APIResponses} message APIResponses
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIResponses.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.responses = [];
            if (options.defaults) {
                object.error = "";
                object.errorCode = "";
            }
            if (message.responses && message.responses.length) {
                object.responses = [];
                for (let j = 0; j < message.responses.length; ++j)
                    object.responses[j] = $root.api.APIResponse.toObject(message.responses[j], options);
            }
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this APIResponses to JSON.
         * @function toJSON
         * @memberof api.APIResponses
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIResponses.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIResponses;
    })();

    api.APIRequests = (function() {

        /**
         * Properties of a APIRequests.
         * @memberof api
         * @interface IAPIRequests
         * @property {boolean|null} [singleTransaction] APIRequests singleTransaction
         * @property {Array.<api.IAPIRequest>|null} [requests] APIRequests requests
         */

        /**
         * Constructs a new APIRequests.
         * @memberof api
         * @classdesc Represents a APIRequests.
         * @implements IAPIRequests
         * @constructor
         * @param {api.IAPIRequests=} [properties] Properties to set
         */
        function APIRequests(properties) {
            this.requests = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIRequests singleTransaction.
         * @member {boolean} singleTransaction
         * @memberof api.APIRequests
         * @instance
         */
        APIRequests.prototype.singleTransaction = false;

        /**
         * APIRequests requests.
         * @member {Array.<api.IAPIRequest>} requests
         * @memberof api.APIRequests
         * @instance
         */
        APIRequests.prototype.requests = $util.emptyArray;

        /**
         * Creates a new APIRequests instance using the specified properties.
         * @function create
         * @memberof api.APIRequests
         * @static
         * @param {api.IAPIRequests=} [properties] Properties to set
         * @returns {api.APIRequests} APIRequests instance
         */
        APIRequests.create = function create(properties) {
            return new APIRequests(properties);
        };

        /**
         * Encodes the specified APIRequests message. Does not implicitly {@link api.APIRequests.verify|verify} messages.
         * @function encode
         * @memberof api.APIRequests
         * @static
         * @param {api.IAPIRequests} message APIRequests message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIRequests.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requests != null && message.requests.length)
                for (let i = 0; i < message.requests.length; ++i)
                    $root.api.APIRequest.encode(message.requests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.singleTransaction != null && Object.hasOwnProperty.call(message, "singleTransaction"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.singleTransaction);
            return writer;
        };

        /**
         * Encodes the specified APIRequests message, length delimited. Does not implicitly {@link api.APIRequests.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIRequests
         * @static
         * @param {api.IAPIRequests} message APIRequests message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIRequests.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIRequests message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIRequests
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIRequests} APIRequests
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIRequests.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIRequests();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.singleTransaction = reader.bool();
                    break;
                case 1:
                    if (!(message.requests && message.requests.length))
                        message.requests = [];
                    message.requests.push($root.api.APIRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIRequests message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIRequests
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIRequests} APIRequests
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIRequests.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIRequests message.
         * @function verify
         * @memberof api.APIRequests
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIRequests.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.singleTransaction != null && message.hasOwnProperty("singleTransaction"))
                if (typeof message.singleTransaction !== "boolean")
                    return "singleTransaction: boolean expected";
            if (message.requests != null && message.hasOwnProperty("requests")) {
                if (!Array.isArray(message.requests))
                    return "requests: array expected";
                for (let i = 0; i < message.requests.length; ++i) {
                    let error = $root.api.APIRequest.verify(message.requests[i]);
                    if (error)
                        return "requests." + error;
                }
            }
            return null;
        };

        /**
         * Creates a APIRequests message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIRequests
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIRequests} APIRequests
         */
        APIRequests.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIRequests)
                return object;
            let message = new $root.api.APIRequests();
            if (object.singleTransaction != null)
                message.singleTransaction = Boolean(object.singleTransaction);
            if (object.requests) {
                if (!Array.isArray(object.requests))
                    throw TypeError(".api.APIRequests.requests: array expected");
                message.requests = [];
                for (let i = 0; i < object.requests.length; ++i) {
                    if (typeof object.requests[i] !== "object")
                        throw TypeError(".api.APIRequests.requests: object expected");
                    message.requests[i] = $root.api.APIRequest.fromObject(object.requests[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a APIRequests message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIRequests
         * @static
         * @param {api.APIRequests} message APIRequests
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIRequests.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.requests = [];
            if (options.defaults)
                object.singleTransaction = false;
            if (message.requests && message.requests.length) {
                object.requests = [];
                for (let j = 0; j < message.requests.length; ++j)
                    object.requests[j] = $root.api.APIRequest.toObject(message.requests[j], options);
            }
            if (message.singleTransaction != null && message.hasOwnProperty("singleTransaction"))
                object.singleTransaction = message.singleTransaction;
            return object;
        };

        /**
         * Converts this APIRequests to JSON.
         * @function toJSON
         * @memberof api.APIRequests
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIRequests.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIRequests;
    })();

    api.APIMessage = (function() {

        /**
         * Properties of a APIMessage.
         * @memberof api
         * @interface IAPIMessage
         * @property {api.IAPIResponses|null} [apiResponses] APIMessage apiResponses
         * @property {api.IAPIRequests|null} [apiRequests] APIMessage apiRequests
         */

        /**
         * Constructs a new APIMessage.
         * @memberof api
         * @classdesc Represents a APIMessage.
         * @implements IAPIMessage
         * @constructor
         * @param {api.IAPIMessage=} [properties] Properties to set
         */
        function APIMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIMessage apiResponses.
         * @member {api.IAPIResponses|null|undefined} apiResponses
         * @memberof api.APIMessage
         * @instance
         */
        APIMessage.prototype.apiResponses = null;

        /**
         * APIMessage apiRequests.
         * @member {api.IAPIRequests|null|undefined} apiRequests
         * @memberof api.APIMessage
         * @instance
         */
        APIMessage.prototype.apiRequests = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * APIMessage messageInfo.
         * @member {"apiResponses"|"apiRequests"|undefined} messageInfo
         * @memberof api.APIMessage
         * @instance
         */
        Object.defineProperty(APIMessage.prototype, "messageInfo", {
            get: $util.oneOfGetter($oneOfFields = ["apiResponses", "apiRequests"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new APIMessage instance using the specified properties.
         * @function create
         * @memberof api.APIMessage
         * @static
         * @param {api.IAPIMessage=} [properties] Properties to set
         * @returns {api.APIMessage} APIMessage instance
         */
        APIMessage.create = function create(properties) {
            return new APIMessage(properties);
        };

        /**
         * Encodes the specified APIMessage message. Does not implicitly {@link api.APIMessage.verify|verify} messages.
         * @function encode
         * @memberof api.APIMessage
         * @static
         * @param {api.IAPIMessage} message APIMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.apiResponses != null && Object.hasOwnProperty.call(message, "apiResponses"))
                $root.api.APIResponses.encode(message.apiResponses, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.apiRequests != null && Object.hasOwnProperty.call(message, "apiRequests"))
                $root.api.APIRequests.encode(message.apiRequests, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified APIMessage message, length delimited. Does not implicitly {@link api.APIMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIMessage
         * @static
         * @param {api.IAPIMessage} message APIMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIMessage message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIMessage} APIMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.apiResponses = $root.api.APIResponses.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.apiRequests = $root.api.APIRequests.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIMessage} APIMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIMessage message.
         * @function verify
         * @memberof api.APIMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.apiResponses != null && message.hasOwnProperty("apiResponses")) {
                properties.messageInfo = 1;
                {
                    let error = $root.api.APIResponses.verify(message.apiResponses);
                    if (error)
                        return "apiResponses." + error;
                }
            }
            if (message.apiRequests != null && message.hasOwnProperty("apiRequests")) {
                if (properties.messageInfo === 1)
                    return "messageInfo: multiple values";
                properties.messageInfo = 1;
                {
                    let error = $root.api.APIRequests.verify(message.apiRequests);
                    if (error)
                        return "apiRequests." + error;
                }
            }
            return null;
        };

        /**
         * Creates a APIMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIMessage} APIMessage
         */
        APIMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIMessage)
                return object;
            let message = new $root.api.APIMessage();
            if (object.apiResponses != null) {
                if (typeof object.apiResponses !== "object")
                    throw TypeError(".api.APIMessage.apiResponses: object expected");
                message.apiResponses = $root.api.APIResponses.fromObject(object.apiResponses);
            }
            if (object.apiRequests != null) {
                if (typeof object.apiRequests !== "object")
                    throw TypeError(".api.APIMessage.apiRequests: object expected");
                message.apiRequests = $root.api.APIRequests.fromObject(object.apiRequests);
            }
            return message;
        };

        /**
         * Creates a plain object from a APIMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIMessage
         * @static
         * @param {api.APIMessage} message APIMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.apiResponses != null && message.hasOwnProperty("apiResponses")) {
                object.apiResponses = $root.api.APIResponses.toObject(message.apiResponses, options);
                if (options.oneofs)
                    object.messageInfo = "apiResponses";
            }
            if (message.apiRequests != null && message.hasOwnProperty("apiRequests")) {
                object.apiRequests = $root.api.APIRequests.toObject(message.apiRequests, options);
                if (options.oneofs)
                    object.messageInfo = "apiRequests";
            }
            return object;
        };

        /**
         * Converts this APIMessage to JSON.
         * @function toJSON
         * @memberof api.APIMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIMessage;
    })();

    api.DocumentEmbedding = (function() {

        /**
         * Properties of a DocumentEmbedding.
         * @memberof api
         * @interface IDocumentEmbedding
         * @property {number|null} [pageNumber] DocumentEmbedding pageNumber
         * @property {string|null} [content] DocumentEmbedding content
         * @property {Array.<number>|null} [embeddingVector] DocumentEmbedding embeddingVector
         */

        /**
         * Constructs a new DocumentEmbedding.
         * @memberof api
         * @classdesc Represents a DocumentEmbedding.
         * @implements IDocumentEmbedding
         * @constructor
         * @param {api.IDocumentEmbedding=} [properties] Properties to set
         */
        function DocumentEmbedding(properties) {
            this.embeddingVector = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocumentEmbedding pageNumber.
         * @member {number} pageNumber
         * @memberof api.DocumentEmbedding
         * @instance
         */
        DocumentEmbedding.prototype.pageNumber = 0;

        /**
         * DocumentEmbedding content.
         * @member {string} content
         * @memberof api.DocumentEmbedding
         * @instance
         */
        DocumentEmbedding.prototype.content = "";

        /**
         * DocumentEmbedding embeddingVector.
         * @member {Array.<number>} embeddingVector
         * @memberof api.DocumentEmbedding
         * @instance
         */
        DocumentEmbedding.prototype.embeddingVector = $util.emptyArray;

        /**
         * Creates a new DocumentEmbedding instance using the specified properties.
         * @function create
         * @memberof api.DocumentEmbedding
         * @static
         * @param {api.IDocumentEmbedding=} [properties] Properties to set
         * @returns {api.DocumentEmbedding} DocumentEmbedding instance
         */
        DocumentEmbedding.create = function create(properties) {
            return new DocumentEmbedding(properties);
        };

        /**
         * Encodes the specified DocumentEmbedding message. Does not implicitly {@link api.DocumentEmbedding.verify|verify} messages.
         * @function encode
         * @memberof api.DocumentEmbedding
         * @static
         * @param {api.IDocumentEmbedding} message DocumentEmbedding message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DocumentEmbedding.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pageNumber != null && Object.hasOwnProperty.call(message, "pageNumber"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageNumber);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
            if (message.embeddingVector != null && message.embeddingVector.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.embeddingVector.length; ++i)
                    writer.float(message.embeddingVector[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified DocumentEmbedding message, length delimited. Does not implicitly {@link api.DocumentEmbedding.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DocumentEmbedding
         * @static
         * @param {api.IDocumentEmbedding} message DocumentEmbedding message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DocumentEmbedding.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DocumentEmbedding message from the specified reader or buffer.
         * @function decode
         * @memberof api.DocumentEmbedding
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DocumentEmbedding} DocumentEmbedding
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DocumentEmbedding.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DocumentEmbedding();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.pageNumber = reader.int32();
                    break;
                case 2:
                    message.content = reader.string();
                    break;
                case 3:
                    if (!(message.embeddingVector && message.embeddingVector.length))
                        message.embeddingVector = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.embeddingVector.push(reader.float());
                    } else
                        message.embeddingVector.push(reader.float());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DocumentEmbedding message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DocumentEmbedding
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DocumentEmbedding} DocumentEmbedding
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DocumentEmbedding.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DocumentEmbedding message.
         * @function verify
         * @memberof api.DocumentEmbedding
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DocumentEmbedding.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pageNumber != null && message.hasOwnProperty("pageNumber"))
                if (!$util.isInteger(message.pageNumber))
                    return "pageNumber: integer expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.embeddingVector != null && message.hasOwnProperty("embeddingVector")) {
                if (!Array.isArray(message.embeddingVector))
                    return "embeddingVector: array expected";
                for (let i = 0; i < message.embeddingVector.length; ++i)
                    if (typeof message.embeddingVector[i] !== "number")
                        return "embeddingVector: number[] expected";
            }
            return null;
        };

        /**
         * Creates a DocumentEmbedding message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DocumentEmbedding
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DocumentEmbedding} DocumentEmbedding
         */
        DocumentEmbedding.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DocumentEmbedding)
                return object;
            let message = new $root.api.DocumentEmbedding();
            if (object.pageNumber != null)
                message.pageNumber = object.pageNumber | 0;
            if (object.content != null)
                message.content = String(object.content);
            if (object.embeddingVector) {
                if (!Array.isArray(object.embeddingVector))
                    throw TypeError(".api.DocumentEmbedding.embeddingVector: array expected");
                message.embeddingVector = [];
                for (let i = 0; i < object.embeddingVector.length; ++i)
                    message.embeddingVector[i] = Number(object.embeddingVector[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a DocumentEmbedding message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DocumentEmbedding
         * @static
         * @param {api.DocumentEmbedding} message DocumentEmbedding
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DocumentEmbedding.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.embeddingVector = [];
            if (options.defaults) {
                object.pageNumber = 0;
                object.content = "";
            }
            if (message.pageNumber != null && message.hasOwnProperty("pageNumber"))
                object.pageNumber = message.pageNumber;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.embeddingVector && message.embeddingVector.length) {
                object.embeddingVector = [];
                for (let j = 0; j < message.embeddingVector.length; ++j)
                    object.embeddingVector[j] = options.json && !isFinite(message.embeddingVector[j]) ? String(message.embeddingVector[j]) : message.embeddingVector[j];
            }
            return object;
        };

        /**
         * Converts this DocumentEmbedding to JSON.
         * @function toJSON
         * @memberof api.DocumentEmbedding
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DocumentEmbedding.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DocumentEmbedding;
    })();

    api.DocumentMapping = (function() {

        /**
         * Properties of a DocumentMapping.
         * @memberof api
         * @interface IDocumentMapping
         * @property {string|null} [documentId] DocumentMapping documentId
         * @property {Array.<api.IDocumentEmbedding>|null} [embeddings] DocumentMapping embeddings
         */

        /**
         * Constructs a new DocumentMapping.
         * @memberof api
         * @classdesc Represents a DocumentMapping.
         * @implements IDocumentMapping
         * @constructor
         * @param {api.IDocumentMapping=} [properties] Properties to set
         */
        function DocumentMapping(properties) {
            this.embeddings = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocumentMapping documentId.
         * @member {string} documentId
         * @memberof api.DocumentMapping
         * @instance
         */
        DocumentMapping.prototype.documentId = "";

        /**
         * DocumentMapping embeddings.
         * @member {Array.<api.IDocumentEmbedding>} embeddings
         * @memberof api.DocumentMapping
         * @instance
         */
        DocumentMapping.prototype.embeddings = $util.emptyArray;

        /**
         * Creates a new DocumentMapping instance using the specified properties.
         * @function create
         * @memberof api.DocumentMapping
         * @static
         * @param {api.IDocumentMapping=} [properties] Properties to set
         * @returns {api.DocumentMapping} DocumentMapping instance
         */
        DocumentMapping.create = function create(properties) {
            return new DocumentMapping(properties);
        };

        /**
         * Encodes the specified DocumentMapping message. Does not implicitly {@link api.DocumentMapping.verify|verify} messages.
         * @function encode
         * @memberof api.DocumentMapping
         * @static
         * @param {api.IDocumentMapping} message DocumentMapping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DocumentMapping.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.documentId != null && Object.hasOwnProperty.call(message, "documentId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.documentId);
            if (message.embeddings != null && message.embeddings.length)
                for (let i = 0; i < message.embeddings.length; ++i)
                    $root.api.DocumentEmbedding.encode(message.embeddings[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DocumentMapping message, length delimited. Does not implicitly {@link api.DocumentMapping.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DocumentMapping
         * @static
         * @param {api.IDocumentMapping} message DocumentMapping message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DocumentMapping.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DocumentMapping message from the specified reader or buffer.
         * @function decode
         * @memberof api.DocumentMapping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DocumentMapping} DocumentMapping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DocumentMapping.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DocumentMapping();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.documentId = reader.string();
                    break;
                case 2:
                    if (!(message.embeddings && message.embeddings.length))
                        message.embeddings = [];
                    message.embeddings.push($root.api.DocumentEmbedding.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DocumentMapping message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DocumentMapping
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DocumentMapping} DocumentMapping
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DocumentMapping.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DocumentMapping message.
         * @function verify
         * @memberof api.DocumentMapping
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DocumentMapping.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.documentId != null && message.hasOwnProperty("documentId"))
                if (!$util.isString(message.documentId))
                    return "documentId: string expected";
            if (message.embeddings != null && message.hasOwnProperty("embeddings")) {
                if (!Array.isArray(message.embeddings))
                    return "embeddings: array expected";
                for (let i = 0; i < message.embeddings.length; ++i) {
                    let error = $root.api.DocumentEmbedding.verify(message.embeddings[i]);
                    if (error)
                        return "embeddings." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DocumentMapping message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DocumentMapping
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DocumentMapping} DocumentMapping
         */
        DocumentMapping.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DocumentMapping)
                return object;
            let message = new $root.api.DocumentMapping();
            if (object.documentId != null)
                message.documentId = String(object.documentId);
            if (object.embeddings) {
                if (!Array.isArray(object.embeddings))
                    throw TypeError(".api.DocumentMapping.embeddings: array expected");
                message.embeddings = [];
                for (let i = 0; i < object.embeddings.length; ++i) {
                    if (typeof object.embeddings[i] !== "object")
                        throw TypeError(".api.DocumentMapping.embeddings: object expected");
                    message.embeddings[i] = $root.api.DocumentEmbedding.fromObject(object.embeddings[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a DocumentMapping message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DocumentMapping
         * @static
         * @param {api.DocumentMapping} message DocumentMapping
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DocumentMapping.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.embeddings = [];
            if (options.defaults)
                object.documentId = "";
            if (message.documentId != null && message.hasOwnProperty("documentId"))
                object.documentId = message.documentId;
            if (message.embeddings && message.embeddings.length) {
                object.embeddings = [];
                for (let j = 0; j < message.embeddings.length; ++j)
                    object.embeddings[j] = $root.api.DocumentEmbedding.toObject(message.embeddings[j], options);
            }
            return object;
        };

        /**
         * Converts this DocumentMapping to JSON.
         * @function toJSON
         * @memberof api.DocumentMapping
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DocumentMapping.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DocumentMapping;
    })();

    /**
     * SettableBool enum.
     * @name api.SettableBool
     * @enum {number}
     * @property {number} NotSet=0 NotSet value
     * @property {number} True=1 True value
     * @property {number} False=2 False value
     */
    api.SettableBool = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NotSet"] = 0;
        values[valuesById[1] = "True"] = 1;
        values[valuesById[2] = "False"] = 2;
        return values;
    })();

    /**
     * TextLayerAlign enum.
     * @name api.TextLayerAlign
     * @enum {number}
     * @property {number} NonSet=0 NonSet value
     * @property {number} Left=1 Left value
     * @property {number} Center=2 Center value
     * @property {number} Right=3 Right value
     * @property {number} Justify=4 Justify value
     */
    api.TextLayerAlign = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NonSet"] = 0;
        values[valuesById[1] = "Left"] = 1;
        values[valuesById[2] = "Center"] = 2;
        values[valuesById[3] = "Right"] = 3;
        values[valuesById[4] = "Justify"] = 4;
        return values;
    })();

    api.TextLayer = (function() {

        /**
         * Properties of a TextLayer.
         * @memberof api
         * @interface ITextLayer
         * @property {number|Long|null} [fontWeight] TextLayer fontWeight
         * @property {string|null} [fontVariant] TextLayer fontVariant
         * @property {number|null} [fontSize] TextLayer fontSize
         * @property {string|null} [fontHeight] TextLayer fontHeight
         * @property {number|Long|null} [fontColor] TextLayer fontColor
         * @property {string|null} [fontFamily] TextLayer fontFamily
         * @property {string|null} [text] TextLayer text
         * @property {Array.<api.ITextLayer>|null} [children] TextLayer children
         */

        /**
         * Constructs a new TextLayer.
         * @memberof api
         * @classdesc Represents a TextLayer.
         * @implements ITextLayer
         * @constructor
         * @param {api.ITextLayer=} [properties] Properties to set
         */
        function TextLayer(properties) {
            this.children = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TextLayer fontWeight.
         * @member {number|Long} fontWeight
         * @memberof api.TextLayer
         * @instance
         */
        TextLayer.prototype.fontWeight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TextLayer fontVariant.
         * @member {string} fontVariant
         * @memberof api.TextLayer
         * @instance
         */
        TextLayer.prototype.fontVariant = "";

        /**
         * TextLayer fontSize.
         * @member {number} fontSize
         * @memberof api.TextLayer
         * @instance
         */
        TextLayer.prototype.fontSize = 0;

        /**
         * TextLayer fontHeight.
         * @member {string} fontHeight
         * @memberof api.TextLayer
         * @instance
         */
        TextLayer.prototype.fontHeight = "";

        /**
         * TextLayer fontColor.
         * @member {number|Long} fontColor
         * @memberof api.TextLayer
         * @instance
         */
        TextLayer.prototype.fontColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TextLayer fontFamily.
         * @member {string} fontFamily
         * @memberof api.TextLayer
         * @instance
         */
        TextLayer.prototype.fontFamily = "";

        /**
         * TextLayer text.
         * @member {string} text
         * @memberof api.TextLayer
         * @instance
         */
        TextLayer.prototype.text = "";

        /**
         * TextLayer children.
         * @member {Array.<api.ITextLayer>} children
         * @memberof api.TextLayer
         * @instance
         */
        TextLayer.prototype.children = $util.emptyArray;

        /**
         * Creates a new TextLayer instance using the specified properties.
         * @function create
         * @memberof api.TextLayer
         * @static
         * @param {api.ITextLayer=} [properties] Properties to set
         * @returns {api.TextLayer} TextLayer instance
         */
        TextLayer.create = function create(properties) {
            return new TextLayer(properties);
        };

        /**
         * Encodes the specified TextLayer message. Does not implicitly {@link api.TextLayer.verify|verify} messages.
         * @function encode
         * @memberof api.TextLayer
         * @static
         * @param {api.ITextLayer} message TextLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextLayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fontWeight != null && Object.hasOwnProperty.call(message, "fontWeight"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fontWeight);
            if (message.fontVariant != null && Object.hasOwnProperty.call(message, "fontVariant"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fontVariant);
            if (message.fontSize != null && Object.hasOwnProperty.call(message, "fontSize"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.fontSize);
            if (message.fontColor != null && Object.hasOwnProperty.call(message, "fontColor"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.fontColor);
            if (message.fontFamily != null && Object.hasOwnProperty.call(message, "fontFamily"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.fontFamily);
            if (message.fontHeight != null && Object.hasOwnProperty.call(message, "fontHeight"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.fontHeight);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.text);
            if (message.children != null && message.children.length)
                for (let i = 0; i < message.children.length; ++i)
                    $root.api.TextLayer.encode(message.children[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TextLayer message, length delimited. Does not implicitly {@link api.TextLayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TextLayer
         * @static
         * @param {api.ITextLayer} message TextLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextLayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TextLayer message from the specified reader or buffer.
         * @function decode
         * @memberof api.TextLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TextLayer} TextLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextLayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TextLayer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fontWeight = reader.int64();
                    break;
                case 2:
                    message.fontVariant = reader.string();
                    break;
                case 3:
                    message.fontSize = reader.double();
                    break;
                case 6:
                    message.fontHeight = reader.string();
                    break;
                case 4:
                    message.fontColor = reader.int64();
                    break;
                case 5:
                    message.fontFamily = reader.string();
                    break;
                case 7:
                    message.text = reader.string();
                    break;
                case 8:
                    if (!(message.children && message.children.length))
                        message.children = [];
                    message.children.push($root.api.TextLayer.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TextLayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TextLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TextLayer} TextLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextLayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TextLayer message.
         * @function verify
         * @memberof api.TextLayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TextLayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fontWeight != null && message.hasOwnProperty("fontWeight"))
                if (!$util.isInteger(message.fontWeight) && !(message.fontWeight && $util.isInteger(message.fontWeight.low) && $util.isInteger(message.fontWeight.high)))
                    return "fontWeight: integer|Long expected";
            if (message.fontVariant != null && message.hasOwnProperty("fontVariant"))
                if (!$util.isString(message.fontVariant))
                    return "fontVariant: string expected";
            if (message.fontSize != null && message.hasOwnProperty("fontSize"))
                if (typeof message.fontSize !== "number")
                    return "fontSize: number expected";
            if (message.fontHeight != null && message.hasOwnProperty("fontHeight"))
                if (!$util.isString(message.fontHeight))
                    return "fontHeight: string expected";
            if (message.fontColor != null && message.hasOwnProperty("fontColor"))
                if (!$util.isInteger(message.fontColor) && !(message.fontColor && $util.isInteger(message.fontColor.low) && $util.isInteger(message.fontColor.high)))
                    return "fontColor: integer|Long expected";
            if (message.fontFamily != null && message.hasOwnProperty("fontFamily"))
                if (!$util.isString(message.fontFamily))
                    return "fontFamily: string expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.children != null && message.hasOwnProperty("children")) {
                if (!Array.isArray(message.children))
                    return "children: array expected";
                for (let i = 0; i < message.children.length; ++i) {
                    let error = $root.api.TextLayer.verify(message.children[i]);
                    if (error)
                        return "children." + error;
                }
            }
            return null;
        };

        /**
         * Creates a TextLayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TextLayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TextLayer} TextLayer
         */
        TextLayer.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TextLayer)
                return object;
            let message = new $root.api.TextLayer();
            if (object.fontWeight != null)
                if ($util.Long)
                    (message.fontWeight = $util.Long.fromValue(object.fontWeight)).unsigned = false;
                else if (typeof object.fontWeight === "string")
                    message.fontWeight = parseInt(object.fontWeight, 10);
                else if (typeof object.fontWeight === "number")
                    message.fontWeight = object.fontWeight;
                else if (typeof object.fontWeight === "object")
                    message.fontWeight = new $util.LongBits(object.fontWeight.low >>> 0, object.fontWeight.high >>> 0).toNumber();
            if (object.fontVariant != null)
                message.fontVariant = String(object.fontVariant);
            if (object.fontSize != null)
                message.fontSize = Number(object.fontSize);
            if (object.fontHeight != null)
                message.fontHeight = String(object.fontHeight);
            if (object.fontColor != null)
                if ($util.Long)
                    (message.fontColor = $util.Long.fromValue(object.fontColor)).unsigned = false;
                else if (typeof object.fontColor === "string")
                    message.fontColor = parseInt(object.fontColor, 10);
                else if (typeof object.fontColor === "number")
                    message.fontColor = object.fontColor;
                else if (typeof object.fontColor === "object")
                    message.fontColor = new $util.LongBits(object.fontColor.low >>> 0, object.fontColor.high >>> 0).toNumber();
            if (object.fontFamily != null)
                message.fontFamily = String(object.fontFamily);
            if (object.text != null)
                message.text = String(object.text);
            if (object.children) {
                if (!Array.isArray(object.children))
                    throw TypeError(".api.TextLayer.children: array expected");
                message.children = [];
                for (let i = 0; i < object.children.length; ++i) {
                    if (typeof object.children[i] !== "object")
                        throw TypeError(".api.TextLayer.children: object expected");
                    message.children[i] = $root.api.TextLayer.fromObject(object.children[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TextLayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TextLayer
         * @static
         * @param {api.TextLayer} message TextLayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TextLayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.children = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.fontWeight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fontWeight = options.longs === String ? "0" : 0;
                object.fontVariant = "";
                object.fontSize = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.fontColor = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fontColor = options.longs === String ? "0" : 0;
                object.fontFamily = "";
                object.fontHeight = "";
                object.text = "";
            }
            if (message.fontWeight != null && message.hasOwnProperty("fontWeight"))
                if (typeof message.fontWeight === "number")
                    object.fontWeight = options.longs === String ? String(message.fontWeight) : message.fontWeight;
                else
                    object.fontWeight = options.longs === String ? $util.Long.prototype.toString.call(message.fontWeight) : options.longs === Number ? new $util.LongBits(message.fontWeight.low >>> 0, message.fontWeight.high >>> 0).toNumber() : message.fontWeight;
            if (message.fontVariant != null && message.hasOwnProperty("fontVariant"))
                object.fontVariant = message.fontVariant;
            if (message.fontSize != null && message.hasOwnProperty("fontSize"))
                object.fontSize = options.json && !isFinite(message.fontSize) ? String(message.fontSize) : message.fontSize;
            if (message.fontColor != null && message.hasOwnProperty("fontColor"))
                if (typeof message.fontColor === "number")
                    object.fontColor = options.longs === String ? String(message.fontColor) : message.fontColor;
                else
                    object.fontColor = options.longs === String ? $util.Long.prototype.toString.call(message.fontColor) : options.longs === Number ? new $util.LongBits(message.fontColor.low >>> 0, message.fontColor.high >>> 0).toNumber() : message.fontColor;
            if (message.fontFamily != null && message.hasOwnProperty("fontFamily"))
                object.fontFamily = message.fontFamily;
            if (message.fontHeight != null && message.hasOwnProperty("fontHeight"))
                object.fontHeight = message.fontHeight;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.children && message.children.length) {
                object.children = [];
                for (let j = 0; j < message.children.length; ++j)
                    object.children[j] = $root.api.TextLayer.toObject(message.children[j], options);
            }
            return object;
        };

        /**
         * Converts this TextLayer to JSON.
         * @function toJSON
         * @memberof api.TextLayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TextLayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TextLayer;
    })();

    api.TextParagraph = (function() {

        /**
         * Properties of a TextParagraph.
         * @memberof api
         * @interface ITextParagraph
         * @property {api.TextLayerAlign|null} [textAlign] TextParagraph textAlign
         * @property {api.ITextLayer|null} [root] TextParagraph root
         */

        /**
         * Constructs a new TextParagraph.
         * @memberof api
         * @classdesc Represents a TextParagraph.
         * @implements ITextParagraph
         * @constructor
         * @param {api.ITextParagraph=} [properties] Properties to set
         */
        function TextParagraph(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TextParagraph textAlign.
         * @member {api.TextLayerAlign} textAlign
         * @memberof api.TextParagraph
         * @instance
         */
        TextParagraph.prototype.textAlign = 0;

        /**
         * TextParagraph root.
         * @member {api.ITextLayer|null|undefined} root
         * @memberof api.TextParagraph
         * @instance
         */
        TextParagraph.prototype.root = null;

        /**
         * Creates a new TextParagraph instance using the specified properties.
         * @function create
         * @memberof api.TextParagraph
         * @static
         * @param {api.ITextParagraph=} [properties] Properties to set
         * @returns {api.TextParagraph} TextParagraph instance
         */
        TextParagraph.create = function create(properties) {
            return new TextParagraph(properties);
        };

        /**
         * Encodes the specified TextParagraph message. Does not implicitly {@link api.TextParagraph.verify|verify} messages.
         * @function encode
         * @memberof api.TextParagraph
         * @static
         * @param {api.ITextParagraph} message TextParagraph message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextParagraph.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.textAlign != null && Object.hasOwnProperty.call(message, "textAlign"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.textAlign);
            if (message.root != null && Object.hasOwnProperty.call(message, "root"))
                $root.api.TextLayer.encode(message.root, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TextParagraph message, length delimited. Does not implicitly {@link api.TextParagraph.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TextParagraph
         * @static
         * @param {api.ITextParagraph} message TextParagraph message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextParagraph.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TextParagraph message from the specified reader or buffer.
         * @function decode
         * @memberof api.TextParagraph
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TextParagraph} TextParagraph
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextParagraph.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TextParagraph();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.textAlign = reader.int32();
                    break;
                case 2:
                    message.root = $root.api.TextLayer.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TextParagraph message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TextParagraph
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TextParagraph} TextParagraph
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextParagraph.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TextParagraph message.
         * @function verify
         * @memberof api.TextParagraph
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TextParagraph.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.textAlign != null && message.hasOwnProperty("textAlign"))
                switch (message.textAlign) {
                default:
                    return "textAlign: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.root != null && message.hasOwnProperty("root")) {
                let error = $root.api.TextLayer.verify(message.root);
                if (error)
                    return "root." + error;
            }
            return null;
        };

        /**
         * Creates a TextParagraph message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TextParagraph
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TextParagraph} TextParagraph
         */
        TextParagraph.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TextParagraph)
                return object;
            let message = new $root.api.TextParagraph();
            switch (object.textAlign) {
            case "NonSet":
            case 0:
                message.textAlign = 0;
                break;
            case "Left":
            case 1:
                message.textAlign = 1;
                break;
            case "Center":
            case 2:
                message.textAlign = 2;
                break;
            case "Right":
            case 3:
                message.textAlign = 3;
                break;
            case "Justify":
            case 4:
                message.textAlign = 4;
                break;
            }
            if (object.root != null) {
                if (typeof object.root !== "object")
                    throw TypeError(".api.TextParagraph.root: object expected");
                message.root = $root.api.TextLayer.fromObject(object.root);
            }
            return message;
        };

        /**
         * Creates a plain object from a TextParagraph message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TextParagraph
         * @static
         * @param {api.TextParagraph} message TextParagraph
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TextParagraph.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.textAlign = options.enums === String ? "NonSet" : 0;
                object.root = null;
            }
            if (message.textAlign != null && message.hasOwnProperty("textAlign"))
                object.textAlign = options.enums === String ? $root.api.TextLayerAlign[message.textAlign] : message.textAlign;
            if (message.root != null && message.hasOwnProperty("root"))
                object.root = $root.api.TextLayer.toObject(message.root, options);
            return object;
        };

        /**
         * Converts this TextParagraph to JSON.
         * @function toJSON
         * @memberof api.TextParagraph
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TextParagraph.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TextParagraph;
    })();

    api.TextBlock = (function() {

        /**
         * Properties of a TextBlock.
         * @memberof api
         * @interface ITextBlock
         * @property {number|Long|null} [backgroundColor] TextBlock backgroundColor
         * @property {Array.<api.ITextParagraph>|null} [paragraphs] TextBlock paragraphs
         * @property {number|null} [margin] TextBlock margin
         * @property {number|null} [curveRadius] TextBlock curveRadius
         * @property {number|Long|null} [outlineColor] TextBlock outlineColor
         * @property {number|null} [outlineThickness] TextBlock outlineThickness
         * @property {number|null} [outlineOffsetX] TextBlock outlineOffsetX
         * @property {number|null} [outlineOffsetY] TextBlock outlineOffsetY
         * @property {api.TextDrawStyle|null} [textDrawStyle] TextBlock textDrawStyle
         */

        /**
         * Constructs a new TextBlock.
         * @memberof api
         * @classdesc Represents a TextBlock.
         * @implements ITextBlock
         * @constructor
         * @param {api.ITextBlock=} [properties] Properties to set
         */
        function TextBlock(properties) {
            this.paragraphs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TextBlock backgroundColor.
         * @member {number|Long} backgroundColor
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.backgroundColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TextBlock paragraphs.
         * @member {Array.<api.ITextParagraph>} paragraphs
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.paragraphs = $util.emptyArray;

        /**
         * TextBlock margin.
         * @member {number} margin
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.margin = 0;

        /**
         * TextBlock curveRadius.
         * @member {number} curveRadius
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.curveRadius = 0;

        /**
         * TextBlock outlineColor.
         * @member {number|Long} outlineColor
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.outlineColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TextBlock outlineThickness.
         * @member {number} outlineThickness
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.outlineThickness = 0;

        /**
         * TextBlock outlineOffsetX.
         * @member {number} outlineOffsetX
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.outlineOffsetX = 0;

        /**
         * TextBlock outlineOffsetY.
         * @member {number} outlineOffsetY
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.outlineOffsetY = 0;

        /**
         * TextBlock textDrawStyle.
         * @member {api.TextDrawStyle} textDrawStyle
         * @memberof api.TextBlock
         * @instance
         */
        TextBlock.prototype.textDrawStyle = 0;

        /**
         * Creates a new TextBlock instance using the specified properties.
         * @function create
         * @memberof api.TextBlock
         * @static
         * @param {api.ITextBlock=} [properties] Properties to set
         * @returns {api.TextBlock} TextBlock instance
         */
        TextBlock.create = function create(properties) {
            return new TextBlock(properties);
        };

        /**
         * Encodes the specified TextBlock message. Does not implicitly {@link api.TextBlock.verify|verify} messages.
         * @function encode
         * @memberof api.TextBlock
         * @static
         * @param {api.ITextBlock} message TextBlock message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextBlock.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.backgroundColor);
            if (message.paragraphs != null && message.paragraphs.length)
                for (let i = 0; i < message.paragraphs.length; ++i)
                    $root.api.TextParagraph.encode(message.paragraphs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.margin != null && Object.hasOwnProperty.call(message, "margin"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.margin);
            if (message.curveRadius != null && Object.hasOwnProperty.call(message, "curveRadius"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.curveRadius);
            if (message.outlineColor != null && Object.hasOwnProperty.call(message, "outlineColor"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.outlineColor);
            if (message.outlineThickness != null && Object.hasOwnProperty.call(message, "outlineThickness"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.outlineThickness);
            if (message.outlineOffsetX != null && Object.hasOwnProperty.call(message, "outlineOffsetX"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.outlineOffsetX);
            if (message.outlineOffsetY != null && Object.hasOwnProperty.call(message, "outlineOffsetY"))
                writer.uint32(/* id 8, wireType 1 =*/65).double(message.outlineOffsetY);
            if (message.textDrawStyle != null && Object.hasOwnProperty.call(message, "textDrawStyle"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.textDrawStyle);
            return writer;
        };

        /**
         * Encodes the specified TextBlock message, length delimited. Does not implicitly {@link api.TextBlock.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TextBlock
         * @static
         * @param {api.ITextBlock} message TextBlock message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TextBlock.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TextBlock message from the specified reader or buffer.
         * @function decode
         * @memberof api.TextBlock
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TextBlock} TextBlock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextBlock.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TextBlock();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.backgroundColor = reader.int64();
                    break;
                case 2:
                    if (!(message.paragraphs && message.paragraphs.length))
                        message.paragraphs = [];
                    message.paragraphs.push($root.api.TextParagraph.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.margin = reader.double();
                    break;
                case 4:
                    message.curveRadius = reader.double();
                    break;
                case 5:
                    message.outlineColor = reader.int64();
                    break;
                case 6:
                    message.outlineThickness = reader.double();
                    break;
                case 7:
                    message.outlineOffsetX = reader.double();
                    break;
                case 8:
                    message.outlineOffsetY = reader.double();
                    break;
                case 9:
                    message.textDrawStyle = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TextBlock message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TextBlock
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TextBlock} TextBlock
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TextBlock.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TextBlock message.
         * @function verify
         * @memberof api.TextBlock
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TextBlock.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
                if (!$util.isInteger(message.backgroundColor) && !(message.backgroundColor && $util.isInteger(message.backgroundColor.low) && $util.isInteger(message.backgroundColor.high)))
                    return "backgroundColor: integer|Long expected";
            if (message.paragraphs != null && message.hasOwnProperty("paragraphs")) {
                if (!Array.isArray(message.paragraphs))
                    return "paragraphs: array expected";
                for (let i = 0; i < message.paragraphs.length; ++i) {
                    let error = $root.api.TextParagraph.verify(message.paragraphs[i]);
                    if (error)
                        return "paragraphs." + error;
                }
            }
            if (message.margin != null && message.hasOwnProperty("margin"))
                if (typeof message.margin !== "number")
                    return "margin: number expected";
            if (message.curveRadius != null && message.hasOwnProperty("curveRadius"))
                if (typeof message.curveRadius !== "number")
                    return "curveRadius: number expected";
            if (message.outlineColor != null && message.hasOwnProperty("outlineColor"))
                if (!$util.isInteger(message.outlineColor) && !(message.outlineColor && $util.isInteger(message.outlineColor.low) && $util.isInteger(message.outlineColor.high)))
                    return "outlineColor: integer|Long expected";
            if (message.outlineThickness != null && message.hasOwnProperty("outlineThickness"))
                if (typeof message.outlineThickness !== "number")
                    return "outlineThickness: number expected";
            if (message.outlineOffsetX != null && message.hasOwnProperty("outlineOffsetX"))
                if (typeof message.outlineOffsetX !== "number")
                    return "outlineOffsetX: number expected";
            if (message.outlineOffsetY != null && message.hasOwnProperty("outlineOffsetY"))
                if (typeof message.outlineOffsetY !== "number")
                    return "outlineOffsetY: number expected";
            if (message.textDrawStyle != null && message.hasOwnProperty("textDrawStyle"))
                switch (message.textDrawStyle) {
                default:
                    return "textDrawStyle: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a TextBlock message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TextBlock
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TextBlock} TextBlock
         */
        TextBlock.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TextBlock)
                return object;
            let message = new $root.api.TextBlock();
            if (object.backgroundColor != null)
                if ($util.Long)
                    (message.backgroundColor = $util.Long.fromValue(object.backgroundColor)).unsigned = false;
                else if (typeof object.backgroundColor === "string")
                    message.backgroundColor = parseInt(object.backgroundColor, 10);
                else if (typeof object.backgroundColor === "number")
                    message.backgroundColor = object.backgroundColor;
                else if (typeof object.backgroundColor === "object")
                    message.backgroundColor = new $util.LongBits(object.backgroundColor.low >>> 0, object.backgroundColor.high >>> 0).toNumber();
            if (object.paragraphs) {
                if (!Array.isArray(object.paragraphs))
                    throw TypeError(".api.TextBlock.paragraphs: array expected");
                message.paragraphs = [];
                for (let i = 0; i < object.paragraphs.length; ++i) {
                    if (typeof object.paragraphs[i] !== "object")
                        throw TypeError(".api.TextBlock.paragraphs: object expected");
                    message.paragraphs[i] = $root.api.TextParagraph.fromObject(object.paragraphs[i]);
                }
            }
            if (object.margin != null)
                message.margin = Number(object.margin);
            if (object.curveRadius != null)
                message.curveRadius = Number(object.curveRadius);
            if (object.outlineColor != null)
                if ($util.Long)
                    (message.outlineColor = $util.Long.fromValue(object.outlineColor)).unsigned = false;
                else if (typeof object.outlineColor === "string")
                    message.outlineColor = parseInt(object.outlineColor, 10);
                else if (typeof object.outlineColor === "number")
                    message.outlineColor = object.outlineColor;
                else if (typeof object.outlineColor === "object")
                    message.outlineColor = new $util.LongBits(object.outlineColor.low >>> 0, object.outlineColor.high >>> 0).toNumber();
            if (object.outlineThickness != null)
                message.outlineThickness = Number(object.outlineThickness);
            if (object.outlineOffsetX != null)
                message.outlineOffsetX = Number(object.outlineOffsetX);
            if (object.outlineOffsetY != null)
                message.outlineOffsetY = Number(object.outlineOffsetY);
            switch (object.textDrawStyle) {
            case "Normal":
            case 0:
                message.textDrawStyle = 0;
                break;
            case "Outline":
            case 1:
                message.textDrawStyle = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a TextBlock message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TextBlock
         * @static
         * @param {api.TextBlock} message TextBlock
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TextBlock.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.paragraphs = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.backgroundColor = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.backgroundColor = options.longs === String ? "0" : 0;
                object.margin = 0;
                object.curveRadius = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.outlineColor = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outlineColor = options.longs === String ? "0" : 0;
                object.outlineThickness = 0;
                object.outlineOffsetX = 0;
                object.outlineOffsetY = 0;
                object.textDrawStyle = options.enums === String ? "Normal" : 0;
            }
            if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
                if (typeof message.backgroundColor === "number")
                    object.backgroundColor = options.longs === String ? String(message.backgroundColor) : message.backgroundColor;
                else
                    object.backgroundColor = options.longs === String ? $util.Long.prototype.toString.call(message.backgroundColor) : options.longs === Number ? new $util.LongBits(message.backgroundColor.low >>> 0, message.backgroundColor.high >>> 0).toNumber() : message.backgroundColor;
            if (message.paragraphs && message.paragraphs.length) {
                object.paragraphs = [];
                for (let j = 0; j < message.paragraphs.length; ++j)
                    object.paragraphs[j] = $root.api.TextParagraph.toObject(message.paragraphs[j], options);
            }
            if (message.margin != null && message.hasOwnProperty("margin"))
                object.margin = options.json && !isFinite(message.margin) ? String(message.margin) : message.margin;
            if (message.curveRadius != null && message.hasOwnProperty("curveRadius"))
                object.curveRadius = options.json && !isFinite(message.curveRadius) ? String(message.curveRadius) : message.curveRadius;
            if (message.outlineColor != null && message.hasOwnProperty("outlineColor"))
                if (typeof message.outlineColor === "number")
                    object.outlineColor = options.longs === String ? String(message.outlineColor) : message.outlineColor;
                else
                    object.outlineColor = options.longs === String ? $util.Long.prototype.toString.call(message.outlineColor) : options.longs === Number ? new $util.LongBits(message.outlineColor.low >>> 0, message.outlineColor.high >>> 0).toNumber() : message.outlineColor;
            if (message.outlineThickness != null && message.hasOwnProperty("outlineThickness"))
                object.outlineThickness = options.json && !isFinite(message.outlineThickness) ? String(message.outlineThickness) : message.outlineThickness;
            if (message.outlineOffsetX != null && message.hasOwnProperty("outlineOffsetX"))
                object.outlineOffsetX = options.json && !isFinite(message.outlineOffsetX) ? String(message.outlineOffsetX) : message.outlineOffsetX;
            if (message.outlineOffsetY != null && message.hasOwnProperty("outlineOffsetY"))
                object.outlineOffsetY = options.json && !isFinite(message.outlineOffsetY) ? String(message.outlineOffsetY) : message.outlineOffsetY;
            if (message.textDrawStyle != null && message.hasOwnProperty("textDrawStyle"))
                object.textDrawStyle = options.enums === String ? $root.api.TextDrawStyle[message.textDrawStyle] : message.textDrawStyle;
            return object;
        };

        /**
         * Converts this TextBlock to JSON.
         * @function toJSON
         * @memberof api.TextBlock
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TextBlock.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TextBlock;
    })();

    /**
     * LayerEffectType enum.
     * @name api.LayerEffectType
     * @enum {number}
     * @property {number} Shadow=0 Shadow value
     * @property {number} Glow=1 Glow value
     * @property {number} Blur=2 Blur value
     * @property {number} Dilate=3 Dilate value
     */
    api.LayerEffectType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Shadow"] = 0;
        values[valuesById[1] = "Glow"] = 1;
        values[valuesById[2] = "Blur"] = 2;
        values[valuesById[3] = "Dilate"] = 3;
        return values;
    })();

    /**
     * BlurType enum.
     * @name api.BlurType
     * @enum {number}
     * @property {number} OmniDirectional=0 OmniDirectional value
     * @property {number} Horizontal=1 Horizontal value
     * @property {number} Vertical=2 Vertical value
     */
    api.BlurType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OmniDirectional"] = 0;
        values[valuesById[1] = "Horizontal"] = 1;
        values[valuesById[2] = "Vertical"] = 2;
        return values;
    })();

    api.LayerEffect = (function() {

        /**
         * Properties of a LayerEffect.
         * @memberof api
         * @interface ILayerEffect
         * @property {api.LayerEffectType|null} [effectType] LayerEffect effectType
         * @property {number|Long|null} [shadowColor] LayerEffect shadowColor
         * @property {number|null} [shadowDistance] LayerEffect shadowDistance
         * @property {number|null} [shadowAngle] LayerEffect shadowAngle
         * @property {number|null} [shadowBlurRadius] LayerEffect shadowBlurRadius
         * @property {boolean|null} [enabled] LayerEffect enabled
         * @property {number|null} [glowIntensity] LayerEffect glowIntensity
         * @property {api.BlurType|null} [blurType] LayerEffect blurType
         * @property {number|null} [blurIntensity] LayerEffect blurIntensity
         * @property {number|null} [dilateRadius] LayerEffect dilateRadius
         */

        /**
         * Constructs a new LayerEffect.
         * @memberof api
         * @classdesc Represents a LayerEffect.
         * @implements ILayerEffect
         * @constructor
         * @param {api.ILayerEffect=} [properties] Properties to set
         */
        function LayerEffect(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LayerEffect effectType.
         * @member {api.LayerEffectType} effectType
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.effectType = 0;

        /**
         * LayerEffect shadowColor.
         * @member {number|Long} shadowColor
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.shadowColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LayerEffect shadowDistance.
         * @member {number} shadowDistance
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.shadowDistance = 0;

        /**
         * LayerEffect shadowAngle.
         * @member {number} shadowAngle
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.shadowAngle = 0;

        /**
         * LayerEffect shadowBlurRadius.
         * @member {number} shadowBlurRadius
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.shadowBlurRadius = 0;

        /**
         * LayerEffect enabled.
         * @member {boolean} enabled
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.enabled = false;

        /**
         * LayerEffect glowIntensity.
         * @member {number} glowIntensity
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.glowIntensity = 0;

        /**
         * LayerEffect blurType.
         * @member {api.BlurType} blurType
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.blurType = 0;

        /**
         * LayerEffect blurIntensity.
         * @member {number} blurIntensity
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.blurIntensity = 0;

        /**
         * LayerEffect dilateRadius.
         * @member {number} dilateRadius
         * @memberof api.LayerEffect
         * @instance
         */
        LayerEffect.prototype.dilateRadius = 0;

        /**
         * Creates a new LayerEffect instance using the specified properties.
         * @function create
         * @memberof api.LayerEffect
         * @static
         * @param {api.ILayerEffect=} [properties] Properties to set
         * @returns {api.LayerEffect} LayerEffect instance
         */
        LayerEffect.create = function create(properties) {
            return new LayerEffect(properties);
        };

        /**
         * Encodes the specified LayerEffect message. Does not implicitly {@link api.LayerEffect.verify|verify} messages.
         * @function encode
         * @memberof api.LayerEffect
         * @static
         * @param {api.ILayerEffect} message LayerEffect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LayerEffect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.shadowColor != null && Object.hasOwnProperty.call(message, "shadowColor"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.shadowColor);
            if (message.shadowDistance != null && Object.hasOwnProperty.call(message, "shadowDistance"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.shadowDistance);
            if (message.shadowAngle != null && Object.hasOwnProperty.call(message, "shadowAngle"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.shadowAngle);
            if (message.shadowBlurRadius != null && Object.hasOwnProperty.call(message, "shadowBlurRadius"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.shadowBlurRadius);
            if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.enabled);
            if (message.effectType != null && Object.hasOwnProperty.call(message, "effectType"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.effectType);
            if (message.glowIntensity != null && Object.hasOwnProperty.call(message, "glowIntensity"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.glowIntensity);
            if (message.blurType != null && Object.hasOwnProperty.call(message, "blurType"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.blurType);
            if (message.blurIntensity != null && Object.hasOwnProperty.call(message, "blurIntensity"))
                writer.uint32(/* id 9, wireType 1 =*/73).double(message.blurIntensity);
            if (message.dilateRadius != null && Object.hasOwnProperty.call(message, "dilateRadius"))
                writer.uint32(/* id 10, wireType 1 =*/81).double(message.dilateRadius);
            return writer;
        };

        /**
         * Encodes the specified LayerEffect message, length delimited. Does not implicitly {@link api.LayerEffect.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LayerEffect
         * @static
         * @param {api.ILayerEffect} message LayerEffect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LayerEffect.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LayerEffect message from the specified reader or buffer.
         * @function decode
         * @memberof api.LayerEffect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LayerEffect} LayerEffect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LayerEffect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LayerEffect();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6:
                    message.effectType = reader.int32();
                    break;
                case 1:
                    message.shadowColor = reader.int64();
                    break;
                case 2:
                    message.shadowDistance = reader.double();
                    break;
                case 3:
                    message.shadowAngle = reader.double();
                    break;
                case 4:
                    message.shadowBlurRadius = reader.double();
                    break;
                case 5:
                    message.enabled = reader.bool();
                    break;
                case 7:
                    message.glowIntensity = reader.double();
                    break;
                case 8:
                    message.blurType = reader.int32();
                    break;
                case 9:
                    message.blurIntensity = reader.double();
                    break;
                case 10:
                    message.dilateRadius = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LayerEffect message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LayerEffect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LayerEffect} LayerEffect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LayerEffect.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LayerEffect message.
         * @function verify
         * @memberof api.LayerEffect
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LayerEffect.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.effectType != null && message.hasOwnProperty("effectType"))
                switch (message.effectType) {
                default:
                    return "effectType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.shadowColor != null && message.hasOwnProperty("shadowColor"))
                if (!$util.isInteger(message.shadowColor) && !(message.shadowColor && $util.isInteger(message.shadowColor.low) && $util.isInteger(message.shadowColor.high)))
                    return "shadowColor: integer|Long expected";
            if (message.shadowDistance != null && message.hasOwnProperty("shadowDistance"))
                if (typeof message.shadowDistance !== "number")
                    return "shadowDistance: number expected";
            if (message.shadowAngle != null && message.hasOwnProperty("shadowAngle"))
                if (typeof message.shadowAngle !== "number")
                    return "shadowAngle: number expected";
            if (message.shadowBlurRadius != null && message.hasOwnProperty("shadowBlurRadius"))
                if (typeof message.shadowBlurRadius !== "number")
                    return "shadowBlurRadius: number expected";
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                if (typeof message.enabled !== "boolean")
                    return "enabled: boolean expected";
            if (message.glowIntensity != null && message.hasOwnProperty("glowIntensity"))
                if (typeof message.glowIntensity !== "number")
                    return "glowIntensity: number expected";
            if (message.blurType != null && message.hasOwnProperty("blurType"))
                switch (message.blurType) {
                default:
                    return "blurType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.blurIntensity != null && message.hasOwnProperty("blurIntensity"))
                if (typeof message.blurIntensity !== "number")
                    return "blurIntensity: number expected";
            if (message.dilateRadius != null && message.hasOwnProperty("dilateRadius"))
                if (typeof message.dilateRadius !== "number")
                    return "dilateRadius: number expected";
            return null;
        };

        /**
         * Creates a LayerEffect message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LayerEffect
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LayerEffect} LayerEffect
         */
        LayerEffect.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LayerEffect)
                return object;
            let message = new $root.api.LayerEffect();
            switch (object.effectType) {
            case "Shadow":
            case 0:
                message.effectType = 0;
                break;
            case "Glow":
            case 1:
                message.effectType = 1;
                break;
            case "Blur":
            case 2:
                message.effectType = 2;
                break;
            case "Dilate":
            case 3:
                message.effectType = 3;
                break;
            }
            if (object.shadowColor != null)
                if ($util.Long)
                    (message.shadowColor = $util.Long.fromValue(object.shadowColor)).unsigned = false;
                else if (typeof object.shadowColor === "string")
                    message.shadowColor = parseInt(object.shadowColor, 10);
                else if (typeof object.shadowColor === "number")
                    message.shadowColor = object.shadowColor;
                else if (typeof object.shadowColor === "object")
                    message.shadowColor = new $util.LongBits(object.shadowColor.low >>> 0, object.shadowColor.high >>> 0).toNumber();
            if (object.shadowDistance != null)
                message.shadowDistance = Number(object.shadowDistance);
            if (object.shadowAngle != null)
                message.shadowAngle = Number(object.shadowAngle);
            if (object.shadowBlurRadius != null)
                message.shadowBlurRadius = Number(object.shadowBlurRadius);
            if (object.enabled != null)
                message.enabled = Boolean(object.enabled);
            if (object.glowIntensity != null)
                message.glowIntensity = Number(object.glowIntensity);
            switch (object.blurType) {
            case "OmniDirectional":
            case 0:
                message.blurType = 0;
                break;
            case "Horizontal":
            case 1:
                message.blurType = 1;
                break;
            case "Vertical":
            case 2:
                message.blurType = 2;
                break;
            }
            if (object.blurIntensity != null)
                message.blurIntensity = Number(object.blurIntensity);
            if (object.dilateRadius != null)
                message.dilateRadius = Number(object.dilateRadius);
            return message;
        };

        /**
         * Creates a plain object from a LayerEffect message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LayerEffect
         * @static
         * @param {api.LayerEffect} message LayerEffect
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LayerEffect.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.shadowColor = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.shadowColor = options.longs === String ? "0" : 0;
                object.shadowDistance = 0;
                object.shadowAngle = 0;
                object.shadowBlurRadius = 0;
                object.enabled = false;
                object.effectType = options.enums === String ? "Shadow" : 0;
                object.glowIntensity = 0;
                object.blurType = options.enums === String ? "OmniDirectional" : 0;
                object.blurIntensity = 0;
                object.dilateRadius = 0;
            }
            if (message.shadowColor != null && message.hasOwnProperty("shadowColor"))
                if (typeof message.shadowColor === "number")
                    object.shadowColor = options.longs === String ? String(message.shadowColor) : message.shadowColor;
                else
                    object.shadowColor = options.longs === String ? $util.Long.prototype.toString.call(message.shadowColor) : options.longs === Number ? new $util.LongBits(message.shadowColor.low >>> 0, message.shadowColor.high >>> 0).toNumber() : message.shadowColor;
            if (message.shadowDistance != null && message.hasOwnProperty("shadowDistance"))
                object.shadowDistance = options.json && !isFinite(message.shadowDistance) ? String(message.shadowDistance) : message.shadowDistance;
            if (message.shadowAngle != null && message.hasOwnProperty("shadowAngle"))
                object.shadowAngle = options.json && !isFinite(message.shadowAngle) ? String(message.shadowAngle) : message.shadowAngle;
            if (message.shadowBlurRadius != null && message.hasOwnProperty("shadowBlurRadius"))
                object.shadowBlurRadius = options.json && !isFinite(message.shadowBlurRadius) ? String(message.shadowBlurRadius) : message.shadowBlurRadius;
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                object.enabled = message.enabled;
            if (message.effectType != null && message.hasOwnProperty("effectType"))
                object.effectType = options.enums === String ? $root.api.LayerEffectType[message.effectType] : message.effectType;
            if (message.glowIntensity != null && message.hasOwnProperty("glowIntensity"))
                object.glowIntensity = options.json && !isFinite(message.glowIntensity) ? String(message.glowIntensity) : message.glowIntensity;
            if (message.blurType != null && message.hasOwnProperty("blurType"))
                object.blurType = options.enums === String ? $root.api.BlurType[message.blurType] : message.blurType;
            if (message.blurIntensity != null && message.hasOwnProperty("blurIntensity"))
                object.blurIntensity = options.json && !isFinite(message.blurIntensity) ? String(message.blurIntensity) : message.blurIntensity;
            if (message.dilateRadius != null && message.hasOwnProperty("dilateRadius"))
                object.dilateRadius = options.json && !isFinite(message.dilateRadius) ? String(message.dilateRadius) : message.dilateRadius;
            return object;
        };

        /**
         * Converts this LayerEffect to JSON.
         * @function toJSON
         * @memberof api.LayerEffect
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LayerEffect.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LayerEffect;
    })();

    /**
     * ColorEffectType enum.
     * @name api.ColorEffectType
     * @enum {number}
     * @property {number} Custom=0 Custom value
     * @property {number} Sepia=1 Sepia value
     * @property {number} Invert=2 Invert value
     * @property {number} Greyscale=3 Greyscale value
     */
    api.ColorEffectType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Custom"] = 0;
        values[valuesById[1] = "Sepia"] = 1;
        values[valuesById[2] = "Invert"] = 2;
        values[valuesById[3] = "Greyscale"] = 3;
        return values;
    })();

    api.ColorEffect = (function() {

        /**
         * Properties of a ColorEffect.
         * @memberof api
         * @interface IColorEffect
         * @property {number|null} [hue] ColorEffect hue
         * @property {number|null} [saturation] ColorEffect saturation
         * @property {number|null} [brightness] ColorEffect brightness
         * @property {boolean|null} [enabled] ColorEffect enabled
         * @property {api.ColorEffectType|null} [colorEffectType] ColorEffect colorEffectType
         * @property {number|null} [transparency] ColorEffect transparency
         * @property {number|null} [contrast] ColorEffect contrast
         */

        /**
         * Constructs a new ColorEffect.
         * @memberof api
         * @classdesc Represents a ColorEffect.
         * @implements IColorEffect
         * @constructor
         * @param {api.IColorEffect=} [properties] Properties to set
         */
        function ColorEffect(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ColorEffect hue.
         * @member {number} hue
         * @memberof api.ColorEffect
         * @instance
         */
        ColorEffect.prototype.hue = 0;

        /**
         * ColorEffect saturation.
         * @member {number} saturation
         * @memberof api.ColorEffect
         * @instance
         */
        ColorEffect.prototype.saturation = 0;

        /**
         * ColorEffect brightness.
         * @member {number} brightness
         * @memberof api.ColorEffect
         * @instance
         */
        ColorEffect.prototype.brightness = 0;

        /**
         * ColorEffect enabled.
         * @member {boolean} enabled
         * @memberof api.ColorEffect
         * @instance
         */
        ColorEffect.prototype.enabled = false;

        /**
         * ColorEffect colorEffectType.
         * @member {api.ColorEffectType} colorEffectType
         * @memberof api.ColorEffect
         * @instance
         */
        ColorEffect.prototype.colorEffectType = 0;

        /**
         * ColorEffect transparency.
         * @member {number} transparency
         * @memberof api.ColorEffect
         * @instance
         */
        ColorEffect.prototype.transparency = 0;

        /**
         * ColorEffect contrast.
         * @member {number} contrast
         * @memberof api.ColorEffect
         * @instance
         */
        ColorEffect.prototype.contrast = 0;

        /**
         * Creates a new ColorEffect instance using the specified properties.
         * @function create
         * @memberof api.ColorEffect
         * @static
         * @param {api.IColorEffect=} [properties] Properties to set
         * @returns {api.ColorEffect} ColorEffect instance
         */
        ColorEffect.create = function create(properties) {
            return new ColorEffect(properties);
        };

        /**
         * Encodes the specified ColorEffect message. Does not implicitly {@link api.ColorEffect.verify|verify} messages.
         * @function encode
         * @memberof api.ColorEffect
         * @static
         * @param {api.IColorEffect} message ColorEffect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ColorEffect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hue != null && Object.hasOwnProperty.call(message, "hue"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.hue);
            if (message.saturation != null && Object.hasOwnProperty.call(message, "saturation"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.saturation);
            if (message.brightness != null && Object.hasOwnProperty.call(message, "brightness"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.brightness);
            if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.enabled);
            if (message.colorEffectType != null && Object.hasOwnProperty.call(message, "colorEffectType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.colorEffectType);
            if (message.transparency != null && Object.hasOwnProperty.call(message, "transparency"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.transparency);
            if (message.contrast != null && Object.hasOwnProperty.call(message, "contrast"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.contrast);
            return writer;
        };

        /**
         * Encodes the specified ColorEffect message, length delimited. Does not implicitly {@link api.ColorEffect.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ColorEffect
         * @static
         * @param {api.IColorEffect} message ColorEffect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ColorEffect.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ColorEffect message from the specified reader or buffer.
         * @function decode
         * @memberof api.ColorEffect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ColorEffect} ColorEffect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ColorEffect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ColorEffect();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.hue = reader.double();
                    break;
                case 2:
                    message.saturation = reader.double();
                    break;
                case 3:
                    message.brightness = reader.double();
                    break;
                case 4:
                    message.enabled = reader.bool();
                    break;
                case 5:
                    message.colorEffectType = reader.int32();
                    break;
                case 6:
                    message.transparency = reader.double();
                    break;
                case 7:
                    message.contrast = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ColorEffect message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ColorEffect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ColorEffect} ColorEffect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ColorEffect.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ColorEffect message.
         * @function verify
         * @memberof api.ColorEffect
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ColorEffect.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hue != null && message.hasOwnProperty("hue"))
                if (typeof message.hue !== "number")
                    return "hue: number expected";
            if (message.saturation != null && message.hasOwnProperty("saturation"))
                if (typeof message.saturation !== "number")
                    return "saturation: number expected";
            if (message.brightness != null && message.hasOwnProperty("brightness"))
                if (typeof message.brightness !== "number")
                    return "brightness: number expected";
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                if (typeof message.enabled !== "boolean")
                    return "enabled: boolean expected";
            if (message.colorEffectType != null && message.hasOwnProperty("colorEffectType"))
                switch (message.colorEffectType) {
                default:
                    return "colorEffectType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.transparency != null && message.hasOwnProperty("transparency"))
                if (typeof message.transparency !== "number")
                    return "transparency: number expected";
            if (message.contrast != null && message.hasOwnProperty("contrast"))
                if (typeof message.contrast !== "number")
                    return "contrast: number expected";
            return null;
        };

        /**
         * Creates a ColorEffect message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ColorEffect
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ColorEffect} ColorEffect
         */
        ColorEffect.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ColorEffect)
                return object;
            let message = new $root.api.ColorEffect();
            if (object.hue != null)
                message.hue = Number(object.hue);
            if (object.saturation != null)
                message.saturation = Number(object.saturation);
            if (object.brightness != null)
                message.brightness = Number(object.brightness);
            if (object.enabled != null)
                message.enabled = Boolean(object.enabled);
            switch (object.colorEffectType) {
            case "Custom":
            case 0:
                message.colorEffectType = 0;
                break;
            case "Sepia":
            case 1:
                message.colorEffectType = 1;
                break;
            case "Invert":
            case 2:
                message.colorEffectType = 2;
                break;
            case "Greyscale":
            case 3:
                message.colorEffectType = 3;
                break;
            }
            if (object.transparency != null)
                message.transparency = Number(object.transparency);
            if (object.contrast != null)
                message.contrast = Number(object.contrast);
            return message;
        };

        /**
         * Creates a plain object from a ColorEffect message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ColorEffect
         * @static
         * @param {api.ColorEffect} message ColorEffect
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ColorEffect.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hue = 0;
                object.saturation = 0;
                object.brightness = 0;
                object.enabled = false;
                object.colorEffectType = options.enums === String ? "Custom" : 0;
                object.transparency = 0;
                object.contrast = 0;
            }
            if (message.hue != null && message.hasOwnProperty("hue"))
                object.hue = options.json && !isFinite(message.hue) ? String(message.hue) : message.hue;
            if (message.saturation != null && message.hasOwnProperty("saturation"))
                object.saturation = options.json && !isFinite(message.saturation) ? String(message.saturation) : message.saturation;
            if (message.brightness != null && message.hasOwnProperty("brightness"))
                object.brightness = options.json && !isFinite(message.brightness) ? String(message.brightness) : message.brightness;
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                object.enabled = message.enabled;
            if (message.colorEffectType != null && message.hasOwnProperty("colorEffectType"))
                object.colorEffectType = options.enums === String ? $root.api.ColorEffectType[message.colorEffectType] : message.colorEffectType;
            if (message.transparency != null && message.hasOwnProperty("transparency"))
                object.transparency = options.json && !isFinite(message.transparency) ? String(message.transparency) : message.transparency;
            if (message.contrast != null && message.hasOwnProperty("contrast"))
                object.contrast = options.json && !isFinite(message.contrast) ? String(message.contrast) : message.contrast;
            return object;
        };

        /**
         * Converts this ColorEffect to JSON.
         * @function toJSON
         * @memberof api.ColorEffect
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ColorEffect.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ColorEffect;
    })();

    api.MaskEffect = (function() {

        /**
         * Properties of a MaskEffect.
         * @memberof api
         * @interface IMaskEffect
         * @property {boolean|null} [enabled] MaskEffect enabled
         * @property {api.DrawShape|null} [drawShape] MaskEffect drawShape
         * @property {number|null} [angle] MaskEffect angle
         * @property {number|null} [sides] MaskEffect sides
         * @property {number|null} [cornerRadius] MaskEffect cornerRadius
         * @property {number|null} [size] MaskEffect size
         * @property {string|null} [vectorId] MaskEffect vectorId
         */

        /**
         * Constructs a new MaskEffect.
         * @memberof api
         * @classdesc Represents a MaskEffect.
         * @implements IMaskEffect
         * @constructor
         * @param {api.IMaskEffect=} [properties] Properties to set
         */
        function MaskEffect(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MaskEffect enabled.
         * @member {boolean} enabled
         * @memberof api.MaskEffect
         * @instance
         */
        MaskEffect.prototype.enabled = false;

        /**
         * MaskEffect drawShape.
         * @member {api.DrawShape} drawShape
         * @memberof api.MaskEffect
         * @instance
         */
        MaskEffect.prototype.drawShape = 0;

        /**
         * MaskEffect angle.
         * @member {number} angle
         * @memberof api.MaskEffect
         * @instance
         */
        MaskEffect.prototype.angle = 0;

        /**
         * MaskEffect sides.
         * @member {number} sides
         * @memberof api.MaskEffect
         * @instance
         */
        MaskEffect.prototype.sides = 0;

        /**
         * MaskEffect cornerRadius.
         * @member {number} cornerRadius
         * @memberof api.MaskEffect
         * @instance
         */
        MaskEffect.prototype.cornerRadius = 0;

        /**
         * MaskEffect size.
         * @member {number} size
         * @memberof api.MaskEffect
         * @instance
         */
        MaskEffect.prototype.size = 0;

        /**
         * MaskEffect vectorId.
         * @member {string} vectorId
         * @memberof api.MaskEffect
         * @instance
         */
        MaskEffect.prototype.vectorId = "";

        /**
         * Creates a new MaskEffect instance using the specified properties.
         * @function create
         * @memberof api.MaskEffect
         * @static
         * @param {api.IMaskEffect=} [properties] Properties to set
         * @returns {api.MaskEffect} MaskEffect instance
         */
        MaskEffect.create = function create(properties) {
            return new MaskEffect(properties);
        };

        /**
         * Encodes the specified MaskEffect message. Does not implicitly {@link api.MaskEffect.verify|verify} messages.
         * @function encode
         * @memberof api.MaskEffect
         * @static
         * @param {api.IMaskEffect} message MaskEffect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MaskEffect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
            if (message.drawShape != null && Object.hasOwnProperty.call(message, "drawShape"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.drawShape);
            if (message.angle != null && Object.hasOwnProperty.call(message, "angle"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.angle);
            if (message.sides != null && Object.hasOwnProperty.call(message, "sides"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sides);
            if (message.cornerRadius != null && Object.hasOwnProperty.call(message, "cornerRadius"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.cornerRadius);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.size);
            if (message.vectorId != null && Object.hasOwnProperty.call(message, "vectorId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.vectorId);
            return writer;
        };

        /**
         * Encodes the specified MaskEffect message, length delimited. Does not implicitly {@link api.MaskEffect.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MaskEffect
         * @static
         * @param {api.IMaskEffect} message MaskEffect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MaskEffect.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MaskEffect message from the specified reader or buffer.
         * @function decode
         * @memberof api.MaskEffect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MaskEffect} MaskEffect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MaskEffect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MaskEffect();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.enabled = reader.bool();
                    break;
                case 2:
                    message.drawShape = reader.int32();
                    break;
                case 3:
                    message.angle = reader.double();
                    break;
                case 4:
                    message.sides = reader.int32();
                    break;
                case 5:
                    message.cornerRadius = reader.double();
                    break;
                case 6:
                    message.size = reader.double();
                    break;
                case 7:
                    message.vectorId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MaskEffect message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MaskEffect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MaskEffect} MaskEffect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MaskEffect.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MaskEffect message.
         * @function verify
         * @memberof api.MaskEffect
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MaskEffect.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                if (typeof message.enabled !== "boolean")
                    return "enabled: boolean expected";
            if (message.drawShape != null && message.hasOwnProperty("drawShape"))
                switch (message.drawShape) {
                default:
                    return "drawShape: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.angle != null && message.hasOwnProperty("angle"))
                if (typeof message.angle !== "number")
                    return "angle: number expected";
            if (message.sides != null && message.hasOwnProperty("sides"))
                if (!$util.isInteger(message.sides))
                    return "sides: integer expected";
            if (message.cornerRadius != null && message.hasOwnProperty("cornerRadius"))
                if (typeof message.cornerRadius !== "number")
                    return "cornerRadius: number expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size !== "number")
                    return "size: number expected";
            if (message.vectorId != null && message.hasOwnProperty("vectorId"))
                if (!$util.isString(message.vectorId))
                    return "vectorId: string expected";
            return null;
        };

        /**
         * Creates a MaskEffect message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MaskEffect
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MaskEffect} MaskEffect
         */
        MaskEffect.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MaskEffect)
                return object;
            let message = new $root.api.MaskEffect();
            if (object.enabled != null)
                message.enabled = Boolean(object.enabled);
            switch (object.drawShape) {
            case "Rectangle":
            case 0:
                message.drawShape = 0;
                break;
            case "Circle":
            case 1:
                message.drawShape = 1;
                break;
            case "Polygon":
            case 2:
                message.drawShape = 2;
                break;
            case "Line":
            case 3:
                message.drawShape = 3;
                break;
            case "Svg":
            case 4:
                message.drawShape = 4;
                break;
            }
            if (object.angle != null)
                message.angle = Number(object.angle);
            if (object.sides != null)
                message.sides = object.sides | 0;
            if (object.cornerRadius != null)
                message.cornerRadius = Number(object.cornerRadius);
            if (object.size != null)
                message.size = Number(object.size);
            if (object.vectorId != null)
                message.vectorId = String(object.vectorId);
            return message;
        };

        /**
         * Creates a plain object from a MaskEffect message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MaskEffect
         * @static
         * @param {api.MaskEffect} message MaskEffect
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MaskEffect.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.enabled = false;
                object.drawShape = options.enums === String ? "Rectangle" : 0;
                object.angle = 0;
                object.sides = 0;
                object.cornerRadius = 0;
                object.size = 0;
                object.vectorId = "";
            }
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                object.enabled = message.enabled;
            if (message.drawShape != null && message.hasOwnProperty("drawShape"))
                object.drawShape = options.enums === String ? $root.api.DrawShape[message.drawShape] : message.drawShape;
            if (message.angle != null && message.hasOwnProperty("angle"))
                object.angle = options.json && !isFinite(message.angle) ? String(message.angle) : message.angle;
            if (message.sides != null && message.hasOwnProperty("sides"))
                object.sides = message.sides;
            if (message.cornerRadius != null && message.hasOwnProperty("cornerRadius"))
                object.cornerRadius = options.json && !isFinite(message.cornerRadius) ? String(message.cornerRadius) : message.cornerRadius;
            if (message.size != null && message.hasOwnProperty("size"))
                object.size = options.json && !isFinite(message.size) ? String(message.size) : message.size;
            if (message.vectorId != null && message.hasOwnProperty("vectorId"))
                object.vectorId = message.vectorId;
            return object;
        };

        /**
         * Converts this MaskEffect to JSON.
         * @function toJSON
         * @memberof api.MaskEffect
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MaskEffect.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MaskEffect;
    })();

    api.Effects = (function() {

        /**
         * Properties of an Effects.
         * @memberof api
         * @interface IEffects
         * @property {api.ILayerEffect|null} [layer] Effects layer
         * @property {api.IColorEffect|null} [color] Effects color
         * @property {api.IMaskEffect|null} [mask] Effects mask
         */

        /**
         * Constructs a new Effects.
         * @memberof api
         * @classdesc Represents an Effects.
         * @implements IEffects
         * @constructor
         * @param {api.IEffects=} [properties] Properties to set
         */
        function Effects(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Effects layer.
         * @member {api.ILayerEffect|null|undefined} layer
         * @memberof api.Effects
         * @instance
         */
        Effects.prototype.layer = null;

        /**
         * Effects color.
         * @member {api.IColorEffect|null|undefined} color
         * @memberof api.Effects
         * @instance
         */
        Effects.prototype.color = null;

        /**
         * Effects mask.
         * @member {api.IMaskEffect|null|undefined} mask
         * @memberof api.Effects
         * @instance
         */
        Effects.prototype.mask = null;

        /**
         * Creates a new Effects instance using the specified properties.
         * @function create
         * @memberof api.Effects
         * @static
         * @param {api.IEffects=} [properties] Properties to set
         * @returns {api.Effects} Effects instance
         */
        Effects.create = function create(properties) {
            return new Effects(properties);
        };

        /**
         * Encodes the specified Effects message. Does not implicitly {@link api.Effects.verify|verify} messages.
         * @function encode
         * @memberof api.Effects
         * @static
         * @param {api.IEffects} message Effects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Effects.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.layer != null && Object.hasOwnProperty.call(message, "layer"))
                $root.api.LayerEffect.encode(message.layer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                $root.api.ColorEffect.encode(message.color, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.mask != null && Object.hasOwnProperty.call(message, "mask"))
                $root.api.MaskEffect.encode(message.mask, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Effects message, length delimited. Does not implicitly {@link api.Effects.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Effects
         * @static
         * @param {api.IEffects} message Effects message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Effects.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Effects message from the specified reader or buffer.
         * @function decode
         * @memberof api.Effects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Effects} Effects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Effects.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Effects();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.layer = $root.api.LayerEffect.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.color = $root.api.ColorEffect.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.mask = $root.api.MaskEffect.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Effects message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Effects
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Effects} Effects
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Effects.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Effects message.
         * @function verify
         * @memberof api.Effects
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Effects.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.layer != null && message.hasOwnProperty("layer")) {
                let error = $root.api.LayerEffect.verify(message.layer);
                if (error)
                    return "layer." + error;
            }
            if (message.color != null && message.hasOwnProperty("color")) {
                let error = $root.api.ColorEffect.verify(message.color);
                if (error)
                    return "color." + error;
            }
            if (message.mask != null && message.hasOwnProperty("mask")) {
                let error = $root.api.MaskEffect.verify(message.mask);
                if (error)
                    return "mask." + error;
            }
            return null;
        };

        /**
         * Creates an Effects message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Effects
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Effects} Effects
         */
        Effects.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Effects)
                return object;
            let message = new $root.api.Effects();
            if (object.layer != null) {
                if (typeof object.layer !== "object")
                    throw TypeError(".api.Effects.layer: object expected");
                message.layer = $root.api.LayerEffect.fromObject(object.layer);
            }
            if (object.color != null) {
                if (typeof object.color !== "object")
                    throw TypeError(".api.Effects.color: object expected");
                message.color = $root.api.ColorEffect.fromObject(object.color);
            }
            if (object.mask != null) {
                if (typeof object.mask !== "object")
                    throw TypeError(".api.Effects.mask: object expected");
                message.mask = $root.api.MaskEffect.fromObject(object.mask);
            }
            return message;
        };

        /**
         * Creates a plain object from an Effects message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Effects
         * @static
         * @param {api.Effects} message Effects
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Effects.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.layer = null;
                object.color = null;
                object.mask = null;
            }
            if (message.layer != null && message.hasOwnProperty("layer"))
                object.layer = $root.api.LayerEffect.toObject(message.layer, options);
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = $root.api.ColorEffect.toObject(message.color, options);
            if (message.mask != null && message.hasOwnProperty("mask"))
                object.mask = $root.api.MaskEffect.toObject(message.mask, options);
            return object;
        };

        /**
         * Converts this Effects to JSON.
         * @function toJSON
         * @memberof api.Effects
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Effects.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Effects;
    })();

    /**
     * DrawShape enum.
     * @name api.DrawShape
     * @enum {number}
     * @property {number} Rectangle=0 Rectangle value
     * @property {number} Circle=1 Circle value
     * @property {number} Polygon=2 Polygon value
     * @property {number} Line=3 Line value
     * @property {number} Svg=4 Svg value
     */
    api.DrawShape = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Rectangle"] = 0;
        values[valuesById[1] = "Circle"] = 1;
        values[valuesById[2] = "Polygon"] = 2;
        values[valuesById[3] = "Line"] = 3;
        values[valuesById[4] = "Svg"] = 4;
        return values;
    })();

    api.Shape = (function() {

        /**
         * Properties of a Shape.
         * @memberof api
         * @interface IShape
         * @property {api.DrawShape|null} [drawShape] Shape drawShape
         * @property {number|null} [angle] Shape angle
         * @property {number|null} [sides] Shape sides
         * @property {number|null} [borderThickness] Shape borderThickness
         * @property {number|Long|null} [color] Shape color
         * @property {number|Long|null} [fillColor] Shape fillColor
         * @property {number|null} [cornerRadius] Shape cornerRadius
         * @property {string|null} [vectorId] Shape vectorId
         * @property {number|Long|null} [svgColor] Shape svgColor
         */

        /**
         * Constructs a new Shape.
         * @memberof api
         * @classdesc Represents a Shape.
         * @implements IShape
         * @constructor
         * @param {api.IShape=} [properties] Properties to set
         */
        function Shape(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Shape drawShape.
         * @member {api.DrawShape} drawShape
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.drawShape = 0;

        /**
         * Shape angle.
         * @member {number} angle
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.angle = 0;

        /**
         * Shape sides.
         * @member {number} sides
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.sides = 0;

        /**
         * Shape borderThickness.
         * @member {number} borderThickness
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.borderThickness = 0;

        /**
         * Shape color.
         * @member {number|Long} color
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.color = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Shape fillColor.
         * @member {number|Long} fillColor
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.fillColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Shape cornerRadius.
         * @member {number} cornerRadius
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.cornerRadius = 0;

        /**
         * Shape vectorId.
         * @member {string} vectorId
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.vectorId = "";

        /**
         * Shape svgColor.
         * @member {number|Long} svgColor
         * @memberof api.Shape
         * @instance
         */
        Shape.prototype.svgColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Shape instance using the specified properties.
         * @function create
         * @memberof api.Shape
         * @static
         * @param {api.IShape=} [properties] Properties to set
         * @returns {api.Shape} Shape instance
         */
        Shape.create = function create(properties) {
            return new Shape(properties);
        };

        /**
         * Encodes the specified Shape message. Does not implicitly {@link api.Shape.verify|verify} messages.
         * @function encode
         * @memberof api.Shape
         * @static
         * @param {api.IShape} message Shape message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Shape.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.drawShape != null && Object.hasOwnProperty.call(message, "drawShape"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.drawShape);
            if (message.angle != null && Object.hasOwnProperty.call(message, "angle"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.angle);
            if (message.sides != null && Object.hasOwnProperty.call(message, "sides"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sides);
            if (message.borderThickness != null && Object.hasOwnProperty.call(message, "borderThickness"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.borderThickness);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.color);
            if (message.fillColor != null && Object.hasOwnProperty.call(message, "fillColor"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.fillColor);
            if (message.cornerRadius != null && Object.hasOwnProperty.call(message, "cornerRadius"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.cornerRadius);
            if (message.vectorId != null && Object.hasOwnProperty.call(message, "vectorId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.vectorId);
            if (message.svgColor != null && Object.hasOwnProperty.call(message, "svgColor"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.svgColor);
            return writer;
        };

        /**
         * Encodes the specified Shape message, length delimited. Does not implicitly {@link api.Shape.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Shape
         * @static
         * @param {api.IShape} message Shape message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Shape.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Shape message from the specified reader or buffer.
         * @function decode
         * @memberof api.Shape
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Shape} Shape
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Shape.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Shape();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.drawShape = reader.int32();
                    break;
                case 2:
                    message.angle = reader.double();
                    break;
                case 3:
                    message.sides = reader.int32();
                    break;
                case 4:
                    message.borderThickness = reader.double();
                    break;
                case 5:
                    message.color = reader.int64();
                    break;
                case 6:
                    message.fillColor = reader.int64();
                    break;
                case 7:
                    message.cornerRadius = reader.double();
                    break;
                case 8:
                    message.vectorId = reader.string();
                    break;
                case 9:
                    message.svgColor = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Shape message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Shape
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Shape} Shape
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Shape.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Shape message.
         * @function verify
         * @memberof api.Shape
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Shape.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.drawShape != null && message.hasOwnProperty("drawShape"))
                switch (message.drawShape) {
                default:
                    return "drawShape: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.angle != null && message.hasOwnProperty("angle"))
                if (typeof message.angle !== "number")
                    return "angle: number expected";
            if (message.sides != null && message.hasOwnProperty("sides"))
                if (!$util.isInteger(message.sides))
                    return "sides: integer expected";
            if (message.borderThickness != null && message.hasOwnProperty("borderThickness"))
                if (typeof message.borderThickness !== "number")
                    return "borderThickness: number expected";
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isInteger(message.color) && !(message.color && $util.isInteger(message.color.low) && $util.isInteger(message.color.high)))
                    return "color: integer|Long expected";
            if (message.fillColor != null && message.hasOwnProperty("fillColor"))
                if (!$util.isInteger(message.fillColor) && !(message.fillColor && $util.isInteger(message.fillColor.low) && $util.isInteger(message.fillColor.high)))
                    return "fillColor: integer|Long expected";
            if (message.cornerRadius != null && message.hasOwnProperty("cornerRadius"))
                if (typeof message.cornerRadius !== "number")
                    return "cornerRadius: number expected";
            if (message.vectorId != null && message.hasOwnProperty("vectorId"))
                if (!$util.isString(message.vectorId))
                    return "vectorId: string expected";
            if (message.svgColor != null && message.hasOwnProperty("svgColor"))
                if (!$util.isInteger(message.svgColor) && !(message.svgColor && $util.isInteger(message.svgColor.low) && $util.isInteger(message.svgColor.high)))
                    return "svgColor: integer|Long expected";
            return null;
        };

        /**
         * Creates a Shape message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Shape
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Shape} Shape
         */
        Shape.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Shape)
                return object;
            let message = new $root.api.Shape();
            switch (object.drawShape) {
            case "Rectangle":
            case 0:
                message.drawShape = 0;
                break;
            case "Circle":
            case 1:
                message.drawShape = 1;
                break;
            case "Polygon":
            case 2:
                message.drawShape = 2;
                break;
            case "Line":
            case 3:
                message.drawShape = 3;
                break;
            case "Svg":
            case 4:
                message.drawShape = 4;
                break;
            }
            if (object.angle != null)
                message.angle = Number(object.angle);
            if (object.sides != null)
                message.sides = object.sides | 0;
            if (object.borderThickness != null)
                message.borderThickness = Number(object.borderThickness);
            if (object.color != null)
                if ($util.Long)
                    (message.color = $util.Long.fromValue(object.color)).unsigned = false;
                else if (typeof object.color === "string")
                    message.color = parseInt(object.color, 10);
                else if (typeof object.color === "number")
                    message.color = object.color;
                else if (typeof object.color === "object")
                    message.color = new $util.LongBits(object.color.low >>> 0, object.color.high >>> 0).toNumber();
            if (object.fillColor != null)
                if ($util.Long)
                    (message.fillColor = $util.Long.fromValue(object.fillColor)).unsigned = false;
                else if (typeof object.fillColor === "string")
                    message.fillColor = parseInt(object.fillColor, 10);
                else if (typeof object.fillColor === "number")
                    message.fillColor = object.fillColor;
                else if (typeof object.fillColor === "object")
                    message.fillColor = new $util.LongBits(object.fillColor.low >>> 0, object.fillColor.high >>> 0).toNumber();
            if (object.cornerRadius != null)
                message.cornerRadius = Number(object.cornerRadius);
            if (object.vectorId != null)
                message.vectorId = String(object.vectorId);
            if (object.svgColor != null)
                if ($util.Long)
                    (message.svgColor = $util.Long.fromValue(object.svgColor)).unsigned = false;
                else if (typeof object.svgColor === "string")
                    message.svgColor = parseInt(object.svgColor, 10);
                else if (typeof object.svgColor === "number")
                    message.svgColor = object.svgColor;
                else if (typeof object.svgColor === "object")
                    message.svgColor = new $util.LongBits(object.svgColor.low >>> 0, object.svgColor.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Shape message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Shape
         * @static
         * @param {api.Shape} message Shape
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Shape.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.drawShape = options.enums === String ? "Rectangle" : 0;
                object.angle = 0;
                object.sides = 0;
                object.borderThickness = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.color = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.color = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.fillColor = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fillColor = options.longs === String ? "0" : 0;
                object.cornerRadius = 0;
                object.vectorId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.svgColor = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.svgColor = options.longs === String ? "0" : 0;
            }
            if (message.drawShape != null && message.hasOwnProperty("drawShape"))
                object.drawShape = options.enums === String ? $root.api.DrawShape[message.drawShape] : message.drawShape;
            if (message.angle != null && message.hasOwnProperty("angle"))
                object.angle = options.json && !isFinite(message.angle) ? String(message.angle) : message.angle;
            if (message.sides != null && message.hasOwnProperty("sides"))
                object.sides = message.sides;
            if (message.borderThickness != null && message.hasOwnProperty("borderThickness"))
                object.borderThickness = options.json && !isFinite(message.borderThickness) ? String(message.borderThickness) : message.borderThickness;
            if (message.color != null && message.hasOwnProperty("color"))
                if (typeof message.color === "number")
                    object.color = options.longs === String ? String(message.color) : message.color;
                else
                    object.color = options.longs === String ? $util.Long.prototype.toString.call(message.color) : options.longs === Number ? new $util.LongBits(message.color.low >>> 0, message.color.high >>> 0).toNumber() : message.color;
            if (message.fillColor != null && message.hasOwnProperty("fillColor"))
                if (typeof message.fillColor === "number")
                    object.fillColor = options.longs === String ? String(message.fillColor) : message.fillColor;
                else
                    object.fillColor = options.longs === String ? $util.Long.prototype.toString.call(message.fillColor) : options.longs === Number ? new $util.LongBits(message.fillColor.low >>> 0, message.fillColor.high >>> 0).toNumber() : message.fillColor;
            if (message.cornerRadius != null && message.hasOwnProperty("cornerRadius"))
                object.cornerRadius = options.json && !isFinite(message.cornerRadius) ? String(message.cornerRadius) : message.cornerRadius;
            if (message.vectorId != null && message.hasOwnProperty("vectorId"))
                object.vectorId = message.vectorId;
            if (message.svgColor != null && message.hasOwnProperty("svgColor"))
                if (typeof message.svgColor === "number")
                    object.svgColor = options.longs === String ? String(message.svgColor) : message.svgColor;
                else
                    object.svgColor = options.longs === String ? $util.Long.prototype.toString.call(message.svgColor) : options.longs === Number ? new $util.LongBits(message.svgColor.low >>> 0, message.svgColor.high >>> 0).toNumber() : message.svgColor;
            return object;
        };

        /**
         * Converts this Shape to JSON.
         * @function toJSON
         * @memberof api.Shape
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Shape.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Shape;
    })();

    /**
     * GradientType enum.
     * @name api.GradientType
     * @enum {number}
     * @property {number} Linear=0 Linear value
     * @property {number} Radial=1 Radial value
     * @property {number} Angular=2 Angular value
     */
    api.GradientType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Linear"] = 0;
        values[valuesById[1] = "Radial"] = 1;
        values[valuesById[2] = "Angular"] = 2;
        return values;
    })();

    /**
     * InkType enum.
     * @name api.InkType
     * @enum {number}
     * @property {number} Gradient=0 Gradient value
     * @property {number} Image=1 Image value
     * @property {number} Solid=2 Solid value
     */
    api.InkType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Gradient"] = 0;
        values[valuesById[1] = "Image"] = 1;
        values[valuesById[2] = "Solid"] = 2;
        return values;
    })();

    api.Ink = (function() {

        /**
         * Properties of an Ink.
         * @memberof api
         * @interface IInk
         * @property {boolean|null} [enabled] Ink enabled
         * @property {api.GradientType|null} [gradientType] Ink gradientType
         * @property {Array.<number|Long>|null} [colors] Ink colors
         * @property {number|null} [angle] Ink angle
         * @property {number|null} [centerX] Ink centerX
         * @property {number|null} [centerY] Ink centerY
         * @property {string|null} [imageId] Ink imageId
         * @property {api.InkType|null} [inkType] Ink inkType
         * @property {number|null} [imageSize] Ink imageSize
         * @property {number|null} [imageOffsetX] Ink imageOffsetX
         * @property {number|null} [imageOffsetY] Ink imageOffsetY
         * @property {number|Long|null} [solidColor] Ink solidColor
         */

        /**
         * Constructs a new Ink.
         * @memberof api
         * @classdesc Represents an Ink.
         * @implements IInk
         * @constructor
         * @param {api.IInk=} [properties] Properties to set
         */
        function Ink(properties) {
            this.colors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ink enabled.
         * @member {boolean} enabled
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.enabled = false;

        /**
         * Ink gradientType.
         * @member {api.GradientType} gradientType
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.gradientType = 0;

        /**
         * Ink colors.
         * @member {Array.<number|Long>} colors
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.colors = $util.emptyArray;

        /**
         * Ink angle.
         * @member {number} angle
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.angle = 0;

        /**
         * Ink centerX.
         * @member {number} centerX
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.centerX = 0;

        /**
         * Ink centerY.
         * @member {number} centerY
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.centerY = 0;

        /**
         * Ink imageId.
         * @member {string} imageId
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.imageId = "";

        /**
         * Ink inkType.
         * @member {api.InkType} inkType
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.inkType = 0;

        /**
         * Ink imageSize.
         * @member {number} imageSize
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.imageSize = 0;

        /**
         * Ink imageOffsetX.
         * @member {number} imageOffsetX
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.imageOffsetX = 0;

        /**
         * Ink imageOffsetY.
         * @member {number} imageOffsetY
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.imageOffsetY = 0;

        /**
         * Ink solidColor.
         * @member {number|Long} solidColor
         * @memberof api.Ink
         * @instance
         */
        Ink.prototype.solidColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Ink instance using the specified properties.
         * @function create
         * @memberof api.Ink
         * @static
         * @param {api.IInk=} [properties] Properties to set
         * @returns {api.Ink} Ink instance
         */
        Ink.create = function create(properties) {
            return new Ink(properties);
        };

        /**
         * Encodes the specified Ink message. Does not implicitly {@link api.Ink.verify|verify} messages.
         * @function encode
         * @memberof api.Ink
         * @static
         * @param {api.IInk} message Ink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
            if (message.gradientType != null && Object.hasOwnProperty.call(message, "gradientType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.gradientType);
            if (message.colors != null && message.colors.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.colors.length; ++i)
                    writer.int64(message.colors[i]);
                writer.ldelim();
            }
            if (message.angle != null && Object.hasOwnProperty.call(message, "angle"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.angle);
            if (message.centerX != null && Object.hasOwnProperty.call(message, "centerX"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.centerX);
            if (message.centerY != null && Object.hasOwnProperty.call(message, "centerY"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.centerY);
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.imageId);
            if (message.inkType != null && Object.hasOwnProperty.call(message, "inkType"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.inkType);
            if (message.imageSize != null && Object.hasOwnProperty.call(message, "imageSize"))
                writer.uint32(/* id 9, wireType 1 =*/73).double(message.imageSize);
            if (message.imageOffsetX != null && Object.hasOwnProperty.call(message, "imageOffsetX"))
                writer.uint32(/* id 10, wireType 1 =*/81).double(message.imageOffsetX);
            if (message.imageOffsetY != null && Object.hasOwnProperty.call(message, "imageOffsetY"))
                writer.uint32(/* id 11, wireType 1 =*/89).double(message.imageOffsetY);
            if (message.solidColor != null && Object.hasOwnProperty.call(message, "solidColor"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.solidColor);
            return writer;
        };

        /**
         * Encodes the specified Ink message, length delimited. Does not implicitly {@link api.Ink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Ink
         * @static
         * @param {api.IInk} message Ink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Ink message from the specified reader or buffer.
         * @function decode
         * @memberof api.Ink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Ink} Ink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Ink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.enabled = reader.bool();
                    break;
                case 2:
                    message.gradientType = reader.int32();
                    break;
                case 3:
                    if (!(message.colors && message.colors.length))
                        message.colors = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.colors.push(reader.int64());
                    } else
                        message.colors.push(reader.int64());
                    break;
                case 4:
                    message.angle = reader.double();
                    break;
                case 5:
                    message.centerX = reader.double();
                    break;
                case 6:
                    message.centerY = reader.double();
                    break;
                case 7:
                    message.imageId = reader.string();
                    break;
                case 8:
                    message.inkType = reader.int32();
                    break;
                case 9:
                    message.imageSize = reader.double();
                    break;
                case 10:
                    message.imageOffsetX = reader.double();
                    break;
                case 11:
                    message.imageOffsetY = reader.double();
                    break;
                case 12:
                    message.solidColor = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Ink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Ink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Ink} Ink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Ink message.
         * @function verify
         * @memberof api.Ink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                if (typeof message.enabled !== "boolean")
                    return "enabled: boolean expected";
            if (message.gradientType != null && message.hasOwnProperty("gradientType"))
                switch (message.gradientType) {
                default:
                    return "gradientType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.colors != null && message.hasOwnProperty("colors")) {
                if (!Array.isArray(message.colors))
                    return "colors: array expected";
                for (let i = 0; i < message.colors.length; ++i)
                    if (!$util.isInteger(message.colors[i]) && !(message.colors[i] && $util.isInteger(message.colors[i].low) && $util.isInteger(message.colors[i].high)))
                        return "colors: integer|Long[] expected";
            }
            if (message.angle != null && message.hasOwnProperty("angle"))
                if (typeof message.angle !== "number")
                    return "angle: number expected";
            if (message.centerX != null && message.hasOwnProperty("centerX"))
                if (typeof message.centerX !== "number")
                    return "centerX: number expected";
            if (message.centerY != null && message.hasOwnProperty("centerY"))
                if (typeof message.centerY !== "number")
                    return "centerY: number expected";
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                if (!$util.isString(message.imageId))
                    return "imageId: string expected";
            if (message.inkType != null && message.hasOwnProperty("inkType"))
                switch (message.inkType) {
                default:
                    return "inkType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.imageSize != null && message.hasOwnProperty("imageSize"))
                if (typeof message.imageSize !== "number")
                    return "imageSize: number expected";
            if (message.imageOffsetX != null && message.hasOwnProperty("imageOffsetX"))
                if (typeof message.imageOffsetX !== "number")
                    return "imageOffsetX: number expected";
            if (message.imageOffsetY != null && message.hasOwnProperty("imageOffsetY"))
                if (typeof message.imageOffsetY !== "number")
                    return "imageOffsetY: number expected";
            if (message.solidColor != null && message.hasOwnProperty("solidColor"))
                if (!$util.isInteger(message.solidColor) && !(message.solidColor && $util.isInteger(message.solidColor.low) && $util.isInteger(message.solidColor.high)))
                    return "solidColor: integer|Long expected";
            return null;
        };

        /**
         * Creates an Ink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Ink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Ink} Ink
         */
        Ink.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Ink)
                return object;
            let message = new $root.api.Ink();
            if (object.enabled != null)
                message.enabled = Boolean(object.enabled);
            switch (object.gradientType) {
            case "Linear":
            case 0:
                message.gradientType = 0;
                break;
            case "Radial":
            case 1:
                message.gradientType = 1;
                break;
            case "Angular":
            case 2:
                message.gradientType = 2;
                break;
            }
            if (object.colors) {
                if (!Array.isArray(object.colors))
                    throw TypeError(".api.Ink.colors: array expected");
                message.colors = [];
                for (let i = 0; i < object.colors.length; ++i)
                    if ($util.Long)
                        (message.colors[i] = $util.Long.fromValue(object.colors[i])).unsigned = false;
                    else if (typeof object.colors[i] === "string")
                        message.colors[i] = parseInt(object.colors[i], 10);
                    else if (typeof object.colors[i] === "number")
                        message.colors[i] = object.colors[i];
                    else if (typeof object.colors[i] === "object")
                        message.colors[i] = new $util.LongBits(object.colors[i].low >>> 0, object.colors[i].high >>> 0).toNumber();
            }
            if (object.angle != null)
                message.angle = Number(object.angle);
            if (object.centerX != null)
                message.centerX = Number(object.centerX);
            if (object.centerY != null)
                message.centerY = Number(object.centerY);
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            switch (object.inkType) {
            case "Gradient":
            case 0:
                message.inkType = 0;
                break;
            case "Image":
            case 1:
                message.inkType = 1;
                break;
            case "Solid":
            case 2:
                message.inkType = 2;
                break;
            }
            if (object.imageSize != null)
                message.imageSize = Number(object.imageSize);
            if (object.imageOffsetX != null)
                message.imageOffsetX = Number(object.imageOffsetX);
            if (object.imageOffsetY != null)
                message.imageOffsetY = Number(object.imageOffsetY);
            if (object.solidColor != null)
                if ($util.Long)
                    (message.solidColor = $util.Long.fromValue(object.solidColor)).unsigned = false;
                else if (typeof object.solidColor === "string")
                    message.solidColor = parseInt(object.solidColor, 10);
                else if (typeof object.solidColor === "number")
                    message.solidColor = object.solidColor;
                else if (typeof object.solidColor === "object")
                    message.solidColor = new $util.LongBits(object.solidColor.low >>> 0, object.solidColor.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an Ink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Ink
         * @static
         * @param {api.Ink} message Ink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.colors = [];
            if (options.defaults) {
                object.enabled = false;
                object.gradientType = options.enums === String ? "Linear" : 0;
                object.angle = 0;
                object.centerX = 0;
                object.centerY = 0;
                object.imageId = "";
                object.inkType = options.enums === String ? "Gradient" : 0;
                object.imageSize = 0;
                object.imageOffsetX = 0;
                object.imageOffsetY = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.solidColor = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.solidColor = options.longs === String ? "0" : 0;
            }
            if (message.enabled != null && message.hasOwnProperty("enabled"))
                object.enabled = message.enabled;
            if (message.gradientType != null && message.hasOwnProperty("gradientType"))
                object.gradientType = options.enums === String ? $root.api.GradientType[message.gradientType] : message.gradientType;
            if (message.colors && message.colors.length) {
                object.colors = [];
                for (let j = 0; j < message.colors.length; ++j)
                    if (typeof message.colors[j] === "number")
                        object.colors[j] = options.longs === String ? String(message.colors[j]) : message.colors[j];
                    else
                        object.colors[j] = options.longs === String ? $util.Long.prototype.toString.call(message.colors[j]) : options.longs === Number ? new $util.LongBits(message.colors[j].low >>> 0, message.colors[j].high >>> 0).toNumber() : message.colors[j];
            }
            if (message.angle != null && message.hasOwnProperty("angle"))
                object.angle = options.json && !isFinite(message.angle) ? String(message.angle) : message.angle;
            if (message.centerX != null && message.hasOwnProperty("centerX"))
                object.centerX = options.json && !isFinite(message.centerX) ? String(message.centerX) : message.centerX;
            if (message.centerY != null && message.hasOwnProperty("centerY"))
                object.centerY = options.json && !isFinite(message.centerY) ? String(message.centerY) : message.centerY;
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            if (message.inkType != null && message.hasOwnProperty("inkType"))
                object.inkType = options.enums === String ? $root.api.InkType[message.inkType] : message.inkType;
            if (message.imageSize != null && message.hasOwnProperty("imageSize"))
                object.imageSize = options.json && !isFinite(message.imageSize) ? String(message.imageSize) : message.imageSize;
            if (message.imageOffsetX != null && message.hasOwnProperty("imageOffsetX"))
                object.imageOffsetX = options.json && !isFinite(message.imageOffsetX) ? String(message.imageOffsetX) : message.imageOffsetX;
            if (message.imageOffsetY != null && message.hasOwnProperty("imageOffsetY"))
                object.imageOffsetY = options.json && !isFinite(message.imageOffsetY) ? String(message.imageOffsetY) : message.imageOffsetY;
            if (message.solidColor != null && message.hasOwnProperty("solidColor"))
                if (typeof message.solidColor === "number")
                    object.solidColor = options.longs === String ? String(message.solidColor) : message.solidColor;
                else
                    object.solidColor = options.longs === String ? $util.Long.prototype.toString.call(message.solidColor) : options.longs === Number ? new $util.LongBits(message.solidColor.low >>> 0, message.solidColor.high >>> 0).toNumber() : message.solidColor;
            return object;
        };

        /**
         * Converts this Ink to JSON.
         * @function toJSON
         * @memberof api.Ink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Ink;
    })();

    api.GroupLayer = (function() {

        /**
         * Properties of a GroupLayer.
         * @memberof api
         * @interface IGroupLayer
         * @property {Array.<api.ILayer>|null} [layers] GroupLayer layers
         */

        /**
         * Constructs a new GroupLayer.
         * @memberof api
         * @classdesc Represents a GroupLayer.
         * @implements IGroupLayer
         * @constructor
         * @param {api.IGroupLayer=} [properties] Properties to set
         */
        function GroupLayer(properties) {
            this.layers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GroupLayer layers.
         * @member {Array.<api.ILayer>} layers
         * @memberof api.GroupLayer
         * @instance
         */
        GroupLayer.prototype.layers = $util.emptyArray;

        /**
         * Creates a new GroupLayer instance using the specified properties.
         * @function create
         * @memberof api.GroupLayer
         * @static
         * @param {api.IGroupLayer=} [properties] Properties to set
         * @returns {api.GroupLayer} GroupLayer instance
         */
        GroupLayer.create = function create(properties) {
            return new GroupLayer(properties);
        };

        /**
         * Encodes the specified GroupLayer message. Does not implicitly {@link api.GroupLayer.verify|verify} messages.
         * @function encode
         * @memberof api.GroupLayer
         * @static
         * @param {api.IGroupLayer} message GroupLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupLayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.layers != null && message.layers.length)
                for (let i = 0; i < message.layers.length; ++i)
                    $root.api.Layer.encode(message.layers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GroupLayer message, length delimited. Does not implicitly {@link api.GroupLayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GroupLayer
         * @static
         * @param {api.IGroupLayer} message GroupLayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupLayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GroupLayer message from the specified reader or buffer.
         * @function decode
         * @memberof api.GroupLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GroupLayer} GroupLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupLayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GroupLayer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.layers && message.layers.length))
                        message.layers = [];
                    message.layers.push($root.api.Layer.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GroupLayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GroupLayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GroupLayer} GroupLayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupLayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GroupLayer message.
         * @function verify
         * @memberof api.GroupLayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GroupLayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.layers != null && message.hasOwnProperty("layers")) {
                if (!Array.isArray(message.layers))
                    return "layers: array expected";
                for (let i = 0; i < message.layers.length; ++i) {
                    let error = $root.api.Layer.verify(message.layers[i]);
                    if (error)
                        return "layers." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GroupLayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GroupLayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GroupLayer} GroupLayer
         */
        GroupLayer.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GroupLayer)
                return object;
            let message = new $root.api.GroupLayer();
            if (object.layers) {
                if (!Array.isArray(object.layers))
                    throw TypeError(".api.GroupLayer.layers: array expected");
                message.layers = [];
                for (let i = 0; i < object.layers.length; ++i) {
                    if (typeof object.layers[i] !== "object")
                        throw TypeError(".api.GroupLayer.layers: object expected");
                    message.layers[i] = $root.api.Layer.fromObject(object.layers[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GroupLayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GroupLayer
         * @static
         * @param {api.GroupLayer} message GroupLayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GroupLayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.layers = [];
            if (message.layers && message.layers.length) {
                object.layers = [];
                for (let j = 0; j < message.layers.length; ++j)
                    object.layers[j] = $root.api.Layer.toObject(message.layers[j], options);
            }
            return object;
        };

        /**
         * Converts this GroupLayer to JSON.
         * @function toJSON
         * @memberof api.GroupLayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GroupLayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GroupLayer;
    })();

    /**
     * ImageFit enum.
     * @name api.ImageFit
     * @enum {number}
     * @property {number} Stretch=0 Stretch value
     * @property {number} Cover=1 Cover value
     * @property {number} Contain=2 Contain value
     * @property {number} Tile=3 Tile value
     */
    api.ImageFit = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Stretch"] = 0;
        values[valuesById[1] = "Cover"] = 1;
        values[valuesById[2] = "Contain"] = 2;
        values[valuesById[3] = "Tile"] = 3;
        return values;
    })();

    api.PatchRect = (function() {

        /**
         * Properties of a PatchRect.
         * @memberof api
         * @interface IPatchRect
         * @property {number|null} [left] PatchRect left
         * @property {number|null} [top] PatchRect top
         * @property {number|null} [right] PatchRect right
         * @property {number|null} [bottom] PatchRect bottom
         */

        /**
         * Constructs a new PatchRect.
         * @memberof api
         * @classdesc Represents a PatchRect.
         * @implements IPatchRect
         * @constructor
         * @param {api.IPatchRect=} [properties] Properties to set
         */
        function PatchRect(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PatchRect left.
         * @member {number} left
         * @memberof api.PatchRect
         * @instance
         */
        PatchRect.prototype.left = 0;

        /**
         * PatchRect top.
         * @member {number} top
         * @memberof api.PatchRect
         * @instance
         */
        PatchRect.prototype.top = 0;

        /**
         * PatchRect right.
         * @member {number} right
         * @memberof api.PatchRect
         * @instance
         */
        PatchRect.prototype.right = 0;

        /**
         * PatchRect bottom.
         * @member {number} bottom
         * @memberof api.PatchRect
         * @instance
         */
        PatchRect.prototype.bottom = 0;

        /**
         * Creates a new PatchRect instance using the specified properties.
         * @function create
         * @memberof api.PatchRect
         * @static
         * @param {api.IPatchRect=} [properties] Properties to set
         * @returns {api.PatchRect} PatchRect instance
         */
        PatchRect.create = function create(properties) {
            return new PatchRect(properties);
        };

        /**
         * Encodes the specified PatchRect message. Does not implicitly {@link api.PatchRect.verify|verify} messages.
         * @function encode
         * @memberof api.PatchRect
         * @static
         * @param {api.IPatchRect} message PatchRect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchRect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.left != null && Object.hasOwnProperty.call(message, "left"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.left);
            if (message.top != null && Object.hasOwnProperty.call(message, "top"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.top);
            if (message.right != null && Object.hasOwnProperty.call(message, "right"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.right);
            if (message.bottom != null && Object.hasOwnProperty.call(message, "bottom"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.bottom);
            return writer;
        };

        /**
         * Encodes the specified PatchRect message, length delimited. Does not implicitly {@link api.PatchRect.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PatchRect
         * @static
         * @param {api.IPatchRect} message PatchRect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchRect.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PatchRect message from the specified reader or buffer.
         * @function decode
         * @memberof api.PatchRect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PatchRect} PatchRect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchRect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PatchRect();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.left = reader.double();
                    break;
                case 2:
                    message.top = reader.double();
                    break;
                case 3:
                    message.right = reader.double();
                    break;
                case 4:
                    message.bottom = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PatchRect message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PatchRect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PatchRect} PatchRect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchRect.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PatchRect message.
         * @function verify
         * @memberof api.PatchRect
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PatchRect.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.left != null && message.hasOwnProperty("left"))
                if (typeof message.left !== "number")
                    return "left: number expected";
            if (message.top != null && message.hasOwnProperty("top"))
                if (typeof message.top !== "number")
                    return "top: number expected";
            if (message.right != null && message.hasOwnProperty("right"))
                if (typeof message.right !== "number")
                    return "right: number expected";
            if (message.bottom != null && message.hasOwnProperty("bottom"))
                if (typeof message.bottom !== "number")
                    return "bottom: number expected";
            return null;
        };

        /**
         * Creates a PatchRect message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PatchRect
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PatchRect} PatchRect
         */
        PatchRect.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PatchRect)
                return object;
            let message = new $root.api.PatchRect();
            if (object.left != null)
                message.left = Number(object.left);
            if (object.top != null)
                message.top = Number(object.top);
            if (object.right != null)
                message.right = Number(object.right);
            if (object.bottom != null)
                message.bottom = Number(object.bottom);
            return message;
        };

        /**
         * Creates a plain object from a PatchRect message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PatchRect
         * @static
         * @param {api.PatchRect} message PatchRect
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PatchRect.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.left = 0;
                object.top = 0;
                object.right = 0;
                object.bottom = 0;
            }
            if (message.left != null && message.hasOwnProperty("left"))
                object.left = options.json && !isFinite(message.left) ? String(message.left) : message.left;
            if (message.top != null && message.hasOwnProperty("top"))
                object.top = options.json && !isFinite(message.top) ? String(message.top) : message.top;
            if (message.right != null && message.hasOwnProperty("right"))
                object.right = options.json && !isFinite(message.right) ? String(message.right) : message.right;
            if (message.bottom != null && message.hasOwnProperty("bottom"))
                object.bottom = options.json && !isFinite(message.bottom) ? String(message.bottom) : message.bottom;
            return object;
        };

        /**
         * Converts this PatchRect to JSON.
         * @function toJSON
         * @memberof api.PatchRect
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PatchRect.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PatchRect;
    })();

    /**
     * TextDrawStyle enum.
     * @name api.TextDrawStyle
     * @enum {number}
     * @property {number} Normal=0 Normal value
     * @property {number} Outline=1 Outline value
     */
    api.TextDrawStyle = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Normal"] = 0;
        values[valuesById[1] = "Outline"] = 1;
        return values;
    })();

    api.Layer = (function() {

        /**
         * Properties of a Layer.
         * @memberof api
         * @interface ILayer
         * @property {string|null} [imageId] Layer imageId
         * @property {number|null} [x] Layer x
         * @property {number|null} [y] Layer y
         * @property {number|null} [rotate] Layer rotate
         * @property {number|null} [scaleX] Layer scaleX
         * @property {number|null} [scaleY] Layer scaleY
         * @property {boolean|null} [locked] Layer locked
         * @property {api.ITextBlock|null} [text] Layer text
         * @property {api.IEffects|null} [effects] Layer effects
         * @property {api.IShape|null} [shape] Layer shape
         * @property {api.IInk|null} [ink] Layer ink
         * @property {api.IGroupLayer|null} [group] Layer group
         * @property {api.ImageFit|null} [imageFit] Layer imageFit
         * @property {api.IPatchRect|null} [patchRect] Layer patchRect
         * @property {number|null} [imageSize] Layer imageSize
         * @property {number|null} [imageOffsetX] Layer imageOffsetX
         * @property {number|null} [imageOffsetY] Layer imageOffsetY
         */

        /**
         * Constructs a new Layer.
         * @memberof api
         * @classdesc Represents a Layer.
         * @implements ILayer
         * @constructor
         * @param {api.ILayer=} [properties] Properties to set
         */
        function Layer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Layer imageId.
         * @member {string} imageId
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.imageId = "";

        /**
         * Layer x.
         * @member {number} x
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.x = 0;

        /**
         * Layer y.
         * @member {number} y
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.y = 0;

        /**
         * Layer rotate.
         * @member {number} rotate
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.rotate = 0;

        /**
         * Layer scaleX.
         * @member {number} scaleX
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.scaleX = 0;

        /**
         * Layer scaleY.
         * @member {number} scaleY
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.scaleY = 0;

        /**
         * Layer locked.
         * @member {boolean} locked
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.locked = false;

        /**
         * Layer text.
         * @member {api.ITextBlock|null|undefined} text
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.text = null;

        /**
         * Layer effects.
         * @member {api.IEffects|null|undefined} effects
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.effects = null;

        /**
         * Layer shape.
         * @member {api.IShape|null|undefined} shape
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.shape = null;

        /**
         * Layer ink.
         * @member {api.IInk|null|undefined} ink
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.ink = null;

        /**
         * Layer group.
         * @member {api.IGroupLayer|null|undefined} group
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.group = null;

        /**
         * Layer imageFit.
         * @member {api.ImageFit} imageFit
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.imageFit = 0;

        /**
         * Layer patchRect.
         * @member {api.IPatchRect|null|undefined} patchRect
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.patchRect = null;

        /**
         * Layer imageSize.
         * @member {number} imageSize
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.imageSize = 0;

        /**
         * Layer imageOffsetX.
         * @member {number} imageOffsetX
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.imageOffsetX = 0;

        /**
         * Layer imageOffsetY.
         * @member {number} imageOffsetY
         * @memberof api.Layer
         * @instance
         */
        Layer.prototype.imageOffsetY = 0;

        /**
         * Creates a new Layer instance using the specified properties.
         * @function create
         * @memberof api.Layer
         * @static
         * @param {api.ILayer=} [properties] Properties to set
         * @returns {api.Layer} Layer instance
         */
        Layer.create = function create(properties) {
            return new Layer(properties);
        };

        /**
         * Encodes the specified Layer message. Does not implicitly {@link api.Layer.verify|verify} messages.
         * @function encode
         * @memberof api.Layer
         * @static
         * @param {api.ILayer} message Layer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Layer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageId);
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.y);
            if (message.rotate != null && Object.hasOwnProperty.call(message, "rotate"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.rotate);
            if (message.scaleX != null && Object.hasOwnProperty.call(message, "scaleX"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.scaleX);
            if (message.locked != null && Object.hasOwnProperty.call(message, "locked"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.locked);
            if (message.scaleY != null && Object.hasOwnProperty.call(message, "scaleY"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.scaleY);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                $root.api.TextBlock.encode(message.text, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.effects != null && Object.hasOwnProperty.call(message, "effects"))
                $root.api.Effects.encode(message.effects, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.shape != null && Object.hasOwnProperty.call(message, "shape"))
                $root.api.Shape.encode(message.shape, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.ink != null && Object.hasOwnProperty.call(message, "ink"))
                $root.api.Ink.encode(message.ink, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupLayer.encode(message.group, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.imageFit != null && Object.hasOwnProperty.call(message, "imageFit"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.imageFit);
            if (message.patchRect != null && Object.hasOwnProperty.call(message, "patchRect"))
                $root.api.PatchRect.encode(message.patchRect, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.imageSize != null && Object.hasOwnProperty.call(message, "imageSize"))
                writer.uint32(/* id 15, wireType 1 =*/121).double(message.imageSize);
            if (message.imageOffsetX != null && Object.hasOwnProperty.call(message, "imageOffsetX"))
                writer.uint32(/* id 16, wireType 1 =*/129).double(message.imageOffsetX);
            if (message.imageOffsetY != null && Object.hasOwnProperty.call(message, "imageOffsetY"))
                writer.uint32(/* id 17, wireType 1 =*/137).double(message.imageOffsetY);
            return writer;
        };

        /**
         * Encodes the specified Layer message, length delimited. Does not implicitly {@link api.Layer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Layer
         * @static
         * @param {api.ILayer} message Layer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Layer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Layer message from the specified reader or buffer.
         * @function decode
         * @memberof api.Layer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Layer} Layer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Layer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Layer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.imageId = reader.string();
                    break;
                case 2:
                    message.x = reader.double();
                    break;
                case 3:
                    message.y = reader.double();
                    break;
                case 4:
                    message.rotate = reader.double();
                    break;
                case 5:
                    message.scaleX = reader.double();
                    break;
                case 7:
                    message.scaleY = reader.double();
                    break;
                case 6:
                    message.locked = reader.bool();
                    break;
                case 8:
                    message.text = $root.api.TextBlock.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.effects = $root.api.Effects.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.shape = $root.api.Shape.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.ink = $root.api.Ink.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.group = $root.api.GroupLayer.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.imageFit = reader.int32();
                    break;
                case 14:
                    message.patchRect = $root.api.PatchRect.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.imageSize = reader.double();
                    break;
                case 16:
                    message.imageOffsetX = reader.double();
                    break;
                case 17:
                    message.imageOffsetY = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Layer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Layer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Layer} Layer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Layer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Layer message.
         * @function verify
         * @memberof api.Layer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Layer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                if (!$util.isString(message.imageId))
                    return "imageId: string expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (typeof message.y !== "number")
                    return "y: number expected";
            if (message.rotate != null && message.hasOwnProperty("rotate"))
                if (typeof message.rotate !== "number")
                    return "rotate: number expected";
            if (message.scaleX != null && message.hasOwnProperty("scaleX"))
                if (typeof message.scaleX !== "number")
                    return "scaleX: number expected";
            if (message.scaleY != null && message.hasOwnProperty("scaleY"))
                if (typeof message.scaleY !== "number")
                    return "scaleY: number expected";
            if (message.locked != null && message.hasOwnProperty("locked"))
                if (typeof message.locked !== "boolean")
                    return "locked: boolean expected";
            if (message.text != null && message.hasOwnProperty("text")) {
                let error = $root.api.TextBlock.verify(message.text);
                if (error)
                    return "text." + error;
            }
            if (message.effects != null && message.hasOwnProperty("effects")) {
                let error = $root.api.Effects.verify(message.effects);
                if (error)
                    return "effects." + error;
            }
            if (message.shape != null && message.hasOwnProperty("shape")) {
                let error = $root.api.Shape.verify(message.shape);
                if (error)
                    return "shape." + error;
            }
            if (message.ink != null && message.hasOwnProperty("ink")) {
                let error = $root.api.Ink.verify(message.ink);
                if (error)
                    return "ink." + error;
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.GroupLayer.verify(message.group);
                if (error)
                    return "group." + error;
            }
            if (message.imageFit != null && message.hasOwnProperty("imageFit"))
                switch (message.imageFit) {
                default:
                    return "imageFit: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.patchRect != null && message.hasOwnProperty("patchRect")) {
                let error = $root.api.PatchRect.verify(message.patchRect);
                if (error)
                    return "patchRect." + error;
            }
            if (message.imageSize != null && message.hasOwnProperty("imageSize"))
                if (typeof message.imageSize !== "number")
                    return "imageSize: number expected";
            if (message.imageOffsetX != null && message.hasOwnProperty("imageOffsetX"))
                if (typeof message.imageOffsetX !== "number")
                    return "imageOffsetX: number expected";
            if (message.imageOffsetY != null && message.hasOwnProperty("imageOffsetY"))
                if (typeof message.imageOffsetY !== "number")
                    return "imageOffsetY: number expected";
            return null;
        };

        /**
         * Creates a Layer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Layer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Layer} Layer
         */
        Layer.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Layer)
                return object;
            let message = new $root.api.Layer();
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            if (object.x != null)
                message.x = Number(object.x);
            if (object.y != null)
                message.y = Number(object.y);
            if (object.rotate != null)
                message.rotate = Number(object.rotate);
            if (object.scaleX != null)
                message.scaleX = Number(object.scaleX);
            if (object.scaleY != null)
                message.scaleY = Number(object.scaleY);
            if (object.locked != null)
                message.locked = Boolean(object.locked);
            if (object.text != null) {
                if (typeof object.text !== "object")
                    throw TypeError(".api.Layer.text: object expected");
                message.text = $root.api.TextBlock.fromObject(object.text);
            }
            if (object.effects != null) {
                if (typeof object.effects !== "object")
                    throw TypeError(".api.Layer.effects: object expected");
                message.effects = $root.api.Effects.fromObject(object.effects);
            }
            if (object.shape != null) {
                if (typeof object.shape !== "object")
                    throw TypeError(".api.Layer.shape: object expected");
                message.shape = $root.api.Shape.fromObject(object.shape);
            }
            if (object.ink != null) {
                if (typeof object.ink !== "object")
                    throw TypeError(".api.Layer.ink: object expected");
                message.ink = $root.api.Ink.fromObject(object.ink);
            }
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.Layer.group: object expected");
                message.group = $root.api.GroupLayer.fromObject(object.group);
            }
            switch (object.imageFit) {
            case "Stretch":
            case 0:
                message.imageFit = 0;
                break;
            case "Cover":
            case 1:
                message.imageFit = 1;
                break;
            case "Contain":
            case 2:
                message.imageFit = 2;
                break;
            case "Tile":
            case 3:
                message.imageFit = 3;
                break;
            }
            if (object.patchRect != null) {
                if (typeof object.patchRect !== "object")
                    throw TypeError(".api.Layer.patchRect: object expected");
                message.patchRect = $root.api.PatchRect.fromObject(object.patchRect);
            }
            if (object.imageSize != null)
                message.imageSize = Number(object.imageSize);
            if (object.imageOffsetX != null)
                message.imageOffsetX = Number(object.imageOffsetX);
            if (object.imageOffsetY != null)
                message.imageOffsetY = Number(object.imageOffsetY);
            return message;
        };

        /**
         * Creates a plain object from a Layer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Layer
         * @static
         * @param {api.Layer} message Layer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Layer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.imageId = "";
                object.x = 0;
                object.y = 0;
                object.rotate = 0;
                object.scaleX = 0;
                object.locked = false;
                object.scaleY = 0;
                object.text = null;
                object.effects = null;
                object.shape = null;
                object.ink = null;
                object.group = null;
                object.imageFit = options.enums === String ? "Stretch" : 0;
                object.patchRect = null;
                object.imageSize = 0;
                object.imageOffsetX = 0;
                object.imageOffsetY = 0;
            }
            if (message.imageId != null && message.hasOwnProperty("imageId"))
                object.imageId = message.imageId;
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
            if (message.rotate != null && message.hasOwnProperty("rotate"))
                object.rotate = options.json && !isFinite(message.rotate) ? String(message.rotate) : message.rotate;
            if (message.scaleX != null && message.hasOwnProperty("scaleX"))
                object.scaleX = options.json && !isFinite(message.scaleX) ? String(message.scaleX) : message.scaleX;
            if (message.locked != null && message.hasOwnProperty("locked"))
                object.locked = message.locked;
            if (message.scaleY != null && message.hasOwnProperty("scaleY"))
                object.scaleY = options.json && !isFinite(message.scaleY) ? String(message.scaleY) : message.scaleY;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = $root.api.TextBlock.toObject(message.text, options);
            if (message.effects != null && message.hasOwnProperty("effects"))
                object.effects = $root.api.Effects.toObject(message.effects, options);
            if (message.shape != null && message.hasOwnProperty("shape"))
                object.shape = $root.api.Shape.toObject(message.shape, options);
            if (message.ink != null && message.hasOwnProperty("ink"))
                object.ink = $root.api.Ink.toObject(message.ink, options);
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.GroupLayer.toObject(message.group, options);
            if (message.imageFit != null && message.hasOwnProperty("imageFit"))
                object.imageFit = options.enums === String ? $root.api.ImageFit[message.imageFit] : message.imageFit;
            if (message.patchRect != null && message.hasOwnProperty("patchRect"))
                object.patchRect = $root.api.PatchRect.toObject(message.patchRect, options);
            if (message.imageSize != null && message.hasOwnProperty("imageSize"))
                object.imageSize = options.json && !isFinite(message.imageSize) ? String(message.imageSize) : message.imageSize;
            if (message.imageOffsetX != null && message.hasOwnProperty("imageOffsetX"))
                object.imageOffsetX = options.json && !isFinite(message.imageOffsetX) ? String(message.imageOffsetX) : message.imageOffsetX;
            if (message.imageOffsetY != null && message.hasOwnProperty("imageOffsetY"))
                object.imageOffsetY = options.json && !isFinite(message.imageOffsetY) ? String(message.imageOffsetY) : message.imageOffsetY;
            return object;
        };

        /**
         * Converts this Layer to JSON.
         * @function toJSON
         * @memberof api.Layer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Layer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Layer;
    })();

    api.Design = (function() {

        /**
         * Properties of a Design.
         * @memberof api
         * @interface IDesign
         * @property {number|Long|null} [width] Design width
         * @property {number|Long|null} [height] Design height
         * @property {Array.<api.ILayer>|null} [layers] Design layers
         * @property {number|Long|null} [backgroundColor] Design backgroundColor
         * @property {string|null} [backgroundImageId] Design backgroundImageId
         */

        /**
         * Constructs a new Design.
         * @memberof api
         * @classdesc Represents a Design.
         * @implements IDesign
         * @constructor
         * @param {api.IDesign=} [properties] Properties to set
         */
        function Design(properties) {
            this.layers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Design width.
         * @member {number|Long} width
         * @memberof api.Design
         * @instance
         */
        Design.prototype.width = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Design height.
         * @member {number|Long} height
         * @memberof api.Design
         * @instance
         */
        Design.prototype.height = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Design layers.
         * @member {Array.<api.ILayer>} layers
         * @memberof api.Design
         * @instance
         */
        Design.prototype.layers = $util.emptyArray;

        /**
         * Design backgroundColor.
         * @member {number|Long} backgroundColor
         * @memberof api.Design
         * @instance
         */
        Design.prototype.backgroundColor = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Design backgroundImageId.
         * @member {string} backgroundImageId
         * @memberof api.Design
         * @instance
         */
        Design.prototype.backgroundImageId = "";

        /**
         * Creates a new Design instance using the specified properties.
         * @function create
         * @memberof api.Design
         * @static
         * @param {api.IDesign=} [properties] Properties to set
         * @returns {api.Design} Design instance
         */
        Design.create = function create(properties) {
            return new Design(properties);
        };

        /**
         * Encodes the specified Design message. Does not implicitly {@link api.Design.verify|verify} messages.
         * @function encode
         * @memberof api.Design
         * @static
         * @param {api.IDesign} message Design message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Design.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.height);
            if (message.layers != null && message.layers.length)
                for (let i = 0; i < message.layers.length; ++i)
                    $root.api.Layer.encode(message.layers[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.backgroundColor);
            if (message.backgroundImageId != null && Object.hasOwnProperty.call(message, "backgroundImageId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.backgroundImageId);
            return writer;
        };

        /**
         * Encodes the specified Design message, length delimited. Does not implicitly {@link api.Design.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Design
         * @static
         * @param {api.IDesign} message Design message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Design.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Design message from the specified reader or buffer.
         * @function decode
         * @memberof api.Design
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Design} Design
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Design.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Design();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.width = reader.uint64();
                    break;
                case 2:
                    message.height = reader.uint64();
                    break;
                case 3:
                    if (!(message.layers && message.layers.length))
                        message.layers = [];
                    message.layers.push($root.api.Layer.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.backgroundColor = reader.int64();
                    break;
                case 5:
                    message.backgroundImageId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Design message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Design
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Design} Design
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Design.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Design message.
         * @function verify
         * @memberof api.Design
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Design.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width) && !(message.width && $util.isInteger(message.width.low) && $util.isInteger(message.width.high)))
                    return "width: integer|Long expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height) && !(message.height && $util.isInteger(message.height.low) && $util.isInteger(message.height.high)))
                    return "height: integer|Long expected";
            if (message.layers != null && message.hasOwnProperty("layers")) {
                if (!Array.isArray(message.layers))
                    return "layers: array expected";
                for (let i = 0; i < message.layers.length; ++i) {
                    let error = $root.api.Layer.verify(message.layers[i]);
                    if (error)
                        return "layers." + error;
                }
            }
            if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
                if (!$util.isInteger(message.backgroundColor) && !(message.backgroundColor && $util.isInteger(message.backgroundColor.low) && $util.isInteger(message.backgroundColor.high)))
                    return "backgroundColor: integer|Long expected";
            if (message.backgroundImageId != null && message.hasOwnProperty("backgroundImageId"))
                if (!$util.isString(message.backgroundImageId))
                    return "backgroundImageId: string expected";
            return null;
        };

        /**
         * Creates a Design message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Design
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Design} Design
         */
        Design.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Design)
                return object;
            let message = new $root.api.Design();
            if (object.width != null)
                if ($util.Long)
                    (message.width = $util.Long.fromValue(object.width)).unsigned = true;
                else if (typeof object.width === "string")
                    message.width = parseInt(object.width, 10);
                else if (typeof object.width === "number")
                    message.width = object.width;
                else if (typeof object.width === "object")
                    message.width = new $util.LongBits(object.width.low >>> 0, object.width.high >>> 0).toNumber(true);
            if (object.height != null)
                if ($util.Long)
                    (message.height = $util.Long.fromValue(object.height)).unsigned = true;
                else if (typeof object.height === "string")
                    message.height = parseInt(object.height, 10);
                else if (typeof object.height === "number")
                    message.height = object.height;
                else if (typeof object.height === "object")
                    message.height = new $util.LongBits(object.height.low >>> 0, object.height.high >>> 0).toNumber(true);
            if (object.layers) {
                if (!Array.isArray(object.layers))
                    throw TypeError(".api.Design.layers: array expected");
                message.layers = [];
                for (let i = 0; i < object.layers.length; ++i) {
                    if (typeof object.layers[i] !== "object")
                        throw TypeError(".api.Design.layers: object expected");
                    message.layers[i] = $root.api.Layer.fromObject(object.layers[i]);
                }
            }
            if (object.backgroundColor != null)
                if ($util.Long)
                    (message.backgroundColor = $util.Long.fromValue(object.backgroundColor)).unsigned = false;
                else if (typeof object.backgroundColor === "string")
                    message.backgroundColor = parseInt(object.backgroundColor, 10);
                else if (typeof object.backgroundColor === "number")
                    message.backgroundColor = object.backgroundColor;
                else if (typeof object.backgroundColor === "object")
                    message.backgroundColor = new $util.LongBits(object.backgroundColor.low >>> 0, object.backgroundColor.high >>> 0).toNumber();
            if (object.backgroundImageId != null)
                message.backgroundImageId = String(object.backgroundImageId);
            return message;
        };

        /**
         * Creates a plain object from a Design message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Design
         * @static
         * @param {api.Design} message Design
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Design.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.layers = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.width = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.width = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.height = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.height = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.backgroundColor = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.backgroundColor = options.longs === String ? "0" : 0;
                object.backgroundImageId = "";
            }
            if (message.width != null && message.hasOwnProperty("width"))
                if (typeof message.width === "number")
                    object.width = options.longs === String ? String(message.width) : message.width;
                else
                    object.width = options.longs === String ? $util.Long.prototype.toString.call(message.width) : options.longs === Number ? new $util.LongBits(message.width.low >>> 0, message.width.high >>> 0).toNumber(true) : message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                if (typeof message.height === "number")
                    object.height = options.longs === String ? String(message.height) : message.height;
                else
                    object.height = options.longs === String ? $util.Long.prototype.toString.call(message.height) : options.longs === Number ? new $util.LongBits(message.height.low >>> 0, message.height.high >>> 0).toNumber(true) : message.height;
            if (message.layers && message.layers.length) {
                object.layers = [];
                for (let j = 0; j < message.layers.length; ++j)
                    object.layers[j] = $root.api.Layer.toObject(message.layers[j], options);
            }
            if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
                if (typeof message.backgroundColor === "number")
                    object.backgroundColor = options.longs === String ? String(message.backgroundColor) : message.backgroundColor;
                else
                    object.backgroundColor = options.longs === String ? $util.Long.prototype.toString.call(message.backgroundColor) : options.longs === Number ? new $util.LongBits(message.backgroundColor.low >>> 0, message.backgroundColor.high >>> 0).toNumber() : message.backgroundColor;
            if (message.backgroundImageId != null && message.hasOwnProperty("backgroundImageId"))
                object.backgroundImageId = message.backgroundImageId;
            return object;
        };

        /**
         * Converts this Design to JSON.
         * @function toJSON
         * @memberof api.Design
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Design.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Design;
    })();

    api.GeneratedImageMetadata = (function() {

        /**
         * Properties of a GeneratedImageMetadata.
         * @memberof api
         * @interface IGeneratedImageMetadata
         * @property {Object.<string,string>|null} [parameters] GeneratedImageMetadata parameters
         * @property {api.IDesign|null} [design] GeneratedImageMetadata design
         */

        /**
         * Constructs a new GeneratedImageMetadata.
         * @memberof api
         * @classdesc Represents a GeneratedImageMetadata.
         * @implements IGeneratedImageMetadata
         * @constructor
         * @param {api.IGeneratedImageMetadata=} [properties] Properties to set
         */
        function GeneratedImageMetadata(properties) {
            this.parameters = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GeneratedImageMetadata parameters.
         * @member {Object.<string,string>} parameters
         * @memberof api.GeneratedImageMetadata
         * @instance
         */
        GeneratedImageMetadata.prototype.parameters = $util.emptyObject;

        /**
         * GeneratedImageMetadata design.
         * @member {api.IDesign|null|undefined} design
         * @memberof api.GeneratedImageMetadata
         * @instance
         */
        GeneratedImageMetadata.prototype.design = null;

        /**
         * Creates a new GeneratedImageMetadata instance using the specified properties.
         * @function create
         * @memberof api.GeneratedImageMetadata
         * @static
         * @param {api.IGeneratedImageMetadata=} [properties] Properties to set
         * @returns {api.GeneratedImageMetadata} GeneratedImageMetadata instance
         */
        GeneratedImageMetadata.create = function create(properties) {
            return new GeneratedImageMetadata(properties);
        };

        /**
         * Encodes the specified GeneratedImageMetadata message. Does not implicitly {@link api.GeneratedImageMetadata.verify|verify} messages.
         * @function encode
         * @memberof api.GeneratedImageMetadata
         * @static
         * @param {api.IGeneratedImageMetadata} message GeneratedImageMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImageMetadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parameters != null && Object.hasOwnProperty.call(message, "parameters"))
                for (let keys = Object.keys(message.parameters), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.parameters[keys[i]]).ldelim();
            if (message.design != null && Object.hasOwnProperty.call(message, "design"))
                $root.api.Design.encode(message.design, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GeneratedImageMetadata message, length delimited. Does not implicitly {@link api.GeneratedImageMetadata.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GeneratedImageMetadata
         * @static
         * @param {api.IGeneratedImageMetadata} message GeneratedImageMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeneratedImageMetadata.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GeneratedImageMetadata message from the specified reader or buffer.
         * @function decode
         * @memberof api.GeneratedImageMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GeneratedImageMetadata} GeneratedImageMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImageMetadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GeneratedImageMetadata(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (message.parameters === $util.emptyObject)
                        message.parameters = {};
                    let end2 = reader.uint32() + reader.pos;
                    key = "";
                    value = "";
                    while (reader.pos < end2) {
                        let tag2 = reader.uint32();
                        switch (tag2 >>> 3) {
                        case 1:
                            key = reader.string();
                            break;
                        case 2:
                            value = reader.string();
                            break;
                        default:
                            reader.skipType(tag2 & 7);
                            break;
                        }
                    }
                    message.parameters[key] = value;
                    break;
                case 2:
                    message.design = $root.api.Design.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GeneratedImageMetadata message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GeneratedImageMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GeneratedImageMetadata} GeneratedImageMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeneratedImageMetadata.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GeneratedImageMetadata message.
         * @function verify
         * @memberof api.GeneratedImageMetadata
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GeneratedImageMetadata.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.parameters != null && message.hasOwnProperty("parameters")) {
                if (!$util.isObject(message.parameters))
                    return "parameters: object expected";
                let key = Object.keys(message.parameters);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.parameters[key[i]]))
                        return "parameters: string{k:string} expected";
            }
            if (message.design != null && message.hasOwnProperty("design")) {
                let error = $root.api.Design.verify(message.design);
                if (error)
                    return "design." + error;
            }
            return null;
        };

        /**
         * Creates a GeneratedImageMetadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GeneratedImageMetadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GeneratedImageMetadata} GeneratedImageMetadata
         */
        GeneratedImageMetadata.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GeneratedImageMetadata)
                return object;
            let message = new $root.api.GeneratedImageMetadata();
            if (object.parameters) {
                if (typeof object.parameters !== "object")
                    throw TypeError(".api.GeneratedImageMetadata.parameters: object expected");
                message.parameters = {};
                for (let keys = Object.keys(object.parameters), i = 0; i < keys.length; ++i)
                    message.parameters[keys[i]] = String(object.parameters[keys[i]]);
            }
            if (object.design != null) {
                if (typeof object.design !== "object")
                    throw TypeError(".api.GeneratedImageMetadata.design: object expected");
                message.design = $root.api.Design.fromObject(object.design);
            }
            return message;
        };

        /**
         * Creates a plain object from a GeneratedImageMetadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GeneratedImageMetadata
         * @static
         * @param {api.GeneratedImageMetadata} message GeneratedImageMetadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GeneratedImageMetadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.parameters = {};
            if (options.defaults)
                object.design = null;
            let keys2;
            if (message.parameters && (keys2 = Object.keys(message.parameters)).length) {
                object.parameters = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.parameters[keys2[j]] = message.parameters[keys2[j]];
            }
            if (message.design != null && message.hasOwnProperty("design"))
                object.design = $root.api.Design.toObject(message.design, options);
            return object;
        };

        /**
         * Converts this GeneratedImageMetadata to JSON.
         * @function toJSON
         * @memberof api.GeneratedImageMetadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GeneratedImageMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GeneratedImageMetadata;
    })();

    api.ImageEmbedding = (function() {

        /**
         * Properties of an ImageEmbedding.
         * @memberof api
         * @interface IImageEmbedding
         * @property {Array.<number>|null} [vector] ImageEmbedding vector
         */

        /**
         * Constructs a new ImageEmbedding.
         * @memberof api
         * @classdesc Represents an ImageEmbedding.
         * @implements IImageEmbedding
         * @constructor
         * @param {api.IImageEmbedding=} [properties] Properties to set
         */
        function ImageEmbedding(properties) {
            this.vector = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageEmbedding vector.
         * @member {Array.<number>} vector
         * @memberof api.ImageEmbedding
         * @instance
         */
        ImageEmbedding.prototype.vector = $util.emptyArray;

        /**
         * Creates a new ImageEmbedding instance using the specified properties.
         * @function create
         * @memberof api.ImageEmbedding
         * @static
         * @param {api.IImageEmbedding=} [properties] Properties to set
         * @returns {api.ImageEmbedding} ImageEmbedding instance
         */
        ImageEmbedding.create = function create(properties) {
            return new ImageEmbedding(properties);
        };

        /**
         * Encodes the specified ImageEmbedding message. Does not implicitly {@link api.ImageEmbedding.verify|verify} messages.
         * @function encode
         * @memberof api.ImageEmbedding
         * @static
         * @param {api.IImageEmbedding} message ImageEmbedding message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageEmbedding.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.vector != null && message.vector.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.vector.length; ++i)
                    writer.float(message.vector[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ImageEmbedding message, length delimited. Does not implicitly {@link api.ImageEmbedding.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ImageEmbedding
         * @static
         * @param {api.IImageEmbedding} message ImageEmbedding message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageEmbedding.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageEmbedding message from the specified reader or buffer.
         * @function decode
         * @memberof api.ImageEmbedding
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ImageEmbedding} ImageEmbedding
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageEmbedding.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ImageEmbedding();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.vector && message.vector.length))
                        message.vector = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.vector.push(reader.float());
                    } else
                        message.vector.push(reader.float());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageEmbedding message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ImageEmbedding
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ImageEmbedding} ImageEmbedding
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageEmbedding.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageEmbedding message.
         * @function verify
         * @memberof api.ImageEmbedding
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageEmbedding.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.vector != null && message.hasOwnProperty("vector")) {
                if (!Array.isArray(message.vector))
                    return "vector: array expected";
                for (let i = 0; i < message.vector.length; ++i)
                    if (typeof message.vector[i] !== "number")
                        return "vector: number[] expected";
            }
            return null;
        };

        /**
         * Creates an ImageEmbedding message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ImageEmbedding
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ImageEmbedding} ImageEmbedding
         */
        ImageEmbedding.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ImageEmbedding)
                return object;
            let message = new $root.api.ImageEmbedding();
            if (object.vector) {
                if (!Array.isArray(object.vector))
                    throw TypeError(".api.ImageEmbedding.vector: array expected");
                message.vector = [];
                for (let i = 0; i < object.vector.length; ++i)
                    message.vector[i] = Number(object.vector[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImageEmbedding message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ImageEmbedding
         * @static
         * @param {api.ImageEmbedding} message ImageEmbedding
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageEmbedding.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.vector = [];
            if (message.vector && message.vector.length) {
                object.vector = [];
                for (let j = 0; j < message.vector.length; ++j)
                    object.vector[j] = options.json && !isFinite(message.vector[j]) ? String(message.vector[j]) : message.vector[j];
            }
            return object;
        };

        /**
         * Converts this ImageEmbedding to JSON.
         * @function toJSON
         * @memberof api.ImageEmbedding
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageEmbedding.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImageEmbedding;
    })();

    api.SearchMatch = (function() {

        /**
         * Properties of a SearchMatch.
         * @memberof api
         * @interface ISearchMatch
         * @property {string|null} [id] SearchMatch id
         * @property {number|null} [score] SearchMatch score
         */

        /**
         * Constructs a new SearchMatch.
         * @memberof api
         * @classdesc Represents a SearchMatch.
         * @implements ISearchMatch
         * @constructor
         * @param {api.ISearchMatch=} [properties] Properties to set
         */
        function SearchMatch(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchMatch id.
         * @member {string} id
         * @memberof api.SearchMatch
         * @instance
         */
        SearchMatch.prototype.id = "";

        /**
         * SearchMatch score.
         * @member {number} score
         * @memberof api.SearchMatch
         * @instance
         */
        SearchMatch.prototype.score = 0;

        /**
         * Creates a new SearchMatch instance using the specified properties.
         * @function create
         * @memberof api.SearchMatch
         * @static
         * @param {api.ISearchMatch=} [properties] Properties to set
         * @returns {api.SearchMatch} SearchMatch instance
         */
        SearchMatch.create = function create(properties) {
            return new SearchMatch(properties);
        };

        /**
         * Encodes the specified SearchMatch message. Does not implicitly {@link api.SearchMatch.verify|verify} messages.
         * @function encode
         * @memberof api.SearchMatch
         * @static
         * @param {api.ISearchMatch} message SearchMatch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchMatch.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.score);
            return writer;
        };

        /**
         * Encodes the specified SearchMatch message, length delimited. Does not implicitly {@link api.SearchMatch.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SearchMatch
         * @static
         * @param {api.ISearchMatch} message SearchMatch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchMatch.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SearchMatch message from the specified reader or buffer.
         * @function decode
         * @memberof api.SearchMatch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SearchMatch} SearchMatch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchMatch.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SearchMatch();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.score = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SearchMatch message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SearchMatch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SearchMatch} SearchMatch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchMatch.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SearchMatch message.
         * @function verify
         * @memberof api.SearchMatch
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SearchMatch.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.score != null && message.hasOwnProperty("score"))
                if (typeof message.score !== "number")
                    return "score: number expected";
            return null;
        };

        /**
         * Creates a SearchMatch message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SearchMatch
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SearchMatch} SearchMatch
         */
        SearchMatch.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SearchMatch)
                return object;
            let message = new $root.api.SearchMatch();
            if (object.id != null)
                message.id = String(object.id);
            if (object.score != null)
                message.score = Number(object.score);
            return message;
        };

        /**
         * Creates a plain object from a SearchMatch message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SearchMatch
         * @static
         * @param {api.SearchMatch} message SearchMatch
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchMatch.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.score = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.score != null && message.hasOwnProperty("score"))
                object.score = options.json && !isFinite(message.score) ? String(message.score) : message.score;
            return object;
        };

        /**
         * Converts this SearchMatch to JSON.
         * @function toJSON
         * @memberof api.SearchMatch
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchMatch.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SearchMatch;
    })();

    api.SearchResult = (function() {

        /**
         * Properties of a SearchResult.
         * @memberof api
         * @interface ISearchResult
         * @property {Array.<api.ISearchMatch>|null} [matches] SearchResult matches
         */

        /**
         * Constructs a new SearchResult.
         * @memberof api
         * @classdesc Represents a SearchResult.
         * @implements ISearchResult
         * @constructor
         * @param {api.ISearchResult=} [properties] Properties to set
         */
        function SearchResult(properties) {
            this.matches = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchResult matches.
         * @member {Array.<api.ISearchMatch>} matches
         * @memberof api.SearchResult
         * @instance
         */
        SearchResult.prototype.matches = $util.emptyArray;

        /**
         * Creates a new SearchResult instance using the specified properties.
         * @function create
         * @memberof api.SearchResult
         * @static
         * @param {api.ISearchResult=} [properties] Properties to set
         * @returns {api.SearchResult} SearchResult instance
         */
        SearchResult.create = function create(properties) {
            return new SearchResult(properties);
        };

        /**
         * Encodes the specified SearchResult message. Does not implicitly {@link api.SearchResult.verify|verify} messages.
         * @function encode
         * @memberof api.SearchResult
         * @static
         * @param {api.ISearchResult} message SearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.matches != null && message.matches.length)
                for (let i = 0; i < message.matches.length; ++i)
                    $root.api.SearchMatch.encode(message.matches[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SearchResult message, length delimited. Does not implicitly {@link api.SearchResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SearchResult
         * @static
         * @param {api.ISearchResult} message SearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SearchResult message from the specified reader or buffer.
         * @function decode
         * @memberof api.SearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SearchResult} SearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SearchResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.matches && message.matches.length))
                        message.matches = [];
                    message.matches.push($root.api.SearchMatch.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SearchResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SearchResult} SearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SearchResult message.
         * @function verify
         * @memberof api.SearchResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SearchResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.matches != null && message.hasOwnProperty("matches")) {
                if (!Array.isArray(message.matches))
                    return "matches: array expected";
                for (let i = 0; i < message.matches.length; ++i) {
                    let error = $root.api.SearchMatch.verify(message.matches[i]);
                    if (error)
                        return "matches." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SearchResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SearchResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SearchResult} SearchResult
         */
        SearchResult.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SearchResult)
                return object;
            let message = new $root.api.SearchResult();
            if (object.matches) {
                if (!Array.isArray(object.matches))
                    throw TypeError(".api.SearchResult.matches: array expected");
                message.matches = [];
                for (let i = 0; i < object.matches.length; ++i) {
                    if (typeof object.matches[i] !== "object")
                        throw TypeError(".api.SearchResult.matches: object expected");
                    message.matches[i] = $root.api.SearchMatch.fromObject(object.matches[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SearchResult
         * @static
         * @param {api.SearchResult} message SearchResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.matches = [];
            if (message.matches && message.matches.length) {
                object.matches = [];
                for (let j = 0; j < message.matches.length; ++j)
                    object.matches[j] = $root.api.SearchMatch.toObject(message.matches[j], options);
            }
            return object;
        };

        /**
         * Converts this SearchResult to JSON.
         * @function toJSON
         * @memberof api.SearchResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SearchResult;
    })();

    return api;
})();

export { $root as default };
