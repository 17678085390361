import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
const Footer2 = ({ footer }: { footer?: number | undefined }) => {
  return (
    <div id="footer" style={{ backgroundColor: "#EFF2F5", color: "#212B35", fontSize: "0.7rem" }}>
      <hr />
      <Row style={{ padding: '30px' }}>
        <Col lg="3" sm="6">
          <div style={{ marginTop: "10px" }}>
            <b>AI Image Tools</b><br />
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-photo-editor">Photo Editor</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/background-remover-ai">AI Background Remover</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-hairstyle-try-on">Hairstyle Try On</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-professional-headshots">AI Professional Headshots
            </a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-object-remover">AI Object Remover</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-photo-enhancer">AI Photo Enhancer</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-product-photography">AI Product Photography</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-room-design-generator">AI Room Designer</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-virtual-home-staging-realtors">Virtual Home Staging</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-upscaler">AI Image Upscaler</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/sketch-to-image-ai">Sketch To Image AI</a>
          </div>
        </Col>
        <Col lg="3" sm="6">
          <div style={{ marginTop: "10px" }}>
            <b>AI Generation Tools</b><br />
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-anime-pfp-generator">AI Anime PFP Generator</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-art-image-generator">AI Art Generator</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-clipart-image-generator">AI ClipArt Image Generator
            </a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-face-generator-maker">AI Face Generator & Maker
            </a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-logo-maker-generator">AI Logo Maker & Generator
            </a>
          </div>


          <div style={{ marginTop: "8px" }}>
            <a href="/ai-tattoo-design-generator">AI Tattoo Design Generator</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-t-shirt-design-generator-maker">AI T-Shirt Design Generator
            </a>
          </div>
          <div style={{ marginTop: "30px" }}>
            <b>Explore</b><br />
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-generated-art/1">General</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/aesthetic-wallpapers-backgrounds-image">Aesthetic Wallpapers</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/home-decor-interior-design-styles-ideas">Home Decor &amp; Interior Design</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/tattoo-ideas-design-men-women">Tattoo Ideas</a>
          </div>

        </Col>
        <Col lg="3" sm="6">
          <div style={{ marginTop: "10px" }}>
            <b>AI Tools</b><br />
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-writer-generator">AI Writer</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-paraphrasing-rewording-tool">AI Paraphrasing Tool</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/ai-document-query-ask-answer">AI Comprehend</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="/start">AI Chatbot for Slack</a>
          </div>
          <br />
          <div style={{ marginTop: "10px" }}>
            <b>iOS Download</b><br />
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="https://apps.apple.com/us/app/windy-ai-photo-editor-art-lab/id6468611189">AI Photo Editor App</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="https://apps.apple.com/us/app/beauty-face-headshot-generator/id6653343240">AI Beauty Face App</a>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a href="https://apps.apple.com/us/app/ai-room-planner-home-designer/id6621183737">AI Room Planner & Designer</a>
          </div>

        </Col>
        {(footer && footer !== 4) &&
          <Col lg="3" sm="6">
            <div style={{ marginTop: "10px" }}>
              <b>Company</b><br />
            </div>
            <div style={{ marginTop: "8px" }}>
              <a href="/about">
                About
              </a>
            </div>
            <div style={{ marginTop: "8px" }}>
              <a href="/faqs">
                FAQs
              </a>
            </div>
            <div style={{ marginTop: "8px" }}>
              <a href="/privacy" rel="nofollow">
                Privacy Policy
              </a>
            </div>
            <div style={{ marginTop: "8px" }}>
              <a href="https://windyaiart.com/ai-tools/">
                Blog
              </a>
            </div>
            <div style={{ marginTop: "8px" }}>
              <a href="/terms" rel="nofollow">
                Terms
              </a>
            </div>
            <div style={{ marginTop: "8px" }}>
              <a href="/contacts">
                Contact Us
              </a>
            </div>
          </Col>
        }
      </Row>
      <Row style={{ padding: '10px' }}>
        <Col sm="12" md="7" style={{ padding: "10px" }}>
          <img
            src="/images/windybotlogo.png"
            height={17}
            width={80}
            style={{ margin: "-5px 5px 0px 15px" }}
            alt="footer-logo"

          />           All rights reserved, WindyBot© {new Date().getFullYear()}

        </Col>
        <Col sm="12" md="5" style={{ padding: "10px" }}>
          <a rel="nofollow" href="https://www.linkedin.com/company/windybot/" className="socialLink" target="social1" aria-label="Follow us on LinkedIn"><i className="fab fa-linkedin-in"></i></a>
          <a rel="nofollow" href="https://www.instagram.com/windyartstudio/" className="socialLink" target="social2" aria-label="Follow us on Instagram"><i className="fab fa-instagram"></i></a>
          <a rel="nofollow" href="https://www.facebook.com/groups/windyart/" className="socialLink" target="social3" aria-label="Join Facebook Group"><i className="fab fa-facebook"></i></a>
          <a rel="nofollow" href="https://www.reddit.com/r/windybot/" className="socialLink" target="social4" aria-label="Join  our subreddit"><i className="fab fa-reddit"></i></a>
          <a rel="nofollow" href="https://www.youtube.com/@windybot" className="socialLink" target="social3" aria-label="Subscribe to Youtube channel"><i className="fab fa-youtube"></i></a>
          <a rel="nofollow" href="https://www.pinterest.com/windybotart/" className="socialLink" target="social3" aria-label="Follow us on Pinterest"><i className="fab fa-pinterest"></i></a>


          {/*
              <li>
                <Link to="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="ico-facebook"
                >
                  <i className="fab fa-facebook-f" />
                </Link>
              </li>
              <li>
                <Link to="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="ico-twitter"
                >
                  <i className="fab fa-twitter" />
                </Link>
              </li>
              <li>
                <Link to="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="ico-google-plus"
                >
                  <i className="fab fa-google-plus-g" />
                </Link>
              </li>
              <li>
                <Link to="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="ico-tumblr"
                >
                  <i className="fab fa-tumblr" />
                </Link>
              </li>
                */}
          {/*
                                  <li><a href="#" class="ico-behance"><i class="fab fa-behance"></i></a></li>	
                                  <li><a href="#" class="ico-dribbble"><i class="fab fa-dribbble"></i></a></li>									
                                  
                                  <li><a href="#" class="ico-linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                  <li><a href="#" class="ico-pinterest"><i class="fab fa-pinterest-p"></i></a></li>								
                                  <li><a href="#" class="ico-youtube"><i class="fab fa-youtube"></i></a></li>										
                                  <li><a href="#" class="ico-vk"><i class="fab fa-vk"></i></a></li>
                                  <li><a href="#" class="ico-yelp"><i class="fab fa-yelp"></i></a></li>
                                  <li><a href="#" class="ico-yahoo"><i class="fab fa-yahoo"></i></a></li>
                                  */}
          {/* END BOTTOM FOOTER */}
        </Col>
      </Row>

    </div >
  );
};

export default Footer2;
